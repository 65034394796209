import React from 'react';
import {ShippingDashboardRow} from '@shipwell/backend-core-singlerequestparam-sdk';
import {FlexBox} from 'App/components/Box';
import {formatCurrency} from 'App/utils/internationalConstants';
import {getCustomerTotalFinancials, getVendorTotalFinancials} from 'App/utils/shipmentTableHelpers/typed';

export const FinancialsCell = ({shipment}: {shipment: ShippingDashboardRow}) => {
  const customerTotal = formatCurrency(
    getCustomerTotalFinancials(shipment).toFixed(2).toString(),
    shipment.preferred_currency || 'USD'
  );
  const vendorTotal = formatCurrency(
    getVendorTotalFinancials(shipment).toFixed(2).toString(),
    shipment.preferred_currency || 'USD'
  );
  return (
    <FlexBox direction="col">
      <span className="whitespace-nowrap">Customer: {customerTotal}</span>
      <span className="whitespace-nowrap">Carrier: {vendorTotal}</span>
    </FlexBox>
  );
};
