import {useState, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {Modal, Title} from '@shipwell/shipwell-ui';
import {RATE_TABLE_TITLE} from 'App/containers/userCompany/rateTables/constants';
import {RateTableContent, RemoveRateTables} from 'App/containers/userCompany/rateTables/helpers';
import RateTableDetailsAccessorial from 'App/containers/userCompany/rateTables/components/rateTableDetailsAccessorial';
import RateTableDetailsFuelSurcharge from 'App/containers/userCompany/rateTables/components/rateTableDetailsFuelSurcharge';
import {fetchAccessorialChargeTablePromise} from 'App/api/shipment';
import {getFuelSurchargeTable} from 'App/api/rateTables';
import {shipmentsShipmentIdPut} from 'App/actions/shipmentdetails';
import {cleanPayload} from 'App/utils/globals';

// Rate table container initial state
const initialState = {
  accRateTableId: '',
  accessorialCustomer: false,
  accessorialVendor: false,
  fscRateTableId: '',
  fuelSurchargeCustomer: false,
  fuelSurchargeVendor: false
};

const RateTableDetailsContainer = ({setSuccess, dispatch, shipment, financialSide, isEditMode, onRateTableClick}) => {
  const metadata = get(shipment, 'metadata', {});
  const [showFscModal, setShowFscModal] = useState(false);
  const [showAccModal, setShowAccModal] = useState(false);
  const [
    {
      fuelSurchargeVendor,
      fuelSurchargeCustomer,
      accessorialVendor,
      accessorialCustomer,
      accRateTableId,
      fscRateTableId
    },
    setRateTableDetails
  ] = useState(initialState);

  const rateTableHasAccessorialId = accessorialCustomer?.id || accessorialVendor?.id;
  const rateTableHasFuelSurchargeId = fuelSurchargeCustomer?.id || fuelSurchargeVendor?.id;

  const {
    customer_side_accessorial_charge_table: customerAccId,
    vendor_side_accessorial_charge_table: vendorAccId,
    customer_side_fuel_surcharge_table: customerFscId,
    vendor_side_fuel_surcharge_table: vendorFscId
  } = metadata;

  // Monitors chnages in accessorial rate tables
  const updateAccessorialState = useCallback(() => {
    if (customerAccId || vendorAccId) {
      setRateTableDetails((prev) => ({
        ...prev,
        accRateTableId: financialSide === 'customer' ? customerAccId : vendorAccId
      }));
    }
  }, [financialSide, vendorAccId, customerAccId]);

  useEffect(() => {
    updateAccessorialState();
  }, [updateAccessorialState]);

  // Monitors chnages in Fuel Surcharge rate tables
  const updateFuelsurchargeState = useCallback(() => {
    if (customerFscId || vendorFscId) {
      setRateTableDetails((prev) => ({
        ...prev,
        fscRateTableId: financialSide === 'customer' ? customerFscId : vendorFscId
      }));
    }
  }, [financialSide, vendorFscId, customerFscId]);

  useEffect(() => {
    updateFuelsurchargeState();
  }, [updateFuelsurchargeState]);

  const fetchAccessorialTable = useCallback(
    async (accRateTableId) => {
      try {
        const response = await fetchAccessorialChargeTablePromise(accRateTableId);
        if (response?.ok && customerAccId) {
          setRateTableDetails((prevAcc) => ({...prevAcc, accessorialCustomer: response.body}));
        }
        if (response?.ok && vendorAccId) {
          setRateTableDetails((prevAcc) => ({...prevAcc, accessorialVendor: response.body}));
        }
        updateAccessorialState();
      } catch (error) {
        console.error(error);
      }
    },
    [customerAccId, updateAccessorialState, vendorAccId]
  );

  // Call accessorialTable
  useEffect(() => {
    if (!accRateTableId) {
      return;
    }
    fetchAccessorialTable(accRateTableId);
  }, [accRateTableId, fetchAccessorialTable]);

  const fetchFuelSurchargeTable = useCallback(
    async (fscRateTableId) => {
      try {
        const response = await getFuelSurchargeTable(fscRateTableId);
        if (response?.ok && customerFscId) {
          setRateTableDetails((prevFsc) => ({...prevFsc, fuelSurchargeCustomer: response.body}));
        }
        if (response?.ok && vendorFscId) {
          setRateTableDetails((prevFsc) => ({...prevFsc, fuelSurchargeVendor: response.body}));
        }
        updateFuelsurchargeState();
      } catch (error) {
        console.error(error);
      }
    },
    [customerFscId, updateFuelsurchargeState, vendorFscId]
  );

  // Call fuelSurchargeTable
  useEffect(() => {
    if (!fscRateTableId) {
      return;
    }
    fetchFuelSurchargeTable(fscRateTableId);
  }, [fscRateTableId, fetchFuelSurchargeTable]);

  // Remove the rate tables
  const handleDeleteRateTable = (e, tableType) => {
    e.preventDefault();
    const payload = {
      ...shipment
    };
    if (tableType === 'accCustomer' && accessorialCustomer?.id) {
      payload.metadata.customer_side_accessorial_charge_table = null;
      setRateTableDetails((prev) => ({...prev, accessorialCustomer: null}));
    }
    if (tableType === 'accVendor' && accessorialVendor?.id) {
      payload.metadata.vendor_side_accessorial_charge_table = null;
      setRateTableDetails((prev) => ({...prev, accessorialVendor: null}));
    }
    if (tableType === 'fscCustomer' && fuelSurchargeCustomer?.id) {
      payload.metadata.customer_side_fuel_surcharge_table = 'null';
      setRateTableDetails((prev) => ({...prev, fuelSurchargeCustomer: null}));
    }
    if (tableType === 'fscVendor' && fuelSurchargeVendor?.id) {
      payload.metadata.vendor_side_fuel_surcharge_table = 'null';
      setRateTableDetails((prev) => ({...prev, fuelSurchargeVendor: null}));
    }
    const cleanObj = cleanPayload(payload);
    dispatch(shipmentsShipmentIdPut(shipment?.id, cleanObj, undefined, true));
  };

  const rateTable = accessorialVendor?.id
    ? accessorialVendor
    : accessorialCustomer?.id
    ? accessorialCustomer
    : fuelSurchargeCustomer?.id
    ? fuelSurchargeCustomer
    : fuelSurchargeVendor?.id
    ? fuelSurchargeVendor
    : null;

  return (
    <>
      {rateTable ? (
        <div className="mb-2">
          <Title variant="formTitle" className="text-md mb-2 flex w-full items-center pb-1 pl-4 pt-4 font-medium">
            {RATE_TABLE_TITLE}
          </Title>
          {rateTableHasAccessorialId ? (
            <RateTableContent
              rateTableLink={
                financialSide === 'customer' && accessorialCustomer?.name
                  ? accessorialCustomer.name
                  : financialSide === 'vendor' && accessorialVendor?.name
                  ? accessorialVendor.name
                  : 'View Rate Table'
              }
              onClick={() => {
                if (onRateTableClick) {
                  onRateTableClick();
                }
                setShowAccModal(true);
              }}
            >
              <RemoveRateTables
                onClick={(e) => {
                  financialSide === 'customer'
                    ? handleDeleteRateTable(e, 'accCustomer')
                    : financialSide === 'vendor'
                    ? handleDeleteRateTable(e, 'accVendor')
                    : null;
                }}
              >
                {financialSide === 'customer'
                  ? 'Remove Customer ACC'
                  : financialSide === 'vendor'
                  ? 'Remove Vendor ACC'
                  : null}
              </RemoveRateTables>
            </RateTableContent>
          ) : null}
          {rateTableHasFuelSurchargeId ? (
            <RateTableContent
              rateTableLink={
                financialSide === 'customer' && fuelSurchargeCustomer?.name
                  ? fuelSurchargeCustomer.name
                  : financialSide === 'vendor' && fuelSurchargeVendor?.name
                  ? fuelSurchargeVendor.name
                  : 'View Rate Table'
              }
              onClick={() => {
                if (onRateTableClick) {
                  onRateTableClick();
                }
                setShowFscModal(true);
              }}
            >
              <RemoveRateTables
                onClick={(e) => {
                  financialSide === 'customer'
                    ? handleDeleteRateTable(e, 'fscCustomer')
                    : financialSide === 'vendor'
                    ? handleDeleteRateTable(e, 'fscVendor')
                    : null;
                }}
              >
                {financialSide === 'customer'
                  ? 'Remove Customer FSC'
                  : financialSide === 'vendor'
                  ? 'Remove Vendor ACC'
                  : null}
              </RemoveRateTables>
            </RateTableContent>
          ) : null}
        </div>
      ) : null}

      <Modal
        show={showAccModal}
        title={`Viewing ${
          financialSide === 'customer' && accessorialCustomer?.name
            ? accessorialCustomer.name
            : financialSide === 'vendor' && accessorialVendor?.name
            ? accessorialVendor.name
            : 'Rate Table'
        }`}
        footerComponent={null}
        onClose={() => setShowAccModal(false)}
      >
        <RateTableDetailsAccessorial
          financialSide={financialSide}
          isEditMode={isEditMode}
          accessorialCustomer={accessorialCustomer}
          accessorialVendor={accessorialVendor}
          onSubmitSuccess={() => {
            setShowAccModal(false);
            setSuccess('Your table has been added!');
          }}
        />
      </Modal>
      <Modal
        show={showFscModal}
        title={`Viewing ${
          financialSide === 'customer' && fuelSurchargeCustomer?.name
            ? fuelSurchargeCustomer.name
            : financialSide === 'vendor' && fuelSurchargeVendor?.name
            ? fuelSurchargeVendor.name
            : 'Rate Table'
        }`}
        footerComponent={null}
        onClose={() => setShowFscModal(false)}
      >
        <RateTableDetailsFuelSurcharge
          financialSide={financialSide}
          fuelSurchargeCustomer={fuelSurchargeCustomer}
          fuelSurchargeVendor={fuelSurchargeVendor}
          onCancel={() => setShowFscModal(false)}
          onSubmitSuccess={() => {
            setShowFscModal(false);
            setSuccess('Your table has been added!');
          }}
          isEditMode={isEditMode}
        />
      </Modal>
    </>
  );
};

RateTableDetailsContainer.propTypes = {
  dispatch: PropTypes.func,
  setSuccess: PropTypes.func,
  shipment: PropTypes.shape({
    id: PropTypes.string,
    metadata: PropTypes.shape({
      customer_side_accessorial_charge_table: PropTypes.string,
      vendor_side_accessorial_charge_table: PropTypes.string,
      vendor_side_fuel_surcharge_table: PropTypes.string,
      customer_side_fuel_surcharge_table: PropTypes.string
    })
  }),

  financialSide: PropTypes.oneOf(['customer', 'vendor']),
  isEditMode: PropTypes.bool,
  onRateTableClick: PropTypes.func
};

RateTableDetailsContainer.defaultProps = {
  setSuccess: () => {}
};

export default compose(
  connect((state) => ({
    shipment: state.shipmentdetails.one
  }))
)(RateTableDetailsContainer);
