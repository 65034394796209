import {useState} from 'react';
import PropTypes from 'prop-types';
import {SidebarContent, DeprecatedButton} from '@shipwell/shipwell-ui';
import {trackClickCases} from 'App/utils/analytics';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import CasesComponent from 'App/components/Cases/Cases';

import './styles.scss';

export const CasesController = ({shipment}) => {
  const [showCasesModal, setShowCaseModal] = useState(false);

  const handleCaseClick = (e) => {
    e.preventDefault();
    trackClickCases();
    setShowCaseModal(true);
  };

  return (
    <>
      <div className="shipment__collaboration-cases">
        <DeprecatedButton variant="primary" size="medium" onClick={handleCaseClick}>
          View Cases
        </DeprecatedButton>
      </div>

      <InfoModalWrapper
        show={showCasesModal}
        extraClass="cases__modal"
        title={'Shipment ' + shipment.reference_id + ' Cases'}
        onHide={() => {
          setShowCaseModal(false);
        }}
      >
        <CasesComponent
          customer={shipment.customer && shipment.customer.name}
          vendor={
            shipment.mode && shipment.mode.code === 'LTL'
              ? //for LTL, show the current_carrier if it exists
                shipment.current_carrier
                ? shipment.current_carrier.name
                : shipment.relationship_to_vendor && shipment.relationship_to_vendor.vendor
                ? shipment.relationship_to_vendor.vendor.name
                : null
              : shipment.relationship_to_vendor && shipment.relationship_to_vendor.vendor
              ? shipment.relationship_to_vendor.vendor.name
              : null
          }
          shipmentIdentifier={shipment.reference_id}
          shouldShowOwner
          shipmentId={shipment.id}
        />
      </InfoModalWrapper>
    </>
  );
};

const SidebarCases = (props) => {
  return (
    <>
      <SidebarContent className="shipment__action-container" title="Cases" {...props}>
        <CasesController {...props} />
      </SidebarContent>
    </>
  );
};

SidebarCases.tabsRole = 'TabPanel';
export default SidebarCases;
