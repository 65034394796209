/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import {Card, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import DoeRatesCriteriaFormFields from 'App/containers/userCompany/fuelSurcharges/formFields/doeRatesCriteria';
import UserRatesCriteriaFormFields from 'App/containers/userCompany/fuelSurcharges/formFields/userRatesCriteria';
import {DoeRateTableFormFields} from 'App/containers/userCompany/fuelSurcharges/formFields/doeRateTable/DoeRateTableFormFields';
import {UserRateTableFormFields} from 'App/containers/userCompany/fuelSurcharges/formFields/userRateTable/UserRateTableFormFields';
import {toTitleCase} from 'App/utils/globals';

export const cardConstants = [
  {
    title: 'Department of Energy Rates',
    tooltipContent:
      'The US Department of Energy publishes current rates regularly and will be matched against the table you define here to determine the appropriate surcharge.',
    builderExplainer: 'Update your DOE rates using the fields below.',
    isValid: (values) => values?.base_fuel_price >= 0 && values?.doe_rate_increment > 0 && values?.doe_max_rate > 0,
    previewHeader: () => 'Department of Energy Rate',
    criteriaFormFields: DoeRatesCriteriaFormFields,
    rateTableFormFields: DoeRateTableFormFields
  },
  {
    title: 'Your Rates',
    tooltipContent:
      'Surcharges will be mapped to the rate ranges according to your rules here. When shipments execute, they will be applied to shipment financials.',
    builderExplainer: 'Update your rates mapping to the DOE rates using the fields below.',
    isValid: (values) =>
      cardConstants[0].isValid(values) && values?.user_base_rate >= 0 && values?.user_rate_increment > 0,
    previewHeader: (values) => `Your Rates (${toTitleCase(values.rate_type)})`,
    criteriaFormFields: UserRatesCriteriaFormFields,
    rateTableFormFields: UserRateTableFormFields
  }
];

const FuelSurchargeRateTableBuilder = ({values}) => {
  return (
    <div className="fuelSurcharge__ratesCards">
      {cardConstants.map((card) => {
        const CriteriaFormFields = card.criteriaFormFields;
        const RateTableFormFields = card.rateTableFormFields;
        return (
          <Card
            bodyClassName="fuelSurcharge__ratesCards-cardContent"
            key={card.title}
            title={
              <>
                <span>{card.title}</span>
                <Tooltip
                  tooltipContent={card.tooltipContent}
                  trigger="hover"
                  wrapperClassname="ml-1"
                  tooltipClassname="w-96"
                >
                  <SvgIcon name="InfoOutlined" color="$sw-icon" />
                </Tooltip>
              </>
            }
          >
            <div className="fuelSurcharge__ratesCards-criteriaFieldsWrapper">
              <div className="fuelSurcharge__ratesCards-criteriaFieldsWrapper__builderExplainer">
                {card.builderExplainer}
              </div>
              <CriteriaFormFields />
            </div>
            <div className="fuelSurcharge__ratesCards-ratePreviewWrapper">
              {card.isValid(values) ? (
                <>
                  <div className="fuelSurcharge__ratesCards-previewHeader">{card.previewHeader(values)}</div>
                  <RateTableFormFields values={values} />
                </>
              ) : (
                <div className="fuelSurcharge__ratesCards-noPreview">
                  <div className="fuelSurcharge__ratesCards-noPreview-title">No Preview</div>
                  <div>Complete the field above to see your rate table strucure</div>
                </div>
              )}
            </div>
          </Card>
        );
      })}
    </div>
  );
};

FuelSurchargeRateTableBuilder.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.shape({
    base_fuel_price: PropTypes.number,
    doe_max_rate: PropTypes.number,
    doe_rate_increment: PropTypes.number,
    rate_type: PropTypes.string,
    user_base_rate: PropTypes.number,
    user_rate_increment: PropTypes.number,
    calculation_rows: PropTypes.array
  })
};

FuelSurchargeRateTableBuilder.defaultProps = {
  setFieldValue: () => {}
};

export default FuelSurchargeRateTableBuilder;
