import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useMutation} from '@tanstack/react-query';
import {ComponentType} from 'react';
import {wrapDisplayName} from 'recompose';
import {ParcelBillTo, ParcelCapacityProvider, ProviderCode} from '@shipwell/genesis-sdk';
import toNumber from 'lodash/toNumber';
import {transformParcelPayload} from '../../utils/transformParcelPayload';
import {createRateRequest} from 'App/api/genesis/typed';
import {useCapacityProviders} from 'App/data-hooks';

export const useCreateParcelRateRequest = () => {
  const {parcelCapacityProviders} = useCapacityProviders({getActiveParcelOnly: true});

  const activeParcelProviderCodes = parcelCapacityProviders?.map((provider) => provider.provider_code);

  const {mutateAsync: createParcelRateRequest, data} = useMutation({
    mutationFn: ({
      shipment,
      parcelCapacityProviderOptions,
      billTo
    }: {
      shipment: Shipment;
      parcelCapacityProviderOptions?: ParcelCapacityProvider;
      billTo?: ParcelBillTo;
    }) => {
      if (!parcelCapacityProviderOptions || !parcelCapacityProviders) {
        throw new Error('Company lacks capacity provider accounts.');
      }

      return createRateRequest(
        transformParcelPayload({
          parcelCapacityProviders: parcelCapacityProviderOptions
            ? [parcelCapacityProviderOptions]
            : parcelCapacityProviders,
          shipment,
          billTo
        })
      );
    }
  });

  return {
    parcelRequestId: data?.id || null,
    handleCreateParcelRequest: createParcelRateRequest,
    activeParcelProviderCodes
  };
};

// HOC for use with class components
export const withCreateParcelRateRequest = <PropsT,>(Component: ComponentType<PropsT>) => {
  const ComponentWithParcelRateRequest = (props: PropsT) => {
    const {handleCreateParcelRequest, activeParcelProviderCodes} = useCreateParcelRateRequest();

    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        handleCreateParcelRequest={handleCreateParcelRequest}
        activeParcelProviderCodes={activeParcelProviderCodes}
      />
    );
  };

  ComponentWithParcelRateRequest.displayName = wrapDisplayName(Component, 'withCreateParcelRateRequest');
  return ComponentWithParcelRateRequest;
};

export type NewQuoteServiceOptions = {
  providerCode: ProviderCode;
  account?: string;
  cod_options?: {
    price: {
      amount?: number;
      currency?: string;
    };
    cod_type: string;
  };
  service_code?: string | null;
  delivery_confirmation?: string;
  bill_to?: ParcelBillTo;
  payment_options?: string;
};
export const mapNewQuoteFormServiceOptions = (
  options: NewQuoteServiceOptions,
  currency?: string
): {parcelCapacityProviderOptions: ParcelCapacityProvider; billTo?: ParcelBillTo} => {
  const {
    providerCode,
    account,
    cod_options,
    service_code: serviceCode,
    delivery_confirmation,
    bill_to,
    payment_options
  } = options;

  const {price, cod_type} = cod_options || {};

  const serviceOptions =
    (price && cod_type) || delivery_confirmation
      ? {
          ...(price?.amount && cod_type
            ? {
                cod_options: {
                  method_of_payment: cod_type,
                  price: {
                    currency: currency || 'USD',
                    amount: toNumber(price.amount)
                  },
                  cod_type: cod_type
                }
              }
            : {}),
          ...(delivery_confirmation ? {delivery_confirmation} : {}),
          ...(payment_options ? {payment_options} : {})
        }
      : undefined;

  return {
    parcelCapacityProviderOptions: {
      provider_code: providerCode,
      account_number: account,
      service_codes: serviceCode ? [serviceCode] : undefined,
      service_options: serviceOptions
    },
    billTo: bill_to
  };
};
