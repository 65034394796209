import {useEffect, useMemo, useState} from 'react';
import {Card, Tooltip} from '@shipwell/shipwell-ui';
import moment from 'moment-timezone';
import {withRouter, WithRouterProps} from 'react-router';
import {get} from 'lodash';
import {LoadboardType, LoadboardUser} from '@shipwell/tabula-sdk';
import {Column} from 'react-table-6';
import classNames from 'classnames';

import ShipwellTable from 'App/components/swTable';
import SearchUsersForm from 'App/formComponents/forms/searchUsers';
import {useCompanyUsers, useUserMe} from 'App/data-hooks';
import buildPathParams from 'App/utils/buildPathParams';
import {
  EmptyState,
  mergeUsersInfo,
  Original,
  TooltipContent,
  UserData
} from 'App/containers/integrations/details/components/truckstopDetails/utils';
import ActivateUserModal from 'App/containers/integrations/details/components/truckstopDetails/components/ActivateUserModal';
import DeactivateUserModal from 'App/containers/integrations/details/components/truckstopDetails/components/DeactivateUserModal';
import useToggle from 'App/utils/hooks/useToggle';
import useGetExternalAuthService from 'App/api/loadboards/hooks/useGetExternalAuthService';
import Switch from 'App/containers/integrations/details/components/truckstopDetails/components/Switch';

interface CompanyUsersTableProps extends WithRouterProps {
  truckstopUsers: Array<LoadboardUser>;
  isConnected: boolean;
  isActiveUserLoading: boolean;
}

export type UserDataProps = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
};

const CompanyUsersTable = ({
  router,
  location,
  truckstopUsers,
  isConnected,
  isActiveUserLoading
}: CompanyUsersTableProps) => {
  const [showActivateUserModal, toggleShowActivateUserModal] = useToggle(false);
  const [showDeactivateUserModal, toggleShowDeactivateUserModal] = useToggle(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const {data: externalUrl} = useGetExternalAuthService(LoadboardType.Truckstop);

  useEffect(() => {
    setShowSpinner(isActiveUserLoading);
  }, [isActiveUserLoading]);

  const userDataStringify = sessionStorage.getItem('truckstop-user-data');

  const defaultUserData = userDataStringify
    ? (JSON.parse(userDataStringify) as UserDataProps)
    : {
        id: '',
        first_name: '',
        last_name: '',
        email: ''
      };

  const [userData, setUserData] = useState<UserDataProps>(defaultUserData);

  const {data: userMe} = useUserMe();
  const company = userMe?.company;
  const companyId = company?.id || '';

  const currentPage = get(location, 'query.page', '1') as string;
  const currentQuery = get(location, 'query.q', '') as string;
  const currentPageSize = get(location, 'query.pageSize', '10') as string;

  const [meta, setMeta] = useState({
    pageSize: Number(currentPageSize),
    pages: 1,
    page: Number(currentPage),
    q: currentQuery
  });

  const {data, isLoading} = useCompanyUsers(companyId, {...meta, pageSize: meta.pageSize});
  const results = useMemo(() => mergeUsersInfo(data?.results || [], truckstopUsers), [data?.results, truckstopUsers]);

  useEffect(() => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      pageSize: Number(currentPageSize),
      pages: Number(data?.total_pages) || meta.pages,
      page: Number(currentPage),
      q: currentQuery
    }));
  }, [data?.total_pages, currentPage, currentQuery, currentPageSize, meta.pages]);

  const mutate = ({q}: {q: string}) => {
    const updatedPath = buildPathParams(location, {
      page: 1,
      q: q || undefined
    });

    router.push(updatedPath);
  };

  const handleChange = (checked: boolean, userData: UserData) => {
    setUserData(userData);

    if (checked) {
      toggleShowDeactivateUserModal();
    } else {
      sessionStorage.setItem('truckstop-user-data', JSON.stringify(userData));
      externalUrl && window.location.replace(externalUrl);
    }
  };

  const columns = useMemo<Array<Column>>(
    () => [
      {
        Header: 'User',
        accessor: 'email',
        sortable: false,
        Cell: ({original, value}: {original: Original; value: string}) => (
          <Switch
            original={original}
            value={value}
            handleChange={handleChange}
            isConnected={isConnected}
            isLoading={userData.email === value && showSpinner}
          />
        )
      },
      {
        Header: 'Last Active',
        accessor: 'last_login',
        Cell: ({original}: {original: Original}) => {
          return (
            <span className={classNames({'text-sw-disabled': !isConnected || !original.truckstop?.is_active})}>
              {original.last_login
                ? moment.tz(original.last_login, original.timezone).format('MM/DD/YYYY hh:mm A zz')
                : '--'}
            </span>
          );
        }
      },
      {
        Header: 'Enabled Date',
        accessor: 'truckstop.created_at',
        Cell: ({original}: {original: Original}) => {
          if (isConnected) {
            return (
              <span className={classNames({'text-sw-disabled': !isConnected || !original.truckstop?.is_active})}>
                {original.truckstop?.created_at
                  ? moment.tz(original.truckstop.created_at, original.timezone).format('MM/DD/YYYY hh:mm A zz')
                  : '--'}
              </span>
            );
          }
          return (
            <span className={classNames({'text-sw-disabled': !isConnected || !original.truckstop?.is_active})}>--</span>
          );
        }
      },
      {
        Header: 'Permissions',
        accessor: 'truckstop.permissions',
        Cell: ({original, viewIndex, value}: {original: Original; viewIndex: number; value: string[]}) => {
          if (value?.length && isConnected) {
            const formattedValues = value.map((permission) => permission.toLowerCase().replace('_', ' '));
            return (
              <Tooltip
                key={viewIndex}
                placement="top"
                wrapperClassname="w-full z-[999]"
                tooltipContent={<TooltipContent permissions={formattedValues} />}
              >
                <div
                  className={classNames('w-full truncate text-capitalize', {
                    'text-sw-disabled': !isConnected || !original.truckstop?.is_active
                  })}
                >
                  {formattedValues.join(', ')}
                </div>
              </Tooltip>
            );
          }
          return (
            <span className={classNames({'text-sw-disabled': !isConnected || !original.truckstop?.is_active})}>--</span>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isConnected, showSpinner]
  );

  return (
    <Card
      title={
        <div className="integration-details-card-title">
          <div className="flex items-center gap-x-4">
            <span>Users</span>
            <div>
              <SearchUsersForm values={{q: meta.q}} onSubmit={mutate} />
            </div>
          </div>
        </div>
      }
      draggableProvided={'false'}
    >
      <>
        <div className="-mx-6 -my-4 flex min-h-[400px]">
          <ShipwellTable
            data={results}
            router={router}
            location={location}
            loading={isLoading}
            page={Number(meta.page) - 1}
            pages={Number(meta.pages)}
            pageSize={Number(meta.pageSize)}
            tableType="COMPANY_USERS"
            noDataText={<EmptyState />}
            columns={columns}
          />
        </div>
        <ActivateUserModal
          show={showActivateUserModal}
          toggleShow={toggleShowActivateUserModal}
          userData={userData}
          setShowSpinner={setShowSpinner}
        />
        <DeactivateUserModal
          show={showDeactivateUserModal}
          toggleShow={toggleShowDeactivateUserModal}
          userData={userData}
          setShowSpinner={setShowSpinner}
        />
      </>
    </Card>
  );
};

export default withRouter(CompanyUsersTable);
