import React from 'react';
import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {MovementDetailsCard} from './MovementDetailsCard';
import {RollbarErrorBoundary} from 'App/common/ErrorBoundary';

// TODO: remove this flag when the flight data is available
const hideMovementDetailsCard = true;

const movementTypeToLabelMap: Record<string, string> = {
  DOOR_TO_DOOR: 'Door to Door',
  PORT_TO_PORT: 'Port to Port',
  DOOR_TO_PORT: 'Door to Port',
  PORT_TO_DOOR: 'Port to Door',
  '': 'Unknown'
};

const airMovementTypeToLabelMap: Record<string, string> = {
  DOOR_TO_DOOR: 'Door to Door',
  PORT_TO_PORT: 'Airport to Airport',
  DOOR_TO_PORT: 'Door to Airport',
  PORT_TO_DOOR: 'Airport to Door',
  '': 'Unknown'
};

export const MovementDetails = ({shipment}: {shipment: Shipment}) => {
  const movementTypeLabel =
    shipment?.mode?.code === 'AIR'
      ? airMovementTypeToLabelMap[shipment?.movement_type || '']
      : movementTypeToLabelMap[shipment?.movement_type || ''];

  return (
    <div className="p-2">
      <Card
        title={
          <div className="flex items-center py-4">
            <div className="flex items-baseline">
              <span>Movement Details</span>
              <span className="text-grey-5 max-w-tag truncate text-xxs font-normal uppercase">
                <span className="mx-1">•</span>
                {shipment?.mode?.description}
                <span className="mx-1">•</span>
                {movementTypeLabel}
                {!hideMovementDetailsCard && <span className="mx-1">•</span>}
                {!hideMovementDetailsCard && `2 flights`}
              </span>
            </div>
          </div>
        }
        isCollapsible={!hideMovementDetailsCard}
        draggableProvided={undefined}
      >
        {!hideMovementDetailsCard && (
          <CollapsibleCardContent>
            <RollbarErrorBoundary errorMessage="There was an error displaying the movement details">
              <MovementDetailsCard />
              <MovementDetailsCard />
            </RollbarErrorBoundary>
          </CollapsibleCardContent>
        )}
      </Card>
    </div>
  );
};
