import {Shipment, ShipmentChargeLineItem} from '@shipwell/backend-core-singlerequestparam-sdk';
import {DisplayValue, IconButton, SvgIcon} from '@shipwell/shipwell-ui';
import {isNull} from 'lodash';
import React from 'react';
import {formatCurrency} from 'App/utils/internationalConstants';

const Financials = ({
  shipment,
  isUpdateShipmentLoading,
  toggleShowFinancialsModal
}: {
  shipment: Shipment;
  isUpdateShipmentLoading: boolean;
  toggleShowFinancialsModal: () => void;
}) => {
  const shipmentCurrency = shipment?.preferred_currency;

  const calculateGrandTotal = (charges: ShipmentChargeLineItem[]) => {
    const rawTotal = charges.reduce((total, charge) => {
      return total + charge.unit_amount * charge.unit_quantity;
    }, 0);

    if (rawTotal) {
      return parseFloat(String(rawTotal)).toFixed(2);
    }
    return 0;
  };

  const financialItems = [
    {label: 'Max Buy', value: shipment?.metadata?.max_buy_amount},
    {label: 'Book Now', value: shipment?.metadata?.buy_it_now_amount},
    {label: 'Target Rate', value: shipment?.metadata?.target_rate_amount}
  ];

  return (
    <div className="mb-3 rounded border-1 border-sw-success bg-sw-success-background py-4 pl-4 pr-2">
      <div className="flex justify-between">
        {financialItems.map((item) => (
          <DisplayValue label={item.label} key={item.label}>
            {isUpdateShipmentLoading ? (
              <SvgIcon name="LoadingDots" />
            ) : !isNull(item?.value) ? (
              formatCurrency(item?.value, shipmentCurrency)
            ) : (
              '--'
            )}
          </DisplayValue>
        ))}
        <IconButton iconName="Edit" aria-label="Edit financials" onClick={() => toggleShowFinancialsModal()} />
      </div>
      <div className="mt-2 text-[8px] uppercase">{`Customer Financials: ${formatCurrency(
        calculateGrandTotal(shipment.relationship_to_customer?.vendor_charge_line_items || []),
        shipmentCurrency
      )}`}</div>
    </div>
  );
};

export default Financials;
