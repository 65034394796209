/* eslint-disable camelcase */
import {useState, useEffect} from 'react';
import {Button, Loader} from '@shipwell/shipwell-ui';
import {connect} from 'react-redux';
import {InjectedRouter, RouterState} from 'react-router';
import {Hazmat, Quote, RFQ, Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AllParcelRates} from './components/AllParcelRates';
import {RatesSideNav} from './components/RatesSideNav';
import {RatesAccountSelector} from './components/RatesAccountSelector';
import {combineQuotesWithParcelRates} from './helpers/combineQuotesWithParcelRates';
import {CombinedQuote} from './components/AllParcelRates/types/combinedQuote';
import DashboardSummary from 'App/components/dashboardSummary';
import ShipwellLoader from 'App/common/shipwellLoader';
import PageHeader from 'App/common/pageHeader';
import {fetchFedexPackageTypes, fetchUPSPackageTypes, fetchUSPSPackageTypes} from 'App/actions/_shipments';
// eslint-disable-next-line import/no-namespace
import * as shipmentActions from 'App/actions/shipments';
import {transformShipmentToForm} from 'App/utils/globals';
import {parcelPickupStatuses, getParcelPackageType} from 'App/utils/parcelConstants';
import {useGetRfqDetails} from 'App/api/quoting/hooks/useGetRfqDetails';
import {useGetFullShipmentDetails} from 'App/containers/alertsDashboard/utils/hooks/useGetFullShipmentDetails';
import {State} from 'App/reducers/types';
import {useUserMe} from 'App/data-hooks';

import {useGetParcelRateRequest} from 'App/api/quoting/hooks/parcel/useGetParcelRateRequest';
import {useGetParcelRateRequestIdByShipmentId} from 'App/api/quoting/hooks/parcel/useGetParcelRateRequestIdByShipmentId';

type Props = {
  params: {shipment_id: string};
  fetchFedexPackageTypes: () => void;
  fetchUPSPackageTypes: () => void;
  fetchUSPSPackageTypes: () => void;
  getShipmentDetails: (shipmentId: string) => void;
  selectQuote: (quote: Quote) => void;
  selectFormShipment: (shipment: Shipment) => void;
  hazmatCodes: Hazmat;
  router: InjectedRouter & RouterState;
};

const NewParcelMarketplace = ({
  hazmatCodes,
  params,
  fetchFedexPackageTypes,
  fetchUPSPackageTypes,
  fetchUSPSPackageTypes,
  getShipmentDetails,
  selectQuote,
  selectFormShipment,
  router
}: Props) => {
  const {company} = useUserMe();
  const [isLoadingRfqCreation, setIsLoadingRfqCreation] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>('all');

  const [rfqFromShipment, setRFQFromShipment] = useState<RFQ>({} as RFQ);

  const locationState = router.location.state as
    | {
        backRoute?: string;
      }
    | undefined;
  const {backRoute} = locationState || {};

  const {rateRequestIdByShipmentId, isLoadingParcelRates, noParcelRatesOnShipment} =
    useGetParcelRateRequestIdByShipmentId(params.shipment_id);

  const {parcelRates} = useGetParcelRateRequest({
    requestParams: {
      rateRequestId: rateRequestIdByShipmentId as string
    }
  });

  const {queryInfo: shipmentQuery} = useGetFullShipmentDetails(params.shipment_id, {
    onSettled: (shipmentDetails) => {
      if (!shipmentDetails?.id) return;

      getShipmentDetails(shipmentDetails?.id);
      const parentRqf = getParentRFQ(shipmentDetails);
      setRFQFromShipment(parentRqf as RFQ);
    }
  });

  const shipment = shipmentQuery?.data;

  const getReadableShipmentStatus = (status: Shipment['parcel_pickup_status']) => {
    let readableShipmentStatus = null;
    parcelPickupStatuses.forEach((s) => {
      if (status === s.parcel_pickup_status) {
        readableShipmentStatus = s.label;
      }
    });
    return readableShipmentStatus;
  };

  const getParentRFQ = (shipment: Shipment) => {
    const {rfqs = []} = shipment;

    if (rfqs.length > 0) {
      return rfqs
        .filter((rfq) => !rfq.has_parent_rfq)
        .sort((a, b) => {
          if (!a.updated_at || !b.updated_at) return 0;

          return +b.updated_at - +a.updated_at;
        })[0];
    }
    return null;
  };

  const {getRfqDetailsQuery, noRfqOnShipment, quotes} = useGetRfqDetails(rfqFromShipment);

  const rfq = getRfqDetailsQuery?.data?.did_finish_autoquote && getRfqDetailsQuery?.data;

  const handleSelectQuote = (quote: CombinedQuote) => {
    const isGenesisRate = quote?.isGenesisRate;
    const shipmentForForm = {...shipment};
    const {mode} = quote;

    if (!isGenesisRate) {
      if (rfq) {
        if (quote?.fedex_direct_quote) {
          shipmentForForm.fedex_specific_options = {
            ...rfq.fedex_specific_options,
            ...(shipment?.fedex_specific_options && shipment.fedex_specific_options),
            ...quote?.fedex_direct_quote,
            bill_to_payment_type: 'SENDER',
            dropoff_type: 'REGULAR_PICKUP'
          } as RFQ['fedex_specific_options'];
        } else if (quote?.ups_direct_quote) {
          shipmentForForm.ups_specific_options = {
            ...rfq.ups_specific_options,
            ...(shipment?.ups_specific_options && shipment.ups_specific_options),
            ...quote?.ups_direct_quote,
            bill_to_payment_type: 'SENDER'
          } as RFQ['ups_specific_options'];
        } else if (quote?.usps_direct_quote) {
          shipmentForForm.usps_specific_options = {
            ...rfq.usps_specific_options,
            ...(shipment?.usps_specific_options && shipment.usps_specific_options),
            ...quote?.usps_direct_quote,
            bill_to_payment_type: 'SENDER'
          } as RFQ['usps_specific_options'];
        }
      }
    }

    const equipmentTypes = [];

    if (quote?.equipment_type) {
      equipmentTypes.push(quote?.equipment_type);
    }

    selectQuote(quote);
    selectFormShipment(transformShipmentToForm(shipmentForForm, hazmatCodes, [mode], equipmentTypes, true));

    if (shipment?.id) {
      router.push(`/shipments/${shipment.id}/confirm?mode=instant-rates&parcel=true`);
    }
  };

  const getNumberOfPackages = (lineItems: Shipment['line_items']) => {
    return lineItems?.reduce((totalCount, li) => {
      if (!li.total_packages) return 0;

      return (totalCount += li.total_packages);
    }, 0);
  };

  const packageType = getParcelPackageType(shipment?.line_items) as string;
  const numberOfPackages = getNumberOfPackages(shipment?.line_items);

  useEffect(() => {
    // set package types in store
    fetchFedexPackageTypes();
    fetchUPSPackageTypes();
    fetchUSPSPackageTypes();
  }, [fetchFedexPackageTypes, fetchUPSPackageTypes, fetchUSPSPackageTypes]);

  const isLoading = [shipmentQuery?.isLoading, getRfqDetailsQuery?.isLoading].some(Boolean);

  if (!shipment || isLoading || isLoadingParcelRates)
    return (
      <div className="h-screen">
        <ShipwellLoader loading />
      </div>
    );

  const isFetchingRates = !getRfqDetailsQuery?.data?.did_finish_autoquote || getRfqDetailsQuery.isFetching;

  if (isLoadingRfqCreation) {
    return (
      <div className="flex items-center justify-center bg-sw-background py-12 font-bold">
        <Loader show>Re-rating...</Loader>
      </div>
    );
  }

  if (isFetchingRates) {
    return (
      <div className="flex items-center justify-center bg-sw-background py-12 font-bold">
        <Loader show>Getting Rates...</Loader>
      </div>
    );
  }

  if (noParcelRatesOnShipment && noRfqOnShipment) {
    router.push({
      pathname: `/shipments/${params.shipment_id}/edit`
    });
    return null;
  }

  return (
    <>
      {shipmentQuery?.error ? (
        <h2>There was an error getting the details of this shipment. Please refresh your page or check the URL.</h2>
      ) : (
        <div className="flex h-screen-minus-header w-full flex-col  items-start justify-between overflow-y-scroll bg-sw-text-reverse md:flex-row">
          <RatesSideNav
            parcelRates={combineQuotesWithParcelRates({
              parcelRates,
              quotes
            })}
            rfqFromShipment={rfqFromShipment}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />

          <div className="w-full">
            <PageHeader
              title={
                <div className="flex items-center gap-4">
                  Shipment {shipment.reference_id}
                  <div className="rounded-3xl bg-sw-text-section-title px-2 py-1 text-center text-xs font-bold uppercase text-sw-text-reverse">
                    {shipment.state === 'carrier_confirmed'
                      ? 'Carrier Confirmed'
                      : getReadableShipmentStatus(shipment.parcel_pickup_status)}
                  </div>
                </div>
              }
              backRoute={backRoute || `/shipments/${shipment.id}/edit`}
              className="bg-sw-text-reverse-alternate"
              actions={
                <div className="flex items-center gap-4">
                  {rfq && (
                    <RatesAccountSelector
                      providerCode={selectedTab}
                      shipment={shipment}
                      setRFQFromShipment={setRFQFromShipment}
                      rfq={rfq}
                      packageType={packageType}
                      setIsLoadingRfqCreation={setIsLoadingRfqCreation}
                    />
                  )}
                  <Button
                    disabled={isFetchingRates}
                    onClick={() => router.push(`/shipments/${shipment.id}/edit`)}
                    variant="secondary"
                  >
                    Edit Quote
                  </Button>
                </div>
              }
            />
            <div className="flex h-screen-minus-header-and-page-title w-full flex-col divide-x-1 divide-sw-border md:flex-row">
              <div className="w-full">
                <AllParcelRates
                  handleSelectQuote={handleSelectQuote}
                  numberOfPackages={numberOfPackages as number}
                  packageType={packageType}
                  quotes={quotes}
                  selectedTab={selectedTab}
                  isFetchingRates={isFetchingRates}
                  parcelRates={parcelRates}
                />
              </div>
              <DashboardSummary
                shipment={shipment}
                customer={shipment.customer}
                company={company}
                isLoadBoard={false}
                isCarrierDetailView={false}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default connect(
  (state: State) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    hazmatCodes: state.shipments.hazmatCodes
  }),
  {
    ...shipmentActions,
    fetchFedexPackageTypes,
    fetchUPSPackageTypes,
    fetchUSPSPackageTypes
  }
)(NewParcelMarketplace);
