import {ShipwellError} from '@shipwell/backend-core-sdk';
import {AxiosError} from 'axios';
import invariant from 'tiny-invariant';

export interface ShipwellApiErrorSource {
  parameter?: string;
  pointer?: string;
}
export interface ShipwellApiError {
  title: string;
  status: string;
  code?: string;
  detail?: string;
  source?: ShipwellApiErrorSource;
  meta?: unknown;
}
export interface ShipwellApiErrorResponse {
  errors: ShipwellApiError[];
}
/**
 * Helper method to keep original axios error object and convert the error response into a standardized error object.
 */
export const convertShipwellAxiosError = (error: AxiosError<ShipwellError>): AxiosError<ShipwellApiErrorResponse> => {
  invariant(error?.response?.data, 'Error response data is undefined');
  return {
    ...error,
    response: {
      ...error.response,
      data: convertShipwellError(error.response.data)
    }
  };
};
/**
 * Converts legacy error object into standardized error object that is used throughout the shipwell ecosystem.
 * All errors are mapped to be flattened out in into the `ShipwellApiError` format. The `field_errors_condensed`
 * are ignored and not appended to the error messages.
 */
export const convertShipwellError = (error: ShipwellError): ShipwellApiErrorResponse => {
  let errors: ShipwellApiError[] = [];
  if (error.field_errors) {
    for (const key in error.field_errors) {
      for (let i = 0; i < error.field_errors[key].length; ++i) {
        const fieldErrorValueErrorMessage: string = error.field_errors[key][i];
        const fieldError: ShipwellApiError = {
          title: 'Error',
          status: 'Invalid',
          detail: fieldErrorValueErrorMessage
        };
        errors = [...errors, fieldError];
      }
    }
  }
  if (error.non_field_errors) {
    for (let i = 0; i < error.non_field_errors.length; ++i) {
      const errorMessage: string = error.non_field_errors[i];
      const fieldError: ShipwellApiError = {
        title: 'Error',
        status: 'Invalid',
        detail: errorMessage
      };
      errors = [...errors, fieldError];
    }
  }
  const rootError: ShipwellApiError = {
    title: error.error,
    status: 'Invalid',
    detail: error.error_description
  };
  errors = [...errors, rootError];
  return {
    errors: errors
  };
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
export const is404Error = (error: unknown) => (error as any)?.response?.status === 404;
