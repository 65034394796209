import PropTypes from 'prop-types';
import {detailsComponentMap} from 'App/containers/shipments/details/utils/DetailsSchemas';

const DetailsCard = ({draggableId, ...props}) => {
  const config = detailsComponentMap[draggableId];
  const View = config.view;

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <View {...props} />
  );
};

DetailsCard.propTypes = {
  draggableId: PropTypes.string
};

export default DetailsCard;
