import {
  SELECT_SPOT_NEGOTIATION_ARRAY,
  ALERT_ERROR,
  FETCH_SPOT_NEGOTIATION_MESSAGES,
  GET_SPOT_NEGOTIATION_EMAIL_PREVIEW,
  FETCH_SPOT_NEGOTIATION_QUOTES,
  SELECT_SPOT_NEGOTIATION,
  FETCH_CUSTOMER_NEGOTIATION,
  SET_COMMUNICATION_TAB,
  SET_QUOTES_TAB,
  MARKETPLACE_LOADING,
  GET_BIDDING_SHIPMENTS,
  GET_LOADBOARD_SHIPMENT
} from './types';
import {parseErrors} from 'App/utils/globals';
import {getShipwellApi} from 'App/api/config';

/**
 * Bulk create SpotNegotiations for a Shipment
 * @param {String} shipmentId The id of the shipment
 * @param {module:model/BulkCreateSpotNegotiationRequest} body
 * @param {Object} opts Optional parameters
 * @param {Boolean} opts.expandQuotes Boolean, if True will include quotes
 * @param {module:api/shipwellApi~quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsPostCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/SpotNegotiation>}
 */

export function createPrivateMarketplaceSpotNegotiations(shipmentId, body) {
  return (dispatch) => {
    return createPrivateMarketplaceSpotNegotiationsPromise(shipmentId, body)
      .then((response) => {
        dispatch({
          type: SELECT_SPOT_NEGOTIATION_ARRAY,
          payload: response
        });
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function createPrivateMarketplaceSpotNegotiationsPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsPost(
      shipmentId,
      body,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

/**
 * List all SpotNegotiationMessages of a SpotNegotiation
 * @param {String} spotNegotiationId The id of the spot negotiation
 * @param {module:api/shipwellApi~quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdMessagesGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/PaginatedSpotNegotiationMessage}
 */

export function getSpotNegotiationMessages(spotId) {
  return (dispatch) => {
    return getSpotNegotiationMessagesPromise(spotId)
      .then((response) => {
        dispatch({
          type: FETCH_SPOT_NEGOTIATION_MESSAGES,
          payload: response
        });
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function getSpotNegotiationMessagesPromise(spotId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdMessagesGet(
      spotId,
      {},
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

/**
 * Returns a preview of the HTML Email that will be sent and the included message
 * @param {String} shipmentId The id of the shipment
 * @param {Object} opts Optional parameters
 * @param {Boolean} opts.isCustomerFacing If True, render a template for sending a quote to a customer. Else, render a request for quote to be sent to a vendor.
 * @param {module:api/shipwellApi~quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsPreviewGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/CustomerNegotiationPreview}
 */

export function getSpotNegotiationEmailPreview(shipmentId, opts) {
  opts = opts || {};
  return (dispatch) => {
    return getSpotNegotiationEmailPreviewPromise(shipmentId, opts)
      .then((response) => {
        dispatch({
          type: GET_SPOT_NEGOTIATION_EMAIL_PREVIEW,
          payload: response
        });
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function getSpotNegotiationEmailPreviewPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsPreviewGet(
      shipmentId,
      opts,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

/**
 * List all Qutoes of the SpotNegotiation
 * @param {String} spotNegotiationId The id of the spot negotiation
 * @param {module:api/shipwellApi~quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/PaginatedSpotNegotiationQuote}
 */

export function getSpotNegotiationQuotes(spotId) {
  return (dispatch) => {
    return getSpotNegotiationQuotesPromise(spotId)
      .then((response) => {
        dispatch({
          type: FETCH_SPOT_NEGOTIATION_QUOTES,
          payload: response
        });
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function getSpotNegotiationQuotesPromise(spotId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesGet(
      spotId,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

/**
 * Retrieve a ShipmentSpotNegotiation by its ID
 * @param {String} spotNegotiationId The id of the spot negotiation
 * @param {module:api/shipwellApi~quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/ShipmentSpotNegotiation}
 */

export function getSpotNegotiationDetails(spotId) {
  return (dispatch) => {
    return getSpotNegotiationDetailsPromise(spotId)
      .then((response) => {
        dispatch({
          type: SELECT_SPOT_NEGOTIATION,
          payload: response
        });
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function getSpotNegotiationDetailsPromise(spotId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdGet(spotId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * Get, create, or update a negotiation in which you are the vendor and the shipment&#39;s customer_freight_authority is the customer. Only available to companies that are already assigned as a vendor to that customer.
 * @param {String} shipmentId The id of the shipment
 * @param {module:model/SpotNegotiation} body
 * @param {module:api/shipwellApi~quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsCustomerNegotiationPostCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/SpotNegotiation}
 */
export function getCustomerNegotiation(shipmentId, body) {
  return (dispatch) => {
    return getCustomerNegotiationPromise(shipmentId, body)
      .then((response) => {
        dispatch({
          type: FETCH_CUSTOMER_NEGOTIATION,
          payload: response
        });
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function getCustomerNegotiationPromise(shipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsCustomerNegotiationPost(
      shipmentId,
      body,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

/**
 * Create a SpotNegotiationMessage
 * @param {String} spotNegotiationId The id of the spot negotiation
 * @param {module:model/SpotNegotiationMessage} body
 * @param {module:api/shipwellApi~quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdMessagesPostCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/SpotNegotiationMessage}
 */

export function sendSpotNegotiationMessage(spotId, messageBody) {
  return (dispatch) => {
    return sendSpotNegotiationMessagePromise(spotId, messageBody)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function sendSpotNegotiationMessagePromise(spotId, messageBody) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdMessagesPost(
      spotId,
      messageBody,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

/**
 * Create a Quote on the SpotNegotiation
 * @param {String} spotNegotiationId The id of the spot negotiation
 * @param {module:model/CreateSpotNegotiationQuote} body
 * @param {module:api/shipwellApi~quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesPostCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/Quote}
 */
export function createSpotNegotiationQuote(spotId, quote) {
  if (quote.charge_line_items) {
    for (var i = 0; i < quote.charge_line_items.length; i++) {
      quote.charge_line_items[i].category = 'LH';
    }
  }
  return (dispatch) => {
    return createSpotNegotiationQuotePromise(spotId, quote)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function createSpotNegotiationQuotePromise(spotId, quote) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesPost(
      spotId,
      quote,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

/**
 * Update a SpotNegotiation Quote
 * @param {String} spotNegotiationId The id of the spot negotiation
 * @param {String} spotNegotiationQuoteId The id of the spot negotiation quote
 * @param {module:model/Quote} body
 * @param {module:api/shipwellApi~quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdPutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/Quote}
 */
export function editSpotNegotiationQuote(spotNegotiationId, spotNegotiationQuoteId, body) {
  if (body.charge_line_items) {
    for (var i = 0; i < body.charge_line_items.length; i++) {
      body.charge_line_items[i].category = 'LH';
    }
  }

  return (dispatch) => {
    return editSpotNegotiationQuotePromise(spotNegotiationId, spotNegotiationQuoteId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function editSpotNegotiationQuotePromise(spotNegotiationId, spotNegotiationQuoteId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdPut(
      spotNegotiationId,
      spotNegotiationQuoteId,
      body,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

/**
 * Delete a SpotNegotiation Quote
 * @param {String} spotNegotiationId The id of the spot negotiation
 * @param {String} spotNegotiationQuoteId The id of the spot negotiation quote
 * @param {module:api/shipwellApi~quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdDeleteCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/NoContentResponse}
 */
export function deleteSpotNegotiationQuote(spotNegotiationId, spotNegotiationQuoteId) {
  return (dispatch) => {
    return deleteSpotNegotiationQuotePromise(spotNegotiationId, spotNegotiationQuoteId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function deleteSpotNegotiationQuotePromise(spotNegotiationId, spotNegotiationQuoteId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdDelete(
      spotNegotiationId,
      spotNegotiationQuoteId,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

/**
 * Award or decline a SpotNegotiation Quote
 * @param {String} spotNegotiationId The id of the spot negotiation
 * @param {String} spotNegotiationQuoteId The id of the spot negotiation quote
 * @param {module:model/AwardDeclineQuoteRequest1} body
 * @param {module:api/shipwellApi~quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdAwardDeclinePutCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/Quote}
 */
export function awardDeclineQuote(spotNegotiationId, spotNegotiationQuoteId, body) {
  return (dispatch) => {
    return awardDeclineQuotePromise(spotNegotiationId, spotNegotiationQuoteId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function awardDeclineQuotePromise(spotNegotiationId, spotNegotiationQuoteId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdQuotesSpotNegotiationQuoteIdAwardDeclinePut(
      spotNegotiationId,
      spotNegotiationQuoteId,
      body,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

/**
 * Open or close authenticated company&#39;s side of the provided negotiations
 * @param {module:model/OpenCloseSpotNegotiationsRequest} body
 * @param {module:api/shipwellApi~quotingSpotnegotiationsSpotNegotiationsOpenClosePostCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link Array.<module:model/SpotNegotiation>}
 */

/*body should contain:
 * @param spot_negotiations {Array.<String>} SpotNegotiation IDs to open or close
 * @param close {Boolean} If true, close the negotiations, else open them*/
export function openCloseSpotNegotiation(body) {
  return (dispatch) => {
    return openCloseSpotNegotiationPromise(body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function openCloseSpotNegotiationPromise(body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsOpenClosePost(body, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 * List all outstanding negotiations under this shipment where the authenticated user is a customer
 * @param {String} shipmentId The id of the shipment
 * @param {module:api/shipwellApi~quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/PaginatedSpotNegotiation}
 */
export function getAllSpotNegotiationsByShipment(shipmentId) {
  //default to get all (at least 100) negotiations
  const opts = {
    expandQuotes: true,
    page: 1,
    pageSize: 1000
  };
  return (dispatch) => {
    return getAllSpotNegotiationsByShipmentPromise(shipmentId, opts)
      .then((response) => {
        dispatch({type: SELECT_SPOT_NEGOTIATION_ARRAY, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function getAllSpotNegotiationsByShipmentPromise(shipmentId, opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsShipmentsShipmentIdSpotNegotiationsGet(
      shipmentId,
      opts,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response.body);
        }
      }
    );
  });
}

export function setSelectedSpotNegotiationsArray(spotNeg) {
  return (dispatch) => {
    dispatch({type: SELECT_SPOT_NEGOTIATION_ARRAY, payload: spotNeg});
  };
}

export function selectSpotNegotiation(spotNeg) {
  return (dispatch) => {
    dispatch({type: SELECT_SPOT_NEGOTIATION, payload: spotNeg});
    if (!spotNeg) {
      //clearing out quotes and messages
      dispatch({type: FETCH_SPOT_NEGOTIATION_QUOTES, payload: []});
      dispatch({type: FETCH_SPOT_NEGOTIATION_MESSAGES, payload: []});
    }
  };
}

export function setDefaultCommunicationTab(tab) {
  return (dispatch) => {
    dispatch({type: SET_COMMUNICATION_TAB, payload: tab});
  };
}
export function setDefaultQuotesTab(tab) {
  return (dispatch) => {
    dispatch({type: SET_QUOTES_TAB, payload: tab});
  };
}
function loadingMarketplace(bool) {
  return {type: MARKETPLACE_LOADING, payload: bool};
}

/* -- SPOT NEGOTIATION V2 REWRITE -- */

/**
 * List all shipments available on a carrier load board
 * @param {Object} opts Optional parameters
 * @param {Integer} opts.page indicates what page of a list of query results you want to view
 * @param {Integer} opts.pageSize indicates the how many items to return per page in a list request
 * @param {String} opts.ordering determines which column to sort by. Prepend with &#39;-&#39; to sort desc
 * @param {String} opts.q search query
 * @param {Boolean} opts.activeVendorAssignment Include/exclude Shipments with active CarrierAssignments
 * @param {Boolean} opts.archived Filter on whether or not a shipment is archived
 * @param {Array.<String>} opts.bolNumber Include Shipments with these BOL Numbers
 * @param {String} opts.bolNumberContains Return shipments where the BOL number contains this value
 * @param {Array.<String>} opts.bolNumberExclude Exclude Shipments with these BOL Numbers
 * @param {String} opts.customer Return Shipments with this exact value as the customer company name
 * @param {String} opts.customerContains Return Shipments where the customer company name contains this value
 * @param {String} opts.customerId Return Shipments with this exact ID for the customer company
 * @param {Array.<String>} opts.customerReferenceNumber Include Shipments with these Customer Reference Numbers
 * @param {String} opts.customerReferenceNumberContains Include Shipments where the Customer Reference Numbers contains this value
 * @param {Array.<String>} opts.customerReferenceNumberExclude Exclude Shipments with these Customer Reference Numbers
 * @param {String} opts.destinationContains Return Shipments with this value in the destination
 * @param {String} opts.destinationStateProvince Return Shipments with this state province on its destination stop
 * @param {Boolean} opts.driverAssigned Return Shipments where the driver is assigned/unassigned
 * @param {Date} opts.dropoffGte Return Shipments with a dropoff stop with a planned date greater than or equal to this value
 * @param {Date} opts.dropoffLte Return Shipments with a dropoff stop with a planned data less than or equal to this value
 * @param {String} opts.equipmentType Return shipments with this equipment type
 * @param {String} opts.equipmentTypeContains Return shipments where the equipment type contains this value
 * @param {Boolean} opts.loadBoardEnabled Filter on whether or not a shipment is posted on the company load board
 * @param {String} opts.mode Return shipments with this shipment mode
 * @param {String} opts.modeContains Return shipments where the shipment mode contains this value
 * @param {String} opts.nameContains Return Shipments where the shipment name contains this value
 * @param {Boolean} opts.open Filter on whether or not a shipment is open
 * @param {String} opts.originContains Return Shipments with this value in the origin
 * @param {String} opts.originStateProvince Return Shipments with this state province on its origin stop
 * @param {Date} opts.pickupGte Return Shipments with a pickup stop with a planned date greater than or equal to this value
 * @param {Date} opts.pickupLte Return Shipments with a pickup stop with a planned date less than or equal to this value
 * @param {Array.<String>} opts.pickupNumber Include Shipments with these Pickup Numbers
 * @param {String} opts.pickupNumberContains Include Shipments where the Pickup Number contains this value
 * @param {Array.<String>} opts.pickupNumberExclude Exclude Shipments with these Pickup Numbers
 * @param {Array.<String>} opts.proNumber Include Shipments with these PRO Numbers
 * @param {String} opts.proNumberContains Return shipments where the PRO number contains this value
 * @param {Array.<String>} opts.proNumberExclude Exclude Shipments with these PRO Numbers
 * @param {String} opts.powerUnit Return Shipments where this power unit is assigned
 * @param {Array.<String>} opts.purchaseOrderNumber Include Shipments with these PO Numbers
 * @param {String} opts.purchaseOrderNumberContains Include Shipments where the PO number contains this value
 * @param {Array.<String>} opts.purchaseOrderNumberExclude Exclude Shipments with these PO Numbers
 * @param {Array.<String>} opts.referenceId Include Shipments with these Reference IDs
 * @param {String} opts.referenceIdContains Include Shipments where the reference ID contains this value
 * @param {Array.<String>} opts.referenceIdExclude Exclude Shipments with these Reference IDs
 * @param {Array.<String>} opts.reps Include Shipments with these reps
 * @param {String} opts.serviceLevel Return shipments with this service level
 * @param {Array.<module:model/String>} opts.status Include Shipments with these statuses
 * @param {Array.<module:model/String>} opts.statusExclude Exclude Shipments with these statuses
 * @param {String} opts.statusContains Include shipments where the status contains this string
 * @param {Array.<String>} opts.tags Include only Shipments with at least one matching ShipmentTag
 * @param {String} opts.stopsContains Return Shipments with this value in one of its stops
 * @param {String} opts.stopsStateProvince Return Shipments with this state province on one of its stops
 * @param {String} opts.timelineLastUpdatedAtGte Return shipments where last timeline update was greater than or equal to the value or was never updated
 * @param {String} opts.timelineLastUpdatedAtLte Return shipments where last timeline update was less than or equal to the value or was never updated
 * @param {String} opts.vendorId Return Shipments with this exact value as the vendor Carrier ID
 * @param {String} opts.vendorContains Return Shipments where the vendor company name contains this value
 * @param {module:api/shipwellApi~quotingLoadboardGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/PaginatedLoadBoardShipment}
 */

export function getLoadBoardShipments(opts, count) {
  if (opts.customer) {
    //hardcoding this delete here because we are using the customerId contains filter always
    delete opts.customer;
  }
  //use a fallback when we are sorting by match_score in case these are still being calculated
  if (opts.ordering && (opts.ordering.includes('-match_score') || opts.ordering.includes('match_score'))) {
    opts.ordering[0] = opts.ordering[0] + ',-created_at';
  }
  return (dispatch) => {
    dispatch(loadingMarketplace(true));
    return getLoadBoardShipmentsPromise(opts)
      .then((response) => {
        dispatch({
          type: GET_BIDDING_SHIPMENTS,
          payload: response,
          meta: {
            key: count
          }
        });
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function getLoadBoardShipmentsPromise(opts) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingLoadboardGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 Get details about a specific load board shipment
 */

export function getLoadBoardShipmentDetails(loadBoardShipmentId) {
  return (dispatch) => {
    return getLoadBoardShipmentDetailsPromise(loadBoardShipmentId)
      .then((response) => {
        dispatch({type: GET_LOADBOARD_SHIPMENT, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function getLoadBoardShipmentDetailsPromise(loadBoardShipmentId) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingLoadboardLoadBoardShipmentIdGet(loadBoardShipmentId, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response.body);
      }
    });
  });
}

/**
 Create a spot negotiation as the vendor on a load board shipment
 */

export function createSpotNegotiationFromLoadboard(loadBoardShipmentId, body) {
  return (dispatch) => {
    return createSpotNegotiationFromLoadboardPromise(loadBoardShipmentId, body)
      .then((response) => {
        dispatch({type: SELECT_SPOT_NEGOTIATION, payload: response.body});
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function createSpotNegotiationFromLoadboardPromise(loadBoardShipmentId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingLoadboardLoadBoardShipmentIdSpotNegotiationsPost(
      loadBoardShipmentId,
      body,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response);
        }
      }
    );
  });
}

/**
 Buy now route - awards the spot negotiation to the carrier immediately for the buy_it_now_amount
 */

export function bookNow(spotNegotiationId, body) {
  return (dispatch) => {
    return bookNowPromise(spotNegotiationId, body)
      .then((response) => {
        dispatch({type: SELECT_SPOT_NEGOTIATION, payload: response.body});
        return response;
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

async function bookNowPromise(spotNegotiationId, body) {
  const shipwellApi = await getShipwellApi();

  return new Promise(function (resolve, reject) {
    shipwellApi.quotingSpotnegotiationsSpotNegotiationsSpotNegotiationIdBuyItNowPost(
      spotNegotiationId,
      body,
      function (err, data, response) {
        if (err) {
          reject(parseErrors(response));
        } else {
          resolve(response);
        }
      }
    );
  });
}
