import isEmpty from 'lodash/isEmpty';
import {useQuery} from '@tanstack/react-query';
import {connect} from 'react-redux';
import uniq from 'lodash/uniq';
import {
  ShipmentTimelineEvent,
  NestedLegTimelineEvent,
  NestedServiceTimelineEvent,
  StageTimelineEventType
} from '@shipwell/corrogo-sdk';
import {SvgIcon} from '@shipwell/shipwell-ui';
import {Company} from '@shipwell/backend-core-sdk';
import TimelineIcon from 'App/components/ShipmentTrackingOverview/TimelineContainer/TimelineIcon';
import TimelineCard from 'App/components/ShipmentTrackingOverview/TimelineContainer/TimelineCard';
import {mapEventTypeWithIcon} from 'App/components/ShipmentTrackingOverview/TimelineContainer/utils';
import {getShipmentTimeline} from 'App/api/corrogo/typed';
import {eventTypes} from 'App/components/ShipmentTrackingOverview/TimelineContainer/utils/timelineConstants';
import Loader from 'App/common/shipwellLoader';
import type {State} from 'App/reducers/types';
import {SHIPMENT_TIMELINE_SUMMARY} from 'App/data-hooks/queryKeys';
import type {CreateEventValidation} from 'App/components/ShipmentTrackingOverview/TimelineContainer/NewEventModal';
import type {ShipmentTimelineSummaryEvent} from 'App/api/corrogo/typed';
import {useShipmentLegs, useUserDetails} from 'App/data-hooks';
import {generateContainerLabel, isContainerLegItem} from 'App/containers/shipments/utils/typed';

interface TimelineProps {
  shipmentId: string;
  toggleShowEventModal: () => void;
  onUpdateEvent?(event: ShipmentTimelineSummaryEvent, values: CreateEventValidation): void;
  onDeleteEvent?(event: ShipmentTimelineSummaryEvent): void;
  company: Partial<Company>;
}

function isShipmentTimelineEvent(
  event?: ShipmentTimelineEvent | NestedLegTimelineEvent | NestedServiceTimelineEvent
): event is ShipmentTimelineEvent {
  return event ? 'id' in event : false;
}

function isNestedLegTimelineEvent(
  event?: ShipmentTimelineEvent | NestedLegTimelineEvent | NestedServiceTimelineEvent
): event is NestedLegTimelineEvent {
  return event ? 'leg_id' in event : false;
}

function isNestedServiceTimelineEvent(
  event?: ShipmentTimelineEvent | NestedLegTimelineEvent | NestedServiceTimelineEvent
): event is NestedServiceTimelineEvent {
  return event ? 'service_id' in event : false;
}

function useTimelineSummary(shipmentId: string, companyId: string) {
  const {data, getItemFromLeg} = useShipmentLegs(shipmentId);

  const timelineQuery = useQuery(
    [SHIPMENT_TIMELINE_SUMMARY, shipmentId],
    async () => {
      if (!shipmentId) {
        return Promise.reject('Invalid shipmentId');
      }
      const response = await getShipmentTimeline(shipmentId);
      return response.data;
    },
    {
      enabled: !!shipmentId
    }
  );

  const userIds = uniq(
    timelineQuery.data?.events?.reduce<string[]>((userIds, event) => {
      if (isShipmentTimelineEvent(event)) {
        return [...userIds, event.created_by_user_id || ''].filter((userId) => !!userId);
      }
      return [
        ...userIds,
        ...event.timeline_events.map((timelineEvent) => timelineEvent.created_by_user_id || '')
      ].filter((userId) => !!userId);
    }, []) || []
  );

  const userDetails = useUserDetails(companyId, userIds);

  const timelineSummary = timelineQuery.data?.events.map((event) => {
    if (isShipmentTimelineEvent(event)) {
      return {...event, actor: userDetails[event.created_by_user_id || '']};
    }
    if (isNestedLegTimelineEvent(event)) {
      const associatedContainer = getItemFromLeg(event.leg_id);
      const containerNumber = isContainerLegItem(associatedContainer)
        ? associatedContainer.identification_number
        : undefined;
      const containerIndex = data?.findIndex((leg) => leg.id === event.leg_id);
      const containerLabel =
        (containerIndex || containerIndex === 0) && containerIndex !== -1
          ? generateContainerLabel(containerIndex, containerNumber)
          : null;

      return {
        ...event,
        event_type: StageTimelineEventType.LocationUpdate,
        timeline_events: event.timeline_events.map((legEvent, index) => ({
          ...legEvent,
          actor: userDetails[legEvent.created_by_user_id || ''],
          leg_id: event.leg_id,
          title: index === 0 ? containerLabel : null
        }))
      };
    }
    if (isNestedServiceTimelineEvent(event)) {
      return {
        ...event,
        event_type: StageTimelineEventType.LocationUpdate,
        timeline_events: event.timeline_events.map((serviceEvent) => ({
          ...serviceEvent,
          actor: userDetails[serviceEvent.created_by_user_id || ''],
          service_id: event.service_id
        }))
      };
    }
  });

  return {timelineSummary, queryInfo: timelineQuery};
}

const Timeline = ({shipmentId, toggleShowEventModal, company, onDeleteEvent}: TimelineProps) => {
  const {timelineSummary, queryInfo} = useTimelineSummary(shipmentId, company.id ?? '');
  if (queryInfo.isInitialLoading) {
    return <Loader loading />;
  }
  if (isEmpty(timelineSummary)) {
    return (
      <div className="flex h-5/6 flex-col items-center justify-center">
        <div className="mb-1">No Timeline Events</div>
        <div className="flex cursor-pointer items-center text-sw-primary" onClick={toggleShowEventModal}>
          <SvgIcon name="AddCircleOutlined" />
          Add Event
        </div>
      </div>
    );
  }

  return (
    <>
      {timelineSummary?.map((event, eventIndex) => {
        if (isShipmentTimelineEvent(event)) {
          return (
            <div key={event.id} className="flex">
              <TimelineIcon
                iconToShow={mapEventTypeWithIcon(event.event_type)}
                isLineHidden={timelineSummary?.length !== undefined && eventIndex === timelineSummary?.length - 1}
              />
              <TimelineCard timelineEvents={[event]} onDeleteEvent={onDeleteEvent} />
            </div>
          );
        }
        if (isNestedLegTimelineEvent(event)) {
          return (
            <div key={event.leg_id} className="flex">
              <TimelineIcon
                iconToShow={mapEventTypeWithIcon(eventTypes.CONTAINER)}
                isLineHidden={timelineSummary?.length !== undefined && eventIndex === timelineSummary?.length - 1}
              />
              <TimelineCard timelineEvents={event.timeline_events} onDeleteEvent={onDeleteEvent} />
            </div>
          );
        }

        if (isNestedServiceTimelineEvent(event)) {
          return (
            <div key={event.service_id} className="flex">
              <TimelineIcon
                iconToShow={mapEventTypeWithIcon(event.event_type)}
                isLineHidden={timelineSummary?.length !== undefined && eventIndex === timelineSummary?.length - 1}
              />
              <TimelineCard timelineEvents={event.timeline_events} onDeleteEvent={onDeleteEvent} />
            </div>
          );
        }
        return <></>;
      })}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export default connect((state: State) => ({company: state.userCompany.company}))(Timeline);
