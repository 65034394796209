import {cloneElement, useState, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import Paper from '@material-ui/core/Paper';
import {useFlags} from 'launchdarkly-react-client-sdk';
import DashboardSummary from 'App/components/dashboardSummary';
import ShipwellLoader from 'App/common/shipwellLoader';
import PageHeader from 'App/common/pageHeader';
import SideNav from 'App/common/sideNav';
import {fetchFedexPackageTypes, fetchUPSPackageTypes, fetchUSPSPackageTypes} from 'App/actions/_shipments';
import * as shipmentActions from 'App/actions/shipments';
import {transformShipmentToForm} from 'App/utils/globals';
import {parcelPickupStatuses, getParcelPackageType} from 'App/utils/parcelConstants';
import './styles.scss';

const ParcelMarketplace = (props) => {
  const {
    company,
    hazmatCodes,
    children,
    params,
    fetchFedexPackageTypes,
    fetchUPSPackageTypes,
    fetchUSPSPackageTypes,
    getShipmentDetails,
    getRFQDetails,
    editShipment,
    createRFQ,
    selectQuote,
    selectFormShipment,
    router
  } = props;

  const {consolidatedParcelRates} = useFlags();

  if (consolidatedParcelRates) {
    router.push({
      pathname: `/marketplace/${params.shipment_id}/parcel-rates`
    });
    return null;
  }

  const [shipment, setShipment] = useState({});
  const [packageType, setPackageType] = useState(null);
  const [numberOfPackages, setNumberOfPackages] = useState(null);
  const [errorFetchingShipment, setErrorFetchingShipment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quotes, setQuotes] = useState({});
  const [rfq, setRFQ] = useState({});
  const [tabsToDisplay, setTabsToDisplay] = useState([]);
  const backRoute = router.location?.state?.backRoute;

  const getReadableShipmentStatus = (status) => {
    let readableShipmentStatus = null;
    parcelPickupStatuses.forEach((s) => {
      if (status === s.parcel_pickup_status) {
        readableShipmentStatus = s.label;
      }
    });
    return readableShipmentStatus;
  };

  const getRfqDetailsCallback = useCallback(
    async (rfq, provider) => {
      try {
        const opts = {includeFailures: true};

        if (rfq.company_owner_id !== company.id) {
          opts.xCompanyId = rfq.company_owner_id;
        }
        const response = await getRFQDetails(rfq.id, opts);

        if (response && response.details) {
          // poll if not finished quoting
          if (!response.details.did_finish_autoquote) {
            setTimeout(() => getRfqDetailsCallback(rfq, provider), 500);
          } else {
            const tabs = [
              response.details.fedex_specific_options && 'fedex-rates',
              response.details.ups_specific_options && 'ups-rates',
              response.details.usps_specific_options && 'usps-rates'
            ].filter(Boolean); // remove falsy values

            if (!tabs.length) {
              router.push({
                pathname: `/shipments/${params.shipment_id}/edit`
              });
              return;
            }

            setRFQ(response.details);
            setTabsToDisplay(tabs);
            setQuotes(sortParcelQuotes(response.details.quotes));

            // if provider exists, this is an rfq created due to a provider account change, don't redirect
            if (!provider) {
              router.push({
                pathname: `/marketplace/${params.shipment_id}/${tabs[0]}`,
                state: {backRoute}
              });
            }
          }
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    },
    [getRFQDetails, company.id, params.shipment_id, router]
  );

  const createParcelRFQ = async (shipment = {}, provider) => {
    let {fedex_specific_options, ups_specific_options, usps_specific_options} = rfq;
    const packageTypeForRfq = packageType === 'MIXED_TYPES' ? 'YOUR_PACKAGING' : packageType;
    // override the provider specific options from the orignal rfq
    // with the new account number that was just set on the shipment
    if (provider === 'fedex') {
      fedex_specific_options = shipment.fedex_specific_options;
    } else if (provider === 'ups') {
      ups_specific_options = shipment.ups_specific_options;
    } else if (provider === 'usps') {
      usps_specific_options = shipment.usps_specific_options;
    }

    try {
      const updatedShipment = await editShipment(shipment.id, shipment);
      const createdRFQ = await createRFQ({
        shipment: shipment.id,
        parent_rfq: null,
        shipment_modes: [shipment.mode],
        autoquote: true,
        fedex_specific_options: fedex_specific_options
          ? {...fedex_specific_options, packaging: packageTypeForRfq}
          : null,
        ups_specific_options: ups_specific_options ? {...ups_specific_options, packaging: packageTypeForRfq} : null,
        usps_specific_options: usps_specific_options ? {...usps_specific_options, packaging: packageTypeForRfq} : null
      });

      if (createdRFQ && createdRFQ.details) {
        getRfqDetailsCallback(createdRFQ.details, provider);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const sortParcelQuotes = (quotes = []) => {
    const sortedQuotesByCarrier = {
      fedex: [],
      ups: [],
      usps: []
    };
    // Here we need additional check for display_name, since for failed quotes [provider]_direct_quote will be always null
    // and without this check FE will not show quotes with errors
    quotes.forEach((q) => {
      if (q.fedex_direct_quote || (q?.carrier?.display_name || '').toLowerCase() === 'fedex') {
        sortedQuotesByCarrier.fedex.push(q);
      }
      if (q.ups_direct_quote || (q?.carrier?.display_name || '').toLowerCase() === 'ups') {
        sortedQuotesByCarrier.ups.push(q);
      }
      if (q.usps_direct_quote || (q?.carrier?.display_name || '').toLowerCase() === 'usps') {
        sortedQuotesByCarrier.usps.push(q);
      }
    });

    // Here we need to sort quotes and put failed at the end
    const sortedQuotes = Object.keys(sortedQuotesByCarrier)
      .map((carrier) => {
        return {
          [carrier]: sortedQuotesByCarrier[carrier].length
            ? sortedQuotesByCarrier[carrier].sort((a, b) => Number(a.is_failure) - Number(b.is_failure))
            : []
        };
      })
      .reduce((quotesObj, quotesByCarrier) => {
        return {...quotesObj, ...quotesByCarrier};
      }, {});

    return sortedQuotes;
  };

  const handleAccountChange = (selectedAccount, provider) => {
    const shipmentForQuote = {...shipment};

    if (selectedAccount && provider) {
      const options = `${provider}_specific_options`;

      shipmentForQuote[options] = {
        ...shipment[options],
        account: selectedAccount
      };

      setQuotes({});
      createParcelRFQ(shipmentForQuote, provider);
    }
  };

  const handleSelectQuote = (quote = {}) => {
    const shipmentForForm = {...shipment};
    const {mode, equipment_type, fedex_direct_quote, ups_direct_quote, usps_direct_quote} = quote;
    const equipment_types = [];

    if (rfq) {
      if (fedex_direct_quote) {
        shipmentForForm.fedex_specific_options = {
          ...rfq.fedex_specific_options,
          ...shipment.fedex_specific_options,
          ...fedex_direct_quote,
          bill_to_payment_type: 'SENDER',
          dropoff_type: 'REGULAR_PICKUP'
        };
      } else if (ups_direct_quote) {
        shipmentForForm.ups_specific_options = {
          ...rfq.ups_specific_options,
          ...shipment.ups_specific_options,
          ...ups_direct_quote,
          bill_to_payment_type: 'SENDER'
        };
      } else if (usps_direct_quote) {
        shipmentForForm.usps_specific_options = {
          ...rfq.usps_specific_options,
          ...shipment.usps_specific_options,
          ...usps_direct_quote,
          bill_to_payment_type: 'SENDER'
        };
      }
    }

    if (equipment_type) {
      equipment_types.push(equipment_type);
    }

    selectQuote(quote);
    selectFormShipment(transformShipmentToForm(shipmentForForm, hazmatCodes, [mode], equipment_types, true));

    router.push(`/shipments/${shipment.id}/confirm?mode=instant-rates&parcel=true`);
  };

  useEffect(() => {
    const getParentRFQ = (shipment = {}) => {
      const {rfqs = []} = shipment;

      if (rfqs.length > 0) {
        return rfqs.filter((rfq) => !rfq.has_parent_rfq).sort((a, b) => b.updated_at - a.updated_at)[0];
      }
      return null;
    };

    const getNumberOfPackages = (lineItems = []) => {
      return lineItems.reduce((totalCount, li) => {
        return (totalCount += li.total_packages);
      }, 0);
    };

    const getParcelShipmentDetails = async (shipmentId) => {
      try {
        const response = await getShipmentDetails(shipmentId);
        if (response?.details) {
          setShipment(response.details);
          setPackageType(getParcelPackageType(response.details.line_items));
          setNumberOfPackages(getNumberOfPackages(response.details.line_items));
          getRfqDetailsCallback(getParentRFQ(response.details));
          setLoading(false);
          return response.details;
        }
      } catch (error) {
        setErrorFetchingShipment(error);
      }
    };

    getParcelShipmentDetails(params.shipment_id);
  }, [params.shipment_id, getShipmentDetails, getRfqDetailsCallback]);

  useEffect(() => {
    // set package types in store
    fetchFedexPackageTypes();
    fetchUPSPackageTypes();
    fetchUSPSPackageTypes();
  }, [fetchFedexPackageTypes, fetchUPSPackageTypes, fetchUSPSPackageTypes]);

  return (
    <div className="marketplace">
      {errorFetchingShipment ? (
        <h2 className="text-center">
          There was an error getting the details of this shipment. Please refresh your page or check the URL.
        </h2>
      ) : loading || tabsToDisplay.length < 1 ? (
        <div style={{height: '100vh'}}>
          <ShipwellLoader loading={loading || tabsToDisplay.length < 1} />
        </div>
      ) : (
        <div className="marketplace-wrapper">
          <PageHeader
            title={`Shipment ${shipment.reference_id}`}
            backRoute={backRoute || `/shipments/${shipment.id}/edit`}
            actions={
              <div className="marketplace__header-status">
                <h3>
                  {shipment.state === 'carrier_confirmed'
                    ? 'Carrier Confirmed'
                    : getReadableShipmentStatus(shipment.parcel_pickup_status)}
                </h3>
              </div>
            }
          ></PageHeader>
          {tabsToDisplay.length > 0 && (
            <div className="marketplace-container">
              <SideNav>
                {tabsToDisplay.includes('fedex-rates') && (
                  <Link
                    to={`/marketplace/${shipment.id}/fedex-rates`}
                    activeClassName="active"
                    className={quotes.fedex ? '' : 'disabled'}
                  >
                    FedEx
                  </Link>
                )}
                {tabsToDisplay.includes('ups-rates') && (
                  <Link
                    to={`/marketplace/${shipment.id}/ups-rates`}
                    activeClassName="active"
                    className={quotes.ups ? '' : 'disabled'}
                  >
                    UPS
                  </Link>
                )}
                {tabsToDisplay.includes('usps-rates') && (
                  <Link
                    to={`/marketplace/${shipment.id}/usps-rates`}
                    activeClassName="active"
                    className={quotes.usps ? '' : 'disabled'}
                  >
                    USPS
                  </Link>
                )}
              </SideNav>
              <div className="marketplace-content">
                <div className="rates-container">
                  <Link to={`/shipments/${shipment.id}/edit`} className="flex justify-end pb-3">
                    Edit Quote
                  </Link>
                  {cloneElement(children, {
                    rfq: rfq,
                    quotes: quotes,
                    packageType: packageType,
                    numberOfPackages: numberOfPackages,
                    handleSelectQuote: handleSelectQuote,
                    onAccountChange: handleAccountChange
                  })}
                </div>
                <Paper className="dashboard-container">
                  <DashboardSummary
                    shipment={shipment}
                    customer={shipment.customer}
                    company={company}
                    isLoadBoard={false}
                    isCarrierDetailView={false}
                  />
                </Paper>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    company: state.auth.company,
    hazmatCodes: state.shipments.hazmatCodes
  }),
  {
    ...shipmentActions,
    fetchFedexPackageTypes,
    fetchUPSPackageTypes,
    fetchUSPSPackageTypes
  }
)(ParcelMarketplace);
