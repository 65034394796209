import {PostCompaniesCompanyidUsers} from '@shipwell/backend-core-singlerequestparam-sdk';

/**
 * Calls to `usersApi.companiesCompanyIdUsersUserIdPut` and `usersApi.companiesCompanyIdUsersUserIdPost` will fail on
 * the backend unless it receives a FormData object. This function makes this transformation.
 */
export const transformRequestPostCompaniesCompanyidUsersBody = (body: PostCompaniesCompanyidUsers) => {
  const formData = new FormData();
  const fields = Object.keys(body) as (keyof PostCompaniesCompanyidUsers)[];

  fields.forEach((field) => {
    if (field === 'avatar') {
      // `avatar` is of type `any`, but null or undefined avatars cause a backend failure.
      // Check to see if STM - 2567 was ever completed.If so, this can be cleaned up
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (body[field]) formData.append(field, body[field]);

      return;
    }

    const fieldValue = body[field];

    if (Array.isArray(fieldValue)) {
      fieldValue.forEach((permission) => {
        formData.append(field, permission);
      });
      return;
    }
    if (typeof fieldValue === 'boolean') {
      formData.append(field, fieldValue.toString());
      return;
    }
    if (fieldValue) {
      formData.append(field, fieldValue);
      return;
    }
  });

  return formData;
};
