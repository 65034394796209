import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {change, reduxForm, formValueSelector, Field} from 'redux-form';
import {Button, Row, Col, Form} from 'react-bootstrap';
import * as actions from '../../actions/shipments';
import * as carrierActions from '../../actions/vendors';
import * as brokerActions from '../../actions/brokers';
import {Financials} from 'App/containers/Book/FormSections/Financials';
import renderTextArea from 'App/formComponents/renderTextArea';
import renderAsyncSelect from 'App/formComponents/renderAsyncSelect';
import './_create-manual-rate.scss';
import validate from 'App/utils/validateManualRateForm';
import {formatCurrencyValue} from 'App/utils/globals';

@connect(
  (state) => ({
    selectedQuote: state.shipments.selectedQuote,
    authenticated: state.auth.authenticated,
    selectedShipment: state.shipments.selectedShipment,
    company: state.auth.company,
    carrierRelationships: state.vendors.carrierRelationships,
    getSpotQuotes: state.form.getSpotQuotes,
    chargeCategories: state.shipments.chargeCategories,
    createManualRate: state.form.createManualRate
  }),
  {...actions, ...carrierActions, ...brokerActions}
)
class CreateManualRate extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);

    this.dispatchMarkupChange = this.dispatchMarkupChange.bind(this);
    this.getCustomerOptions = this.getCustomerOptions.bind(this);
    this.state = {
      setMargin: '',
      pagination: {
        page: 1,
        pageSize: 25,
        ordering: 'company',
        q: '',
        expandUsers: true
      }
    };
  }

  componentWillReceiveProps(nextProps) {
    let carrierTotal = 0;
    if (nextProps.financialTotals && nextProps.financialTotals.length > 0) {
      carrierTotal = nextProps.financialTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur));
    }

    let customerTotal = '$' + formatCurrencyValue(carrierTotal);
    if (nextProps.markup) {
      customerTotal = '$' + formatCurrencyValue(parseFloat(nextProps.markup) + parseFloat(carrierTotal));
    }
    this.props.setTotal(customerTotal);
    if (this.props.markup !== nextProps.markup) {
      let carrierTotal = 0;
      let calculatedMargin = 0;
      if (nextProps.financialTotals && nextProps.financialTotals.length > 0) {
        carrierTotal = nextProps.financialTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur));
      }
      calculatedMargin = parseFloat((parseFloat(nextProps.markup) / carrierTotal) * 100).toFixed(2);
      if (!isNaN(calculatedMargin) && isFinite(calculatedMargin)) {
        this.setState({setMargin: calculatedMargin + '%'});
      }
    }

    //check if financial line items have changed, default descriptions when category is selected;
    if (
      this.props.createManualRate &&
      this.props.createManualRate.values &&
      this.props.createManualRate.values.financials &&
      nextProps.createManualRate &&
      nextProps.createManualRate.values &&
      nextProps.createManualRate.values.financials
    ) {
      const oldValues = this.props.createManualRate.values.financials;
      const newValues = nextProps.createManualRate.values.financials;

      for (var i = 0; i < newValues.length; i++) {
        if (
          newValues[i].category &&
          (!oldValues[i] ||
            (oldValues[i] && oldValues[i].category && newValues[i].category !== oldValues[i].category) ||
            (oldValues[i] && !oldValues[i].category))
        ) {
          if (
            !newValues[i].unit_name ||
            nextProps.chargeCategories.filter((c) => c.name === newValues[i].unit_name).length > 0
          ) {
            const description = nextProps.chargeCategories.filter((c) => c.id === newValues[i].category)[0].name;
            this.props.dispatch(change('createManualRate', 'financials[' + i + '].unit_name', description));
          }
        }
      }
    }
  }

  dispatchMarkupChange(margin) {
    if (parseFloat(margin)) {
      let carrierTotal = 0;
      let calculatedMarkup = 0;
      if (this.props.financialTotals && this.props.financialTotals.length > 0) {
        carrierTotal = this.props.financialTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur));
      }
      calculatedMarkup = parseFloat(parseFloat(margin / 100) * carrierTotal).toFixed(2);
      if (!isNaN(calculatedMarkup)) {
        this.props.dispatch(change('createManualRate', 'markup', calculatedMarkup));
      }
    }
  }

  setEmailRequired(bool) {
    this.props.dispatch(change('createManualRate', 'emailRequired', bool));
  }

  getCustomerOptions(input) {
    const pagination = this.state.pagination;
    if (input) {
      pagination.q = input;
      pagination.pageSize = null;
    }
    return this.props
      .fetchBrokerShipperRelationshipsForTypeaheads(this.props.company.brokerage.id, pagination)
      .then((response) => {
        if (response.status === 200) {
          const POCs = [];
          response.details.results.forEach((item) => {
            if (item.company && item.company.id !== this.props.company.id && item.company.users.length > 0) {
              item.company.users.forEach((poc) => {
                const nameStr =
                  item.company.name +
                  ' - ' +
                  poc.first_name +
                  (poc.last_name ? ' ' + poc.last_name : '') +
                  ' (' +
                  poc.email +
                  ')';
                POCs.push({id: poc.id, name: nameStr});
              });
            }
          });
          return {results: POCs};
        }
      });
  }

  render() {
    const {submitting, selectedShipment} = this.props;

    return (
      <Row>
        <Form onSubmit={this.props.handleSubmit}>
          <Col xs={12} sm={6}>
            <h3>Ready to schedule this shipment now?</h3>
            <div className="create-manual-rate formstyle-light">
              <Financials
                dispatchMarkupChange={this.dispatchMarkupChange}
                onSubmit={this.onFormSubmit}
                financialTotals={this.props.financialTotals}
                markup={this.props.markup}
                setMargin={this.state.setMargin}
              />
              <div className="create-manual-rate__footer">
                <Button
                  disabled={submitting}
                  onMouseDown={() => this.setEmailRequired(false)}
                  onClick={this.props.handleSubmit((values) => this.props.onSubmit({...values, option: 'book-now'}))}
                  bsStyle="primary"
                >
                  <i className="icon icon-Truck pad-right" /> Continue Booking
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <h3>Need to send this quote to your customer?</h3>
            <div className="send-customer-quote">
              {selectedShipment && (
                <div>
                  <h5>{'Send Quote #' + selectedShipment.reference_id}</h5>
                  {this.props.authenticated && (
                    <Field
                      component={renderAsyncSelect}
                      getOptions={this.getCustomerOptions}
                      name="customers"
                      label="Customers"
                      isMulti
                      valueField="id"
                      labelField="name"
                      placeholder="Search for a customer user"
                    />
                  )}
                  <Field
                    label="Message"
                    name="message"
                    minRows={2}
                    component={renderTextArea}
                    placeholder="Enter message here"
                  />
                </div>
              )}
            </div>
            <div className="create-manual-rate__footer">
              <Button onClick={() => this.props.showPreview()} bsStyle="default">
                <i className="icon icon-Mail pad-right" />
                Preview Email
              </Button>
              <Button
                disabled={submitting}
                onClick={this.props.handleSubmit((values) => this.props.onSubmit({...values, option: 'send-quote'}))}
                bsStyle="primary"
              >
                <i className="icon icon-Plane pad-right" /> Send Quote
              </Button>
            </div>
          </Col>
        </Form>
      </Row>
    );
  }
}

CreateManualRate = reduxForm({
  form: 'createManualRate',
  validate: validate,
  enableReinitialize: true
})(CreateManualRate);

CreateManualRate = connect((state) => ({
  initialValues: {
    financials: [{unit_quantity: 1, category: 'LH'}],
    emailRequired: true,
    message: state.marketplace.spotNegotiationEmailPreview && state.marketplace.spotNegotiationEmailPreview.message
  }
}))(CreateManualRate);

const selector = formValueSelector('createManualRate');

CreateManualRate = connect((state) => {
  const financialData = selector(state, 'financials');
  const financialTotals = [];
  if (financialData) {
    for (var i = 0; i < financialData.length; i++) {
      if (financialData[i] && financialData[i].unit_amount && financialData[i].unit_quantity) {
        let value = financialData[i].unit_amount;
        if (value && typeof value === 'string' && value.startsWith('$')) {
          value = value.slice(1);
        }
        if (value && typeof value === 'string') {
          value = value.replace(/,/g, '');
        }

        const valToAdd = parseFloat(value * financialData[i].unit_quantity);

        if (!isNaN(valToAdd)) {
          financialTotals.push(valToAdd);
        } else {
          financialTotals.push(0);
        }
      } else {
        financialTotals.push(0);
      }
    }
  }
  let markup = selector(state, 'markup');

  if (markup && markup.startsWith('$')) {
    markup = markup.slice(1);
  }
  if (markup) {
    markup = markup.replace(/,/g, '');
  }
  return {
    financialTotals,
    markup
  };
})(CreateManualRate);
export default CreateManualRate;
