import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Formik, Form, Field} from 'formik';
import {object, string, mixed} from 'yup';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import toUpper from 'lodash/toUpper';
import {
  FormikSelect,
  FormikTextInput,
  FormikDateTimePicker,
  FormikCollapsibleGroupSelect,
  DeprecatedButton
} from '@shipwell/shipwell-ui';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import {createStopAlert} from 'App/api/shipment';
import {getShipmentTimeline, shipmentsShipmentIdGet} from 'App/actions/_shipmentDetails';
import {stopFlagAlertTypes} from 'App/utils/stopFlag';
import makeLabelForOptionObject from 'App/utils/makeLabelForOptionObject';
import useGroupedStopStatusReasonCodes from 'App/api/shipment/useGroupedStopStatusReasonCodes';
import './styles.scss';

const flagValidationSchema = object().shape({
  alert_type: string().nullable().required('A flag type is required.'),
  reason_code: string().nullable().required('A reason is required.'),
  description: string().nullable().max(30, 'Maximum of 30 characters.')
});

const StopFlagsCreate = ({shipment, stop, onCancel, onSubmit, onError, dispatch}) => {
  const groupedStopStatusReasonCodes = useGroupedStopStatusReasonCodes();

  const handleSubmit = async (values) => {
    const payload = {
      alert_type: toUpper(values.alert_type),
      description: values.description,
      expiration: values.expiration,
      location: values.location,
      reason_code: toUpper(values.reason_code)
    };
    try {
      const response = await createStopAlert(shipment.id, stop.id, payload);
      await Promise.all([dispatch(getShipmentTimeline(shipment.id)), dispatch(shipmentsShipmentIdGet(shipment.id))]);
      onSubmit(response);
    } catch (e) {
      console.error('Error creating stop flag', e);
      onError({
        title: 'Error creating stop flag!',
        error: get(e, 'error_description', 'There was an error creating the stop flag.')
      });
    }
  };

  return (
    <div className="shipment__stopflags-create">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={flagValidationSchema}
        initialValues={{
          alert_type: '',
          reason_code: ''
        }}
      >
        {({isSubmitting, dirty, isValid}) => (
          <Form>
            <div className="shipment__stopflags-create-field">
              <Field
                component={FormikSelect}
                name="alert_type"
                label="Flag Type"
                simpleValue
                options={stopFlagAlertTypes}
                getOptionLabel={makeLabelForOptionObject}
              />
            </div>
            <div className="shipment__stopflags-create-field">
              <Field
                component={FormikCollapsibleGroupSelect}
                name="reason_code"
                label="Reason"
                simpleValue
                options={groupedStopStatusReasonCodes}
                getOptionLabel={makeLabelForOptionObject}
                maxMenuHeight={500}
              />
            </div>
            <div className="shipment__stopflags-create-field">
              <Field component={FormikTextInput} name="description" label="Description" />
            </div>
            <div className="shipment__stopflags-create-field">
              <Field
                name="location"
                label="Location"
                searchAddressBook
                searchMaps
                searchStateProvince
                component={AddressSearch}
              />
            </div>
            <div className="shipment__stopflags-create-field">
              <Field
                label="Expiration"
                name="expiration"
                component={FormikDateTimePicker}
                timezone={get(stop, 'location.address.timezone')}
                defaultValue={new Date()}
              />
            </div>
            <div className="shipment__stopflags-create-buttons">
              <DeprecatedButton variant="secondary" disabled={isSubmitting} onClick={onCancel}>
                Cancel
              </DeprecatedButton>
              <DeprecatedButton type="submit" disabled={isSubmitting || !dirty || !isValid} loading={isSubmitting}>
                Save
              </DeprecatedButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

StopFlagsCreate.propTypes = {
  shipment: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  stop: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  dispatch: PropTypes.func
};

StopFlagsCreate.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  onError: () => {},
  dispatch: () => {}
};

export default connect((state) => ({
  shipment: state.shipmentdetails.one
}))(StopFlagsCreate);
