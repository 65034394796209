import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useFormikContext, Field} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {connect} from 'react-redux';
import {PROPTYPE_COMPANY} from '../../../utils/propTypeConstants';
import {useBrokerCustomers} from 'App/data-hooks';

/**
 * Customer account form field that requires a Formik context provider.
 */
const CustomerAccountField = ({company, name, label, ...props}) => {
  const {setFieldValue} = useFormikContext();

  const {
    context: {customers, getCustomers}
  } = useBrokerCustomers(company);
  useEffect(() => {
    // If the customer endpoint comes back with a list of length 1, then we know the user does not have any other customers.
    // As a result, we default to the single available customer, the house account.
    if (customers?.length === 1) {
      setFieldValue(name, customers[0]);
    }
  }, [customers, name, setFieldValue]);
  return customers?.length > 1 ? (
    <Field
      name={name}
      label={label}
      component={FormikSelect}
      async
      defaultOptions={customers}
      getOptionLabel={(option) => option.company?.name}
      loadOptions={async (input) => {
        const response = await getCustomers({companyContains: input});
        return response?.body?.results;
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  ) : null;
};

CustomerAccountField.propTypes = {
  company: PROPTYPE_COMPANY,
  name: PropTypes.string,
  label: PropTypes.string
};

CustomerAccountField.defaultProps = {
  name: 'customer',
  label: 'Customer Account'
};

export default connect((state) => ({company: state.userCompany.company}))(CustomerAccountField);
