import {ContractAdditionalStopChargesTypeEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {RATE_TYPE_FLAT_RATE, RATE_TYPE_PER_HOUR} from 'App/containers/userCompany/rateTables/constants';
import {formatAddresses} from 'App/containers/workflows/utils';

export const formatContractValuesBeforeSubmit = (values) => {
  values.origins = formatAddresses(values.origins);
  values.destinations = formatAddresses(values.destinations);
  if (values.rate_type === RATE_TYPE_FLAT_RATE) {
    //minimum rate isnt valid when we have a flat rate
    values.minimum_rate = null;
  }
  if (values.service_level_agreement && values.service_level_agreement.id) {
    values.service_level_agreement = values.service_level_agreement.id;
  }
  if (values.fuel_surcharge_table?.id) {
    values.fuel_surcharge_table = values.fuel_surcharge_table.id || values.fuel_surcharge_table;
  }

  // if additional_stop_charges_type is Custom, remove additional_stop_charge_flat_fee
  if (values.additional_stop_charges_type === ContractAdditionalStopChargesTypeEnum.CustomStopCharges) {
    values.additional_stop_charge_flat_fee = '';
  }
  // if additional_stop_charges_type is Charge per stop, remove additional_stop_charges on each additional stop
  if (
    values.additional_stop_charges_type === ContractAdditionalStopChargesTypeEnum.ChargePerAdditionalStop &&
    values.additional_stops?.length
  ) {
    values.additional_stops = values.additional_stops.map((additionalStop) => {
      additionalStop.additional_stop_charge = '';
      return additionalStop;
    });
  }

  // handle per hour contract rate type
  if (
    (values.rate_type === RATE_TYPE_PER_HOUR &&
      [false, 'false'].includes(values.calculate_per_hour_rate_automatically)) ||
    values.rate_type !== RATE_TYPE_PER_HOUR
  ) {
    values.per_hour_start_field = null;
    values.per_hour_end_field = null;
  }

  return values;
};
