import {FieldArray} from 'formik';
import {DisplayTypes, StopTypeType} from './constants';

const StopContactsFields = ({stopType, display}: {stopType: StopTypeType; display: DisplayTypes}) => {
  const StopComponent = stopType.component;
  return (
    <div className="flex flex-col gap-4">
      <FieldArray
        name={`${stopType.id}.point_of_contacts`}
        render={(arrayHelpers) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <StopComponent stopType={stopType} display={display} {...arrayHelpers} />
        )}
      />
    </div>
  );
};

export default StopContactsFields;
