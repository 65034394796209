import {
  EquipmentType,
  ShipmentLineItem,
  Stop,
  TotalWeightOverride
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Card, CollapsibleCardContent, IconButton, Modal, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router';
import {isNull} from 'lodash';
import OriginAndDestinationBanner from 'App/common/OriginAndDestinationBanner';
import {
  getExternalDATRatesList,
  getStopAddress,
  mapContracts,
  mapHistoricalPrices
} from 'App/containers/Shipment/components/EmbeddedPricingIntel/utils';
import ExternalRates from 'App/containers/Shipment/components/EmbeddedPricingIntel/ExternalRates';
import {
  useCarriersPriceHistory,
  useDatPricing,
  useGetPIContracts,
  useGetPricingForecast,
  useTotalDistance
} from 'App/data-hooks';
import {
  DAT_RATE_TYPE_CONTRACT,
  DAT_RATE_TYPE_SPOT,
  PRICING_INTEL_REQUIRED_ADDRESS_KEYS
} from 'App/components/pricingIntelChart/pricingIntelConstants';
import useToggle from 'App/utils/hooks/useToggle';
import PricingIntelCalculatorForm from 'App/components/pricingIntelChart/components/pricingIntelCalculator';
import {DEFAULT_DAT_EQUIPMENT} from 'App/containers/Shipment/components/EmbeddedPricingIntel';
import {getStringifiedStopsWithRequiredKeys} from 'App/components/pricingIntelChart/utils';
import ShipwellLoader from 'App/common/shipwellLoader';
import RatesTable from 'App/components/NewShipmentEmbeddedPricingIntel/RatesTable';

export enum PricingIntelTableKindEnum {
  contracts = 'CONTRACT_PRICING',
  history = 'PRICING_HISTORY'
}

const ContractRatesTooltipText = () => {
  return (
    <div>
      <div className="text-bold">Contract Rates</div>
      <div>This shows up to 5 contract rates, to see the full list select View Pricing Details.</div>
    </div>
  );
};

const LaneHistoryTooltipText = () => {
  return (
    <div>
      <div className="text-bold">Lane History</div>
      <div>This shows up to the last 5 lane runs, to see the full history select View Pricing Details.</div>
    </div>
  );
};

const NewShipmentEmbeddedPricingIntel = ({
  stops = [],
  equipmentType,
  lineItems,
  totalWeightOverride
}: {
  stops?: Stop[];
  equipmentType: EquipmentType;
  lineItems?: ShipmentLineItem[];
  totalWeightOverride?: TotalWeightOverride;
}) => {
  const [pricingSource, setPricingSource] = useState(DAT_RATE_TYPE_SPOT);
  const [showCalculator, toggleShowCalculator] = useToggle(false);
  const [initialRate, setInitialRate] = useState<string | null>(null);
  const [tableKind, setTableKind] = useState<PricingIntelTableKindEnum>(PricingIntelTableKindEnum.contracts);

  // To show Embedded Pricing Intel we need 2 stops (not more) with valid addresses to get rates.
  // Check address keys > 1 is for valid stops, since initialy location.address is {}, but if user
  // will choose address and then remove it, location.address will be {company:undefined}, so checking keys for >1
  const stopsArr = useMemo(
    () => (stops.length === 2 && stops.every((stop) => Object.keys(stop?.location?.address).length > 1) ? stops : []),
    [stops]
  );
  const selectedEquipmentType = useMemo(
    () => (equipmentType?.id ? equipmentType : DEFAULT_DAT_EQUIPMENT),
    [equipmentType]
  );
  const isContractsTableKindSelected = tableKind === PricingIntelTableKindEnum.contracts;

  const stopsPayload = stopsArr.map((s) => s.location);

  const {isFetchingDistance, totalDistance} = useTotalDistance({stops: stopsPayload});

  const {DpmToggle, showDPM, DATResponse, fetchDatRates, isLoadingDatRates, isDatIntegrated} = useDatPricing({
    equipmentType: selectedEquipmentType,
    stops: stopsArr,
    rateType: pricingSource as typeof DAT_RATE_TYPE_SPOT | typeof DAT_RATE_TYPE_CONTRACT
  });

  useEffect(() => {
    if (stopsArr.length) {
      void fetchDatRates();
    }
  }, [fetchDatRates, stopsArr, pricingSource, selectedEquipmentType]);

  const {applicableContractsQuery, applicableContracts} = useGetPIContracts({
    equipmentType: selectedEquipmentType,
    stops: stopsPayload,
    lineItems,
    totalWeightOverride,
    pageSize: 5,
    options: {enabled: isContractsTableKindSelected && stopsArr.length > 0}
  });
  const isContractsLoading = applicableContractsQuery.isInitialLoading;

  const stringifiedStops = getStringifiedStopsWithRequiredKeys(stopsPayload, PRICING_INTEL_REQUIRED_ADDRESS_KEYS);
  const params = {
    page: 1,
    limit: 5
  };
  const {isInitialLoading: isPricingForecastLoading, data: pricingForecast} = useGetPricingForecast({
    stops: stopsArr,
    selectedEquipmentType
  });
  const {data: historicalPriceData, isInitialLoading: isLoadingPrices} = useCarriersPriceHistory({
    equipmentType: selectedEquipmentType.machine_readable,
    stringifiedStops,
    params
  });

  const priceData = historicalPriceData?.data.prices || [];

  const confidenceCategory = pricingForecast?.confidence_category || '--';
  const response = DATResponse?.rateResponses?.[0]?.response;
  const averageFuelSurchargePerTripUsd = response?.rate?.averageFuelSurchargePerTripUsd || 0;
  const averageFuelSurchargePerMileUsd = response?.rate?.averageFuelSurchargePerMileUsd || 0;

  const externalDATRates = getExternalDATRatesList({
    isDatIntegrated: Boolean(isDatIntegrated),
    ratesPerMile: response?.rate?.perMile,
    ratesPerTrip: response?.rate?.perTrip
  });
  const isLoading = isFetchingDistance || isLoadingDatRates;
  const isDisabledCalculator = externalDATRates.every((val) => val.rate === 0);
  const isLoadingTableData = isContractsLoading || isLoadingPrices || isPricingForecastLoading;
  return (
    <>
      <Card
        isCollapsible
        isCollapsed={false}
        title="Pricing Intel"
        draggableProvided={null}
        actions={
          stopsArr.length ? (
            <Link
              to={{
                pathname: '/pricing-intel',
                state: {
                  origin: stopsArr?.[0]?.location,
                  destination: stopsArr?.[1]?.location,
                  equipment: selectedEquipmentType
                }
              }}
              className="mr-2"
            >
              View Pricing Details
            </Link>
          ) : null
        }
        bodyClassName="p-0"
      >
        <CollapsibleCardContent>
          <div>
            {stopsArr.length ? (
              <>
                <div className="grid grid-cols-2 gap-4 px-6 pt-4">
                  <ExternalRates
                    isDatIntegrated={Boolean(isDatIntegrated)}
                    DpmToggle={DpmToggle}
                    pricingSource={pricingSource}
                    setPricingSource={setPricingSource}
                    isLoading={isLoading}
                    showDPM={showDPM}
                    stopsArr={stopsArr}
                    totalMiles={totalDistance}
                    averageFuelSurchargePerTripUsd={averageFuelSurchargePerTripUsd}
                    averageFuelSurchargePerMileUsd={averageFuelSurchargePerMileUsd}
                    toggleShowCalculator={toggleShowCalculator}
                    setInitialRate={setInitialRate}
                    selectedExternalRates={externalDATRates}
                    isDisabledCalculator={isDisabledCalculator}
                    datResponse={DATResponse}
                    showOnlyDatRates
                  />
                  <div>
                    <div className="flex h-10 items-center justify-between">
                      <div className="flex">
                        <div className="text-bold mr-1">
                          {isContractsTableKindSelected ? 'Contract Pricing' : 'Lane History'}
                        </div>
                        <Tooltip
                          placement="top"
                          tooltipClassname="w-72"
                          tooltipContent={
                            isContractsTableKindSelected ? <ContractRatesTooltipText /> : <LaneHistoryTooltipText />
                          }
                          trigger="hover"
                          portal
                        >
                          <SvgIcon color="$sw-icon" name="InfoOutlined" />
                        </Tooltip>
                      </div>
                      <div className="flex">
                        <IconButton
                          iconName="ArrowSmallLeft"
                          aria-label="Show contract pricing"
                          disabled={isContractsTableKindSelected}
                          onClick={() => setTableKind(PricingIntelTableKindEnum.contracts)}
                        />
                        <IconButton
                          iconName="ArrowSmallRight"
                          aria-label="Show lane history"
                          disabled={tableKind === PricingIntelTableKindEnum.history}
                          onClick={() => setTableKind(PricingIntelTableKindEnum.history)}
                        />
                      </div>
                    </div>
                    {isLoadingTableData ? (
                      <ShipwellLoader loading additionalClassNames={['h-[184px]']} />
                    ) : (
                      <RatesTable
                        tableData={
                          isContractsTableKindSelected
                            ? mapContracts(applicableContracts)
                            : mapHistoricalPrices(priceData)
                        }
                        tableKind={tableKind}
                        showDPM={showDPM}
                        totalMiles={totalDistance}
                      />
                    )}
                  </div>
                </div>
                <div className="my-3 mr-6 flex items-center justify-end">
                  <div className="font-bold">Price Volatility:</div>
                  <div>&nbsp;{confidenceCategory}</div>
                </div>
                <OriginAndDestinationBanner
                  origin={getStopAddress(stopsArr, 0)}
                  destination={getStopAddress(stopsArr, stopsArr.length - 1)}
                />
              </>
            ) : (
              <div className="flex h-32 items-center justify-center font-bold">
                Please specify a lane to retrieve data
              </div>
            )}
          </div>
        </CollapsibleCardContent>
      </Card>
      <Modal
        show={showCalculator && !isNull(initialRate)}
        onClose={() => {
          toggleShowCalculator();
          setInitialRate(null);
        }}
        footerComponent={null}
        title="Calculator"
        size="small"
      >
        <PricingIntelCalculatorForm
          rates={externalDATRates}
          initialRate={initialRate}
          averageFuelSurchargePerTripUsd={averageFuelSurchargePerTripUsd}
        />
      </Modal>
    </>
  );
};

export default NewShipmentEmbeddedPricingIntel;
