import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {DisplayValue, Title, Card, DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import {ReferencesModal} from 'App/containers/shipments/components/ReferencesFields';
import {useBrokerCustomers, useV3Shipment} from 'App/data-hooks';
import useToggle from 'App/utils/hooks/useToggle';
import {PROPTYPE_COMPANY, PROPTYPE_DRAGGABLE_PROVIDED} from 'App/utils/propTypeConstants';
import getNil from 'App/utils/getNil';
import Loader from 'App/common/shipwellLoader';

const PaddedDisplayValue = ({label, value}) => (
  <DisplayValue className="pb-2" variant="sectionTitle" label={label}>
    {value}
  </DisplayValue>
);

PaddedDisplayValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

const FullReferencesCardBody = ({shipmentId, company, expanded}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  const {
    context: {getCustomerOptionFromId},
    isLoading: customersIsLoading
  } = useBrokerCustomers(company);

  if (shipmentQuery.isInitialLoading || customersIsLoading) {
    return <Loader loading />;
  }

  const {referenceDisplayValues, modeSpecificDisplayValues} = shipmentQuery.context;
  const {customerReferenceNumber, poNumber, bolNumber, houseBolNumber, bookingNumber} = referenceDisplayValues;
  const {isImport} = modeSpecificDisplayValues;

  const customerId = get(shipmentQuery, 'context.customerId');
  const customerObject = getCustomerOptionFromId(customerId);
  const customer = getNil(customerObject, 'company.name', '--');

  return (
    <>
      <Title className="pb-2" variant="sectionTitle">
        Customer
      </Title>
      <div className="grid grid-cols-2 gap-4">
        <PaddedDisplayValue label="Customer" value={customer} />
        <PaddedDisplayValue label="Customer Reference #" value={customerReferenceNumber} />
      </div>
      {expanded ? (
        <div className="border-t border-sw-border pt-4">
          <Title className="pb-2" variant="sectionTitle">
            Reference Numbers
          </Title>
          <div className="grid grid-cols-2 gap-4">
            {isImport ? (
              <>
                <PaddedDisplayValue label="BOL #" value={bolNumber} />
                <PaddedDisplayValue label="House BOL #" value={houseBolNumber} />
              </>
            ) : (
              <PaddedDisplayValue label="Booking #" value={bookingNumber} />
            )}
            <PaddedDisplayValue label="PO #" value={poNumber} />
          </div>
        </div>
      ) : null}
    </>
  );
};

FullReferencesCardBody.propTypes = {
  company: PROPTYPE_COMPANY,
  expanded: PropTypes.bool,
  shipmentId: PropTypes.string
};

FullReferencesCardBody.defaultProps = {
  expanded: true
};

export const ConnectedFullReferencesCardBody = connect((state) => ({
  company: state.userCompany.company
}))(FullReferencesCardBody);

export const FullReferences = ({shipmentId, draggableProvided}) => {
  const [showEditState, toggleShowEditState] = useToggle();

  return (
    <>
      <Card
        title="References"
        className="mb-2"
        isCollapsible
        actions={
          <DeprecatedButton variant="icon" onClick={toggleShowEditState}>
            <SvgIcon name="Edit" color="$sw-icon" />
          </DeprecatedButton>
        }
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        draggableProvided={draggableProvided}
      >
        <ConnectedFullReferencesCardBody shipmentId={shipmentId} />
      </Card>
      {showEditState ? (
        <ReferencesModal showModal={showEditState} shipmentId={shipmentId} onClose={toggleShowEditState} />
      ) : null}
    </>
  );
};

FullReferences.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  draggableProvided: PROPTYPE_DRAGGABLE_PROVIDED
};

const BaseReferences = ({shipmentId}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const {referenceDisplayValues, shipmentInfoDisplayValues} = shipmentQuery.context;
  const {customerReferenceNumber, poNumber} = referenceDisplayValues;
  const {shipmentName} = shipmentInfoDisplayValues;

  return (
    <div className="min-h-40">
      <PaddedDisplayValue label="Shipment Name" value={shipmentName} />
      <PaddedDisplayValue label="Customer Reference #" value={customerReferenceNumber} />
      <DisplayValue variant="sectionTitle" label="PO #">
        {poNumber}
      </DisplayValue>
    </div>
  );
};

BaseReferences.propTypes = {
  shipmentId: PropTypes.string.isRequired
};

export default BaseReferences;
