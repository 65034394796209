import {set} from 'json-pointer';
import {ShipmentAssemblyBaseError, ShipmentAssemblyBaseErrorResponse} from 'App/containers/loadOptimization/types';

export const getPointerFieldErrorKeyValues = (error: ShipmentAssemblyBaseErrorResponse) => {
  return error.errors.reduce((errorObject, error: ShipmentAssemblyBaseError) => {
    if (error.source && 'pointer' in error?.source) {
      //remove body key from pointer path
      const pointerWithBodyKeyRemoved = error.source.pointer?.replace(/body/g, '') || '';
      //check for leading backslash
      if (pointerWithBodyKeyRemoved.charAt(0) !== '/') {
        set(errorObject, '/'.concat(pointerWithBodyKeyRemoved), error.detail);
      }
      set(errorObject, pointerWithBodyKeyRemoved, error.detail);
    }
    return errorObject;
  }, {});
};
