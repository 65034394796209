import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {browserHistory} from 'react-router';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {object} from 'yup';
import {useQuery} from '@tanstack/react-query';
import get from 'lodash/get';
import {Formik, Form} from 'formik';
import {DeprecatedButton, Card, Modal, ThemeProvider, theme, TabList, Tab, TabPanel, Tabs} from '@shipwell/shipwell-ui';
import {
  fetchAccessorialChargeTablePromise,
  updateAccessorialChargeTablePromise,
  deleteAccessorialChargeTablePromise
} from 'App/api/shipment';
import WithStatusToasts, {
  WithStatusToastsPropTypes,
  WithStatusToastsDefaultProps
} from 'App/components/withStatusToasts';
import AccessorialCriteriaFields from 'App/formComponents/formSections/accessorialCriteria';
import {AccessorialSettingsFields} from 'App/formComponents/formSections/accessorialSettingsFields';
import AccessorialRateFields from 'App/formComponents/formSections/accessorialRates';
import FormFooter from 'App/formComponents/formSections/formFooter';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import PageHeader from 'App/common/pageHeader';
import {
  defaultAccessorialRatesFormValues,
  accessorialCriteriaValidationSchemaFields,
  accessorialRatesValidationSchemaFields
} from 'App/containers/userCompany/accessorialRates/validation';
import {useCarrierDetails} from 'App/data-hooks';
import {fetchBrokerShipperRelationshipsPromise} from 'App/api/brokers';
import {BROKERAGES_QUERY_KEY} from 'App/data-hooks/queryKeys';

const tabs = ['Criteria', 'Rate'];

const CompanyAccessorialRateTableDetails = ({params, router, values, company, setSuccess, setError}) => {
  const [accessorialRateTable, setAccessorialRateTable] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [isDeleting, setIsDeleting] = useState(false);
  const carrierDetails = useCarrierDetails(get(accessorialRateTable, 'carrier_relationships', []));
  const customerIds = get(accessorialRateTable, 'customers', []);

  const validationSchema = object().shape({
    ...accessorialCriteriaValidationSchemaFields,
    ...accessorialRatesValidationSchemaFields
  });

  function makeCarrierOption(carrierDetail) {
    return {
      id: carrierDetail.id,
      label: get(carrierDetail, 'shipwell_vendor.name', '')
    };
  }

  useEffect(() => {
    const getAccessorialRateTable = async (accessorialRateTableId) => {
      try {
        const response = await fetchAccessorialChargeTablePromise(accessorialRateTableId);
        if (response?.ok) {
          setAccessorialRateTable(response.body);
        }
      } catch (error) {
        console.error(error);
        setError('Unable to load Accessorial Rate Table!', error.error_description);
      }
    };
    if (params.accessorialRateTableId) {
      getAccessorialRateTable(params.accessorialRateTableId);
    }
  }, [params, setError]);

  const handleSubmit = async (values) => {
    try {
      const response = await updateAccessorialChargeTablePromise(accessorialRateTable.id, {
        ...values,
        locations: values.locations.map((location) =>
          location.value ? location.value.address || location.value : location
        ),
        carrier_relationships: values.carrier_relationships
          ? values.carrier_relationships.map((carrier) => carrier.id)
          : [],
        customers: values.customers ? values.customers.map((customer) => customer?.id) : []
      });

      if (response?.ok) {
        setAccessorialRateTable(response.body);
        setSuccess('Success', 'Accessorial Rate Table updated!');
      }
    } catch (error) {
      console.error(error);
      setError('Unable to update!', error.error_description);
    }
  };

  const handleCancel = () => {
    browserHistory.goBack();
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await deleteAccessorialChargeTablePromise(accessorialRateTable.id);
      if (response.ok) {
        setSuccess('Success', 'Accessorial Rate Table deleted!');
        router.push('/company/lane-management/accessorial-rates');
      }
    } catch (error) {
      console.error(error);
      setError('Unable to delete table!', error.error_description);
    }
    setIsDeleting(false);
  };

  const getCustomersQuery = useQuery(
    [BROKERAGES_QUERY_KEY, company?.brokerage?.id, customerIds],
    () => fetchBrokerShipperRelationshipsPromise(company.brokerage.id, {customerId: customerIds}),
    {
      enabled: company?.brokerage?.id && customerIds.length > 0
    }
  );
  const customers = getCustomersQuery?.data?.body?.results;

  const initialValues = {
    ...values,
    carrier_relationships: get(accessorialRateTable, 'carrier_relationships', []).map((carrierId) =>
      makeCarrierOption(get(carrierDetails, carrierId, {}))
    ),
    customers: customers?.map((c) => ({id: c.company.id, label: get(c, 'company.name')}))
  };

  const ThemedTabs = (props) => (
    <ThemeProvider theme={theme}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Tabs {...props} />
    </ThemeProvider>
  );

  return (
    <div className="w-full">
      {!accessorialRateTable.id ? (
        <ShipwellLoader loading />
      ) : (
        <>
          <PageHeader title={accessorialRateTable.name} backArrow />
          <div className="m-auto w-full max-w-5xl p-4">
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                ...defaultAccessorialRatesFormValues,
                ...accessorialRateTable,
                ...initialValues
              }}
              onSubmit={handleSubmit}
            >
              {({isSubmitting, values, dirty}) => (
                <Form>
                  <ThemedTabs
                    selectedIndex={tabs.indexOf(selectedTab)}
                    onSelect={(index) => setSelectedTab(tabs[index])}
                  >
                    <TabList className="pb-4">
                      {tabs.map((tab) => (
                        <Tab key={tab}>{tab}</Tab>
                      ))}
                    </TabList>
                    <TabPanel>
                      <Card title="Accessorial Criteria" className="mb-4">
                        <AccessorialCriteriaFields customerIds={customerIds} values={values} />
                      </Card>
                      <Card title="Settings" className="mb-4">
                        <AccessorialSettingsFields />
                      </Card>
                    </TabPanel>
                    <TabPanel>
                      <Card title="Accessorial Rates" className="mb-4">
                        <AccessorialRateFields values={values} />
                      </Card>
                    </TabPanel>
                    <div className="flex justify-end">
                      <DeprecatedButton variant="warning" onClick={() => setShowDeleteModal(true)}>
                        Delete Accessorial Rate Table
                      </DeprecatedButton>
                    </div>
                    {dirty ? <FormFooter isSubmitting={isSubmitting} onCancel={handleCancel} /> : null}
                  </ThemedTabs>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
      <Modal
        variant="warning"
        show={showDeleteModal}
        title="Delete Accessorial Rate Table"
        primaryBtnName="Delete Accessorial Rate Table"
        onClose={() => setShowDeleteModal(false)}
        onPrimaryAction={handleDelete}
        primaryButtonProps={{loading: isDeleting}}
      >
        <div>{`Are you sure you want to delete ${get(
          accessorialRateTable,
          'name',
          'this Accessorial Rate Table table'
        )}?`}</div>
      </Modal>
    </div>
  );
};

CompanyAccessorialRateTableDetails.propTypes = {
  params: PropTypes.shape({
    accessorialRateTableId: PropTypes.string
  }),
  company: PropTypes.shape({
    brokerage: PropTypes.object
  }),
  router: PropTypes.shape({
    push: PropTypes.func
  }),
  ...WithStatusToastsPropTypes
};

CompanyAccessorialRateTableDetails.defaultProps = {
  ...WithStatusToastsDefaultProps
};

export default compose(
  connect((state) => ({
    company: state.userCompany.company
  })),
  WithStatusToasts
)(CompanyAccessorialRateTableDetails);
