import _ from 'lodash';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import NewShipmentDocumentForm from './NewShipmentDocumentForm';
import * as actions from 'App/actions/documents';
import {trackSubmitUploadDocument} from 'App/utils/analytics';

@connect(
  (state) => ({
    isLoading: state.documents.isLoading,
    error: state.documents.error,
    document: state.documents.document,
    documentTypes: state.documents.documentTypes,
    company: state.auth.company,
    user: state.auth.user,
    successfulDispatch: state.shipments.successfulDispatch
  }),
  actions
)
export default class NewShipmentDocument extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.state = {
      files: []
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.company)) {
      this.props.fetchDocumentTypes();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.company && nextProps.company !== this.props.company) {
      this.props.fetchDocumentTypes();
    }
  }

  onFormSubmit(attrs) {
    //GA tracking
    trackSubmitUploadDocument(attrs.type || 'unknown');

    const shipmentId = this.props.params.shipment_id;
    const docForm = {
      description: attrs.description || '',
      type: attrs.type,
      file: attrs.files,
      isCarrierDocument: this.props.user.carrier ? true : false
    };
    return this.props.postDocument(docForm, shipmentId);
  }

  render() {
    return (
      <div className="shipment__document content-wrapper">
        <h1 className="section-header">New Document</h1>
        <section className="content">
          <NewShipmentDocumentForm
            documentTypes={this.props.documentTypes}
            onSubmit={this.onFormSubmit}
            context={this.context}
            shipmentId={this.props.params.shipment_id}
            isLoading={this.props.isLoading}
            error={this.props.error}
            goBack={() => this.context.router.push('/shipments/' + this.props.params.shipment_id)}
          />
        </section>
      </div>
    );
  }
}
