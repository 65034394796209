import PropTypes from 'prop-types';
import {DisplayValue, Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {useFlags} from 'launchdarkly-react-client-sdk';

import ShipmentCarrierInsuranceFields from 'App/formComponents/formSections/shipmentCarrierInsuranceFields';
import {calculateShipmentTotals} from 'App/utils/globalsTyped';
import {formatCurrency, METRIC} from 'App/utils/internationalConstants';
import ShipmentTotalWeightFields from 'App/formComponents/formSections/shipmentTotalWeightFields';
import ShipmentTotalValueFields from 'App/formComponents/formSections/shipmentTotalValueFields';
import ShipmentTotalQuantityFields from 'App/formComponents/formSections/shipmentTotalQuantityFields';

import './styles.scss';

/**
 * Shipment Item Totals
 * Displays the aggregate of the shipments line-items
 */

const ShipmentItemTotals = ({
  lineItems,
  totalWeight,
  totalValue,
  valueCurrency,
  totalQuantity,
  unitPreferences,
  form,
  externalForm = false,
  isSidebar = false,
  isCollapsed = false
}) => {
  const {
    units,
    volume,
    weight: calculatedWeight,
    density: calculatedDensity,
    value: calculatedValue,
    system
  } = calculateShipmentTotals({line_items: lineItems, unitPreferences, totalWeight});

  const {fiCarrierComplianceInsurance} = useFlags();

  const quantity = totalQuantity || units;

  //check if total weight is a valid number
  const weight = Number(totalWeight?.value) ? Number(totalWeight?.value) : calculatedWeight;
  const value = totalValue || calculatedValue;
  const currency = valueCurrency || unitPreferences.currency;
  const density = Number(totalWeight?.value) && volume ? Number(weight / volume) : Number(calculatedDensity);
  const showTotalOverrideFields = form;
  const showTotalValueFields = form && !externalForm;
  const showInsuranceOverrideFields = fiCarrierComplianceInsurance && form;
  const gridClassName = isSidebar ? 'grid grid-cols-2' : 'grid grid-cols-2 gap-4 md:grid-cols-6';
  const isMetricSystem = system === METRIC;
  const displayValueClassName = isSidebar ? '' : 'm-0 p-1';
  const totalPieces = lineItems.reduce((acc, item) => acc + item.total_pieces, 0);

  return (
    <Card
      title={
        isSidebar ? (
          <>
            <div className="flex items-baseline">
              <span>Totals</span>
              <span className="text-grey-5 flex items-baseline text-xxs font-normal">
                <span className="mx-1">•</span>
                <span className="inline-block truncate">
                  {weight && weight > 0
                    ? parseFloat(weight).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })
                    : null}
                  {isMetricSystem ? ' KGS' : ' LBS'}
                </span>
              </span>
            </div>
          </>
        ) : (
          'Totals'
        )
      }
      className="quote-lineitem-totals"
      isCollapsed={isCollapsed}
      isCollapsible
      cals
      bodyClassName={isSidebar ? 'p-4 empty:p-0' : undefined}
    >
      <CollapsibleCardContent>
        <>
          {showTotalValueFields || showTotalValueFields ? (
            <div className="mb-4">
              {showTotalOverrideFields ? <ShipmentTotalWeightFields form={form} /> : null}
              {showTotalValueFields ? <ShipmentTotalValueFields form={form} /> : null}
              {showInsuranceOverrideFields ? <ShipmentCarrierInsuranceFields form={form} /> : null}
              {showTotalOverrideFields ? <ShipmentTotalQuantityFields form={form} /> : null}
            </div>
          ) : null}
          <div className={`quote-lineitem-totals-body ${gridClassName}`}>
            <DisplayValue className={displayValueClassName} label="Weight">
              {weight && weight > 0 ? (
                <>
                  {parseFloat(weight).toLocaleString(undefined, {
                    minimumFractionDigits: isSidebar ? 0 : 2,
                    maximumFractionDigits: isSidebar ? 0 : 2
                  })}
                  {isMetricSystem ? ' KGS' : ' LBS'}
                </>
              ) : (
                '--'
              )}
            </DisplayValue>
            <DisplayValue className={displayValueClassName} label="Declared Value">
              {formatCurrency(value, currency)}
            </DisplayValue>
            <DisplayValue className={displayValueClassName} label="Quantity">
              {quantity && quantity > 0 ? quantity : '--'}
            </DisplayValue>
            <DisplayValue className={displayValueClassName} label="Volume">
              {volume && volume.toFixed(2) > 0 ? (
                <>
                  {volume.toFixed(2)}
                  {isMetricSystem ? ' M' : ' FT'}
                  <sup>3</sup>
                </>
              ) : (
                '--'
              )}
            </DisplayValue>
            <DisplayValue className={displayValueClassName} label="Density">
              {density && density.toFixed(2) > 0 ? (
                <>
                  {density.toFixed(2)}
                  {isMetricSystem ? ' KG/M' : ' LBS/FT'}
                  <sup>3</sup>
                </>
              ) : (
                '--'
              )}
            </DisplayValue>
            <DisplayValue className={displayValueClassName} label="Pieces">
              {totalPieces
                ? parseFloat(String(totalPieces)).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                : '--'}
            </DisplayValue>
          </div>
        </>
      </CollapsibleCardContent>
    </Card>
  );
};

ShipmentItemTotals.propTypes = {
  externalForm: PropTypes.bool,
  form: PropTypes.string,
  hasFTL: PropTypes.bool,
  hasLTL: PropTypes.bool,
  hasVLTL: PropTypes.bool,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      volume: PropTypes.number,
      units: PropTypes.number
    })
  ),
  totalValue: PropTypes.number,
  totalWeight: PropTypes.shape({
    value: PropTypes.number
  }),
  unitPreferences: PropTypes.shape({
    currency: PropTypes.string
  }),
  valueCurrency: PropTypes.string,
  isSidebar: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  totalQuantity: PropTypes.number
};

export default ShipmentItemTotals;
