import {
  CustomField,
  ProductLengthUnitEnum,
  ProductPackageType,
  ProductWeightUnitEnum,
  ShipmentLineItemLengthUnitEnum,
  ShipmentLineItemWeightUnitEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';

export const createDefaultCustomDataValues = (customFields: CustomField[]) =>
  customFields.reduce(
    (customData, cf: CustomField) => ({
      ...customData,
      [cf.id || '']: ''
    }),
    {}
  );
export const createDefaultCustomFields = (customFields: CustomField[]) =>
  customFields.reduce(
    (customData, cf: CustomField) => ({
      ...customData,
      [cf.key || '']: ''
    }),
    {}
  );
interface UnitPreferences {
  weightUnit: ProductWeightUnitEnum;
  lengthUnit: ProductLengthUnitEnum;
}
export const createDefaultLineItem = (
  customFields: CustomField[],
  unitPreferences: UnitPreferences = {
    weightUnit: ShipmentLineItemWeightUnitEnum.Lb,
    lengthUnit: ShipmentLineItemLengthUnitEnum.In
  }
) => {
  return {
    product_ref: '',
    description: '',
    package_type: ProductPackageType.Plt,
    package_weight: null,
    length: 40,
    width: 48,
    height: null,
    value_per_piece: null,
    weight_unit: unitPreferences.weightUnit,
    length_unit: unitPreferences.lengthUnit,
    stackable: false,
    refrigeration_min_temp: null,
    refrigeration_max_temp: null,
    custom_data: {
      shipwell_custom_data: createDefaultCustomDataValues(customFields),
      custom_fields: createDefaultCustomFields(customFields)
    },
    country_of_manufacture: null
  };
};
