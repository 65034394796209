import {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import get from 'lodash/get';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import pluralize from 'pluralize';
import {shipmentsShipmentIdPut, deleteScheduledCalls} from 'App/actions/shipmentdetails';
import {unpackShipmentErrors} from 'App/utils/globalsTyped';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import withStatusToasts, {
  WithStatusToastsPropTypes,
  WithStatusToastsDefaultProps
} from 'App/components/withStatusToasts';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const ArchiveAction = ({
  shipment,
  canEdit,
  onUpdateShipment,
  shipmentsShipmentIdPut,
  hasFutureScheduledCalls,
  deleteScheduledCalls,
  setError
}) => {
  const [changingArchiveStatus, setChangingArchiveStatus] = useState(false);
  const [showDeleteCallsModal, setShowDeleteCallsModal] = useState(false);

  const {archived} = get(shipment, 'metadata', {});

  const handleArchiveClick = async () => {
    setChangingArchiveStatus(true);
    try {
      const payload = {
        ...shipment,
        metadata: {
          ...shipment.metadata,
          archived: !archived
        }
      };

      const response = await shipmentsShipmentIdPut(shipment.id, payload);
      //prompt to delete any scheduled calls if the shipment is being archived
      if (!archived && hasFutureScheduledCalls) {
        setShowDeleteCallsModal(true);
      }
      onUpdateShipment(response);
    } catch (error) {
      const errorMessages = unpackShipmentErrors(error, `Error changing archive status for ${shipment.id}`, [
        'metadata.archived'
      ]);
      if (Array.isArray(errorMessages)) {
        const errorBlock = (
          <>
            <div className="flex flex-col gap-2 pb-4">
              {errorMessages.map((err, i) => (
                <div key={i}>
                  <span>{err}</span>
                </div>
              ))}
            </div>
            <span>
              {pluralize('This', errorMessages.length)} {pluralize('error', errorMessages.length)} must be corrected
              before any new changes can be saved.
            </span>
          </>
        );
        setError('Error on Shipment!', errorBlock, 'top-right', {delay: 10000});
      } else {
        setError('Error!', errorMessages, 'top-right', {delay: 10000});
      }
    } finally {
      setChangingArchiveStatus(false);
    }
  };

  return (
    <>
      <DeprecatedButton disabled={changingArchiveStatus || !canEdit} variant="text" onClick={handleArchiveClick}>
        {`${archived ? 'Unarchive' : 'Archive'} Shipment`}
      </DeprecatedButton>
      <InfoModalWrapper
        show={showDeleteCallsModal}
        onHide={() => setShowDeleteCallsModal(false)}
        title={'Delete Scheduled Calls'}
        primaryAction={{
          action: async () => {
            try {
              const response = await deleteScheduledCalls(shipment.id);
              setShowDeleteCallsModal(false);
              return response;
            } catch (e) {
              console.error(`Error deleting scheduled calls for ${shipment.id}`);
            }
          },
          label: 'Yes'
        }}
        secondaryAction={{action: () => setShowDeleteCallsModal(false), label: 'No'}}
      >
        <div>
          <p>This shipment has automated calls scheduled which may now be out of date due to the changes you made.</p>
          <p>Do you want to delete the scheduled calls?</p>
        </div>
      </InfoModalWrapper>
    </>
  );
};

ArchiveAction.propTypes = {
  shipment: PropTypes.object,
  canEdit: PropTypes.bool,
  onUpdateShipment: PropTypes.func,
  shipmentsShipmentIdPut: PropTypes.func,
  isQuotingLimitedUser: PropTypes.bool,
  hasFutureScheduledCalls: PropTypes.bool,
  deleteScheduledCalls: PropTypes.func,
  ...WithStatusToastsPropTypes
};

ArchiveAction.defaultProps = {
  shipment: {},
  canEdit: false,
  onUpdateShipment: () => {},
  shipmentsShipmentIdPut: () => {},
  isQuotingLimitedUser: false,
  hasFutureScheduledCalls: false,
  deleteScheduledCalls: () => {},
  ...WithStatusToastsDefaultProps
};

export default compose(
  withStatusToasts,
  connect(
    (state) => ({
      canEdit:
        get(state, 'userProfile.user.permissions', []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
        get(state, 'userProfile.user.permissions', []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION),
      isQuotingLimitedUser: get(state, 'userProfile.user.is_quoting_limited_user'),
      hasFutureScheduledCalls: get(state, 'shipmentdetails.hasFutureScheduledCalls')
    }),
    (dispatch) =>
      bindActionCreators(
        {
          shipmentsShipmentIdPut,
          deleteScheduledCalls
        },
        dispatch
      )
  )
)(ArchiveAction);
