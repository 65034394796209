import {Field} from 'formik';
import PropTypes from 'prop-types';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {useEquipmentTypesQuery} from 'App/utils/useEquipmentTypesQuery';

const EquipmentTypesField = ({name, required, ...props}) => {
  const {data: EquipmentTypesQueryData} = useEquipmentTypesQuery();
  return (
    <Field
      required={required}
      options={EquipmentTypesQueryData}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      label="Equipment"
      name={name || 'equipment_types'}
      isMulti
      component={FormikSelect}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

EquipmentTypesField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool
};

export default EquipmentTypesField;
