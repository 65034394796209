import PropTypes from 'prop-types';
import {Formik, Form} from 'formik';
import {object, string} from 'yup';
import {Card, DisplayValue} from '@shipwell/shipwell-ui';
import {ServiceLevelAgreementField, NotesField} from 'App/formComponents/formSections/contractFields';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {formatDate} from 'App/utils/globals';
import {startCaseToLower} from 'App/utils/startCaseToLower';

const ContractsAttachSLAForm = ({onSubmit, onCancel}) => {
  return (
    <Formik
      initialValues={{service_level_agreement: null, notes: ''}}
      validationSchema={object().shape({
        service_level_agreement: object().required().typeError('Service Level Agreement is required.'),
        notes: string().nullable()
      })}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({isSubmitting, dirty, values, isValid}) => (
        <Form className="mb-10">
          <Card title="Service Level Agreement">
            <div className="mb-4 grid">
              <ServiceLevelAgreementField required />
            </div>
            {values.service_level_agreement ? (
              <div className="mb-4 grid grid-cols-2 px-3">
                <DisplayValue label="loads awarded">{values.service_level_agreement.capacity ?? '--'}</DisplayValue>
                <DisplayValue label="interval">
                  {values.service_level_agreement.awarded_loads_interval
                    ? `Per ${startCaseToLower(values.service_level_agreement.awarded_loads_interval)}`
                    : '--'}
                </DisplayValue>
                <DisplayValue label="start date">
                  {values.service_level_agreement.start_date
                    ? formatDate(values.service_level_agreement.start_date)
                    : '--'}
                </DisplayValue>
                <DisplayValue label="end date">
                  {values.service_level_agreement.end_date ? formatDate(values.service_level_agreement.end_date) : '--'}
                </DisplayValue>
              </div>
            ) : null}
            <div className="grid">
              <NotesField />
            </div>
          </Card>
          <ModalFormFooter
            onCancel={onCancel}
            dirty={dirty}
            isSubmitting={isSubmitting}
            isValid={isValid}
            primaryActionName="Attach"
          />
        </Form>
      )}
    </Formik>
  );
};

ContractsAttachSLAForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

ContractsAttachSLAForm.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {}
};

export default ContractsAttachSLAForm;
