import {SvgIcon} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {Fragment, useState} from 'react';
import {Link} from 'react-router';
import {useOrderOptions} from 'App/containers/orders/views/OrdersListView/hooks/useOrderOptions';

const SupplierPortalOrdersExpandableNavigationMenu = ({
  onClick,
  orderOptions
}: {
  onClick?: () => void;
  orderOptions: {label: string; truncatedLabel: string; value: string; ariaLabel: string}[];
}) => {
  const [ordersExpanded, setOrdersExpanded] = useState(false);

  return (
    <Fragment key="new-order">
      <div
        role="button"
        onClick={() => setOrdersExpanded(!ordersExpanded)}
        className={classNames('flex justify-between pl-5 pr-2 items-center w-56', {
          'font-bold': ordersExpanded
        })}
      >
        <span>Order</span>
        <SvgIcon name={ordersExpanded ? 'ExpandLess' : 'ExpandMore'} />
      </div>
      {ordersExpanded
        ? orderOptions.map(({value, truncatedLabel}) => (
            <li key={truncatedLabel}>
              <Link className="pl-6 text-xs uppercase" to={value} activeClassName="active" onClick={onClick}>
                {truncatedLabel}
              </Link>
            </li>
          ))
        : null}
    </Fragment>
  );
};

export const ConditionalCreateOrderStandardNavigationLinks = ({onClick}: {onClick: () => void}): JSX.Element => {
  //we still use the flags hook here to determine whether to return the expandable
  //navigation or not. Didn't wanna overload useOrderOptions with this logic.
  const {stmV3OrdersTable, stmV3PurchaseOrder, stmSupplierPortal} = useFlags();
  const orderOptions = useOrderOptions();
  //if user has supplier portal and associated v3 table and order flags, show expandable
  //orders navigation menu.
  if (stmV3OrdersTable && stmV3PurchaseOrder && stmSupplierPortal) {
    return <SupplierPortalOrdersExpandableNavigationMenu onClick={onClick} orderOptions={orderOptions} />;
  }
  return (
    <>
      {orderOptions.map(({value, truncatedLabel}) => (
        <li key={truncatedLabel}>
          <Link key={truncatedLabel} to={value} activeClassName="active" onClick={onClick}>
            {truncatedLabel}
          </Link>
        </li>
      ))}
    </>
  );
};

export const ConditionalCreateOrderMobileNavigationLinks = (): JSX.Element => {
  const orderOptions = useOrderOptions();
  return (
    <>
      {orderOptions.map(({value, truncatedLabel}) => (
        <Link key={truncatedLabel} to={value} className="grey-color uppercase" activeClassName="active">
          {truncatedLabel}
        </Link>
      ))}
    </>
  );
};
