import {useState} from 'react';
import {object} from 'yup';
import PropTypes from 'prop-types';
import {Formik, Form} from 'formik';
import get from 'lodash/get';
import {DeprecatedButton, Card, SvgIcon, StepIndicator} from '@shipwell/shipwell-ui';
import FuelSurchargeTableCreateFormFields from 'App/containers/userCompany/fuelSurcharges/formFields/criteria';
import FuelSurchargeSettingsFormFields from 'App/containers/userCompany/fuelSurcharges/formFields/settings';
import FuelSurchargeRateTableBuilder from 'App/containers/userCompany/fuelSurcharges/createForm/rateTableBuilder';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {createFuelSurchargeTable, updateFuelSurchargeTable} from 'App/api/rateTables';
import {getCarrierRelationshipsPromise} from 'App/api/carriers';
import {useCarrierDetails} from 'App/data-hooks';
import {
  defaultFormValues,
  carrierValidationSchemaFields,
  fuelSurchargeRateTableBuilderSchemaFields
} from 'App/containers/userCompany/fuelSurcharges/validation';
import {formatCarrierRelationships, makeCarrierOption} from 'App/containers/userCompany/fuelSurcharges/utils';
import {formatAddresses} from 'App/containers/workflows/utils';
import {PROPTYPE_ADDRESS} from 'App/utils/propTypeConstants';
import {createDoeTable} from 'App/containers/userCompany/fuelSurcharges/utils/utils';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';

const steps = [
  {
    title: 'Define Criteria',
    explainer: 'Set your policy criteria to define which shipments to use this pricing table on.',
    validationSchema: object().shape({
      ...carrierValidationSchemaFields
    })
  },
  {
    title: 'Define Rates',
    explainer: 'Use the fields in blue to set your table structure for mapping DOE rates to your rates.',
    validationSchema: object().shape({
      ...fuelSurchargeRateTableBuilderSchemaFields
    })
  }
];

const FuelSurchargeTableCreateForm = ({
  onCancel,
  onSubmitSuccess,
  onError,
  values,
  selectedFuelSurchargeTable,
  setSelectedFuelSurchargeTable
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const carrierDetails = useCarrierDetails(get(values, 'carrier_relationships', []));

  const onBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleFuelSurchargeTableSubmit = async (values, {setTouched}) => {
    try {
      const payload = {
        ...values,
        carrier_relationships: formatCarrierRelationships(values.carrier_relationships),
        locations: formatAddresses(values.locations),
        customers: values.customers ? values.customers.map((customer) => customer?.id) : []
      };
      const cleanedPayload = omitEmptyKeysWithEmptyObjectsRemoved(payload);
      const opts = {
        auto_calculate_rows: true
      };
      let response;
      if (!selectedFuelSurchargeTable?.id) {
        response = await createFuelSurchargeTable(cleanedPayload, opts);
      } else {
        response = await updateFuelSurchargeTable(selectedFuelSurchargeTable.id, cleanedPayload);
      }

      if (activeStep < steps.length - 1) {
        // resetting touched values when moving to next step
        setTouched({});
        setSelectedFuelSurchargeTable(response.body);
        setActiveStep(activeStep + 1);
      } else {
        onSubmitSuccess(response);
        setSelectedFuelSurchargeTable(undefined);
      }
    } catch (error) {
      console.error('Error creating fuel surcharge table', error?.error_description);
      onError('Error!', error?.error_description);
    }
  };

  const fetchCarrierRelationships = async (q) => {
    try {
      const response = await getCarrierRelationshipsPromise({q});
      return get(response, 'body.results', []).map((carrier) => makeCarrierOption(carrier));
    } catch (error) {
      console.error('Error retrieving carrier relationships', error);
    }
  };

  const initialValues = {
    ...values,
    calculation_rows: createDoeTable(values),
    carrier_relationships: get(values, 'carrier_relationships', []).map((carrierId) =>
      makeCarrierOption(get(carrierDetails, carrierId, {}))
    )
  };

  return (
    <div className="fuelSurcharge__modal-body">
      <div className="fuelSurcharge__stepIndicator">
        <StepIndicator steps={steps.map((step) => step.title)} alternativeLabel activeStep={activeStep} />
      </div>
      <div className="fuelSurcharge__explainer">{steps[activeStep].explainer}</div>
      <Formik
        enableReinitialize
        validationSchema={steps[activeStep].validationSchema}
        initialValues={{
          ...defaultFormValues,
          ...initialValues
        }}
        onSubmit={handleFuelSurchargeTableSubmit}
      >
        {(formikProps) => (
          <Form noValidate>
            {activeStep === 0 ? (
              <>
                <Card title="Fuel Surcharge Table Criteria">
                  <FuelSurchargeTableCreateFormFields fetchCarrierRelationships={fetchCarrierRelationships} />
                </Card>
                <Card title="Settings" className="mt-4">
                  <FuelSurchargeSettingsFormFields />
                </Card>
              </>
            ) : activeStep === 1 ? (
              <FuelSurchargeRateTableBuilder values={formikProps.values} setFieldValue={formikProps.setFieldValue} />
            ) : null}
            <ModalFormFooter
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...formikProps}
              onCancel={onCancel}
              primaryActionName={activeStep < steps.length - 1 ? 'Create and Continue' : 'Save Table'}
              options={
                activeStep > 0 && (
                  <DeprecatedButton variant="tertiary" onClick={onBack} className="fuelSurcharge__back">
                    <SvgIcon name="ArrowBack" />
                    Back
                  </DeprecatedButton>
                )
              }
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

FuelSurchargeTableCreateForm.propTypes = {
  dispatch: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onError: PropTypes.func,
  values: PropTypes.shape({
    id: PropTypes.string,
    carrier_relationships: PropTypes.arrayOf(PropTypes.string),
    locations: PropTypes.arrayOf(PROPTYPE_ADDRESS),
    customers: PropTypes.arrayOf(PropTypes.string),
    miles_per_gallon: PropTypes.string
  }),
  selectedFuelSurchargeTable: PropTypes.object,
  setSelectedFuelSurchargeTable: PropTypes.func
};

FuelSurchargeTableCreateForm.defaultProps = {
  dispatch: () => {},
  onCancel: () => {},
  onSubmitSuccess: () => {},
  onError: () => {}
};

export default FuelSurchargeTableCreateForm;
