import {ShipmentStateEnum} from '@shipwell/backend-core-sdk';
import {SvgIcon, SvgIconName} from '@shipwell/shipwell-ui';
import {Step, ProgressIndicator} from 'App/components/ProgressIndicator';

interface TrackingStepsProps {
  status?: ShipmentStateEnum;
}

export default function ExternalTrackingSteps({status}: TrackingStepsProps) {
  const STATUSES = {
    Dispatched: [ShipmentStateEnum.Dispatched],
    InTransit: [
      ShipmentStateEnum.Delayed,
      ShipmentStateEnum.InTransit,
      ShipmentStateEnum.OutForDelivery,
      ShipmentStateEnum.AtPickup,
      ShipmentStateEnum.AtDelivery
    ],
    Delivered: [ShipmentStateEnum.Delivered, ShipmentStateEnum.Reconciled]
  };

  const isActiveOrComplete = (types: ShipmentStateEnum[]) => Boolean(status && types.includes(status));
  const steps: Step[] = [
    {
      id: 'dispatched',
      label: 'Dispatched',
      isActive: isActiveOrComplete(STATUSES.Dispatched),
      isComplete: isActiveOrComplete([...STATUSES.Delivered, ...STATUSES.InTransit, ...STATUSES.Dispatched]),
      renderIcon: (step) => renderIcon(step, 'Dispatcher'),
      renderLabel
    },
    {
      id: 'in_transit',
      label: 'In Transit',
      isActive: isActiveOrComplete(STATUSES.InTransit),
      isComplete: isActiveOrComplete([...STATUSES.InTransit, ...STATUSES.Delivered]),
      renderIcon: (step) => renderIcon(step, 'TruckCircleOutlined'),
      renderLabel
    },
    {
      id: 'delivered',
      label: 'delivered',
      isActive: isActiveOrComplete(STATUSES.Delivered),
      isComplete: isActiveOrComplete(STATUSES.Delivered),
      renderIcon: (step) => renderIcon(step, 'CheckCircleOutlined'),
      renderLabel
    }
  ];

  function renderLabel(step: Step) {
    return (
      <span className={`top-full text-center uppercase ${step.isActive ? 'font-bold text-sw-active' : 'text-xs'}`}>
        {step.label}
      </span>
    );
  }

  function renderIcon(step: Step, name: SvgIconName) {
    return (
      <SvgIcon
        name={name}
        color={step.isActive ? 'sw-active' : step.isComplete ? 'sw-icon-alternate' : 'sw-disabled-alternate'}
        height="50"
        width="50"
      />
    );
  }

  return (
    <div className="flex justify-center py-6">
      <ProgressIndicator
        steps={steps}
        activeBorderClass="border-sw-icon-alternate"
        disabledBorderClass="border-sw-disabled-alternate"
      />
    </div>
  );
}
