import {useState} from 'react';
import {Paper} from '@material-ui/core';
import {Quote, RFQ} from '@shipwell/backend-core-singlerequestparam-sdk';
import SelectField from 'App/formComponents/fields/select';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {upsServiceOptions} from 'App/utils/parcelConstants';
import ParcelRateList from 'App/components/parcelRateList';
import {useGetUpsAccountsQuery} from 'App/data-hooks/parcel/UPS/hooks/useGetUpsAccountsQuery';
import {useGetUpsPackageTypes} from 'App/data-hooks/parcel/UPS/hooks/useGetUpsPackageTypes';
import './styles.scss';

export const UPSRates = ({
  packageType,
  numberOfPackages,
  rfq,
  quotes,
  handleSelectQuote,
  onAccountChange
}: {
  packageType: string;
  numberOfPackages: number;
  rfq?: RFQ;
  quotes?: {ups?: Quote[]};
  handleSelectQuote: (quote: Quote) => void;
  onAccountChange?: (selectedAccount: string, provider: string) => void;
}) => {
  const {upsPackageTypes} = useGetUpsPackageTypes();

  const [account, setAccount] = useState(rfq?.ups_specific_options?.account || null);

  const serviceLevels = upsServiceOptions.reduce((options, option) => {
    const {id, name} = option;
    return {...options, [id]: name};
  }, {});

  const {upsAccounts} = useGetUpsAccountsQuery();
  const accounts = upsAccounts?.map(({id, account_number}) => ({id: id, name: account_number})) || [];

  const handleAccountChange = (selectedAccount?: string) => {
    if (selectedAccount) {
      setAccount(selectedAccount);
      onAccountChange?.(selectedAccount, 'ups');
    }
  };

  return (
    <Paper className="ups-rates">
      <div className="paper__header">
        <img src="/images/ups_logo.svg" width="90" />
        <div>
          <label>UPS Account</label>
          <SelectField
            simpleValue
            value={account}
            placeholder="UPS Account"
            options={accounts}
            onChange={handleAccountChange}
          />
        </div>
      </div>

      {quotes?.ups && quotes.ups.length > 0 ? (
        <div className="ups-rates__table table__container">
          <ParcelRateList
            quotes={quotes.ups}
            serviceLevels={serviceLevels}
            packageType={packageType}
            packageTypes={upsPackageTypes}
            numberOfPackages={numberOfPackages}
            handleSelectQuote={handleSelectQuote}
            providerCode="UPS"
          />
        </div>
      ) : null}

      {quotes?.ups && quotes.ups.length === 0 ? (
        <div className="paper__content text-center ">
          <div className="ups-rates__noResults">
            <h3>No rates available for this shipment.</h3>
          </div>
        </div>
      ) : null}

      {!quotes || !quotes.ups ? <ShipwellLoader loading={!quotes || !quotes.ups} /> : null}
    </Paper>
  );
};
