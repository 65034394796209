import {useQuery} from '@tanstack/react-query';
import {getCapacityProviders} from 'App/api/integrations/typed';
import {CAPACITY_PROVIDERS_QUERY_KEY} from 'App/data-hooks/queryKeys';

const useCapacityProviders = ({getActiveParcelOnly = false}: {getActiveParcelOnly?: boolean} = {}) => {
  const capacityProvidersQuery = useQuery([CAPACITY_PROVIDERS_QUERY_KEY], async () => {
    const response = await getCapacityProviders();
    return response.data.filter((currentCarrier) => !currentCarrier?.modes?.includes('LTL'));
  });

  // seems like Genesis does not have an endpoint to get capacity by shipment mode.
  const parcelCapacityProviders = capacityProvidersQuery.data?.filter((currentCarrier) => {
    return getActiveParcelOnly
      ? currentCarrier?.modes?.includes('PARCEL') && currentCarrier?.accounts?.length
      : currentCarrier?.modes?.includes('PARCEL');
  });

  return {capacityProvidersQuery, parcelCapacityProviders};
};

export default useCapacityProviders;
