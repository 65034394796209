import {useState, useEffect, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import {SearchField} from '@shipwell/shipwell-ui';
import {withRouter} from 'react-router';
import {columnIds} from './columns';
import ShipwellTable from 'App/components/swTable';
import {getPurchaseOrdersList} from 'App/api/purchaseOrders';
import {SelectedCount} from 'App/components/SelectedCount';
import bulkSelect from 'App/components/tableColumns/bulkSelect';
import PageHeader from 'App/common/pageHeader';
import parsePathParams from 'App/utils/parsePathParams';
import useDebouncedEffect from 'App/utils/hooks/useDebouncedEffect';
import ShipwellLoader from 'App/common/shipwellLoader';
import usePrevious from 'App/utils/hooks/usePrevious';
import './styles.scss';
import {getTotalOrderWeightPounds} from 'App/containers/loadOptimization/utils';
import {POUNDS} from 'App/utils/internationalConstants';
import {useLoadDetail} from 'App/api/loadOptimization/hooks/useLoadDetail';
import {PROPTYPE_ROUTER} from 'App/utils/propTypeConstants';

const UnassignedPurchaseOrdersListBase = ({shipment, selectedPurchaseOrders, setSelectedPurchaseOrders, router}) => {
  const ASSIGNED_TO_LOAD_KEY = 'assigned_to_load';
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [pathParams, setPathParams] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [desc, setDesc] = useState('');
  const [meta, setMeta] = useState({
    page: 1,
    pages: 1,
    pageSize: 20
  });
  const prevPurchaseOrders = usePrevious(purchaseOrders);
  const [previousPathParams, setPreviousPathParams] = useState(usePrevious(pathParams) || {});
  const [loading, setLoading] = useState(false);
  const {loadId} = router?.params;
  const {loadDetailQuery} = useLoadDetail(loadId);
  useEffect(() => {
    if (pathParams.ordering && isEqual(previousPathParams, pathParams)) {
      toggleDesc();
    } else {
      setPreviousPathParams(pathParams);
    }
  }, [pathParams, toggleDesc, previousPathParams]);

  const toggleDesc = useCallback(() => {
    const newDesc = desc === '' ? '-' : '';
    setDesc(newDesc);
  }, [desc]);

  /** Fetch purchase orders on inital load, pagination, and ordering changes */
  useEffect(() => {
    if (!isEqual(previousPathParams, pathParams)) {
      handleGetPurchaseOrders();
    }
  }, [handleGetPurchaseOrders, previousPathParams, pathParams]);

  /** Fetch purchase orders on search query changes */
  useDebouncedEffect(() => handleGetPurchaseOrders(), 500, [searchTerm]);

  /** Update search term */
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  /** Updates parsed on pagination and ordering changes */
  /** Passed as router (required) to swTable */
  const syntheticRouter = {
    push: (path) => {
      setPathParams(parsePathParams(path));
    }
  };

  useEffect(() => {
    const loadDetailOrderExternalIds =
      loadDetailQuery.data?.data?.orders?.map((loadDetailOrder) => loadDetailOrder.external_id) || [];
    if (loadDetailQuery?.isSuccess && !isEqual(purchaseOrders, prevPurchaseOrders)) {
      const purchaseOrdersWithLoadAssignmentMutation = purchaseOrders.map((purchaseOrder) => {
        set(purchaseOrder, ASSIGNED_TO_LOAD_KEY, loadDetailOrderExternalIds.includes(purchaseOrder.id));
        return purchaseOrder;
      });
      setPurchaseOrders(purchaseOrdersWithLoadAssignmentMutation);
    }
  }, [loadDetailQuery.data?.data?.orders, loadDetailQuery?.isSuccess, prevPurchaseOrders, purchaseOrders]);

  const handleGetPurchaseOrders = useCallback(
    async (options) => {
      setLoading(true);
      try {
        options = {
          shipmentAssigned: false,
          ordering: '-planned_pickup_date',
          ...meta,
          ...pathParams,
          q: searchTerm,
          ...options
        };
        options = pathParams.ordering ? {...options, ordering: `${desc}${pathParams.ordering}`} : options;
        const response = await getPurchaseOrdersList({...options});
        setPurchaseOrders(response.body?.results);
        setMeta({
          ...meta,
          ...pathParams,
          pages: response.body?.total_pages,
          pageSize: response.body?.page_size
        });
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },
    [meta, pathParams, searchTerm, desc]
  );
  const columns = useMemo(
    () => [
      {
        Header: (
          <>
            {columnIds.order_number.label} <i className="btn-sort" />
          </>
        ),
        id: columnIds.order_number.id,
        label: columnIds.order_number.label,
        minWidth: 95,
        accessor: (d) => d.order_number,
        Cell: ({value}) => value
      },
      {
        Header: (
          <>
            {columnIds.name.label} <i className="btn-sort" />
          </>
        ),
        id: columnIds.name.id,
        label: columnIds.name.label,
        minWidth: 95,
        accessor: (d) => d.name,
        Cell: ({value}) => value ?? '--'
      },
      {
        Header: (
          <>
            {columnIds.origin_address.label} <i className="btn-sort" />
          </>
        ),
        id: columnIds.origin_address.id,
        label: columnIds.origin_address.label,
        accessor: (d) => d.origin_address,
        Cell: ({value}) => value.formatted_address
      },
      {
        Header: (
          <>
            {columnIds.destination_address.label} <i className="btn-sort" />
          </>
        ),
        id: columnIds.destination_address.id,
        label: columnIds.destination_address.label,
        accessor: (d) => d.destination_address,
        Cell: ({value}) => value.formatted_address
      },
      {
        Header: (
          <>
            {columnIds.customer_name.label} <i className="btn-sort" />
          </>
        ),
        id: columnIds.customer_name.id,
        label: columnIds.customer_name.label,
        accessor: (d) => d.customer_name,
        Cell: ({value}) => value
      },
      {
        Header: (
          <>
            {columnIds.supplier_name.label} <i className="btn-sort" />
          </>
        ),
        id: columnIds.supplier_name.id,
        label: columnIds.supplier_name.label,
        accessor: (d) => d.supplier_name,
        Cell: ({value}) => value
      },
      {
        Header: columnIds.total_line_item_weight.label,
        id: columnIds.total_line_item_weight.id,
        accessor: (d) => d.line_items,
        Cell: ({value}) => `${getTotalOrderWeightPounds(value).toLocaleString()} ${POUNDS}`
      },
      {
        Header: columnIds.total_packages.label,
        id: columnIds.total_packages.id,
        accessor: (d) => d.line_items,
        Cell: ({value}) => value?.reduce((totalPackages, lineItem) => totalPackages + lineItem.total_packages, 0) || 0
      }
    ],
    []
  );

  return (
    <>
      <div className="unassigned-orders-table__note">
        {shipment?.reference_id
          ? `Select the order(s) you want to add to Shipment ID ${shipment.reference_id}`
          : 'Select the order(s) you want to add'}
      </div>
      <div className="unassigned-orders-table">
        <PageHeader title="Unassigned Orders">
          <SearchField label="Search orders" name="search_orders" value={searchTerm} onChange={handleSearch} />
        </PageHeader>
        {loading ? (
          <ShipwellLoader loading={loading} />
        ) : (
          <div className="flex min-h-[calc(100vh-360px)]">
            <ShipwellTable
              router={syntheticRouter}
              location={location}
              data={purchaseOrders}
              defaultSorted={[{id: 'planned_pickup_date', desc: true}]}
              page={Number(meta.page) - 1}
              pages={Number(meta.pages)}
              pageSize={Number(meta.pageSize)}
              tableType="UNASSIGNED_ORDERS"
              noDataText="No search results - No Unassigned Orders Found"
              columns={columns}
              bulkSelectColumns={[
                bulkSelect({
                  currentPage: purchaseOrders,
                  selected: selectedPurchaseOrders,
                  onSelect: setSelectedPurchaseOrders,
                  propertyToDisableOn: ASSIGNED_TO_LOAD_KEY
                })
              ]}
            />
          </div>
        )}

        {selectedPurchaseOrders.length > 0 && (
          <SelectedCount
            count={selectedPurchaseOrders.length}
            itemLabel="Orders"
            className="selected-count__table-overlay"
            clickCta="Deselect All"
            onClick={() => setSelectedPurchaseOrders([])}
          />
        )}
      </div>
    </>
  );
};

const UnassignedPurchaseOrdersList = withRouter(UnassignedPurchaseOrdersListBase);
UnassignedPurchaseOrdersListBase.propTypes = {
  shipment: PropTypes.object,
  selectedPurchaseOrders: PropTypes.array,
  setSelectedPurchaseOrders: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  router: PROPTYPE_ROUTER
};
UnassignedPurchaseOrdersListBase.defaultProps = {
  router: {params: {loadId: ''}}
};

export default UnassignedPurchaseOrdersList;
