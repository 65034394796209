import {useState, useCallback} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {Card, Dropdown, SvgIcon} from '@shipwell/shipwell-ui';
import {unassignCarrierPromise, fetchCarrierConfig} from 'App/api/shipment';
import BaseCarrierEquipment from 'App/containers/Shipment/components/CarrierEquipment';
import WithStatusToasts, {WithStatusToastsPropTypes} from 'App/components/withStatusToasts';
import {hasInstantRateBooked, getCurrentCarrier} from 'App/containers/Shipment/components/CarrierEquipment/utils';
import DeleteWarningTooltip from 'App/components/DeleteWarningTooltip';
import {clearSelectedRelationship} from 'App/actions/vendors';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const CarrierEquipment = WithStatusToasts(BaseCarrierEquipment);

const CarrierEquipmentActionsDropdown = ({canEdit, handleEdit, handleRemoveCarrier, shipment}) => (
  <Dropdown variant="icon" drop="right" indicator={false} icon={<SvgIcon name="Overflow" title="overflowIcon" />}>
    {({onClick}) => (
      <>
        <li
          disabled={!canEdit}
          onClick={() => {
            onClick();
            handleEdit(true)();
          }}
        >
          Edit
        </li>
        {canEdit && handleRemoveCarrier ? (
          <DeleteWarningTooltip
            title={'Remove Carrier'}
            primaryActionText="Remove"
            message="Are you sure you want to remove the carrier from the shipment?"
            onDelete={() => {
              onClick();
              handleRemoveCarrier();
            }}
          >
            <li disabled={hasInstantRateBooked(shipment)}>Remove carrier</li>
          </DeleteWarningTooltip>
        ) : null}
      </>
    )}
  </Dropdown>
);

CarrierEquipmentActionsDropdown.propTypes = {
  canEdit: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleRemoveCarrier: PropTypes.func,
  shipment: PropTypes.object
};

const ShipmentCardCarrierEquipment = ({
  ordinalIndex,
  isCollapsed,
  onCollapse,
  draggableProvided,
  user,
  company,
  shipment,
  isQuotingLimitedUser,
  setError,
  setWarning,
  fetchDetails: fetchShipmentDetails,
  clearSelectedRelationship,
  ...props
}) => {
  const [editing, setEditing] = useState(false);
  const [carrierConfig, setCarrierConfig] = useState({});
  const getCarrierConfig = useCallback(
    async (id) => {
      try {
        const response = await fetchCarrierConfig(id);
        setCarrierConfig(response.body);
      } catch (error) {
        console.error(error);
        setError('Error!', 'Error retrieving carrier details.');
      }
    },
    [setError]
  );

  const handleSuccess = async () => {
    await Promise.all([getCarrierConfig(shipment.id), fetchShipmentDetails(shipment.id)]);
    handleEdit(false)();
  };

  const handleWarning = (title, message) => {
    setWarning(title, message);
    handleSuccess();
  };

  const handleRemoveCarrier = async () => {
    try {
      await unassignCarrierPromise(shipment.id);
      handleSuccess();
      clearSelectedRelationship();
    } catch (error) {
      console.error(error);
    }
  };

  const isCarrier = isQuotingLimitedUser || getCurrentCarrier(shipment)?.id === company.id;
  const canEdit =
    get(user, 'permissions', []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
    get(user, 'permissions', []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION);
  const handleEdit = (isEditing) => () => setEditing(isEditing);

  return (
    <Card
      title={
        <div className="flex items-center py-4">
          <span className="mr-3">
            <SvgIcon name="Drag" color="$grey-3" width="10" height="16" />
          </span>
          <div className="flex items-baseline">
            <span>{isCarrier ? 'Equipment' : 'Carrier & Equipment'}</span>
            <span className="text-grey-5 text-xxs font-normal">
              <span className="mx-1">•</span>
              {get(carrierConfig, 'vendor.name') || 'No carrier assigned'}
            </span>
          </div>
        </div>
      }
      tabOrder={ordinalIndex}
      isCollapsible
      isCollapsed={isCollapsed}
      onCollapse={onCollapse}
      draggableProvided={draggableProvided}
      actions={
        canEdit && (
          <CarrierEquipmentActionsDropdown
            canEdit={canEdit}
            handleEdit={handleEdit}
            handleRemoveCarrier={carrierConfig?.vendor?.id !== company?.id ? handleRemoveCarrier : null}
            shipment={shipment}
          />
        )
      }
      bodyClassName="p-4 empty:p-0"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <CarrierEquipment
        editing={editing}
        handleEdit={handleEdit}
        shipment={shipment}
        carrierConfig={carrierConfig}
        getCarrierConfig={getCarrierConfig}
        handleSuccess={handleSuccess}
        handleWarning={handleWarning}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Card>
  );
};

ShipmentCardCarrierEquipment.defaultProps = {
  ordinalIndex: 0,
  isCollapsed: false,
  onCollapse: () => {},
  draggableProvided: {
    draggableProps: {},
    dragHandleProps: {},
    innerRef: null
  },
  setError: () => {},
  fetchDetails: () => {}
};

ShipmentCardCarrierEquipment.propTypes = {
  ordinalIndex: PropTypes.number,
  isCollapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  draggableProvided: PropTypes.shape({
    draggableProps: PropTypes.object,
    dragHandleProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.elementType})])
  }),
  user: PropTypes.object,
  company: PropTypes.object,
  shipment: PropTypes.object,
  isQuotingLimitedUser: PropTypes.bool,
  fetchDetails: PropTypes.func,
  ...WithStatusToastsPropTypes
};

const mapStateToProps = (state) => ({
  user: state.userProfile.user,
  company: state.userCompany.company,
  isQuotingLimitedUser: state.userProfile.user.is_quoting_limited_user
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearSelectedRelationship
    },
    dispatch
  );
};

export default compose(WithStatusToasts, connect(mapStateToProps, mapDispatchToProps))(ShipmentCardCarrierEquipment);
