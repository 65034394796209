import {useEffect, useState, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import {SvgIcon, Dropdown, Checkbox} from '@shipwell/shipwell-ui';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import {useTrimbleMaps} from './useTrimbleMaps';
import {
  createLines,
  createMarker,
  createPopup,
  createStopPoints,
  markerMap,
  createTrackingLines,
  transformLongitudes
} from './utils/typed';
import {createHTMLElementFromComponent} from './utils';
import LoadLinesLegend from './components/LoadLinesLegend';
import Loader from 'App/common/shipwellLoader';
import usePrevious from 'App/utils/hooks/usePrevious';
import {RollbarErrorBoundary} from 'App/common/ErrorBoundary';
import './styles.scss';
import {getMapModeColor} from 'App/containers/loadOptimization/utils';
import {MAP_LINE_POINT_CIRCLE_RADIUS, MAP_LINE_WIDTH} from 'App/containers/loadOptimization/constants';

function TrimbleMapErrorFallback() {
  return (
    <div className="flex h-full items-center justify-center">
      <>
        <div className="flex justify-center">
          <SvgIcon name="WarningFilled" className="mb-2" color="$sw-error" height="32" width="32" />
        </div>
        <div className="text-lg text-sw-disabled-text">Map rendering is not possible with an outdated browser.</div>
        <div className="flex justify-center text-lg text-sw-disabled-text">
          Please ensure your browser is up to date.
        </div>
      </>
    </div>
  );
}

function TrimbleMap(props) {
  return (
    <RollbarErrorBoundary fallbackUI={TrimbleMapErrorFallback}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <TrimbleMapContainer {...props} />
    </RollbarErrorBoundary>
  );
}

function TrimbleMapContainer({elementId, additionalClassNames, options = {}, onZoomChange = noop, ...props}) {
  const {mapRef, mapLoaded, baseStyles, styleLoaded} = useTrimbleMaps({elementId, options, onZoomChange});

  return (
    <>
      {mapRef.current && mapLoaded ? (
        <Map
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          baseStyles={baseStyles}
          mapRef={mapRef}
          elementId={elementId}
          styleLoaded={styleLoaded}
        />
      ) : null}
      <div className={`trimbleMap ${additionalClassNames} size-full`} id={elementId}></div>
    </>
  );
}

function Map({
  locations = [],
  route,
  address,
  resizeMap,
  totalDistance,
  mapRef,
  baseStyles,
  styleLoaded,
  dataLoading,
  loadLines,
  trackingLinePoints
}) {
  const map = mapRef.current;
  const [trackingLayerId, setTrackingLayerId] = useState();
  const [layers, setLayers] = useState(
    localStorage.getItem('swMapLayers')
      ? JSON.parse(localStorage.getItem('swMapLayers'))
      : {weather: false, traffic: false, satellite: false}
  );
  const [displayedMarkers, setDisplayedMarkers] = useState([]);
  const prevResizeMap = usePrevious(resizeMap);
  const prevLayers = usePrevious(layers) || {};

  const prevRoute = usePrevious(route);
  const [displayedRoutes, setDisplayedRoutes] = useState([]);
  const prevDisplayedRoutes = usePrevious(displayedRoutes);

  const prevLoaded = usePrevious(styleLoaded);
  // style loaded field changes when `setStyle` is called - we have wait until style is loaded to draw on map
  const styleLoadedChanged = styleLoaded && !prevLoaded;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shipmentAddress = useMemo(() => address, [address?.latitude, address?.longitude]);
  const prevShipmentAddress = usePrevious(shipmentAddress);

  const prevLocations = usePrevious(locations);

  const putMarkersOnMap = useCallback(() => {
    if (!locations.length) {
      return;
    }

    const newBounds = new TrimbleMaps.LngLatBounds();
    const newMarkers = locations.map((location) => {
      const markerElement = createHTMLElementFromComponent(markerMap[location.type]);
      const marker = createMarker(markerElement, location.type);

      marker.setLngLat(location.coords).addTo(map);
      if (location.details) {
        const markerDetails = createPopup(location.type).setDOMContent(
          createHTMLElementFromComponent(location.details)
        );
        marker.setPopup(markerDetails);
      }

      markerElement.addEventListener('mouseenter', () => {
        markerElement.classList.add('grow');
        marker.togglePopup();
      });
      markerElement.addEventListener('mouseleave', () => {
        markerElement.classList.remove('grow');
        marker.togglePopup();
      });
      if (location.onClick) {
        markerElement.addEventListener('click', () => {
          location.onClick();
        });
      }
      newBounds.extend(location.coords);

      return marker;
    });
    //only fit bounds to marker points if no tracking line
    //else map bounces around weird
    if ((trackingLinePoints || []).length < 2) {
      map.fitBounds(newBounds, {padding: 32, maxZoom: 20});
    }
    setDisplayedMarkers(newMarkers);
    // ignoring exhaustive-deps because we don't want this useEffect to run
    // whenever trackingLinePoints is updated, it is only used to determine if
    // fitBounds should be called
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, map]);

  const putRoutesOnMap = useCallback(() => {
    if (!displayedRoutes.length) {
      return;
    }
    const [completedRoute, futureRoute] = displayedRoutes;
    try {
      if (completedRoute) {
        if (completedRoute.id) {
          map.addSource(completedRoute.id, {
            type: 'geojson',
            data: completedRoute
          });
          map.addLayer({
            id: completedRoute.id,
            source: completedRoute.id,
            type: 'line',
            paint: {
              'line-width': 6,
              'line-color': '#005F9E'
            }
          });
        } else {
          completedRoute.addTo(map);
        }
      }
      if (futureRoute) {
        if (futureRoute.id) {
          map.addSource(futureRoute.id, {
            type: 'geojson',
            data: futureRoute
          });
          map.addLayer({
            id: futureRoute.id,
            source: futureRoute.id,
            type: 'line',
            paint: {
              'line-width': 6,
              'line-color': '#005F9E'
            }
          });
        } else {
          futureRoute.addTo(map);
        }
      }
    } catch (error) {
      console.error('Error putting route on map:', error);
      removeAllRoutes();
    }
  }, [displayedRoutes, map, removeAllRoutes]);

  const putTrackingLinesOnMap = useCallback(() => {
    if ((trackingLinePoints || []).length < 2) {
      return;
    }
    //remove existing layer if it exists
    if (map.getLayer(trackingLayerId)) {
      map.removeLayer(trackingLayerId);
    }
    const mapLines = createTrackingLines(trackingLinePoints);

    if (!mapLines?.id) {
      console.error('Missing load line id');
      return;
    }
    map.addSource(mapLines.id, {
      type: 'geojson',
      data: mapLines
    });
    map.addLayer({
      id: mapLines.id,
      source: mapLines.id,
      type: 'line',
      paint: {
        'line-width': MAP_LINE_WIDTH,
        'line-color': '#838383'
      }
    });
    setTrackingLayerId(mapLines.id);

    const trackingCoords = transformLongitudes(trackingLinePoints);
    const mapBounds = new TrimbleMaps.LngLatBounds.convert([trackingCoords[0], trackingCoords.at(-1)]);

    //fitBounds using the line coordinates makes it center correctly
    //other call to fitBounds would often center on the wrong ocean
    map.fitBounds(mapBounds, {padding: 32, maxZoom: 20});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingLinePoints, map]);

  const putLoadLinesOnMap = useCallback(() => {
    if ((loadLines || []).length === 0) {
      return;
    }

    const mapLines = createLines(loadLines);
    const mapStopPoints = createStopPoints(loadLines);
    mapLines.forEach((loadLine) => {
      if (!loadLine?.id) {
        console.error('Missing load line id');
        return;
      }
      map.addSource(loadLine.id, {
        type: 'geojson',
        data: loadLine
      });
      map.addLayer({
        id: loadLine.id,
        source: loadLine.id,
        type: 'line',
        paint: {
          'line-width': MAP_LINE_WIDTH,
          'line-color': getMapModeColor(loadLine?.mode || '')
        }
      });
    });

    mapStopPoints.forEach((loadStopPoint) => {
      if (!loadStopPoint?.id) {
        console.error('Missing load stop point id');
        return;
      }
      map.addSource(loadStopPoint.id, {
        type: 'geojson',
        data: loadStopPoint
      });

      map.addLayer({
        id: loadStopPoint.id,
        source: loadStopPoint.id,
        type: 'circle',
        paint: {
          'circle-color': getMapModeColor(loadStopPoint?.mode || ''),
          'circle-radius': MAP_LINE_POINT_CIRCLE_RADIUS
        }
      });
    });
  }, [loadLines, map]);

  const removeAllMarkers = useCallback(() => {
    try {
      if (displayedMarkers.length) {
        displayedMarkers.forEach((marker) => marker.remove());
      }
    } catch (error) {
      console.error(error);
    }
  }, [displayedMarkers]);

  const removeAllRoutes = useCallback(() => {
    try {
      if (displayedRoutes.length && styleLoaded) {
        displayedRoutes.forEach((route) => route?.remove());
      }
    } catch (error) {
      console.error(error);
    }
  }, [displayedRoutes, styleLoaded]);

  function toggleLayer(e) {
    setLayers({...layers, [e.target.name]: e.target.checked});
  }

  function getStops(route) {
    return Object.keys(route || {}).map((r) => route[r]?._options?.stops);
  }

  useEffect(() => {
    const stopsChanged = !isEqual(getStops(route), getStops(prevRoute));

    if (route && stopsChanged) {
      setDisplayedRoutes([route.completedRoute, route.futureRoute, route.entireRoute]);
    }
  }, [route, prevRoute]);

  useEffect(() => {
    if (layers.satellite !== prevLayers.satellite) {
      if (layers.satellite) {
        map.setStyle(TrimbleMaps.Common.Style.SATELLITE, {diff: true});
      } else {
        if (!isEmpty(baseStyles)) {
          map.setStyle(baseStyles, {diff: true});
        }
      }
    }

    if (layers.weather !== prevLayers.weather) {
      map.setWeatherRadarVisibility(layers.weather);
    }

    if (layers.traffic !== prevLayers.traffic) {
      map.setTrafficVisibility(layers.traffic);
    }

    localStorage.setItem('swMapLayers', JSON.stringify({...layers}));
  }, [
    layers.traffic,
    layers.weather,
    layers.satellite,
    prevLayers.traffic,
    prevLayers.weather,
    prevLayers.satellite,
    map,
    baseStyles,
    layers
  ]);

  // this effect is used to remove the displayed routes in load optimization
  // when a user removes or adds stops to a load
  useEffect(() => {
    const displayedRouteStops = getStops(displayedRoutes);
    const routeStops = getStops(route);
    const routeStopsChanged = !isEqual(routeStops, displayedRouteStops);
    if (routeStopsChanged && displayedRoutes.length > 0) {
      removeAllRoutes();
    }
  }, [displayedRoutes, map, removeAllRoutes, route]);

  useEffect(() => {
    if (!displayedRoutes?.length) {
      return;
    }
    const routesChanged = !isEqual(getStops(displayedRoutes), getStops(prevDisplayedRoutes));
    if ((routesChanged && styleLoaded) || styleLoadedChanged) {
      removeAllRoutes();
      putRoutesOnMap();
    }
  }, [
    map,
    route,
    removeAllRoutes,
    removeAllMarkers,
    putRoutesOnMap,
    putMarkersOnMap,
    displayedRoutes,
    displayedMarkers,
    prevDisplayedRoutes,
    shipmentAddress,
    prevShipmentAddress,
    styleLoadedChanged,
    styleLoaded
  ]);

  useEffect(() => {
    const addressChanged = !isEmpty(shipmentAddress) && !isEqual(shipmentAddress, prevShipmentAddress);
    const locationsChanged = !isEmpty(locations) && !isEqual(prevLocations, locations);
    if (((addressChanged || locationsChanged) && styleLoaded) || styleLoadedChanged) {
      removeAllMarkers();
      putMarkersOnMap();
    }
  }, [
    locations,
    prevLocations,
    putMarkersOnMap,
    removeAllMarkers,
    styleLoadedChanged,
    styleLoaded,
    prevShipmentAddress,
    shipmentAddress
  ]);

  useEffect(() => {
    if (map && prevResizeMap !== resizeMap) {
      map.resize();
    }
  }, [map, prevResizeMap, resizeMap]);

  useEffect(() => {
    if (map && (loadLines || []).length > 0 && styleLoaded) {
      putLoadLinesOnMap();
    }
  }, [map, loadLines, putLoadLinesOnMap, styleLoaded]);

  useEffect(() => {
    if (map && (trackingLinePoints || []).length > 0 && styleLoaded) {
      putTrackingLinesOnMap();
    }
  }, [map, putTrackingLinesOnMap, styleLoaded, trackingLinePoints]);

  return (
    <>
      <Dropdown
        alignEnd
        className="size-8 pr-3"
        variant="icon"
        indicator={false}
        drop="right"
        icon={
          <div className="z-[3] flex w-full items-center justify-center rounded-[3px] border-1 border-sw-border-alternate bg-sw-background-component text-sw-text-reverse">
            <SvgIcon name="Layers" color="$sw-icon" />
            {(layers.weather || layers.traffic || layers.satellite) && (
              <span className="absolute left-6 top-[6px] size-2 rounded-[50%] bg-sw-error" />
            )}
          </div>
        }
      >
        {() => [
          <div className="px-3 py-0" key={1}>
            <Checkbox checked={layers.weather} onChange={toggleLayer} label="Weather" name="weather" />
          </div>,
          <div className="px-3 py-0" key={2}>
            <Checkbox checked={layers.traffic} onChange={toggleLayer} label="Traffic" name="traffic" />
          </div>,
          <div className="px-3 py-0" key={3}>
            <Checkbox checked={layers.satellite} onChange={toggleLayer} label="Satellite" name="satellite" />
          </div>
        ]}
      </Dropdown>
      {(loadLines || []).length > 0 ? <LoadLinesLegend /> : null}
      {totalDistance ? (
        <div className="absolute right-2 top-1 z-10 rounded-sm border-1 border-solid border-sw-border bg-sw-background-component p-2 text-sw-label shadow-md">
          <span className="font-bold">{`Total Distance: `}</span>
          <span>{totalDistance} mi</span>
        </div>
      ) : null}
      {dataLoading ? (
        <>
          <div className="absolute top-0 z-[11] size-full bg-sw-background-component text-sw-label opacity-50"></div>
          <div className="absolute inset-0 z-[12]">
            <Loader loading additionalClassNames={['h-full']} />
          </div>
        </>
      ) : null}
    </>
  );
}

const TrimbleMapProps = {
  additionalClassNames: PropTypes.string,
  elementId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  onZoomChange: PropTypes.func,
  options: PropTypes.object,
  route: PropTypes.object,
  address: PropTypes.object,
  resizeMap: PropTypes.bool,
  dataLoading: PropTypes.bool,
  totalDistance: PropTypes.string,
  loadLines: PropTypes.arrayOf(PropTypes.array),
  trackingLinePoints: PropTypes.arrayOf(PropTypes.array)
};

TrimbleMap.propTypes = TrimbleMapProps;

TrimbleMapContainer.propTypes = TrimbleMapProps;

Map.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object),
  route: PropTypes.object,
  address: PropTypes.object,
  resizeMap: PropTypes.bool,
  totalDistance: PropTypes.string,
  mapRef: PropTypes.object,
  baseStyles: PropTypes.object,
  styleLoaded: PropTypes.bool,
  dataLoading: PropTypes.bool,
  loadLines: PropTypes.arrayOf(PropTypes.array),
  trackingLinePoints: PropTypes.arrayOf(PropTypes.array)
};

export default TrimbleMap;
