import {
  Appointment,
  AppointmentCheckInRequest,
  AppointmentCheckOutRequest,
  AppointmentRejectRequest,
  AppointmentStatusEnum,
  AppointmentsApi,
  Configuration,
  RescheduleAppointmentRequest,
  ScheduleAppointmentRequest,
  PaginatedResponseAppointment,
  ScheduledResourceTypeEnum
} from '@shipwell/tempus-sdk';
import isNil from 'lodash/isNil';
import {getV3ApiAllOfPaginated} from '../typedUtils';
import {getAccessToken} from 'App/api/utils';
import {CreateAppointmentParameters, GetAppointmentNamedParameters} from 'App/api/appointments/types';
import {AppointmentEntry} from 'App/data-hooks/appointments/types';
import {makeEntry} from 'App/containers/appointments/utils';
import {cmp} from 'App/utils/cmp';

const createAppointmentsApi = () => {
  const basePath = process.env.SHIPWELL_TEMPUS_API_BASE_PATH;
  const config = new Configuration({
    basePath: basePath,
    apiKey: getAccessToken
  });
  return new AppointmentsApi(config);
};

const getQueryParams = (options: GetAppointmentNamedParameters) => {
  const opts: Record<string, string> = {};
  if (!isNil(options.startDate)) {
    const startDateTime = new Date(options.startDate);
    if (Number.isNaN(startDateTime.valueOf())) {
      console.warn('Could not filter facility appointments with invalid start datetime "%s"', options.startDate);
    }
    opts['start_datetime_utc.gte'] = startDateTime.toISOString();
  }
  if (!isNil(options.endDate)) {
    const endDateTime = new Date(options.endDate);
    if (Number.isNaN(endDateTime.valueOf())) {
      console.warn('Could not filter facility appointments with invalid end datetime "%s"', options.endDate);
    }
    opts['end_datetime_utc.lte'] = endDateTime.toISOString();
  }
  if (!isNil(options.shipmentId)) {
    opts['scheduled_resource_id'] = options.shipmentId;
  }
  if (!isNil(options.stopId)) {
    opts['stop_id'] = options.stopId;
  }
  if (options.status) {
    opts['status'] = options.status;
  }
  if (options.plannedDate) {
    opts['planned_date'] = options.plannedDate;
  }
  if (options.plannedDateGte) {
    opts['planned_date.gte'] = options.plannedDateGte;
  }
  if (options.plannedDateLte) {
    opts['planned_date.lte'] = options.plannedDateLte;
  }

  return opts;
};

function cleanAppointmentReferences(appointmentIn: Appointment): Appointment {
  return {
    ...appointmentIn,
    references: appointmentIn.references
      ?.flatMap((r) => r.value.split(',').map((v) => ({qualifier: r.qualifier, value: v.trim()})))
      .filter((r) => !!r.value) // remove references with empty values (trailing commas)
      .sort(
        // sort references to deduplicate them
        (a, b) => cmp(a.qualifier, b.qualifier) || cmp(a.value, b.value)
      )
      .filter(
        // filter out references that are not distinct from their ordered predecessor
        (r, i, a) => i === 0 || r.qualifier !== a[i - 1].qualifier || r.value !== a[i - 1].value
      )
  };
}

const createAppointment = async (opts: CreateAppointmentParameters): Promise<Appointment> => {
  const client = createAppointmentsApi();
  if (isNil(opts.dockId)) {
    throw new Error('Facility Appointments must be assigned to a dock.');
  }

  const {data} = await client.createDockAppointmentFacilitiesFacilityIdDocksDockIdAppointmentsPost(
    opts.facilityId,
    opts.dockId,
    opts.createAppointment
  );

  return cleanAppointmentReferences(data);
};

const updateAppointment = async ({id, appointment}: {id: string; appointment: RescheduleAppointmentRequest}) => {
  const api = createAppointmentsApi();
  const {data} = await api.rescheduleAppointmentFacilitiesAppointmentsAppointmentIdReschedulePost(id, appointment);

  return cleanAppointmentReferences(data);
};
/**
 * Gets an appointment by it's identifier or throws if non exists.
 * @param {string} id the ID of the appointment to get
 * @returns {Promise<Appointment>}
 */
const getAppointment = async (id: string): Promise<Appointment> => {
  const {data} = await createAppointmentsApi().retrieveAppointmentFacilitiesAppointmentsAppointmentIdGet(id);

  return cleanAppointmentReferences(data);
};

/**
 * Cancels an appointment
 * @param {string} id the ID of the appointment to cancel
 * @returns {Promise<void>}
 */
const cancelAppointment = async (id: string | Appointment): Promise<void> => {
  if (typeof id !== 'string') {
    id = id.id;
  }
  const api = createAppointmentsApi();

  await api.cancelAppointmentFacilitiesAppointmentsAppointmentIdCancelPost(id);
};

export type GetAllAppointmentQueryParameters = {
  q?: string;
  status?: AppointmentStatusEnum;
  plannedDate?: string;
  plannedDateGte?: string;
  plannedDateLte?: string;
  page?: number;
  limit?: number;
  sort?: string;
  facilityId?: string;
  dockId?: string;
  startDate?: Date;
  endDate?: Date;
  /**
   * When true only appointments with an assigned carrier.
   * When false only appointment without an assigned carrier.
   * When `null` (the default) return both.
   */
  carrierAssigned?: boolean | null;
};
/**
 * Paginates through all appointments and flattens the records out as a single result.
 */
async function getAppointments(
  {
    q,
    status,
    plannedDate,
    plannedDateGte,
    plannedDateLte,
    limit,
    sort,
    facilityId,
    dockId,
    startDate,
    endDate,
    page,
    carrierAssigned
  }: GetAllAppointmentQueryParameters & {page?: number} = {page: 1}
): Promise<PaginatedResponseAppointment> {
  const params = getQueryParams({startDate, endDate, status, plannedDate, plannedDateGte, plannedDateLte});

  const axiosOptions = {params};

  const {data} = await createAppointmentsApi().listAllAppointmentsFacilitiesAppointmentsGet(
    q,
    status,
    undefined,
    carrierAssigned ?? undefined,
    page,
    limit,
    sort,
    facilityId,
    dockId,
    axiosOptions
  );

  return {
    total_count: data.total_count,
    data: data?.data?.map(cleanAppointmentReferences) || []
  };
}

async function getFacilityAppointments(
  facilityId: string,
  dockId: string | null,
  start: Date | null,
  end: Date | null,
  searchText?: string | null,
  status?: AppointmentStatusEnum,
  plannedDate?: string | null,
  plannedDateGte?: string | null,
  plannedDateLte?: string | null,
  options: {
    shipmentId?: string;
    stopId?: string;
    /**
     * When true only appointments with an assigned carrier.
     * When false only appointment without an assigned carrier.
     * When `null` (the default) return both.
     * Note, this only applies to appointments with `scheduled_resource_type: 'SHIPMENT'`.
     */
    carrierAssigned?: boolean | null;
  } = {}
): Promise<Appointment[]> {
  const axiosOptions = {
    params: getQueryParams({
      startDate: start,
      endDate: end,
      plannedDate: plannedDate,
      plannedDateGte: plannedDateGte,
      plannedDateLte: plannedDateLte,
      ...options
    })
  };

  const fetch = async (page: number, pageSize: number) => {
    const {data} = await createAppointmentsApi().listAllAppointmentsFacilitiesAppointmentsGet(
      searchText ?? undefined,
      status || undefined,
      undefined,
      undefined, // we handle carrier_assigned omn the client now - Joe 2023-10-04
      page,
      pageSize,
      undefined,
      dockId ? undefined : facilityId,
      dockId || undefined,
      axiosOptions
    );
    if (options.carrierAssigned === true) {
      data.data = data.data.filter(
        (appointment) =>
          appointment.scheduled_resource_type !== ScheduledResourceTypeEnum.Shipment || !!appointment.carrier_tenant_id
      );
    }
    if (options.carrierAssigned === false) {
      data.data = data.data.filter((appointment) => !appointment.carrier_tenant_id);
    }

    return data;
  };

  const appointments = await getV3ApiAllOfPaginated(fetch);

  return appointments.map(cleanAppointmentReferences);
}

async function checkInAppointment(appointment: AppointmentEntry, checkInTime?: Date): Promise<AppointmentEntry> {
  const request: AppointmentCheckInRequest = {
    checked_in_at: {
      timestamp: (checkInTime ?? new Date()).toISOString(),
      timezone: appointment.start.timezone
    }
  };
  const api = createAppointmentsApi();
  const {data} = await api.checkInAppointmentFacilitiesAppointmentsAppointmentIdCheckInPost(appointment.id, request);

  const entry = makeEntry(cleanAppointmentReferences(data));
  return entry;
}

async function checkOutAppointment(appointment: AppointmentEntry, checkOutTime?: Date): Promise<AppointmentEntry> {
  const request: AppointmentCheckOutRequest = {
    checked_out_at: {
      timestamp: (checkOutTime ?? new Date()).toISOString(),
      timezone: appointment.start.timezone
    }
  };
  const api = createAppointmentsApi();
  const {data} = await api.checkOutAppointmentFacilitiesAppointmentsAppointmentIdCheckOutPost(appointment.id, request);

  const entry = makeEntry(cleanAppointmentReferences(data));
  return entry;
}

async function rejectAppointment(appointment: AppointmentEntry, reason?: string): Promise<AppointmentEntry> {
  const request: AppointmentRejectRequest = {
    rejected_reasons: reason
  };
  const api = createAppointmentsApi();
  const {data} = await api.rejectAppointmentFacilitiesAppointmentsAppointmentIdRejectPost(appointment.id, request);

  const entry = makeEntry(cleanAppointmentReferences(data));
  return entry;
}

async function rescheduleAppointment(
  appointmentId: string,
  timezone: string,
  start: Date,
  end: Date,
  dockId?: string,
  override?: boolean
): Promise<Appointment> {
  start.setMilliseconds(0);
  end.setMilliseconds(0);
  const request: RescheduleAppointmentRequest = {
    start: {timestamp: start.toISOString(), timezone},
    end: {timestamp: end.toISOString(), timezone},
    dock_id: dockId
  };
  const api = createAppointmentsApi();
  const result = await api.rescheduleAppointmentFacilitiesAppointmentsAppointmentIdReschedulePost(
    appointmentId,
    request,
    override
  );
  const {data} = result;

  return cleanAppointmentReferences(data);
}

async function scheduleAppointment(
  appointmentId: string,
  timezone: string,
  start: Date,
  end: Date,
  dockId?: string,
  override?: boolean,
  matchedLoadTypeId?: string,
  allDay?: boolean
): Promise<Appointment> {
  start.setMilliseconds(0);
  end.setMilliseconds(0);
  const request: ScheduleAppointmentRequest = {
    start: {timestamp: start.toISOString(), timezone},
    end: {timestamp: end.toISOString(), timezone},
    dock_id: dockId,
    matched_load_type_id: matchedLoadTypeId,
    is_all_day: allDay
  };
  const api = createAppointmentsApi();
  const result = await api.scheduleAppointmentFacilitiesAppointmentsAppointmentIdSchedulePost(
    appointmentId,
    request,
    override
  );
  const {data} = result;

  return cleanAppointmentReferences(data);
}

export {
  getAppointments,
  getFacilityAppointments,
  createAppointment,
  updateAppointment,
  checkInAppointment,
  checkOutAppointment,
  rejectAppointment,
  cancelAppointment,
  getAppointment,
  rescheduleAppointment,
  scheduleAppointment
};
