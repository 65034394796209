import {Configuration, RateTablesApi} from '@shipwell/backend-core-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createRateTablesApi() {
  return new RateTablesApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

type GetFuelSurchargeTablesParameters = Parameters<
  typeof RateTablesApi.prototype.quotingRateTablesFuelSurchargeTablesGet
>;
type GetFuelSurchargeTables = {
  page?: GetFuelSurchargeTablesParameters[0];
  pageSize?: GetFuelSurchargeTablesParameters[1];
  ordering?: GetFuelSurchargeTablesParameters[2];
  q?: GetFuelSurchargeTablesParameters[3];
};

export const getFuelSurchargeTables = ({page = 1, pageSize = 50, ordering, q}: GetFuelSurchargeTables) => {
  return createRateTablesApi().quotingRateTablesFuelSurchargeTablesGet(page, pageSize, ordering, q);
};

export const deleteFuelSurchargeTable = (fuelSurchargeTableId: string) => {
  return createRateTablesApi().quotingRateTablesFuelSurchargeTablesFuelSurchargeTableIdDelete(fuelSurchargeTableId);
};
