import {
  ActionRef,
  ActionRefActionIdEnum,
  AttachedTrigger,
  WorkflowExecution,
  WorkflowExecutionEvent,
  WorkflowExecutionInstanceStatusEnum,
  WorkflowExecutionSkippedStepExplanation,
  TimerTrigger
} from '@shipwell/opus-sdk';
import {ActionParam} from '../workflows/utils/typed';
import {getStepsExtraEventInfos} from './eventsUtils/eventsUtilts';
import {createGrammaticList} from 'App/utils/grammar';
import {getCompanyDetails} from 'App/api/company/typed';

export const statusWithoutExecutionInstance = (summary: WorkflowExecution) => {
  if (summary.was_cancelled) {
    return 'CANCELLED';
  }
  if (summary.failed) {
    return 'FAILED';
  }
  if (summary.ended_at) {
    return 'SUCCESS';
  }
  return 'RUNNING';
};

export const getActionsWithoutExecutionInstance = (
  // eslint-disable-next-line @typescript-eslint/naming-convention
  actions: Array<{STEP_ID?: string}> = [],
  summary: WorkflowExecution
) => {
  return actions.map((action) => {
    // if step id is item in skipped step explanations array, set its status to skipped
    if (summary?.skipped_step_explanations?.some((step) => step?.skipped_step_id === action.STEP_ID)) {
      return {
        ...action,
        status: 'skipped'
      };
    }
    // set current running step status to failed or cancelled based on summary...
    // ...if current step has an 'ended_at' time stamp and summary was not cancelled nor failed...
    // ...status is assumed 'end' or successful
    if (summary?.execution_steps?.some((step) => step.step_id === action.STEP_ID)) {
      const [currentStep] = summary?.execution_steps?.filter((step) => step.step_id === action.STEP_ID);
      const status = currentStep.ended_at
        ? 'end'
        : summary.failed
        ? 'failed'
        : summary.was_cancelled
        ? 'cancelled'
        : 'start';
      return {
        ...action,
        status
      };
    }
    // if workflow was cancelled with no execution steps listed
    if (summary.was_cancelled) {
      return {
        ...action,
        status: 'cancelled'
      };
    }
    // if step id is not found in execution steps, it is assumed to be a future step
    return {
      ...action,
      status: 'future'
    };
  });
};

// reduces actions to an array of their latest instance in the execution events array
export const getActionsWithExecutionInstance = (
  executionEvents: WorkflowExecutionEvent[],
  actions: ActionRef[],
  status: WorkflowExecutionInstanceStatusEnum,
  skippedStepExplanations: WorkflowExecutionSkippedStepExplanation[]
) => {
  // eslint-disable-next-line
  const stepsExtraEventsInfos = getStepsExtraEventInfos(executionEvents as any, actions as any);

  // An event with the same step_id can occur multiple times in the events array...
  // ...the frontend is only concerned with the latest instance
  const latestEvents = executionEvents?.reduce((executionEvents, e) => {
    if (executionEvents?.some((executionEvent) => executionEvent.step_id === e.step_id)) {
      const newEvents = executionEvents.slice(0, -1);
      return [...newEvents, e];
    }
    return [...executionEvents, e];
  }, [] as WorkflowExecutionEvent[]);

  // an event in the events array may be an error, it does not contain a step_id...
  // ...we have to deduce that the error occured during the preceeding event
  const erroredEvents = latestEvents?.reduce((errors, latestEvent, i) => {
    if (latestEvent.step_id.includes('ERROR') && latestEvents[i - 1]) {
      return [
        ...errors,
        {
          ...latestEvents[i - 1],
          errorMessage: latestEvent.message
        }
      ];
    }
    return errors;
  }, [] as WorkflowExecutionEvent[]);

  // expand each action with status and error info as needed
  const expandedActions = actions?.map((action) => {
    // if step id is item in skipped step ids array, set its status to skipped
    const [skippedStepExplanation] = skippedStepExplanations.filter(
      (skippedStep) => skippedStep?.skipped_step_id === action.step_id
    );
    if (skippedStepExplanation) {
      return {
        ...action,
        skippedStepExplanation: skippedStepExplanation.detail_message,
        status: 'skipped'
      };
    }
    // check erroredEvents array to see if step contains an error
    if (erroredEvents?.some((erroredEvent) => erroredEvent.step_id === action.step_id)) {
      const [error] = erroredEvents.filter((errored) => errored.step_id === action.step_id);
      return {
        ...action,
        status: 'failed',
        error
      };
    }
    // if event is recorded in latestEvents, set action status to its message
    if (latestEvents?.some((event) => event.step_id === action.step_id)) {
      const [event] = latestEvents.filter((e) => e.step_id === action.step_id);
      const extraInfo = stepsExtraEventsInfos.find((info) => info.step === action.step_id);

      // if event has not ended and workflow was cancelled, set action status to cancelled.
      // if event is retrying, set to 'start
      // Otherwise, set to event message ('Start' | 'End')
      const actionStatus =
        event.message !== 'End' && status?.toLowerCase() === 'cancelled'
          ? 'cancelled'
          : event.message?.includes('Retrying')
          ? 'start'
          : event.message;
      return {
        ...action,
        extraInfo,
        status: actionStatus
      };
    }
    // if not skipped, nor found in latestEvents, it must be a future step
    // if workflow is cancelled, update all future steps to cancelled
    return {...action, status: status?.toLowerCase() === 'cancelled' ? 'cancelled' : 'future'};
  });

  return expandedActions;
};

export const loadingAction = {
  type: {
    value: '--',
    label: '--'
  },
  status: 'future'
};

const getCompanyNameById = async (companyId: string) => {
  try {
    const companyResponse = await getCompanyDetails(companyId);
    return companyResponse?.name;
  } catch (error) {
    return '--';
  }
};

const getTriggerTime = (attachedTriggers: AttachedTrigger[]) => {
  const [timerTrigger] =
    attachedTriggers?.filter((attachedTrigger) => attachedTrigger?.trigger?.trigger_type === 'TIMER') || [];
  const trigger = timerTrigger?.trigger as unknown as TimerTrigger;
  return trigger?.start_after_seconds;
};

const getParamValue = (params: ActionParam[], valueOf: string) => {
  const [selectedParam] = params?.filter((param) => param?.name == valueOf);
  return selectedParam?.value || '--';
};

const getEmailAction = (params: ActionParam[]) => {
  const recipients = getParamValue(params, 'recipients');
  return {
    to: createGrammaticList(recipients as string[])
  };
};

const getTenderAction = async (params: ActionParam[]) => {
  const companyId = getParamValue(params, 'tender_to_company') as string;
  const exp = getParamValue(params, 'expires_after_seconds');
  const to = await getCompanyNameById(companyId);
  return {to, exp};
};

const getLoadboardAction = (params: ActionParam[], attachedTriggers: AttachedTrigger[]) => {
  return {
    amount: getParamValue(params, 'buy_it_now_amount'),
    currency: getParamValue(params, 'buy_it_now_amount_currency'),
    exp: getTriggerTime(attachedTriggers)
  };
};

const getSpotNegotiationsAction = async (params: ActionParam[], attachedTriggers: AttachedTrigger[]) => {
  const companyIds = getParamValue(params, 'carriers') as Array<{company_id: string}>;
  const companyNames = await Promise.all(companyIds.map(async (id) => await getCompanyNameById(id.company_id)));
  return {
    to: createGrammaticList(companyNames),
    exp: getTriggerTime(attachedTriggers)
  };
};

// opus SDK does not provide types for params
type ExtendedActionType = ActionRef & {
  params: ActionParam[];
};

// don't really understand this, seems to account for workflows created prior to a major opus change
type LegacyActionParams = {
  // Tender
  tender_to_company?: {shipwell_vendor?: {name: string}};
  expires_after_seconds?: {value: number};
  // Post to Loadboard
  buy_it_now_amount?: number;
  buy_it_now_amount_currency?: string;
  step_timer?: {value: number};
  // Email
  recipients?: Array<{label: string}>;
  // Spot Negotiations
  carriers?: Array<{carrierName: string}>;
};
type LegacyExtendedActionType = {type: {value: ActionRefActionIdEnum}} & LegacyActionParams;

export type ActionWithParams = ExtendedActionType | LegacyExtendedActionType;

// augments the incoming action with needed data for messsaging to the user
export const getActionParams = async (action?: ActionWithParams) => {
  let actionParams = {};
  // action_id is only available on objects coming from the new getWorkflowExecutionShipmentInstance
  // ...the else block below is used as a fallback if the workflow was created before the above endpoint was added
  if (action && 'action_id' in action) {
    switch (action.action_id) {
      case ActionRefActionIdEnum.Tender:
        actionParams = await getTenderAction(action.params);
        break;
      case ActionRefActionIdEnum.PostToLoadboard:
        actionParams = getLoadboardAction(action.params, action.attached_triggers as AttachedTrigger[]);
        break;
      case ActionRefActionIdEnum.SendEmail:
        actionParams = getEmailAction(action.params);
        break;
      case ActionRefActionIdEnum.CreateSpotNegotiations:
        actionParams = await getSpotNegotiationsAction(action.params, action.attached_triggers as AttachedTrigger[]);
        break;
    }
  } else {
    switch (action?.type?.value) {
      case ActionRefActionIdEnum.Tender:
        actionParams = {
          to: action?.tender_to_company?.shipwell_vendor?.name,
          exp: action?.expires_after_seconds?.value
        };
        break;
      case ActionRefActionIdEnum.PostToLoadboard:
        actionParams = {
          amount: action?.buy_it_now_amount,
          currency: action?.buy_it_now_amount_currency,
          exp: action?.step_timer?.value
        };
        break;
      case ActionRefActionIdEnum.SendEmail:
        actionParams = {
          to: createGrammaticList(action?.recipients?.map((r) => r.label) || [])
        };
        break;
      case ActionRefActionIdEnum.CreateSpotNegotiations:
        actionParams = {
          to: createGrammaticList(action?.carriers?.map((c) => c.carrierName) || []),
          exp: action?.step_timer?.value
        };
        break;
    }
  }
  return {...action, actionParams};
};

export type ActionParams = Awaited<ReturnType<typeof getActionParams>>;
