import {PurchaseOrder, RelatedOrder, Shipment, StopDetail} from '@shipwell/backend-core-sdk';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {compose} from 'recompose';
import {Popover} from '@shipwell/shipwell-ui';
import {getOrderUrl} from 'App/containers/Shipment/utils/orderUtils';
import {pickupIcon, deliveryIcon} from 'App/utils/globals';
import {useRemovePurchaseOrderFromShipment} from 'App/data-hooks';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {parseV3ApiError} from 'App/api/typedUtils';
import {shipmentsShipmentIdGet} from 'App/actions/shipmentdetails';

export type ShipmentStopPurchaseOrdersType = {
  shipmentStop: StopDetail;
  purchaseOrders: PurchaseOrder[];
  shipment: Shipment;
  onRemovePurchaseOrder: (order?: PurchaseOrder) => void;
  dispatch?: Dispatch<Action<typeof shipmentsShipmentIdGet>>;
} & WithStatusToastProps;

const ShipmentStopPurchaseOrdersBase = ({
  shipmentStop,
  purchaseOrders,
  shipment,
  onRemovePurchaseOrder,
  setError,
  setSuccess,
  dispatch
}: ShipmentStopPurchaseOrdersType) => {
  const {removePurchaseOrderFromShipmentAsync} = useRemovePurchaseOrderFromShipment();

  const handleRemoveOrder = async (
    relatedOrder: RelatedOrder,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setIsOpen(false);
    if (relatedOrder.self_link?.includes('/v2/')) {
      onRemovePurchaseOrder(purchaseOrders.find((po) => po?.id === relatedOrder.order_id));
    } else {
      try {
        const removePurchaseOrderData = await removePurchaseOrderFromShipmentAsync({
          shipmentId: shipment.id,
          orderId: relatedOrder.order_id
        });
        setSuccess('Order removed', removePurchaseOrderData.statusText);
        dispatch && dispatch(shipmentsShipmentIdGet(shipment.id, {}) as unknown as Action);
      } catch (error) {
        const {title, detail} = parseV3ApiError(error);
        setError(title, detail);
        setIsOpen(false);
      }
    }
  };
  return (
    <div className="flex min-h-[49px] w-full flex-wrap items-center rounded border border-sw-border">
      {shipmentStop.related_orders?.map((order) => (
        <div key={`${order.order_id}:${shipmentStop.id}`}>
          <Popover
            placement="top"
            portal
            showArrow
            theme="dark"
            trigger={({
              setIsOpen,
              setTriggerElement
            }: {
              setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
              setTriggerElement: (el: HTMLElement | null) => void;
            }) => (
              <div className="relative m-1 inline-flex rounded-sm bg-[#dce3e8] px-3 py-1.5" ref={setTriggerElement}>
                <span className="flex items-center">
                  <div className="mr-2">{shipmentStop.is_pickup ? pickupIcon : deliveryIcon}</div>
                  <span className="mr-1">Order</span>
                  <Link to={getOrderUrl(order.self_link, order.order_id)}>#{order.order_number}</Link>
                  <span
                    data-testid="remove-order-button"
                    className="flaticon-multiply ml-3 flex size-3 cursor-pointer justify-center text-[8px]"
                    onClick={() => setIsOpen((prev) => !prev)}
                  />
                </span>
              </div>
            )}
          >
            {({setIsOpen}: {setIsOpen: React.Dispatch<React.SetStateAction<boolean>>}) => (
              <div className="p-2.5">
                <h3 className="mt-0 text-sm font-bold text-[#f6cf00]">Delete order</h3>
                <p>Are you sure you want to delete</p>
                <p>Order #{order.order_number}</p>
                <p>
                  This order will be removed from all
                  <br /> stops in the shipment
                </p>
                <div className="flex justify-between">
                  <button className="btn btn-secondary" onClick={() => setIsOpen(false)}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" onClick={() => void handleRemoveOrder(order, setIsOpen)}>
                    Confirm
                  </button>
                </div>
              </div>
            )}
          </Popover>
        </div>
      ))}
    </div>
  );
};
export const ShipmentStopPurchaseOrders = compose<ShipmentStopPurchaseOrdersType, ShipmentStopPurchaseOrdersType>(
  WithStatusToasts,
  connect()
)(ShipmentStopPurchaseOrdersBase);
