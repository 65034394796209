import {CustomField, TenderAcceptRejectDetailsStatusEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {WorkflowExecutionInstanceStatusEnum} from '@shipwell/opus-sdk';
import {Column} from '@tanstack/react-table';
import invariant from 'tiny-invariant';
import {Columns, DashboardColumnIds, Filters, ShippingDashboardRowWithCustomDataSchema} from './DashboardUtilTypes';
import {EnumRelativeDates} from './date/RelativeDatesEnum';
import {tryGetDateFromRelativeDate} from './date/dateUtils';
import {ShipmentModeEnum} from 'App/utils/globalsTyped';
import {enumToOptionConverter} from 'App/utils/enumToOptionConverter';
import {getColumnData} from 'App/containers/Dashboard/columns';

const mapCustomReferenceFieldToReactTableColumn = (referencesCustomFields: CustomField[]) => {
  return referencesCustomFields.map((customReferenceField) => {
    const customReferenceFieldId = customReferenceField.id || '';
    return {
      Header: customReferenceField.label,
      id: customReferenceField.name,
      accessor: (row: ShippingDashboardRowWithCustomDataSchema) =>
        row?.custom_data?.shipwell_custom_data?.shipment?.[customReferenceFieldId] || '--'
    };
  });
};

export const mapCustomReferenceFieldsToDashboardColumnNames = (referencesCustomFields: CustomField[]) => {
  return referencesCustomFields.reduce((dashboardColumnNames, customReferenceFieldColumn) => {
    const {name, label} = customReferenceFieldColumn;
    return {...dashboardColumnNames, [name]: label};
  }, {});
};

export const mapCustomReferenceFieldsToDashboardColumns = (referencesCustomFields: CustomField[]) => {
  const customReferenceFieldColumns = mapCustomReferenceFieldToReactTableColumn(referencesCustomFields);
  return customReferenceFieldColumns.reduce((columnsWithCustomReferences, customReferenceFieldColumn) => {
    const {id} = customReferenceFieldColumn;
    return {...columnsWithCustomReferences, [id]: customReferenceFieldColumn};
  }, {});
};

export const initialFilterState = {
  status: [],
  mode: [],
  alerts: '',
  archived: [],
  parcelPickupStatus: [],
  open: '',
  hasBill: '',
  hasInvoice: '',
  loadBoardEnabled: '',
  workflowExecutionStatus: [],
  drayageContainerReturnDateGte: '',
  drayageContainerReturnDateLte: '',
  createdAtGte: '',
  createdAtLte: '',
  dropoffGte: '',
  dropoffLte: '',
  drayageEstimatedArrivalDateGte: '',
  drayageEstimatedArrivalDateLte: '',
  drayageLastFreeDateGte: '',
  drayageLastFreeDateLte: '',
  pickupGte: '',
  pickupLte: '',
  drayageReleaseDateGte: '',
  drayageReleaseDateLte: '',
  tenderStatus: [],
  tags: [],
  tagsExclude: [],
  deliveryStopsStateProvince: [],
  deliveryStopsContains: [],
  deliveryRadius: '',
  deliveryLat: '',
  deliveryLon: '',
  deliveryStopsLabel: '',
  deliveryStopsCompanyNameContains: [],
  pickupStopsStateProvince: [],
  pickupStopsContains: [],
  pickupRadius: '',
  pickupLat: '',
  pickupLon: '',
  pickupStopsLabel: '',
  vendorId: [],
  vendor: [],
  statusExclude: [],
  customer: [],
  customerId: [],
  drayageContainerNumberContains: [],
  drayageSealNumberContains: [],
  reps: [],
  repLabels: [],
  accessorials: [],
  accessorialLabels: [],
  serviceLevel: [],
  serviceLevelLabels: [],
  createdByUserId: [],
  createdBy: [],
  createdBySource: [],
  shipmentShipwellCustomData: [],
  //filter components not implemented for params below
  activeVendorAssignment: [],
  alertTypes: [],
  bolNumber: [],
  bolNumberContains: [],
  bolNumberExclude: [],
  createdByUserContains: [],
  createdByUserName: [],
  createdByUserNameContains: [],
  customerContains: [],
  customerReferenceNumber: [],
  customerReferenceNumberContains: [],
  customerReferenceNumberExclude: [],
  destinationStateProvince: [],
  destinationContains: [],
  destinationRadius: '',
  destinationLat: '',
  destinationLon: '',
  destinationLabel: '',
  drayageBookingNumber: [],
  drayageChassisNumber: [],
  drayageContainerNumber: [],
  driverAssigned: [],
  equipmentType: [],
  equipmentTypeContains: [],
  fedexAccount: [],
  groupId: [],
  include: [],
  loadBoardId: [],
  loadBoardIdContains: [],
  modeContains: [],
  nameContains: [],
  originContains: [],
  originStateProvince: [],
  pickupStopsCompanyNameContains: [],
  pickupId: [],
  pickupNumber: [],
  pickupNumberContains: [],
  pickupNumberExclude: [],
  proNumber: [],
  proNumberContains: [],
  proNumberExclude: [],
  powerUnit: [],
  purchaseOrderNumber: [],
  purchaseOrderNumberContains: [],
  purchaseOrderNumberExclude: [],
  referenceId: [],
  referenceIdContains: [],
  referenceIdExclude: [],
  role: [],
  shipmentAccessorials: [],
  shipmentCreatedAtGte: [],
  shipmentCreatedAtLte: [],
  shipmentStatus: [],
  shipmentStatusExclude: [],
  shipmentStatusContains: [],
  stopsContains: [],
  stopsCompanyNameContains: [],
  stopsStateProvince: [],
  timelineLastUpdatedAtGte: [],
  timelineLastUpdatedAtLte: [],
  timeFrame: [],
  totalMilesGte: [],
  totalMilesLte: [],
  upsAccount: [],
  vendorContains: [],
  totalWeightOverrideGte: [],
  totalWeightOverrideLte: [],
  originRadius: [],
  originLat: [],
  originLon: [],
  showClonedResults: []
};

export type InitialFiltersType = typeof initialFilterState;

export const dateFilters: (keyof InitialFiltersType)[] = [
  'createdAtLte',
  'createdAtGte',
  'drayageContainerReturnDateGte',
  'drayageContainerReturnDateLte',
  'dropoffGte',
  'dropoffLte',
  'drayageEstimatedArrivalDateGte',
  'drayageEstimatedArrivalDateLte',
  'drayageLastFreeDateGte',
  'drayageLastFreeDateLte',
  'pickupGte',
  'pickupLte',
  'drayageReleaseDateGte',
  'drayageReleaseDateLte'
];

const isDynamicDateFilter = (dateFilter: string) =>
  Object.values(EnumRelativeDates).includes(dateFilter as EnumRelativeDates);

export const parseDynamicDateFilter = (dateFilter: string): string[] => {
  const regularDate = tryGetDateFromRelativeDate(dateFilter);
  return regularDate ? [regularDate] : [];
};

export const transformDynamicDateFilters = (filters: Filters): Filters => {
  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (
        dateFilters.includes(key as keyof InitialFiltersType) &&
        Array.isArray(filters[key]) &&
        isDynamicDateFilter(filters[key][0])
      ) {
        filters[key] = parseDynamicDateFilter(filters[key][0]);
      }
    });
  }
  return filters;
};

// determines if filter values were saved from the old dashboard
const isLegacy = (arr: {label: string; value: string}[] | string[]): arr is {label: string; value: string}[] =>
  arr?.some((arr) => typeof arr !== 'string');

export const getVendorCustomerFilterValues = (
  filters:
    | {
        vendorId?: string[];
        customerId?: string[];
        vendor?: {label: string; value: string}[] | string[];
        customer?: {label: string; value: string}[] | string[];
      }
    | null
    | undefined
): Filters => {
  if (filters) {
    if (filters.vendor && isLegacy(filters.vendor)) {
      filters.vendorId = filters.vendor?.map((vendor) => vendor.value);
      filters.vendor = filters.vendor?.map((vendor) => vendor.label);
    }
    if (filters.customer && isLegacy(filters.customer)) {
      filters.customerId = filters.customer?.map((customer) => customer.value);
      filters.customer = filters.customer?.map((customer) => customer.label);
    }
  }
  //we have to typecast here because otherwise the compiler thinks that we are returning
  //vendor and customer as an object with a label and value key.
  return filters as Filters;
};

export const transformShipmentsDashboardFilters = (filters: Filters): Filters => {
  const filtersCopy = filters ? {...filters} : undefined;
  transformDynamicDateFilters(filtersCopy);
  getVendorCustomerFilterValues(filtersCopy);
  return filtersCopy;
};

//takes the legacy column id from saved dashboards
//and maps them to column id that is sortable in the dashboard endpoint
const legacyDashboardColumnIdMap: Record<string, DashboardColumnIds> = {
  pickup_location: 'pickup_location',
  pickup_date: 'pickup',
  delivery_location: 'dropoff_location',
  delivery_date: 'dropoff',
  equipment: 'equipment_type',
  createdAt: 'created_at',
  buy_it_now_amount: 'book_now',
  bol: 'bol_number',
  container_number: 'drayage_container_number',
  created_by: 'created_by',
  customer_reference: 'customer_reference_number',
  estimated_container_arrival: 'drayage_estimated_arrival_date',
  house_bol_number: 'drayage_house_bol_number',
  line_items: 'items',
  miles: 'total_miles',
  pickup_number: 'pickup_number',
  po: 'po_number',
  pro: 'pro_number',
  seal_number: 'drayage_seal_number',
  accessorials: 'shipment_accessorials',
  target_rate_amount: 'target_rate_amount',
  timeline_updated: 'timeline_last_updated_at'
};

export const dashboardLabelMap: {[K in DashboardColumnIds]?: string} = {
  reference_id: 'ID',
  multi_stage_id: 'Multi-Stage ID',
  number_of_stages: 'Stages',
  pickup_location: 'Pickup',
  pickup: 'Pickup Date',
  pickup_number: 'Pickup #',
  dropoff_location: 'Delivery',
  dropoff: 'Delivery Date',
  bol_number: 'BOL #',
  drayage_house_bol_number: 'House BOL #',
  po_number: 'PO #',
  power_unit_name: 'Power Unit',
  pro_number: 'PRO #',
  items: 'Line Items',
  max_buy: 'Max Buy Amount',
  upcoming_etas: 'ETAs',
  equipment_type: 'Equipment',
  created_at: 'Created',
  drayage_container_number: 'Container #',
  drayage_estimated_arrival_date: 'Estimated Container Arrival',
  total_miles: 'Distance',
  drayage_seal_number: 'Seal #',
  target_rate_amount: 'Target Rate',
  timeline_last_updated_at: 'Timeline Updated',
  trailer_name: 'Trailer #',
  customer_reference_number: 'Customer Reference #',
  load_board_id: 'Load Board ID',
  calculated_ui_service_level: 'Service Level',
  MASTER_AIR_WAYBILL: 'Master Airway Bill Number',
  HOUSE_AIR_WAYBILL: 'House Airway Bill Number'
};

export const transformShipmentsDashboardColumns = (savedDashboardColumnIds: Columns): string[] => {
  const sortableColumnIds: Columns = [];
  savedDashboardColumnIds?.forEach((columnId) => {
    const sortableColumnId = legacyDashboardColumnIdMap[columnId];
    if (sortableColumnId) {
      sortableColumnIds.push(sortableColumnId);
    }
    //if there is not a matching legacy column id that needs to map to a sortable id,
    //we can assume that the column id is already sortable.
    else sortableColumnIds.push(columnId);
  });
  return sortableColumnIds;
};

export const modeOptions = [
  {label: 'Full Truckload', value: ShipmentModeEnum.FTL},
  {label: 'Less Than Truckload', value: ShipmentModeEnum.LTL},
  {label: 'Volume Less Than Truckload', value: ShipmentModeEnum.VLTL},
  {label: 'Drayage', value: ShipmentModeEnum.DRAYAGE},
  {label: 'Rail', value: ShipmentModeEnum.RAIL},
  {label: 'Parcel', value: ShipmentModeEnum.PARCEL},
  {label: 'Air', value: ShipmentModeEnum.AIR}
];

export const workflowOptions = [
  {label: 'Cancelled', value: WorkflowExecutionInstanceStatusEnum.Cancelled},
  {label: 'Complete', value: WorkflowExecutionInstanceStatusEnum.Success},
  {label: 'Failed', value: WorkflowExecutionInstanceStatusEnum.Failed},
  {label: 'Running', value: WorkflowExecutionInstanceStatusEnum.Running}
];

export const tenderStatusOptions = enumToOptionConverter(TenderAcceptRejectDetailsStatusEnum).sort((a, b) =>
  a.label.localeCompare(b.label)
);

export const getColumnExportIds = (columns: Column<ShippingDashboardRowWithCustomDataSchema>[]): string[] =>
  columns.reduce<string[]>((previous, currentColumn) => {
    const customFieldType = currentColumn.columnDef.meta?.customFieldType;
    //Only pass the custom data column once for each custom field type.
    //The backend merges all of the custom fields of the same type into one custom field when the column is passed
    //to the download csv route, and you automatically get all custom fields of that type included the csv.
    if (customFieldType && !previous.includes(customFieldType)) {
      previous.push(customFieldType);
    }
    if (currentColumn.columnDef.id) {
      previous.push(currentColumn.columnDef.id);
    }
    return previous;
  }, []);

/**
 * This function filters a list of columns depending on feature flag values.
 * The only restriction here is that the flags have boolean values; this utility
 * does not work with flags of different data types.
 */
export const filterDashboardColumnsByFlagValue = ({
  columnData,
  flags,
  flagsColumnMap
}: {
  columnData: ReturnType<typeof getColumnData>;
  flags?: Record<string, boolean>;
  flagsColumnMap?: Record<string, string[]>;
}) =>
  columnData.filter((column) => {
    invariant(column.id, 'Column id is required');
    if (flags) {
      for (const flagKey in flagsColumnMap) {
        // Exclude the column if its flag value is false,
        // and the flag column map includes the column id
        if (flags[flagKey] === false && flagsColumnMap[flagKey].includes(column.id)) {
          return false;
        }
      }
    }
    // Include the column otherwise
    return true;
  });

/**
 * This function filters a list of columns depending on user permission values.
 */
export const filterDashboardColumnsByPermissionValue = ({
  columnData,
  permissions,
  userPermissionColumnMap
}: {
  columnData: ReturnType<typeof getColumnData>;
  permissions?: string[];
  userPermissionColumnMap?: Record<string, string[]>;
}) =>
  columnData.filter((column) => {
    invariant(column.id, 'Column id is required');
    if (permissions) {
      for (const permissionKey in userPermissionColumnMap) {
        // Exclude the column if the permission key is not
        // found in the user permissions list
        if (!permissions?.includes(permissionKey) && userPermissionColumnMap[permissionKey].includes(column.id)) {
          return false;
        }
      }
    }
    // Include the column otherwise
    return true;
  });
