import {Fragment, useMemo} from 'react';
import classNames from 'classnames';
import {SvgIcon} from '@shipwell/shipwell-ui';
import {AppointmentStatusEnum} from '@shipwell/tempus-sdk';

import {ReferencePreferenceOrder, StatusToColor, SvgIconColors, SvgIconNames} from '../../constants';

import {AppointmentEntry, ViewMode} from 'App/data-hooks/appointments/types';

import './AppointmentCalendarEvent.scss';

function AppointmentIcon({appointment}: {appointment: AppointmentEntry}) {
  const {status} = appointment;
  const iconName = SvgIconNames[status];
  const color = SvgIconColors[status];
  // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
  return iconName ? <SvgIcon className="-ml-[3px]" color={color} name={iconName} width="16" height="16" /> : null;
}

export function AppointmentCalendarEntry(props: {appointment: AppointmentEntry; viewMode: ViewMode}) {
  const {appointment, viewMode} = props;
  const {reason, name, carrierName, status, scheduledResourceReferenceId, durationMs, references} = appointment;

  const referenceIdDisplayText: string = useMemo(() => {
    if (!references?.length) {
      return scheduledResourceReferenceId;
    }
    const bestReferenceType = ReferencePreferenceOrder.find((qualifier) =>
      references.some((r) => r.qualifier === qualifier)
    );
    const referencesValues = references
      .filter((r) => r.qualifier === bestReferenceType)
      .map((r) => r.value)
      .sort(); // make the order predictable for tests
    if (referencesValues.length === 0) {
      return scheduledResourceReferenceId;
    }
    if (referencesValues.length === 1) {
      return referencesValues[0];
    }
    return `${referencesValues[0]} (+${referencesValues.length - 1})`;
  }, [references, scheduledResourceReferenceId]);

  const nameParts = [
    referenceIdDisplayText && <span className="sw-appointment-shipment-id">{referenceIdDisplayText}</span>,
    reason && !referenceIdDisplayText && <span className="sw-appointment-reason">{reason}</span>,
    name && !referenceIdDisplayText && <span className="sw-appointment-name">{name}</span>,
    carrierName && <span className="sw-appointment-carrier">{carrierName}</span>
  ].filter(Boolean);

  const rootClasses = [
    `fc-event-main-frame`,
    `sw-appointment`,
    `sw-appointment-status-${status}`,
    'rounded',
    'text-sw-text',
    'flex',
    'px-1',
    'py-[2px]',
    'w-full',
    'shadow-lg'
  ];

  const isShortAppointment = durationMs <= 22.5 * 60 * 1000 || appointment.allDay;

  if (isShortAppointment) {
    rootClasses.push(`sw-appointment-short`);
  }
  rootClasses.push(viewMode === ViewMode.Week ? 'sw-appointment-week-view' : 'sw-appointment-day-view');

  nameParts[0] = (
    <>
      <div className="flex items-center gap-[2px] text-[8px] leading-[8px]">
        <AppointmentIcon appointment={appointment} />
        {nameParts[0]}
      </div>
      {isShortAppointment ? ' • ' : null}
    </>
  );

  return (
    <div
      className={classNames(rootClasses, StatusToColor[status], {
        'text-xxs flex-col': !isShortAppointment,
        'text-[8px] flex-row items-center gap-px leading-[10px] px-[2px]': isShortAppointment,
        'border-1 border-sw-border-alternate': AppointmentStatusEnum.Unscheduled === status
      })}
    >
      {nameParts.map((content, i) => (
        <Fragment key={`key_${i}`}>{content}</Fragment>
      ))}
    </div>
  );
}
