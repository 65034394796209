import PropTypes from 'prop-types';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';

const EditActionButtons = ({isSubmitting, dirty, onCancelEdit}) => (
  <div className="shipment__edit-form-buttons">
    <DeprecatedButton
      type="button"
      variant="secondary"
      disabled={isSubmitting}
      onClick={onCancelEdit}
      className="shipment__edit-form-buttons-cancel-edit"
      name="cancel-edit"
    >
      Cancel
    </DeprecatedButton>
    <DeprecatedButton
      type="submit"
      disabled={isSubmitting || !dirty}
      className="shipment__edit-form-buttons-submit-edit"
    >
      {isSubmitting ? (
        <>
          <SvgIcon name="Refresh" className="shipment__edit-form-buttons-button-icon rotate" /> Saving...
        </>
      ) : (
        'Save'
      )}
    </DeprecatedButton>
  </div>
);

EditActionButtons.propTypes = {
  isSubmitting: PropTypes.bool,
  dirty: PropTypes.bool,
  onCancelEdit: PropTypes.func
};

EditActionButtons.defaultProps = {
  isSubmitting: false,
  dirty: false,
  onCancelEdit: () => {}
};

export default EditActionButtons;
