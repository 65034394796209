import get from 'lodash/get';
import {Field, FormSection} from 'redux-form';
import {
  FedexShipmentOptions,
  UPSShipmentOptions,
  USPSShipmentOptions
} from '@shipwell/backend-core-singlerequestparam-sdk';
import SelectField from 'App/formComponents/fields/select';
import InputField from 'App/formComponents/fields/input';
import {truncateNumber} from 'App/utils/globals';
import {fedexServiceOptions, upsServiceOptions, uspsServiceOptions} from 'App/utils/parcelConstants';
import {countries} from 'App/utils/countries';
import {useGetUpsAccountsQuery} from 'App/data-hooks/parcel/UPS/hooks/useGetUpsAccountsQuery';
import {useGetFedExAccountsQuery} from 'App/data-hooks/parcel/FedEx/hooks/useGetFedexAccounts';
import {PartialNewQuoteFormValues} from 'App/formComponents/formSections/shipmentServices';
import './styles.scss';

type LegacyServiceOptionsProps = {
  values?: PartialNewQuoteFormValues;
  triggerFieldTouch: (field: string) => void;
  setFieldValue: (field: string, value: unknown) => void;
  parcelLabel: string;
  isFedExParcel: boolean;
  isUPSParcel: boolean;
  isUSPSParcel: boolean;
};

export const LegacyServiceOptions = ({
  values = {},
  triggerFieldTouch,
  setFieldValue,
  parcelLabel,
  isFedExParcel,
  isUPSParcel,
  isUSPSParcel
}: LegacyServiceOptionsProps) => {
  const {
    fedExAccounts,
    fedExQuery: {isInitialLoading: isLoadingFedexAccounts}
  } = useGetFedExAccountsQuery();
  const {
    upsAccounts,
    upsQuery: {isInitialLoading: isLoadingUpsAccounts}
  } = useGetUpsAccountsQuery();
  const isLoadingAccounts = isLoadingFedexAccounts || isLoadingUpsAccounts;

  let serviceCodeOptions = fedexServiceOptions;
  if (isUPSParcel) {
    serviceCodeOptions = upsServiceOptions;
  }
  if (isUSPSParcel) {
    serviceCodeOptions = uspsServiceOptions;
  }

  const providerOptionsField = `${parcelLabel.toLowerCase()}_specific_options`;
  const serviceOptions = get(values, providerOptionsField) as
    | undefined
    | null
    | FedexShipmentOptions
    | UPSShipmentOptions
    | USPSShipmentOptions;

  const has3rdPartyBilling = (billingType?: string) => {
    return !!billingType && ['COLLECT', 'RECIPIENT', 'THIRD_PARTY'].includes(billingType);
  };

  const onBillingTypeChange = (value: string) => {
    if (value === 'SENDER') {
      setFieldValue(`${providerOptionsField}.bill_to_account_number`, '');
      setFieldValue(`${providerOptionsField}.bill_to_country_code`, '');
      setFieldValue(`${providerOptionsField}.bill_to_postal_code`, '');
    } else if (has3rdPartyBilling(value) && !serviceOptions?.bill_to_country_code) {
      setFieldValue(`${providerOptionsField}.bill_to_country_code`, 'US');
    }
  };

  return (
    <FormSection name={providerOptionsField}>
      <div className="field-grid">
        {!isUSPSParcel ? (
          <div className="grid-item-2">
            <Field
              req
              simpleValue
              label={`${parcelLabel} Account`}
              name="account"
              placeholder={`${parcelLabel} Account`}
              options={(isFedExParcel ? fedExAccounts : upsAccounts)?.map((account) => ({
                id: account.id,
                name: account.account_number
              }))}
              component={SelectField}
              isLoading={isLoadingAccounts}
            />
          </div>
        ) : null}
        <div className="grid-item-2">
          <Field
            simpleValue
            label="Standard Service"
            name="service_code"
            placeholder="Standard Service"
            options={serviceCodeOptions}
            component={SelectField}
          />
        </div>
        <div className="grid-item-2">
          <Field
            simpleValue
            clearable
            label="Signature Type"
            name="signature_option_signature_type"
            placeholder="Signature Type"
            options={[
              {id: 'SERVICE_DEFAULT', name: 'Service Default'},
              {id: 'NO_SIGNATURE', name: 'No Signature Required'},
              {id: 'INDIRECT_SIGNATURE', name: 'Indirect Signature Required'},
              {id: 'DIRECT_SIGNATURE', name: 'Direct Signature Required'},
              {id: 'ADULT_SIGNATURE', name: 'Adult Signature Required'}
            ]}
            component={SelectField}
          />
        </div>
        {isFedExParcel ? (
          <div className="field-grid grid-item-2">
            <div className="grid-item-1">
              <Field
                simpleValue
                label="Dropoff Options"
                name="dropoff_type"
                placeholder="Dropoff Options"
                options={[
                  {id: 'REGULAR_PICKUP', name: 'Regular Pickup'},
                  {id: 'STATION', name: 'Station'},
                  {id: 'REQUEST_COURIER', name: 'Request Courier'},
                  {id: 'BUSINESS_SERVICE_CENTER', name: 'Business Service Center'},
                  {id: 'DROP_BOX', name: 'Drop Box'}
                ]}
                component={SelectField}
              />
            </div>
          </div>
        ) : null}
        <div className="grid-item-2 card-subheader">
          <strong>Billing Options</strong>
        </div>

        <div className="grid-item-1">
          <Field
            simpleValue
            label="Bill To Payment Options"
            name="bill_to_payment_type"
            placeholder="Bill To Payment Options"
            options={[
              {id: 'COLLECT', name: 'Collect'},
              {id: 'RECIPIENT', name: 'Recipient'},
              {id: 'SENDER', name: 'Sender'},
              {id: 'THIRD_PARTY', name: 'Third Party'}
            ]}
            // e: unknown is the only way to not cause a TS error here. anyway we dont' need it
            onChange={(e: unknown, value: string) => {
              onBillingTypeChange(value);
            }}
            // type cast to 'select' was required because we pass in an onChange function with a parameter signature
            component={SelectField as unknown as 'select'}
          />
        </div>
        {has3rdPartyBilling(serviceOptions?.bill_to_payment_type) ? (
          <>
            <div className="grid-item-1">
              <Field
                req
                label="Bill To Account ID"
                name="bill_to_account_number"
                placeholder="Account ID"
                component={InputField}
              />
            </div>
            <div className="grid-item-1">
              <Field
                req
                label="Country"
                name="bill_to_country_code"
                placeholder="Country"
                labelKey="label"
                valueKey="value"
                simpleValue
                options={countries}
                component={SelectField}
              />
            </div>
            <div className="grid-item-1">
              <Field
                req
                label="Postal Code"
                name="bill_to_postal_code"
                placeholder="Postal Code"
                component={InputField}
              />
            </div>
          </>
        ) : null}
        <div className="grid-item-2 card-subheader">
          <strong>Additional Services</strong>
        </div>
        {isFedExParcel ? (
          <>
            <div className="grid-item-1">
              <Field
                simpleValue
                clearable
                label="Battery Material Type"
                name="battery_material_type"
                placeholder="Battery Material Type"
                options={[
                  {id: 'LITHIUM_ION', name: 'Lithium Ion'},
                  {id: 'LITHIUM_METAL', name: 'Lithium Metal'}
                ]}
                component={SelectField}
              />
            </div>
            <div className="grid-item-1">
              <Field
                simpleValue
                clearable
                label="Battery Packaging Type"
                name="battery_packaging_type"
                placeholder="Battery Packaging Type"
                options={[
                  {id: 'CONTAINED_IN_EQUIPMENT', name: 'Contained in Equipment'},
                  {id: 'PACKED_WITH_EQUIPMENT', name: 'Packed with Equipment'}
                ]}
                component={SelectField}
              />
            </div>
          </>
        ) : null}
        {isFedExParcel || isUPSParcel ? (
          <div className="field-grid grid-item-2">
            <div className="grid-item-1">
              <Field
                simpleValue
                clearable
                label="Alcohol Recipient Type"
                name="alcohol_recipient_type"
                placeholder="Alcohol Recipient Type"
                options={[
                  {id: 'CONSUMER', name: 'Consumer'},
                  {id: 'LICENSEE', name: 'Licensee'}
                ]}
                component={SelectField}
              />
            </div>
          </div>
        ) : null}
        <div className="field-grid grid-item-2">
          <div className="grid-item-1">
            <Field
              simpleValue
              clearable
              label="C.O.D Options"
              name="cod_collection_type"
              placeholder="C.O.D Options"
              options={[
                {id: 'ANY', name: 'Any'},
                {id: 'COMPANY_CHECK', name: 'Company Check'},
                {id: 'GUARANTEED_FUNDS', name: 'Guaranteed Funds'},
                {id: 'PERSONAL_CHECK', name: 'Personal Check'}
              ]}
              onChange={() => {
                // set related field touched so that validation error displays before submit
                triggerFieldTouch(`[${providerOptionsField}].cod_collection_amount`);
              }}
              component={SelectField}
            />
          </div>
          <div className="grid-item-1">
            <Field
              req={serviceOptions?.cod_collection_type}
              label={`Amount to Collect on Delivery (${values.preferred_currency || 'USD'})`}
              name="cod_collection_amount"
              placeholder="Amount"
              component={InputField}
              normalize={(value: string) => (value === '' ? null : truncateNumber(value))}
            />
          </div>
        </div>
      </div>
    </FormSection>
  );
};
