import {ReactNode, useState} from 'react';
import {IconButton, ToggleSwitch} from '@shipwell/shipwell-ui';
import {useFormikContext} from 'formik';
import {uniq} from 'lodash';
import {PermissionsByActionType, PermissionsCategory} from '../../permissionsCategories';
import {UserWithPermissions} from '../UserForm';
import {PermissionsCheckbox} from './PermissionsCheckbox';

export const RowLayout = ({
  label,
  description,
  checkboxColumns
}: {
  label: ReactNode;
  description?: ReactNode;
  checkboxColumns: ReactNode;
  handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isOpen?: boolean;
  isSecondary?: boolean;
}) => (
  <div className="relative flex items-center justify-between gap-4 border-b-1 border-b-sw-divider py-3 last:border-b-0">
    <div>
      <div>{label}</div>
      {description ? <div className="text-xs text-sw-form-helper-text">{description}</div> : null}
    </div>
    <div className="grid w-72 shrink-0 grid-cols-6">{checkboxColumns}</div>
  </div>
);

export const ExpansiveRowLayout = ({
  permission,
  actions = ['view', 'update', 'create', 'delete']
}: {
  permission: PermissionsCategory;
  actions?: ('view' | 'update' | 'create' | 'delete')[];
}) => {
  const {type, nested, name, label, description, permissions, enableReadCheckboxIfWriteGranted} = permission;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsOpen((state) => !state);
  };

  const {values, setFieldValue} = useFormikContext<UserWithPermissions>();
  const userPermissions = values.permissions;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    permission: PermissionsCategory,
    allAvailablePermissions: string[]
  ) => {
    const {permissions} = permission;

    if (event.target.checked) {
      const viewPermissions = permissions['view'];
      const deduplicatedNewUserPermissions = uniq([...userPermissions, ...allAvailablePermissions, ...viewPermissions]);
      setFieldValue('permissions', deduplicatedNewUserPermissions.sort()); // sorting keeps Formik's `dirty` flag accurate
    } else {
      const permissionsWithThisGroupRemoved = userPermissions.filter((perm) => !allAvailablePermissions.includes(perm));
      setFieldValue('permissions', permissionsWithThisGroupRemoved);
    }
  };

  const verifyPermissionCondition = (permissions: PermissionsByActionType) => {
    const isInUserPermissions = (permission: string) => userPermissions.includes(permission);
    const allAvailablePermissions = actions.map((actionType) => permissions[actionType]).flat();
    const isChecked = allAvailablePermissions.length > 0 && allAvailablePermissions.every(isInUserPermissions);

    return {
      allAvailablePermissions,
      isChecked
    };
  };

  const {allAvailablePermissions, isChecked} = verifyPermissionCondition(permissions);

  return (
    <>
      <div className="relative flex items-center justify-between gap-4 border-b-1 border-b-sw-divider py-3 last:border-b-0">
        <div>
          <div className="font-bold">{label}</div>
          {description ? <div className="text-xs text-sw-form-helper-text">{description}</div> : null}
        </div>
        <div className="grid w-72 shrink-0 grid-cols-6 items-center">
          {type === 'toggle-nested' ? (
            <ToggleSwitch
              checkedLabel="ON"
              uncheckedLabel="OFF"
              name={name}
              fixedHeight={false}
              checked={isChecked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event, permission, allAvailablePermissions)
              }
            />
          ) : (
            <>
              <PermissionsCheckbox
                permissions={permissions}
                actions={['view', 'update', 'create', 'delete']}
                aria-label="All"
              />
              <PermissionsCheckbox
                permissions={permissions}
                actions={['view']}
                aria-label="View"
                enableReadCheckboxIfWriteGranted={enableReadCheckboxIfWriteGranted}
              />
              <PermissionsCheckbox permissions={permissions} actions={['update']} aria-label="Edit" />
              <PermissionsCheckbox permissions={permissions} actions={['create']} aria-label="Create" />
              <PermissionsCheckbox permissions={permissions} actions={['delete']} aria-label="Delete" />
            </>
          )}
          {nested ? (
            <div
              className={`col-start-6 col-end-7 flex size-5 items-center justify-center rounded-full ${
                isOpen ? 'bg-sw-secondary' : ''
              }`}
            >
              <IconButton
                iconName={isOpen ? 'CarrotDown' : 'CarrotUp'}
                aria-label="Show all filters"
                onClick={handleOpen}
              />
            </div>
          ) : null}
        </div>
      </div>

      {isOpen && nested
        ? nested?.map((permission) => {
            const {allAvailablePermissions, isChecked} = verifyPermissionCondition(permission.permissions);

            return (
              <div
                key={permission.name}
                className={`relative flex items-center justify-between gap-4 border-b-1 border-b-sw-divider py-3 last:border-b-0 ${'bg-sw-background pl-6'}`}
              >
                <div>
                  <div className="text-sw-info">
                    {permission.label} ●{' '}
                    {permission.description ? <span className="text-xs">{permission.description}</span> : null}
                  </div>
                </div>
                <div className="grid w-72 shrink-0 grid-cols-6">
                  {type === 'toggle-nested' ? (
                    <ToggleSwitch
                      checkedLabel="ON"
                      uncheckedLabel="OFF"
                      name={permission.name}
                      fixedHeight={false}
                      checked={isChecked}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(event, permission, allAvailablePermissions)
                      }
                    />
                  ) : (
                    <>
                      <PermissionsCheckbox
                        permissions={permission.permissions}
                        actions={['view', 'update', 'create', 'delete']}
                        aria-label="All"
                      />
                      <PermissionsCheckbox permissions={permission.permissions} actions={['view']} aria-label="View" />
                      <PermissionsCheckbox
                        permissions={permission.permissions}
                        actions={['update']}
                        aria-label="Edit"
                      />
                      <PermissionsCheckbox
                        permissions={permission.permissions}
                        actions={['create']}
                        aria-label="Create"
                      />
                      <PermissionsCheckbox
                        permissions={permission.permissions}
                        actions={['delete']}
                        aria-label="Delete"
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })
        : null}
    </>
  );
};

export const ColumnHeader = ({children}: {children: ReactNode}) => (
  <div className="text-xxs uppercase text-sw-text-section-title">{children}</div>
);
