import {ChangeEvent} from 'react';
import {Checkbox, RadioGroup} from '@shipwell/shipwell-ui';
import {RelativeDateSelectFilter} from './filters/relativeDateSelectFilter/RelativeDateSelectFilter';
import {TableFilter} from 'App/components/TypedTable/complementaryComponents';
import {
  InitialFiltersType,
  modeOptions,
  tenderStatusOptions,
  workflowOptions
} from 'App/containers/Dashboard/utils/DashboardUtils';
import {
  AccessorialsFilter,
  CarrierFilter,
  CustomerFilter,
  EquipmentFilter,
  CompanyFilter,
  DrayageContainerNumberFilter,
  DrayageSealNumberFilter,
  RepsFilter,
  StatusFilter,
  TagsFilter,
  ServiceLevelFilter,
  CreatedByFilter,
  CustomDataFilter
} from 'App/containers/Dashboard/filters';
import {CheckboxesFilter, LocationFilter, useLocationFilter} from 'App/components/TypedTable/filters';

export function DashboardFilters({
  filters,
  onChange,
  openFilters,
  onToggleFilter,
  deliveryAddressFilter,
  pickupAddressFilter
}: {
  filters: InitialFiltersType;
  onChange: (filterName: keyof InitialFiltersType, value: string | string[]) => void;
  openFilters: Record<keyof InitialFiltersType, boolean>;
  onToggleFilter: (filterName: keyof InitialFiltersType) => void;
  deliveryAddressFilter: ReturnType<typeof useLocationFilter>;
  pickupAddressFilter: ReturnType<typeof useLocationFilter>;
}) {
  const {
    status,
    tenderStatus,
    mode,
    parcelPickupStatus,
    open,
    alerts,
    archived,
    hasBill,
    hasInvoice,
    loadBoardEnabled,
    workflowExecutionStatus,
    drayageContainerReturnDateGte,
    drayageContainerReturnDateLte,
    createdAtGte,
    createdAtLte,
    createdByUserId,
    createdBy,
    createdBySource,
    dropoffGte,
    dropoffLte,
    drayageEstimatedArrivalDateGte,
    drayageEstimatedArrivalDateLte,
    drayageLastFreeDateGte,
    drayageLastFreeDateLte,
    equipmentType,
    pickupGte,
    pickupLte,
    drayageReleaseDateGte,
    drayageReleaseDateLte,
    tags,
    tagsExclude,
    deliveryStopsContains,
    deliveryStopsStateProvince,
    deliveryRadius,
    deliveryLat,
    deliveryLon,
    deliveryStopsLabel,
    deliveryStopsCompanyNameContains,
    pickupStopsContains,
    pickupStopsStateProvince,
    pickupStopsCompanyNameContains,
    pickupRadius,
    pickupLat,
    pickupLon,
    pickupStopsLabel,
    vendorId,
    vendor,
    statusExclude,
    drayageContainerNumberContains,
    drayageSealNumberContains,
    reps,
    repLabels,
    customer,
    customerId,
    accessorials,
    accessorialLabels,
    serviceLevel,
    serviceLevelLabels,
    shipmentShipwellCustomData
  } = filters;
  return (
    <>
      {/* Accessorials */}
      <TableFilter
        isOpen={openFilters.accessorials}
        onToggleOpen={() => onToggleFilter('accessorials')}
        label="Accessorials"
        isClearable={Boolean(accessorials.length)}
        onClear={() => {
          onChange('accessorials', []);
          onChange('accessorialLabels', []);
        }}
      >
        <AccessorialsFilter
          defaultIds={accessorials}
          defaultLabels={accessorialLabels}
          onChange={(ids, labels) => {
            onChange('accessorials', ids);
            onChange('accessorialLabels', labels);
          }}
        />
      </TableFilter>

      {/* Alerts */}
      <TableFilter
        isOpen={openFilters.alerts}
        onToggleOpen={() => onToggleFilter('alerts')}
        label="Alerts"
        isClearable={Boolean(alerts)}
        onClear={() => onChange('alerts', '')}
      >
        <Checkbox
          label="Alerts Exist"
          name="alerts"
          checked={Boolean(alerts)}
          onChange={() => onChange('alerts', alerts ? '' : 'true')}
          fixedHeight={false}
        />
      </TableFilter>

      {/* Archived */}
      <TableFilter
        isOpen={openFilters.archived}
        onToggleOpen={() => onToggleFilter('archived')}
        label="Archived"
        isClearable={Boolean(archived[0])}
        onClear={() => onChange('archived', [])}
      >
        <Checkbox
          label="Include Archived Shipments"
          name="archived"
          checked={Boolean(archived[0])}
          onChange={() =>
            onChange(
              'archived',
              archived?.[0]
                ? []
                : //we want both archived shipments (archived=true)
                  //and active shipments (archived=false)
                  ['true', 'false']
            )
          }
          fixedHeight={false}
        />
      </TableFilter>

      {/* Bill */}
      <TableFilter
        isOpen={openFilters.hasBill}
        onToggleOpen={() => onToggleFilter('hasBill')}
        label="Bill"
        isClearable={Boolean(hasBill)}
        onClear={() => onChange('hasBill', '')}
      >
        <RadioGroup
          name="hasBill"
          options={[
            {label: 'Bill Exists', value: 'true'},
            {label: 'Bill Does Not Exists', value: 'false'}
          ]}
          value={hasBill}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange('hasBill', e.target.value)}
        />
      </TableFilter>

      {/* Carrier (Vendor) */}
      <TableFilter
        isOpen={openFilters.vendorId}
        onToggleOpen={() => onToggleFilter('vendorId')}
        label="Carrier"
        isClearable={Boolean(vendorId.length)}
        onClear={() => {
          onChange('vendorId', []);
          onChange('vendor', []);
        }}
      >
        <CarrierFilter
          carrierIds={vendorId}
          carrierLabels={vendor}
          onChange={(vendorIds, vendorNames) => {
            onChange('vendorId', vendorIds);
            onChange('vendor', vendorNames);
          }}
        />
      </TableFilter>

      {/* Container Number */}
      <TableFilter
        isOpen={openFilters.drayageContainerNumberContains}
        onToggleOpen={() => onToggleFilter('drayageContainerNumberContains')}
        label="Container #"
        isClearable={Boolean(drayageContainerNumberContains.length)}
        onClear={() => onChange('drayageContainerNumberContains', [])}
      >
        <DrayageContainerNumberFilter
          defaultContainerNumbers={drayageContainerNumberContains}
          onChange={(containerNumbers: string[]) => onChange('drayageContainerNumberContains', containerNumbers)}
        />
      </TableFilter>

      {/* Container Return Date */}
      <TableFilter
        isOpen={openFilters.drayageContainerReturnDateGte}
        onToggleOpen={() => onToggleFilter('drayageContainerReturnDateGte')}
        label="Container Return Date"
        isClearable={Boolean(drayageContainerReturnDateGte || drayageContainerReturnDateLte)}
        onClear={() => {
          onChange('drayageContainerReturnDateGte', '');
          onChange('drayageContainerReturnDateLte', '');
        }}
      >
        <RelativeDateSelectFilter
          selectedDates={[drayageContainerReturnDateGte, drayageContainerReturnDateLte]}
          onSelect={([start, stop]) => {
            onChange('drayageContainerReturnDateGte', start);
            onChange('drayageContainerReturnDateLte', stop);
          }}
        />
      </TableFilter>

      {/* Created */}
      <TableFilter
        isOpen={openFilters.createdAtGte}
        onToggleOpen={() => onToggleFilter('createdAtGte')}
        label="Created"
        isClearable={Boolean(createdAtLte || createdAtGte)}
        onClear={() => {
          onChange('createdAtGte', '');
          onChange('createdAtLte', '');
        }}
      >
        <RelativeDateSelectFilter
          pastOnly
          selectedDates={[createdAtGte, createdAtLte]}
          onSelect={([start, stop]) => {
            onChange('createdAtGte', start);
            onChange('createdAtLte', stop);
          }}
        />
      </TableFilter>

      {/* Created By */}
      <TableFilter
        isOpen={openFilters.createdByUserId}
        onToggleOpen={() => onToggleFilter('createdByUserId')}
        label="Created By"
        isClearable={Boolean(createdBy.length || createdByUserId.length || createdBySource.length)}
        onClear={() => {
          onChange('createdBy', []);
          onChange('createdByUserId', []);
          onChange('createdBySource', []);
        }}
      >
        <CreatedByFilter
          defaultIds={createdByUserId}
          defaultLabels={createdBy}
          defaultSources={createdBySource}
          onChange={(ids, labels, sources) => {
            onChange('createdByUserId', ids);
            onChange('createdBy', labels);
            onChange('createdBySource', sources);
          }}
        />
      </TableFilter>

      {/* Custom Data */}
      <TableFilter
        isOpen={openFilters.shipmentShipwellCustomData}
        onToggleOpen={() => onToggleFilter('shipmentShipwellCustomData')}
        label="Custom Data"
        isClearable={Boolean(shipmentShipwellCustomData.length)}
        onClear={() => onChange('shipmentShipwellCustomData', [])}
      >
        <CustomDataFilter
          defaultValues={shipmentShipwellCustomData}
          onChange={(customData) => onChange('shipmentShipwellCustomData', customData)}
        />
      </TableFilter>

      {/* Customer */}
      <TableFilter
        isOpen={openFilters.customerId}
        onToggleOpen={() => onToggleFilter('customerId')}
        label="Customer"
        isClearable={Boolean(customer.length || customerId.length)}
        onClear={() => {
          onChange('customer', []);
          onChange('customerId', []);
        }}
      >
        <CustomerFilter
          customerIds={customerId}
          customerLabels={customer}
          onChange={(ids, customers) => {
            onChange('customer', customers);
            onChange('customerId', ids);
          }}
        />
      </TableFilter>

      {/* Delivery Address */}
      <TableFilter
        isOpen={openFilters.deliveryStopsContains}
        onToggleOpen={() => onToggleFilter('deliveryStopsContains')}
        label="Delivery Address"
        isClearable={Boolean(
          deliveryStopsContains.length ||
            deliveryStopsStateProvince.length ||
            deliveryLat ||
            deliveryLon ||
            deliveryStopsLabel ||
            deliveryRadius
        )}
        onClear={() => {
          deliveryAddressFilter.handleClear();
          onChange('deliveryStopsContains', []);
          onChange('deliveryStopsStateProvince', []);
          onChange('deliveryLat', '');
          onChange('deliveryLon', '');
          onChange('deliveryRadius', '');
          onChange('deliveryStopsLabel', '');
        }}
      >
        <LocationFilter
          label="Delivery Address"
          onChange={deliveryAddressFilter.handleChange}
          onRadiusChange={deliveryAddressFilter.handleRadiusChange}
          radius={deliveryRadius}
          isFetching={deliveryAddressFilter.fetchingAddress}
          showRadiusSelect={deliveryAddressFilter.showRadiusSelect}
          values={deliveryAddressFilter.values}
          useLatLong
        />
      </TableFilter>

      {/* Delivery Company */}
      <TableFilter
        isOpen={openFilters.deliveryStopsCompanyNameContains}
        onToggleOpen={() => onToggleFilter('deliveryStopsCompanyNameContains')}
        label="Delivery Company"
        isClearable={Boolean(deliveryStopsCompanyNameContains.length)}
        onClear={() => onChange('deliveryStopsCompanyNameContains', [])}
      >
        <CompanyFilter
          companies={deliveryStopsCompanyNameContains}
          onChange={(newCompanies) => onChange('deliveryStopsCompanyNameContains', newCompanies)}
        />
      </TableFilter>

      {/* Delivery Date */}
      <TableFilter
        isOpen={openFilters.dropoffGte}
        onToggleOpen={() => onToggleFilter('dropoffGte')}
        label="Delivery Date"
        isClearable={Boolean(dropoffLte || dropoffGte)}
        onClear={() => {
          onChange('dropoffGte', '');
          onChange('dropoffLte', '');
        }}
      >
        <RelativeDateSelectFilter
          selectedDates={[dropoffGte, dropoffLte]}
          onSelect={([start, stop]) => {
            onChange('dropoffGte', start);
            onChange('dropoffLte', stop);
          }}
        />
      </TableFilter>

      {/* Estimated Container Arrival */}
      <TableFilter
        isOpen={openFilters.drayageEstimatedArrivalDateGte}
        onToggleOpen={() => onToggleFilter('drayageEstimatedArrivalDateGte')}
        label="Estimated Container Arrival"
        isClearable={Boolean(drayageEstimatedArrivalDateLte || drayageEstimatedArrivalDateGte)}
        onClear={() => {
          onChange('drayageEstimatedArrivalDateGte', '');
          onChange('drayageEstimatedArrivalDateLte', '');
        }}
      >
        <RelativeDateSelectFilter
          selectedDates={[drayageEstimatedArrivalDateGte, drayageEstimatedArrivalDateLte]}
          onSelect={([start, stop]) => {
            onChange('drayageEstimatedArrivalDateGte', start);
            onChange('drayageEstimatedArrivalDateLte', stop);
          }}
        />
      </TableFilter>

      {/* Equipment */}
      <TableFilter
        isOpen={openFilters.equipmentType}
        onToggleOpen={() => onToggleFilter('equipmentType')}
        label="Equipment"
        isClearable={Boolean(equipmentType.length)}
        onClear={() => onChange('equipmentType', [])}
      >
        <EquipmentFilter
          defaultEquipment={equipmentType}
          onChange={(equipment) => onChange('equipmentType', equipment)}
        />
      </TableFilter>

      {/* Invoice */}
      <TableFilter
        isOpen={openFilters.hasInvoice}
        onToggleOpen={() => onToggleFilter('hasInvoice')}
        label="Invoice"
        isClearable={Boolean(hasInvoice)}
        onClear={() => onChange('hasInvoice', '')}
      >
        <RadioGroup
          name="hasInvoice"
          options={[
            {label: 'Invoice Exists', value: 'true'},
            {label: 'Invoice Does Not Exists', value: 'false'}
          ]}
          value={hasInvoice}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange('hasInvoice', e.target.value)}
        />
      </TableFilter>

      {/* Last Free Date */}
      <TableFilter
        isOpen={openFilters.drayageLastFreeDateGte}
        onToggleOpen={() => onToggleFilter('drayageLastFreeDateGte')}
        label="Last Free Date"
        isClearable={Boolean(drayageLastFreeDateLte || drayageLastFreeDateGte)}
        onClear={() => {
          onChange('drayageLastFreeDateGte', '');
          onChange('drayageLastFreeDateLte', '');
        }}
      >
        <RelativeDateSelectFilter
          selectedDates={[drayageLastFreeDateGte, drayageLastFreeDateLte]}
          onSelect={([start, stop]) => {
            onChange('drayageLastFreeDateGte', start);
            onChange('drayageLastFreeDateLte', stop);
          }}
        />
      </TableFilter>

      {/* Load board */}
      <TableFilter
        isOpen={openFilters.loadBoardEnabled}
        onToggleOpen={() => onToggleFilter('loadBoardEnabled')}
        label="Load Board"
        isClearable={Boolean(loadBoardEnabled)}
        onClear={() => onChange('loadBoardEnabled', '')}
      >
        <Checkbox
          label="On Load Board"
          name="loadBoardEnabled"
          checked={Boolean(loadBoardEnabled)}
          onChange={() => onChange('loadBoardEnabled', loadBoardEnabled ? '' : 'true')}
          fixedHeight={false}
        />
      </TableFilter>

      {/* Mode */}
      <TableFilter
        isOpen={openFilters.mode}
        onToggleOpen={() => onToggleFilter('mode')}
        label="Mode"
        isClearable={Boolean(mode.length)}
        onClear={() => onChange('mode', [])}
      >
        <CheckboxesFilter selected={mode} onChange={(modes) => onChange('mode', modes)} options={modeOptions} />
      </TableFilter>

      {/* Pickup Address */}
      <TableFilter
        isOpen={openFilters.pickupStopsContains}
        onToggleOpen={() => onToggleFilter('pickupStopsContains')}
        label="Pickup Address"
        isClearable={Boolean(
          pickupStopsContains.length ||
            pickupStopsStateProvince.length ||
            pickupLat ||
            pickupLon ||
            pickupStopsLabel ||
            pickupRadius
        )}
        onClear={() => {
          pickupAddressFilter.handleClear();
          onChange('pickupStopsContains', []);
          onChange('pickupStopsStateProvince', []);
          onChange('pickupLat', '');
          onChange('pickupLon', '');
          onChange('pickupRadius', '');
          onChange('pickupStopsLabel', '');
        }}
      >
        <LocationFilter
          label="Pickup Address"
          onChange={pickupAddressFilter.handleChange}
          onRadiusChange={pickupAddressFilter.handleRadiusChange}
          radius={pickupRadius}
          isFetching={pickupAddressFilter.fetchingAddress}
          showRadiusSelect={pickupAddressFilter.showRadiusSelect}
          values={pickupAddressFilter.values}
          useLatLong
        />
      </TableFilter>

      {/* Pickup Company */}
      <TableFilter
        isOpen={openFilters.pickupStopsCompanyNameContains}
        onToggleOpen={() => onToggleFilter('pickupStopsCompanyNameContains')}
        label="Pickup Company"
        isClearable={Boolean(pickupStopsCompanyNameContains.length)}
        onClear={() => onChange('pickupStopsCompanyNameContains', [])}
      >
        <CompanyFilter
          companies={pickupStopsCompanyNameContains}
          onChange={(newCompanies) => onChange('pickupStopsCompanyNameContains', newCompanies)}
        />
      </TableFilter>

      {/* Pickup Date */}
      <TableFilter
        isOpen={openFilters.pickupGte}
        onToggleOpen={() => onToggleFilter('pickupGte')}
        label="Pickup Date"
        isClearable={Boolean(pickupLte || pickupGte)}
        onClear={() => {
          onChange('pickupGte', '');
          onChange('pickupLte', '');
        }}
      >
        <RelativeDateSelectFilter
          selectedDates={[pickupGte, pickupLte]}
          onSelect={([start, stop]) => {
            onChange('pickupGte', start);
            onChange('pickupLte', stop);
          }}
        />
      </TableFilter>

      {/* Release Date */}
      <TableFilter
        isOpen={openFilters.drayageReleaseDateGte}
        onToggleOpen={() => onToggleFilter('drayageReleaseDateGte')}
        label="Release Date"
        isClearable={Boolean(drayageReleaseDateLte || drayageReleaseDateGte)}
        onClear={() => {
          onChange('drayageReleaseDateGte', '');
          onChange('drayageReleaseDateLte', '');
        }}
      >
        <RelativeDateSelectFilter
          selectedDates={[drayageReleaseDateGte, drayageReleaseDateLte]}
          onSelect={([start, stop]) => {
            onChange('drayageReleaseDateGte', start);
            onChange('drayageReleaseDateLte', stop);
          }}
        />
      </TableFilter>

      {/* Reps */}
      <TableFilter
        isOpen={openFilters.reps}
        onToggleOpen={() => onToggleFilter('reps')}
        label="Reps"
        isClearable={Boolean(reps.length)}
        onClear={() => {
          onChange('reps', []);
          onChange('repLabels', []);
        }}
      >
        <RepsFilter
          defaultRepIds={reps}
          defaultRepLabels={repLabels}
          onChange={(newRepIds, newRepLabels) => {
            onChange('reps', newRepIds);
            onChange('repLabels', newRepLabels);
          }}
        />
      </TableFilter>

      {/* Seal # */}
      <TableFilter
        isOpen={openFilters.drayageSealNumberContains}
        onToggleOpen={() => onToggleFilter('drayageSealNumberContains')}
        label="Seal #"
        isClearable={Boolean(drayageSealNumberContains.length)}
        onClear={() => onChange('drayageSealNumberContains', [])}
      >
        <DrayageSealNumberFilter
          defaultSealNumbers={drayageSealNumberContains}
          onChange={(sealNumbers) => onChange('drayageSealNumberContains', sealNumbers)}
        />
      </TableFilter>

      {/* Service Level */}
      <TableFilter
        isOpen={openFilters.serviceLevel}
        onToggleOpen={() => onToggleFilter('serviceLevel')}
        label="Service Level"
        isClearable={Boolean(serviceLevel.length)}
        onClear={() => {
          onChange('serviceLevel', []);
          onChange('serviceLevelLabels', []);
        }}
      >
        <ServiceLevelFilter
          defaultLevels={serviceLevel}
          defaultLabels={serviceLevelLabels}
          onChange={(levels, labels) => {
            onChange('serviceLevel', levels);
            onChange('serviceLevelLabels', labels);
          }}
        />
      </TableFilter>

      {/* Status */}
      <StatusFilter
        isOpen={openFilters.status}
        onToggleOpen={() => onToggleFilter('status')}
        isClearable={Boolean(status.length || parcelPickupStatus.length || open || statusExclude.length)}
        onClear={() => {
          onChange('status', []);
          onChange('parcelPickupStatus', []);
          onChange('open', '');
          onChange('statusExclude', []);
        }}
        activeStatuses={status}
        activeStatusExclude={statusExclude}
        onStatusExcludeChange={(statuses) => onChange('statusExclude', statuses)}
        activeParcelPickupStatuses={parcelPickupStatus}
        openStatus={open}
        onStatusChange={(statuses) => onChange('status', statuses)}
        onParcelPickupStatusChange={(statuses) => onChange('parcelPickupStatus', statuses)}
        onOpenStatusChange={(isOpen) => onChange('open', isOpen)}
      />

      {/* Tags */}
      <TagsFilter
        isOpen={openFilters.tags}
        onToggleOpen={() => onToggleFilter('tags')}
        isClearable={Boolean(tags.length || tagsExclude.length)}
        onClear={() => {
          onChange('tags', []);
          onChange('tagsExclude', []);
        }}
        tags={tags}
        tagsExclude={tagsExclude}
        onChangeOr={(newTags) => onChange('tags', newTags)}
        onChangeExclude={(newTags) => onChange('tagsExclude', newTags)}
      />

      {/* Tender status */}
      <TableFilter
        isOpen={openFilters.tenderStatus}
        onToggleOpen={() => onToggleFilter('tenderStatus')}
        label="Tender Status"
        isClearable={Boolean(tenderStatus.length)}
        onClear={() => onChange('tenderStatus', [])}
      >
        <CheckboxesFilter
          selected={tenderStatus}
          onChange={(newStatuses) => onChange('tenderStatus', newStatuses)}
          options={tenderStatusOptions}
        />
      </TableFilter>

      {/* Workflows */}
      <TableFilter
        isOpen={openFilters.workflowExecutionStatus}
        onToggleOpen={() => onToggleFilter('workflowExecutionStatus')}
        label="Workflows"
        isClearable={Boolean(workflowExecutionStatus.length)}
        onClear={() => onChange('workflowExecutionStatus', [])}
      >
        <CheckboxesFilter
          selected={workflowExecutionStatus}
          onChange={(workflow) => onChange('workflowExecutionStatus', workflow)}
          options={workflowOptions}
        />
      </TableFilter>
    </>
  );
}
