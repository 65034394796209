import {
  AmountToleranceBoundaryType,
  InvoiceRuleConfiguration,
  InvoiceRuleConfigurationType
} from '@shipwell/settlements-sdk';
import {getAmount} from './components/InvoiceRuleDescription';
import {isAmountToleranceLimitDetails, isDocumentsRequiredDetails} from 'App/api/settlements/typeGuards';
import {isDefined} from 'App/utils/guard';

const boundaryTypeLabels: Record<AmountToleranceBoundaryType, string> = {
  CEILING: 'upper',
  FLOOR: 'lower'
};

export const getFinancialsThresholdDescription = (
  invoiceRule: InvoiceRuleConfiguration | undefined,
  boundaryType: AmountToleranceBoundaryType
) =>
  invoiceRule && isAmountToleranceLimitDetails(invoiceRule.details)
    ? `${getAmount(invoiceRule.details)} ${boundaryTypeLabels[boundaryType]} threshold`
    : '';

// TODO: delete this before merging
export const getDocumentRequiredRuleDescription = (
  invoiceRule: InvoiceRuleConfiguration | undefined,
  documentShortNames: Record<string, string> | undefined
) => {
  if (invoiceRule && isDocumentsRequiredDetails(invoiceRule.details) && documentShortNames) {
    const ids = invoiceRule.details.document_types;
    const shortNames = [...new Set(ids.map((id) => documentShortNames[id]).filter(isDefined))];

    return shortNames.join(', ');
  }

  return '';
};

export const sortInvoiceRules = (invoiceRules: InvoiceRuleConfiguration[]) =>
  invoiceRules.sort((ruleA, ruleB) => getSortIndex(ruleA) - getSortIndex(ruleB));

const getSortIndex = (rule: InvoiceRuleConfiguration) =>
  sortIndexGetters[rule.rule_type](
    isAmountToleranceLimitDetails(rule.details) ? rule.details.boundary_type : undefined
  );

/* eslint-disable @typescript-eslint/naming-convention */
const sortIndexGetters: Record<InvoiceRuleConfigurationType, (boundaryType?: AmountToleranceBoundaryType) => number> = {
  FINANCIALS_THRESHOLD: (boundaryType) => (boundaryType === 'CEILING' ? 1 : 2),
  DOCUMENT_REQUIRED: () => 3,
  DOCUMENTS_REQUIRED: () => 4,
  SERVICE_PROVIDER_NAME_MATCH: () => 5,
  MULTIPLE_INVOICES_PER_INVOICEABLE: () => 6,
  INVOICEABLE_STATUS: () => 7
};
