import {
  Address,
  FreightInvoiceDocumentMetadataDocumentTypeEnum,
  FreightInvoiceRemitTo,
  FreightInvoiceRuleConfiguration,
  InvoicingShipmentsShipmentIdFreightInvoicesData,
  InvoicingShipmentsShipmentIdFreightInvoicesDataChargeLineItems,
  FreightInvoiceStatus,
  ShipmentDocumentMetadata,
  ShipmentDocumentMetadataTypeEnum
} from '@shipwell/backend-core-sdk';
import without from 'lodash/without';
import {DocumentType, FreightInvoiceStatus as SettlementsFreightInvoiceStatus} from '@shipwell/settlements-sdk';
import invariant from 'tiny-invariant';
import {DashboardNameEnum} from 'App/containers/settlements/freightInvoices/components/SettlementsDashboard/DashboardsSelector';

export interface DateTerm {
  label: string;
  date: string | null;
  id: string;
}
export interface FreightInvoiceShipmentDocument {
  document_type?: DocumentType | null;
  description?: string | null;
  add_to_invoice?: boolean;
  id?: string;
  file: string | Blob | null;
}
export interface FreightInvoiceRemitToWithAddressLookup extends FreightInvoiceRemitTo {
  address?: Address;
}
export interface InvoicingShipmentsShipmentIdFreightInvoicesDataChargeLineItemsWithQuantityCast
  extends Omit<InvoicingShipmentsShipmentIdFreightInvoicesDataChargeLineItems, 'quantity'> {
  quantity: string;
}
export interface FreightInvoiceChargeLineItemsWithFormMetadata
  extends InvoicingShipmentsShipmentIdFreightInvoicesDataChargeLineItemsWithQuantityCast {
  add_to_invoice?: boolean;
  create_as_financial_line_item?: boolean;
  charge_code?: string | null;
}
export interface FreightInvoiceDocumentMetadata extends ShipmentDocumentMetadata {
  //backend expects type property from shipment documents to be mapped to document_type.
  document_type?: ShipmentDocumentMetadataTypeEnum;
  original_file?: File;
  add_to_invoice?: boolean;
}

export interface FreightInvoiceFormData
  //omit the non-form-field properties that are added on payload construction
  extends Omit<InvoicingShipmentsShipmentIdFreightInvoicesData, 'charge_line_items'> {
  //add frontend-only fields
  charge_line_items: FreightInvoiceChargeLineItemsWithFormMetadata[];
  due_date_term?: DateTerm | string;
  remit_to?: FreightInvoiceRemitToWithAddressLookup | null;
  document_metadata?: FreightInvoiceDocumentMetadata[];
}

/* 👇👇👇👇 TEMPORARY! 👇👇👇👇 */
export type DocumentRequiredDetails = {
  document_types: FreightInvoiceDocumentMetadataDocumentTypeEnum[];
  required_per_delivery_stop_document_types: FreightInvoiceDocumentMetadataDocumentTypeEnum[];
};

export type AmountToleranceLimitDetails = {
  boundary_type: unknown;
  limit_type: unknown;
  limit_value: unknown;
};
/* 👆👆👆👆 TEMPORARY! 👆👆👆👆 */

export type DocumentRequiredRule = FreightInvoiceRuleConfiguration & {
  rule_type: 'DOCUMENTS_REQUIRED';
  details: DocumentRequiredDetails;
};

const SelectableStatuses = [
  FreightInvoiceStatus.Resolved,
  FreightInvoiceStatus.Disputed,
  FreightInvoiceStatus.Passed,
  FreightInvoiceStatus.Approved,
  FreightInvoiceStatus.Scheduled,
  FreightInvoiceStatus.Paid,
  FreightInvoiceStatus.Rejected,
  FreightInvoiceStatus.Voided
];

const getSelectableStatusesWithout = (excludeStatuses: FreightInvoiceStatus[]) =>
  without(SelectableStatuses, ...excludeStatuses);

type StatusOptionLabels = 'Dispute' | 'Pass' | 'Approve' | 'Schedule' | 'Pay' | 'Reject' | 'Resolve';
type CarrierStatusOptionLabels = 'Cancel';
type StatusDataBaseProperties = {
  status: FreightInvoiceStatus;
  label?: string;
  variant: 'primary' | 'success' | 'warning' | 'alert';
  service_provider: FreightInvoiceStatus[];
  booking_party: FreightInvoiceStatus[];
};
export type SelectableStatus = StatusDataBaseProperties & {
  actionLabel: StatusOptionLabels | CarrierStatusOptionLabels;
  actionTargetDashboard: DashboardNameEnum;
};
export type NonSelectableStatus = StatusDataBaseProperties & {
  optionLabel?: StatusOptionLabels;
};

export const isSelectableStatus = (status: SelectableStatus | NonSelectableStatus): status is SelectableStatus =>
  SelectableStatuses.some((selectableStatus) => selectableStatus === status.status);

export const StatusData: Record<FreightInvoiceStatus, SelectableStatus | NonSelectableStatus> = {
  [FreightInvoiceStatus.Disputed]: {
    status: FreightInvoiceStatus.Disputed,
    variant: 'warning',
    service_provider: [FreightInvoiceStatus.Resolved, FreightInvoiceStatus.Voided],
    booking_party: getSelectableStatusesWithout([FreightInvoiceStatus.Voided]),
    actionLabel: 'Dispute',
    actionTargetDashboard: DashboardNameEnum.Disputing
  },
  [FreightInvoiceStatus.Resolved]: {
    status: FreightInvoiceStatus.Resolved,
    variant: 'primary',
    service_provider: [],
    booking_party: getSelectableStatusesWithout([FreightInvoiceStatus.Rejected, FreightInvoiceStatus.Voided]),
    actionLabel: 'Resolve',
    actionTargetDashboard: DashboardNameEnum.AllInvoices
  },
  [FreightInvoiceStatus.Exception]: {
    status: FreightInvoiceStatus.Exception,
    variant: 'warning',
    service_provider: [FreightInvoiceStatus.Voided],
    booking_party: getSelectableStatusesWithout([FreightInvoiceStatus.Resolved, FreightInvoiceStatus.Voided])
  },
  [FreightInvoiceStatus.Passed]: {
    status: FreightInvoiceStatus.Passed,
    variant: 'primary',
    service_provider: [FreightInvoiceStatus.Voided],
    booking_party: getSelectableStatusesWithout([FreightInvoiceStatus.Resolved, FreightInvoiceStatus.Voided]),
    actionLabel: 'Pass',
    actionTargetDashboard: DashboardNameEnum.Paying
  },
  [FreightInvoiceStatus.Approved]: {
    status: FreightInvoiceStatus.Approved,
    variant: 'primary',
    service_provider: [FreightInvoiceStatus.Voided],
    booking_party: getSelectableStatusesWithout([FreightInvoiceStatus.Resolved, FreightInvoiceStatus.Voided]),
    actionLabel: 'Approve',
    actionTargetDashboard: DashboardNameEnum.Paying
  },
  [FreightInvoiceStatus.Scheduled]: {
    status: FreightInvoiceStatus.Scheduled,
    variant: 'success',
    service_provider: [],
    booking_party: getSelectableStatusesWithout([
      FreightInvoiceStatus.Rejected,
      FreightInvoiceStatus.Resolved,
      FreightInvoiceStatus.Voided
    ]),
    actionLabel: 'Schedule',
    actionTargetDashboard: DashboardNameEnum.AllInvoices
  },
  [FreightInvoiceStatus.Paid]: {
    status: FreightInvoiceStatus.Paid,
    variant: 'success',
    service_provider: [],
    booking_party: getSelectableStatusesWithout([
      FreightInvoiceStatus.Rejected,
      FreightInvoiceStatus.Resolved,
      FreightInvoiceStatus.Voided
    ]),
    actionLabel: 'Pay',
    actionTargetDashboard: DashboardNameEnum.AllInvoices
  },
  [FreightInvoiceStatus.Rejected]: {
    status: FreightInvoiceStatus.Rejected,
    variant: 'alert',
    service_provider: [],
    booking_party: getSelectableStatusesWithout([FreightInvoiceStatus.Resolved, FreightInvoiceStatus.Voided]),
    actionLabel: 'Reject',
    actionTargetDashboard: DashboardNameEnum.AllInvoices
  },
  [FreightInvoiceStatus.Received]: {
    status: FreightInvoiceStatus.Received,
    variant: 'primary',
    service_provider: [],
    booking_party: getSelectableStatusesWithout([
      FreightInvoiceStatus.Rejected,
      FreightInvoiceStatus.Resolved,
      FreightInvoiceStatus.Voided
    ])
  },
  [FreightInvoiceStatus.Voided]: {
    status: FreightInvoiceStatus.Voided,
    label: 'CANCELLED',
    variant: 'alert',
    service_provider: [],
    booking_party: getSelectableStatusesWithout([
      FreightInvoiceStatus.Rejected,
      FreightInvoiceStatus.Resolved,
      FreightInvoiceStatus.Voided
    ]),
    actionLabel: 'Cancel',
    actionTargetDashboard: DashboardNameEnum.AllInvoices
  },
  [FreightInvoiceStatus.Reviewing]: {
    status: FreightInvoiceStatus.Reviewing,
    variant: 'primary',
    service_provider: [FreightInvoiceStatus.Voided],
    booking_party: getSelectableStatusesWithout([
      FreightInvoiceStatus.Rejected,
      FreightInvoiceStatus.Resolved,
      FreightInvoiceStatus.Voided
    ])
  },
  [FreightInvoiceStatus.Pending]: {
    status: FreightInvoiceStatus.Pending,
    variant: 'warning',
    service_provider: [],
    booking_party: getSelectableStatusesWithout([
      FreightInvoiceStatus.Rejected,
      FreightInvoiceStatus.Resolved,
      FreightInvoiceStatus.Voided
    ])
  }
};

export function assertAsFreightInvoiceStatus(
  status: FreightInvoiceStatus | SettlementsFreightInvoiceStatus
): asserts status is SettlementsFreightInvoiceStatus {
  invariant(
    Object.values(SettlementsFreightInvoiceStatus).includes(status as SettlementsFreightInvoiceStatus),
    `Invoice status ${status} is not assignable to SettlementsFreightInvoiceStatus. Users may not assign this status. Check the clickable options configured in the component`
  );
}
