import {Rate, RateStatus} from '@shipwell/genesis-sdk';
import {useQuery} from '@tanstack/react-query';
import {useState} from 'react';
import {groupBy} from 'lodash';

import {getRateRequestDetails, listRatesByRequestId} from 'App/api/genesis/typed';

export const useGetParcelRateRequest = ({
  requestParams
}: {
  requestParams: {
    rateRequestId: string;
  };
}) => {
  const [rateRequestId, setRateRequestId] = useState<string | null>(null);

  useQuery({
    queryKey: ['parcelRateRequest', requestParams.rateRequestId],
    queryFn: async () => {
      if (!requestParams?.rateRequestId) return null;

      const rateRequest = await getRateRequestDetails({
        rateRequestId: requestParams?.rateRequestId
      });

      return rateRequest;
    },
    refetchInterval: (rateRequest) => {
      if (!rateRequest) return false;
      const pollStatus = [RateStatus.Processing, RateStatus.Created] as Partial<RateStatus[]>;

      if (!pollStatus.includes(rateRequest?.status)) {
        setRateRequestId(rateRequest?.id);
        return false;
      }

      return 1000; // 1 seconds
    },
    enabled: !!requestParams?.rateRequestId
  });

  const ratesListQuery = useQuery({
    queryKey: ['parcelRateRequestRates', rateRequestId],
    queryFn: async () => {
      if (!rateRequestId) return null;

      const rates = await listRatesByRequestId(rateRequestId);

      return rates?.data?.map((rate) => ({
        ...rate,
        provider_code: rate.provider_code.toLowerCase(),
        isGenesisRate: true
      }));
    },
    enabled: !!rateRequestId
  });

  const groupRatesByProviderCode = (rates: Rate[]) => {
    return groupBy(rates, 'provider_code');
  };

  return {
    parcelRates: groupRatesByProviderCode(ratesListQuery?.data as Rate[])
  };
};
