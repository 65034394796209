import {LoadType, FacilityAppointmentType, FacilityDock} from '@shipwell/tempus-sdk';

import {AppointmentEntry} from '../../../../data-hooks/appointments/types';
import {AppointmentListEntry} from './AppointmentListEntry';
import {AppointmentListBlob} from './types';

import {formatParts} from 'App/data-hooks/appointments/utils';
import {convertDuration, weekdayMonthDate} from 'App/utils/dateTimeGlobalsTyped';

export type AppointmentListProps = {
  appointments: AppointmentEntry[];
  loadTypes: LoadType[];
  docks: FacilityDock[];
  setSelectedAppointment: (appointment: AppointmentEntry) => unknown;
  setDraggingAppointment?: (appointment: AppointmentEntry) => unknown;
  setSelectedTab?: React.Dispatch<React.SetStateAction<string | null>>;
};

export function AppointmentList({appointments, loadTypes, docks, setSelectedAppointment}: AppointmentListProps) {
  const entries: AppointmentListBlob[] = appointments
    .map((appointment) => {
      const [start, end] = [appointment.start, appointment.end].map(({timestamp, timezone}) =>
        formatParts(new Date(timestamp), timezone)
      );
      const loadType = loadTypes.find((lt) => appointment.matchedLoadTypeId === lt.id);
      const dock = docks.find((d) => d.id === appointment.dockId);
      const day = weekdayMonthDate(start.original);
      let durationMs: number;
      if (appointment.appointmentType === FacilityAppointmentType.ByAppointmentOnly) {
        durationMs = +end.original - +start.original;
      } else if (loadType && loadType.appointment_duration) {
        durationMs = convertDuration(loadType.appointment_duration, 'milliseconds');
      } else {
        durationMs = 60 * 60 * 1000; // just guess 1h
      }

      return {
        appointment,
        loadType,
        dock,
        durationMs,
        day,
        t: +start.original
      };
    })
    .sort((a, b) => a.t - b.t);
  const byDay = [] as (AppointmentListBlob | string)[];
  let day = '';
  for (const entry of entries) {
    if (entry.day !== day) {
      day = entry.day;
      byDay.push(day);
    }
    byDay.push(entry);
  }
  return (
    <div className="sw-appointment-list flex w-full flex-1 flex-col gap-2 overflow-scroll p-2 text-xxs">
      {byDay.length > 0 ? (
        byDay.map((entry) =>
          typeof entry === 'string' ? (
            <div
              key={entry}
              className="sw-weekday-heading rounded-md bg-sw-low-price-background p-1 text-center font-bold"
            >
              {entry}
            </div>
          ) : (
            <AppointmentListEntry
              key={entry.appointment.id}
              appointment={entry}
              onAppointmentClick={setSelectedAppointment}
            />
          )
        )
      ) : (
        <p>No Appointments.</p>
      )}
    </div>
  );
}
