import {useEffect, useState} from 'react';
import {ensurePluginOrder} from 'react-table';
import classNames from 'classnames';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';

export default function usePaginationFooter(hooks) {
  hooks.useInstance.push(useInstance);
}

usePaginationFooter.pluginName = 'usePaginationFooter';

function useInstance(instance) {
  const {
    plugins,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state: {pageIndex, pageSize, showPaginationFooter}
  } = instance;
  const [currentPage, setCurrentPage] = useState(pageIndex + 1);

  ensurePluginOrder(plugins, ['usePagination'], 'usePaginationFooter');

  // update current page in UI if user updated the pageSize
  // I'd prefer this in the onChange for the page size select, but can't find an easy/smooth way to make that work
  useEffect(() => {
    setCurrentPage(pageIndex + 1);
  }, [pageIndex, pageSize]);

  const handleGoToPage = (pageNumber) => {
    gotoPage(pageNumber);
    setCurrentPage(pageNumber + 1);
  };

  Object.assign(instance, {
    // eslint-disable-next-line react/display-name
    renderPaginationFooter: (footerContent) =>
      showPaginationFooter ? (
        <div className="sticky inset-x-0 bottom-0 flex w-full items-center gap-4 border-t border-sw-divider bg-sw-background p-2">
          {footerContent ? footerContent : null}
          <div className="flex w-full justify-end gap-4">
            <div className="flex items-center gap-2">
              <span>Rows Per Page:</span>
              <div className="relative flex h-8 items-center">
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  className="appearance-none bg-sw-background p-2 pr-6 text-sw-primary"
                >
                  {[10, 20, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
                <SvgIcon
                  className="pointer-events-none absolute inset-y-0 right-0 h-full text-sw-primary"
                  name="CarrotDown"
                />
              </div>
            </div>

            <label htmlFor="pageNumber" className="flex items-center gap-1 font-normal">
              <span>Page</span>
              <input
                id="pageNumber"
                name="pageNumber"
                type="number"
                className="w-10 rounded border border-sw-border py-2 text-center"
                value={currentPage}
                onChange={(e) => {
                  const userInput = parseInt(e.target.value);
                  const clampInputToPageOptions = Math.min(Math.max(userInput, 1), pageOptions.length);
                  setCurrentPage(clampInputToPageOptions);
                  if (!isNaN(clampInputToPageOptions)) {
                    gotoPage(clampInputToPageOptions - 1); // page is zero index
                  }
                }}
              />
              <span>of {pageOptions.length}</span>
            </label>

            <div className="flex items-center gap-1">
              <DeprecatedButton variant="icon" disabled={!canPreviousPage} onClick={() => handleGoToPage(0)}>
                <div className={buttonStyles(!canPreviousPage)}>
                  <SvgIcon name="FirstPage" />
                </div>
              </DeprecatedButton>
              <DeprecatedButton
                variant="icon"
                disabled={!canPreviousPage}
                onClick={() => handleGoToPage(pageIndex - 1)}
              >
                <div className={buttonStyles(!canPreviousPage)}>
                  <SvgIcon name="ArrowLargeLeft" />
                </div>
              </DeprecatedButton>
              <DeprecatedButton variant="icon" onClick={() => handleGoToPage(pageIndex + 1)} disabled={!canNextPage}>
                <div className={buttonStyles(!canNextPage)}>
                  <SvgIcon name="ArrowLargeRight" />
                </div>
              </DeprecatedButton>
              <DeprecatedButton
                variant="icon"
                disabled={!canNextPage}
                onClick={() => handleGoToPage(pageOptions.length - 1)}
              >
                <div className={buttonStyles(!canNextPage)}>
                  <SvgIcon name="LastPage" />
                </div>
              </DeprecatedButton>
            </div>
          </div>
        </div>
      ) : null
  });
}

const buttonStyles = (isDisabled) =>
  classNames('flex items-center justify-center rounded-full p-1', {
    'text-sw-disabled': isDisabled,
    'hover:bg-sw-active-light hover:text-sw-active': !isDisabled
  });
