import {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import pluralize from 'pluralize';
import defer from 'lodash/defer';
import {Toast} from '@shipwell/shipwell-ui';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import * as shipmentdetailsActions from 'App/actions/shipmentdetails';
import View from 'App/containers/Shipment/components/References/View';
import {unpackErrors} from 'App/utils/globals';
import {unpackShipmentErrors} from 'App/utils/globalsTyped';
import {trackSubmitReferences} from 'App/utils/analytics';
import {CustomFieldsContextProvider} from 'App/data-hooks';
import 'App/containers/Shipment/components/References/styles.scss';
import {referencesArrayFields} from 'App/containers/Shipment/components/References/utils/constants';

const ShipmentCardReferences = ({
  shipment,
  dispatch,
  fetchDetails,
  shipmentId,
  shipmentsShipmentIdPut,
  onCancelEdit,
  onSuccessEdit,
  editing,
  company,
  ...props
}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [metaErrors, setMetaErrors] = useState([]);

  const updated = () => {
    defer(() => fetchDetails(shipment.id));
  };

  const handleCancelEdit = () => {
    updated();
    if (onCancelEdit) {
      onCancelEdit();
    }
  };

  const handleReferencesArraySubmitObject = (values, oldReferenceArray) => {
    // take the fields that should be send inside the references array from the values and remove them from there
    const fieldNames = referencesArrayFields.map((field) => field.fieldName);
    const newReferencesArray = fieldNames.map((fieldName) => {
      return {
        qualifier: fieldName,
        value: values[fieldName]
      };
    });

    const valuesWithoutReferencesArrayFields = fieldNames.reduce(
      (prev, curr) => {
        const {[curr]: valueToRemove, ...filteredObj} = prev;
        return filteredObj;
      },
      {...values}
    );

    // transform both old references Array and new references Array to a map with the qualifier as key
    const oldReferencesMap = oldReferenceArray.reduce((prev, curr) => {
      prev[curr.qualifier] = curr.value;
      return prev;
    }, {});

    const newReferencesMap = newReferencesArray.reduce((prev, curr) => {
      if (curr.value) {
        prev[curr.qualifier] = curr.value;
      }
      return prev;
    }, {});

    //merge both objects, prioritizing the new values
    const mergedReferencesMap = {...oldReferencesMap, ...newReferencesMap};

    //transform the merged map back to the references array format array
    const mergedReferencesArray = Object.entries(mergedReferencesMap).map(([qualifier, value]) => {
      return {
        qualifier,
        value
      };
    });

    //return attribs with the references array and the rest of the values
    return {
      ...valuesWithoutReferencesArrayFields,
      references: mergedReferencesArray
    };
  };

  const handleFormSubmit = (values, {setSubmitting, setErrors}) => {
    //GA tracking
    trackSubmitReferences();
    const attrs = {...values};

    Object.keys(attrs).forEach((key) => {
      const value = attrs[key];
      attrs[key] = value === '' ? null : value;
    });

    const attrsWithReferencesArray = handleReferencesArraySubmitObject(attrs, shipment?.references || []);
    const shipmentObj = {...shipment, ...attrsWithReferencesArray};

    if (attrs.carrier_reference_code && shipmentObj.relationship_to_vendor) {
      shipmentObj.relationship_to_vendor.carrier_reference_code = attrs.carrier_reference_code;
    }

    //assign custom reference values
    if (attrs.custom_reference_values && attrs.custom_reference_values.length > 0) {
      shipmentObj.metadata.custom_reference_values.forEach((ref, i) => {
        ref.value = attrs.custom_reference_values[i].value;
      });
    }

    return shipmentsShipmentIdPut(shipmentId, shipmentObj, {})
      .then((response) => {
        setSubmitting(false);
        setShowSuccess(true);
        if (onSuccessEdit) {
          onSuccessEdit();
        }
        return response;
      })
      .catch((error) => {
        const errors = error.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = error.error_description;
        const errorMessages = unpackShipmentErrors(error, '', [
          'customer_reference_number',
          'pro_number',
          'purchase_order_number',
          'pickup_number',
          'bol_number',
          'drayage_seal_number'
        ]);
        if (Array.isArray(errorMessages)) {
          setMetaErrors(errorMessages);
        } else {
          setErrors(submissionError);
        }
      });
  };

  return (
    <>
      <CustomFieldsContextProvider
        companyId={company?.id}
        shipmentId={shipmentId}
        entityType={CustomFieldEntityTypesEnum.Shipment}
      >
        <View
          shipment={shipment}
          editing={editing}
          onCancelEdit={handleCancelEdit}
          onSubmit={handleFormSubmit}
          {...props}
        />
      </CustomFieldsContextProvider>
      <Toast
        show={showSuccess}
        title="Changes saved!"
        variant="success"
        anchor="top-right"
        onClose={() => setShowSuccess(false)}
      >
        Your references have been updated.
      </Toast>
      <Toast
        variant="error"
        show={metaErrors.length > 0}
        title="Error on Shipment!"
        onClose={() => setMetaErrors([])}
        delay={10000}
      >
        <div className="flex flex-col gap-2 pb-4">
          {metaErrors.map((error, i) => (
            <div key={i}>
              <span>{error}</span>
            </div>
          ))}
        </div>
        <span>
          {pluralize('This', metaErrors.length)} {pluralize('error', metaErrors.length)} must be corrected before any
          new changes can be saved.
        </span>
      </Toast>
    </>
  );
};

ShipmentCardReferences.defaultProps = {
  ordinalIndex: 0,
  isCollapsed: false,
  onCollapse: () => {},
  draggableProvided: {
    draggableProps: {},
    dragHandleProps: {},
    innerRef: null
  },
  shipment: {}
};

ShipmentCardReferences.propTypes = {
  ordinalIndex: PropTypes.number,
  isCollapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  draggableProvided: PropTypes.shape({
    draggableProps: PropTypes.object,
    dragHandleProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.elementType})])
  }),
  shipment: PropTypes.shape({
    customer: PropTypes.shape({
      name: PropTypes.string
    }),
    customer_reference_number: PropTypes.string,
    bol_number: PropTypes.string,
    pro_number: PropTypes.string,
    pickup_number: PropTypes.string,
    purchase_order_number: PropTypes.string,
    references: PropTypes.array,
    metadata: PropTypes.shape({
      custom_reference_values: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  })
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    company: state.auth.company,
    isQuotingLimitedUser: state.auth.is_quoting_limited_user,
    shipmentRepRoles: state.shipmentdetails.shipmentRepRoles,
    companyUsers: state.users.companyUsers,
    companyCustomRefValues: state.userCompany.preferences.custom_references
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...shipmentdetailsActions
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentCardReferences);
