import {Invocation, SupportedEvents} from '@shipwell/scriptlab-sdk';
import {InvocationData} from './invocationData';

export function getDataFromInvocations(data: Invocation[]) {
  return data.map<InvocationData>((inv) => ({
    id: inv.id,
    name: inv.script?.name || '',
    didSucceed: inv.did_succeed,
    triggerEvent: getEventName(inv.webhook_event_name),
    startDate: inv.created_at,
    endDate: inv.updated_at,
    message: getMessage(inv)
  }));
}

export function getMessage(inv: Invocation) {
  if (inv.did_succeed === true) return inv.outcome_message || 'Script Succeeded.';
  if (inv.did_succeed === false) return inv.outcome_message;
  return 'Script is running...';
}

type SupportedEventsDicType = {
  [key in SupportedEvents]: string;
};

const supportedEventsDic: SupportedEventsDicType = {
  [SupportedEvents.Created]: 'Shipment Creation',
  [SupportedEvents.Updated]: 'Shipment Updated'
};

export function getEventName(evt?: string) {
  if (evt && evt in supportedEventsDic) return supportedEventsDic[evt as SupportedEvents];
  return 'Unknown';
}
