import {Uuid4, Ulid} from 'id128';
import LegacyShipmentDetails from 'App/containers/Shipment/ShipmentDetails';
import ErrorPage from 'App/common/ErrorPage';
import ShipmentDetails from 'App/containers/shipments/details/ShipmentDetails';

const RenderShipmentVersion = (props: {params: {shipment_id: string}}) => {
  const shipmentId = props.params.shipment_id;

  // v2 shipments IDs are UUIDs
  if (Uuid4.isCanonical(shipmentId)) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <LegacyShipmentDetails {...props} />;
  }

  // v3 shipment IDs are ULIDs
  if (Ulid.isCanonical(shipmentId)) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ShipmentDetails {...props} />;
  }

  return (
    <ErrorPage
      primaryMessage="Sorry, we couldn't find this shipment."
      secondaryMessage="Please check your URL and try again, or contact your account admin for assistance."
    />
  );
};

export default RenderShipmentVersion;
