import {Fragment} from 'react';
import {Field} from 'formik';
import {FormikSelect, Tooltip} from '@shipwell/shipwell-ui';
import {NetsuiteCategory, NetsuiteCategoryOptions} from '@shipwell/backend-core-sdk';
import MappingFormFieldsHeader from 'App/formComponents/forms/netSuiteDataMapping/components/mappingFormFieldsHeader';

const lineItems = [
  {
    id: 'ACC',
    label: 'Accessorials',
    tooltipContent:
      'Fees added to a shipper’s freight invoice for services the motor freight carrier performed beyond the standard pick-up and delivery operation designated at the time a shipment is tendered.'
  },
  {
    id: 'ADJUSTMENT',
    label: 'Adjustment',
    tooltipContent:
      'Fee entries that are made in a general journal at the end of an accounting period to bring account balances up-to-date.'
  },
  {
    id: 'DETENTION',
    label: 'Detention',
    tooltipContent:
      "A penalty charge against shippers or consignees for delaying carrier's equipment beyond allowed time."
  },
  {
    id: 'FUEL',
    label: 'Fuel',
    tooltipContent: 'A fee that trucking companies (or third parties) charge to cover the regular cost of fuel.'
  },
  {
    id: 'FSC',
    label: 'Fuel Surcharge',
    tooltipContent:
      'An extra fee that trucking companies (or third parties) charge to cover the fluctuating cost of fuel.'
  },
  {
    id: 'INSURANCE',
    label: 'Insurance',
    tooltipContent:
      'Protection for the people and property from damage caused by a truck or the transportation of goods.'
  },
  {
    id: 'LAYOVER',
    label: 'Layover',
    tooltipContent:
      'Type of added pay that some trucking companies issue to drivers when they spend a predefined amount of time not moving because of a lack of dispatch or load assignment.'
  },
  {
    id: 'LH',
    label: 'Line Haul',
    tooltipContent:
      'The movement of freight with any mode of transport by land, air or waterway between distant cities.'
  },
  {
    id: 'OTHER',
    label: 'Other',
    tooltipContent: 'Any miscellaneous fees associated with billing and invoicing.'
  },
  {
    id: 'SF',
    label: 'Service Fee',
    tooltipContent: 'A fee covering the costs of administrative or processing costs.'
  },
  {
    id: 'TAX',
    label: 'Tax',
    tooltipContent:
      'Involuntary fees levied on individuals or corporations and enforced by a government entity—whether local, regional or national.'
  },
  {
    id: 'TEAMDRIVERS',
    label: 'Team Drivers',
    tooltipContent:
      'A fee applied when 2+ drivers work together on a haul to maximize miles. This allows freight to move as fast as possible while staying within federal hours of service regulations.'
  },
  {
    id: 'TONU',
    label: 'Truck ordered, not used',
    tooltipContent: 'A cancellation charge for ordering a truck and then cancelling the order.'
  },
  {
    id: 'VARIANCE',
    label: 'Variance',
    tooltipContent: 'The difference between actual and budgeted income and expenditure.'
  }
];

const ModeMappingFormFields = ({
  mode,
  type,
  categoryOptions
}: {
  mode: string;
  type: string;
  categoryOptions: NetsuiteCategoryOptions;
}) => (
  <>
    <MappingFormFieldsHeader labelLeft="Line Items" labelRight="Products & Services" />
    <div className="mt-3 grid grid-cols-2 items-start justify-items-stretch gap-x-[10px] gap-y-1">
      {lineItems.map((lineItem) => (
        <Fragment key={`${type}-${mode}-${lineItem.id}`}>
          <div className="flex h-full flex-col justify-center">
            <Tooltip
              tooltipContent={lineItem.tooltipContent}
              trigger="hover"
              placement="right"
              wrapperClassname="w-fit"
              tooltipClassname="max-w-[200px] !left-1"
            >
              {lineItem.label}
            </Tooltip>
          </div>
          <Field
            simpleValue
            name={`category_fields.${type}.${mode}.${lineItem.id}`}
            label="NetSuite Item"
            component={FormikSelect}
            options={categoryOptions?.[type as keyof NetsuiteCategoryOptions]}
            getOptionLabel={(option: NetsuiteCategory) => option.display_name}
            getOptionValue={(option: NetsuiteCategory) => option.id}
            clearable={mode !== 'DEFAULT'}
            required={mode === 'DEFAULT'}
          />
        </Fragment>
      ))}
    </div>
  </>
);

export default ModeMappingFormFields;
