import {useState} from 'react';
import {ShipmentStatusEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {Formik, Form, Field} from 'formik';
import {object, mixed} from 'yup';
import {FormikSelect, SvgIcon} from '@shipwell/shipwell-ui';
import {createBulkOperation} from 'App/api/bulkOperations';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {WithStatusToastsPropTypes, WithStatusToastsDefaultProps} from 'App/components/withStatusToasts';

const {Delivered, InTransit, Cancelled, Reconciled} = ShipmentStatusEnum;

export const changeStatuses = [
  {value: Delivered, label: 'Delivered'},
  {value: InTransit, label: 'In Transit'},
  {value: Cancelled, label: 'Cancelled'},
  {value: Reconciled, label: 'Reconciled'}
];

const ChangeShipmentStatus = ({selectedShipmentIds, onSubmitSuccess, onCancel, setError}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      if (selectedShipmentIds) {
        const bulkOperationBody = {
          resource_type: 'shipment',
          workflow: {
            name: 'Bulk op',
            start_events: [
              {
                step_id: 'STEP_1',
                trigger: {
                  trigger_type: 'NONE',
                  outputs: [
                    {name: 'shipment_id', data_type: 'String', push_to_list: null},
                    {name: 'shipment', data_type: 'Shipment', push_to_list: null}
                  ]
                },
                next_step: {
                  step_id: 'STEP_2'
                }
              }
            ],
            end_events: [
              {
                step_id: 'STEP_END',
                should_terminate: true
              }
            ],
            actions: [
              {
                action_id: 'MODIFY_SHIPMENT_STATUS',
                step_id: 'STEP_2',
                attached_triggers: [
                  {
                    trigger: {
                      trigger_type: 'NONE'
                    },
                    next_step_id: 'STEP_END'
                  }
                ],
                inputs: [
                  {
                    name: 'shipment_id',
                    data_type: 'String',
                    is_required: true
                  }
                ],
                params: [
                  {
                    name: 'new_status',
                    value: values.new_status
                  }
                ]
              }
            ]
          },
          criteria: {
            id: {
              $in: selectedShipmentIds
            }
          }
        };
        const response = await createBulkOperation(bulkOperationBody);
        onSubmitSuccess(response.data.id);
      }
    } catch (error) {
      console.error(`Error editing ${pluralize('shipment', selectedShipmentIds.length)} status.`, error);
      setError(
        'Changes Not Saved',
        `Your changes could not be applied to the ${pluralize('shipment', selectedShipmentIds.length)} you selected.`
      );
    }
    setIsSubmitting(false);
  };
  return (
    <Formik
      initialValues={{
        new_status: null
      }}
      validationSchema={object().shape({
        new_status: mixed().oneOf([Delivered, InTransit, Cancelled, Reconciled]).required('Status is required.')
      })}
      onSubmit={handleSubmit}
      validateOnBlur
    >
      {({values}) => (
        <Form noValidate>
          <div className="mb-2 ml-2">
            Choose which status you would like to apply to all of the shipments you selected.
          </div>
          <div className="mb-4">
            <Field
              required
              label="Shipment Status"
              name="new_status"
              simpleValue
              clearable={false}
              component={FormikSelect}
              options={changeStatuses}
              styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
              menuPortalTarget={document.body}
            />
          </div>
          {[Delivered, InTransit].some((status) => status === values?.new_status) ? (
            <div className="-mt-1 mb-4 flex flex-row items-center rounded bg-sw-warning-background p-2">
              <div className="flex flex-row items-center font-bold">
                <SvgIcon name="ErrorOutlined" color="sw-warning" />
                <div className="ml-2">Planned times will be inserted as actuals on all shipments.</div>
              </div>
            </div>
          ) : null}
          <ModalFormFooter
            primaryActionName="Apply"
            onCancel={onCancel}
            className="static m-0 p-0"
            isValid={!isSubmitting && values.new_status}
          />
        </Form>
      )}
    </Formik>
  );
};

ChangeShipmentStatus.propTypes = {
  selectedShipmentIds: PropTypes.arrayOf(PropTypes.string),
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  ...WithStatusToastsPropTypes
};

ChangeShipmentStatus.defaultProps = {
  selectedShipmentIds: [],
  onSubmitSuccess: () => {},
  onCancel: () => {},
  ...WithStatusToastsDefaultProps
};

export default ChangeShipmentStatus;
