import {
  requestUserCompanyPreferencesPending,
  requestUserCompanyPreferencesSuccess,
  requestUserCompanyPreferencesError,
  requestPermissionsSuccess,
  requestIdentifyingCodesSuccess,
  updateUserCompanySuccess
} from './index';
import {company, user} from 'App/api';
import {GET_COMPANY_PREFERENCES} from 'App/actions/types';

/**
 * Update Company
 * @param {Number} companyId
 * @param {Object} body
 */
export function updateCompanySetting(companyId, body = {}) {
  return async (dispatch) => {
    const response = await company.editCompanyProfilePromise(companyId, body);
    if (response) {
      dispatch(updateUserCompanySuccess(response.body));
      return response;
    }
  };
}

/**
 * Obtain the company's preferences
 * @param {String} companyId The id of the company
 */
export function getCompanyPreferences(companyId) {
  return async (dispatch) => {
    dispatch(requestUserCompanyPreferencesPending());
    try {
      const response = await company.getCompanyPreferencesPromise(companyId);
      if (response) {
        //temporary
        dispatch({type: GET_COMPANY_PREFERENCES, payload: response.body});
        dispatch(requestUserCompanyPreferencesSuccess(response.body));
        return {status: 200};
      }
    } catch (error) {
      dispatch(requestUserCompanyPreferencesError(error));
      return error;
    }
  };
}

/**
 * Update the company's preferences
 * @param {String} companyId The id of the company
 */
export function editCompanyPreferences(companyId, body) {
  return async (dispatch) => {
    try {
      const response = await company.editCompanyPreferencesPromise(companyId, body);
      if (response) {
        dispatch(requestUserCompanyPreferencesSuccess(response.body));
        return {status: 200};
      }
    } catch (error) {
      return error;
    }
  };
}

/**
 * Get permissions
 */
export function getPermissions() {
  return async (dispatch) => {
    const response = await user.getPermissionsPromise();
    if (response) {
      dispatch(requestPermissionsSuccess(response));
    }
  };
}

/**
 * Get identifying codes
 */
export function fetchIdentifyingCodes() {
  return async (dispatch) => {
    const response = await company.fetchIdentifyingCodesPromise();
    if (response) {
      dispatch(requestIdentifyingCodesSuccess(response));
    }
  };
}

/**
 *
 * Single function to update company preferences and settings
 * Required because async calls that update redux will cause the form to reinitialize early
 */
export function updateCompanyPreferencesAndSettings(companyId, body) {
  return async (dispatch) => {
    const [preferencesResponse, profileResponse] = await Promise.all([
      company.editCompanyPreferencesPromise(companyId, body),
      company.editCompanyProfilePromise(companyId, body)
    ]);
    if (preferencesResponse && profileResponse) {
      dispatch(requestUserCompanyPreferencesSuccess(preferencesResponse.body));
      dispatch(updateUserCompanySuccess(profileResponse.body));
      return {status: 200};
    }
  };
}
