import {useQuery} from '@tanstack/react-query';
import {useCompanyPreferences, useUserMe} from 'App/data-hooks';
import {COMPANY_UNIT_PREFERENCES} from 'App/data-hooks/queryKeys';
import {countryDefaults} from 'App/utils/internationalConstants';

export const useCompanyUnitPreferences = () => {
  const {data: userData} = useUserMe();
  const {data: companyPreferencesData} = useCompanyPreferences(userData?.company?.id || '');
  const unitPreferencesQuery = useQuery(
    [COMPANY_UNIT_PREFERENCES, userData?.company?.id],
    () => {
      return countryDefaults.find((countryDefault) => countryDefault.country === companyPreferencesData?.country)
        ?.unitPreferences;
    },
    {
      enabled: !!companyPreferencesData?.country
    }
  );
  return unitPreferencesQuery;
};
