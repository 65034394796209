import {SvgIcon, Title} from '@shipwell/shipwell-ui';
import {InvocationCard} from './invocationCard/invocationCard';
import {ResourcesEnum} from './resourcesEnum';
import {useScriptsService} from './useScriptsService/useScriptsService';

export interface ScriptsInvocationStatusProps {
  resource: ResourcesEnum;
  resourceId: string;
}

export function ScriptsInvocationStatus(props: ScriptsInvocationStatusProps) {
  const scriptService = useScriptsService({
    resource: props.resource,
    resourceId: props.resourceId
  });

  const hasScripts = !!scriptService.data?.length;

  return (
    <div className="rounded-md border border-sw-border bg-sw-background p-4">
      <div className="flex items-center justify-start gap-2">
        <Title className={'grow-0'}>Scripts</Title>
        {scriptService.loading && <SvgIcon role={'progressbar'} width="16" height="16" name="LoadingDots" />}
      </div>

      <div className="mt-2 flex flex-col gap-2">
        {!scriptService.loading && !hasScripts && (
          <Title variant="emptyStateHeader" className="p-4 text-center">
            No Scripts on this Shipment
          </Title>
        )}

        {scriptService.data?.map((invocation) => (
          <InvocationCard key={invocation.id} invocation={invocation} />
        ))}
      </div>
    </div>
  );
}
