import {
  ALERT_ERROR,
  REQ_USER_DATA,
  USER_DASHBOARD_CONFIG_REQUEST_PENDING,
  USER_DASHBOARD_CONFIG_REQUEST_SUCCESS,
  USER_DASHBOARD_CONFIG_UPDATE_PENDING,
  USER_DASHBOARD_CONFIG_UPDATE_SUCCESS,
  GET_COMPANY_USERS,
  CREATE_COMPANY_USER,
  EDIT_COMPANY_USER,
  DELETE_COMPANY_USER,
  EDIT_USER,
  EDIT_COMPANY,
  GET_COMPANY_PREFERENCES,
  GET_IDENTIFYING_CODES,
  GET_NOTIFICATION_PREFERENCES,
  GET_PERMISSIONS,
  RESET_PASSWORD,
  USER_ACCT_ERROR,
  GET_CASES,
  GET_CASE_NOTES,
  LOADING_TASKS_FOR_CASE,
  GET_TASKS_BY_CASE,
  GET_TASKS,
  SELECT_TASK,
  SELECT_CASE,
  CLEAR_SELECTED_TASK,
  CLEAR_SELECTED_CASE,
  TOGGLE_SIDE_MENU,
  SELECT_USER,
  CLEAR_SELECTED_USER
} from './types';
import {user, preferences, company, shipment} from 'App/api';

/**
 * @todo Break this file out into separate files
 */

export const displayError = (response) => ({
  type: ALERT_ERROR,
  payload: response.error_description
});

// starts the Loading... spinner
export const requestData = () => ({
  type: REQ_USER_DATA
});

/**
 * Dashboard Configuration - actions
 */
export const userDashboardConfigRequestPending = () => ({
  type: USER_DASHBOARD_CONFIG_REQUEST_PENDING
});

export const userDashboardConfigRequestSuccess = (response) => ({
  type: USER_DASHBOARD_CONFIG_REQUEST_SUCCESS,
  payload: response.body
});

export const userDashboardConfigUpdatePending = () => ({
  type: USER_DASHBOARD_CONFIG_UPDATE_PENDING
});

export const userDashboardConfigUpdateSuccess = (response) => ({
  type: USER_DASHBOARD_CONFIG_UPDATE_SUCCESS,
  payload: response.body
});

// Create dashboard configuration
export function createUserDashboardConfiguration(payload) {
  return (dispatch) => {
    dispatch(userDashboardConfigUpdatePending());

    return preferences
      .createUserDashboardConfigurationPromise(payload)
      .then((response) => {
        dispatch(userDashboardConfigUpdateSuccess(response));
        return response;
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

// Get dashboard configuration
export function getUserDashboardConfiguration(opts = {}) {
  return (dispatch) => {
    dispatch(userDashboardConfigRequestPending());

    return preferences
      .getUserDashboardConfigurationPromise(opts)
      .then((response) => {
        dispatch(userDashboardConfigRequestSuccess(response));
        return response;
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

// Update dashboard configuration
export function updateUserDashboardConfiguration(configId, payload = {}) {
  return (dispatch) => {
    dispatch(userDashboardConfigUpdatePending());

    return preferences
      .updateUserDashboardConfigurationPromise(configId, payload)
      .then((response) => {
        dispatch(userDashboardConfigUpdateSuccess(response));
        return response;
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

// Delete dashboard configuration
export function deleteUserDashboardConfiguration(configId) {
  return (dispatch) => {
    return preferences
      .deleteUserDashboardConfigurationPromise(configId)
      .then((response) => {
        return response;
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

/**
 * Company users - actions
 */
export const getCompanyUsersSuccess = (response) => ({
  type: GET_COMPANY_USERS,
  payload: response.body
});

export const createCompanyUsersSuccess = (response) => ({
  type: CREATE_COMPANY_USER,
  payload: response.body
});

export const updateCompanyUsersSuccess = (response) => ({
  type: EDIT_COMPANY_USER,
  payload: response.body
});

export const deleteCompanyUsersSuccess = (userId) => ({
  type: DELETE_COMPANY_USER,
  payload: userId
});

// Create company user
export function createCompanyUser(companyId, user) {
  return (dispatch) => {
    return company
      .createCompanyUserPromise(companyId, user)
      .then((response) => {
        dispatch(createCompanyUsersSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

// Get company users
export function getCompanyUsers(companyId, opts = {}) {
  return (dispatch) => {
    dispatch(requestData());
    return company
      .getCompanyUsersPromise(companyId, opts)
      .then((response) => {
        dispatch(getCompanyUsersSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

// Update company user
export function editCompanyUser(companyId, userId, user = {}) {
  return (dispatch) => {
    return company
      .editCompanyUserPromise(companyId, userId, user)
      .then((response) => {
        dispatch(updateCompanyUsersSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

// Delete company user
export function deleteCompanyUser(companyId, userId) {
  return (dispatch) => {
    return company
      .deleteCompanyUserPromise(companyId, userId)
      .then((response) => {
        dispatch(deleteCompanyUsersSuccess(userId));
        return {status: 200};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

/**
 * User Profile - actions
 */
export const editUserProfileSuccess = (response) => ({
  type: EDIT_USER,
  payload: response.body
});

// Update user profile
export function editMyUserProfile(companyId, userId, user) {
  return (dispatch) => {
    return company
      .editCompanyUserPromise(companyId, userId, user)
      .then((response) => {
        dispatch(editUserProfileSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

/**
 * Company Profile - actions
 */
export const editCompanyProfileSuccess = (response) => ({
  type: EDIT_COMPANY,
  payload: response.body
});

// Get company profile
export function getCompanyDetails(companyId) {
  return (dispatch) => {
    return company
      .getCompanyDetailsPromise(companyId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Update company profile
export function editCompanyProfile(companyId, body) {
  return (dispatch) => {
    return company
      .editCompanyProfilePromise(companyId, body)
      .then((response) => {
        dispatch(editCompanyProfileSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

/**
 * Company Preferences - actions
 */
export const getCompanyPreferencesSuccess = (response) => ({
  type: GET_COMPANY_PREFERENCES,
  payload: response.body
});

// Get company preferences
export function getCompanyPreferences(companyId) {
  return (dispatch) => {
    return company
      .getCompanyPreferencesPromise(companyId)
      .then((response) => {
        dispatch(getCompanyPreferencesSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Update company preferences
export function editCompanyPreferences(companyId, body) {
  return (dispatch) => {
    return company
      .editCompanyPreferencesPromise(companyId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Company Identification Codes - actions
 */
export const identifyingCodesRequestSuccess = (response) => ({
  type: GET_IDENTIFYING_CODES,
  payload: response.body
});

export const identifyingCodesRequestError = () => ({
  type: ALERT_ERROR,
  payload: 'There was an error getting identifying codes.'
});

// Get company identifying codes
export function fetchIdentifyingCodes() {
  return (dispatch) => {
    return company
      .fetchIdentifyingCodesPromise()
      .then((response) => {
        dispatch(identifyingCodesRequestSuccess(response));
      })
      .catch((err) => {
        dispatch(identifyingCodesRequestError());
      });
  };
}

/**
 * User Notification Preferences - actions
 */
export const getNotificationPreferencesSuccess = (response) => ({
  type: GET_NOTIFICATION_PREFERENCES,
  payload: response.body
});

// Get user notification preferences
export function getNotificationPreferences() {
  return (dispatch) => {
    return user
      .getNotificationPreferencesPromise()
      .then((response) => {
        dispatch(getNotificationPreferencesSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Update user notification preferences
export function editNotificationPreferences(body = {}) {
  return (dispatch) => {
    return user
      .editNotificationPreferencesPromise(body)
      .then((response) => {
        dispatch(getNotificationPreferencesSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        dispatch(displayError(response));
        return response;
      });
  };
}

/**
 * User Permissions - actions
 */
const getPermissionsSuccess = (response) => ({
  type: GET_PERMISSIONS,
  payload: response
});

// Get user permissions
export function getPermissions() {
  return (dispatch) => {
    return user
      .getPermissionsPromise()
      .then((response) => {
        dispatch(getPermissionsSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * User password - actions
 */
export const resetPasswordSuccess = (response) => ({
  type: RESET_PASSWORD,
  payload: response.body
});

export const usersAccountError = (error) => ({
  type: USER_ACCT_ERROR,
  payload: error
});

export function changePassword(currentPass, newPass) {
  return (dispatch) => {
    return user
      .changePasswordPromise(currentPass, newPass)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function resetPassword(email) {
  return (dispatch) => {
    return user
      .resetPasswordPromise(email)
      .then((response) => {
        dispatch(resetPasswordSuccess(response));
        return response;
      })
      .catch((response) => {
        return response;
      });
  };
}

export function setResetPassword(body) {
  return (dispatch) => {
    return user
      .setResetPasswordPromise(body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function setPassword(body) {
  return (dispatch) => {
    return user
      .setPasswordPromise(body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Cases - actions
 */
export const getCasesRequestSuccess = (response) => ({
  type: GET_CASES,
  payload: response.body
});

// Create case for shipment
export function createCaseForShipment(shipmentId, body = {}) {
  return (dispatch) => {
    return shipment
      .createCaseForShipmentPromise(shipmentId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Get cases by shipment
export function getCasesByShipmentId(shipmentId, opts = {}) {
  return (dispatch) => {
    return shipment
      .getCasesByShipmentIdPromise(shipmentId, opts)
      .then((response) => {
        dispatch(getCasesRequestSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Edit case for shipment
export function editCase(shipmentId, shipmentCaseId, body = {}) {
  return (dispatch) => {
    return shipment
      .editCasePromise(shipmentId, shipmentCaseId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Delete case for shipment
export function deleteCase(shipmentId, caseId) {
  return (dispatch) => {
    return shipment
      .deleteCasePromise(shipmentId, caseId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Case Notes - actions
 */
export const getCaseNotesRequestSuccess = (response) => ({
  type: GET_CASE_NOTES,
  payload: response.body
});

// Create note for a case
export function createNoteByCaseId(shipmentCaseId, body = {}) {
  return (dispatch) => {
    return shipment
      .createNoteByCaseIdPromise(shipmentCaseId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Get notes by case
export function getNotesByCaseId(shipmentCaseId, opts = {}) {
  return (dispatch) => {
    return shipment
      .getNotesByCaseIdPromise(shipmentCaseId, opts)
      .then((response) => {
        dispatch(getCaseNotesRequestSuccess(response));
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Edit note for a case
export function editCaseNote(shipmentCaseId, caseNoteId, body = {}) {
  return (dispatch) => {
    return shipment
      .editCaseNotePromise(shipmentCaseId, caseNoteId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Delete note for a case
export function deleteNoteByCaseId(shipmentCaseId, noteId) {
  return (dispatch) => {
    return shipment
      .deleteNoteByCaseIdPromise(shipmentCaseId, noteId)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Case Tasks - actions
 */
export const getTasksByCaseRequestPending = (shipmentCaseId) => ({
  type: LOADING_TASKS_FOR_CASE,
  payload: {
    id: shipmentCaseId,
    bool: true
  }
});

export const getTasksByCaseRequestSuccess = (response) => ({
  type: GET_TASKS_BY_CASE,
  payload: response.body
});

export const getTasksByCaseRequestComplete = (shipmentCaseId) => ({
  type: LOADING_TASKS_FOR_CASE,
  payload: {
    id: shipmentCaseId,
    bool: false
  }
});

// Create task for a case
export function createTaskInCase(caseId, body = {}) {
  return (dispatch) => {
    return shipment
      .createTaskInCasePromise(caseId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Get tasks for a case
export function getTasksByCaseId(shipmentCaseId, opts = {}) {
  return (dispatch) => {
    dispatch(getTasksByCaseRequestPending(shipmentCaseId));
    return shipment
      .getTasksByCaseIdPromise(shipmentCaseId, opts)
      .then((response) => {
        dispatch(getTasksByCaseRequestSuccess(response));
        dispatch(getTasksByCaseRequestComplete(shipmentCaseId));
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

/**
 * Tasks - actions
 */
export const getTasksRequestSuccess = (response) => ({
  type: GET_TASKS,
  payload: response.body
});

// Create task
export function createTask(body = {}) {
  return (dispatch) => {
    return shipment
      .createTaskPromise(body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Get tasks
export function getTasks(opts = {}) {
  return (dispatch) => {
    return shipment
      .getTasksPromise(opts)
      .then((response) => {
        dispatch(getTasksRequestSuccess(response));
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Edit task
export function editTask(taskId, body = {}) {
  return (dispatch) => {
    return shipment
      .editTaskPromise(taskId, body)
      .then((response) => {
        return {status: 200, details: response};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Delete task
export function deleteTask(taskId) {
  return (dispatch) => {
    return shipment
      .deleteTaskPromise(taskId)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

// Toggle task completion - @todo use `editTask` instead
export function toggleCompleteTask(taskId, body) {
  return (dispatch) => {
    return shipment
      .editTaskPromise(taskId, body)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        return response;
      });
  };
}

export function selectTask(task) {
  return (dispatch) => {
    dispatch({type: SELECT_TASK, payload: task});
  };
}

export function selectCase(oneCase) {
  return (dispatch) => {
    dispatch({type: SELECT_CASE, payload: oneCase});
  };
}

export function clearSelectedTask(userId) {
  return (dispatch) => {
    dispatch({type: CLEAR_SELECTED_TASK, payload: userId});
  };
}

export function clearSelectedCase(userId, titleString) {
  return (dispatch) => {
    dispatch({type: CLEAR_SELECTED_CASE, payload: {user: userId, title: titleString}});
  };
}

/**
 * Misc.
 */
export const toggleSideMenu = (toggle) => ({
  type: TOGGLE_SIDE_MENU,
  payload: toggle
});

//select a user for editing
export function selectUser(user) {
  return (dispatch) => {
    dispatch({type: SELECT_USER, payload: user});
  };
}

//clear selected user
export function clearSelectedUser() {
  return (dispatch) => {
    dispatch({type: CLEAR_SELECTED_USER, payload: null});
  };
}
