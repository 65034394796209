import {InvoiceRuleConfiguration, InvoiceRuleConfigurationType} from '@shipwell/settlements-sdk';
import {Field} from 'formik';
import {FormikToggleSwitch} from '@shipwell/shipwell-ui';
import {ConfigurationRow} from '../../ConfigurationRow';
import EditThresholdModal from './EditThresholdModal';
import EditRequiredDocsModal from './EditRequiredDocsModal';
import {boundaryTypeLabels, InvoiceRuleDescription} from './InvoiceRuleDescription';
import {isFinancialsThresholdRule} from 'App/api/settlements/typeGuards';

export const InvoiceRuleRow = ({invoiceRule}: {invoiceRule?: InvoiceRuleConfiguration}) => {
  if (!invoiceRule) {
    return null;
  }

  const ruleTypeName = isFinancialsThresholdRule(invoiceRule)
    ? `Financials ${boundaryTypeLabels[invoiceRule.details.boundary_type]} threshold`
    : configTypeLabels[invoiceRule.rule_type];

  const EditModal = invoiceRuleEditModals[invoiceRule.rule_type];

  return (
    <>
      <ConfigurationRow
        toggle={<Field checkedLabel="ON" uncheckedLabel="OFF" name={invoiceRule.id} component={FormikToggleSwitch} />}
        type={ruleTypeName}
        description={<InvoiceRuleDescription invoiceRule={invoiceRule} />}
        editModal={
          EditModal
            ? ({isModalOpen, setIsModalOpen}) => (
                <EditModal showModal={isModalOpen} onClose={() => setIsModalOpen(false)} invoiceRule={invoiceRule} />
              )
            : null
        }
      />
    </>
  );
};

const configTypeLabels: Record<InvoiceRuleConfigurationType, string> = {
  FINANCIALS_THRESHOLD: 'Financials threshold', // This should never get used. It's just to satisfy TS
  DOCUMENTS_REQUIRED: 'Document requirement',
  DOCUMENT_REQUIRED: 'Document requirement',
  SERVICE_PROVIDER_NAME_MATCH: 'Carrier match',
  INVOICEABLE_STATUS: 'Shipment delivered',
  MULTIPLE_INVOICES_PER_INVOICEABLE: 'Multiple invoices'
};

export type InvoiceRuleEditModal = <T extends InvoiceRuleConfiguration>(props: {
  showModal: boolean;
  onClose: () => void;
  invoiceRule: T;
}) => JSX.Element;

const invoiceRuleEditModals: Record<InvoiceRuleConfigurationType, InvoiceRuleEditModal | null> = {
  FINANCIALS_THRESHOLD: EditThresholdModal,
  DOCUMENTS_REQUIRED: EditRequiredDocsModal,
  DOCUMENT_REQUIRED: null,
  SERVICE_PROVIDER_NAME_MATCH: null,
  INVOICEABLE_STATUS: null,
  MULTIPLE_INVOICES_PER_INVOICEABLE: null
};
