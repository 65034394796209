import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';

export const getRateTotal = ({
  shipment,
  rateType,
  baseRate,
  percentOfLineHaul,
  minimumRate,
  distance
}: {
  shipment?: Shipment;
  rateType: string;
  baseRate: number;
  percentOfLineHaul?: number;
  minimumRate?: number | null;
  distance?: number;
}) => {
  // allow passing in distance directly if not using a shipment to calculate
  const totalMiles = distance || shipment?.total_miles || 0;

  switch (rateType) {
    case 'FLAT_RATE':
      return baseRate;
    case 'PER_MILE': {
      const calculatedRate = baseRate * totalMiles;
      return minimumRate ? Math.max(calculatedRate, minimumRate) : calculatedRate;
    }
    case 'PERCENT_OF_LINE_HAUL':
      return baseRate * (percentOfLineHaul || 0);
    default:
      return baseRate;
  }
};
