import {useState, useEffect, useCallback} from 'react';
import {Field, Formik} from 'formik';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {object, string, array, mixed} from 'yup';
import {formValueSelector} from 'redux-form';
import cloneDeep from 'lodash/cloneDeep';
import {Modal, FormikDateTimePicker, FormikTextarea} from '@shipwell/shipwell-ui';
import {ContractsDetails} from './ContractsDetails';
import TenderRequestFields from 'App/formComponents/formSections/tenderRequest';
import {fetchEquipmentTypes} from 'App/actions/_shipments';
import {getCarrierLocationCapacityAvailability} from 'App/api/carriers';
import {chargeLineItemDefaults, validateDollarValue} from 'App/utils/globals';
import {validateDateTimeNotInPast} from 'App/utils/dateTimeGlobals';
import {carriersValidationSchema} from 'App/utils/yupHelpers';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import withFlags from 'App/utils/withFlags';
import {roundNumberToSpecificDecimals} from 'App/utils/mathHelpers';
import {ContractSelect} from 'App/formComponents/formSections/tenderRequest/ContractSelect';
import {useTenderContract} from 'App/data-hooks/tendering/useTenderContract';
import {getRateTotal} from 'App/utils/tenderUtils';

const TenderRequestForm = ({
  values = {},
  onClose,
  onSubmit,
  show,
  shipmentDetailData,
  buyItNowAmountManual,
  buyItNowAmountQuote,
  equipmentTypesQuote,
  equipmentTypesManualShipment,
  modeQuote,
  modeManualShipment,
  specialInstructionsQuote,
  specialInstructionsManualShipment,
  equipmentTypes,
  dispatch,
  wfaLocationCapacity
}) => {
  //use the latest RFQ if there is no mode/equipment on the shipment
  const [latestRFQIndex, setLatestRFQIndex] = useState(null);
  const [locationCapacityAvailable, setLocationCapacityAvailable] = useState(true);
  const stopAddresses = shipmentDetailData?.stops?.map((stop) => stop.location.address);

  //get latest RFQ index
  useEffect(() => {
    if (shipmentDetailData && shipmentDetailData.rfqs && shipmentDetailData.rfqs.length > 0) {
      setLatestRFQIndex(shipmentDetailData.rfqs.length - 1);
    }
  }, [shipmentDetailData && shipmentDetailData.rfqs]);

  const getLocationCapacityAvailability = useCallback(async (shipmentId) => {
    try {
      const locationCapacityResponse = await getCarrierLocationCapacityAvailability(shipmentId);
      setLocationCapacityAvailable(locationCapacityResponse?.body?.capacity_available);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (shipmentDetailData?.id) {
      getLocationCapacityAvailability(shipmentDetailData.id);
    }
  }, [getLocationCapacityAvailability, shipmentDetailData]);

  const getEquipmentTypes = async () => {
    try {
      await dispatch(fetchEquipmentTypes());
    } catch (error) {
      console.error(error);
    }
  };

  //default the unit amount to the relevant 'buy it now' fields
  let defaultValueRate;
  const showOptions = {equipment_type: true, mode: true};
  //if multiple modes or equipment types, pass selections as a prop
  let filteredOptionsList = {showFilteredEquipTypes: false, showFilteredModes: false, equipment_type: [], mode: []};
  if (buyItNowAmountManual) {
    defaultValueRate = buyItNowAmountManual;
  } else if (buyItNowAmountQuote) {
    defaultValueRate = buyItNowAmountQuote;
  } else if (shipmentDetailData && shipmentDetailData.metadata && shipmentDetailData.metadata.buy_it_now_amount) {
    defaultValueRate = parseFloat(shipmentDetailData.metadata.buy_it_now_amount).toFixed(2);
  } else {
    defaultValueRate = '';
  }

  //default the equipment type to the relevant 'equipment type' fields
  let defaultEquipmentType;
  if (equipmentTypesQuote && equipmentTypesQuote.length > 0) {
    //deep clone equipment types so we can pass machine_readable as id key for options list
    const equipment_type = cloneDeep(equipmentTypesQuote);
    equipment_type.forEach((equip) => (equip.id = equip.machine_readable));
    //more than one equip type, show options in dropdown
    if (equipmentTypesQuote.length > 1) {
      filteredOptionsList = {
        ...filteredOptionsList,
        equipment_type,
        showFilteredEquipTypes: true
      };
      //react-select will match machine_readable value string to options list
      defaultEquipmentType = equipmentTypesQuote[0].machine_readable;
      //set default equip to single selected item
    } else if (equipmentTypesQuote.length === 1) {
      defaultEquipmentType = equipmentTypesQuote[0].machine_readable;
      showOptions.equipment_type = false;
    }
  } else if (equipmentTypesManualShipment) {
    if (!equipmentTypes.length) {
      getEquipmentTypes();
    } else {
      defaultEquipmentType = equipmentTypes.find(
        (equip) => parseInt(equipmentTypesManualShipment) === equip.id
      ).machine_readable;
      showOptions.equipment_type = false;
    }
  } else if (shipmentDetailData?.equipment_type?.machine_readable) {
    defaultEquipmentType = shipmentDetailData.equipment_type.machine_readable;
    showOptions.equipment_type = false;
  } else if (
    !shipmentDetailData?.equipment_type &&
    shipmentDetailData?.rfqs?.length > 0 &&
    latestRFQIndex &&
    shipmentDetailData?.rfqs[latestRFQIndex]?.equipment_types?.length > 0
  ) {
    //deep clone equipment types so we can pass machine_readable as id key for options list
    const equipment_type = cloneDeep(shipmentDetailData.rfqs[latestRFQIndex].equipment_types);
    equipment_type.forEach((equip) => (equip.id = equip.machine_readable));
    filteredOptionsList = {
      ...filteredOptionsList,
      equipment_type,
      showFilteredEquipTypes: true
    };
    //react-select will match machine_readable value string to options list
    defaultEquipmentType = shipmentDetailData.rfqs[latestRFQIndex].equipment_types[0].machine_readable;
  } else {
    defaultEquipmentType = '';
  }

  //default the equipment type to the relevant 'equipment type' fields
  let defaultMode;
  if (modeQuote) {
    if (modeQuote.length > 1) {
      filteredOptionsList = {
        ...filteredOptionsList,
        mode: modeQuote,
        showFilteredModes: true
      };
      defaultMode = modeQuote[0].id;
      //set default mode to single selected item
    } else if (modeQuote.length === 1) {
      defaultMode = modeQuote[0].id;
      showOptions.mode = false;
    }
  } else if (modeManualShipment) {
    defaultMode = parseInt(modeManualShipment);
    showOptions.mode = false;
  } else if (shipmentDetailData && shipmentDetailData.mode) {
    defaultMode = shipmentDetailData.mode.id;
    showOptions.mode = false;
  } else if (
    !shipmentDetailData?.mode &&
    shipmentDetailData?.rfqs?.length > 0 &&
    latestRFQIndex &&
    shipmentDetailData?.rfqs[latestRFQIndex]?.shipment_modes?.length > 0
  ) {
    filteredOptionsList = {
      ...filteredOptionsList,
      mode: shipmentDetailData.rfqs[latestRFQIndex].shipment_modes,
      showFilteredModes: true
    };
    defaultMode = shipmentDetailData.rfqs[latestRFQIndex].shipment_modes[0].id;
  } else {
    defaultMode = '';
  }
  let defaultSpecialInstructions;
  if (specialInstructionsQuote) {
    defaultSpecialInstructions = specialInstructionsQuote;
  } else if (specialInstructionsManualShipment) {
    defaultSpecialInstructions = specialInstructionsManualShipment;
  } else if (shipmentDetailData?.notes_for_carrier) {
    defaultSpecialInstructions = shipmentDetailData.notes_for_carrier;
  } else {
    defaultSpecialInstructions = '';
  }

  const currencyOfRecord = shipmentDetailData.preferred_currency;

  const defaultFormValues = {
    equipment_type: defaultEquipmentType,
    mode: defaultMode,
    expires_at: '',
    message: '',
    charge_line_items: [
      {
        unit_name: chargeLineItemDefaults.unit_name,
        unit_amount: defaultValueRate,
        unit_amount_currency: currencyOfRecord,
        unit_quantity: chargeLineItemDefaults.unit_quantity,
        category: chargeLineItemDefaults.category
      }
    ],
    //shipment_id is populated when submit is performed
    shipment: '',
    rate_type: null,
    info_message: defaultSpecialInstructions,
    tender_to_company: null,
    contract: null
  };

  const contractTenderRequestSchema = object().shape({
    expires_at: mixed().test({
      name: 'date not in the past',
      message: `Expiration date must not be in the past`,
      test: (value) => {
        if (value) {
          return validateDateTimeNotInPast(value);
        }
        return true;
      }
    })
  });

  const tenderRequestSchema = object().shape({
    mode: string().required('Mode is required.'),
    tender_to_company: carriersValidationSchema,
    equipment_type: string().required('Equipment type is required.'),
    charge_line_items: array().of(
      object().shape({
        unit_amount: mixed()
          .test({
            name: 'valid dollar value',
            message: 'Enter a dollar value',
            test: (value) => validateDollarValue(value)
          })
          .required('Rate is required.')
      })
    ),
    rate_type: string().required('Rate type is required.').nullable(),
    expires_at: mixed().test({
      name: 'date not in the past',
      message: `Expiration date must not be in the past`,
      test: (value) => {
        if (value) {
          return validateDateTimeNotInPast(value);
        }
        return true;
      }
    })
  });

  const {
    selectedApplicableContract,
    selectedMultiApplicableContracts,
    handleContractChange,
    carrierCapacityIsAvailable,
    getContractValues,
    getMultiContractsValues,
    isLoading: isLoadingTenderContract,
    hasSelectedContracts,
    perHourMessage
  } = useTenderContract({shipment: shipmentDetailData, percentOfLineHaul: defaultValueRate});

  /** Submit tender request values */
  const handleSubmit = async (values, actions) => {
    if (onSubmit) {
      //if ContractSelect only allows a single contract
      if (!selectedMultiApplicableContracts.length) {
        const payload = selectedApplicableContract
          ? getContractValues(values)
          : {
              ...values,
              charge_line_items: values.charge_line_items.map((lineItem) => {
                // getRateTotal calcs proper rate if PER_MILE, FLAT_RATE, etc
                const unitAmount = getRateTotal({
                  shipment: shipmentDetailData,
                  rateType: values.rate_type,
                  baseRate: lineItem.unit_amount,
                  percentOfLineHaul: defaultValueRate
                });
                return {
                  ...lineItem,
                  // update charge_line_items to have unit_amounts rounded to the ten-thousandths place...
                  // to prevent the BE from throwing an error
                  unit_amount: roundNumberToSpecificDecimals(unitAmount, 4)
                };
              })
            };

        return await onSubmit(payload, actions, {showWarning: wfaLocationCapacity && !locationCapacityAvailable});
      }
      const payloads = getMultiContractsValues(values);
      return await Promise.allSettled(
        payloads.map(
          async (payload) =>
            await onSubmit(payload, actions, {showWarning: wfaLocationCapacity && !locationCapacityAvailable})
        )
      );
    }
  };

  return (
    <Modal
      show={show}
      title="Tender To Carrier"
      onClose={onClose}
      footerComponent={null}
      className="tender-request-form-modal"
    >
      <div className="mb-12 flex flex-col gap-4">
        <ContractSelect
          contract={selectedApplicableContract}
          onChange={handleContractChange}
          modes={[shipmentDetailData?.mode?.code]}
          equipment={[shipmentDetailData?.equipment_type?.machine_readable]}
          stopAddresses={stopAddresses}
          currencyOfRecord={currencyOfRecord}
          isMulti
          shipmentId={shipmentDetailData?.id}
        />
        {hasSelectedContracts ? (
          // if contract(s) are selected show the tender by contract form
          <Formik
            enableReinitialize
            validationSchema={contractTenderRequestSchema}
            onSubmit={handleSubmit}
            initialValues={{
              expires_at: '',
              info_message: [defaultSpecialInstructions, perHourMessage].filter(Boolean).join('\n\n')
            }}
          >
            {({handleSubmit, isSubmitting, dirty}) => (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <ContractsDetails
                  carrierCapacityIsAvailable={carrierCapacityIsAvailable}
                  selectedApplicableContract={selectedApplicableContract}
                  selectedMultiApplicableContracts={selectedMultiApplicableContracts}
                  modes={[shipmentDetailData?.mode?.code]}
                  equipment={[shipmentDetailData?.equipment_type?.name]}
                  currencyOfRecord={currencyOfRecord}
                  shipmentId={shipmentDetailData?.id}
                />
                <Field label="Expiration" name="expires_at" component={FormikDateTimePicker} />
                <Field label="Special Instructions" name="info_message" component={FormikTextarea} />
                <ModalFormFooter
                  onCancel={onClose}
                  dirty={dirty}
                  isSubmitting={isSubmitting}
                  isValid={carrierCapacityIsAvailable && !isLoadingTenderContract}
                />
              </form>
            )}
          </Formik>
        ) : (
          // else we show the manual tender form with all tender fields exposed to the user
          <Formik
            enableReinitialize
            validationSchema={tenderRequestSchema}
            onSubmit={handleSubmit}
            initialValues={{...defaultFormValues, ...values}}
          >
            {({handleSubmit, isSubmitting, dirty}) => (
              <form onSubmit={handleSubmit} className="tender-request-form" noValidate="novalidate">
                <TenderRequestFields
                  shipmentDetailData={shipmentDetailData || {}}
                  showOptions={showOptions}
                  filteredOptionsList={filteredOptionsList}
                  locationCapacityAvailable={locationCapacityAvailable}
                  wfaLocationCapacity={wfaLocationCapacity}
                  currencyOfRecord={currencyOfRecord}
                />
                <ModalFormFooter onCancel={onClose} dirty={dirty} isSubmitting={isSubmitting} />
              </form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};
const newQuoteSelector = formValueSelector('newQuoteForm');
const manualShipmentSelector = formValueSelector('newShipmentForm');
export default compose(
  withFlags('wfaLocationCapacity'),
  connect((state) => ({
    buyItNowAmountQuote: newQuoteSelector(state, 'metadata.buy_it_now_amount'),
    equipmentTypesManualShipment: manualShipmentSelector(state, 'equipment_type'),
    buyItNowAmountManual: manualShipmentSelector(state, 'metadata.buy_it_now_amount'),
    equipmentTypesQuote: newQuoteSelector(state, 'equipment_type'),
    modeQuote: newQuoteSelector(state, 'mode'),
    modeManualShipment: manualShipmentSelector(state, 'mode'),
    equipmentTypes: state.shipments.equipmentTypes,
    specialInstructionsQuote: newQuoteSelector(state, 'notes_for_carrier'),
    specialInstructionsManualShipment: manualShipmentSelector(state, 'notes_for_carrier')
  }))
)(TenderRequestForm);
