import {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Modal, DeprecatedButton} from '@shipwell/shipwell-ui';
import ExecuteRoutingGuide from 'App/containers/routingGuides/execute';
import {permViewContracts} from 'App/utils/globals';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import withStatusToasts, {
  WithStatusToastsPropTypes,
  WithStatusToastsDefaultProps
} from 'App/components/withStatusToasts';
import {
  WORKFLOW_WARNING_OVER_CAPACITY_ON_EXECUTION,
  ROUTING_GUIDE_SUCCESS_ON_EXECUTION
} from 'App/containers/workflows/workflowConstants';

const PUSH_TO_ROUTING_GUIDE_TITLE = 'Push To Routing Guide';

const PushToRoutingGuideAction = ({
  shipment,
  onUpdateShipment,
  onExecuteRoutingGuide,
  setSuccess,
  setWarning,
  hasContractsViewPermission,
  isContractsEnabled,
  isRoutingGuidesEnabled
}) => {
  const [showPushToRoutingGuideModal, setShowPushToRoutingGuideModal] = useState(false);

  const handlePushToRoutingGuideClick = () => setShowPushToRoutingGuideModal(true);

  const {hasFTL, hasDrayage, hasLTL, hasVLTL, hasIntermodal, hasRail} = checkShipmentModes(shipment.mode);
  if (
    (hasFTL || hasDrayage || hasLTL || hasVLTL || hasIntermodal || hasRail) &&
    hasContractsViewPermission &&
    isContractsEnabled &&
    isRoutingGuidesEnabled
  ) {
    return (
      <>
        <DeprecatedButton variant="text" onClick={handlePushToRoutingGuideClick}>
          {PUSH_TO_ROUTING_GUIDE_TITLE}
        </DeprecatedButton>
        <Modal
          show={showPushToRoutingGuideModal}
          title="Push to Routing Guide"
          footerComponent={null}
          onClose={() => setShowPushToRoutingGuideModal(false)}
        >
          <ExecuteRoutingGuide
            selectedShipment={shipment}
            onCancel={() => setShowPushToRoutingGuideModal(false)}
            onSubmitSuccess={({showWarning}) => {
              setShowPushToRoutingGuideModal(false);
              showWarning
                ? setWarning(
                    'Your Routing Guide is Running!',
                    WORKFLOW_WARNING_OVER_CAPACITY_ON_EXECUTION,
                    'top-right',
                    {
                      portal: true
                    }
                  )
                : setSuccess('Your Routing Guide is Running!', ROUTING_GUIDE_SUCCESS_ON_EXECUTION, 'top-right', {
                    portal: true
                  });
              onUpdateShipment(shipment.id);
              onExecuteRoutingGuide(shipment.id);
            }}
          />
        </Modal>
      </>
    );
  }
  return null;
};

PushToRoutingGuideAction.propTypes = {
  shipment: PropTypes.object,
  onUpdateShipment: PropTypes.func,
  onExecuteRoutingGuide: PropTypes.func,
  hasContractsViewPermission: PropTypes.bool,
  isContractsEnabled: PropTypes.bool,
  isRoutingGuidesEnabled: PropTypes.bool,
  ...WithStatusToastsPropTypes
};

PushToRoutingGuideAction.defaultProps = {
  shipment: {},
  onUpdateShipment: () => {},
  onExecuteRoutingGuide: () => {},
  hasContractsViewPermission: false,
  isContractsEnabled: false,
  isRoutingGuidesEnabled: false,
  ...WithStatusToastsDefaultProps
};

export default compose(
  withStatusToasts,
  connect((state) => ({
    hasContractsViewPermission:
      state.userProfile.user.permissions && state.userProfile.user.permissions.includes(permViewContracts),
    isContractsEnabled:
      state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.contracts_enabled,
    isRoutingGuidesEnabled:
      state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.policies_enabled
  }))
)(PushToRoutingGuideAction);
