import util from 'util';
import {Component, ReactNode} from 'react';

// eslint-disable-next-line import/no-named-as-default-member
export default class ErrorBoundary extends Component<
  {children?: ReactNode; location?: string; meta?: unknown},
  {error: unknown; errorInfo: unknown}
> {
  constructor(props: {children?: ReactNode}) {
    super(props);
    this.state = {error: null, errorInfo: null};
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return {error};
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    this.setState({error, errorInfo});
  }

  render() {
    if (this.state.error) {
      const {meta, location} = this.props;
      if (meta) {
        console.error('ErrorBoundary caught an error with `meta`', {
          ...this.state,
          meta,
          location
        });
      }
      return (
        <div className="sw-error-boundary-error">
          <h1>ERROR</h1>
          {location ? <p>Location: {location}</p> : null}
          <pre>{util.inspect(this.state, false, 4, false)}</pre>
        </div>
      );
    }
    return this.props.children;
  }
}
