import {uniqBy} from 'lodash';
import {tenderStatusOptions} from '../../utils/DashboardUtils';
import store from 'App/routes/store';
import {getCarrierRelationshipsPromise} from 'App/actions/vendors';
import {fetchVendorRelationshipsPromise} from 'App/api/shipwellUI';
import {componentTypes} from 'App/components/TableFiltersWithSavedViews/constants';
import {getCreatorFilterOptionsPromise} from 'App/api/company';
import {getShipmentsStopLocationsByCompanyName, getShipmentsPromise} from 'App/api/shipment';
import modeFilterOptions from 'App/utils/shipmentModeFilterOptions';

export const dashboardStatuses = [
  {value: 'open', label: 'Open', index: 0.1},
  {value: 'delayed', label: 'Delayed', index: 0.2},
  {value: 'scheduled', label: 'Scheduled', index: 0.3},
  {value: 'auction_running', label: 'Auction Running', index: 3},
  {value: 'out_for_delivery', label: 'Out for Delivery', index: 11}
];

const getCompanyNameFilterOptionsPromise = async (opts) => {
  const response = await getShipmentsStopLocationsByCompanyName(opts);
  return {results: uniqBy(response.body.results, 'company_name')};
};

const getShipmentPropertyOptionsPromise = async (opts, propertyId, filterName) => {
  const response = await getShipmentsPromise({
    ...opts,
    q: null,
    [filterName]: opts.q,
    ordering: propertyId
  });

  return {
    results: response?.body?.results?.reduce((resultsList, shipment) => {
      const data = shipment[propertyId];
      if (data && !resultsList.find((result) => result.value === data)) {
        resultsList.push({value: data});
      }
      return resultsList;
    }, [])
  };
};

const filters = {
  mode: {
    title: 'Mode',
    id: 'mode',
    component: componentTypes.LIST_SELECT,
    options: modeFilterOptions(),
    allowMultiSelect: true
  },
  status: {
    title: 'Status',
    id: 'status',
    component: componentTypes.LIST_SELECT,
    allowMultiSelect: true,
    allowModifiers: true
  },
  archived: {
    title: 'Archived',
    id: 'archived',
    component: componentTypes.LIST_SELECT,
    options: [{label: 'Include Archived Shipments', value: true}]
  },
  pickup_location: {
    title: 'Pickup Address',
    id: 'pickupStops',
    component: componentTypes.LOCATION_LOOKUP
  },
  delivery_location: {
    title: 'Delivery Address',
    id: 'deliveryStops',
    component: componentTypes.LOCATION_LOOKUP
  },
  vendor: {
    title: 'Carrier',
    id: 'vendor',
    component: componentTypes.TYPEAHEAD,
    performSearch: getCarrierRelationshipsPromise,
    placeholder: 'Search Carrier',
    valueField: 'shipwell_vendor.id',
    labelField: 'shipwell_vendor.name'
  },
  customer: {
    title: 'Customer',
    id: 'customer',
    component: componentTypes.TYPEAHEAD,
    performSearch: async (opts) => {
      const result = await fetchVendorRelationshipsPromise(opts);
      return result.body || {};
    },
    placeholder: 'Search Customers',
    valueField: 'company.id',
    labelField: 'company.name'
  },
  equipment: {
    title: 'Equipment',
    id: 'equipment',
    component: componentTypes.TYPEAHEAD,
    searchLocalList: true,
    localOptionsLocation: 'shipments.equipmentTypes',
    placeholder: 'Search Equipment',
    valueField: 'machine_readable',
    labelField: 'name'
  },
  pickup_date: {
    title: 'Pickup Date',
    id: 'pickup',
    component: componentTypes.DATE_PICKER
  },
  delivery_date: {
    title: 'Delivery Date',
    id: 'dropoff',
    component: componentTypes.DATE_PICKER
  },
  createdAt: {
    title: 'Created',
    id: 'createdAt',
    component: componentTypes.DATE_PICKER,
    preventFuture: true
  },
  reps: {
    title: 'Reps',
    id: 'reps',
    component: componentTypes.TYPEAHEAD,
    searchLocalList: true,
    localOptionsLocation: 'users.companyUsers.results',
    placeholder: 'Search Reps',
    valueField: 'id',
    labelField: ['first_name', 'last_name'] //labelField can handle an array -- will concat the fields with a ' '
  },
  tags: {
    title: 'Tags',
    id: 'tags',
    component: componentTypes.TAG_SELECTOR,
    localOptionsLocation: 'shipments.tags',
    placeholder: 'Search Tags',
    allowModifiers: true
  },
  service_level: {
    title: 'Service Level',
    id: 'service_level',
    component: componentTypes.TYPEAHEAD,
    searchLocalList: true,
    localOptionsLocation: 'shipments.serviceLevels',
    placeholder: 'Search Service Levels',
    valueField: 'code',
    labelField: 'description'
  },
  alerts: {
    title: 'Alerts',
    id: 'alerts',
    component: componentTypes.LIST_SELECT,
    options: [{label: 'Alert Exists', value: true}]
  },
  load_board_enabled: {
    title: 'Load Board',
    id: 'loadBoardEnabled',
    component: componentTypes.LIST_SELECT,
    options: [{label: 'On Load Board', value: true}]
  },
  invoice: {
    title: 'Invoice',
    id: 'hasInvoice',
    component: componentTypes.LIST_SELECT,
    options: [
      {label: 'Invoice Exists', value: true},
      {label: 'Invoice Does Not Exist', value: false}
    ]
  },
  bill: {
    title: 'Bill',
    id: 'hasBill',
    component: componentTypes.LIST_SELECT,
    options: [
      {label: 'Bill Exists', value: true},
      {label: 'Bill Does Not Exist', value: false}
    ]
  },
  accessorials: {
    title: 'Shipment Accessorials',
    id: 'accessorial',
    component: componentTypes.TYPEAHEAD,
    searchLocalList: true,
    localOptionsLocation: 'shipments.shipmentAccessorialCodes',
    placeholder: 'Search Shipment Accessorials',
    valueField: 'code',
    labelField: 'description'
  },
  created_by: {
    title: 'Created By',
    id: 'createdBy',
    component: componentTypes.TYPEAHEAD,
    performSearch: (opts) => getCreatorFilterOptionsPromise(store.getState().auth.company.id, opts),
    placeholder: 'Search Users or Sources',
    valueField: 'id',
    labelField: 'label'
  },
  origin_company: {
    title: 'Pickup Company',
    id: 'pickupStopsCompanyNameContains',
    component: componentTypes.TYPEAHEAD,
    performSearch: (opts) => getCompanyNameFilterOptionsPromise(opts),
    placeholder: 'Search Companies',
    valueField: 'company_name',
    simpleValue: true
  },
  destination_company: {
    title: 'Delivery Company',
    id: 'deliveryStopsCompanyNameContains',
    component: componentTypes.TYPEAHEAD,
    performSearch: (opts) => getCompanyNameFilterOptionsPromise(opts),
    placeholder: 'Search Companies',
    valueField: 'company_name',
    simpleValue: true
  },
  workflow_execution_info: {
    title: 'Workflows',
    id: 'workflowExecutionStatus',
    component: componentTypes.LIST_SELECT,
    options: [
      {label: 'Running', value: 'RUNNING'},
      {label: 'Complete', value: 'SUCCESS'},
      {label: 'Failed', value: 'FAILED'},
      {label: 'Cancelled', value: 'CANCELLED'}
    ],
    allowMultiSelect: true
  },
  container_number: {
    title: 'Container #',
    id: 'drayageContainerNumberContains',
    component: componentTypes.TYPEAHEAD,
    performSearch: (opts) =>
      getShipmentPropertyOptionsPromise(opts, 'drayage_container_number', 'drayageContainerNumberContains'),
    placeholder: 'Search Container #',
    valueField: 'value',
    simpleValue: true
  },
  seal_number: {
    title: 'Seal #',
    id: 'drayageSealNumberContains',
    component: componentTypes.TYPEAHEAD,
    performSearch: (opts) =>
      getShipmentPropertyOptionsPromise(opts, 'drayage_seal_number', 'drayageSealNumberContains'),
    placeholder: 'Search Seal #',
    valueField: 'value',
    simpleValue: true
  },
  estimated_arrival_date: {
    title: 'Estimated Container Arrival',
    id: 'drayage_estimated_arrival_date',
    component: componentTypes.DATE_PICKER
  },
  release_date: {
    title: 'Release Date',
    id: 'drayage_release_date',
    component: componentTypes.DATE_PICKER
  },
  last_free_date: {
    title: 'Last Free Date',
    id: 'drayage_last_free_date',
    component: componentTypes.DATE_PICKER
  },
  container_return_date: {
    title: 'Container Return Date',
    id: 'drayage_container_return_date',
    component: componentTypes.DATE_PICKER
  },
  custom_reference_data: {
    title: 'Reference - Custom Data',
    id: 'shipmentShipwellCustomData',
    component: componentTypes.CUSTOM_SELECTION
  },
  tender_status: {
    title: 'Tender Status',
    id: 'tenderStatus',
    component: componentTypes.LIST_SELECT,
    options: tenderStatusOptions,
    allowMultiSelect: true
  }
};

export default filters;
