import {flexRender, Table as TableType} from '@tanstack/react-table';
import {Contract} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Modal, Button, Tooltip, SvgIcon} from '@shipwell/shipwell-ui';
import {
  useRateTableOverview,
  ShipwellContractLaneTable
} from 'App/formComponents/formSections/contractFields/RateTable/useRateTableOverview';
import useToggle from 'App/utils/hooks/useToggle';
import {useTypedTable, useTableColumns} from 'App/components/TypedTable/hooks';
import {Table, TableHeaderRow, TableHeader, TableRow, TableCell} from 'App/components/TypedTable/baseComponents';
import ShipwellLoader from 'App/common/shipwellLoader';
import {RATE_TABLE_LIST_KEY} from 'App/utils/tableTypeKeys';

export const RateTableOverview = ({contract}: {contract: Contract}) => {
  const [showModal, toggleShowModal] = useToggle();

  const {rate_currency: rateCurrency} = contract;

  const {defaultColumns, contractLaneInfiniteScroll, flatData} = useRateTableOverview({
    contractId: contract.id,
    rateCurrency
  });

  const {handleScroll, isFetchingNextPage, isLoading} = contractLaneInfiniteScroll;
  const isFetchingLanes = (isFetchingNextPage || isLoading) && !!flatData.length;

  const {columns} = useTableColumns(defaultColumns, RATE_TABLE_LIST_KEY);

  const table = useTypedTable({
    data: flatData || [],
    columns
  });

  return (
    <>
      <div className="flex items-center">
        <Button variant="tertiary" onClick={toggleShowModal} isCompact disabled={!flatData.length}>
          View Rate Table Details
        </Button>
        <Tooltip
          placement="top"
          tooltipContent="You can modify rate table details using the Contract Importer"
          trigger="hover"
        >
          <SvgIcon name="InfoOutlined" color="$sw-icon" />
        </Tooltip>
      </div>
      <Modal
        title="Rate Table"
        onClose={toggleShowModal}
        show={showModal}
        footerComponent={null}
        size="large"
        bodyVariant="disableOverflowScroll"
      >
        <RateTableTable table={table} onScroll={handleScroll} isFetchingLanes={isFetchingLanes} />
      </Modal>
    </>
  );
};

const RateTableTable = ({
  table,
  onScroll,
  isFetchingLanes
}: {
  table: TableType<ShipwellContractLaneTable | undefined>;
  onScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  isFetchingLanes?: boolean;
}) => (
  <div className="flex size-full max-h-[70vh] grow flex-col justify-between overflow-auto" onWheel={onScroll}>
    <Table
      head={table.getHeaderGroups().map((headerGroup) => (
        <TableHeaderRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableHeader key={header.id} width={header.getSize()} onResize={header.getResizeHandler()}>
              <div className="flex items-center gap-1">
                {flexRender(header.column.columnDef.header, header.getContext())}
              </div>
            </TableHeader>
          ))}
        </TableHeaderRow>
      ))}
      body={table.getRowModel().rows.map((row) => (
        <TableRow key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
          ))}
        </TableRow>
      ))}
    />
    {isFetchingLanes ? (
      <div className="relative flex flex-col items-center">
        <ShipwellLoader loading />
        Loading more results ...
      </div>
    ) : null}
  </div>
);
