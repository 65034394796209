import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {SidebarContent} from '@shipwell/shipwell-ui';
import {OceanTrackingServiceTypeEnum, RailTrackingServiceTypeEnum} from '@shipwell/corrogo-sdk';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import fromPairs from 'lodash/fromPairs';
import DocumentList from '../SidebarDocuments/DocumentList';
import DocumentViewModal from '../SidebarDocuments/DocumentViewModal';
import UploadDocuments from '../SidebarDocuments/UploadDocuments';
import {State} from 'App/reducers/types';
import {UploadDocumentVariablesType} from 'App/api/corrogo/utils/mergeDataFunctions';
import {
  useUpdateShipmentDocuments,
  useUpdateServiceDocuments,
  useUpdateTransportationOrderDocuments,
  useTransportationOrders,
  useShipmentLegsDocuments,
  useUpdateDrayageLegDocuments,
  useUserDetails,
  useV3Shipment
} from 'App/data-hooks';

enum DocumentSource {
  SHIPMENT = 'shipment',
  OCEAN = 'ocean',
  RAIL = 'rail',
  TO = 'transportation_order',
  LEG = 'leg'
}

const SERVICE_DOCUMENT_SOURCE = {
  [OceanTrackingServiceTypeEnum.OceanTracking]: DocumentSource.OCEAN,
  [RailTrackingServiceTypeEnum.RailTracking]: DocumentSource.RAIL
};

const DocumentsController = ({
  companyId,
  shipmentId,
  handleUploadDocument
}: {
  companyId: string;
  shipmentId: string;
  handleUploadDocument: ({file, description, type}: UploadDocumentVariablesType) => void;
}): JSX.Element => {
  const [viewlegId, setViewLegId] = useState<string>('');
  const [viewDocumentId, setViewDocumentId] = useState<string>('');
  const {context, isLoading} = useV3Shipment(shipmentId);
  const {transportationOrderDocuments} = useTransportationOrders(shipmentId);
  const {
    context: {shipmentLegsDocuments}
  } = useShipmentLegsDocuments(shipmentId);
  const {serviceDocuments, shipmentDocuments, serviceDisplayValues, service} = context;
  const {serviceType} = serviceDisplayValues;
  const {updateShipmentDocumentMutation, deleteShipmentDocumentMutation} = useUpdateShipmentDocuments(shipmentId);
  const {
    updateOceanTrackingDocumentMutation,
    updateRailTrackingDocumentMutation,
    deleteOceanTrackingDocumentMutation,
    deleteRailTrackingDocumentMutation
  } = useUpdateServiceDocuments(service?.id || '');
  const {updateDrayageLegDocumentMutation, deleteDrayageLegDocumentMutation} = useUpdateDrayageLegDocuments(viewlegId);
  const {updateTransportationOrderDocumentMutation, deleteTransportationOrderDocumentMutation} =
    useUpdateTransportationOrderDocuments(shipmentId);
  const documentSources = {
    ...fromPairs(serviceDocuments.map((document) => [document.id, get(SERVICE_DOCUMENT_SOURCE, [serviceType || ''])])),
    ...fromPairs(shipmentDocuments.map((document) => [document.id, DocumentSource.SHIPMENT])),
    ...fromPairs(transportationOrderDocuments.map((document) => [document.id, DocumentSource.TO])),
    ...fromPairs(shipmentLegsDocuments.map((document) => [document.id, DocumentSource.LEG]))
  };
  const transportationOrders = fromPairs(
    transportationOrderDocuments.map((document) => [document.id, document.transportationOrderId])
  );
  const drayageLegs = fromPairs(shipmentLegsDocuments.map((document) => [document.id, document.legId]));
  const documents = [
    ...serviceDocuments,
    ...shipmentDocuments,
    ...transportationOrderDocuments,
    ...shipmentLegsDocuments
  ].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
  const userIds = uniq(documents.map((document) => document.created_by_user_id || ''));
  const userDetails = useUserDetails(companyId, userIds);

  useEffect(() => {
    const legDocument = shipmentLegsDocuments.find((document) => document.id === viewDocumentId);
    legDocument && setViewLegId(legDocument.legId);
  }, [viewDocumentId, shipmentLegsDocuments]);

  const handleUpdateDocument = ({description, type}: {description: string; type: string}) => {
    const documentId = viewDocumentId;
    const serviceId = service?.id || '';
    switch (documentSources[documentId]) {
      case DocumentSource.SHIPMENT: {
        updateShipmentDocumentMutation.mutate({shipmentId, documentId, description, type});
        break;
      }
      case DocumentSource.OCEAN: {
        updateOceanTrackingDocumentMutation.mutate({serviceId, documentId, description, type});
        break;
      }
      case DocumentSource.RAIL: {
        updateRailTrackingDocumentMutation.mutate({serviceId, documentId, description, type});
        break;
      }
      case DocumentSource.TO: {
        const transportationOrderId = transportationOrders[documentId];
        updateTransportationOrderDocumentMutation.mutate({transportationOrderId, documentId, description, type});
        break;
      }
      case DocumentSource.LEG: {
        const legId = drayageLegs[documentId];
        updateDrayageLegDocumentMutation.mutate({legId, documentId, description, type});
      }
    }
  };

  const handleDeleteDocument = (documentId: string) => {
    const serviceId = service?.id || '';
    switch (documentSources[documentId]) {
      case DocumentSource.SHIPMENT: {
        deleteShipmentDocumentMutation.mutate({shipmentId, documentId});
        break;
      }
      case DocumentSource.OCEAN: {
        deleteOceanTrackingDocumentMutation.mutate({serviceId, documentId});
        break;
      }
      case DocumentSource.RAIL: {
        deleteRailTrackingDocumentMutation.mutate({serviceId, documentId});
        break;
      }
      case DocumentSource.TO: {
        const transportationOrderId = transportationOrders[documentId];
        deleteTransportationOrderDocumentMutation.mutate({transportationOrderId, documentId});
        break;
      }
      case DocumentSource.LEG: {
        const legId = drayageLegs[documentId];
        deleteDrayageLegDocumentMutation.mutate({legId, documentId});
      }
    }
  };

  return (
    <>
      <DocumentList
        isLoading={isLoading}
        documents={documents}
        users={userDetails}
        viewDocumentId={viewDocumentId}
        onDocumentClick={setViewDocumentId}
        onDocumentUpload={handleUploadDocument}
      />
      <DocumentViewModal
        document={documents.find((document) => document.id === viewDocumentId)}
        closeModal={() => {
          setViewDocumentId('');
          setViewLegId('');
        }}
        onUpdateDocument={handleUpdateDocument}
        onDeleteDocument={handleDeleteDocument}
      />
    </>
  );
};

const SidebarDocuments = ({
  companyId,
  shipmentId,
  onClose,
  ...props
}: {
  companyId: string;
  shipmentId: string;
  onClose: () => void;
}): JSX.Element => {
  const {uploadShipmentDocumentMutation} = useUpdateShipmentDocuments(shipmentId);

  const handleUploadDocument = ({file, description, type}: UploadDocumentVariablesType) => {
    uploadShipmentDocumentMutation.mutate({shipmentId, file, description, type});
  };

  return (
    <SidebarContent
      className="shipment__document-container"
      title="Documents"
      action={<UploadDocuments label="Upload" onDocumentUpload={handleUploadDocument} />}
      onClose={onClose}
      // spreading props are required for SidebarContent in the shipwell-ui
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <DocumentsController companyId={companyId} shipmentId={shipmentId} handleUploadDocument={handleUploadDocument} />
    </SidebarContent>
  );
};

SidebarDocuments.tabsRole = 'TabPanel';

export default connect((state: State) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  companyId: state.userCompany.company?.id || ''
}))(SidebarDocuments);
