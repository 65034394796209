import {ReactNode} from 'react';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {Form, Formik, FormikHelpers} from 'formik';
import {CompanyInternationalPreferences} from '@shipwell/backend-core-sdk';
import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {useQueryClient} from '@tanstack/react-query';
import PageHeader from 'App/common/pageHeader';
import {CurrenciesConfiguration} from 'App/containers/internationalization/components/CurrenciesConfiguration';
import Error404Page2 from 'App/common/Error404Page2';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {useUpdateInternationalPreferences} from 'App/containers/internationalization/hooks/useUpdateInternationalPreferences';
import {useGetInternationalPreferences} from 'App/containers/internationalization/hooks/useGetInternationalPreferences';
import {INTERNATIONAL_CONFIGS} from 'App/data-hooks/queryKeys';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {
  INTERNATIONAL_CONFIG_SAVE_ERROR_MESSAGE,
  INTERNATIONAL_CONFIG_SAVE_ERROR_TITLE,
  INTERNATIONAL_CONFIG_SAVE_SUCCESS_MESSAGE,
  INTERNATIONAL_CONFIG_SAVE_SUCCESS_TITLE
} from 'App/containers/internationalization/constants';
import {
  getInitialValues,
  internationalConfigurationsSchema
} from 'App/containers/internationalization/components/utils/formUtils';
import Loader from 'App/common/shipwellLoader';

const Layout = ({children}: {children: ReactNode}) => (
  <div className="w-full">
    <PageHeader title="International Configurations" className="bg-sw-background" />
    <main className="flex flex-col gap-6 p-6">{children}</main>
  </div>
);
const InternationalConfigurationsCard = ({children, title}: {children: ReactNode; title: string}) => (
  <Card title={title} className="w-full" draggableProvided={undefined} isCollapsible>
    <CollapsibleCardContent>{children}</CollapsibleCardContent>
  </Card>
);

export const InternationalConfigurationsViewBase = ({setSuccess, setError}: Partial<WithStatusToastProps>) => {
  const {data: internationalPreferences, error} = useGetInternationalPreferences();
  const {mutate: updateInternationalPreferences} = useUpdateInternationalPreferences();
  const queryClient = useQueryClient();

  const onSubmit = (
    values: CompanyInternationalPreferences,
    {setSubmitting, resetForm}: FormikHelpers<CompanyInternationalPreferences>
  ) => {
    queryClient.setQueryData([INTERNATIONAL_CONFIGS], values);
    updateInternationalPreferences(
      {values},
      {
        onSettled: () => {
          setSubmitting(false);
          void queryClient.invalidateQueries([INTERNATIONAL_CONFIGS]);
        },
        onSuccess: ({data: internationalConfigs}) => {
          setSuccess?.(INTERNATIONAL_CONFIG_SAVE_SUCCESS_TITLE, INTERNATIONAL_CONFIG_SAVE_SUCCESS_MESSAGE);
          resetForm({values: internationalConfigs});
        },
        onError: () => setError?.(INTERNATIONAL_CONFIG_SAVE_ERROR_TITLE, INTERNATIONAL_CONFIG_SAVE_ERROR_MESSAGE)
      }
    );
  };

  if (internationalPreferences) {
    return (
      <Layout>
        <Formik
          initialValues={getInitialValues(internationalPreferences)}
          validationSchema={internationalConfigurationsSchema}
          onSubmit={onSubmit}
        >
          {({dirty, resetForm}) => (
            <Form noValidate>
              <InternationalConfigurationsCard title="Currencies">
                <CurrenciesConfiguration />
              </InternationalConfigurationsCard>
              {dirty ? <FormFooter primaryActionName="Save" secondaryActionName="Cancel" onCancel={resetForm} /> : null}
            </Form>
          )}
        </Formik>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <InternationalConfigurationsCard title="Currencies">An error has occurred.</InternationalConfigurationsCard>
      </Layout>
    );
  }

  return (
    <Layout>
      <InternationalConfigurationsCard title="Currencies">
        <Loader loading />
      </InternationalConfigurationsCard>
    </Layout>
  );
};

const InternationalConfigurationsView = WithStatusToasts(InternationalConfigurationsViewBase);

export const ConditionalInternationalConfigurationsView = () => {
  const {stmShipmentCurrencyOfRecord} = useFlags();
  return !stmShipmentCurrencyOfRecord ? (
    <div className="w-full">
      <Error404Page2 />
    </div>
  ) : (
    <InternationalConfigurationsView />
  );
};
