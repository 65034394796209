/* eslint-disable promise/prefer-await-to-then */
import axios from 'axios';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

const OTTER_DJANGO = basePath.slice(0, -3); //remove the 'v2' at the end of the standard basePath

/**
 * Create a new bulk operation
 */
export async function createBulkOperation(body) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      }
    };
    try {
      const response = axios.post(OTTER_DJANGO + '/bulk-operations/', body, config);
      resolve(response);
    } catch (error) {
      if (error.response) {
        reject({body: error.response.data});
      } else {
        reject({body: {error_description: 'An unknown error occurred.'}});
      }
    }
  });
}

/**
 * Retrieve a bulkOperation by id
 */
export async function getBulkOperation(bulkOperationId) {
  const accessToken = await getAccessToken('workflows-api');

  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        authorization: accessToken
      }
    };
    const request = axios.get(OTTER_DJANGO + '/bulk-operations/' + bulkOperationId + '/', config);

    request
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        if (error.response) {
          reject({body: error.response.data});
        } else {
          reject({body: {error_description: 'An unknown error occurred.'}});
        }
      });
  });
}
