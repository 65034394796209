import {Field, useFormikContext} from 'formik';
import PropTypes from 'prop-types';
import {FormikSelect} from '@shipwell/shipwell-ui';
import useHazmatCodes from 'App/data-hooks/mdm/useHazmatCodes';

/**
 * Render Hazmat option label
 * @param {Object.<string, string>} option
 *
 * @return String
 */
function renderHazmatOptionLabel(option) {
  if (option.identification_number) {
    return [option.identification_number, option.proper_shipping_name, option.hazard_class, option.packing_group]
      .filter((a) => a)
      .join(' - ');
  }

  return [
    option.hazmat_identification_number,
    option.hazmat_proper_shipping_name,
    option.hazmat_hazard_class,
    option.hazmat_packing_group
  ]
    .filter((a) => a)
    .join(' - ');
}

const HazmatCodesField = ({name, label, onChange, ...props}) => {
  const {setFieldValue} = useFormikContext();

  const {hazmatOptions, isLoading} = useHazmatCodes();
  const handleHazmatCodeSelection = (value, ...args) => {
    // Clear out hazmat codes when unselected; otherwise set the field value
    setFieldValue(name, value || '');

    onChange(value, ...args);
  };

  return (
    <Field
      name={name}
      label={label}
      component={FormikSelect}
      options={hazmatOptions}
      getOptionValue={({
        hazmat_identification_number: hazmatIdentificationNumber,
        identification_number: identificationNumber
      }) => {
        hazmatIdentificationNumber || identificationNumber;
      }}
      getOptionLabel={renderHazmatOptionLabel}
      clearable
      onChange={handleHazmatCodeSelection}
      disabled={isLoading}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

HazmatCodesField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func
};

HazmatCodesField.defaultProps = {
  label: 'Hazmat Codes',
  onChange: () => {}
};

export default HazmatCodesField;
