import {connect} from 'react-redux';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {ExternalDocument} from '@shipwell/corrogo-sdk';
import get from 'lodash/get';
import {getDocumentTypeLabel} from './documentTypes';
import useToggle from 'App/utils/hooks/useToggle';
import {DocumentViewLayout, DocumentMetadata, DocumentDisplay, DocumentActions} from 'App/components/DocumentView';
import ShipmentDocumentForm from 'App/formComponents/forms/shipmentDocument';
import type {State} from 'App/reducers/types';

type PropTypes = {
  userId: string;
  document: ExternalDocument;
  onClose: () => void;
  onUpdateDocument: (values: {description: string; type: string}) => void;
  onDeleteDocument: (documentId: string) => void;
};

const DocumentView = ({userId, document, onClose, onUpdateDocument, onDeleteDocument}: PropTypes) => {
  const [isEditing, toggleIsEditing] = useToggle(false);

  const handleUpdateDocument = (values: {description: string; type: string}) => {
    onUpdateDocument(values);
    toggleIsEditing();
  };

  const handleDeleteDocument = () => {
    onDeleteDocument(document.id);
    onClose();
  };

  const documentType = getDocumentTypeLabel(document.type);
  const fileType = get(document, 'filename', '').slice(-4).toLowerCase() === '.pdf' ? 'pdf' : undefined;
  return (
    <>
      <DocumentViewLayout>
        <div>
          <DocumentMetadata document={document} documentType={documentType}>
            {isEditing ? (
              <ShipmentDocumentForm
                edit
                values={{...document, file: document.url}}
                onCancel={toggleIsEditing}
                onSubmit={handleUpdateDocument}
              />
            ) : (
              <DeprecatedButton
                variant="tertiary"
                onClick={toggleIsEditing}
                className="shipment__collaboration-documents-view__editButton"
              >
                Edit Details
              </DeprecatedButton>
            )}
          </DocumentMetadata>
        </div>
        <DocumentDisplay document={{...document, file: document.url}} />
      </DocumentViewLayout>
      <DocumentActions
        document={{...document, file: document.url}}
        deleteDisableTitle={document.created_by_user_id !== userId ? 'You can only delete your own documents' : ''}
        onDeleteDocument={handleDeleteDocument}
        fileType={fileType}
      />
    </>
  );
};

export default connect((state: State) => ({
  // eslint-disable-next-line
  userId: state.userProfile.user?.id || ''
}))(DocumentView);
