import {useEffect, useLayoutEffect, useRef, useState} from 'react';

export function useDebounce<T>(value: T, delay: number, onDebounce?: () => void): T {
  // https://epicreact.dev/the-latest-ref-pattern-in-react/
  const callbackRef = useRef(onDebounce);
  useLayoutEffect(() => {
    callbackRef.current = onDebounce;
  });
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(
    () => {
      callbackRef.current && callbackRef.current();
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
