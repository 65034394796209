import {useCallback, useMemo, useState} from 'react';
import {
  Availability,
  ScheduledResourceTypeEnum,
  LoadTypeDockRuleMatchResults,
  AvailabilityRequest,
  AppointmentStatusEnum
} from '@shipwell/tempus-sdk';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {isEqual} from 'lodash/fp';

import {
  AppointmentAvailabilityRestriction,
  AppointmentAvailabilityWindow,
  AppointmentEntry,
  TimezoneAwareDateTime
} from './types';
import {DYNAMIC_APPOINTMENT_AVAILABILITY} from 'App/data-hooks/queryKeys';
import {ensureDateString} from 'App/utils/dateTimeGlobalsTyped';
import {getAvailability} from 'App/api/facilities';
import {cmp} from 'App/utils/cmp';
import {formatParts, parseAvailabilityRestrictions, parseAvailabilityWindows} from 'App/data-hooks/appointments/utils';
import {arrayIncludes} from 'App/utils/betterTypedArrayMethods';

export type DynamicAvailability =
  | {
      isValid: true;
      isInitialLoading: boolean;
      isWorking: boolean;
      request: AppointmentEntry;
      availabilityWindowsByDock: AppointmentAvailabilityWindow[];
      availabilityWindowsUnion: AppointmentAvailabilityWindow[];
      loadTypeDockRuleMatchResults?: LoadTypeDockRuleMatchResults;
      availabilityRestrictions: AppointmentAvailabilityRestriction[];
      snapDuration: string;
      prefetch: (request: AppointmentEntry) => void;
      invalidate: () => void;
    }
  | {
      isValid: false;
      prefetch: (request: AppointmentEntry) => void;
      invalidate: () => void;
    };

function isShipmentResourceType(resourceType: unknown): boolean {
  return arrayIncludes([ScheduledResourceTypeEnum.Shipment, 'SHIPMENT'], resourceType);
}

function computeDynamicAvailabilityRequest(
  start: Date,
  end: Date,
  appointment: AppointmentEntry
): {key: unknown[]; request: AvailabilityRequest} {
  const {scheduledResourceType, scheduledResourceId, facilityId, stopId} = appointment;
  /* eslint-disable @typescript-eslint/naming-convention */
  const start_datetime = ensureDateString(start);
  const end_datetime = ensureDateString(end);
  /* eslint-enable @typescript-eslint/naming-convention */

  let key: unknown[];
  let request: AvailabilityRequest;
  if (isShipmentResourceType(scheduledResourceType)) {
    key = [DYNAMIC_APPOINTMENT_AVAILABILITY, facilityId, appointment.id, start_datetime, end_datetime];
    request = {
      request_criteria_type: 'SHIPMENT',
      start_datetime,
      end_datetime,
      rescheduling_for_appointment_id:
        appointment.status === AppointmentStatusEnum.Unscheduled ? undefined : appointment.id,
      shipment_id: scheduledResourceId ?? '',
      stop_id: stopId ?? ''
    };
  } else if (scheduledResourceType === ScheduledResourceTypeEnum.FreightGeneral) {
    // NOTE: this will not work for now. We need Aquib's freight appointment work merged in first.
    key = [DYNAMIC_APPOINTMENT_AVAILABILITY, facilityId, appointment.id, start_datetime, end_datetime];
    request = {
      request_criteria_type: 'FREIGHT_GENERAL',
      start_datetime,
      end_datetime,
      rescheduling_for_appointment_id: appointment.id
    };
  } else {
    key = [DYNAMIC_APPOINTMENT_AVAILABILITY, facilityId, appointment.id, start_datetime, end_datetime];
    request = {
      request_criteria_type: 'NO_CRITERIA',
      start_datetime,
      end_datetime,
      rescheduling_for_appointment_id: appointment.id
    };
  }
  return {key, request};
}

function keyFn(start: Date, end: Date, appointment?: AppointmentEntry): string[] {
  if (!appointment) {
    return [];
  }
  const {key} = computeDynamicAvailabilityRequest(start, end, appointment);
  return key as string[];
}

function fetchFn(start: Date, end: Date, entry?: AppointmentEntry): () => Promise<Availability> {
  if (!entry) {
    return function () {
      return Promise.resolve({
        available_windows: [],
        availability_restrictions: []
      } as Availability);
    };
  }
  return function () {
    const {request} = computeDynamicAvailabilityRequest(start, end, entry);
    return getAvailability(entry.facilityId, request);
  };
}

type InnerWindow = {
  startDate: TimezoneAwareDateTime;
  endDate: TimezoneAwareDateTime;
  dockId: string;
  loadTypeId?: string | undefined;
  isAllDay: boolean;
};

function unionOfAvailability(
  matchResults: LoadTypeDockRuleMatchResults | undefined,
  windowsIn: InnerWindow[]
): InnerWindow[] {
  const getRank = (w: InnerWindow) => {
    const k = matchResults?.matched_docks.findIndex((d) => d.dock_id === w.dockId) || -1;
    return k >= 0 ? k : Infinity;
  };
  const windows = windowsIn
    .map((w) => ({...w}))
    .sort((a, b) => {
      const c = cmp(a.startDate.original, b.startDate.original);
      if (c) return c;
      return getRank(a) - getRank(b);
    });
  const nWindowsIn = windows.length;
  for (let i = 1; i < nWindowsIn; i++) {
    if (+windows[i].startDate.original >= +windows[i - 1].endDate.original) {
      continue;
    }
    if (getRank(windows[i]) < getRank(windows[i - 1])) {
      windows[i - 1].endDate = windows[i].startDate;
    } else {
      windows[i].startDate = windows[i - 1].endDate;
    }
  }
  return windows.filter((w) => w.endDate.original > w.startDate.original);
}

/**
 * NOTE: You must memo-ize the date objects you pass in or your component will
 * rerender forever.
 */
export function useDynamicAvailability(start: Date, end: Date, request?: AppointmentEntry): DynamicAvailability {
  const client = useQueryClient();

  const prefetch = useCallback(
    (request: AppointmentEntry): void => {
      void client.prefetchQuery(keyFn(start, end, request), fetchFn(start, end, request), {
        staleTime: 10 * 1000,
        cacheTime: 3 * 60 * 1000
      });
    },
    [start, end, client]
  );

  const invalidate = useCallback(() => {
    void client.invalidateQueries({
      predicate: (query) => query.queryKey[0] === DYNAMIC_APPOINTMENT_AVAILABILITY
    });
  }, [client]);

  const [result, setResult] = useState<DynamicAvailability>({
    isValid: false,
    prefetch,
    invalidate
  });

  const key = useMemo(() => keyFn(start, end, request), [start, end, request]);
  const fetcher = useMemo(() => fetchFn(start, end, request), [start, end, request]);

  const query = useQuery(key, fetcher);

  let resultDraft = result;
  let dataChanged = false;
  const isValid = !!request;

  if (!isValid && resultDraft.isValid) {
    dataChanged = true;
    resultDraft = {
      isValid: false,
      prefetch,
      invalidate
    };
  }

  if (isValid && !resultDraft.isValid) {
    dataChanged = true;
    resultDraft = {
      ...resultDraft,
      isValid: true,
      isInitialLoading: true,
      isWorking: true,
      request,
      availabilityWindowsByDock: [],
      availabilityWindowsUnion: [],
      availabilityRestrictions: [],
      snapDuration: '00:30:00',
      prefetch,
      invalidate
    };
  }

  const {availabilityWindowsByDock, availabilityWindowsUnion, loadTypeDockRuleMatchResults, availabilityRestrictions} =
    useMemo(() => {
      const windows = query.data?.available_windows ?? [];
      const loadTypeDockRuleMatchResults = query.data?.load_type_dock_rule_match_results;
      const availabilityRestrictions = parseAvailabilityRestrictions(query.data?.availability_restrictions ?? []);
      const now = new Date();
      const availabilityWindowsByDock = parseAvailabilityWindows(windows).flatMap((availabilityWindow) => {
        if (availabilityWindow.endDate.original < now) {
          return [];
        }
        if (availabilityWindow.startDate.original < now) {
          return [
            {
              ...availabilityWindow,
              startDate: formatParts(now, availabilityWindow.startDate.timezone.name)
            }
          ];
        }
        return [availabilityWindow];
      });

      const availabilityWindowsUnion = unionOfAvailability(loadTypeDockRuleMatchResults, availabilityWindowsByDock);

      return {
        availabilityWindowsByDock,
        availabilityWindowsUnion,
        loadTypeDockRuleMatchResults,
        availabilityRestrictions
      };
    }, [
      query.data?.available_windows,
      query.data?.load_type_dock_rule_match_results,
      query.data?.availability_restrictions
    ]);

  const snapDuration = '00:15:00';

  if (resultDraft.isValid && !isEqual(resultDraft.availabilityWindowsByDock, availabilityWindowsByDock)) {
    resultDraft = {...resultDraft, availabilityWindowsByDock};
    dataChanged = true;
  }
  if (resultDraft.isValid && !isEqual(resultDraft.availabilityWindowsUnion, availabilityWindowsUnion)) {
    resultDraft = {...resultDraft, availabilityWindowsUnion};
    dataChanged = true;
  }
  if (resultDraft.isValid && !isEqual(resultDraft.loadTypeDockRuleMatchResults, loadTypeDockRuleMatchResults)) {
    resultDraft = {...resultDraft, loadTypeDockRuleMatchResults};
    dataChanged = true;
  }
  if (resultDraft.isValid && !isEqual(resultDraft.availabilityRestrictions, availabilityRestrictions)) {
    resultDraft = {...resultDraft, availabilityRestrictions};
    dataChanged = true;
  }
  if (resultDraft.prefetch !== prefetch) {
    resultDraft = {...resultDraft, prefetch};
    dataChanged = true;
  }
  if (resultDraft.invalidate !== invalidate) {
    resultDraft = {...resultDraft, invalidate};
    dataChanged = true;
  }

  if (resultDraft.isValid && resultDraft.snapDuration !== snapDuration) {
    resultDraft = {...resultDraft, snapDuration: snapDuration};
    dataChanged = true;
  }

  if (resultDraft.isValid && resultDraft.isInitialLoading && dataChanged) {
    resultDraft = {...resultDraft, isInitialLoading: false};
    dataChanged = true;
  }

  const isWorking = query.isFetching || query.isInitialLoading || query.isLoading;
  if (resultDraft.isValid && resultDraft.isWorking !== isWorking) {
    resultDraft = {...resultDraft, isWorking};
    dataChanged = true;
  }

  if (dataChanged) {
    setResult(resultDraft);
    return resultDraft;
  }

  return result;
}
