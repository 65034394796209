import React from 'react';
import {Pill} from '@shipwell/shipwell-ui';
import {CarrierToDisplay} from 'App/containers/Shipment/components/EmbeddedPricingIntel/DisplayCarriers';
import ShipwellLoader from 'App/common/shipwellLoader';
import {useCarriersWithDataMetrics, useGetCarrierRelationshipsById} from 'App/data-hooks';

const CarrierDetails = ({carrier}: {carrier: CarrierToDisplay}) => {
  const {data: carriersWithDataMetrics, isInitialLoading: isCarrierWithDataMetricsLoading} = useCarriersWithDataMetrics(
    {
      pageSize: 1,
      q: carrier.name
    }
  );
  const {carrierRelationships, carrierRelationshipStatus} = useGetCarrierRelationshipsById({vendorId: carrier.id});

  const carrierWithDataMetrics = carriersWithDataMetrics?.results?.[0];
  const usdotNumber =
    carrierRelationships?.identifying_codes?.find((identifyingCode) => identifyingCode?.type === 'USDOT')?.value ||
    '--';
  const mcNumber =
    carrierRelationships?.identifying_codes?.find((identifyingCode) => identifyingCode?.type === 'MC_NUMBER')?.value ||
    '--';

  if (isCarrierWithDataMetricsLoading || carrierRelationshipStatus === 'loading') {
    return <ShipwellLoader loading />;
  }

  return (
    <div>
      <div className="flex items-center">
        <div className="mr-1">{carrierRelationships?.name}</div>
        {carrierRelationships?.carrier_status ? (
          <Pill
            variant={['ACTIVE'].includes(carrierRelationships.carrier_status.toUpperCase()) ? 'primary' : 'inactive'}
            size="xs"
          >
            {carrierRelationships.carrier_status.toUpperCase()}
          </Pill>
        ) : null}
      </div>
      <div>{`USDOT: ${usdotNumber}`}</div>
      <div>{`MC: ${mcNumber}`}</div>
      <div>{carrierWithDataMetrics?.overall_on_time_delivery || '--'}% On Time Delivery</div>
      <div>{carrierWithDataMetrics?.overall_tender_acceptance || '--'}% Tender Acceptance</div>
      <div>{carrierWithDataMetrics?.overall_tracking_score || '--'}% Tracking Score</div>
    </div>
  );
};

export default CarrierDetails;
