import PropTypes from 'prop-types';
import {useState} from 'react';
import {Formik, Form, Field, useFormikContext} from 'formik';
import {Loader, Card, CollapsibleCardContent, FormikSelect, DisplayValue} from '@shipwell/shipwell-ui';
import {MileageType} from '@shipwell/backend-core-sdk';
import {object, string} from 'yup';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import moment from 'moment';
import {Link} from 'react-router';
import {updateCompanySetting} from 'App/containers/userCompany/actions/async';
import {getUserMe} from 'App/containers/userProfile/actions/async';
import FormFooter from 'App/formComponents/formSections/formFooter';
import PageHeader from 'App/common/pageHeader';
import WithStatusToasts, {WithStatusToastsDefaultProps} from 'App/components/withStatusToasts';
import {formatDateTime} from 'App/utils/globals';
import getNil from 'App/utils/getNil';
import {useBrokerMileageExceptions} from 'App/data-hooks';

const mileageProviderOptions = [
  {
    id: MileageType.PcMiler37,
    label: 'PC Miler, Version 37'
  },
  {
    id: MileageType.PcMiler36,
    label: 'PC Miler, Version 36'
  },
  {
    id: MileageType.PcMiler35,
    label: 'PC Miler, Version 35'
  },
  {
    id: MileageType.PcMiler34,
    label: 'PC Miler, Version 34'
  },
  {
    id: MileageType.PcMiler33,
    label: 'PC Miler, Version 33'
  },
  {
    id: MileageType.HereMaps,
    label: 'Here Maps'
  }
];

export const validationSchema = object().shape({
  mileage_type: string().nullable()
});

const CompanyCustomerSettingsBase = ({dispatch, company, setSuccess, setError}) => {
  const [shouldQueryMileageExceptions, setShouldQueryMileageExceptions] = useState(false);
  useBrokerMileageExceptions(company?.brokerage?.id, {
    enabled: shouldQueryMileageExceptions
  });
  const handleCustomerSettingsSubmit = async (values) => {
    const mileageType = values.mileage_type || '';
    try {
      await dispatch(updateCompanySetting(company.id, {...company, mileage_type: mileageType}));
      //refresh userCompany information
      await dispatch(getUserMe());
      setSuccess('Success!', 'Company Customer Settings Updated Successfully.');
      setShouldQueryMileageExceptions(true);
    } catch (error) {
      console.error(error);
      setError('Error!', error?.error_description);
      setShouldQueryMileageExceptions(false);
    }
  };

  const initialValuesWithCompany = (company) => {
    return {
      mileage_type: company.mileage_type || ''
    };
  };

  return (
    <div className="w-full">
      <Formik
        initialValues={initialValuesWithCompany(company)}
        enableReinitialize
        onSubmit={handleCustomerSettingsSubmit}
      >
        {({dirty, resetForm}) => (
          <Form>
            <PageHeader title="Customer Settings"></PageHeader>
            <div className="p-6">
              <Card title="Customer Mileage Service" isCollapsible>
                <CollapsibleCardContent expanded>
                  <CustomerMileageServiceFields mileageConfigLastUpdated={company.mileage_config_last_updated} />
                </CollapsibleCardContent>
              </Card>
              {dirty && <FormFooter primaryActionName="Save" secondaryActionName="Cancel" onCancel={resetForm} />}
            </div>
          </Form>
        )}
      </Formik>
      <Loader loading={false} />
    </div>
  );
};

CompanyCustomerSettingsBase.propTypes = {
  ...WithStatusToastsDefaultProps
};

export const CompanyCustomerSettings = compose(
  connect((state) => ({
    company: state.userCompany.company
  })),
  WithStatusToasts
)(CompanyCustomerSettingsBase);

export const CustomerMileageServiceFields = ({mileageConfigLastUpdated}) => {
  return (
    <>
      <div className="p-1">
        <div className="font-bold">All Customers</div>
        <div className="mt-2 grid grid-cols-2 pb-8 sw-border-b">
          <div>
            For all customers the mileage provider used to determine all of the total distance algorithms and subsequent
            pricing breakdowns for services related to distances, use the mapping/mileage provider:
          </div>
          <div className="pl-4">
            <MileageTypeField mileageConfigLastUpdated={mileageConfigLastUpdated} />
          </div>
        </div>
      </div>
      <div className="p-1">
        <div className="font-bold">Customer-Specific Exceptions</div>
        <div className="mt-2 grid grid-cols-2">
          <div>
            Customers that require specific mileage providers can be adjusted to use providers outside of the company
            configuration on their specific{' '}
            <Link to="/customers" target="_blank">
              customer profile
            </Link>{' '}
            page.
          </div>
          <CustomerMileageTypeExceptions />
        </div>
      </div>
    </>
  );
};

CustomerMileageServiceFields.propTypes = {
  mileageConfigLastUpdated: PropTypes.instanceOf(Date)
};

const MileageTypeFieldBase = ({user = {}, mileageConfigLastUpdated}) => {
  const {setFieldValue, values} = useFormikContext();

  return (
    <>
      <Field
        name="mileage_type"
        value={values.mileage_type}
        component={FormikSelect}
        options={mileageProviderOptions}
        label="Distance Service"
        clearable={false}
        simpleValue
        className="mb-0"
        onChange={(selection) => {
          // if user selection is not one of the PC Miler options, set "Add Maximum Driving Elevation" to false
          if (!/pc_miler/i.test(selection)) {
            setFieldValue('is_enabled_max_driving_elevation', false);
          }
          setFieldValue('mileage_type', selection);
        }}
      />
      <div className="ml-4 text-xs uppercase text-sw-form-helper-text">
        {`Last Updated: ${
          mileageConfigLastUpdated
            ? formatDateTime(mileageConfigLastUpdated, true, user.timezone || moment.tz.guess())
            : '--'
        }`}
      </div>
    </>
  );
};

MileageTypeFieldBase.propTypes = {
  user: PropTypes.shape({
    timezone: PropTypes.string
  }),
  mileageConfigLastUpdated: PropTypes.instanceOf(Date)
};

export const MileageTypeField = compose(
  connect((state) => ({
    user: state.userCompany.user
  }))
)(MileageTypeFieldBase);

const CustomerMileageTypeExceptionsBase = ({company}) => {
  const customerMileageExceptionsQuery = useBrokerMileageExceptions(company?.brokerage?.id, {
    enabled: Boolean(company?.brokerage?.id)
  });
  return (
    <div className="flex flex-col pl-4">
      {getNil(customerMileageExceptionsQuery, 'data.body', [])?.map(
        ({shipper_relationship_id: shipperRelationshipId, customer_name: customerName, mileage_type: mileageType}) => (
          <div key={shipperRelationshipId} className="mb-4">
            <div className="mb-1">
              <Link to={`/customers/${shipperRelationshipId}`} target="_blank">
                {customerName}
              </Link>
            </div>
            <DisplayValue label="Mileage Provider" className="mb-0" />
            <div>{mileageType}</div>
          </div>
        )
      )}
    </div>
  );
};

CustomerMileageTypeExceptionsBase.propTypes = {
  company: PropTypes.shape({
    brokerage: PropTypes.shape({
      id: PropTypes.string
    })
  })
};

export const CustomerMileageTypeExceptions = compose(
  connect((state) => ({
    company: state.userCompany.company
  }))
)(CustomerMileageTypeExceptionsBase);
