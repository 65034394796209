import {CATEGORIES, ACTIONS, LABELS} from './analyticsConstants.js';

//analytics tracking, submits to google
const trackGoogleAnalytics = (category, action, label, value) => {
  if (window.analytics) {
    window.analytics.track(action, {
      category: category,
      label: label,
      value: value
    });
  }
};

//LINE ITEMS
export const trackEditLineItems = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.EDIT, LABELS.LINE_ITEMS);
};
export const trackSubmitLineItems = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.LINE_ITEMS);
};
export const trackDeleteLineItems = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.DELETE, LABELS.LINE_ITEMS);
};

//VENDOR & EQUIPMENT
export const trackEditVendorEquipment = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.EDIT, LABELS.CARRIER);
};
export const trackSubmitVendorEquipment = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.CARRIER);
};
export const trackDeleteVendorEquipment = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.DELETE, LABELS.CARRIER);
};

//TIMELINE
export const trackEditTimeline = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.EDIT, LABELS.TIMELINE);
};
export const trackSubmitTimeline = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.TIMELINE);
};
export const trackDeleteTimeline = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.DELETE, LABELS.TIMELINE);
};

//REFERENCES
export const trackEditReferences = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.EDIT, LABELS.REFERENCES);
};
export const trackSubmitReferences = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.REFERENCES);
};

//STOPS
export const trackEditStops = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.EDIT, LABELS.STOPS);
};
export const trackSubmitStops = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.STOPS);
};

//STATUS
export const trackEditStatus = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.EDIT, LABELS.STATUS);
};
export const trackSubmitStatus = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.STATUS);
};

//TAGS
export const trackEditTags = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.EDIT, LABELS.TAGS);
};
export const trackSubmitTags = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.TAGS);
};

//NAME
export const trackEditName = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.EDIT, LABELS.NAME);
};
export const trackSubmitName = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.NAME);
};

//LOCATION
export const trackEditLocation = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.EDIT, LABELS.TRUCK_LOCATION);
};
export const trackSubmitLocation = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.TRUCK_LOCATION);
};

//FINANCIALS
export const trackEditCustomerFinancials = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.EDIT, LABELS.FINANCIALS, 'Customer');
};
export const trackSubmitCustomerFinancials = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.FINANCIALS, 'Customer');
};
export const trackEditVendorFinancials = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.EDIT, LABELS.FINANCIALS, 'Carrier');
};
export const trackSubmitVendorFinancials = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.FINANCIALS, 'Carrier');
};

//SHIPMENT DETAILS CLICK EVENTS
export const trackClickUploadDocument = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.CLICK, LABELS.UPLOAD_DOCUMENT);
};
export const trackSubmitUploadDocument = (docType) => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.UPLOAD_DOCUMENT, docType);
};
export const trackClickSendDocument = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.CLICK, LABELS.SEND_DOCUMENT);
};
export const trackSubmitNote = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.NOTES);
};
export const trackSubmitMessage = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.SUBMIT, LABELS.MESSAGES);
};
export const trackClickViewQuotes = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.CLICK, LABELS.VIEW_QUOTES);
};
export const trackClickClone = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.CLICK, LABELS.CLONE);
};
export const trackClickShare = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.CLICK, LABELS.SHARE);
};
export const trackClickCases = () => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.CLICK, LABELS.CASES);
};
export const trackClickGenerateDocument = (value) => {
  trackGoogleAnalytics(CATEGORIES.SHIPMENT_DETAILS, ACTIONS.CLICK, LABELS.GENERATE_DOCUMENT, value);
};

// Integrations
export const trackConnectIntegration = (value) => {
  trackGoogleAnalytics(CATEGORIES.INTEGRATIONS, ACTIONS.CLICK, LABELS.CONNECT_INTEGRATION, value);
};
export const trackDisconnectIntegration = (value) => {
  trackGoogleAnalytics(CATEGORIES.INTEGRATIONS, ACTIONS.CLICK, LABELS.DISCONNECT_INTEGRATION, value);
};
export const trackUpdateNetSuiteMapping = (value) => {
  trackGoogleAnalytics(CATEGORIES.INTEGRATIONS, ACTIONS.CLICK, LABELS.UPDATE_INTEGRATION, value);
};

// Pricing Intelligence
export const trackCopyReferencePrice = (value) => {
  trackGoogleAnalytics(CATEGORIES.PRICING_INTEL, ACTIONS.CLICK, LABELS.COPY_REFERENCE_PRICE, value);
};
export const trackCopyMyReferencePrice = (value) => {
  trackGoogleAnalytics(CATEGORIES.PRICING_INTEL, ACTIONS.CLICK, LABELS.COPY_MY_REFERENCE_PRICE, value);
};
export const trackCopyMarketReferencePrice = (value) => {
  trackGoogleAnalytics(CATEGORIES.PRICING_INTEL, ACTIONS.CLICK, LABELS.COPY_MARKET_REFERENCE_PRICE, value);
};
export const trackApplyMyPredictedPriceToBookNow = (value) => {
  trackGoogleAnalytics(CATEGORIES.PRICING_INTEL, ACTIONS.CLICK, LABELS.APPLY_MY_PREDICTED_TO_BOOK_NOW, value);
};
export const trackApplyMarketPredictedPriceToBookNow = (value) => {
  trackGoogleAnalytics(CATEGORIES.PRICING_INTEL, ACTIONS.CLICK, LABELS.APPLY_MARKET_PREDICTED_TO_BOOK_NOW, value);
};
