import {Field} from 'formik';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {RATE_TYPE_PER_MILE, RATE_TYPE_PERCENT_OF_LINE_HAUL} from 'App/containers/userCompany/rateTables/constants';

const RateTypeField = ({...props}) => (
  <Field
    label="Rate Type"
    simpleValue
    clearable={false}
    options={[
      {label: 'Percent of Line Haul', id: RATE_TYPE_PERCENT_OF_LINE_HAUL},
      {label: 'Per Mile', id: RATE_TYPE_PER_MILE}
    ]}
    name="rate_type"
    component={FormikSelect}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export default RateTypeField;
