/* eslint-disable import/no-namespace */
/* eslint-disable camelcase */
/* global */
import {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';
import classNames from 'classnames';
import {Link, browserHistory} from 'react-router';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import {Toast, Rule, DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import LoadBoardQuotesTable from './components/LoadBoardQuotesTable';
import EdiTenderData from './components/EDITenderData';
import PlaceBidModal from './components/PlaceBidModal';
import {acceptTender, rejectTender} from 'App/api/tenders';
import * as actions from 'App/actions/shipments';
import * as authActions from 'App/actions/auth';
import * as brokerActions from 'App/actions/brokers';
import * as marketplaceActions from 'App/actions/marketplace';
import {getTenderRequests} from 'App/actions/_tenders';
import TenderRejectForm from 'App/containers/LoadBoard/components/TenderRejectForm';
import Messages from 'App/components/Messages';
import DashboardSummary from 'App/components/dashboardSummary';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {unpackErrors, formatCurrencyValue, formatDateTime} from 'App/utils/globals';
import {formatCurrency} from 'App/utils/internationalConstants';
import BookNowConfirmation from 'App/containers/LoadBoard/components/BookNowConfirmation';
import {leftArrow} from 'App/common/Assets';
import './_loadBoard.scss';
import {handleTenderStatus, tenderStatuses} from 'App/containers/tendering/create/utils/constants';
import {withPageTitle} from 'App/utils/hooks/usePageTitle';
import {lineItemChargeCategories} from 'App/utils/loadBoardConstants';
import WithStatusToasts from 'App/components/withStatusToasts';
import {createSpotNegotiationFromLoadboard} from 'App/api/quoting/typed';

@connect(
  (state) => ({
    spotNegotiationMessages: state.marketplace.spotNegotiationMessages,
    spotNegotiationQuotes: state.marketplace.spotNegotiationQuotes,
    loadBoardShipmentDetails: state.marketplace.loadBoardShipmentDetails,
    selectedSpotNegotiation: state.marketplace.selectedSpotNegotiation,
    selectedShipment: state.shipments.selectedShipment,
    brokerLogos: state.brokers.brokerLogos,
    createCarrierRate: state.form.createCarrierRate,
    alertError: state.shipments.alertError,
    authenticated: state.userProfile.authenticated,
    user: state.userProfile.user,
    company: state.userCompany.company,
    featureFlags: state.auth.company && state.auth.company.feature_flags
  }),
  {...actions, ...brokerActions, ...authActions, ...marketplaceActions, getTenderRequests}
)
export class LoadBoardDetail extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.handleDeleteQuote = this.handleDeleteQuote.bind(this);
    this.initialize = this.initialize.bind(this);
    this.state = {
      mode: 'vendor',
      showNewCarrierRateForm: this.props.location?.query.bid,
      markerArray: [],
      hasShipwellAccount: false,
      intervalId: null,
      userEmail: null,
      showAwardedModal: false,
      showRedirectModal: false,
      tabsValue: 'route',
      hasSetDefaultOpen: false,
      showError: false,
      showBookNowToast: false,
      showTenderAcceptError: false,
      tenderAcceptErrorMessage: '',
      submittingBookNow: false,
      performingTenderAction: false,
      isDeletingQuote: false
    };
  }

  componentDidMount() {
    //need to get spot negotiation details, quotes, messages, etc
    if (this.props.authenticated) {
      this.initialize();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.authenticated && this.props.authenticated) ||
      prevProps.params.load_board_id !== this.props.params.load_board_id
    ) {
      //reinitialize
      this.initialize();
    }
  }

  initialize() {
    const load_board_id = this.props.params.load_board_id;
    this.setState({loading: true}, async () => {
      const response = await this.props.getLoadBoardShipmentDetails(load_board_id);
      if (response) {
        if (response.status === 200) {
          if (response.details?.spot_negotiation) {
            //there is a spot negotiation we can get details on
            await Promise.all([
              this.props.getSpotNegotiationQuotes(response.details.spot_negotiation),
              this.props.getSpotNegotiationDetails(response.details.spot_negotiation)
            ]);
          }

          this.setState({loading: false});

          //update page title with shipment ref
          const {router, constructPageTitle} = this.props;
          constructPageTitle({pathname: '/load/'}, router.params, response.details?.load_board_id);
        } else {
          this.setState({loading: false, showError: true});
        }
      }
      this.props.fetchEquipmentTypes();
      this.props.fetchShipmentModes();
    });
  }

  componentWillUnmount() {
    //clear out selected
    this.props.selectSpotNegotiation(null);
  }

  async handleDeleteQuote(quote) {
    this.setState({isDeletingQuote: true});
    const response = await this.props.deleteSpotNegotiationQuote(this.props.selectedSpotNegotiation.id, quote.id);
    if (response) {
      if (response.status === 200) {
        //refetch quotes after deleting
        await this.props.getSpotNegotiationQuotes(this.props.selectedSpotNegotiation.id);
      }
    }
    //refetch details
    this.fetchShipmentDetails({fetchQuotes: false});
    this.setState({isDeletingQuote: false});
  }

  handlePlaceBidSuccess = async (spotNegotiationId) => {
    await this.props.getSpotNegotiationQuotes(spotNegotiationId);
    this.setState({editingQuote: null});
    this.props.clearSelectedCarrierRate();
    this.fetchShipmentDetails({fetchQuotes: false});
  };

  fetchShipmentDetails = async ({fetchQuotes = true} = {}) => {
    const load_board_id = this.props.params.load_board_id;
    const response = await this.props.getLoadBoardShipmentDetails(load_board_id);
    if (response) {
      if (response.status === 200 && response.details?.spot_negotiation) {
        //there is a spot negotiation we can get details on
        fetchQuotes && (await this.props.getSpotNegotiationQuotes(response.details.spot_negotiation));
        this.props.getSpotNegotiationDetails(response.details.spot_negotiation);
      }
    }
  };

  handleAddQuote() {
    this.setState({editingQuote: null}, () => {
      this.setState({showNewCarrierRateForm: true});
    });
  }

  showBuyItNow(shipment, e) {
    e.stopPropagation();
    this.setState({showBuyItNowModal: true});
  }

  async acceptBookNowPrice(obj) {
    const {loadBoardShipmentDetails} = this.props;
    const hasSpotNegotiation = loadBoardShipmentDetails.spot_negotiation;
    this.setState({submittingBookNow: true});
    if (hasSpotNegotiation) {
      return this.handleBookNow(obj);
    }
    //need to create a spot negotiation for this shipment first
    const body = {
      involved_vendor_users: [{id: this.props.user.id}],
      involved_customer_users: []
    };

    const response = await createSpotNegotiationFromLoadboard(loadBoardShipmentDetails.load_board_id, body);
    if (response) {
      if (response.statusCode === 201) {
        //proceed to create the quote with the SN id
        return this.handleBookNow(obj);
      }
      const errors = response.details.field_errors || [];
      let submissionError = {};
      submissionError = unpackErrors(errors, submissionError);
      submissionError._error = response.details.error_description;
      //handle edge cases for errors here
      if (submissionError.charge_line_items) {
        //put that on the total field
        if (submissionError.charge_line_items.unit_amount) {
          submissionError.total = submissionError.charge_line_items.unit_amount[0];
        }
      }
      this.setState({submittingBookNow: false});
      throw new SubmissionError(submissionError);
    }
  }

  async handleBookNow(obj) {
    const response = await this.props.bookNow(this.props.selectedSpotNegotiation.id, obj);
    if (response) {
      if (response.status === 200) {
        this.setState({showBuyItNowModal: false, showBookNowToast: true});
        this.fetchShipmentDetails();
      }
      this.setState({submittingBookNow: false});
    }
  }

  handleCloseBookNowToast() {
    this.setState({showBookNowToast: false, justBookedShipment: null});
  }

  redirectToLoadboard() {
    if (document.referrer === '') {
      //user linked directly to this page, go back to load board list
      this.context.router.push('/load-board/');
    } else {
      browserHistory.goBack();
    }
  }

  renderBookNowToast() {
    const {loadBoardShipmentDetails} = this.props;
    return (
      <div className="shipmentDetails__loadboardToast">
        <div className="shipmentDetails__loadboardToast-header">
          <i className="flaticon-multiply" onClick={this.handleCloseBookNowToast.bind(this)} />
        </div>
        <div className="shipmentDetails__loadboardToast-body">
          <span>
            <i className="flaticon-check_filled text-success" />
          </span>
          <span className="shipmentDetails__loadboardToast-content">
            <p className="text-success">
              <b>Shipment Booked!</b>
            </p>
            {loadBoardShipmentDetails ? (
              <>
                <p>{`Shipment ${loadBoardShipmentDetails.load_board_id} is now on your dashboard.`}</p>
                <Link to={`/shipments/${loadBoardShipmentDetails.id}`}>View Shipment Details</Link>
              </>
            ) : null}
          </span>
        </div>
      </div>
    );
  }

  renderRejectedToast() {
    const {justRejectedShipment} = this.state;
    return (
      <div className="shipmentDetails__loadboardToast-rejected">
        <div className="shipmentDetails__loadboardToast-header">
          <i className="flaticon-multiply" onClick={this.handleCloseRejectedToast.bind(this)} />
        </div>
        <div className="shipmentDetails__loadboardToast-body">
          <span>
            <i className="icon icon-Delayed text-danger" />
          </span>
          <span className="shipmentDetails__loadboardToast-content">
            <p className="text-danger">
              <b>You rejected tendered load {justRejectedShipment?.load_board_id}</b>
            </p>
            <p>Rejected and expired loads are hidden from your Load Board, but are accessible through filters.</p>
          </span>
        </div>
      </div>
    );
  }

  async acceptTender(e, tender) {
    this.setState({performingTenderAction: true});

    e.stopPropagation();
    const tenderId = tender.id;

    try {
      const response = await acceptTender(tenderId);
      if (response) {
        this.setState({showBuyItNowModal: false, showBookNowToast: true, performingTenderAction: false});
        this.initialize();
      }
    } catch (error) {
      console.error(error);
      this.setState({
        showTenderAcceptError: true,
        tenderAcceptErrorMessage: error.error_description || '',
        performingTenderAction: false
      });
    }
  }

  async rejectTender(tender, rejectReason, customReason) {
    this.setState({performingTenderAction: true});
    const tenderId = tender.id;
    const opts = {
      body: {
        rejection_code: rejectReason
      }
    };

    if (customReason) {
      opts.body.rejection_reason = customReason;
    }

    try {
      const response = await rejectTender(tenderId, opts);
      if (response) {
        //close the modal
        document.body.click();
        this.setState({
          showRejectedToast: true,
          justRejectedShipment: this.props.loadBoardShipmentDetails,
          performingTenderAction: false
        });
        //refresh shipment info
        this.initialize();
      }
    } catch (error) {
      console.error(error);
      this.setState({performingTenderAction: false});
    }
  }

  handleCloseRejectedToast() {
    this.setState({showRejectedToast: false, justRejectedShipment: null});
  }

  getTenderDisplayData(tender) {
    const fuelSurcharge = tender.charge_line_items.find(
      (chargeLineItem) => chargeLineItem.category === lineItemChargeCategories.fsc
    );
    const lineHaul = tender.charge_line_items.find(
      (chargeLineItem) => chargeLineItem.category === lineItemChargeCategories.lh
    );
    const getLineItemTotal = (item) => item?.unit_amount * item?.unit_quantity;
    const total = getLineItemTotal(lineHaul) + (fuelSurcharge ? getLineItemTotal(fuelSurcharge) : 0);
    return {
      status: handleTenderStatus(tender),
      tendered: formatDateTime(tender.created_at),
      expires: tender.expires_at && moment(tender.expires_at).isValid() ? formatDateTime(tender.expires_at) : '--',
      mode: tender?.mode?.code || '--',
      equipment: tender?.equipment_type?.name || '--',
      rate: lineHaul ? `$${formatCurrencyValue(getLineItemTotal(lineHaul))}` : '--',
      fsc: fuelSurcharge ? `$${formatCurrencyValue(getLineItemTotal(fuelSurcharge))}` : '--',
      total: `$${formatCurrencyValue(total)}` ?? '--'
    };
  }

  renderMobileView(tenders) {
    return (
      <div className="flex flex-col md:hidden ">
        {tenders.length &&
          tenders
            .filter((tender) => {
              return tender.status !== tenderStatuses.EXPIRED;
            })
            .map((tender) => {
              const tenderData = this.getTenderDisplayData(tender);
              return (
                <div key={tender.id} className="py-2 sw-border-b">
                  {Object.keys(tenderData).map((data, i) => {
                    return (
                      <div key={i} className="flex w-full py-1">
                        <span className="w-2/4 font-bold capitalize">{data}</span>
                        <span className="w-2/4 grow">{tenderData[data]}</span>
                      </div>
                    );
                  })}
                  <div className="my-2 flex justify-end">{this.renderAcceptRejectButtons(tender)}</div>
                </div>
              );
            })}
      </div>
    );
  }

  renderDesktopView(tenders) {
    return (
      <table className="tw-hidden md:table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Tendered</th>
            <th>Expires</th>
            <th>Mode</th>
            <th>Equipment</th>
            <th>Rate</th>
            <th>FSC</th>
            <th>Total</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {tenders.length &&
            tenders
              .filter((tender) => {
                return tender.status !== tenderStatuses.EXPIRED;
              })
              .map((tender) => {
                return (
                  <tr key={tender.id}>
                    <td className="loadBoard__tendered">{this.getTenderDisplayData(tender)?.status}</td>
                    <td>{this.getTenderDisplayData(tender)?.tendered}</td>
                    <td>{this.getTenderDisplayData(tender)?.expires}</td>
                    <td>{this.getTenderDisplayData(tender)?.mode}</td>
                    <td>{this.getTenderDisplayData(tender)?.equipment}</td>
                    <td>{this.getTenderDisplayData(tender)?.rate}</td>
                    <td>{this.getTenderDisplayData(tender)?.fsc}</td>
                    <td>{this.getTenderDisplayData(tender)?.total}</td>
                    <td>
                      <div className="flex items-center">{this.renderAcceptRejectButtons(tender)}</div>
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    );
  }

  renderAcceptRejectButtons(tender) {
    const {performingTenderAction} = this.state;
    return (
      <>
        {![tenderStatuses.REJECTED, tenderStatuses.REVOKED, tenderStatuses.EXPIRED].includes(tender.status) ? (
          <OverlayTrigger
            placement="bottom"
            trigger="click"
            rootClose
            overlay={
              <Popover id="loadBoard__reject" title="Reject Tender">
                <TenderRejectForm
                  rejectingShipment={tender}
                  performingTenderAction={performingTenderAction}
                  rejectTender={this.rejectTender.bind(this)}
                  handleCancel={() => document.body.click()}
                />
              </Popover>
            }
          >
            <DeprecatedButton className="mx-2" variant="secondary" size="small">
              Reject
            </DeprecatedButton>
          </OverlayTrigger>
        ) : null}
        {![tenderStatuses.REJECTED, tenderStatuses.REVOKED, tenderStatuses.EXPIRED, tenderStatuses.ACCEPTED].includes(
          tender.status
        ) ? (
          <DeprecatedButton
            onClick={async (e) => await this.acceptTender(e, tender)}
            variant="primary"
            className="mx-2"
            size="small"
            disabled={tender.carrier_status && tender.carrier_status !== 'ACTIVE'}
            loading={performingTenderAction}
          >
            Accept
          </DeprecatedButton>
        ) : null}
      </>
    );
  }

  render() {
    const {loadBoardShipmentDetails = {}, setError} = this.props;

    const {buy_it_now_amount} = loadBoardShipmentDetails;
    const {showBuyItNowModal, submittingBookNow, isDeletingQuote} = this.state;
    let quotes = [];
    if (this.props.spotNegotiationQuotes?.results?.length > 0) {
      quotes = JSON.parse(JSON.stringify(this.props.spotNegotiationQuotes.results));
      quotes.sort((a, b) => {
        return a.total < b.total ? -1 : 1;
      });
    }

    let isClosedByCustomer = false;
    const {selectedSpotNegotiation} = this.props;
    if (
      selectedSpotNegotiation?.closed_by_customer_at &&
      selectedSpotNegotiation.closed_by_customer_at !== null &&
      moment(selectedSpotNegotiation.closed_by_customer_at).isValid()
    ) {
      isClosedByCustomer = true;
    }

    const tenders = loadBoardShipmentDetails.tenders || [];
    const isTendered = tenders?.length > 0;
    const isBookNowDisabled = ['INACTIVE', 'DO_NOT_USE'].includes(
      loadBoardShipmentDetails.spot_negotiation_carrier_status
    );

    return (
      <div className="loadBoard loadBoard__detail content-wrapper">
        <section className={classNames('content', {'content-loading': this.state.loading})}>
          {this.state.loading ? (
            <ShipwellLoader loading={this.state.loading} loadingText={'Loading...'} />
          ) : this.state.showError ? (
            <div className="text-center">
              <h2>Sorry, There was an error retrieving the details of this shipment. </h2>
              <h2>Check your URL and contact Shipwell for assistance if the problem persists.</h2>
            </div>
          ) : (
            <div className="loadBoard__detail-content">
              <Grid container spacing={2}>
                <Grid className="loadBoard__detail-topRow" item xs={12}>
                  <h3>
                    <span className="loadBoard__detail-backArrow" onClick={this.redirectToLoadboard.bind(this)}>
                      {leftArrow()}
                    </span>{' '}
                    Load # {loadBoardShipmentDetails.load_board_id}
                  </h3>
                  {!isTendered && !isClosedByCustomer && buy_it_now_amount > 0 ? (
                    <div className="loadBoard__acceptNowContainer">
                      <span>
                        Book Now Price:{' '}
                        <span className="text-success">
                          {formatCurrency(buy_it_now_amount, loadBoardShipmentDetails.preferred_currency)}
                        </span>
                      </span>
                      <div
                        onClick={(e) => this.showBuyItNow(loadBoardShipmentDetails, e)}
                        className={classNames('loadBoard__acceptNow', {disabled: isBookNowDisabled})}
                      >
                        Book Now
                      </div>
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Paper className={'loadBoard__detail-component'}>
                        {isTendered ? (
                          <div className="loadBoard__detail-header paper__header">
                            <h4 className="marketplace__title">Direct Tender</h4>
                          </div>
                        ) : (
                          <div className="loadBoard__detail-header paper__header items-center">
                            <h4 className="marketplace__title">My Bids</h4>
                            {!isClosedByCustomer &&
                            !this.state.showNewCarrierRateForm &&
                            loadBoardShipmentDetails.spot_negotiation_carrier_status !== 'DO_NOT_USE' ? (
                              <DeprecatedButton id="newBid" onClick={() => this.handleAddQuote()} variant="tertiary">
                                <SvgIcon name="AddCircleOutlined" color="$sw-primary" />
                                <span>Place a Bid</span>
                              </DeprecatedButton>
                            ) : null}
                          </div>
                        )}
                        {!isClosedByCustomer ? (
                          <PlaceBidModal
                            loadboardShipment={loadBoardShipmentDetails}
                            handleClose={() => {
                              this.setState({showNewCarrierRateForm: false});
                            }}
                            showPlaceBidModal={this.state.showNewCarrierRateForm}
                            handlePlaceBidSuccess={this.handlePlaceBidSuccess}
                            setError={setError}
                          />
                        ) : null}
                        {quotes.length > 0 && !isTendered ? (
                          <LoadBoardQuotesTable
                            shipment={loadBoardShipmentDetails}
                            spotNegotiation={selectedSpotNegotiation}
                            handleDelete={this.handleDeleteQuote.bind(this)}
                            quotes={quotes}
                            isDeletingQuote={isDeletingQuote}
                          />
                        ) : isTendered ? (
                          <div className="loadBoard__tenderedDetails overflow-scroll">
                            {this.renderMobileView(tenders)}
                            {this.renderDesktopView(tenders)}
                            {tenders.some((t) => t.external_edi_system_shipment_id) ? (
                              <>
                                <Rule />
                                <EdiTenderData />
                              </>
                            ) : null}
                          </div>
                        ) : (
                          <div className="loadBoard__declinedToQuote">
                            <h3 className="text-sw-disabled-text">No Bids Submitted</h3>
                            <div className="text-sw-disabled-text">
                              Place a new bid on this load by selecting ‘Place a Bid’
                            </div>
                          </div>
                        )}
                      </Paper>
                    </Grid>

                    <Grid item xs={12}>
                      <Paper className={'loadBoard__detail-component'}>
                        <div className="loadBoard__detail-header paper__header">
                          <h4 className="marketplace__title">Messages</h4>
                        </div>
                        <Messages
                          shipmentResource={loadBoardShipmentDetails}
                          onSubmit={this.fetchShipmentDetails}
                          resourceType="SPOT_NEGOTIATION"
                          shouldPoll
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper>
                    {this.props.loadBoardShipmentDetails ? (
                      <DashboardSummary
                        shipment={loadBoardShipmentDetails}
                        customer={loadBoardShipmentDetails.customer}
                        company={this.props.company}
                        canViewCarrier={false}
                        isLoadBoard
                      />
                    ) : null}
                  </Paper>
                </Grid>
              </Grid>
            </div>
          )}
          {showBuyItNowModal ? (
            <BookNowConfirmation
              open
              shipment={loadBoardShipmentDetails}
              accept={this.acceptBookNowPrice.bind(this)}
              cancel={() => this.setState({showBuyItNowModal: false})}
              submittingBookNow={submittingBookNow}
            />
          ) : null}
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={this.state.showBookNowToast}
            autoHideDuration={null}
            onClose={this.handleCloseBookNowToast.bind(this)}
          >
            {this.renderBookNowToast()}
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={this.state.showRejectedToast}
            autoHideDuration={null}
            onClose={this.handleCloseRejectedToast.bind(this)}
          >
            {this.renderRejectedToast()}
          </Snackbar>
          <Toast
            show={this.state.showTenderAcceptError}
            title="Error"
            variant="error"
            anchor="top-right"
            onClose={() => this.setState({showTenderAcceptError: false, tenderAcceptErrorMessage: ''})}
          >
            {this.state.tenderAcceptErrorMessage}
          </Toast>
        </section>
      </div>
    );
  }
}

const LoadBoardDetailWithPageTitle = withPageTitle(LoadBoardDetail);

export default WithStatusToasts(LoadBoardDetailWithPageTitle);
