import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

const defaultLogo = '/images/svgs/shipwell-logo.svg';
const defaultTitle = 'Shipwell';

/**
 * Branded Container
 * @param {*} props
 */
const withCompanyBrand = (Component) => {
  const BrandedContainer = (props) => {
    const logo = localStorage.getItem('whiteLabelLogo') || defaultLogo;
    const companyName = localStorage.getItem('whiteLabelTitle') || defaultTitle;

    return (
      <div className="flex min-h-screen flex-col items-center justify-between bg-sw-background p-5">
        <div className="flex w-full flex-col items-center">
          <div
            className={classNames('w-full flex justify-center logo-wrapper', {
              'bg-sw-background-component isWhiteLabel': companyName !== defaultTitle
            })}
          >
            <img alt="Shipwell logo" className="m-6 max-h-24 max-w-sm" src={`${logo}?cachebust=${Date.now()}`} />
          </div>
          {/* eslint-disable-line react/jsx-props-no-spreading */} <Component {...props} />
        </div>
        <small>
          © <strong>2016-{new Date().getFullYear()} Shipwell&reg;</strong>, All Rights Reserved.
        </small>
      </div>
    );
  };

  BrandedContainer.propTypes = {
    children: PropTypes.node.isRequired
  };

  return BrandedContainer;
};

export default withCompanyBrand;
