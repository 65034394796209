import {FormEventHandler, useState} from 'react';

import {Formik, Field} from 'formik';
import {FormikTextInput, FormikSelect, DeprecatedButton} from '@shipwell/shipwell-ui';
import {object, string} from 'yup';
import {round} from 'lodash';
import {numberWithCommas, validateDollarValue} from 'App/utils/globals';
import {useGetInternationalPreferences} from 'App/containers/internationalization/hooks/useGetInternationalPreferences';
import ShipwellLoader from 'App/common/shipwellLoader';

interface FormValues {
  line_haul?: string;
  fuel_surcharge?: string;
  accessorials?: string;
  pricing_source?: {
    value: string | number;
  };
}

export interface FormikSubmitEventData {
  values: FormValues;
}

export interface FormikFormSubmitHandler {
  (formData: FormikSubmitEventData): Promise<void> | void;
}

export interface PricingIntelCalculatorFormPropTypes {
  rates: {rate: number; label: string; currency?: string}[];
  initialRate?: string | null;
  averageFuelSurchargePerTripUsd?: number;
}

const validationSchema = object().shape({
  line_haul: string()
    .nullable()
    .test({
      message: 'Enter a dollar value',
      test: (value) => validateDollarValue(value)
    }),
  fuel_surcharge: string()
    .nullable()
    .test({
      message: 'Enter a dollar value',
      test: (value) => validateDollarValue(value)
    }),
  accessorials: string()
    .nullable()
    .test({
      message: 'Enter a dollar value',
      test: (value) => validateDollarValue(value)
    })
});

const PricingIntelCalculatorForm = (props: PricingIntelCalculatorFormPropTypes): JSX.Element => {
  const {rates, initialRate, averageFuelSurchargePerTripUsd} = props;
  const initialCurrency = rates.find((rate) => rate.label.includes(initialRate || ''))?.currency || '';
  const [selectedCurrency, selSelectedCurrency] = useState<string | undefined>(initialCurrency);
  const {data: internationalPreferences, isInitialLoading: isLoadingInternationalPreferences} =
    useGetInternationalPreferences();

  const defaultCurrency = internationalPreferences?.currency;
  const options = rates.map((rate) => ({
    value: round(rate.rate),
    currency: rate?.currency,
    label: rate.label
  }));

  const initialValues = {
    pricing_source: options.find((option) => option.label.includes(initialRate || '')),
    line_haul: String(options.find((option) => option.label.includes(initialRate || ''))?.value) || '',
    fuel_surcharge: String(round(averageFuelSurchargePerTripUsd || 0)) || ''
  };

  const [calculatedTotal, setCalculatedTotal] = useState<number | null>(
    +initialValues.line_haul + +initialValues.fuel_surcharge
  );

  const handleSubmit = (values: FormValues) => {
    setCalculatedTotal(
      ((values.line_haul && +values.line_haul) || 0) +
        ((values.fuel_surcharge && +values.fuel_surcharge) || 0) +
        ((values.accessorials && +values.accessorials) || 0)
    );
  };

  return (
    <>
      {isLoadingInternationalPreferences ? (
        <ShipwellLoader loading />
      ) : (
        <>
          <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {(innerProps: {
              handleSubmit: FormEventHandler<HTMLFormElement>;
              setFieldValue: (field: string, value: string | number | {label: string; value: string}) => void;
            }) => {
              const onChange = (val: {label: string; value: string; currency?: string}) => {
                innerProps.setFieldValue('pricing_source', val);
                innerProps.setFieldValue('line_haul', val.value);
                if (val?.currency) {
                  selSelectedCurrency(val.currency);
                }
              };
              return (
                <form noValidate onSubmit={innerProps.handleSubmit} className="flex flex-col space-y-2 ">
                  <Field
                    name="pricing_source"
                    label="Pricing Source"
                    options={options}
                    onChange={onChange}
                    component={FormikSelect}
                  />
                  <Field
                    name="line_haul"
                    label="Line Haul"
                    prepend={selectedCurrency || defaultCurrency}
                    component={FormikTextInput}
                  />
                  <Field
                    name="fuel_surcharge"
                    label="Fuel Surcharge"
                    prepend={selectedCurrency || defaultCurrency}
                    component={FormikTextInput}
                  />
                  <Field
                    name="accessorials"
                    label="Accessorials"
                    prepend={selectedCurrency || defaultCurrency}
                    component={FormikTextInput}
                  />
                  <div className="inline-flex w-full items-center justify-between space-x-2">
                    <hr className="h-0.5 w-64 rounded border-0 bg-sw-background" />
                    <DeprecatedButton type="submit" size="small">
                      Calculate
                    </DeprecatedButton>
                  </div>
                </form>
              );
            }}
          </Formik>
          <div className="rounded bg-sw-background p-4">
            <span className="text-bold">All-in Rate:</span> {selectedCurrency || defaultCurrency}{' '}
            {calculatedTotal ? numberWithCommas(calculatedTotal) : '--'}
          </div>
        </>
      )}
    </>
  );
};

export default PricingIntelCalculatorForm;
