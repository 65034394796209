import {Link, withRouter} from 'react-router';
import moment from 'moment';
import classnames from 'classnames';
import pluralize from 'pluralize';
import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import capitalize from 'lodash/capitalize';
import WorkflowExecutionCell from 'App/containers/Dashboard/components/Columns/WorkflowExecutionCell';
import ContainerNumberCell from 'App/containers/Dashboard/components/Columns/ContainerNumberCell';

import {StopsItineraryOverlay, StopsItineraryCell} from 'App/components/stopEta';
import store from 'App/routes/store';
import {numberWithCommas, formatTimeAgo, formatTime, formatMileage} from 'App/utils/globals';
import {checkShipmentModes, formatShipmentModeCode, calculateShipmentTotals} from 'App/utils/globalsTyped';
import {
  fedexServiceOptions,
  upsServiceOptions,
  uspsServiceOptions,
  parcelPickupStatuses
} from 'App/utils/parcelConstants';
import {getFedExRegistrationAccounts, getUPSRegistrationAccounts} from 'App/api/registration';
import {renderStopDate, renderStopCompany} from 'App/utils/shipmentTableHelpers';
import {
  renderFinancialsTable,
  renderCustomerFinancialsTable,
  renderProviderFinancialsTable,
  renderNetFinancialsTable
} from 'App/utils/shipmentTableHelpers/typed';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import {METRIC, displayTotalWeight, convertMilesToKilometers, IMPERIAL} from 'App/utils/internationalConstants';
import {getReadableCreatedByString} from 'App/utils/createdBy';
import ActionLinkWithModal from 'App/components/actionLinkWithModal';
import SendShipmentDocuments from 'App/formComponents/forms/sendShipmentDocuments';
import StopFlagsTooltip from 'App/containers/Shipment/components/StopFlagsTooltip';
import ShipmentNotes from 'App/components/shipmentNotes';
import Messages from 'App/components/Messages';
import {SEND_DOCUMENTS, EMAIL_CONTACTS} from 'App/formComponents/forms/sendShipmentDocuments/constants';
import buildPathParams from 'App/utils/buildPathParams';
import WatchShipmentToggle from 'App/containers/alertsDashboard/components/watchShipmentToggle';
import {V3_VERSION} from 'App/containers/Dashboard/utils/constants';
import ServiceIdCell from 'App/containers/Dashboard/components/Columns/ServiceIdCell';
import {LineItemsTooltip} from 'App/containers/Dashboard/components/Columns/LineItemsTooltip';
import {AuctionStatsCell} from 'App/containers/Dashboard/components/Columns/AuctionStatsCell';
import {AccessorialsCell, CurrencyCell, UpcomingEtasCell} from 'App/containers/Dashboard/cells';
import {getIsLTL} from 'App/containers/Dashboard/columns';

let parcelAccounts = null;
const fedExServiceLevels = fedexServiceOptions.reduce((options, option) => {
  const {id, name} = option;
  return {...options, [id]: name};
}, {});
const upsServiceLevels = upsServiceOptions.reduce((options, option) => {
  const {id, name} = option;
  return {...options, [id]: name};
}, {});
const uspsServiceLevels = uspsServiceOptions.reduce((options, option) => {
  const {id, name} = option;
  return {...options, [id]: name};
}, {});

const parcelStatuses = parcelPickupStatuses.reduce((statuses, status) => {
  const {parcel_pickup_status, label, className} = status;
  return {...statuses, [parcel_pickup_status]: {label, className}};
}, {});

const formatStopAddress = (stopLocation) => {
  if (stopLocation?.address?.city && stopLocation?.address?.state_province) {
    return `${stopLocation?.address?.city}, ${stopLocation?.address?.state_province}`;
  }
  if (stopLocation?.address?.latitude && stopLocation?.address?.longitude) {
    return `${stopLocation?.address?.latitude.toFixed(2)}, ${stopLocation?.address?.longitude.toFixed(2)}`;
  }
  return null;
};

const PurchaseOrderNumberTooltipContent = ({poNumber}) => {
  const purchaseOrderNumberList = poNumber?.split(',');

  return purchaseOrderNumberList?.length > 1 ? (
    <div className="flex flex-row">
      {purchaseOrderNumberList[0]}
      <div className="pl-0.5 text-sw-primary">
        <Tooltip
          portal
          placement={'right'}
          tooltipContent={
            <>
              <div className="pb-2 font-bold">PO #</div>
              {purchaseOrderNumberList.slice(1).map((value, index) => {
                return <div key={index}>{value}</div>;
              })}
            </>
          }
        >
          +{purchaseOrderNumberList.length - 1}
        </Tooltip>
      </div>
    </div>
  ) : poNumber ? (
    poNumber
  ) : (
    '--'
  );
};

const columns = {
  reference_id: {
    Header: () => (
      <span className="id__header">
        ID
        <span className="btn-sort" />
      </span>
    ),
    id: 'reference_id',
    accessor: (d) => d.reference_id,
    width: 130,
    resizable: false,
    sortable: true,
    // eslint-disable-next-line react/display-name
    Cell: (row) => {
      const {version, v3_shipment_id: v3ShipmentId} = row.original;
      const isV3 = version === V3_VERSION;
      const v3ShipmentURI = `/shipments/${v3ShipmentId}`;
      const hasAlert = row.original && row.original.metadata && row.original.metadata.alert_level;
      return (
        <div className="id-wrapper">
          <div className="id-tag-container">
            <div className="id">
              <div className="dashboard__alert">
                {hasAlert ? (
                  <Tooltip portal placement="top" tooltipContent={<div>{row.original.metadata.alert_message}</div>}>
                    <SvgIcon
                      className={`dashboard__priority ${row.original?.metadata?.alert_level}`}
                      name="WarningFilled"
                      width="20"
                      height="20"
                    />
                  </Tooltip>
                ) : null}
                <StopFlagsTooltip stops={row.original.stops} />
              </div>
              {row.original.row_type === 'SERVICE' ? (
                <ServiceIdCell row={row} />
              ) : (
                <Link
                  className="shipmentLink"
                  to={
                    isV3
                      ? v3ShipmentURI
                      : `${row.original.state === 'draft' ? 'new-shipment' : 'shipments'}/${row.original.id}`
                  }
                >
                  {row.value}
                </Link>
              )}
            </div>
            {row?.original?.metadata?.tags?.length > 0 && (
              <div className="tag-container">
                {row.original.metadata.tags.slice(0, 3).map((tagId) => {
                  const tagOptions = store?.getState()?.shipments?.tags;
                  const tag = tagOptions?.find((tagOption) => tagOption?.id === tagId);

                  return (
                    <Tooltip key={tagId} portal placement="top" tooltipContent={tag?.name ?? ''}>
                      {tag ? (
                        <span className={classnames('tag-option size-4 mr-2 rounded-full', {[tag.color]: tag.color})}>
                          &nbsp;
                        </span>
                      ) : null}
                    </Tooltip>
                  );
                })}
                {row.original.metadata?.tags?.length > 3 ? (
                  <Tooltip
                    portal
                    placement="top"
                    tooltipContent={row.original.metadata.tags.slice(3).map((tagId) => {
                      const tagOptions = store?.getState()?.shipments?.tags;
                      const tag = tagOptions?.find((tagOption) => tagOption?.id === tagId);
                      return (
                        <div key={tagId} className="tag-container-tooltip">
                          {tag ? (
                            <span className={classnames('tag-option', {[tag.color]: tag.color})}>&nbsp;</span>
                          ) : null}
                          <span className="tag-option-description">{tag?.name || ''}</span>
                        </div>
                      );
                    })}
                  >
                    <SvgIcon name="Plus" height="16px" width="16px" />
                  </Tooltip>
                ) : null}
              </div>
            )}
          </div>
        </div>
      );
    }
  },
  status: {
    Header: () => (
      <span>
        Status
        <span className="btn-sort" />
      </span>
    ),
    id: 'status',
    accessor: (d) => d.state,
    minWidth: 130,
    sortable: true,
    Cell: (row) => {
      const isOnLoadBoard = row.original && row.original.metadata && row.original.metadata.load_board_enabled;
      const isParcel = row.original?.mode?.code === 'PARCEL';
      const parcelStatus =
        row.value === 'carrier_confirmed'
          ? {label: 'Carrier Confirmed', className: 'status-dispatched'}
          : parcelStatuses[row.original.parcel_pickup_status];

      if (!row.value) {
        return '--';
      }

      const shipmentStatus = (
        <div className="status">
          <div className="status__icon">
            {isOnLoadBoard ? (
              <Tooltip portal placement="top" tooltipContent="This shipment is on your load board.">
                <SvgIcon color="sw-icon" name="Loadboard" />
              </Tooltip>
            ) : (
              ''
            )}
          </div>
          {isParcel && (
            <div className={`status__text ${parcelStatus?.className}`}>
              <span className="table__label">{parcelStatus?.label || ''}</span>
            </div>
          )}
          {((row.original.mode && row.original.mode.code !== 'PARCEL') || !row.original.mode) && (
            <div className="status__text">
              <span className="flex items-center">
                {row.original?.metadata?.archived ? (
                  <Tooltip portal placement="top" tooltipContent="This shipment has been archived.">
                    <SvgIcon color="sw-icon" name="Archive" />
                  </Tooltip>
                ) : null}
                {row.value === 'carrier_confirmed' || row.value === 'quote_accepted' ? (
                  <span className="table__label status-accepted">{row.value.split('_').join(' ').toLowerCase()}</span>
                ) : row.value === 'dispatched' ? (
                  <span className="table__label status-dispatched">{row.value.split('_').join(' ').toLowerCase()}</span>
                ) : row.value === 'appointment_pending' ? (
                  <span className="table__label status-appointment">
                    {row.value.split('_').join(' ').toLowerCase()}
                  </span>
                ) : row.value === 'in_transit' ? (
                  <span className="table__label status-intransit">{row.value.split('_').join(' ').toLowerCase()}</span>
                ) : row.value === 'tendered' || row.value === 'delivered' ? (
                  <span className="table__label status-delivered">{row.value.split('_').join(' ').toLowerCase()}</span>
                ) : row.value === 'reconciled' ? (
                  <span className="table__label status-exception">{row.value.split('_').join(' ').toLowerCase()}</span>
                ) : row.value === 'cancelled' ? (
                  <span className="table__label status-cancelled">{row.value.split('_').join(' ').toLowerCase()}</span>
                ) : row.original.shipment_pickup ? (
                  <span className="table__label status-open">{row.value.split('_').join(' ').toLowerCase()}</span>
                ) : (
                  <span className="table__label status-intransit">{row.value.split('_').join(' ').toLowerCase()}</span>
                )}
                {row.original.delayed ? <br /> : ''}
                {row.original.delayed ? <span className="table__label status-delayed">{'Delayed'}</span> : ''}
                {row.original.metadata.open ? <br /> : ''}
              </span>
              {row.original.metadata.open ? (
                <span className="table__label status-accepted">
                  <i className="flaticon-star" /> Open
                </span>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      );
      return shipmentStatus;
    }
  },
  reps: {
    Header: 'Reps',
    id: 'reps',
    minWidth: 110,
    accessor: (d) => d.reps,
    sortable: false,
    Cell: (row) => {
      return row.value?.length > 0 ? (
        <div>
          <Tooltip
            placement="top"
            portal
            tooltipContent={
              <div>
                {row.value.map((rep, i) => {
                  return (
                    <div key={i}>
                      <b>{rep.role && rep.role.name && rep.role.name.slice(0, -4) + ': '}</b>{' '}
                      {rep.user && rep.user.first_name + ' ' + rep.user.last_name}
                    </div>
                  );
                })}
              </div>
            }
          >
            <div className="reps">
              {row.value.map((rep, i) => {
                return (
                  <div key={i}>
                    <b>{rep.role && rep.role.name && rep.role.name.slice(0, -4) + ': '}</b>{' '}
                    {rep.user &&
                      rep.user.first_name.charAt(0).toUpperCase() + rep.user.last_name.charAt(0).toUpperCase()}
                  </div>
                );
              })}
            </div>
          </Tooltip>
        </div>
      ) : (
        '--'
      );
    }
  },
  pickup_date: {
    Header: () => (
      <span role="button">
        Pickup Date
        <span className="btn-sort" />
      </span>
    ),
    id: 'pickup_date',
    accessor: (d) => d.stops,
    minWidth: 140,
    sortable: true,
    Cell: (row) => {
      let pickUpStop;

      const sortedStops = row.value.sort((a, b) => a.ordinal_index - b.ordinal_index);

      for (let i = 0; i < sortedStops.length; i++) {
        if (sortedStops[i].is_pickup && !pickUpStop) {
          pickUpStop = sortedStops[i];
        }
      }
      const isLTL = getIsLTL(row.original.mode?.code);
      return renderStopDate(pickUpStop, isLTL);
    }
  },
  carrier_status: {
    Header: () => <span>Carrier Status</span>,
    id: 'carrier_status',
    accessor: (d) => d.relationship_to_vendor?.carrier_status,
    Cell: ({original}) => {
      return original.relationship_to_vendor?.carrier_status ? (
        <span className={`shipments-carrier-status-column ${original.relationship_to_vendor.carrier_status}`}>
          {original.relationship_to_vendor.carrier_status === 'DO_NOT_USE' && (
            <SvgIcon name="ErrorOutlined" className="do-not-use-carrier-icon" />
          )}
          {startCaseToLower(original.relationship_to_vendor.carrier_status)}
        </span>
      ) : (
        '--'
      );
    }
  },
  accessorials: {
    Header: () => <span>Shipment Accessorials</span>,
    id: 'accessorials',
    minWidth: 175,
    accessor: (d) => d.accessorials,
    sortable: false,
    Cell: ({value}) => {
      return <AccessorialsCell accessorials={value || []} />;
    }
  },
  pickup_location: {
    Header: 'Pickup',
    id: 'pickup_location',
    minWidth: 125,
    accessor: (d) => d.stops,
    sortable: false,
    Cell: (row) => {
      let pickUpStop;
      const sortedStops = row.value.sort((a, b) => a.ordinal_index - b.ordinal_index);
      for (let i = 0; i < sortedStops.length; i++) {
        if (sortedStops[i].is_pickup && !pickUpStop) {
          pickUpStop = sortedStops[i];
        }
      }
      return formatStopAddress(pickUpStop?.location);
    }
  },
  delivery_date: {
    Header: () => (
      <span role="button">
        Delivery Date
        <span className="btn-sort" />
      </span>
    ),
    id: 'delivery_date',
    sortable: true,
    accessor: (d) => d.stops,
    minWidth: 140,

    Cell: (row) => {
      let dropOffStop;
      const sortedStops = row.value.sort((a, b) => a.ordinal_index - b.ordinal_index);

      for (let i = 0; i < sortedStops.length; i++) {
        if (sortedStops[i].is_dropoff) {
          dropOffStop = sortedStops[i];
        }
      }
      return renderStopDate(dropOffStop, false);
    }
  },
  delivery_location: {
    Header: 'Delivery',
    id: 'delivery_location',
    minWidth: 125,
    accessor: (d) => d.stops,
    sortable: false,
    Cell: (row) => {
      let dropOffStop;
      const sortedStops = row.value.sort((a, b) => a.ordinal_index - b.ordinal_index);
      for (let i = 0; i < sortedStops.length; i++) {
        if (sortedStops[i].is_dropoff) {
          dropOffStop = sortedStops[i];
        }
      }
      return formatStopAddress(dropOffStop?.location);
    }
  },
  stops: {
    Header: 'Stops',
    id: 'stops',
    accessor: (d) => d.stops,
    sortable: false,
    minWidth: 75,
    Cell: function renderStopsItineraryOverlay(row) {
      const countPick = row?.value?.filter((s) => s.is_pickup).length;
      const countDrop = row?.value?.filter((s) => s.is_dropoff).length;
      const {hasLTL} = checkShipmentModes(row?.original?.mode);
      return (
        <StopsItineraryOverlay stops={row.value} isLoadBoard={false} hasLTL={hasLTL}>
          <StopsItineraryCell countPick={countPick} countDrop={countDrop} />
        </StopsItineraryOverlay>
      );
    }
  },
  account_id: {
    Header: 'Account Id',
    id: 'account_id',
    sortable: false,
    accessor: (d) => {
      if (d.fedex_specific_options) {
        return d.fedex_specific_options;
      }
      if (d.ups_specific_options) {
        return d.ups_specific_options;
      }
      if (d.usps_specific_options) {
        return d.usps_specific_options;
      }
      return '--';
    },
    Cell: ({value}) => {
      const parcelOptions = value || {};

      if (!parcelAccounts) {
        parcelAccounts = {};

        getFedExRegistrationAccounts()
          .then((response) => {
            if (response && response.body && response.body.results) {
              response.body.results.forEach((account) => (parcelAccounts[account.id] = account));
            }
          })
          .catch((error) => console.error(error));

        getUPSRegistrationAccounts()
          .then((response) => {
            if (response && response.body && response.body.results) {
              response.body.results.forEach((account) => (parcelAccounts[account.id] = account));
            }
          })
          .catch((error) => console.error(error));
      }

      return parcelAccounts && parcelAccounts[parcelOptions.account]
        ? parcelAccounts[parcelOptions.account].account_number
        : '--';
    }
  },
  mode: {
    Header: () => (
      <span role="button">
        Mode
        <span className="btn-sort" />
      </span>
    ),
    sortable: true,

    id: 'mode',
    accessor: (d) => (d.mode ? formatShipmentModeCode(d.mode.code) : '--')
  },
  equipment: {
    Header: () => (
      <span role="button">
        Equipment
        <span className="btn-sort" />
      </span>
    ),
    sortable: true,
    id: 'equipment',
    accessor: (d) => (d.equipment_type ? d.equipment_type.name : '--')
  },
  weight: {
    Header: () => {
      const {unitPreferences} = store.getState().userCompany;
      return <span>{unitPreferences.system === METRIC ? 'Weight (kg)' : 'Weight (lbs)'}</span>;
    },
    id: 'weight',
    accessor: (d) => d.line_items,
    Cell: (row) => {
      const {unitPreferences} = store.getState().userCompany;
      return displayTotalWeight({
        shipment: row.original,
        unitPreferences,
        returnNullWeight: true,
        returnWeightLabel: false
      });
    }
  },
  line_items: {
    Header: 'Items',
    id: 'line_items',
    minWidth: 85,
    accessor: (d) => d.line_items,
    sortable: false,
    Cell: (row) => {
      const {unitPreferences} = store.getState().userCompany;
      let totals = {};
      if (row.value && row.value.length) {
        totals = calculateShipmentTotals({
          line_items: row.value,
          unitPreferences,
          totalWeight: row.original?.total_weight_override
        });
      }
      const totalPackages = totals.units;
      return (
        <Tooltip
          portal
          placement="left"
          tooltipClassname="p-2"
          tooltipContent={<LineItemsTooltip shipmentId={row.original.id} />}
        >
          <div>
            {row.value && row.value.length > 1 ? (
              <div>{totalPackages} item(s)</div>
            ) : (
              <div>{row.value && row.value[0] && row.value[0].description}</div>
            )}
            {totals.system === METRIC ? (
              <div>
                {totals.volume ? numberWithCommas(totals.volume.toFixed(2)) : ''} m<sup>3</sup>
              </div>
            ) : (
              <div>
                {totals.volume ? numberWithCommas(totals.volume.toFixed(2)) : ''} ft
                <sup>3</sup>
              </div>
            )}
          </div>
        </Tooltip>
      );
    },
    filterable: true,
    Filter: ({filter, onChange}) => <div className="no-filter hidden">&nbsp;</div>
  },
  financials: {
    Header: () => <span className="dashboard__financials-header">Financials</span>,
    id: 'financials',
    minWidth: 160,
    accessor: (d) => d.most_recently_awarded_quote,
    sortable: false,
    className: 'dashboard__financials',
    Cell: (row) => {
      return renderFinancialsTable(row.original);
    },
    filterable: true,
    Filter: ({filter, onChange}) => <div className="no-filter hidden">&nbsp;</div>
  },
  customer_financials: {
    Header: 'Customer Financials',
    id: 'customer_financials',
    minWidth: 160,
    accessor: (d) => d.most_recently_awarded_quote,
    Cell: (row) => <div className="grow text-right">{renderCustomerFinancialsTable(row.original)}</div>
  },
  provider_financials: {
    Header: 'Provider Financials',
    id: 'provider_financials',
    minWidth: 160,
    accessor: (d) => d.most_recently_awarded_quote,
    Cell: (row) => <div className="grow text-right">{renderProviderFinancialsTable(row.original)}</div>
  },
  net_financials: {
    Header: 'Net Financials',
    id: 'net_financials',
    minWidth: 130,
    accessor: (d) => d.most_recently_awarded_quote,
    Cell: (row) => <div className="grow text-right">{renderNetFinancialsTable(row.original)}</div>
  },
  bol: {
    Header: 'BOL #',
    id: 'bol',
    accessor: (d) => (d.bol_number ? d.bol_number : '--')
  },
  pickup_number: {
    Header: 'Pickup #',
    id: 'pickup_number',
    accessor: (d) => (d.pickup_number ? d.pickup_number : '--')
  },
  pro: {
    Header: 'PRO #',
    id: 'pro',
    accessor: (d) => (d.pro_number ? d.pro_number : '--')
  },
  po: {
    Header: 'PO #',
    id: 'po',
    accessor: (d) => d.purchase_order_number,
    Cell: ({value}) => {
      return <PurchaseOrderNumberTooltipContent poNumber={value} />;
    }
  },
  service_level: {
    minWidth: 120,
    Header: 'Service Level',
    id: 'service_level',
    accessor: 'service_level',
    Cell: (row) => {
      if (row.original.mode && row.original.mode.code === 'PARCEL') {
        if (row.original.fedex_specific_options && row.original.fedex_specific_options.service_code) {
          return fedExServiceLevels[row.original.fedex_specific_options.service_code];
        }
        if (row.original.ups_specific_options && row.original.ups_specific_options.service_code) {
          return upsServiceLevels[row.original.ups_specific_options.service_code];
        }
        if (row.original.usps_specific_options && row.original.usps_specific_options.service_code) {
          return uspsServiceLevels[row.original.usps_specific_options.service_code];
        }
      } else if (row.value) {
        return row.value.description;
      }
      return '--';
    }
  },
  origin_company: {
    Header: 'Pickup Company',
    id: 'origin_company',
    accessor: (d) => d.stops,
    Cell: (row) => {
      let pickUpStop;
      const sortedStops = row.value.sort((a, b) => a.ordinal_index - b.ordinal_index);

      for (let i = 0; i < sortedStops.length; i++) {
        if (sortedStops[i].is_pickup && !pickUpStop) {
          pickUpStop = sortedStops[i];
        }
      }
      return renderStopCompany(pickUpStop);
    }
  },
  destination_company: {
    Header: 'Delivery Company',
    id: 'destination_company',
    accessor: (d) => d.stops,
    Cell: (row) => {
      let dropOffStop;
      const sortedStops = row.value.sort((a, b) => a.ordinal_index - b.ordinal_index);

      for (let i = 0; i < sortedStops.length; i++) {
        if (sortedStops[i].is_dropoff) {
          dropOffStop = sortedStops[i];
        }
      }
      return renderStopCompany(dropOffStop);
    }
  },
  timeline_last_updated_at: {
    minWidth: 150,
    Header: () => (
      <span role="button">
        Timeline Updated
        <span className="btn-sort" />
      </span>
    ),
    sortable: true,
    id: 'timeline_last_updated_at',
    accessor: (d) => d.timeline_last_updated_at,
    Cell: (row) => {
      return formatTimeAgo(row.value);
    }
  },
  total_miles: {
    Header: () => {
      const {unitPreferences} = store.getState().userCompany;
      return (
        <span role="button">
          {unitPreferences.system === METRIC ? 'Kilometers' : 'Miles'}
          <span className="btn-sort" />
        </span>
      );
    },
    sortable: true,
    id: 'total_miles',
    accessor: (d) => d.total_miles,
    Cell: (row) => {
      const {unitPreferences} = store.getState().userCompany;
      let distance = '--';
      if (row.value && !isNaN(row.value)) {
        if (unitPreferences.system === METRIC) {
          distance = convertMilesToKilometers(row.value);
        } else if (unitPreferences.system === IMPERIAL) {
          distance = formatMileage(row.value);
        }
      }
      return distance;
    }
  },
  markup: {
    Header: () => (
      <span role="button">
        Markup
        <span className="btn-sort" />
      </span>
    ),
    sortable: true,
    id: 'markup',
    accessor: (d) => d.markup,
    Cell: (row) => <CurrencyCell value={row.value} preferredCurrency={row.original?.preferredCurrency} />
  },
  max_buy_amount: {
    Header: () => (
      <span role="button">
        Max Buy
        <span className="btn-sort" />
      </span>
    ),
    sortable: true,
    id: 'max_buy_amount',
    accessor: (d) => d.metadata.max_buy_amount,
    Cell: (row) => <CurrencyCell value={row.value} preferredCurrency={row.original?.preferredCurrency} />
  },
  buy_it_now_amount: {
    Header: () => (
      <span role="button">
        Book Now
        <span className="btn-sort" />
      </span>
    ),
    sortable: true,
    id: 'buy_it_now_amount',
    accessor: (d) => d.metadata.buy_it_now_amount,
    Cell: (row) => <CurrencyCell value={row.value} preferredCurrency={row.original?.preferredCurrency} />
  },
  target_rate_amount: {
    Header: () => (
      <span role="button">
        Target Rate
        <span className="btn-sort" />
      </span>
    ),
    sortable: true,
    id: 'target_rate_amount',
    accessor: (d) => d.metadata.target_rate_amount,
    Cell: (row) => <CurrencyCell value={row.value} preferredCurrency={row.original?.preferredCurrency} />
  },
  tender_status: {
    Header: () => (
      <span role="button">
        Tender Status
        <span className="btn-sort" />
      </span>
    ),
    id: 'tender_status',
    accessor: 'tender_status',
    Cell: (row) => (row.value ? capitalize(row.value) : '--')
  },
  container_number: {
    Header: () => (
      <span role="button">
        Container #
        <span className="btn-sort" />
      </span>
    ),
    id: 'container_number',
    sortable: true,
    minWidth: 150,
    accessor: (d) => d.drayage_container_number,
    Cell: ContainerNumberCell
  },
  load_board_id: {
    Header: () => <span>Load Board ID</span>,
    sortable: false,
    id: 'load_board_id',
    accessor: (d) => d.metadata.load_board_id,
    Cell: (row) => {
      return row.value ? row.value : '--';
    }
  },
  createdAt: {
    Header: () => (
      <span role="button">
        Created
        <span className="btn-sort" />
      </span>
    ),
    id: 'createdAt',
    sortable: true,
    accessor: (d) => d.created_at,
    Cell: (row) => {
      return (
        <div className="dashboard__created">
          <span>{moment(row.value).format('MMM D, YYYY')}</span>
          <span>{formatTime(row.value, true, moment.tz.guess())}</span>
        </div>
      );
    }
  },
  name: {
    Header: () => (
      <span role="button">
        Name
        <span className="btn-sort" />
      </span>
    ),
    id: 'name',
    sortable: true,
    accessor: (d) => d.name,
    Cell: (row) => {
      return row.value ? row.value : '--';
    }
  },
  customer: {
    Header: () => (
      <span role="button">
        Customer
        <span className="btn-sort" />
      </span>
    ),
    id: 'customer',
    sortable: true,
    minWidth: 150,
    accessor: (d) => d.customer,
    Cell: (row) => {
      return row.original.relationship_to_customer && row.original.relationship_to_customer.customer.name;
    }
  },
  customer_reference_number: {
    Header: () => (
      <span role="button">
        Customer Reference #
        <span className="btn-sort" />
      </span>
    ),
    id: 'customer_reference_number',
    sortable: true,
    minWidth: 175,
    accessor: (d) => d.customer_reference_number,
    Cell: (row) => {
      return row.value ? row.value : '--';
    }
  },
  vendor: {
    Header: 'Carrier',
    id: 'vendor',
    minWidth: 150,
    accessor: (d) => d.relationship_to_vendor,
    sortable: false,
    Cell: (row) => {
      //check if the vendor is the assigned carrier. if not, then we show the carrier instead of this relationship
      const isAssignedCarrier = row.value && row.value.is_assigned_carrier;
      return (
        <div className="dashboard__vendors">
          {
            //if there is a vendor at all, show it
            row.value && row.value.vendor ? (
              <div>
                {row.original.mode &&
                //if LTL, show the carrier assignment instead of the vendor
                (row.original.mode.code === 'LTL' || row.original.mode.code === 'VLTL') &&
                !isAssignedCarrier ? (
                  <div>
                    {row.original.current_carrier && row.original.current_carrier.name}
                    {!row.original.current_carrier && row.value.vendor && row.value.vendor.name}
                  </div>
                ) : (
                  <div>{row.value && row.value.vendor.name ? <div>{row.value.vendor.name}</div> : null}</div>
                )}
              </div>
            ) : (
              '--'
            )
          }
        </div>
      );
    }
  },
  created_by: {
    Header: 'Created By',
    id: 'created_by',
    minWidth: 150,
    accessor: (d) => d.created_by_user,
    Cell: (row) => {
      const createdBy = getReadableCreatedByString(row.value, row.original?.created_by_source);
      return <p>{createdBy || '--'}</p>;
    }
  },
  actions: {
    Header: 'Actions',
    id: 'actions',
    minWidth: 158,
    sortable: false,
    accessor: (d) => d.id,
    Cell: (row) => {
      const {
        documents_count: documentsCount,
        notes_count: notesCount,
        messages_count: messagesCount,
        version
      } = row.original;
      const isV3 = version === V3_VERSION;

      return (
        <div className="dashboard__actions">
          <ActionLinkWithModal
            disabled={isV3 || documentsCount === 0}
            tooltip={`${documentsCount || 'No'} ${pluralize('Document', documentsCount)}`}
            modalTitle="Shipment Documents"
            modalContent={<SendShipmentDocuments mode={SEND_DOCUMENTS} shipmentId={row.original.id} />}
            triggerReload
          >
            <SvgIcon name="Clipboard" color={!isV3 && documentsCount > 0 ? '$sw-icon' : '$sw-disabled'} />
          </ActionLinkWithModal>
          <ActionLinkWithModal
            disabled={isV3}
            tooltip={!isV3 ? 'Shipment Contacts' : 'No Shipment Contacts'}
            modalTitle="Shipment Contacts"
            modalContent={<SendShipmentDocuments mode={EMAIL_CONTACTS} shipmentId={row.original.id} />}
            triggerReload
          >
            <SvgIcon name="Contacts" color={!isV3 ? '$sw-icon' : 'sw-disabled'} />
          </ActionLinkWithModal>
          <ActionLinkWithModal
            disabled={isV3}
            tooltip={!isV3 || notesCount > 0 ? 'Internal Notes' : 'No Internal Notes'}
            modalTitle="Internal Notes"
            modalContent={<ShipmentNotes shipment={row.original} shouldPoll />}
            triggerReload
          >
            <SvgIcon name="DocumentBlank" color={!isV3 || notesCount > 0 ? '$sw-icon' : '$sw-disabled'} />
          </ActionLinkWithModal>
          <ActionLinkWithModal
            disabled={isV3}
            tooltip={!isV3 || messagesCount > 0 ? 'Messages' : 'No Messages'}
            modalTitle="Messages"
            modalContent={<Messages shipmentResource={row.original} resourceType="SHIPMENT" shouldPoll />}
            triggerReload
          >
            <SvgIcon name="Messages" color={!isV3 || messagesCount > 0 ? '$sw-icon' : '$sw-disabled'} />
          </ActionLinkWithModal>
          <WatchShipmentsLink shipment={row.original} />
        </div>
      );
    }
  },
  trailer_name: {
    Header: 'Trailer #',
    id: 'trailer_name',
    accessor: (d) => d.trailer_name || '--'
  },
  upcoming_etas: {
    Header: 'Upcoming ETA(s)',
    id: 'upcoming_etas',
    sortable: false,
    minWidth: 160,
    accessor: (d) => d.id,
    Cell: function setEtaDisplay({original}) {
      const isLTL = getIsLTL(original?.original?.mode?.code);
      return <UpcomingEtasCell stops={original?.stops || []} isLTL={isLTL} />;
    }
  },
  workflow_execution_info: {
    Header: 'Workflows',
    id: 'workflow_execution_info',
    sortable: true,
    accessor: (d) => d.workflow_execution_info,
    Cell: ({value}) => (value ? <WorkflowExecutionCell value={value} /> : '--')
  },
  seal_number: {
    Header: () => (
      <span role="button">
        Seal #
        <span className="btn-sort" />
      </span>
    ),
    id: 'seal_number',
    sortable: true,
    accessor: (d) => d.drayage_seal_number || '--'
  },
  house_bol_number: {
    Header: 'House BOL #',
    id: 'house_bol_number',
    sortable: false,
    accessor: (d) => d.drayage_house_bol_number || '--'
  },
  estimated_arrival_date: {
    Header: () => (
      <span role="button">
        Estimated Container Arrival
        <span className="btn-sort" />
      </span>
    ),
    id: 'estimated_arrival_date',
    sortable: true,
    accessor: (d) =>
      moment(d.drayage_estimated_arrival_date).isValid()
        ? moment(d.drayage_estimated_arrival_date).format('MMM D, YYYY')
        : '--'
  },
  release_date: {
    Header: () => (
      <span role="button">
        Release Date
        <span className="btn-sort" />
      </span>
    ),
    id: 'release_date',
    sortable: true,
    accessor: (d) =>
      moment(d.drayage_release_date).isValid() ? moment(d.drayage_release_date).format('MMM D, YYYY') : '--'
  },
  last_free_date: {
    Header: () => (
      <span role="button">
        Last Free Date
        <span className="btn-sort" />
      </span>
    ),
    id: 'last_free_date',
    sortable: true,
    accessor: (d) =>
      moment(d.drayage_last_free_date).isValid() ? moment(d.drayage_last_free_date).format('MMM D, YYYY') : '--'
  },
  container_return_date: {
    Header: () => (
      <span role="button">
        Container Return Date
        <span className="btn-sort" />
      </span>
    ),
    id: 'container_return_date',
    sortable: true,
    accessor: (d) =>
      moment(d.drayage_container_return_date).isValid()
        ? moment(d.drayage_container_return_date).format('MMM D, YYYY')
        : '--'
  },
  auction_stats: {
    Header: 'Auction Stats',
    id: 'auction_stats',
    sortable: false,
    width: 150,
    accessor: (d) => d.reference_id,
    Cell: (row) => {
      const auctionStats = row.original.auction_stats;
      const hasOpenAuction = row.original?.metadata?.has_open_auction;
      if (!hasOpenAuction) {
        return '--';
      }
      return <AuctionStatsCell shipmentId={row.original.id} hasOpenAuction auctionStats={auctionStats} />;
    }
  },
  lowest_bid: {
    Header: () => <span>Lowest Bid</span>,
    sortable: false,
    id: 'lowest_bid',
    accessor: (d) => d.auction_stats?.lowest_bid,
    Cell: (row) => <CurrencyCell value={row.value} preferredCurrency={row.original?.preferredCurrency} />
  },
  rail_car_status: {
    Header: () => <span>Rail Car Status</span>,
    sortable: true,
    id: 'rail_car_status',
    accessor: (d) => d.rail_car_status,
    Cell: (row) => (row.value ? row.value : '--')
  },
  rail_car_number: {
    Header: () => <span>Rail Car Number</span>,
    sortable: true,
    id: 'rail_car_number',
    accessor: (d) => d.rail_car_number,
    Cell: (row) => (row.value ? row.value : '--')
  },
  power_unit_name: {
    Header: () => (
      <span>
        Power Unit
        <span className="btn-sort" />
      </span>
    ),
    sortable: true,
    id: 'power_unit_name',
    accessor: (d) => d.power_unit_name,
    Cell: (row) => <span className="truncate">{row.value || '--'}</span>
  }
};

const WatchShipmentsLink = withRouter(({shipment, router}) => {
  const toggleWatchShipmentCallback = () => {
    //trigger a refresh of the dashboard
    router.push(buildPathParams(router.location, {reload: true}));
  };
  return <WatchShipmentToggle shipment={shipment} onSuccess={toggleWatchShipmentCallback} />;
});

export default columns;
