/* eslint-disable camelcase */
import {connect} from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import {useFlags} from 'launchdarkly-react-client-sdk';
import {withRouter} from 'react-router';
import {Form, Formik} from 'formik';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {object, array, string, number, mixed, ref} from 'yup';
import get from 'lodash/get';
import set from 'lodash/set';
import {Card} from '@shipwell/shipwell-ui';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  createDefaultCustomDataValues,
  createDefaultLineItem
} from 'App/formComponents/formSections/LineItemFields/utils/createDefaultLineItem';
import {LineItemFields} from 'App/formComponents/formSections/LineItemFields';
import ShipmentItemTotals from 'App/formComponents/formSections/_shipmentItemTotals';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {emptyStringToNull, validateDecimalPoints} from 'App/utils/yupHelpers';
import {validateDollarValue} from 'App/utils/globals';
import {countTotalDigits} from 'App/utils/globalsTyped';
import {CustomFieldsContextProvider, CustomFieldsContextConsumer} from 'App/data-hooks';
import {isCustomFieldOwner} from 'App/utils/customData';
import {getCustomDataPath} from 'App/utils/customDataPath';
import withStatusToasts from 'App/components/withStatusToasts';
import omitEmptyKeys from 'App/utils/omitEmptyKeys';
import validateNMFCCode, {validateNMFCSubCode, nmfcCodeSchema, nmfcSubCodeSchema} from 'App/utils/validateNMFCCode';

export const checkHazmatRequired = (lineItems) =>
  lineItems.map((lineItem) => {
    if (lineItem.hazmat_identification_number) {
      return {...lineItem, hazmat_required: true};
    }
    return lineItem;
  });

export const createValidationSchema = (customFields = [], isWeightRequired, flagDecimalSupportForShipmentLineItems) => {
  const packageWeightSchema = number()
    .nullable()
    .transform(emptyStringToNull)
    .typeError('Total weight must be a number.')
    .test({
      name: 'Total weight max 2 decimals',
      message: 'Enter up to two decimals.',
      test: (value) => (value ? validateDecimalPoints(value, 2) : true)
    });
  const maybeRequiredPackageWeightSchema = isWeightRequired
    ? packageWeightSchema.required('Package weight is required.')
    : packageWeightSchema;

  return object().shape({
    line_items: array().of(
      object().shape(
        {
          total_packages: number()
            .nullable()
            .typeError('Quantity must be a number.')
            .positive('Quantity must be a positive number.')
            .test('is-integer', 'Quantity must be a whole number.', function (value) {
              if (!flagDecimalSupportForShipmentLineItems) {
                return Number.isInteger(value);
              }
              return true;
            }),
          description: string().required('Description is required.'),
          package_weight: maybeRequiredPackageWeightSchema,
          length: number()
            .nullable()
            .transform(emptyStringToNull)
            .typeError('Length must be a number.')
            .test({
              name: 'Length max 2 decimals',
              message: 'Enter up to two decimals.',
              test: (value) => (value ? validateDecimalPoints(value, 2) : true)
            }),
          total_pieces: number()
            .nullable()
            .transform(emptyStringToNull)
            .typeError('Number of Pieces must be a number.'),
          width: number()
            .nullable()
            .transform(emptyStringToNull)
            .typeError('Width must be a number.')
            .test({
              name: 'Width max 2 decimals',
              message: 'Enter up to two decimals.',
              test: (value) => (value ? validateDecimalPoints(value, 2) : true)
            }),
          height: number()
            .nullable()
            .transform(emptyStringToNull)
            .typeError('Height must be a number.')
            .test({
              name: 'Height max 2 decimals',
              message: 'Enter up to two decimals.',
              test: (value) => (value ? validateDecimalPoints(value, 2) : true)
            }),
          value_per_piece: mixed().test({
            name: 'valid dollar value',
            message: 'Enter a dollar value.',
            test: (value) => validateDollarValue(value)
          }),
          refrigeration_min_temp: number().when('refrigeration_required', {
            is: true,
            then: number()
              .transform(emptyStringToNull)
              .typeError('Min Temp must be a number.')
              .required('Min Temp is required.')
              .max(ref('refrigeration_max_temp'), 'Must not be more than Max Temp.'),
            otherwise: number()
              .transform(emptyStringToNull)
              .typeError('Min Temp must be a number.')
              .max(ref('refrigeration_max_temp'), 'Must not be more than Max Temp.')
              .nullable()
          }),
          refrigeration_max_temp: number().when('refrigeration_required', {
            is: true,
            then: number()
              .transform(emptyStringToNull)
              .typeError('Max Temp must be a number.')
              .required('Max Temp is required.')
              .min(ref('refrigeration_min_temp'), 'Must not be less than than Min Temp.'),
            otherwise: number()
              .transform(emptyStringToNull)
              .typeError('Max Temp must be a number.')
              .min(ref('refrigeration_min_temp'), 'Must not be less than than Min Temp.')
              .nullable()
          }),
          country_of_manufacture: string().nullable(),
          nmfc_item_code: nmfcCodeSchema,
          nmfc_sub_code: nmfcSubCodeSchema,
          custom_data: object()
            .nullable()
            .shape({
              shipwell_custom_data: object().shape(
                customFields.reduce(
                  (shape, cf) => ({
                    ...shape,
                    [cf.id]: cf.required ? string().required(`${cf.label} is required.`) : string().nullable()
                  }),
                  {}
                )
              )
            }),
          hazmat_identification_number: string()
            .nullable()
            .when(['hazmat_hazard_class', 'hazmat_packing_group', 'hazmat_proper_shipping_name'], {
              is: (hazmat_hazard_class, hazmat_packing_group, hazmat_proper_shipping_name) =>
                [hazmat_hazard_class, hazmat_packing_group, hazmat_proper_shipping_name].some((value) => !!value),
              then: string()
                .nullable()
                .required('Identification number is required.')
                .test(
                  'valid length',
                  'Ensure this field has no more than 16 characters.',
                  (value) => value?.length <= 16
                )
            }),
          hazmat_hazard_class: string()
            .nullable()
            .when(['hazmat_identification_number', 'hazmat_packing_group', 'hazmat_proper_shipping_name'], {
              is: (hazmat_identification_number, hazmat_packing_group, hazmat_proper_shipping_name) =>
                [hazmat_identification_number, hazmat_packing_group, hazmat_proper_shipping_name].some(
                  (value) => !!value
                ),
              then: string()
                .nullable()
                .required('Hazard class is required.')
                .test('valid length', 'Ensure this field has no more than 6 characters.', (value) => value?.length <= 6)
            }),
          hazmat_packing_group: mixed()
            .nullable()
            .when(['hazmat_hazard_class', 'hazmat_identification_number', 'hazmat_proper_shipping_name'], {
              is: (hazmat_hazard_class, hazmat_identification_number, hazmat_proper_shipping_name) =>
                [hazmat_hazard_class, hazmat_identification_number, hazmat_proper_shipping_name].some(
                  (value) => !!value
                ),
              then: mixed().required('Packing group is required.')
            }),
          hazmat_proper_shipping_name: string()
            .nullable()
            .when(['hazmat_hazard_class', 'hazmat_packing_group', 'hazmat_identification_number'], {
              is: (hazmat_hazard_class, hazmat_packing_group, hazmat_identification_number) =>
                [hazmat_hazard_class, hazmat_packing_group, hazmat_identification_number].some((value) => !!value),
              then: string().nullable().required('Proper shipping name is required.')
            })
        },
        [
          ['hazmat_identification_number', 'hazmat_hazard_class'],
          ['hazmat_identification_number', 'hazmat_packing_group'],
          ['hazmat_identification_number', 'hazmat_proper_shipping_name'],
          ['hazmat_hazard_class', 'hazmat_packing_group'],
          ['hazmat_hazard_class', 'hazmat_proper_shipping_name'],
          ['hazmat_packing_group', 'hazmat_proper_shipping_name']
        ]
      )
    ),
    total_weight_override_value: number()
      .nullable()
      .when('manual_total_weight', {
        is: true,
        then: number().required('Weight is required.').typeError('Weight must be a number.')
      }),
    total_declared_value: number()
      .nullable()
      .typeError('Value must be a dollar value.')
      .when('manual_total_value', {
        is: true,
        then: number()
          .required('Value is required.')
          .test({
            name: 'valid dollar value',
            message: 'Enter a dollar value.',
            test: (value) => validateDollarValue(String(value), true)
          })
          .test({
            name: 'maximum 10 digits',
            message: 'Ensure that there are no more than 10 digits in total.',
            test: (value) => countTotalDigits(value) <= 10
          })
      }),
    total_quantity_override: number()
      .integer()
      .nullable()
      .when('manual_quantity', {
        is: true,
        then: number()
          .integer('Quantity must be an integer.')
          .required('Quantity is required.')
          .typeError('Quantity must be a number.')
          .test({
            name: 'less than or equal to 2147483647',
            message: 'Ensure this value is less than or equal to 2147483647.',
            test: (value) => value <= 2147483647
          })
      })
  });
};

/**
 * Shipment line items form
 */
const ShipmentLineItemsForm = ({
  modalState,
  shipmentDetails,
  values,
  onSubmit,
  lineItems,
  onCancel,
  unitPreferences,
  company,
  valueCurrency,
  submitDisabled,
  isWeightRequired
}) => {
  const flags = useFlags();

  /** Default values needed for Formik */
  const createDefaultFormValues = (customFields = []) => ({
    line_items: [createDefaultLineItem(customFields, unitPreferences)],
    source: 'SHIPWELL_WEB',
    custom_data: set({}, getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentLineItem)),
    manual_total_weight: !!Number(shipmentDetails?.total_weight_override.value),
    total_weight_override_value: shipmentDetails?.total_weight_override?.value,
    total_weight_override_unit: shipmentDetails?.total_weight_override?.unit,
    manual_total_value: !!Number(shipmentDetails?.total_declared_value),
    total_declared_value: shipmentDetails?.total_declared_value,
    manual_quantity: !!Number(shipmentDetails?.total_quantity_override),
    total_quantity_override: shipmentDetails?.total_quantity_override,
    bypass_carrier_insurance_coverage: shipmentDetails?.metadata.bypass_carrier_insurance_coverage
  });

  /** Submit shipment line item values */
  const handleSubmit = (values, props) => {
    const payload = {
      line_items: values.line_items,
      metadata: {
        open: shipmentDetails?.metadata.open,
        archived: shipmentDetails?.metadata.archived,
        tags: shipmentDetails?.metadata.tags,
        bypass_carrier_insurance_coverage: values.bypass_carrier_insurance_coverage
      },
      total_weight_override: {
        value:
          values?.manual_total_weight && values.total_weight_override_value > 0
            ? values.total_weight_override_value
            : null,
        unit: values.total_weight_override_unit ?? shipmentDetails?.total_weight_override?.unit
      },
      total_declared_value: values?.manual_total_value ? values.total_declared_value : null,
      total_quantity_override:
        values?.manual_quantity && values?.total_quantity_override > 0 ? values?.total_quantity_override : null,
      carrier_reference_code: shipmentDetails.carrier_reference_code || null
    };

    payload.line_items.forEach((lineItem) => {
      if (lineItem.nmfc_item_code) {
        try {
          lineItem.nmfc_item_code = validateNMFCCode(lineItem.nmfc_item_code);
        } catch (e) {
          lineItem.nmfc_item_code = '';
        }
      }

      if (lineItem.nmfc_sub_code) {
        try {
          lineItem.nmfc_sub_code = validateNMFCSubCode(lineItem.nmfc_sub_code);
        } catch (e) {
          lineItem.nmfc_sub_code = '';
        }
      }

      if (lineItem.value_per_piece === '') {
        lineItem.value_per_piece = null;
      }

      //Empty fields for temp should be converted from '' to null since BE didn't accept empty strings
      if (lineItem.refrigeration_max_temp === '') {
        lineItem.refrigeration_max_temp = null;
      }
      if (lineItem.refrigeration_min_temp === '') {
        lineItem.refrigeration_min_temp = null;
      }
    });

    if (onSubmit) {
      onSubmit(payload, props);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      <CustomFieldsContextProvider
        companyId={get(company, 'id')}
        entityType={CustomFieldEntityTypesEnum.ShipmentLineItem}
      >
        <CustomFieldsContextConsumer>
          {({customFields = []}) => {
            const editableCustomFields = customFields.filter((cf) =>
              isCustomFieldOwner(cf.company, get(company, 'id'))
            );
            const validationSchemaWithCustomFields = createValidationSchema(
              editableCustomFields,
              isWeightRequired,
              flags?.decimalSupportForShipmentLineItems || false
            );
            const defaultValuesWithCustomFields = createDefaultFormValues(editableCustomFields);

            // Some shipments might have null or other custom_data, which interferes with these initial
            // values. We need to filter out null custom_data from line items then add proper default
            // values to each line item in the order for validation to work when editing such orders.
            const filteredLineItems = get(values, 'line_items', []).map((lineItem) => {
              const filteredLineItem = {
                ...createDefaultLineItem(customFields, unitPreferences),
                ...omitEmptyKeys(lineItem)
              };

              const cdPath = `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentLineItem)}`;
              set(filteredLineItem, cdPath, {
                ...createDefaultCustomDataValues(customFields),
                ...get(filteredLineItem, cdPath, {})
              });

              return filteredLineItem;
            });

            const filteredValues = {
              ...defaultValuesWithCustomFields,
              ...omitEmptyKeys(values),
              line_items:
                lineItems?.length > 0
                  ? checkHazmatRequired(lineItems)
                  : filteredLineItems.length > 0
                  ? checkHazmatRequired(filteredLineItems)
                  : [createDefaultLineItem(customFields, unitPreferences)]
            };

            return (
              <Formik
                validationSchema={validationSchemaWithCustomFields}
                initialValues={filteredValues}
                onSubmit={handleSubmit}
                validateOnMount
              >
                {({values, isValid}) => (
                  <>
                    <Form className="shipment-line-item-form" noValidate="novalidate">
                      <Card title="Shipment Line Items">
                        <LineItemFields
                          defaultLineItemFieldValues={createDefaultLineItem(customFields, unitPreferences)}
                          edit={modalState?.mode === 'edit'}
                          expandedIndex={modalState?.index}
                          shipmentDetails={shipmentDetails}
                          entityType={CustomFieldEntityTypesEnum.ShipmentLineItem}
                          customFields={customFields}
                          isWeightRequired={isWeightRequired}
                        />
                      </Card>
                      <Card title="Totals" className="mt-4">
                        <ShipmentItemTotals
                          unitPreferences={unitPreferences}
                          lineItems={values.line_items || []}
                          totalWeight={{
                            value: values.total_weight_override_value,
                            unit: values.total_weight_override_unit
                          }}
                          totalValue={values.total_declared_value}
                          valueCurrency={valueCurrency}
                          totalQuantity={values.total_quantity_override}
                          shipmentDetails={shipmentDetails}
                          values={values}
                          showManualOverrideFields
                        />
                      </Card>
                      <ModalFormFooter
                        onPrimaryActionClick={() => handleSubmit(values)}
                        isValid={isValid}
                        primaryActionDisabled={submitDisabled}
                        onCancel={handleCancel}
                        className="relative"
                        primaryActionName="Save Line Items"
                      />
                    </Form>
                  </>
                )}
              </Formik>
            );
          }}
        </CustomFieldsContextConsumer>
      </CustomFieldsContextProvider>
    </>
  );
};

ShipmentLineItemsForm.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string
  }),
  edit: PropTypes.bool,
  lineItems: PropTypes.shape({
    length: PropTypes.number
  }),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  shipmentDetails: PropTypes.object,
  unitPreferences: PropTypes.shape({
    lengthUnit: PropTypes.string,
    weightUnit: PropTypes.string
  }),
  valueCurrency: PropTypes.string,
  values: PropTypes.shape({
    filteredValues: PropTypes.array,
    id: PropTypes.string,
    line_items: PropTypes.array,
    bypass_carrier_insurance_coverage: PropTypes.bool,
    metadata: PropTypes.shape({
      archived: PropTypes.bool,
      open: PropTypes.bool,
      tags: PropTypes.array
    }),
    manual_total_weight: PropTypes.bool,
    manual_total_value: PropTypes.bool,
    total_weight_override_value: PropTypes.number,
    total_weight_override_unit: PropTypes.string,
    total_declared_value: PropTypes.number,
    total_quantity_override: PropTypes.number,
    manual_quantity: PropTypes.bool
  }),
  modalState: PropTypes.shape({
    mode: PropTypes.string,
    index: PropTypes.number
  }),
  submitDisabled: PropTypes.bool,
  isWeightRequired: PropTypes.bool
};

ShipmentLineItemsForm.defaultProps = {
  isWeightRequired: false
};

export default compose(
  withStatusToasts,
  withRouter,
  connect((state) => ({
    shipment: state.shipmentdetails.one,
    unitPreferences: state.userCompany.unitPreferences,
    company: state.userCompany.company
  }))
)(ShipmentLineItemsForm);
