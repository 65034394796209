import {ProductPackageType} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  AreaUnit,
  CreateOrderItem,
  LengthUnit,
  OrderItemAmount,
  PackagingType,
  ProductOrderStatus,
  PurchaseOrderStatus,
  SalesOrderStatus,
  VolumeUnit,
  WeightUnit
} from '@shipwell/corrogo-sdk';

export const volumeLabels: Record<VolumeUnit, string> = {
  [VolumeUnit.CuFt]: 'FT\u00B3',
  [VolumeUnit.CuM]: 'M\u00B3',
  [VolumeUnit.Gallon]: 'GAL',
  [VolumeUnit.Liter]: 'L'
};
export const productOrderStatusLabels: Record<ProductOrderStatus, string> = {
  NOT_READY: 'Not Ready',
  READY: 'Ready',
  FILLED: 'Filled',
  ASSIGNED: 'On Shipments',
  CANCELLED: 'Cancelled',
  DISPATCHED: 'Dispatched',
  IN_TRANSIT: 'In Transit',
  OUT_FOR_DELIVERY: 'Out for Delivery',
  DELIVERED: 'Delivered'
};
export const purchaseOrderStatusLabels: Record<PurchaseOrderStatus, string> = {
  NOT_READY: 'Not Ready',
  READY: 'Ready',
  FILLED: 'Filled',
  ASSIGNED: 'Assigned',
  CANCELLED: 'Cancelled',
  DISPATCHED: 'Dispatched',
  IN_TRANSIT: 'In Transit',
  OUT_FOR_DELIVERY: 'Out for Delivery',
  DELIVERED: 'Delivered'
};
export const salesOrderStatusLabels: Record<SalesOrderStatus, string> = {
  NOT_READY: 'Not Ready',
  READY: 'Ready',
  FILLED: 'Filled',
  ASSIGNED: 'Assigned',
  CANCELLED: 'Cancelled',
  DISPATCHED: 'Dispatched',
  IN_TRANSIT: 'In Transit',
  OUT_FOR_DELIVERY: 'Out for Delivery',
  DELIVERED: 'Delivered'
};

export const orderSuccessActionTitle = 'Success!';
export const getOrderSuccessActionDetail = (verb: string) => `Your order was successfully ${verb}.`;

export const blankOrderItem: CreateOrderItem = {
  amount: {
    value: '',
    unit: '' as OrderItemAmount['unit']
  },
  shipping_requirements: {
    description: ''
  }
};

export const CORROGO_ORDERS_IMPORT_TYPE = 'PURCHASE_OR_SALES_ORDER';
export const PACKAGING_TYPES: Record<ProductPackageType, PackagingType> = {
  [ProductPackageType.Bag]: PackagingType.Bag,
  [ProductPackageType.Bale]: PackagingType.Bale,
  [ProductPackageType.Bin]: PackagingType.Bin,
  [ProductPackageType.Bottle]: PackagingType.Bottle,
  [ProductPackageType.Box]: PackagingType.Box,
  [ProductPackageType.Bucket]: PackagingType.Bucket,
  [ProductPackageType.Bundle]: PackagingType.Bundle,
  [ProductPackageType.Can]: PackagingType.Can,
  [ProductPackageType.Carton]: PackagingType.Carton,
  [ProductPackageType.Case]: PackagingType.Case,
  [ProductPackageType.Coil]: PackagingType.Coil,
  [ProductPackageType.Crate]: PackagingType.Crate,
  [ProductPackageType.Cylinder]: PackagingType.Cylinder,
  [ProductPackageType.Drum]: PackagingType.Drum,
  [ProductPackageType.FloorLoaded]: PackagingType.FloorLoaded,
  [ProductPackageType.Jerrican]: PackagingType.Jerrican,
  [ProductPackageType.Other]: PackagingType.Other,
  [ProductPackageType.Pail]: PackagingType.Pail,
  [ProductPackageType.Pieces]: PackagingType.Piece,
  [ProductPackageType.Pkg]: PackagingType.Package,
  [ProductPackageType.Plt]: PackagingType.Pallet,
  [ProductPackageType.Reel]: PackagingType.Reel,
  [ProductPackageType.Roll]: PackagingType.Roll,
  [ProductPackageType.Skid]: PackagingType.Skid,
  [ProductPackageType.ToteBin]: PackagingType.ToteBin,
  [ProductPackageType.ToteCan]: PackagingType.ToteCan,
  [ProductPackageType.Tube]: PackagingType.Tube,
  [ProductPackageType.Unit]: PackagingType.Unit
};

export const unitLabels: Record<WeightUnit | VolumeUnit | LengthUnit | PackagingType | AreaUnit, string> = {
  [VolumeUnit.CuFt]: 'FT\u00B3',
  [VolumeUnit.CuM]: 'M\u00B3',
  [VolumeUnit.Gallon]: 'GAL',
  [VolumeUnit.Liter]: 'L',
  [AreaUnit.Ft]: 'FT\u00B2',
  [AreaUnit.Yd]: 'YD\u00B2',
  [WeightUnit.Kg]: WeightUnit.Kg,
  [WeightUnit.Lb]: WeightUnit.Lb,
  [LengthUnit.Cm]: LengthUnit.Cm,
  [LengthUnit.Ft]: LengthUnit.Ft,
  [LengthUnit.In]: LengthUnit.In,
  [LengthUnit.M]: LengthUnit.M,
  [PackagingType.Bag]: PackagingType.Bag,
  [PackagingType.Bale]: PackagingType.Bale,
  [PackagingType.Barrel]: PackagingType.Barrel,
  [PackagingType.Basket]: PackagingType.Basket,
  [PackagingType.Bin]: PackagingType.Bin,
  [PackagingType.Bottle]: PackagingType.Bottle,
  [PackagingType.Box]: PackagingType.Box,
  [PackagingType.Bucket]: PackagingType.Bucket,
  [PackagingType.Bundle]: PackagingType.Bundle,
  [PackagingType.Can]: PackagingType.Can,
  [PackagingType.Carton]: PackagingType.Carton,
  [PackagingType.Case]: PackagingType.Case,
  [PackagingType.Coil]: PackagingType.Coil,
  [PackagingType.Container]: PackagingType.Container,
  [PackagingType.Crate]: PackagingType.Crate,
  [PackagingType.Cylinder]: PackagingType.Cylinder,
  [PackagingType.Drum]: PackagingType.Drum,
  [PackagingType.Envelope]: PackagingType.Envelope,
  [PackagingType.FloorLoaded]: PackagingType.FloorLoaded,
  [PackagingType.Hamper]: PackagingType.Hamper,
  [PackagingType.Jerrican]: PackagingType.Jerrican,
  [PackagingType.None]: PackagingType.None,
  [PackagingType.Other]: PackagingType.Other,
  [PackagingType.Package]: PackagingType.Package,
  [PackagingType.Pail]: PackagingType.Pail,
  [PackagingType.Pallet]: PackagingType.Pallet,
  [PackagingType.Piece]: PackagingType.Piece,
  [PackagingType.ProviderSpecific]: PackagingType.ProviderSpecific,
  [PackagingType.Reel]: PackagingType.Reel,
  [PackagingType.Roll]: PackagingType.Roll,
  [PackagingType.Skid]: PackagingType.Skid,
  [PackagingType.Tank]: PackagingType.Tank,
  [PackagingType.ToteBin]: PackagingType.ToteBin,
  [PackagingType.ToteCan]: PackagingType.ToteCan,
  [PackagingType.Tube]: PackagingType.Tube,
  [PackagingType.Unit]: PackagingType.Unit,
  [PackagingType.Bottle]: PackagingType.Bottle,
  [PackagingType.Jerrican]: PackagingType.Jerrican
};
