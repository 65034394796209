import {useMemo} from 'react';
import {ItemWithStepsCard, ItemWithStepsCardProps} from '../../itemWithStepsCard/itemWithStepsCard';
import {InvocationData} from '../useScriptsService/invocationData';
import {getCardPropsFromInvocationData} from './helpers';

export interface InvocationCardProps {
  invocation: InvocationData;
}

export function InvocationCard(props: InvocationCardProps) {
  const cardProps = useMemo<ItemWithStepsCardProps>(
    () => getCardPropsFromInvocationData(props.invocation),
    [props.invocation]
  );

  return (
    <ItemWithStepsCard
      title={cardProps.title}
      metaInfos={cardProps.metaInfos}
      stepsInfo={cardProps.stepsInfo}
      status={cardProps.status}
    />
  );
}
