import {FreightInvoiceStatus} from '@shipwell/settlements-sdk';

export enum DashboardNameEnum {
  Delivered = 'Delivered',
  Reviewing = 'Reviewing',
  Disputing = 'Disputing',
  Paying = 'Paying',
  AllInvoices = 'All Invoices'
}

export interface DashboardView {
  name: DashboardNameEnum;
  statuses: string[];
  delivered?: string;
  columnsToHide: string[];
}

export const dashboards: DashboardView[] = [
  {
    name: DashboardNameEnum.Delivered,
    statuses: [],
    delivered: 'true',
    columnsToHide: []
  },
  {
    name: DashboardNameEnum.Reviewing,
    statuses: ['EXCEPTION'],
    columnsToHide: ['actual_due_date', 'invoice_date', 'delivered_date', 'due_date', 'paid_date']
  },
  {
    name: DashboardNameEnum.Disputing,
    statuses: ['DISPUTED'],
    columnsToHide: ['actual_due_date', 'invoice_date', 'delivered_date', 'due_date', 'paid_date']
  },
  {
    name: DashboardNameEnum.Paying,
    statuses: ['PASSED', 'APPROVED'],
    columnsToHide: ['paid_date']
  },
  {
    name: DashboardNameEnum.AllInvoices,
    statuses: Object.values(FreightInvoiceStatus),
    columnsToHide: []
  }
];

export const DashboardsSelector = ({
  activeDashboardName,
  onChange
}: {
  activeDashboardName: DashboardNameEnum;
  onChange: (dashboardName: DashboardNameEnum) => void;
}) => {
  return (
    <div className="flex gap-3 p-3">
      {dashboards.map((filter) => {
        const isActive = filter.name === activeDashboardName;

        return (
          <button
            key={filter.name}
            aria-selected={isActive}
            className={`${isActive ? 'bg-sw-active' : 'bg-sw-disabled'} rounded-full px-6 py-1 text-sw-text-reverse`}
            onClick={() => onChange(filter.name)}
          >
            {filter.name.toUpperCase()}
          </button>
        );
      })}
    </div>
  );
};
