import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import LegacyStatus from '../Status';
import LegacyReferenceId from '../ReferenceId';
import withStatusToasts from 'App/components/withStatusToasts';
import Tags from 'App/components/Tags';
import {shipmentsShipmentIdPut} from 'App/actions/_shipmentDetails';
import LegacyShipmentDescriptionForm from 'App/containers/Shipment/forms/ShipmentDescriptionForm';
import LegacyWatchShipmentToggle from 'App/containers/alertsDashboard/components/watchShipmentToggle';
import {HeaderContainer, InlineHeaderContainer, BackLink} from 'App/containers/shipments/components/Header/Header';

const LegacyHeader = ({hasError, shipment, shipmentsShipmentIdPut, setError, fetchDetails}) => {
  const handleUpdateTags = (tagId, tagIds) => {
    const payload = {
      ...shipment,
      metadata: {
        ...shipment.metadata,
        tags: tagIds
      }
    };
    return shipmentsShipmentIdPut(shipment.id, payload);
  };

  const handleWatchShipment = () => {
    try {
      fetchDetails(shipment.id);
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
    }
  };

  const filterParams = localStorage.getItem('dashboardFilterParams');
  const backLink = filterParams ? `/dashboard?${filterParams}` : '/dashboard';

  return (
    <HeaderContainer>
      <InlineHeaderContainer>
        <div className="flex">
          <BackLink hasError={hasError} routeTo={backLink} />
          <LegacyReferenceId notFound={hasError} />
        </div>
        <LegacyStatus className="tw-hidden lg:flex" />
        <LegacyWatchShipmentToggle onSuccess={handleWatchShipment} shipment={shipment} />
        <Tags
          showDefaultTagsLink
          removeTag={handleUpdateTags}
          addTag={handleUpdateTags}
          className="tw-hidden lg:flex"
        />
      </InlineHeaderContainer>
      <LegacyStatus className="lg:hidden" />
      <Tags
        showDefaultTagsLink
        removeTag={handleUpdateTags}
        addTag={handleUpdateTags}
        className="m-2 lg:hidden"
        showTagBorder={false}
      />
      <div className="h-8">
        <LegacyShipmentDescriptionForm />
      </div>
    </HeaderContainer>
  );
};

LegacyHeader.propTypes = {
  hasError: PropTypes.bool,
  shipment: PropTypes.object,
  setError: PropTypes.func,
  shipmentsShipmentIdPut: PropTypes.func,
  fetchDetails: PropTypes.func
};

LegacyHeader.defaultProps = {
  hasError: false,
  shipment: {},
  setError: () => {},
  shipmentsShipmentIdPut: () => {},
  fetchDetails: () => {}
};

export default compose(
  withStatusToasts,
  connect(
    (state) => ({
      shipment: state.shipmentdetails.one,
      companyTags: state.shipments.tags
    }),
    {
      shipmentsShipmentIdPut
    }
  )
)(LegacyHeader);
