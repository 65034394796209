import {UseQueryOptions, UseQueryResult, useQuery} from '@tanstack/react-query';
import invariant from 'tiny-invariant';
import {ShipwellError, CompanyPreferences} from '@shipwell/backend-core-singlerequestparam-sdk';
import {AxiosError} from 'axios';
import {COMPANY_PREFERENCES_KEY} from 'App/data-hooks/queryKeys';
import {getCompanyPreferences} from 'App/api/company/typed';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';

export type UseCompanyPreferencesOptions = Omit<
  UseQueryOptions<CompanyPreferences, AxiosError<ShipwellError>, CompanyPreferences, (string | null | undefined)[]>,
  'queryKey' | 'queryFn'
>;

/**
 * Fetches the preferences for a company by it's ID. If the company id is falsy then the query is not enabled.
 * If a user does not have access the hook will fail with a 403 axios API error.
 * @param {string|null|undefined} companyId the ID of the company to fetch preferences for. For most requests this will be the company_id of the currently authenticated users which can be retrieved using the `useUserMe` query hook.
 * @param {UseCompanyPreferencesOptions} options any query options to pass to the query hook
 * @returns {UseQueryResult<CompanyPreferences, AxiosError<ShipwellError>>}
 */
const useCompanyPreferences = (
  companyId?: string | null,
  options?: UseCompanyPreferencesOptions
): UseQueryResult<CompanyPreferences, AxiosError<ShipwellError>> => {
  const query = useQuery(
    [COMPANY_PREFERENCES_KEY, companyId],
    async () => {
      invariant(companyId, 'companyId is required to fetch company preferences');
      const {data} = await getCompanyPreferences(companyId);

      return data;
    },
    {
      ...options,
      enabled: !!companyId,
      staleTime: STATIC_DATA_STALE_TIME
    }
  );

  return query;
};

export default useCompanyPreferences;
