import {DeprecatedButton, FormikSelect, SvgIcon} from '@shipwell/shipwell-ui';
import {Field, FieldArray, useFormikContext} from 'formik';
import React from 'react';
import {
  CompanyPreferencesExternalPushTracking,
  CompanyPreferencesExternalPushTrackingExternalIdQualifierEnum,
  CompanyPreferencesExternalPushTrackingProviderEnum
} from '@shipwell/backend-core-sdk';

const DEFAULT_EXTERNAL_ID_OPTIONS = [
  {
    label: 'Customer Reference Number',
    value: CompanyPreferencesExternalPushTrackingExternalIdQualifierEnum.CustomerReferenceNumber
  },
  {label: 'BOL Number', value: CompanyPreferencesExternalPushTrackingExternalIdQualifierEnum.BolNumber},
  {label: 'Reference ID', value: CompanyPreferencesExternalPushTrackingExternalIdQualifierEnum.ReferenceId},
  {label: 'Name', value: CompanyPreferencesExternalPushTrackingExternalIdQualifierEnum.Name}
];

const DEFAULT_PROVIDER_OPTIONS = [
  {label: 'P44', value: CompanyPreferencesExternalPushTrackingProviderEnum.P44},
  {label: 'FourKites', value: CompanyPreferencesExternalPushTrackingProviderEnum.FourKites}
];

const ExternalTrackingConfigFields = () => {
  const {values, setFieldValue} = useFormikContext<{
    external_push_tracking_ids?: CompanyPreferencesExternalPushTracking[];
  }>();

  const getProviderOptions = (id: CompanyPreferencesExternalPushTracking) => {
    const selectedProviders = values?.external_push_tracking_ids
      ?.map((i) => i.provider)
      .filter((provider) => provider !== id.provider);
    const availableOptions = DEFAULT_PROVIDER_OPTIONS.filter((opt) => !selectedProviders?.includes(opt.value));
    return availableOptions;
  };

  const getExternalIdOptions = (id: CompanyPreferencesExternalPushTracking) => {
    return id.provider === CompanyPreferencesExternalPushTrackingProviderEnum.P44
      ? DEFAULT_EXTERNAL_ID_OPTIONS.slice(0, 2)
      : DEFAULT_EXTERNAL_ID_OPTIONS;
  };

  if (!values?.external_push_tracking_ids?.length) {
    return <span>No Providers.</span>;
  }

  const handleProviderChange = (value: CompanyPreferencesExternalPushTrackingProviderEnum, idx: number) => {
    if (value === CompanyPreferencesExternalPushTrackingProviderEnum.P44) {
      setFieldValue(`external_push_tracking_ids.${idx}.external_id_qualifier`, '');
    }
    setFieldValue(`external_push_tracking_ids.${idx}.provider`, value);
  };

  return (
    <FieldArray name="external_push_tracking_ids">
      {({remove}) => (
        <>
          {values?.external_push_tracking_ids?.map((id: CompanyPreferencesExternalPushTracking, idx: number) => (
            <div className="mb-5 grid grid-cols-[1fr,15px] justify-items-stretch gap-x-2.5" key={idx}>
              <div className="grid grid-cols-2 gap-[10px]">
                <Field
                  label="Provider"
                  name={`external_push_tracking_ids.${idx}.provider`}
                  component={FormikSelect}
                  options={getProviderOptions(id)}
                  onChange={(value: CompanyPreferencesExternalPushTrackingProviderEnum) =>
                    handleProviderChange(value, idx)
                  }
                  clearable={false}
                  simpleValue
                />
                <Field
                  label="External ID"
                  name={`external_push_tracking_ids.${idx}.external_id_qualifier`}
                  component={FormikSelect}
                  options={getExternalIdOptions(id)}
                  simpleValue
                />
              </div>
              <DeprecatedButton variant="icon" onClick={() => remove(idx)}>
                <SvgIcon name="TrashOutlined" />
              </DeprecatedButton>
            </div>
          ))}
        </>
      )}
    </FieldArray>
  );
};

export default ExternalTrackingConfigFields;
