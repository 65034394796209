/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {useEffect, useRef, useState} from 'react';
import TrimbleMaps, {NavigationControl, Map, Common} from '@trimblemaps/trimblemaps-js';
import {theme} from '@shipwell/shipwell-ui';
import find from 'lodash/find';
import {TRIMBLE_MAPS_API_KEY, isStyleFunction} from './utils';

export function useTrimbleMaps({
  elementId,
  options,
  onZoomChange
}: {
  elementId: string;
  options: object;
  onZoomChange: (ev: TrimbleMaps.MapEventType & TrimbleMaps.EventData) => void;
}) {
  const mapRef = useRef<TrimbleMaps.Map>();
  const [baseStyles, setBaseStyles] = useState({});
  const [mapLoaded, setMapLoaded] = useState(false);
  const [styleLoaded, setStyleLoaded] = useState(false);

  useEffect(() => {
    /**
     * @function initialize - initializes the Trimble Map with a given elementId and options
     * @param {string} elementId
     * @param {object} options
     */
    function initialize(elementId: string, options: {zoom?: number}) {
      if (TrimbleMaps) {
        // eslint-disable-next-line import/no-named-as-default-member
        TrimbleMaps.APIKey = TRIMBLE_MAPS_API_KEY;
        const map = new Map({
          container: elementId, // container id
          style: Common.Style.DATALIGHT, // hosted style id
          zoom: options?.zoom || 3,
          center: [-97, 38]
        });

        map.on('load', () => {
          const styles = updateStyle(map);
          // save the initial styling for use later if toggling from satellite image
          setBaseStyles(styles);

          // listen for zoom changes on the map
          map.off('zoomend', onZoomChange);
          map.on('zoomend', onZoomChange);

          const nav = new NavigationControl({showZoom: true, showCompass: false});
          map.addControl(nav, 'bottom-right');
          setMapLoaded(true);
        });

        map.on(
          'styledata',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          (e: TrimbleMaps.MapStyleDataEvent & TrimbleMaps.EventData & {target?: {_loaded: boolean}}) => {
            setStyleLoaded(!!e?.target?._loaded);
          }
        );

        return map;
      }
    }
    if (elementId && !mapRef.current) {
      mapRef.current = initialize(elementId, options);
    }
  }, [elementId, options, mapRef, onZoomChange, setBaseStyles, styleLoaded]);

  return {mapRef, baseStyles, mapLoaded, styleLoaded};
}

/**
 * @function updateStyle - updates the map styling to match Shipwell branding
 * @param {TrimbleMaps.Map} map
 */
function updateStyle(map: TrimbleMaps.Map) {
  const styles = map.getStyle();
  const background = find(styles.layers, (val) => val.id === 'background') as TrimbleMaps.BackgroundLayer;
  if (background) {
    background.paint = {'background-color': '#F1F1F1'};
  }
  const city = find(styles.layers, (val) => val.id === 'areas_urban') as TrimbleMaps.FillLayer;
  if (city) {
    city.paint!['fill-color'] = {
      stops: [
        [6, '#F1F1F1'],
        [7, '#F1F1F1']
      ]
    };
  }
  const water = find(styles.layers, (val) => val.id === 'water') as TrimbleMaps.FillLayer;
  if (water) {
    water.paint = {'fill-color': '#C2D2E2'};
  }
  const traffic1 = find(styles.layers, (val) => val.id === 'traffic_speed_1') as undefined | TrimbleMaps.LineLayer;
  if (traffic1?.paint && isStyleFunction(traffic1.paint['line-color'])) {
    traffic1.paint['line-color'].stops = [
      ['Open', theme.colors.swSuccess],
      ['Light', theme.colors.swWarning],
      ['Heavy', theme.colors.swError],
      ['Closed', theme.colors.swBackgroundHeader]
    ];
  }
  const traffic2 = find(styles.layers, (val) => val.id === 'traffic_speed_2') as undefined | TrimbleMaps.LineLayer;
  if (traffic2?.paint && isStyleFunction(traffic2.paint['line-color'])) {
    traffic2.paint['line-color'].stops = [
      ['Open', theme.colors.swSuccess],
      ['Light', theme.colors.swWarning],
      ['Heavy', theme.colors.swError],
      ['Closed', theme.colors.swBackgroundHeader]
    ];
  }
  const stateCountryLabelLayerIds = [
    'place_admin_country',
    'place_admin_country_med',
    'place_admin_country_small',
    'place_admin_admin1',
    'place_admin_admin1_small',
    'place_admin_admin1_tiny',
    'place_admin_county',
    'place_admin_admin1_mex'
  ];

  const stateCountryLabelLayers = styles.layers?.filter((val) => {
    return stateCountryLabelLayerIds.includes(val.id);
  }) as TrimbleMaps.SymbolLayer[];
  stateCountryLabelLayers?.forEach((label) => {
    label.paint!['text-color'] = theme.colors.swText;
    label.layout!['text-transform'] = 'none';
  });

  const cityLabelLayerIds = [
    'place_city_other',
    'place_city_0',
    'place_city_large_area',
    'place_city_1_2',
    'place_city_3_4',
    'place_city_5',
    'place_city_6',
    'place_city_7'
  ];
  const cityLabelLayers = styles.layers?.filter((val) => {
    return cityLabelLayerIds.includes(val.id);
  }) as TrimbleMaps.SymbolLayer[];
  cityLabelLayers.forEach((label) => {
    label.paint!['text-color'] = theme.colors.swBackgroundHeader;
    label.layout!['text-transform'] = 'none';
  });

  const largeCityLayers = styles.layers?.filter((val) => {
    return val.id === 'place_city_6' || val.id === 'place_city_7';
  }) as TrimbleMaps.SymbolLayer[];

  largeCityLayers?.forEach((label) => {
    if (isStyleFunction(label.layout?.['text-size'])) {
      label.layout!['text-size']!.stops = [
        [3, 10],
        [8, 17]
      ];
    }
  });

  const largeRoadLayerIds = [
    'road_tolls',
    'road_interstate',
    'road_ramp',
    'road_interstate_casing',
    'road_toll_casing',
    'road_primary',
    'road_primary_tolls',
    'road_divided',
    'road_divided_tolls'
  ];
  const largeRoadLayers = styles.layers?.filter((val) => {
    return largeRoadLayerIds.includes(val.id);
  }) as TrimbleMaps.LineLayer[];
  largeRoadLayers?.forEach((road) => {
    road.paint!['line-color'] = theme.colors.swBorderAlternate;
  });

  map.setStyle(styles, {diff: true});
  return styles;
}
