import {Banner} from '@shipwell/shipwell-ui';
import {
  CompanyInternationalPreferencesCurrencyEnum,
  CustomField,
  Shipment,
  ShipmentChargeLineItem
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {AggregateCharge, GrandTotals} from '../../types';
import {
  AggregateTotalCell,
  FinancialsGrandTotalRow,
  FinancialsGridRow,
  FinancialsSubTotalRow,
  TextCell
} from './components';
import {getCustomDataValueByFieldType} from './utils';
import {formatCurrency} from 'App/utils/internationalConstants';
import Loader from 'App/common/shipwellLoader';
import {useSortedChargeCategories} from 'App/data-hooks';

export const CustomerTable = ({
  customerAssignment,
  aggregateCustomerCharges,
  theirCustomerCharges,
  myCustomerCharges,
  showCustomerColumn,
  grandTotals,
  shipmentCurrency,
  customFields
}: {
  customerAssignment: Shipment['relationship_to_customer'];
  aggregateCustomerCharges: AggregateCharge[];
  theirCustomerCharges: ShipmentChargeLineItem[];
  myCustomerCharges: ShipmentChargeLineItem[];
  showCustomerColumn?: boolean;
  grandTotals: GrandTotals;
  shipmentCurrency: CompanyInternationalPreferencesCurrencyEnum;
  customFields: CustomField[];
}) => {
  const {data: chargeCategories, isLoading: isLoadingChargeCategories} = useSortedChargeCategories();
  const visibleCustomFields = customFields.filter((customField) => customField.ui_enabled);
  const {chargeLineItemsCustomFieldsFix}: {chargeLineItemsCustomFieldsFix: boolean} = useFlags();

  if (chargeCategories) {
    const hasCustomerFinancials = !!customerAssignment && !!aggregateCustomerCharges?.length;

    const formattedMarkup = formatCurrency(grandTotals.customerMarkup, shipmentCurrency);
    const formattedMargin = grandTotals.customerMargin?.toFixed(2) || '0';
    const financiaslSubTotal = `${formattedMarkup} (${formattedMargin}%)`;

    const financialsGrandTotal = formatCurrency(grandTotals.customer, shipmentCurrency);

    return hasCustomerFinancials ? (
      <div>
        {aggregateCustomerCharges.map((aggregateCustomerCharge, index) => {
          const chargeCategoryName = chargeCategories.find((c) => c.id === aggregateCustomerCharge?.category)?.name;

          const theirCustomerChargesOfThisAggregateCategory = theirCustomerCharges?.filter(
            (theirCustomerCharge) => theirCustomerCharge.category === aggregateCustomerCharge?.category
          );

          const myChargesOfThisAggregateCategory = myCustomerCharges?.filter(
            (myCustomerCharge) => myCustomerCharge.category === aggregateCustomerCharge?.category
          );

          return (
            <FinancialsGridRow
              key={`${aggregateCustomerCharge.category}-${index}}`}
              hasMismatchedTotals={aggregateCustomerCharge?.total_theirs !== aggregateCustomerCharge?.total_mine}
              columnCount={visibleCustomFields.length + 4}
            >
              <TextCell>{chargeCategoryName}</TextCell>

              <TextCell>{aggregateCustomerCharge.unit_name}</TextCell>

              {visibleCustomFields.map((customField) => {
                const customFieldValue = getCustomDataValueByFieldType(
                  aggregateCustomerCharge.customData,
                  customField,
                  chargeLineItemsCustomFieldsFix
                );
                return <TextCell key={customField.id}>{customFieldValue}</TextCell>;
              })}

              {showCustomerColumn ? (
                <AggregateTotalCell
                  aggregateTotal={aggregateCustomerCharge.total_theirs}
                  breakdownCharges={theirCustomerChargesOfThisAggregateCategory}
                  currency={shipmentCurrency}
                />
              ) : (
                <div />
              )}

              <AggregateTotalCell
                aggregateTotal={aggregateCustomerCharge.total_mine}
                breakdownCharges={myChargesOfThisAggregateCategory}
                currency={shipmentCurrency}
              />
            </FinancialsGridRow>
          );
        })}

        <FinancialsSubTotalRow label="Total Markup" total={financiaslSubTotal} />
        <FinancialsGrandTotalRow label="Customer Total" total={financialsGrandTotal} />
      </div>
    ) : (
      <div className="py-4 text-center">
        <h3>No customer financials</h3>
      </div>
    );
  }

  if (isLoadingChargeCategories) return <Loader loading />;

  return <Banner title={'Error'}>There was an error loading financials. Try reloading the page.</Banner>;
};
