import {useFlags} from 'launchdarkly-react-client-sdk';
import {OverflowMenu} from '@shipwell/shipwell-ui';
import {useGetFullShipmentDetails} from 'App/containers/alertsDashboard/utils/hooks/useGetFullShipmentDetails';

export const CreateFreightInvoiceLineItemButton = ({
  role,
  onClick,
  shipmentId
}: {
  role: 'service_provider' | 'booking_party';
  onClick: () => void;
  shipmentId: string;
}) => {
  const {stmGenerateInvoiceOnBehalfOfCarrier} = useFlags();
  const {prefetch: prefetchShipment} = useGetFullShipmentDetails(shipmentId);
  if (role === 'booking_party' && !stmGenerateInvoiceOnBehalfOfCarrier) {
    return null;
  }
  return (
    <OverflowMenu.Item onClick={onClick} onMouseEnter={() => void prefetchShipment()}>{`Create ${
      role === 'booking_party' ? 'Carrier' : ''
    } Invoice`}</OverflowMenu.Item>
  );
};
