import {SvgIcon} from '@shipwell/shipwell-ui';
import isNil from 'lodash/isNil';
import Availability, {
  AvailabilityProps
} from 'App/containers/appointments/components/forms/AppointmentForm/components/availability';
import ShipwellLoader from 'App/common/shipwellLoader';
import {FacilityServiceIcon} from 'App/components/Icons';
import {toTitleCase} from 'App/utils/globals';
import {useShipmentStopFacilityQuery, useShipmentStopQuery} from 'App/data-hooks';

export type SelectStopAppointmentProps = {
  shipmentId?: string | null;
  stopId?: string | null;
} & Pick<AvailabilityProps, 'onSelectedDateChanged' | 'onDockChanged' | 'onPaginate' | 'clickedTime'>;

const SelectStopAppointment = ({
  shipmentId,
  stopId,
  clickedTime,
  onDockChanged,
  onSelectedDateChanged,
  onPaginate
}: SelectStopAppointmentProps): JSX.Element | null => {
  const {stop, isStopsInitialLoading, isStopsFetching} = useShipmentStopQuery({
    shipmentId,
    stopId
  });
  const {facility, isFacilityLoading} = useShipmentStopFacilityQuery(
    {
      shipmentId,
      stopId
    },
    {
      enabled: Boolean(stop)
    }
  );
  const isLoading = Boolean(isStopsInitialLoading || isStopsFetching || isFacilityLoading);
  if (isLoading) {
    return <ShipwellLoader loading />;
  }
  if (isNil(stop) || isNil(facility)) {
    return null;
  }
  // stopNumber is an additional field which FE adding in the getShipmentStops
  // but there are also cases, such as updating stops info, where FE setting stops
  // without adding stopNumber field - in this case we should rely on the ordinal_index
  const stopNumber = stop?.stopNumber || stop?.ordinal_index + 1;

  return (
    <div className="flex h-full flex-row">
      <div className="w-2/5">
        <div className="flex flex-row items-center gap-2 text-lg font-bold">
          <SvgIcon name={`Num${stopNumber}Outlined`} />
          Stop {stop?.stopNumber} - {facility?.name}
        </div>
        <div className="mt-4 grid grid-flow-row gap-4 pl-8" role="list">
          <div className="flex flex-col gap-1" role="listitem">
            <span className="text-xxs uppercase" id="facility-location-label">
              FACILITY LOCATION
            </span>
            <span className="text-sm" aria-labelledby="facility-location-label">
              {facility.address.region}, {facility.address.locality}, {facility.address.postal_code}
            </span>
          </div>
          <div className="flex flex-col gap-1" role="listitem">
            <span className="text-xxs uppercase" id="load-type-label">
              DELIVERY TYPE
            </span>
            <span className="text-sm" aria-labelledby="load-type-label">
              {stop.is_pickup ? 'Shipping' : stop.is_dropoff ? 'Receiving' : '--'}
            </span>
          </div>
          <div className="flex flex-col gap-1" role="listitem">
            <span className="text-xxs uppercase" id="facility-requirements-label">
              LOCATION TYPE
            </span>
            <span className="text-sm" aria-labelledby="facility-requirements-label">
              {facility.location_type?.length ? toTitleCase(facility.location_type) : '--'}
            </span>
          </div>
          <div className="flex flex-col gap-1" role="listitem">
            <span className="break-words text-xxs uppercase" id="carrier-instructions-label">
              CARRIER INSTRUCTIONS
            </span>
            <span className="text-sm" aria-labelledby="carrier-instructions-label">
              {facility.carrier_instructions ?? '--'}
            </span>
          </div>
          <div className="flex flex-col gap-1" role="listitem">
            <span className="text-xxs uppercase" id="disclaimers-label">
              DISCLAIMERS
            </span>
            <span className="break-words text-sm" aria-labelledby="disclaimers-label">
              {facility.disclaimers ?? '--'}
            </span>
          </div>
          <div className="flex flex-col gap-1" role="listitem">
            <span className="text-xxs uppercase" id="facility-services-label">
              FACILITY AMENITIES
            </span>
            <span className="flex flex-row gap-2 break-words text-sm" aria-labelledby="facility-services-label">
              {facility?.facility_services?.length
                ? facility.facility_services?.map((service) => (
                    <FacilityServiceIcon className="text-sw-icon" key={service} service={service} />
                  ))
                : '--'}
            </span>
          </div>
        </div>
      </div>
      <div className="w-3/5">
        <Availability
          facilityId={facility.id}
          shipmentId={shipmentId}
          stopId={stopId}
          clickedTime={clickedTime}
          onSelectedDateChanged={onSelectedDateChanged}
          onDockChanged={onDockChanged}
          onPaginate={onPaginate}
        />
      </div>
    </div>
  );
};

export default SelectStopAppointment;
