import React, {Fragment} from 'react';
import {ShipmentRep} from '@shipwell/backend-core-sdk';
import {Tooltip} from '@shipwell/shipwell-ui';
import {ShippingDashboardShipmentRep} from '@shipwell/backend-core-singlerequestparam-sdk';
import {FlexBox} from 'App/components/Box';

export const RepsCell = ({reps}: {reps: (ShipmentRep | ShippingDashboardShipmentRep)[]}) => {
  if (!reps?.length) {
    return <>--</>;
  }
  const getNameSlice = (name?: string) => name?.slice(0, -4);
  const groupUsersByRole = reps.reduce((acc: Record<string, (ShipmentRep | ShippingDashboardShipmentRep)[]>, user) => {
    const {role} = user;
    if (!role || role.name == null) {
      return acc;
    }

    if (!acc[role.name]) {
      acc[role.name] = [];
    }
    acc[role.name].push(user);
    return acc;
  }, {});

  return (
    <Tooltip
      placement="top"
      portal
      tooltipContent={reps.map((rep) => {
        const nameSlice = getNameSlice(rep.role?.name ?? '--');
        return (
          <div key={rep.id}>
            {nameSlice ? <span className="font-bold">{`${nameSlice}: `}</span> : null}
            <span>
              {rep.user?.first_name} {rep.user?.last_name}
            </span>
          </div>
        );
      })}
    >
      <FlexBox direction="col">
        {Object.keys(groupUsersByRole).map((userRole) => {
          return (
            <div key={userRole} className="whitespace-nowrap">
              <span className="font-bold">{getNameSlice(userRole)}: </span>
              <span>
                {groupUsersByRole[userRole].map(({user}, i) => (
                  <Fragment key={user?.id || i}>
                    {user?.first_name.charAt(0)}
                    {user?.last_name.charAt(0)}
                    {i !== groupUsersByRole[userRole].length - 1 ? ', ' : ''}
                  </Fragment>
                ))}
              </span>
            </div>
          );
        })}
      </FlexBox>
    </Tooltip>
  );
};
