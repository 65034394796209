/* eslint-disable import/no-namespace */
import {
  FeatureFlags,
  Company,
  Shipment,
  Quote,
  SpotNegotiation,
  Hazmat
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {withRouter, WithRouterProps} from 'react-router';
import {MarketplaceBids} from 'App/containers/Marketplace/components/MarketplaceBids';
import {useGetShipmentSpotNegotiations} from 'App/data-hooks/spotNegotiations/useGetShipmentSpotNegotiations';
import {MarketplaceTenders} from 'App/containers/Marketplace/components/MarketplaceTenders';
import * as marketplaceActions from 'App/actions/marketplace';
import * as shipmentActions from 'App/actions/shipments';
import {transformShipmentToForm} from 'App/utils/globals';
import {State} from 'App/reducers/types';

interface DashboardSummaryBidsProps extends WithRouterProps {
  company: Company;
  featureFlags: FeatureFlags;
  selectedShipment: Shipment;
  selectSpotNegotiation: (spotNegotiation: SpotNegotiation) => void;
  selectQuote: (quote: Quote) => void;
  hazmatCodes: Hazmat;
  selectFormShipment: (shipment: ReturnType<typeof transformShipmentToForm>) => void;
}

const DashboardSummaryBids = ({
  company,
  featureFlags,
  selectedShipment,
  selectSpotNegotiation,
  selectQuote,
  hazmatCodes,
  selectFormShipment,
  router
}: DashboardSummaryBidsProps) => {
  const {spotNegotiations} = useGetShipmentSpotNegotiations({
    shipmentId: selectedShipment.id,
    refetchOnWindowFocus: true,
    omitOwnSpotNegotiations: true
  });

  //mostly copy pasta from old MarketplaceDetails
  const handleSelectQuote = (quote: Quote) => {
    selectQuote(quote);
    const mode = quote.mode || {id: 1, code: 'FTL', description: 'Full Truckload'}; //default FTL
    const equipmentTypes = quote.equipment_type || {id: 1, name: 'Dry Van', machine_readable: 'DRY_VAN'}; //default dry van
    const shipment = transformShipmentToForm(
      JSON.parse(JSON.stringify(selectedShipment)),
      hazmatCodes,
      [mode],
      [equipmentTypes],
      true
    );
    selectFormShipment(shipment);
    //send to confirm page to complete the rest of the shipment details
    router.push(`/shipments/${selectedShipment.id}/confirm?mode=bids`);
  };

  //more copy pasta from old MarketplaceDetails
  const handleSelectSpotNegotiation = (spotNegotiation: SpotNegotiation) => {
    //unsure if needed as product wants to open link in new tab, losing redux state in new tab
    selectSpotNegotiation(spotNegotiation);
    if (spotNegotiation.id) {
      window.open(`/marketplace/${selectedShipment.id}/bids/${spotNegotiation.id}`, '_blank');
    }
  };

  return (
    <div className="space-y-4 p-4">
      <Card title="Requested Bids" bodyClassName="grid m-0 p-0" isCollapsible draggableProvided={null}>
        <CollapsibleCardContent>
          <div className="overflow-hidden">
            <MarketplaceBids
              company={company}
              featureFlags={featureFlags}
              selectedShipment={selectedShipment}
              shipmentSpotNegotiations={spotNegotiations}
              compactView
              handleSelect={handleSelectQuote}
              handleSelectSpotNegotiation={handleSelectSpotNegotiation}
            />
          </div>
        </CollapsibleCardContent>
      </Card>
      <Card title="Tenders" isCollapsible bodyClassName="grid m-0 p-0" draggableProvided={null}>
        <CollapsibleCardContent>
          <MarketplaceTenders selectedShipment={selectedShipment} compactView />
        </CollapsibleCardContent>
      </Card>
    </div>
  );
};

export default compose<DashboardSummaryBidsProps, DashboardSummaryBidsProps>(
  connect(
    (state: State) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      hazmatCodes: state.shipments.hazmatCodes
    }),
    {...marketplaceActions, ...shipmentActions}
  ),
  withRouter
)(DashboardSummaryBids);
