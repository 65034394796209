import {FormikSelect} from '@shipwell/shipwell-ui';
import {Field, useFormikContext} from 'formik';
import {Product} from '@shipwell/backend-core-sdk';
import {NewStageFormValues} from 'App/containers/shipments/components/DashboardSummary/utils';
import {useProductFieldHelpers} from 'App/formComponents/formSections/LineItemFields/hooks/useProductFieldHelpers';
export const LineItemProductLookupField = ({
  index,
  onProductSelect
}: {
  index: number;
  onProductSelect: (index: number, value: Product) => void;
}) => {
  const {values} = useFormikContext<NewStageFormValues>();
  const {filterProductsDebounced, setProductValues} = useProductFieldHelpers();

  const LineItemProductRef = values.line_items?.[index]?.product_ref;
  const lineItemId = values.line_items?.[index]?.id || '';
  return (
    <Field
      name={`line_items[${index}].product_ref`}
      component={FormikSelect}
      label="Product"
      async
      clearable
      defaultOptions
      loadOptions={filterProductsDebounced}
      simpleValue
      defaultValue={LineItemProductRef ? {product_ref: LineItemProductRef} : ''}
      onChange={(productId: string) =>
        setProductValues({
          productId,
          index,
          replace: onProductSelect,
          lineItemId
        })
      }
      getOptionLabel={(option: Product) => option.product_ref}
      getOptionValue={(option: Product) => option.id}
    />
  );
};
