import {useEffect, useState} from 'react';
import {FormikCheckbox} from '@shipwell/shipwell-ui';
import {useFormikContext, FieldArray, Field} from 'formik';
import classNames from 'classnames';
import {ChargeLineItemCategory, Shipment} from '@shipwell/backend-core-sdk';
import {formatCurrency} from 'App/utils/internationalConstants';
import {useSortedChargeCategories} from 'App/data-hooks';
import Loader from 'App/common/shipwellLoader';
import {useGetFullShipmentDetails} from 'App/containers/alertsDashboard/utils/hooks/useGetFullShipmentDetails';
import {BillChargeLineItem, ShipmentBillingFormData} from 'App/containers/Shipment/forms/ShipmentBillingForm';
import {calculateGrandTotal} from 'App/containers/Shipment/utils/financials';
import {LineItemDisplayCell} from 'App/containers/settlements/freightInvoices/components/FreightInvoiceForm/FinancialLineItems';

const CHARGE_LINE_ITEM_HEADERS = ['', 'LINE ITEM', 'DESCRIPTION', 'COST'];

export const generateFinancialsLineItemRow = (
  lineItem: BillChargeLineItem,
  index: number,
  chargeCategoryData?: ChargeLineItemCategory[]
) => [
  <Field component={FormikCheckbox} key={lineItem?.id} name={`charge_line_items[${index}].add_to_bill`} />,
  chargeCategoryData?.find((category) => category.id === lineItem.category)?.name || '--',
  lineItem?.unit_name || '--',
  <div className="text-right" key={lineItem?.id}>
    {lineItem?.amount
      ? formatCurrency(lineItem?.amount - (lineItem?.prepaid_amount || 0), lineItem?.unit_amount_currency)
      : '--'}
  </div>
];

const ChargeLineItemRowDisplay = ({
  shipmentCurrencyOfRecord
}: {
  shipmentCurrencyOfRecord: Shipment['preferred_currency'];
}) => {
  const {values} = useFormikContext<ShipmentBillingFormData>();
  const [billTotal, setBillTotal] = useState<string | 0>();
  const {data: chargeCategoryData} = useSortedChargeCategories();
  const selectedChargeLineItems = values.charge_line_items.filter((lineItem) => lineItem.add_to_bill);

  useEffect(() => {
    const selectedChargeLineItemsBillTotal = calculateGrandTotal(selectedChargeLineItems);
    setBillTotal(selectedChargeLineItemsBillTotal);
  }, [selectedChargeLineItems]);

  return (
    <>
      <div className={classNames('grid grid-cols-[5%,30%,40%,23%] items-center gap-x-1 pb-2')}>
        {values.charge_line_items.map((lineItem, index) => {
          const lineItemDisplayValues = generateFinancialsLineItemRow(lineItem, index, chargeCategoryData);
          return lineItemDisplayValues.map((dataToDisplay, displayValIndex) => (
            <div className="w-full truncate text-left" key={displayValIndex}>
              <LineItemDisplayCell>{dataToDisplay}</LineItemDisplayCell>
            </div>
          ));
        })}
      </div>
      <div className="my-2 border-t-1 border-sw-disabled-alternate"></div>
      <div className="align-center flex flex-col text-end">
        <div className="pt-2 text-xxs uppercase">Bill total</div>
        <div className="text-sm font-bold">{formatCurrency(billTotal, shipmentCurrencyOfRecord)}</div>
      </div>
    </>
  );
};

const ChargeLineItems = ({shipmentId}: {shipmentId: string}) => {
  const {values} = useFormikContext<ShipmentBillingFormData>();
  const {isLoading: chargeCategoryQueryLoading} = useSortedChargeCategories();
  const {
    queryInfo: {data: shipment, isLoading: isFullShipmentLoading}
  } = useGetFullShipmentDetails(shipmentId);

  if (chargeCategoryQueryLoading || isFullShipmentLoading) {
    return <Loader loading additionalClassNames={['w-full h-[10rem]']} />;
  }
  const hasFinancialLineItems = values.charge_line_items?.length > 0;
  return hasFinancialLineItems ? (
    <>
      <div className="grid grid-cols-[5%,30%,40%,23%] items-center justify-items-start gap-x-1">
        {CHARGE_LINE_ITEM_HEADERS.map((header: string) => (
          <div
            className={classNames('pt-2 text-xxs', {
              'justify-self-end': header === 'COST'
            })}
            key={header}
          >
            {header}
          </div>
        ))}
      </div>
      <FieldArray
        name="charge_line_items"
        render={() => <ChargeLineItemRowDisplay shipmentCurrencyOfRecord={shipment?.preferred_currency} />}
      />
    </>
  ) : (
    <div className="flex min-h-40 flex-col items-center justify-center">
      <div className="text-lg text-sw-form-helper-text">No Line Items</div>
    </div>
  );
};
export default ChargeLineItems;
