import {
  UsersApi,
  Configuration,
  MeResponse,
  UsersApiCompaniesCompanyIdUsersGetRequest,
  PostCompaniesCompanyidUsers,
  PermissionsApi,
  ShipwellError,
  UsersApiCompaniesCompanyIdUsersUserIdDeleteRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import axios, {AxiosResponse} from 'axios';
import {transformRequestPostCompaniesCompanyidUsersBody} from './utils';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

export type BackendCoreError = ShipwellError;

const usersApi = new UsersApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);
const permissionsApi = new PermissionsApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export function getCompanyUsers(
  companyId: string,
  queryParams: Omit<UsersApiCompaniesCompanyIdUsersGetRequest, 'companyId'>
) {
  return usersApi.companiesCompanyIdUsersGet({companyId, ...queryParams});
}

export function getUserMe(): Promise<AxiosResponse<MeResponse>> {
  return usersApi.authMeGet() as Promise<AxiosResponse<MeResponse>>;
}

export async function getUser({companyId, userId}: Parameters<typeof usersApi.companiesCompanyIdUsersUserIdGet>[0]) {
  const axiosResponse = await usersApi.companiesCompanyIdUsersUserIdGet({companyId, userId});
  return axiosResponse.data;
}

export async function updateUser({
  companyId,
  userId,
  body
}: {
  companyId: string;
  userId: string;
  body: PostCompaniesCompanyidUsers;
}) {
  const axiosResponse = await usersApi.companiesCompanyIdUsersUserIdPut(
    {companyId, userId, body},
    {transformRequest: transformRequestPostCompaniesCompanyidUsersBody}
  );
  return axiosResponse.data;
}

export async function createUser({companyId, body}: {companyId: string; body: PostCompaniesCompanyidUsers}) {
  try {
    const axiosResponse = await usersApi.companiesCompanyIdUsersPost(
      {companyId, body},
      {transformRequest: transformRequestPostCompaniesCompanyidUsersBody}
    );
    return axiosResponse.data;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (axios.isAxiosError(error) && error.response?.data) throw error.response.data;
    throw error;
  }
}

export function getAuthenticationToken(impersonationToken: string) {
  return usersApi.authImpersonationImpersonateGet({impersonationToken});
}

export function getImpersonationTenants() {
  return usersApi.authImpersonationTenantsGet();
}

export function getImpersonationToken(userId: string) {
  return usersApi.authImpersonationTokenGet({impersonableUserId: userId});
}

export function deleteUser(params: UsersApiCompaniesCompanyIdUsersUserIdDeleteRequest) {
  return usersApi.companiesCompanyIdUsersUserIdDelete(params);
}

export function getPermissions() {
  return permissionsApi.permissionsGet();
}
