//on the optimized table, we use a column id that matches the sorting param,
//so there are some different fields in the legacy and optimized table columns
//that map to the same downloadable column. E.g., equipment_type (the actual sortable
//column id) and equipment (legacy dashboard id) both map to equipment_type_name

/* eslint-disable @typescript-eslint/naming-convention */
export enum AllowedColumnNamesEnum {
  reference_id = 'reference_id',
  multi_stage_id = 'multi_stage_id',
  number_of_stages = 'stages',
  status = 'state',
  customer = 'customer_name',
  pickup_location = 'pickup',
  pickup_date = 'pickup_date',
  pickup = 'pickup_date',
  delivery_location = 'delivery',
  delivery_date = 'delivery_date',
  dropoff = 'delivery_date',
  dropoff_location = 'delivery',
  mode = 'mode_code',
  equipment_type = 'equipment_type_name',
  equipment = 'equipment_type_name',
  weight = 'total_weight_lbs',
  createdAt = 'created_at',
  created_at = 'created_at',
  workflow_execution_info = 'workflows',
  trailer_name = 'trailer_name',
  total_miles = 'total_miles',
  timeline_last_updated_at = 'timeline_last_updated_at',
  tender_status = 'tender_status',
  service_level = 'service_level',
  seal_number = 'drayage_seal_number',
  drayage_seal_number = 'drayage_seal_number',
  release_date = 'drayage_release_date',
  provider_financials = 'provider_financials',
  pro = 'pro_number',
  pro_number = 'pro_number',
  po = 'purchase_order_number',
  po_number = 'purchase_order_number',
  power_unit_name = 'power_unit',
  pickup_number = 'pickup_number',
  origin_company = 'pickup_company',
  pickup_company = 'pickup_company',
  net_financials = 'net_financials',
  name = 'name',
  max_buy_amount = 'max_buy',
  markup = 'markup',
  lowest_bid = 'lowest_bid',
  load_board_id = 'load_board_id',
  last_free_date = 'drayage_last_free_date',
  house_bol_number = 'drayage_house_bol_number',
  financials = 'carrier_financials',
  drayage_estimated_arrival_date = 'drayage_estimated_arrival_date',
  estimated_arrival_date = 'drayage_estimated_arrival_date',
  destination_company = 'delivery_company',
  delivery_company = 'delivery_company',
  customer_reference_number = 'customer_reference_number',
  customer_financials = 'customer_financials',
  accessorials = 'accessorials',
  created_by = 'created_by',
  container_return_date = 'drayage_container_return_date',
  container_number = 'drayage_container_number',
  carrier_status = 'carrier_status',
  buy_it_now_amount = 'book_now',
  bol_number = 'bol_number',
  carrier = 'carrier',
  custom_data = 'custom_data'
}

export enum AllowedOrderingEnum {
  accessorials = 'accessorials',
  bol_number = 'bol_number',
  createdAt = 'created_at',
  created_by = 'created_by_user',
  customer_reference_number = 'customer_reference_number',
  container_number = 'drayage_container_number',
  container_return_date = 'drayage_container_return_date',
  estimated_arrival_date = 'drayage_estimated_arrival_date',
  house_bol_number = 'drayage_house_bol_number',
  last_free_date = 'drayage_last_free_date',
  release_date = 'drayage_release_date',
  seal_number = 'drayage_seal_number',
  equipment_type = 'equipment_type',
  line_items = 'line_items',
  lowest_bid = 'lowest_carrier_bid_amount',
  mode = 'mode',
  name = 'name',
  notes = 'notes',
  pickup_number = 'pickup_number',
  pro = 'pro_number',
  po = 'purchase_order_number',
  reference_id = 'reference_id',
  reps = 'reps',
  service_level = 'service_level',
  status = 'state',
  stops = 'stops',
  total_miles = 'total_miles',
  updated_at = 'updated_at'
}
