import {ShipmentStateEnum} from '@shipwell/backend-core-sdk';
import {SvgIcon} from '@shipwell/shipwell-ui';

interface TrackingBannerProps {
  status?: ShipmentStateEnum;
}

export const TRACKING_BANNER_STATUSES: ShipmentStateEnum[] = [
  ShipmentStateEnum.AppointmentPending,
  ShipmentStateEnum.AuctionRunning,
  ShipmentStateEnum.Cancelled,
  ShipmentStateEnum.CarrierConfirmed,
  ShipmentStateEnum.Draft,
  ShipmentStateEnum.QuoteAccepted,
  ShipmentStateEnum.Quoting,
  ShipmentStateEnum.Scheduled,
  ShipmentStateEnum.Tendered
];

export default function TrackingBanner({status}: TrackingBannerProps) {
  if (!status || !TRACKING_BANNER_STATUSES.includes(status)) {
    return null;
  }

  return (
    <div className="my-4 rounded bg-sw-active-light p-4">
      <div className="grid grid-flow-col grid-cols-[5%,95%] justify-center">
        <div className="flex justify-center">
          <SvgIcon name="ErrorFilled" color="$sw-active" />
        </div>
        <div>
          <div className="font-bold">Preparing for shipping, not yet dispatched</div>
        </div>
      </div>
    </div>
  );
}
