import find from 'lodash/find';
import {integrationsConstants} from 'App/containers/integrations/constants';

export type IntegrationDetailsComponentProps = {
  integration: string;
};
/**
 * Performs a lookup in the integrationConstants object to find the appropriate capacity provider
 * card to display for the user. If no card is present then `null` is returned.
 */
const IntegrationDetailsComponent = (props: IntegrationDetailsComponentProps): JSX.Element | null => {
  const IntegrationDetails = find(
    integrationsConstants,
    (integrationsConstant) => integrationsConstant.slug === props.integration
  );

  const DetailsComponent = IntegrationDetails?.detailsComponent;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return DetailsComponent ? <DetailsComponent {...props} /> : null;
};

export default IntegrationDetailsComponent;
