import {useEffect, useState} from 'react';
import {WithRouterProps} from 'react-router';
import {isSupplier} from '../orders/utils';
import LegacyDashboard from 'App/containers/Dashboard/Dashboard';
import {DashboardRoute} from 'App/containers/Dashboard/DashboardRoute';
import ShipwellLoader from 'App/common/shipwellLoader';
import {useUserMe} from 'App/data-hooks';

export const OPTIMIZED_DASHBOARD_LOCALSTORAGE_KEY = 'optimizedShippingDashboardUi';

export const FeatureFlaggedDashboard = (props: WithRouterProps) => {
  const user = useUserMe();
  if (isSupplier(user.data?.company?.name || '')) {
    props.router.push('/purchase-orders');
  }
  const isInLocalstorage = localStorage.getItem(OPTIMIZED_DASHBOARD_LOCALSTORAGE_KEY) === 'true';
  const [isOptimizedDashboardUi, setIsOptimizedDashboardUi] = useState(isInLocalstorage);
  const [isLoading, setIsLoading] = useState(false);

  const onUpdateFlag = () => {
    setIsOptimizedDashboardUi((prevUi) => !prevUi);
    // if switching TO legacy dashboard, reload the page for better UX
    if (isOptimizedDashboardUi) {
      setIsLoading(true);
      // using router instead of window.location.reload() to clear incompatible query params
      props.router.push('/dashboard');
    }
  };

  useEffect(() => {
    // update localstorage with up to date value for isOptimizedDashboardUi
    localStorage.setItem(OPTIMIZED_DASHBOARD_LOCALSTORAGE_KEY, isOptimizedDashboardUi.toString());
    // to ensure an event is triggered which withLaunchDarkly is listening for
    window.dispatchEvent(new Event('storage'));
    setIsLoading(false);
  }, [isOptimizedDashboardUi]);

  if (isLoading) {
    return (
      <div className="h-screen-minus-16">
        <ShipwellLoader loading />
      </div>
    );
  }
  return (
    <>
      {isOptimizedDashboardUi ? (
        <DashboardRoute onUpdateDashboardFlag={onUpdateFlag} />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <LegacyDashboard {...props} onUpdateDashboardFlag={onUpdateFlag} />
      )}
    </>
  );
};
