import {Loader, SvgIcon} from '@shipwell/shipwell-ui';

export const IframeContainer = ({
  url,
  name,
  isLoading,
  setIsIframeOpen,
  setTemplateId
}: {
  url?: string;
  name?: string;
  isLoading: boolean;
  setIsIframeOpen: (isIframeOpen: boolean) => void;
  setTemplateId: (isIframeOpen: string) => void;
}) => {
  const navBarHeight = 64;
  return (
    <div
      // The reason for disabing this eslint rule is that I usually set the dynamic classes at the begining but in this case if I set top in the first or second spot it does not work.
      // eslint-disable-next-line tailwindcss/classnames-order
      className={`h-[calc(100vh-${navBarHeight}px)] bg-sw-divider fixed left-0 top-[${navBarHeight}px] z-[199] w-screen`}
    >
      <div className="bg-sw-divider">
        <div
          className="flex h-10 cursor-pointer items-center justify-start gap-x-4 pl-5"
          onClick={() => {
            setIsIframeOpen(false);
            setTemplateId('');
          }}
        >
          <SvgIcon name="ArrowBack" />
          <span className="text-base font-medium">{name}</span>
        </div>
      </div>
      {isLoading ? (
        <Loader show> </Loader>
      ) : (
        <iframe
          title={name}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            minHeight: '600px'
          }}
          src={url}
        />
      )}
    </div>
  );
};
