import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions} from '@tanstack/react-query';
import type {MeResponse} from '@shipwell/backend-core-sdk';
import {createContext, useContext} from 'react';
import {Banner} from '@shipwell/shipwell-ui';
import {getUserMe} from 'App/api/user/typed';
import {USER_ME_QUERY_KEY} from 'App/data-hooks/queryKeys';
import ShipwellLoader from 'App/common/shipwellLoader';

export type UseUserMeOptions = Omit<
  UseQueryOptions<MeResponse, unknown, MeResponse, string[]>,
  'queryKey' | 'queryFn' | 'cacheTime' | 'staleTime'
>;

function useUserMe(options?: UseUserMeOptions) {
  let query = useQuery(
    [USER_ME_QUERY_KEY],
    async () => {
      const response = await getUserMe();
      return response?.data;
    },
    {
      refetchOnMount: true,
      ...options,
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
  if (!query.data) {
    query = {...query};
    query.isLoading = true;
  }
  return {
    ...query,
    company: query?.data?.company
  };
}

const UserMeContext = createContext<MeResponse | null>(null);

export const UserMeContextProvider = ({children}: {children: React.ReactNode}) => {
  const {data, isLoading} = useUserMe();

  if (data) {
    return <UserMeContext.Provider value={data}>{children}</UserMeContext.Provider>;
  }

  if (isLoading) return <ShipwellLoader loading />;

  return <Banner title={'Error'}>There was an error loading your user information. Try reloading the page.</Banner>;
};

export const useUserMeContext = () => {
  const meResponse = useContext(UserMeContext);
  if (!meResponse) {
    throw new Error('useUserMeContext must be used within a UserMeContextProvider');
  }
  return meResponse;
};

export default useUserMe;
