import {QuoteCurrencyEnum, ShipmentMode} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Rate, RateStatus, TransportationMode} from '@shipwell/genesis-sdk';
import {CombinedQuote} from '../types/combinedQuote';
import {purolatorServiceCodes} from 'App/utils/parcelConstantsTyped';

/**
 * A function that get a Genesis Rate object and transform into a CombinedQuote (Combined Quote is Rate + RQF joined by commom params)
 * We need to match this two cause currently we are using two source of truth for the rates, the RQF (be-core) and the Rate (Genesis SDK)
 * In the future, everything should look like Genesis SDK rate, so I hope we don't need to do all this combinations.
 */
export const transformGenesisRateIntoCombinedQuote = (genesisRate: Rate): CombinedQuote => {
  const isErrored = genesisRate.status === RateStatus.Errored;

  const serviceLabel =
    purolatorServiceCodes.find((service) => service.id === genesisRate?.service_code)?.name || 'Custom';

  return {
    imageUrl: `/images/${genesisRate.provider_code}_logo.svg`,
    serviceLevelLabel: serviceLabel,
    // if there's an error, total must be undefined to be sorted last on the rates table
    total: isErrored ? undefined : genesisRate?.charges?.total_charges?.amount,
    currency: genesisRate.charges?.total_charges.currency as QuoteCurrencyEnum,
    carrier: {
      display_name: genesisRate.provider_code
    },
    // event if we have the error message, we will only display when the rate status is ERRORED.
    error_message: isErrored ? genesisRate.messages?.at(0)?.detail : undefined,
    is_failure: isErrored ? genesisRate.messages?.at(0)?.type === 'ERROR' : false,
    transit_days: genesisRate.transit_time?.transit_days,
    // need to align packageTypes this with backend
    packageTypes: {},
    delivery_date: genesisRate.transit_time?.delivery?.latest,
    isGenesisRate: true,
    mode: mapTransportationModeToShipmentMode(genesisRate.transportation_mode)
  };
};

const mapTransportationModeToShipmentMode = (transportationMode: TransportationMode) => {
  return shipmentModes.find((mode) => mode.code === transportationMode) as ShipmentMode;
};

const shipmentModes = [
  {
    id: 1,
    code: 'FTL',
    description: 'Full Truckload'
  },
  {
    id: 2,
    code: 'LTL',
    description: 'Less Than Truckload'
  },
  {
    id: 4,
    code: 'VLTL',
    description: 'Volume Less Than Truckload'
  },
  {
    id: 5,
    code: 'DRAYAGE',
    description: 'Drayage'
  },
  {
    id: 6,
    code: 'PARCEL',
    description: 'Parcel'
  },
  {
    id: 7,
    code: 'INTERMODAL',
    description: 'Intermodal'
  },
  {
    id: 8,
    code: 'RAIL_CAR',
    description: 'Rail'
  }
] as ShipmentMode[];
