import PropTypes from 'prop-types';
import {DisplayValue} from '@shipwell/shipwell-ui';
import {formatCurrency} from 'App/utils/internationalConstants';
import {RATE_TYPE_PER_MILE, RATE_TYPE_RATE_TABLE} from 'App/containers/userCompany/rateTables/constants';
import {useGetContract} from 'App/data-hooks/contracts/useGetContract';
import {toTitleCase} from 'App/utils/string';

const RoutingGuideActionDetails = ({action, selectedShipment}) => {
  const contractId = action.contract_id?.value;
  const {contractData, isLoading: isLoadingContract} = useGetContract({contractId});

  const formatCarrierPOC = (carriers) => {
    return carriers.map((carrier) => {
      return <p key={carrier.id}>{carrier.label}</p>;
    });
  };

  const rate = action.rate || contractData?.rate;
  const rateType = action.rate_type || contractData?.rate_type;
  const rateCurrency = action.rate_currency || contractData?.rate_currency;

  return (
    <div className="routingGuides__execute-details grid">
      <div className="grid-1-1">
        <DisplayValue label="action">{action.type.label}</DisplayValue>
      </div>
      {action.type.value === 'TENDER' ? (
        <>
          <div className="grid-1-1">
            <DisplayValue label="carrier">{action.tender_to_company.shipwell_vendor?.name || '--'}</DisplayValue>
          </div>
          <div className="grid-1-1">
            <DisplayValue label="users">
              <ul>
                {action.involved_tender_to_company_users.map((e) => (
                  <li key={e.label}>{e.label || '--'}</li>
                ))}
              </ul>
            </DisplayValue>
          </div>
          <div className="grid-1-2">
            <DisplayValue label="mode">{action.mode?.code}</DisplayValue>
            <DisplayValue label="equipment">{action.equipment_type?.name}</DisplayValue>
          </div>
          <div className="grid-1-2">
            <DisplayValue label="rate">
              {isLoadingContract
                ? '--'
                : contractId && rateType === RATE_TYPE_RATE_TABLE
                ? 'Rate Table'
                : rateType === RATE_TYPE_PER_MILE
                ? selectedShipment?.total_miles
                  ? formatCurrency(rate * selectedShipment?.total_miles, rateCurrency)
                  : `${formatCurrency(rate, rateCurrency)} per ${toTitleCase(contractData?.distance_unit) || 'Mile'}`
                : formatCurrency(rate, rateCurrency)}
            </DisplayValue>
            <DisplayValue label="expiration">{action.expires_after_seconds.label}</DisplayValue>
          </div>
          <div className="grid-1-1">
            <DisplayValue label="special instructions">{action.info_message ? action.info_message : '--'}</DisplayValue>
          </div>
        </>
      ) : action.type.value === 'SEND_EMAIL' ? (
        <>
          <div className="grid-1-1">
            <DisplayValue label="recipients">{action.recipients.map((e) => e.label).join(', ')}</DisplayValue>
          </div>
          <div className="grid-1-1">
            <DisplayValue label="subject">{action.subject}</DisplayValue>
          </div>
          <div className="grid-1-1">
            <DisplayValue label="message">{action.message}</DisplayValue>
          </div>
        </>
      ) : action.type.value === 'POST_TO_LOADBOARD' ? (
        <>
          <div className="grid-1-1">
            <DisplayValue label="book now rate">
              {formatCurrency(action.buy_it_now_amount, action.buy_it_now_amount_currency)}
            </DisplayValue>
          </div>
          <div className="grid-1-1">
            <DisplayValue label="step timer">{action.step_timer.label}</DisplayValue>
          </div>
        </>
      ) : action.type.value === 'CREATE_SPOT_NEGOTIATIONS' ? (
        <>
          <div className="grid-1-1">
            <DisplayValue label="carriers">{formatCarrierPOC(action.carriers)}</DisplayValue>
          </div>
          <div className="grid-1-1">
            <DisplayValue label="book now rate">
              {formatCurrency(action.buy_it_now_amount, action.buy_it_now_amount_currency)}
            </DisplayValue>
          </div>
          <div className="grid-1-1">
            <DisplayValue label="step timer">{action.step_timer.label}</DisplayValue>
          </div>
          <div className="grid-1-1">
            <DisplayValue label="special instructions">{action.special_instructions || '--'}</DisplayValue>
          </div>
        </>
      ) : null}
    </div>
  );
};

RoutingGuideActionDetails.propTypes = {
  action: PropTypes.shape({
    buy_it_now_amount: PropTypes.any,
    buy_it_now_amount_currency: PropTypes.string,
    carriers: PropTypes.array,
    contract_id: PropTypes.shape({
      value: PropTypes.string
    }),
    equipment_type: PropTypes.shape({
      name: PropTypes.string
    }),
    expires_after_seconds: PropTypes.shape({
      label: PropTypes.string
    }),
    info_message: PropTypes.string,
    involved_tender_to_company_users: PropTypes.array,
    message: PropTypes.string,
    mode: PropTypes.shape({
      code: PropTypes.string
    }),
    rate: PropTypes.number,
    rate_currency: PropTypes.string,
    rate_type: PropTypes.string,
    recipients: PropTypes.array,
    special_instructions: PropTypes.string,
    step_timer: PropTypes.shape({
      label: PropTypes.any
    }),
    subject: PropTypes.any,
    tender_to_company: PropTypes.shape({
      shipwell_vendor: PropTypes.shape({
        name: PropTypes.string
      })
    }),
    type: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  }),
  selectedShipment: PropTypes.shape({
    total_miles: PropTypes.number
  })
};

export default RoutingGuideActionDetails;
