import {
  REQUEST_USER_COMPANY_PENDING,
  REQUEST_USER_COMPANY_SUCCESS,
  REQUEST_USER_COMPANY_ERROR,
  REQUEST_USER_COMPANY_PREFERENCES_PENDING,
  REQUEST_USER_COMPANY_PREFERENCES_SUCCESS,
  REQUEST_USER_COMPANY_PREFERENCES_ERROR
} from './actions';
import {countryDefaults} from 'App/utils/internationalConstants';

/** Initial state */
export const DEFAULT_STATE = {
  isFetching: false,
  isUpdating: false,
  error: false,
  company: {},
  feature_flags: {},
  preferences: {},
  unitPreferences: countryDefaults.find((e) => e.country === 'US').unitPreferences // default to US
};

export default (state = DEFAULT_STATE, action = {}) => {
  const {type, payload} = action;
  switch (type) {
    case REQUEST_USER_COMPANY_PENDING:
      return {
        ...state,
        isFetching: true
      };
    case REQUEST_USER_COMPANY_SUCCESS: {
      const {feature_flags} = payload;
      return {
        ...state,
        isFetching: false,
        company: payload,
        feature_flags
      };
    }
    case REQUEST_USER_COMPANY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true
      };
    case REQUEST_USER_COMPANY_PREFERENCES_PENDING:
      return {
        ...state,
        isFetching: true
      };
    case REQUEST_USER_COMPANY_PREFERENCES_SUCCESS: {
      const {country} = payload;
      const {unitPreferences} = countryDefaults.find((e) => e.country === country);
      const {currency} = unitPreferences;
      return {
        ...state,
        isFetching: false,
        preferences: payload,
        country,
        unitPreferences,
        currency
      };
    }
    case REQUEST_USER_COMPANY_PREFERENCES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true
      };

    default:
      return state;
  }
};
