import {IconButtonWithTooltip} from '@shipwell/shipwell-ui';
import {ShipmentChargeLineItem, Shipment, CustomField} from '@shipwell/backend-core-singlerequestparam-sdk';
import {InfoTooltip} from './components';
import {CompanyName, FinancialsTableHeader, TextCell} from './layouts';

const tooltipContent =
  "These are your carrier's financials for this shipment. You can view these to aid in resolving disputes, but you won't be able to change them. Rows are highlighted when they differ between parties.";

export const VendorTableHeader = ({
  isEditable,
  isEditing,
  onEditClick,
  onCopyClick,
  vendorAssignment,
  is_quoting_limited_user,
  theirVendorCharges,
  myVendorCharges,
  companyName,
  customFields
}: {
  isEditable: boolean;
  isEditing: boolean;
  onEditClick?: () => void;
  onCopyClick?: () => void;
  vendorAssignment?: Shipment['relationship_to_vendor'];
  is_quoting_limited_user?: boolean;
  theirVendorCharges?: ShipmentChargeLineItem[];
  myVendorCharges?: ShipmentChargeLineItem[];
  companyName?: string;
  customFields: CustomField[];
}) => {
  const vendorName = vendorAssignment?.vendor?.name;

  const showCopyButton =
    vendorAssignment && isEditable && !is_quoting_limited_user && theirVendorCharges && theirVendorCharges?.length > 0;

  const hasMyVendorCharges = myVendorCharges && myVendorCharges.length > 0;
  const hasTheirVendorCharges = theirVendorCharges && theirVendorCharges.length > 0;

  const visibleCustomFields = customFields?.filter((customField) => customField.ui_enabled);

  return (
    <FinancialsTableHeader columnCount={visibleCustomFields.length + 4}>
      <div className="flex items-center gap-2 overflow-hidden text-ellipsis text-base font-medium">
        Carrier
        {isEditable && <IconButtonWithTooltip aria-label="Edit financials" onClick={onEditClick} iconName="Edit" />}
      </div>

      {!isEditing ? <TextCell>Description</TextCell> : <div />}

      {!isEditing ? (
        visibleCustomFields.map((customField) => <TextCell key={customField.id}>{customField.label}</TextCell>)
      ) : (
        <div />
      )}

      {!isEditing ? (
        <div className="flex items-center justify-end gap-2">
          {vendorAssignment && <InfoTooltip aria-label={tooltipContent}>{tooltipContent}</InfoTooltip>}
          <CompanyName>{vendorName}</CompanyName>
        </div>
      ) : (
        <div />
      )}

      {!isEditing ? (
        <div className="flex items-center justify-between">
          {showCopyButton ? (
            <div className="px-4">
              <IconButtonWithTooltip
                aria-label="Copy financials from my carrier"
                onClick={onCopyClick}
                iconName="ArrowForward"
              />
            </div>
          ) : null}

          {(vendorAssignment || hasMyVendorCharges || hasTheirVendorCharges) && (
            <CompanyName>{companyName}</CompanyName>
          )}
        </div>
      ) : (
        <div />
      )}
    </FinancialsTableHeader>
  );
};
