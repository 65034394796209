import {USER_DASHBOARD_CONFIG_REQUEST_PENDING, USER_DASHBOARD_CONFIG_REQUEST_SUCCESS} from '../../../actions/types';

export const ALL_DASHBOARD_COLUMNS = [
  'reference_id',
  'status',
  'customer',
  'customer_reference_number',
  'vendor',
  'reps',
  'pickup_location',
  'origin_company',
  'pickup_date',
  'delivery_location',
  'destination_company',
  'delivery_date',
  'stops',
  'account_id',
  'mode',
  'equipment',
  'timeline_last_updated_at',
  'total_miles',
  'line_items',
  'weight',
  'financials',
  'customer_financials',
  'provider_financials',
  'net_financials',
  'markup',
  'max_buy_amount',
  'notes',
  'service_level',
  'bol',
  'po',
  'pickup_number',
  'pro',
  'container_number',
  'load_board_id',
  'buy_it_now_amount',
  'target_rate_amount',
  'createdAt',
  'name',
  'carrier_status',
  'created_by',
  'accessorials',
  'actions',
  'upcoming_etas',
  'trailer_name',
  'workflow_execution_info',
  'seal_number',
  'house_bol_number',
  'number_of_stages',
  'multi_stage_id',
  'estimated_arrival_date',
  'release_date',
  'last_free_date',
  'container_return_date',
  'auction_stats',
  'lowest_bid',
  'power_unit_name',
  'tender_status'
];

const DEFAULT_COLUMNS = [
  'reference_id',
  'multi_stage_id',
  'number_of_stages',
  'status',
  'customer',
  'pickup_location',
  'pickup_date',
  'delivery_location',
  'delivery_date',
  'stops',
  'mode',
  'equipment',
  'weight',
  'createdAt',
  'actions'
];

export const DASHBOARD_COLUMN_NAMES = {
  reference_id: 'ID',
  status: 'Status',
  customer: 'Customer',
  customer_reference_number: 'Customer Reference #',
  vendor: 'Carrier',
  reps: 'Reps',
  pickup_location: 'Pickup',
  pickup_date: 'Pickup Date',
  delivery_location: 'Delivery',
  delivery_date: 'Delivery Date',
  stops: 'Stops',
  account_id: 'Account Id',
  mode: 'Mode',
  equipment: 'Equipment',
  line_items: 'Items',
  weight: 'Weight',
  financials: 'Financials',
  customer_financials: 'Customer Financials',
  provider_financials: 'Provider Financials',
  net_financials: 'Net Financials',
  notes: 'Notes',
  bol: 'BOL #',
  po: 'PO #',
  pickup_number: 'Pickup #',
  pro: 'PRO #',
  container_number: 'Container #',
  service_level: 'Service Level',
  origin_company: 'Pickup Company',
  destination_company: 'Delivery Company',
  timeline_last_updated_at: 'Timeline Updated',
  total_miles: 'Miles',
  markup: 'Markup',
  max_buy_amount: 'Max Buy',
  load_board_id: 'Load Board ID',
  buy_it_now_amount: 'Book Now',
  target_rate_amount: 'Target Rate',
  load_board_enabled: 'Load Board',
  createdAt: 'Created',
  name: 'Name',
  carrier_status: 'Carrier Status',
  created_by: 'Created By',
  accessorials: 'Shipment Accessorials',
  actions: 'Actions',
  trailer_name: 'Trailer #',
  upcoming_etas: 'Upcoming ETA(s)',
  workflow_execution_info: 'Workflows',
  seal_number: 'Seal #',
  house_bol_number: 'House BOL #',
  number_of_stages: 'Stages',
  multi_stage_id: 'Multi-Stage ID',
  estimated_arrival_date: 'Estimated Container Arrival',
  release_date: 'Release Date',
  last_free_date: 'Last Free Date',
  container_return_date: 'Container Return Date',
  auction_stats: 'Auction Stats',
  lowest_bid: 'Lowest Bid',
  rail_car_status: 'Rail Car Status',
  rail_car_number: 'Rail Car Number',
  power_unit_name: 'Power Unit',
  tender_status: 'Tender Status'
};

export const FILTERABLE_DASHBOARD_COLUMNS = [
  'archived',
  'tags',
  'alerts',
  'bill',
  'invoice',
  'status',
  'pickup_location',
  'delivery_location',
  'vendor',
  'customer',
  'customer_reference_number',
  'stops',
  'pickup_date',
  'delivery_date',
  'mode',
  'reps',
  'equipment',
  'service_level',
  'load_board_enabled',
  'createdAt',
  'created_by',
  'accessorials',
  'origin_company',
  'destination_company',
  'workflow_execution_info',
  'container_number',
  'seal_number',
  'estimated_arrival_date',
  'release_date',
  'last_free_date',
  'container_return_date',
  'custom_reference_data',
  'rail_car_status',
  'rail_car_number',
  'tender_status'
];

const INITIAL_STATE = {
  isFetching: false,
  isSaving: false,
  allColumns: ALL_DASHBOARD_COLUMNS,
  defaultColumns: DEFAULT_COLUMNS,
  defaultConfig: null,
  configs: [],
  configsById: {},
  columnNamesById: DASHBOARD_COLUMN_NAMES
};

export default (state = INITIAL_STATE, action = {}) => {
  const {type, payload} = action;

  switch (type) {
    /**
     * User dashboard request actions
     */
    case USER_DASHBOARD_CONFIG_REQUEST_PENDING:
      return Object.assign({}, state, {
        isFetching: true
      });

    case USER_DASHBOARD_CONFIG_REQUEST_SUCCESS:
      const results = payload && payload.results;
      const defaultConfig = results.filter((e) => e.is_default)[0];
      return Object.assign({}, state, {
        isFetching: false,
        configs: results,
        defaultConfig: defaultConfig,
        configsById: results.reduce((map, result) => {
          result.config.columns = !result.config.columns.length ? ALL_DASHBOARD_COLUMNS : result.config.columns;
          return Object.assign({}, map, {[result.id]: result});
        }, {})
      });

    /**
     * User dashboard update actions
     */
    case USER_DASHBOARD_CONFIG_REQUEST_PENDING:
      return Object.assign({}, state, {
        isSaving: true
      });

    case USER_DASHBOARD_CONFIG_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isSaving: false,
        configsById: Object.assign({}, state.configsById, {
          [payload.id]: payload
        })
      });

    default:
      return state;
  }
};
