import {useState} from 'react';
import {Paper} from '@material-ui/core';
import {Quote, RFQ} from '@shipwell/backend-core-singlerequestparam-sdk';
import SelectField from 'App/formComponents/fields/select';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {fedexServiceOptions} from 'App/utils/parcelConstants';
import ParcelRateList from 'App/components/parcelRateList';
import {useGetFedExAccountsQuery} from 'App/data-hooks/parcel/FedEx/hooks/useGetFedexAccounts';
import './styles.scss';
import {useGetFedExPackageTypes} from 'App/data-hooks/parcel/FedEx/hooks/useGetFedExPackageTypes';

export const FedExRates = ({
  packageType,
  numberOfPackages,
  rfq,
  quotes,
  handleSelectQuote,
  onAccountChange
}: {
  packageType: string;
  numberOfPackages: number;
  rfq?: RFQ;
  quotes?: {fedex?: Quote[]};
  handleSelectQuote: (quote: Quote) => void;
  onAccountChange?: (selectedAccount: string, provider: string) => void;
}) => {
  const {fedexPackageTypes} = useGetFedExPackageTypes();

  const [account, setAccount] = useState(rfq?.fedex_specific_options?.account || null);
  const {fedExAccounts} = useGetFedExAccountsQuery();
  const accounts = fedExAccounts?.map(({id, account_number}) => ({id: id, name: account_number})) || [];

  const serviceLevels = fedexServiceOptions.reduce((options, option) => {
    const {id, name} = option;
    return {...options, [id]: name};
  }, {});

  const handleAccountChange = (selectedAccount?: string) => {
    if (selectedAccount) {
      setAccount(selectedAccount);
      onAccountChange?.(selectedAccount, 'fedex');
    }
  };

  return (
    <Paper className="fedex-rates">
      <div className="paper__header">
        <img src="/images/fedex_logo.svg" width="90" />
        <div>
          <label>FedEx Account</label>
          <SelectField
            simpleValue
            value={account}
            placeholder="FedEx Account"
            options={accounts}
            onChange={handleAccountChange}
          />
        </div>
      </div>

      {quotes?.fedex && quotes.fedex.length > 0 ? (
        <div className="fedex-rates__table table__container">
          <ParcelRateList
            quotes={quotes.fedex}
            serviceLevels={serviceLevels}
            packageType={packageType}
            packageTypes={fedexPackageTypes}
            numberOfPackages={numberOfPackages}
            handleSelectQuote={handleSelectQuote}
            providerCode="FEDEX"
          />
        </div>
      ) : null}

      {quotes?.fedex && quotes.fedex.length === 0 ? (
        <div className="paper__content text-center ">
          <div className="fedex-rates__noResults">
            <h3>No rates available for this shipment.</h3>
          </div>
        </div>
      ) : null}

      {!quotes || !quotes.fedex ? <ShipwellLoader loading={!quotes || !quotes.fedex} /> : null}
    </Paper>
  );
};
