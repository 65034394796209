import axios from 'axios';
import {REQ_PRODUCTS_DATA, SELECT_PRODUCT, GET_PRODUCTS, ALERT_ERROR, CREATE_PRODUCT} from 'App/actions/types';
import {parseErrors} from 'App/utils/globals';
import {getShipwellApi, basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

// starts the Loading... spinner
function requestData() {
  return {type: REQ_PRODUCTS_DATA};
}

export function selectProduct(product, hazmatCodes) {
  if (product && product.hazmat_identification_number !== null && hazmatCodes) {
    const match = hazmatCodes.filter((e) => e.identification_number == product.hazmat_identification_number);
    if (match.length > 0) {
      product.hazmatDetails = match[0].id;
      product.hazmat = true;
    }
  }
  //remove any NaNs
  if (product) {
    const keys = Object.keys(product);
    for (var i = 0; i < keys.length; i++) {
      if (typeof product[keys[i]] === 'number' && isNaN(product[keys[i]])) {
        product[keys[i]] = null;
      }
    }
  }

  return (dispatch) => {
    dispatch({type: SELECT_PRODUCT, payload: product});
  };
}

/**
 * Return a list of products for a company
 * @param {Object} opts Optional parameters
 * @param {Integer} opts.page indicates what page of a list of query results you want to view
 * @param {Integer} opts.pageSize indicates the how many items to return per page in a list request
 * @param {String} opts.ordering determines which column to sort by. Prepend with &#39;-&#39; to sort desc
 * @param {String} opts.descriptionContains Include products where the description contains this string
 * @param {String} opts.productRefContains Include products where the product ref contains this string
 * @param {String} opts.packageTypeContains Include products where the package type contains this string
 * @param {module:api/shipwellApi~productsGetCallback} callback The callback function, accepting three arguments: error, data, response
 * data is of type: {@link module:model/PaginatedProduct}
 */
export function getProducts(opts) {
  opts = opts || {};
  return (dispatch) => {
    dispatch(requestData());
    return getProductsPromise(opts)
      .then((response) => {
        dispatch({type: GET_PRODUCTS, payload: response});
        return {status: 200, details: response};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: response.error_description
        });
        return {status: 400, details: response};
      });
  };
}

export async function getProductsPromise(opts) {
  const shipwellApi = await getShipwellApi();
  return new Promise(function (resolve, reject) {
    shipwellApi.productsGet(opts, function (err, data, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(data);
      }
    });
  });
}

export async function createProductPromise(body) {
  const accessToken = await getAccessToken();
  return new Promise(function (resolve, reject) {
    const config = {
      transformRequest: [
        function (body) {
          const image = body.image;
          const description = body.description;
          const product_ref = body.product_ref;
          const value_per_piece = body.value_per_piece;
          const value_per_piece_currency = body.value_per_piece_currency;
          const freight_class = body.freight_class;
          const hazmat_hazard_class = body.hazmat_hazard_class;
          const hazmat_identification_number = body.hazmat_identification_number;
          const hazmat_packing_group = body.hazmat_packing_group;
          const hazmat_proper_shipping_name = body.hazmat_proper_shipping_name;
          const height = body.height;
          const length = body.length;
          const length_unit = body.length_unit;
          const nmfc_item_code = body.nmfc_item_code;
          const nmfc_sub_code = body.nmfc_sub_code;
          const package_type = body.package_type;
          const piece_type = body.piece_type;
          const stackable = body.stackable;
          const package_weight = body.package_weight;
          const weight_unit = body.weight_unit;
          const width = body.width;
          const refrigeration_required = body.refrigeration_required;
          const refrigeration_min_temp = body.refrigeration_min_temp;
          const refrigeration_max_temp = body.refrigeration_max_temp;
          const country_of_manufacture = body.country_of_manufacture;
          const product_category = body.product_category;

          const formData = new FormData();
          if (image) {
            formData.append('image', image, product_ref + '.png');
          }
          if (description) {
            formData.append('description', description);
          }
          if (product_ref) {
            formData.append('product_ref', product_ref);
          }
          if (value_per_piece) {
            formData.append('value_per_piece', value_per_piece);
          }
          if (value_per_piece_currency) {
            formData.append('value_per_piece_currency', value_per_piece_currency);
          }
          if (freight_class) {
            formData.append('freight_class', freight_class);
          }
          if (hazmat_hazard_class) {
            formData.append('hazmat_hazard_class', hazmat_hazard_class);
          }
          if (hazmat_identification_number) {
            formData.append('hazmat_identification_number', hazmat_identification_number);
          }
          if (hazmat_packing_group) {
            formData.append('hazmat_packing_group', hazmat_packing_group);
          }
          if (hazmat_proper_shipping_name) {
            formData.append('hazmat_proper_shipping_name', hazmat_proper_shipping_name);
          }
          if (height) {
            formData.append('height', height);
          }
          if (length) {
            formData.append('length', length);
          }
          if (length_unit) {
            formData.append('length_unit', length_unit);
          }
          if (nmfc_item_code) {
            formData.append('nmfc_item_code', nmfc_item_code);
          }
          if (nmfc_sub_code) {
            formData.append('nmfc_sub_code', nmfc_sub_code);
          }
          if (package_type) {
            formData.append('package_type', package_type);
          }
          if (piece_type) {
            formData.append('piece_type', piece_type);
          }
          if (stackable) {
            formData.append('stackable', stackable);
          }
          if (package_weight) {
            formData.append('package_weight', package_weight);
          }
          if (weight_unit) {
            formData.append('weight_unit', weight_unit);
          }
          if (width) {
            formData.append('width', width);
          }
          if (country_of_manufacture) {
            formData.append('country_of_manufacture', country_of_manufacture);
          }
          if (product_category) {
            formData.append('product_category', product_category);
          }
          formData.append('refrigeration_required', Boolean(refrigeration_required));
          formData.append('refrigeration_min_temp', refrigeration_min_temp || '');
          formData.append('refrigeration_max_temp', refrigeration_max_temp || '');
          // if refrigeration required is unchecked, remove any temp values
          if (!refrigeration_required) {
            formData.append('refrigeration_min_temp', '');
            formData.append('refrigeration_max_temp', '');
          }
          return formData;
        }
      ],
      headers: {
        authorization: accessToken,
        'Content-Type': 'multipart/form-data'
      }
    };

    const request = axios.post(basePath + '/products/', body, config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        reject({body: error.response && error.response.data});
      });
  });
}

export function createProduct(body) {
  return (dispatch) => {
    return createProductPromise(body)
      .then((response) => {
        dispatch({type: CREATE_PRODUCT, payload: response.data});
        return {status: 200, body: response.data};
      })
      .catch((response) => {
        return parseErrors(response);
      });
  };
}

export async function editProductPromise(productId, body) {
  const accessToken = await getAccessToken();
  return new Promise(function (resolve, reject) {
    const config = {
      transformRequest: [
        function (body) {
          const image = body.image;
          const description = body.description;
          const product_ref = body.product_ref;
          const value_per_piece = body.value_per_piece;
          const value_per_piece_currency = body.value_per_piece_currency;
          const freight_class = body.freight_class;
          const hazmat_hazard_class = body.hazmat_hazard_class;
          const hazmat_identification_number = body.hazmat_identification_number;
          const hazmat_packing_group = body.hazmat_packing_group;
          const hazmat_proper_shipping_name = body.hazmat_proper_shipping_name;
          const height = body.height;
          const length = body.length;
          const length_unit = body.length_unit;
          const nmfc_item_code = body.nmfc_item_code;
          const nmfc_sub_code = body.nmfc_sub_code;
          const package_type = body.package_type;
          const piece_type = body.piece_type;
          const stackable = body.stackable;
          const package_weight = body.package_weight;
          const weight_unit = body.weight_unit;
          const width = body.width;
          const external_can_quote = body.external_can_quote;
          const external_pickup_address = body.external_pickup_address;
          const max_pieces_per_package = body.max_pieces_per_package;
          const refrigeration_required = body.refrigeration_required;
          const refrigeration_min_temp = body.refrigeration_min_temp;
          const refrigeration_max_temp = body.refrigeration_max_temp;
          const country_of_manufacture = body.country_of_manufacture;

          const formData = new FormData();

          // new images will be of type Blob
          // existing images are a url string that do not need to be updated
          if (image && image instanceof Blob) {
            formData.append('image', image, product_ref + '.png');
          }
          if (description) {
            formData.append('description', description);
          }
          if (product_ref) {
            formData.append('product_ref', product_ref);
          }
          if (value_per_piece) {
            formData.append('value_per_piece', value_per_piece);
          }
          if (value_per_piece_currency) {
            formData.append('value_per_piece_currency', value_per_piece_currency);
          }
          if (freight_class) {
            formData.append('freight_class', freight_class);
          }
          if (hazmat_hazard_class) {
            formData.append('hazmat_hazard_class', hazmat_hazard_class);
          }
          if (hazmat_identification_number) {
            formData.append('hazmat_identification_number', hazmat_identification_number);
          }
          if (hazmat_packing_group) {
            formData.append('hazmat_packing_group', hazmat_packing_group);
          }
          if (hazmat_proper_shipping_name) {
            formData.append('hazmat_proper_shipping_name', hazmat_proper_shipping_name);
          }
          if (height) {
            formData.append('height', height);
          }
          if (length) {
            formData.append('length', length);
          }
          if (length_unit) {
            formData.append('length_unit', length_unit);
          }
          if (nmfc_item_code) {
            formData.append('nmfc_item_code', nmfc_item_code);
          }
          if (nmfc_sub_code) {
            formData.append('nmfc_sub_code', nmfc_sub_code);
          }
          if (package_type) {
            formData.append('package_type', package_type);
          }
          if (piece_type) {
            formData.append('piece_type', piece_type);
          }
          if (stackable) {
            formData.append('stackable', stackable);
          }
          if (package_weight) {
            formData.append('package_weight', package_weight);
          }
          if (weight_unit) {
            formData.append('weight_unit', weight_unit);
          }
          if (width) {
            formData.append('width', width);
          }
          if (external_can_quote) {
            formData.append('external_can_quote', external_can_quote);
          }
          if (max_pieces_per_package) {
            formData.append('max_pieces_per_package', max_pieces_per_package);
          }
          formData.append('refrigeration_required', Boolean(refrigeration_required));
          if (refrigeration_required) {
            formData.append('refrigeration_min_temp', refrigeration_min_temp || null);
            formData.append('refrigeration_max_temp', refrigeration_max_temp || null);
          }
          if (external_pickup_address) {
            formData.append('external_pickup_address', external_pickup_address);
            // @todo Rework into recurrsive solution for deeper nested objects
            // for (let key in external_pickup_address) {
            //   if (key === 'address') {
            //     for (let addressKey in external_pickup_address.address) {
            //       formData.append('external_pickup_address.address', external_pickup_address.address[addressKey]);
            //     }
            //   } else {
            //     formData.append(`external_pickup_address.${key}`, external_pickup_address[key]);
            //   }
            // }
          } else {
            formData.append('external_pickup_address', '');
          }
          if (country_of_manufacture) {
            formData.append('country_of_manufacture', country_of_manufacture);
          }
          return formData;
        }
      ],
      headers: {
        authorization: accessToken,
        'Content-Type': 'multipart/form-data'
      }
    };
    const request = axios.put(basePath + '/products/' + productId + '/', body, config);
    request
      .then((response) => resolve(response))
      .catch((error) => {
        reject({body: error.response && error.response.data});
      });
  });
}

export function editProduct(productId, body) {
  return (dispatch) => {
    return editProductPromise(productId, body)
      .then((response) => {
        dispatch({type: CREATE_PRODUCT, payload: response.data});
        return {status: 200, body: response.data};
      })
      .catch((response) => {
        return parseErrors(response);
      });
  };
}

export function deleteProduct(id) {
  return (dispatch) => {
    return deleteProductPromise(id)
      .then((response) => {
        return {status: 200};
      })
      .catch((response) => {
        dispatch({
          type: ALERT_ERROR,
          payload: 'There was an error deleting this product.'
        });
        return {status: 400, details: response};
      });
  };
}

async function deleteProductPromise(id) {
  const shipwellApi = await getShipwellApi();
  return new Promise(function (resolve, reject) {
    shipwellApi.productsProductIdDelete(id, function (err, response) {
      if (err) {
        reject(parseErrors(response));
      } else {
        resolve(response);
      }
    });
  });
}
