import {ShipwellUiApi, Configuration, EmailNotificationRequest, UserPreferences} from '@shipwell/backend-core-sdk';
import {
  ShipwellUiApiShipwellUiSettlementsDashboardDeliveredGetRequest,
  ShipwellUiApi as ShipwellUiApiSingleRequestParam,
  PaginatedAlertsDashBoardConfiguration,
  AlertsDashBoardConfiguration,
  AlertDashboardSlimShipment,
  AlertDashboardSlimCarrierRelationship,
  ShipwellUiApiShipwellUiShipmentsShipmentIdConsolidateLineItemsPostRequest,
  ShipmentIdsAlertMetadata
} from '@shipwell/backend-core-singlerequestparam-sdk';

import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

const shipwellUiApi = new ShipwellUiApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

const shipwellUiApiSingleRequestParam = new ShipwellUiApiSingleRequestParam(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export function sendEmailNotificationPromise(body: EmailNotificationRequest) {
  return shipwellUiApi.shipwellUiEmailNotificationsPost(body);
}

type ShipwellUiAlertsDashboardConfigurationGetParameters = Parameters<
  ShipwellUiApi['shipwellUiAlertsDashboardConfigurationGet']
>;

export async function getAlertsConfigurations(opts: {
  page?: ShipwellUiAlertsDashboardConfigurationGetParameters[0];
  pageSize?: ShipwellUiAlertsDashboardConfigurationGetParameters[1];
  ordering?: ShipwellUiAlertsDashboardConfigurationGetParameters[2];
}): Promise<PaginatedAlertsDashBoardConfiguration> {
  const response = await shipwellUiApi.shipwellUiAlertsDashboardConfigurationGet(
    opts.page,
    opts.pageSize,
    opts.ordering
  );
  return response.data as PaginatedAlertsDashBoardConfiguration;
}

export async function getAlertsConfiguration(
  alertsDashboardConfigurationId: string
): Promise<AlertsDashBoardConfiguration> {
  const response = await shipwellUiApi.shipwellUiAlertsDashboardConfigurationAlertsDashboardConfigurationIdGet(
    alertsDashboardConfigurationId
  );
  return response.data as AlertsDashBoardConfiguration;
}

export function getAlertCountByConfigId(alertsDashboardConfigurationId: string) {
  return shipwellUiApi.shipwellUiAlertsDashboardConfigurationAlertsDashboardConfigurationIdAlertCountGet(
    alertsDashboardConfigurationId
  );
}

export async function getAlertsDashboardShipment(
  shipmentId: string,
  resolvedAlerts: boolean
): Promise<AlertDashboardSlimShipment> {
  const response = await shipwellUiApi.shipwellUiAlertsDashboardShipmentsShipmentIdGet(shipmentId, resolvedAlerts);
  return response.data as AlertDashboardSlimShipment;
}

export async function getAlertsDashboardCarrier(
  carrierRelationshipId: string,
  resolvedAlerts: boolean
): Promise<AlertDashboardSlimCarrierRelationship> {
  const response = await shipwellUiApi.shipwellUiAlertsDashboardCarrierRelationshipsCarrierRelationshipIdGet(
    carrierRelationshipId,
    resolvedAlerts
  );
  return response.data as AlertDashboardSlimCarrierRelationship;
}

export function getUserWatchedShipments() {
  return shipwellUiApi.shipwellUiUserWatchedShipmentsGet();
}

export function getCurrentBillTo(shipmentId: string, quoteId?: string) {
  return shipwellUiApi.shipwellUiShipmentsShipmentIdBillToGet(shipmentId, quoteId);
}

export function getShipmentStatuses(shipmentIds: string[]) {
  return shipwellUiApi.shipwellUiShipmentsStatusGet(shipmentIds);
}

export function getUserPreferences() {
  return shipwellUiApi.shipwellUiUserPreferencesGet();
}

export function updateUserPreferences(userPreferences: UserPreferences) {
  return shipwellUiApi.shipwellUiUserPreferencesPut(userPreferences);
}

type GetFreightInvoicesParameters = Parameters<ShipwellUiApi['shipwellUiSettlementsDashboardGet']>;

export function getSettlementsDashboard(params?: {
  page?: GetFreightInvoicesParameters[0];
  limit?: GetFreightInvoicesParameters[1];
  invoiceableId?: GetFreightInvoicesParameters[2];
  status?: GetFreightInvoicesParameters[3];
  statusIn?: GetFreightInvoicesParameters[4];
  invoiceableReferenceNumber?: GetFreightInvoicesParameters[5];
  invoiceSource?: GetFreightInvoicesParameters[6];
  serviceProviderName?: GetFreightInvoicesParameters[7];
  invoiceNumber?: GetFreightInvoicesParameters[8];
  remitToName?: GetFreightInvoicesParameters[9];
  search?: GetFreightInvoicesParameters[10];
  role?: GetFreightInvoicesParameters[11];
  orderBy?: GetFreightInvoicesParameters[12];
  options?: GetFreightInvoicesParameters[13];
}) {
  const {
    page,
    limit,
    invoiceableId,
    status,
    statusIn,
    invoiceableReferenceNumber,
    invoiceSource,
    serviceProviderName,
    invoiceNumber,
    remitToName,
    search,
    role,
    orderBy,
    options
  } = params || {};

  return shipwellUiApi.shipwellUiSettlementsDashboardGet(
    page,
    limit,
    invoiceableId,
    status,
    statusIn,
    invoiceableReferenceNumber,
    invoiceSource,
    serviceProviderName,
    invoiceNumber,
    remitToName,
    search,
    role,
    orderBy,
    options
  );
}

export const getSettlementsDeliveredDashboard = (
  requestParameters?: ShipwellUiApiShipwellUiSettlementsDashboardDeliveredGetRequest
) => shipwellUiApiSingleRequestParam.shipwellUiSettlementsDashboardDeliveredGet(requestParameters);

export const getDocumentShortNames = () => shipwellUiApi.shipwellUiShipmentDocTypesShortNamesGet();

export const watchShipment = (shipmentId: string) => {
  return shipwellUiApi.shipwellUiUserWatchedShipmentsPost({shipment_id: shipmentId});
};

export const stopWatchingShipment = (shipmentId: string) => {
  return shipwellUiApi.shipwellUiUserWatchedShipmentsDelete({shipment_id: shipmentId});
};

export const consolidateLineItems = ({
  shipmentId,
  itemConsolidation
}: ShipwellUiApiShipwellUiShipmentsShipmentIdConsolidateLineItemsPostRequest) => {
  return shipwellUiApiSingleRequestParam.shipwellUiShipmentsShipmentIdConsolidateLineItemsPost({
    shipmentId,
    itemConsolidation
  });
};

export const getShipmentMetaDataAlerts = (shipmentIds: ShipmentIdsAlertMetadata['shipment_ids']) => {
  return shipwellUiApiSingleRequestParam.shipwellUiShipmentMetadataAlertsPost({
    shipmentIdsAlertMetadata: {shipment_ids: shipmentIds}
  });
};
