import {useQuery} from '@tanstack/react-query';
import {LegacyShipmentStageStageTypeEnum} from '@shipwell/corrogo-sdk';
import {SidebarContent} from '@shipwell/shipwell-ui';
import StagesView from 'App/containers/shipments/components/DashboardSummary/StagesView';
import {getStagesAssociatedToResources} from 'App/api/corrogo/typed';
import {RESOURCE_STAGES_QUERY_KEY} from 'App/data-hooks/queryKeys';
import Loader from 'App/common/shipwellLoader';

// helper to extract the v3 shipment wrapper ID from a resource (in this case a legacy v2 shipment ID)
export function StagesViewWithShipmentId({resourceId}: {resourceId: string}) {
  const stagesQuery = useQuery([RESOURCE_STAGES_QUERY_KEY, resourceId], async () => {
    const response = await getStagesAssociatedToResources([resourceId]);
    return response.data;
  });

  if (stagesQuery.isInitialLoading) {
    return <Loader loading />;
  }

  return (
    <StagesView
      shipmentId={stagesQuery?.data?.[0]?.shipment_id || undefined}
      resourceId={resourceId}
      stageType={LegacyShipmentStageStageTypeEnum.LegacyShipment}
    />
  );
}

function SidebarStages({
  shipment,
  onClose,
  ...props
}: {
  shipment: {id: string; metadata: {v3_shipment_reference_id: string}};
  onClose: () => void;
}) {
  return (
    <SidebarContent
      title={shipment.metadata.v3_shipment_reference_id}
      action={null}
      onClose={onClose}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <div className="h-full overflow-y-auto px-2 pb-12">
        <StagesViewWithShipmentId resourceId={shipment.id} />
      </div>
    </SidebarContent>
  );
}

SidebarStages.tabsRole = 'TabPanel';

export default SidebarStages;
