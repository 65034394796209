import {useMemo} from 'react';
import moment from 'moment-timezone';
import last from 'lodash/last';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {DisplayValue} from '@shipwell/shipwell-ui';

interface TrackingHeaderProps {
  shipment?: Shipment;
}

export default function TrackingHeader({shipment}: TrackingHeaderProps) {
  const deliverTo = last(shipment?.stops?.filter((s) => s.is_dropoff) || []);
  const [deliverToAddressRow1, deliverToAddressRow2] = useMemo(() => {
    const deliverToAddressRow1 =
      deliverTo && [deliverTo.location.address.address_1, deliverTo.location.address.address_2].join('');
    const deliverToAddressRow2 = deliverTo?.location?.address
      ? [
          deliverTo.location.address.city,
          `${deliverTo.location.address.state_province || ''} ${deliverTo.location.address.postal_code || ''}`,
          deliverTo.location.address.country
        ]
      : [];

    return [deliverToAddressRow1, deliverToAddressRow2];
  }, [deliverTo]);

  const [estimatedArrivalTime, estimatedFullDate] = useMemo(() => {
    const TIME_FORMAT = 'HH:mm zz';
    const FULL_FORMAT = 'ddd, MMM Do YYYY';
    const plannedDate = moment.utc(deliverTo?.planned_date);
    const stopEtaWindow = (deliverTo?.display_eta_window || '').split(',')[1];
    const stopEtaTime = stopEtaWindow?.split('-')[1];

    if (moment(deliverTo?.confirmed_departure_at).isValid()) {
      const stopCompletedAt = moment.tz(deliverTo?.confirmed_departure_at, moment.tz.guess());
      return [stopCompletedAt.format(TIME_FORMAT), stopCompletedAt.format(FULL_FORMAT)];
    }

    return [stopEtaTime, plannedDate.format(FULL_FORMAT)];
  }, [deliverTo]);

  return (
    <header className="grid grid-cols-2 grid-rows-2 gap-10 self-center rounded-md bg-sw-background p-6 lg:grid-cols-3 lg:grid-rows-1">
      <div className="col-span-2 row-start-1 flex justify-center lg:col-span-1">
        <div className="flex w-full flex-col items-center justify-center rounded-md bg-sw-background-component p-6">
          <h5 className="font-normal uppercase italic">Estimated Arrival</h5>
          <div className="mb-2 w-20 border-b-4 border-sw-border"></div>
          <div className="text-xl font-black">{estimatedFullDate}</div>
          <div className="text-3xl font-bold">{estimatedArrivalTime}</div>
        </div>
      </div>
      <div className="row-start-2 flex flex-col lg:row-start-1">
        <h5 className="italic">Delivery Information</h5>
        <DisplayValue label="delivering to" className="pt-3">
          <div className="text-base">{deliverToAddressRow1}</div>
          <div className="text-base">{deliverToAddressRow2.join(', ')}</div>
        </DisplayValue>
      </div>
      <div className="row-start-2 flex flex-col justify-center lg:row-start-1">
        <h5 className="italic">Shipment Information</h5>
        <DisplayValue label="customer #" className="pt-3">
          <div className="text-base">{shipment?.customer_reference_number || '--'}</div>
        </DisplayValue>
        <DisplayValue label="bol #" className="pt-3">
          <div className="text-base">{shipment?.bol_number || '--'}</div>
        </DisplayValue>
        <DisplayValue label="pro #" className="pt-3">
          <div className="text-base">{shipment?.pro_number || '--'}</div>
        </DisplayValue>
      </div>
    </header>
  );
}
