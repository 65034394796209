import {useState} from 'react';
import {DragDropContainer, Tabs, TabList, Tab, TabPanel} from '@shipwell/shipwell-ui';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import DetailsCard from 'App/containers/shipments/details/components/DetailsCard';
import ShipmentSidebar from 'App/containers/shipments/details/components/ShipmentSidebar';
import DrayageLegSidebar from 'App/containers/shipments/details/components/DrayageLegSidebar';
import {
  getSchemaByEntityType,
  entityTypes,
  tabNameToRoute
} from 'App/containers/shipments/details/utils/DetailsSchemas';
import {LegDetailsHeader, ShipmentDetailsHeader} from 'App/containers/shipments/components/Header';
import {PROPTYPE_ROUTER, PROPTYPE_LOCATION} from 'App/utils/propTypeConstants';

export const DetailsTabsBase = ({schema, router, location, params, getTabRoutePrefix, ...props}) => {
  const getTabNameFromRoute = () => {
    const parts = get(location, 'pathname', '').split('/');
    const last = parts.pop();
    const tab = schema.find((tab) => tabNameToRoute(tab.title) === last);
    return tab?.title ?? null;
  };

  const getTabIndexForTabName = (tabName) => {
    const tabIndex = findIndex(schema, (tab) => tabName === tab.title);
    return Math.max(0, tabIndex);
  };

  const getRouteForTabIndex = (index) => tabNameToRoute(get(schema, `[${index}].title`, ''));

  const handleTabChange = (index) => {
    const routePrefix = getTabRoutePrefix(params);
    router.push(`${routePrefix}/${getRouteForTabIndex(index)}`);
  };

  return schema ? (
    <Tabs onSelect={handleTabChange} selectedIndex={getTabIndexForTabName(getTabNameFromRoute())}>
      <TabList className="p-4">
        {schema.map((tab) => (
          <Tab key={`list-entry-${tab.title}`}>{tab.title}</Tab>
        ))}
      </TabList>
      {schema.map((tab) => (
        <TabPanel key={`panel-entry-${tab.title}`}>
          {get(tab, 'components', []).map((Component) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Component key={`component-entry-${Component.displayName}`} {...props} />
          ))}
        </TabPanel>
      ))}
    </Tabs>
  ) : null;
};

DetailsTabsBase.propTypes = {
  schema: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      component: PropTypes.node
    })
  ),
  router: PROPTYPE_ROUTER,
  location: PROPTYPE_LOCATION,
  params: PropTypes.shape({
    shipment_id: PropTypes.string
  }),
  getTabRoutePrefix: PropTypes.func
};

DetailsTabsBase.defaultProps = {
  getTabRoutePrefix(params) {
    return `/shipments/${params.shipment_id}`;
  }
};

export const DetailsTabs = withRouter(DetailsTabsBase);

export const ShipmentDetailsController = ({shipmentId}) => {
  const [cardSchema, setCardSchema] = useState(getSchemaByEntityType(entityTypes.DRAYAGE_SHIPMENT));

  const handleDragEnd = (newSchema) => {
    setCardSchema((prevSchema) => ({...prevSchema, card: newSchema}));
  };

  return (
    <div className="bg-sw-background">
      <ShipmentDetailsHeader shipmentId={shipmentId} />
      <div style={{gridTemplateColumns: 'minmax(352px, 0) 1fr'}} className="grid grid-flow-row grid-auto-rows-5">
        <div style={{height: 'calc(100vh - 135px)'}} className="overflow-y-auto p-2">
          <DragDropContainer
            items={cardSchema?.card}
            Component={DetailsCard}
            direction="vertical"
            onDragEnd={handleDragEnd}
            shipmentId={shipmentId}
          />
        </div>
        <div style={{height: 'calc(100vh - 135px)'}} className="flex">
          <div className="mr-2 mt-2 w-full overflow-y-auto rounded border border-sw-border bg-sw-background-component">
            <DetailsTabs schema={cardSchema?.tabs} shipmentId={shipmentId} />
          </div>
          <ShipmentSidebar shipmentId={shipmentId} />
        </div>
      </div>
    </div>
  );
};

ShipmentDetailsController.propTypes = {
  shipmentId: PropTypes.string
};

export const LegDetailsController = ({legId}) => {
  const [cardSchema, setCardSchema] = useState(getSchemaByEntityType(entityTypes.DRAYAGE_LEG));

  const handleDragEnd = (newSchema) => {
    setCardSchema((prevSchema) => ({...prevSchema, card: newSchema}));
  };

  const LegFooter = cardSchema.footer;

  const getTabRoutePrefix = (params) => {
    return `/leg/${params.leg_id}`;
  };

  return (
    <>
      <div className="bg-sw-background">
        <LegDetailsHeader legId={legId} />
        <div style={{gridTemplateColumns: 'minmax(352px, 0) 1fr'}} className="grid grid-flow-row grid-auto-rows-5">
          <div style={{height: 'calc(100vh - 105px)'}} className="overflow-y-auto p-2">
            <DragDropContainer
              items={cardSchema?.card}
              Component={DetailsCard}
              direction="vertical"
              onDragEnd={handleDragEnd}
              legId={legId}
            />
          </div>
          <div style={{height: 'calc(100vh - 105px)'}} className="flex">
            <div className="mr-2 mt-2 w-full overflow-y-auto rounded border border-sw-border bg-sw-background-component">
              <DetailsTabs schema={cardSchema?.tabs} legId={legId} getTabRoutePrefix={getTabRoutePrefix} />
            </div>
            <DrayageLegSidebar legId={legId} />
          </div>
        </div>
      </div>
      <LegFooter legId={legId} />
    </>
  );
};

LegDetailsController.propTypes = {
  legId: PropTypes.string
};
