import {Field, getFormValues, change, FormAction} from 'redux-form';
import {connect} from 'react-redux';
import CheckboxField from 'App/formComponents/fields/checkbox';
import InputField from 'App/formComponents/fields/input';
import {cleanNumber, truncateNumber} from 'App/utils/globals';
import {State} from 'App/reducers/types';

interface ShipmentTotalQuantityFieldsProps {
  form: string;
  dispatch: (dispatch: FormAction) => void;
  values: Partial<{manual_quantity: boolean}>;
}

const ShipmentTotalQuantityFields = ({form, values, dispatch}: ShipmentTotalQuantityFieldsProps) => {
  const {manual_quantity} = values;

  const handleQuantityInputChange = ({preventDefault, currentTarget: {value}}: React.FormEvent<HTMLInputElement>) => {
    preventDefault();
    dispatch(change(form, 'total_quantity_override', Number(value) > 0 ? truncateNumber(value, 0) : null));
  };

  const handleCheckboxSelection = ({currentTarget: {value}}: React.FormEvent<HTMLInputElement>) => {
    if (!value) {
      dispatch(change(form, 'total_quantity_override', null));
    }
  };

  return (
    <div className="ml-[5px] mt-[13px] grid pl-[8px]">
      <Field
        name="manual_quantity"
        label="Enter Total Shipment Quantity Override"
        component={CheckboxField}
        onChange={handleCheckboxSelection}
      />
      {manual_quantity ? (
        <div className="grid grid-cols-2">
          <Field
            req
            name="total_quantity_override"
            label="Total Shipment Quantity"
            placeholder="Enter Quantity"
            format={(value: string | null) => truncateNumber(value || '', 0)}
            normalize={(value: string | null) => cleanNumber(value || '')}
            component={InputField}
            onChange={handleQuantityInputChange}
          />
        </div>
      ) : null}
    </div>
  );
};

//still needed because this component is used with redux forms
const mapStateToProps = (state: State, props: ShipmentTotalQuantityFieldsProps) => ({
  values: getFormValues(props.form)(state)
});

export default connect(mapStateToProps)(ShipmentTotalQuantityFields);
