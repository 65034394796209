import {Template, TemplateDocumentType} from '@shipwell/template-builder-sdk';
import {createColumnHelper} from '@tanstack/react-table';
import {Pill, Button} from '@shipwell/shipwell-ui';
import {TemplateActionsColumn} from './actions/TemplateActionsColumn';
import {CreatedAtCell} from './cells/CreatedAtCell';
import {toTitleCase} from 'App/utils/string';
import {parseAutoGenerateRules} from 'App/containers/userCompany/templates/utils/formtData';

const columnHelper = createColumnHelper<Template>();

export const templateColumns = (
  setTemplateId: (templateId: string) => void,
  setTemplateName: (templateName: string) => void
) => [
  columnHelper.accessor('name', {
    header: 'Name',
    size: 250,
    cell: (info) => {
      return (
        <Button
          variant="tertiary"
          onClick={() => {
            setTemplateId(info.row.original.id);
            setTemplateName(info.row.original.name);
          }}
        >
          {info.getValue()}
        </Button>
      );
    },
    enableSorting: true
  }),
  columnHelper.accessor('document_type', {
    header: 'Document Type',
    size: 150,
    cell: (info) => (info.getValue() === TemplateDocumentType.Bol ? 'BOL' : toTitleCase(info.getValue())),
    enableSorting: true
  }),
  columnHelper.accessor('description', {
    header: 'Description',
    size: 250,
    cell: (info) => info.getValue(),
    enableSorting: false
  }),
  columnHelper.accessor('auto_generate_rules', {
    header: 'Auto Generate',
    size: 250,
    cell: (info) => parseAutoGenerateRules(info.getValue()).join(', '),
    enableSorting: false
  }),
  columnHelper.accessor('allowed_modes', {
    header: 'Mode',
    size: 100,
    cell: (info) => info.getValue().join(', '),
    enableSorting: false
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    size: 100,
    cell: (info) => (
      <div className="w-full">
        <div className="flex items-center justify-center">
          <Pill variant={info.getValue() === 'ACTIVE' ? 'primary' : 'inactive'}>{info.getValue()}</Pill>
        </div>
      </div>
    ),
    enableSorting: true
  }),
  columnHelper.accessor('updated_at', {
    header: 'Last Updated',
    size: 150,
    cell: (info) => <CreatedAtCell createdAt={info.row.original.created_at} updatedAt={info.row.original.updated_at} />,
    enableSorting: true
  }),
  columnHelper.accessor('template_id', {
    header: 'Actions',
    size: 100,
    cell: (info) => <TemplateActionsColumn template={info.row.original} />,
    enableSorting: false
  })
];
