import React, {useState, useEffect, useMemo, PropsWithChildren, useCallback} from 'react';
import {debounce} from 'lodash';
import {Loader, SearchField, Button} from '@shipwell/shipwell-ui';
import {DeliveryTypeEnum, Facility} from '@shipwell/tempus-sdk';
import {CalendarApi} from '@fullcalendar/core';
import {Draggable} from '@fullcalendar/interaction';
import {Drawer} from '@material-ui/core';
import LittleCalendar from './components/LittleCalendar';
import WeekPaginator from './components/WeekPaginator';
import BigCalendar from './components/BigCalendar/BigCalendar';
import {WeekDaySelector} from './components/WeekDaySelector';
import DayPaginator from './components/DayPaginator';
import FacilitySelector from './components/FacilitySelector';
import {getCalendarTimeOfDayBounds, getTimeOfInterestBounds} from './utils';

import {FiltersPanel} from './components/Filters';
import {AppointmentVerbContextProvider} from './AppointmentVerbContext';

import {FirstComeFirstServeList} from './components/FirstComeFirstServeList';
import {UnscheduledAppointmentsList} from './components/UnscheduledAppointmentsList';
import {AppointmentDetailsTabs} from './components/AppointmentDetails/AppointmentDetailsTabs';
import {appointmentToEvent} from './components/BigCalendar/full-calendar-utils';
import {
  Filters,
  ViewMode,
  AppointmentEntry,
  AppointmentAvailabilityWindow,
  AppointmentAvailabilityRestriction
} from 'App/data-hooks/appointments/types';
import {useUserMe, useDynamicAvailability, useFacilityAppointments, useAppointmentPerms} from 'App/data-hooks';

import PageHeader from 'App/common/pageHeader';
import Error404Page from 'App/common/Error404Page';
import {formatDate, today} from 'App/utils/dateTimeGlobalsTyped';
import {AppointmentCreationModal} from 'App/containers/appointments/components/modals';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import ErrorBoundary from 'App/common/ErrorBoundary/ErrorBoundary';
import {TabbedSlidingDrawer} from 'App/components/TabbedSlidingDrawer';
import CarrierPortal from 'App/containers/appointments/CarrierPortal';
import {useLocalStorage} from 'App/utils/hooks/useLocalStorage';
import {FlexBox} from 'App/components/Box';

const DefaultFilters: Filters = {
  deliveryTypes: {
    [DeliveryTypeEnum.Receiving]: true,
    [DeliveryTypeEnum.Shipping]: true
  },
  docks: {},
  loadTypes: {}
};

const EmptyAvailability: AppointmentAvailabilityWindow[] = [];
const EmptyRestrictions: AppointmentAvailabilityRestriction[] = [];

/** Necessary for reliable and expedient tests */
type ShipperAppointmentsContainerProps = WithStatusToastProps & {
  apiRef?: (ref: CalendarApi) => unknown;
  initialSelectedDate?: Date;
};

const drawerWidth = '389px';
export const ShipperAppointmentsContainer = ({
  setError,
  setSuccess,
  apiRef,
  initialSelectedDate
}: ShipperAppointmentsContainerProps): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate ? new Date(initialSelectedDate) : today());
  const [viewMode, setViewMode] = useLocalStorage({key: 'dock-scheduling-view-mode', initialValue: ViewMode.Week});
  const [facility, setFacility] = useLocalStorage<Facility | null>({
    key: 'dock-scheduling-facility',
    initialValue: null
  });
  const [filters, setFilters] = useState<Filters>(DefaultFilters);
  const [searchText, setSearchText] = useState<string>();
  const [showModal, setShowModal] = useState<{show: boolean; clickedTime: Date | null}>({
    show: false,
    clickedTime: null
  });

  const [start, end] = useMemo(() => getTimeOfInterestBounds(selectedDate, viewMode), [selectedDate, viewMode]);
  const [timeOfDayStart, timeOfDayEnd] = useMemo(
    () => getCalendarTimeOfDayBounds(facility, selectedDate),
    [facility, selectedDate]
  );

  const [selectedAppointment, innerSetSelectedAppointment] = useState<AppointmentEntry | null>(null);
  const [draggingAppointment, setDraggingAppointment] = useState<AppointmentEntry | null>(null);
  const [selectedTab, setSelectedTab] = useState<string | null>(null);

  const openFCFS = useCallback(() => {
    setSelectedTab('first-come-first-serve-appointments');
  }, []);

  const setSelectedAppointment = useCallback((appointment: AppointmentEntry | null) => {
    innerSetSelectedAppointment(appointment);
  }, []);

  const handleSearchTextChanged = debounce((value: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(value.target.value);
  }, 700);

  const {
    isLoading,
    appointments,
    firstComeFirstServeAppointments,
    allDayAppointments,
    unscheduledAppointments,
    docks,
    loadTypes,
    checkIn,
    checkOut,
    reject,
    reschedule,
    cancel,
    successQueue,
    errorQueue,
    appointmentsQuery
  } = useFacilityAppointments(facility, start, end, searchText);

  const dynamicAvailability = useDynamicAvailability(start, end, draggingAppointment ?? undefined);
  const availabilityRestrictions = dynamicAvailability.isValid
    ? dynamicAvailability.availabilityRestrictions
    : EmptyRestrictions;
  const availabilityWindowsByDock = dynamicAvailability.isValid
    ? dynamicAvailability.availabilityWindowsByDock
    : EmptyAvailability;
  const availabilityWindowsUnion = dynamicAvailability.isValid
    ? dynamicAvailability.availabilityWindowsUnion
    : EmptyAvailability;
  const loadTypeMatchResults = dynamicAvailability.isValid
    ? dynamicAvailability.loadTypeDockRuleMatchResults
    : undefined;

  const selectedAppointmentUpdate =
    selectedAppointment &&
    [...appointments, ...firstComeFirstServeAppointments, ...allDayAppointments, ...unscheduledAppointments].find(
      ({id}) => id === selectedAppointment?.id
    );

  if (selectedAppointmentUpdate && selectedAppointment !== selectedAppointmentUpdate) {
    setSelectedAppointment(selectedAppointmentUpdate);
  }

  useEffect(() => {
    let msg: string | undefined;
    while ((msg = successQueue.pop())) {
      setSuccess('Success!', msg);
    }
  }, [successQueue, setSuccess]);

  useEffect(() => {
    let msg: {title: string; detail: string} | undefined;
    while ((msg = errorQueue.pop())) {
      setError(msg.title, msg.detail || ' ');
    }
  }, [errorQueue, setError]);

  useEffect(() => {
    const dockIds = docks.map((d) => d.id);
    const toAdd = dockIds.filter((id) => filters.docks[id] == null);
    const toRemove = Object.keys(filters.docks).filter((id) => !dockIds.includes(id));
    if (toAdd.length + toRemove.length === 0) {
      return;
    }
    const nextFilters = {...filters, docks: {...filters.docks}};
    for (const id of toAdd) {
      nextFilters.docks[id] = true;
    }
    for (const id of toRemove) {
      delete nextFilters.docks[id];
    }
    setFilters(nextFilters);
  }, [docks, filters]);

  useEffect(() => {
    const loadTypeIds = loadTypes.map((d) => d.id);
    const toAdd = loadTypeIds.filter((id) => filters.loadTypes[id] == null);
    const toRemove = Object.keys(filters.loadTypes).filter((id) => !loadTypeIds.includes(id));
    if (toAdd.length + toRemove.length === 0) {
      return;
    }
    const nextFilters = {...filters, loadTypes: {...filters.loadTypes}};
    for (const id of toAdd) {
      nextFilters.loadTypes[id] = true;
    }
    for (const id of toRemove) {
      delete nextFilters.loadTypes[id];
    }
    setFilters(nextFilters);
  }, [loadTypes, filters]);

  const availabilityWindows = useMemo(() => {
    return viewMode === ViewMode.Day
      ? availabilityWindowsByDock
      : draggingAppointment?.dockId
      ? availabilityWindowsByDock.filter((w) => w.dockId === draggingAppointment?.dockId)
      : availabilityWindowsUnion;
  }, [availabilityWindowsByDock, availabilityWindowsUnion, viewMode, draggingAppointment?.dockId]);

  const [containerEl, setContainerEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const el = containerEl;
    if (el) {
      const draggable = new Draggable(el, {
        itemSelector: '.sw-appointment-list-draggable-entry',
        minDistance: 16,
        eventData: function (eventEl: HTMLElement) {
          const appointmentData = eventEl.dataset.appointment;
          let appointmentObject: AppointmentEntry | null = null;
          if (appointmentData) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            appointmentObject = JSON.parse(appointmentData);
          }
          const calendarEvent = appointmentObject && appointmentToEvent(loadTypes, ViewMode.Week, appointmentObject);
          return {
            ...calendarEvent
          };
        }
      });

      draggable.dragging.emitter.on('dragstart', (event: DragEvent) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        const el = (event as any).subjectEl as HTMLElement;
        let appointmentObject: AppointmentEntry | null = null;
        const appointmentData = el.dataset.appointment;

        if (appointmentData) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          appointmentObject = JSON.parse(appointmentData);
        }
        setDraggingAppointment(appointmentObject);
        setSelectedAppointment(null);
      });
      draggable.dragging.emitter.on('dragend', () => {
        setDraggingAppointment(null);
      });
      return () => {
        draggable.destroy();
      };
    }
  }, [containerEl, loadTypes, setDraggingAppointment, setSelectedAppointment, setSelectedTab]);

  const verbContextValue = useMemo(
    () => ({
      setSuccess,
      setError,
      checkIn,
      checkOut,
      reject,
      reschedule,
      cancel,
      prefetchAvailability: dynamicAvailability.prefetch,
      invalidateAvailability: dynamicAvailability.invalidate,
      setSelectedTab
    }),
    [
      setSuccess,
      setError,
      checkIn,
      checkOut,
      reject,
      reschedule,
      cancel,
      dynamicAvailability.prefetch,
      dynamicAvailability.invalidate,
      setSelectedTab
    ]
  );

  return (
    <ErrorBoundary location="top">
      <AppointmentVerbContextProvider value={verbContextValue}>
        <div
          className="flex w-full flex-col h-screen-minus-16"
          style={{
            width: selectedAppointment ? `calc(100% - ${drawerWidth})` : '100%'
          }}
        >
          <PageHeader title="Dock Scheduling" className="bg-sw-background" />
          <div
            className="sw-appointments-container relative flex flex-1 flex-row bg-sw-background text-xxs h-screen-minus-28"
            ref={setContainerEl}
          >
            <div className="flex flex-1 flex-col">
              {/* AppointmentQueryRow */}
              <FlexBox
                gap="l"
                justify="between"
                items="center"
                pad={[
                  ['x', 'm'],
                  ['y', 's']
                ]}
              >
                <div className="flex items-center gap-3">
                  <h3 className="m-0">Calendar</h3>
                  <SearchField
                    label="Search Calendar"
                    name="searchText"
                    value={searchText}
                    onChange={handleSearchTextChanged}
                  />
                </div>
                <div className="flex items-center gap-3">
                  {/* 
                    Leaving this commented as a place holder for when we actually implement this.
                    <SvgIcon className="m-2" name="Download" />
                  */}
                  <WeekDaySelector viewMode={viewMode} setViewMode={setViewMode} />
                  <FacilitySelector facility={facility} setFacility={setFacility} />
                </div>
              </FlexBox>

              {/* AppointmentsDateRange */}
              <div className="flex-0 flex flex-row content-center justify-between border-t-1 border-sw-border px-4">
                {viewMode === ViewMode.Week ? (
                  <WeekPaginator selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                ) : (
                  <DayPaginator selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                )}

                {viewMode === ViewMode.Week ? null : (
                  <div className="sw-calendar-selected-date flex-0 p-2 text-sm">
                    {formatDate(selectedDate, facility?.address?.timezone)}
                  </div>
                )}

                <Button
                  size="sm"
                  variant="tertiary"
                  iconName="AddCircleOutlined"
                  onClick={() => setShowModal({show: true, clickedTime: null})}
                >
                  New Appointment
                </Button>
              </div>
              {/* Calendar View Almost */}
              <div className="relative h-full overflow-hidden">
                <div className="flex h-full flex-1 flex-row border-t-1 border-sw-border bg-sw-background-component">
                  <div className="flex-0 flex h-full flex-col overflow-hidden">
                    <div id="corner-spacer" className="h-[24px] border-b-1 border-sw-border bg-sw-background" />
                    <div id="sw-calendar-left-panel" className="flex flex-1 flex-col overflow-y-scroll p-0">
                      <div className="flex-0 flex flex-col">
                        <div className="flex-0 relative top-0 flex w-[200px] flex-col border-sw-border bg-sw-background-component p-2 pt-0">
                          <div className="relative h-min">
                            <LittleCalendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                            <FiltersPanel
                              filters={filters}
                              setFilters={setFilters}
                              docks={docks}
                              loadTypes={loadTypes}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex h-full flex-1 flex-col flex-wrap">
                    {isLoading ? (
                      <div className="content-center justify-center">
                        <Loader show>
                          <p className="loading-message">Loading Appointments...</p>
                        </Loader>
                      </div>
                    ) : (
                      <BigCalendar
                        facility={facility}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        viewMode={viewMode}
                        setViewMode={setViewMode}
                        appointments={appointments}
                        allDayAppointments={allDayAppointments}
                        fcfsAppointments={firstComeFirstServeAppointments}
                        availabilityWindows={availabilityWindows}
                        availabilityRestrictions={availabilityRestrictions}
                        docks={docks}
                        filters={filters}
                        loadTypes={loadTypes}
                        timeOfDayStart={timeOfDayStart}
                        timeOfDayEnd={timeOfDayEnd}
                        draggingAppointment={draggingAppointment}
                        setDraggingAppointment={setDraggingAppointment}
                        setShowModal={setShowModal}
                        selectedAppointment={selectedAppointment}
                        setSelectedAppointment={setSelectedAppointment}
                        openFCFS={openFCFS}
                        apiRef={apiRef}
                        loadTypeDockRuleMatchResults={loadTypeMatchResults}
                      />
                    )}
                  </div>
                  <div className="flex-0 flex w-14 bg-sw-background" />
                </div>
                <TabbedSlidingDrawer
                  selectedTab={selectedTab}
                  onTabSelect={setSelectedTab}
                  tabs={[
                    {
                      key: 'first-come-first-serve-appointments',
                      iconName: 'UnplannedTime',
                      content: ({close}) => {
                        return (
                          <FirstComeFirstServeList
                            facility={facility}
                            appointments={firstComeFirstServeAppointments}
                            loadTypes={loadTypes}
                            docks={docks}
                            close={close}
                            setSelectedAppointment={setSelectedAppointment}
                          />
                        );
                      }
                    },
                    {
                      key: 'unscheduled-appointments',
                      iconName: 'CalendarEdit',
                      content: ({close}) => {
                        return (
                          <UnscheduledAppointmentsList
                            facility={facility}
                            appointments={unscheduledAppointments}
                            loadTypes={loadTypes}
                            docks={docks}
                            close={close}
                            setSelectedAppointment={setSelectedAppointment}
                            setDraggingAppointment={setDraggingAppointment}
                            setSelectedTab={setSelectedTab}
                          />
                        );
                      }
                    }
                  ]}
                  coverShim={false}
                />
              </div>
            </div>
          </div>
        </div>

        <Drawer
          anchor="right"
          variant="persistent"
          open={Boolean(selectedAppointment)}
          classes={{
            paper: `h-screen-minus-16 top-auto bottom-0 z-10 w-[${drawerWidth}] overflow-y-clip`
          }}
        >
          <AppointmentDetailsTabs
            selectedAppointment={selectedAppointment}
            setSelectedAppointment={setSelectedAppointment}
            facility={facility}
            loadTypes={loadTypes}
            docks={docks}
            onClose={() => {
              void appointmentsQuery.refetch();
              setSelectedAppointment(null);
            }}
            drawerWidth={drawerWidth}
          />
        </Drawer>

        <AppointmentCreationModal
          onClose={() => setShowModal({show: false, clickedTime: null})}
          showModal={showModal.show}
          clickedTime={showModal.clickedTime}
          loadTypes={loadTypes}
          facilityId={facility?.id}
        />
      </AppointmentVerbContextProvider>
    </ErrorBoundary>
  );
};

export const AppointmentsRootWrapper = ({children}: PropsWithChildren<unknown>) => (
  <div className="sw-background relative flex flex-1 flex-col h-screen-minus-16">{children}</div>
);

export const ToastyShipperContainer = WithStatusToasts(ShipperAppointmentsContainer);
/**
 * Renders the Shipper appointments container or the Carrier appointments container
 * based on permissions or whether the user is a shipper or carrier. If a user belongs
 * to both shipper and carrier then the shipper container will be rendered.
 * @returns {JSX.Element | null}
 */
export const ConditionalAppointmentsContainer = (): JSX.Element | null => {
  const {data: auth, isLoading: authLoading} = useUserMe();

  const {canView} = useAppointmentPerms();

  const isShipper = Boolean(auth?.company?.shipper);
  const isCarrier = Boolean(auth?.company?.carrier) && !isShipper;

  if (authLoading) {
    return null;
  }
  if (!auth) {
    // no user could be located or the user doesn't have access to appointments
    return <Error404Page />;
  }

  if (isCarrier) {
    // carriers can always view appointments implicitly
    return <CarrierPortal />;
  }
  if (!canView) {
    return <Error404Page />;
  }
  return <ToastyShipperContainer />;
};

export default ConditionalAppointmentsContainer;

export {AppointmentForm, AppointmentCreationModal} from 'App/containers/appointments/components';
