import {
  CreateOrderItem,
  LengthUnit,
  OrderItem,
  OrderItemAmount,
  OrderItemShippingRequirements,
  FreightClass,
  PieceType,
  TemperatureUnit,
  WeightUnit,
  Hazmat,
  PackagingType
} from '@shipwell/corrogo-sdk';
import {isEmpty, isNil, omitBy, pick} from 'lodash';
import {Product} from '@shipwell/backend-core-sdk';
import pluralize from 'pluralize';
import {PACKAGING_TYPES, unitLabels} from 'App/containers/orders/constants';
import {convertKilogramsToPounds} from 'App/utils/internationalConstants';
import {UnitPreferences} from 'App/reducers/types';
import {calculateVolume, convertWeight} from 'App/utils/globalsTyped';
import {isNewOrderItem} from 'App/containers/orders/typeGuards';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';

export const getOrderItemAmount = (orderItem: OrderItem) => parseOrderItemAmount(orderItem.amount);

export const parseOrderItemUnit = (unit: OrderItemAmount['unit']) => unitLabels[unit] || '';

export const parseOrderItemAmount = (amount: OrderItemAmount) => {
  const amountValue = Number(amount?.value);
  const amountText = amountValue.toLocaleString();
  const unitLabel = pluralize(parseOrderItemUnit(amount.unit), amountValue);

  return [amountText, unitLabel].join(' ').trim();
};

export const isRefrigerationRequired = (orderItem: OrderItem | CreateOrderItem) => {
  const itemMinTemp = orderItem?.shipping_requirements?.temperature?.minimum;
  const itemMaxTemp = orderItem?.shipping_requirements?.temperature?.maximum;
  return Boolean(itemMinTemp || itemMaxTemp);
};

export const hasDimensions = (orderItem: OrderItem | CreateOrderItem) => {
  const length = orderItem?.shipping_requirements?.dimensions?.length;
  const height = orderItem?.shipping_requirements?.dimensions?.height;
  const width = orderItem?.shipping_requirements?.dimensions?.width;
  return Boolean(length || height || width);
};

export const hasValuePerPiece = (orderItem: OrderItem | CreateOrderItem) =>
  !isNilOrEmptyString(orderItem?.shipping_requirements?.value_per_piece?.value);

export const hasGrossWeight = (orderItem: OrderItem | CreateOrderItem) =>
  !isNilOrEmptyString(orderItem?.shipping_requirements?.gross_weight?.value);

export const getOrderItemInitialValues = (items: OrderItem[]): OrderItem[] =>
  items.map((item) => ({
    ...item,
    available_amount: {
      value: item.available_amount?.value || '',
      unit: item.available_amount.unit || ('' as OrderItemAmount['unit'])
    },
    shipping_requirements: {
      ...item.shipping_requirements,
      description: item.shipping_requirements?.description || '',
      dimensions: {
        length: item.shipping_requirements?.dimensions?.length || '',
        height: item.shipping_requirements?.dimensions?.height || '',
        width: item.shipping_requirements?.dimensions?.width || '',
        unit: item.shipping_requirements?.dimensions?.unit || LengthUnit.In
      },
      //since the backend doesn't store refrigeration required, we have to set it in the initial values
      //based on whether min or max temp has a value.
      refrigerationRequired: isRefrigerationRequired(item),
      gross_weight: {
        value: item.shipping_requirements?.gross_weight?.value || '',
        unit: item.shipping_requirements?.gross_weight?.unit || WeightUnit.Lb
      },
      packaging_type: item.shipping_requirements?.packaging_type || PackagingType.Pallet,
      quantity: item.shipping_requirements?.quantity || undefined,
      value_per_piece: {
        value: item.shipping_requirements?.value_per_piece?.value || '',
        currency_code: 'USD'
      },
      total_pieces: item.shipping_requirements?.total_pieces || undefined,
      temperature: {
        //unit doesn't have a corresponding field to set the temperature unit,
        //but show the unit value if it exists.
        unit: item.shipping_requirements?.temperature?.unit || TemperatureUnit.F,
        minimum: item.shipping_requirements?.temperature?.minimum,
        maximum: item.shipping_requirements?.temperature?.maximum
      },
      freight_class: item.shipping_requirements?.freight_class || undefined
    }
  }));
//the backend expects the measurement unit to be removed if the value is empty.
export const removeUnitValuesForEmptyMeasurements = (
  item: OrderItem | CreateOrderItem
): OrderItem | CreateOrderItem => {
  return {
    ...item,
    shipping_requirements: {
      ...item.shipping_requirements,
      dimensions: hasDimensions(item) ? item.shipping_requirements?.dimensions : undefined,
      temperature: isRefrigerationRequired(item) ? item.shipping_requirements?.temperature : undefined,
      value_per_piece: hasValuePerPiece(item) ? item.shipping_requirements?.value_per_piece : undefined,
      gross_weight: hasGrossWeight(item) ? item.shipping_requirements?.gross_weight : undefined,
      nmfc_item_code:
        item?.shipping_requirements && !isNilOrEmptyString(item?.shipping_requirements.nmfc_item_code)
          ? item.shipping_requirements.nmfc_item_code
          : undefined,
      nmfc_sub_code:
        item?.shipping_requirements && !isNilOrEmptyString(item?.shipping_requirements.nmfc_sub_code)
          ? item.shipping_requirements.nmfc_sub_code
          : undefined
    }
  };
};

/**
 * Removes the unit values for empty measurements, and then removes empty string values.
 * The backend service is set-up to throw an error if any empty strings are passed as values,
 * because disallowing empty strings helps with API schema validation and other type validations.
 */
export const cleanOrderItemsPayload = (items: OrderItem | CreateOrderItem): OrderItem | CreateOrderItem =>
  omitEmptyKeysWithEmptyObjectsRemoved(removeUnitValuesForEmptyMeasurements(items));

export const getTotalLineItemWeight = (orderItem: OrderItem) =>
  orderItem.shipping_requirements?.quantity &&
  orderItem.shipping_requirements.gross_weight?.value &&
  orderItem.shipping_requirements.gross_weight?.unit
    ? parseOrderItemAmount({
        unit: orderItem.shipping_requirements.gross_weight.unit,
        value: `${
          (Number(orderItem.shipping_requirements.quantity) || 0) *
          (Number(orderItem.shipping_requirements.gross_weight.value) || 0)
        }`
      })
    : '--';

export const getTotalOrderWeightLbs = (orderItems: OrderItem[]): string => {
  const totalOrderWeight = orderItems?.reduce((acc, orderItem) => {
    const itemWeightUnit = orderItem.shipping_requirements?.gross_weight?.unit;
    const itemWeight = Number(
      convertKilogramsToPounds(
        (Number(orderItem.shipping_requirements?.quantity) || 0) *
          Number(orderItem.shipping_requirements?.gross_weight?.value) || 0
      )
    );
    if (itemWeightUnit === WeightUnit.Kg) {
      acc + Number(convertKilogramsToPounds(itemWeight));
    }
    return acc + itemWeight;
  }, 0);
  if (totalOrderWeight) {
    return parseOrderItemAmount({
      unit: WeightUnit.Lb,
      value: totalOrderWeight.toString()
    });
  }
  return '--';
};

export const calculateOrderTotals = ({
  items = [],
  unitPreferences
}: {
  items: OrderItem[];
  unitPreferences?: UnitPreferences;
}) => {
  const shipmentTotals = {
    weight: 0,
    volume: 0,
    units: 0,
    density: 0,
    value: 0,
    system: unitPreferences?.system
  };
  const hasUsedCombinedHandlingUnitInfo: Record<string, number | undefined> = {};
  items.forEach((item) => {
    const {
      dimensions,
      quantity,
      total_pieces,
      value_per_piece,
      gross_weight,
      handling_unit_id,
      combined_handling_unit_info
    } = item?.shipping_requirements ?? {};
    const length = Number(dimensions?.length);
    const width = Number(dimensions?.width);
    const height = Number(dimensions?.height);
    const totalPackages = !handling_unit_id
      ? Number(quantity)
      : !hasUsedCombinedHandlingUnitInfo[handling_unit_id]
      ? Number(combined_handling_unit_info?.quantity)
      : 0;
    if (handling_unit_id) hasUsedCombinedHandlingUnitInfo[handling_unit_id] = combined_handling_unit_info?.quantity;
    const totalPieces = Number(total_pieces);
    const valuePerPiece = Number(value_per_piece?.value);
    const packageWeight = Number(gross_weight?.value);
    const weightUnit = gross_weight?.unit;

    shipmentTotals.value += totalPieces * valuePerPiece;
    shipmentTotals.units += totalPackages;
    shipmentTotals.weight += convertWeight(packageWeight * totalPackages, weightUnit, shipmentTotals.system);
    shipmentTotals.volume +=
      calculateVolume(length, width, height, dimensions?.unit, shipmentTotals.system) * totalPackages;
  });

  if (isNaN(shipmentTotals.units)) {
    shipmentTotals.units = 0;
  }

  if (isNaN(shipmentTotals.value)) {
    shipmentTotals.value = 0;
  }

  if (isNaN(shipmentTotals.weight)) {
    shipmentTotals.weight = 0;
  }

  if (isNaN(shipmentTotals.volume)) {
    shipmentTotals.volume = 0;
  }

  if (shipmentTotals.volume > 0) {
    shipmentTotals.density = shipmentTotals.weight / shipmentTotals.volume;
  }

  return shipmentTotals;
};

// this is kind of ridiculous. I tried a few different ways to satisfy TypeScript, but several didn't work. Feel
// free to try something better if you find this and it bothers you.
export const splitOrderItems = (items: (CreateOrderItem | OrderItem)[]) =>
  items.reduce<[CreateOrderItem[], OrderItem[]]>(
    (acc, item) => {
      if (isNewOrderItem(item)) {
        acc[0].push(item);
      } else {
        acc[1].push(item);
      }
      return acc;
    },
    [[], []]
  );
//this is used to check form values that are cleared out in addition to nil values,
//as empty form values are empty strings.
export function isNilOrEmptyString<T>(value: T): boolean {
  return value === null || value === undefined || value === '';
}

export const mapProductHazmatToOrderItem = (product: Product): Hazmat | undefined => {
  //first, check if there are any hazmat fields that have values.
  const truthyHazmatFields = omitBy(
    pick(product, [
      'hazmat_hazard_class',
      'hazmat_identification_number',
      'hazmat_packing_group',
      'hazmat_proper_shipping_name'
    ]),
    isNilOrEmptyString
  );
  // if there are hazmat fields with values, map those
  if (!isEmpty(truthyHazmatFields)) {
    return {
      hazard_class: truthyHazmatFields.hazmat_hazard_class,
      identification_number: truthyHazmatFields.hazmat_identification_number,
      packing_group: truthyHazmatFields.hazmat_packing_group,
      proper_shipping_name: truthyHazmatFields.hazmat_proper_shipping_name
      //there are some type discrepancies between backend-core and corrogo
      //for the hazmat properties that require a typecast
    } as Hazmat;
  }
  //otherwise just return undefined for hazmat
  return;
};

export const mapProductToCorrogoOrderItemShippingRequirements = (product: Product): OrderItemShippingRequirements => {
  return {
    product_id: product.id,
    //undefined short circuit narrows type to remove null from set of possible values,
    //which fixes type discrepancies between corrogo and backend-core
    country_of_manufacture: product.country_of_manufacture || undefined,
    description: product.description || undefined,
    //typecast to allow backend-core enum values to match corrogo SDK enum
    freight_class: product.freight_class as FreightClass,
    dimensions: {
      //using isNil prevents us from casting 0 to an empty string
      length: !isNil(product.length) ? String(product.length) : undefined,
      width: !isNil(product.width) ? String(product.width) : undefined,
      height: !isNil(product.height) ? String(product.height) : undefined,
      //typecast to allow backend-core enum values to match corrogo SDK enum
      unit: product.length_unit as LengthUnit
    },
    nmfc_item_code: product.nmfc_item_code || undefined,
    nmfc_sub_code: product.nmfc_sub_code || undefined,
    packaging_type: product.package_type ? PACKAGING_TYPES[product.package_type] : undefined,
    gross_weight: {
      value: !isNil(product.package_weight)
        ? String(product.package_weight)
        : //This typecast is a last ditch effort to allow saving form values as string|undefined,
          // as the backend type definition doesn't make sense.
          //First, the backend says that the value has to be a string,and cannot be null or undefined.
          //Second, the value cannot be 0.
          //So what are we supposed to put in here if there's no value?
          (undefined as unknown as string),
      unit: product.weight_unit as WeightUnit
    },
    piece_type: product.piece_type as PieceType,
    temperature: {
      minimum: !isNil(product.refrigeration_min_temp) ? String(product.refrigeration_min_temp) : undefined,
      maximum: !isNil(product.refrigeration_max_temp) ? String(product.refrigeration_max_temp) : undefined,
      unit: product.temp_unit as TemperatureUnit
    },
    stackable: product.stackable,
    value_per_piece: !isNil(product.value_per_piece)
      ? {
          value: String(product.value_per_piece),
          currency_code: product.value_per_piece_currency
        }
      : undefined,
    hazmat: mapProductHazmatToOrderItem(product)
  };
};
