import {useCallback} from 'react';
import {Card, Checkbox, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {DeliveryTypeEnum, FacilityDock, LoadType} from '@shipwell/tempus-sdk';
import {Filters} from 'App/data-hooks/appointments/types';
import {objectEntries} from 'App/utils/betterTypedArrayMethods';

// Necessary to repair garbage `:before` content attached to `input[type="checkbox"]` elsewhere.
import './Filters.scss';
import {toTitleCase} from 'App/utils/globals';

type FiltersPanelProps = {
  filters: Filters;
  setFilters: (filters: Filters) => unknown;
  docks: FacilityDock[];
  loadTypes: LoadType[];
};

export function FiltersPanel(props: FiltersPanelProps) {
  const {filters, setFilters, docks, loadTypes} = props;

  const onChangeDeliveryType = useCallback(
    (event: {target: HTMLInputElement}) => {
      const deliveryType = event.target.dataset.id;
      const checked = event.target.checked;
      if (!deliveryType) {
        throw new Error('Invariant Violation');
      }
      if (filters.deliveryTypes[deliveryType as DeliveryTypeEnum] === checked) return;
      setFilters({
        ...filters,
        deliveryTypes: {
          ...filters.deliveryTypes,
          [deliveryType]: checked
        }
      });
    },
    [filters, setFilters]
  );

  const onChangeDock = useCallback(
    (event: {target: HTMLInputElement}) => {
      const dockId = event.target.dataset.id;
      const checked = event.target.checked;
      if (!dockId) {
        throw new Error('Invariant Violation');
      }
      if (filters.docks[dockId] === checked) return;
      setFilters({
        ...filters,
        docks: {
          ...filters.docks,
          [dockId]: checked
        }
      });
    },
    [filters, setFilters]
  );

  const onChangeLoadType = useCallback(
    (event: {target: HTMLInputElement}) => {
      const loadTypeId = event.target.dataset.id;
      const checked = event.target.checked;
      if (!loadTypeId) {
        throw new Error('Invariant Violation');
      }
      if (filters.loadTypes[loadTypeId] === checked) return;
      setFilters({
        ...filters,
        loadTypes: {
          ...filters.loadTypes,
          [loadTypeId]: checked
        }
      });
    },
    [filters, setFilters]
  );

  return (
    <div id="sw-filters-panel" className="sw-filters-panel flex-0 flex select-none flex-col">
      <div className="sw-docks-filter flex-0 flex flex-col">
        <Card
          title="Delivery Types"
          isCollapsible
          draggableProvided={{}}
          borderless
          bodyClassName="sw-docks-filter-card-content"
        >
          <CollapsibleCardContent>
            {objectEntries(filters.deliveryTypes).map(([deliveryType, checked]) => (
              <FilterEntry
                key={deliveryType}
                id={deliveryType}
                text={toTitleCase(deliveryType)}
                checked={checked ?? false}
                onChange={onChangeDeliveryType}
              />
            ))}
          </CollapsibleCardContent>
        </Card>
      </div>

      {docks.length > 0 ? (
        <div className="sw-docks-filter flex-0 flex flex-col">
          <Card
            title="Docks"
            isCollapsible
            draggableProvided={{}}
            borderless
            bodyClassName="sw-docks-filter-card-content"
          >
            <CollapsibleCardContent>
              {docks.map((entry) => (
                <FilterEntry
                  key={entry.id}
                  id={entry.id}
                  text={entry.name}
                  checked={filters.docks[entry.id] ?? false}
                  onChange={onChangeDock}
                />
              ))}
            </CollapsibleCardContent>
          </Card>
        </div>
      ) : null}

      {loadTypes.length > 0 ? (
        <div className="sw-docks-filter flex-0 flex flex-col items-start">
          <Card
            className="p-0"
            title="Load Types"
            isCollapsible
            draggableProvided={{}}
            borderless
            bodyClassName="sw-docks-filter-card-content"
          >
            <CollapsibleCardContent>
              {loadTypes.map((entry) => (
                <FilterEntry
                  key={entry.id}
                  id={entry.id}
                  text={entry.display_name}
                  checked={filters.loadTypes[entry.id] ?? false}
                  onChange={onChangeLoadType}
                />
              ))}
            </CollapsibleCardContent>
          </Card>
        </div>
      ) : null}
    </div>
  );
}

function FilterEntry(props: {
  id: string;
  checked: boolean;
  onChange: (event: {target: HTMLInputElement}) => unknown;
  text: string;
  color?: string;
}) {
  const {id, checked, onChange, text} = props;
  return (
    <div className="sw-dock-filter-entry flex-0 m-1 flex w-full flex-row items-start justify-items-start">
      <Checkbox className="flex-0 m-1 flex size-4" data-id={id} checked={checked} onChange={onChange} />
      <span className="sw-dock-filter-name m-1 mt-2 flex flex-1 text-sm">{text}</span>
    </div>
  );
}
