import {useQuery} from '@tanstack/react-query';
import {ResourcesEnum} from '../resourcesEnum';
import {getDataFromInvocations} from './helpers';
import {listInvocationsScriptsInvocationsGet} from 'App/api/scriptlab/scripts';

export interface UseScriptsServiceProps {
  resource: ResourcesEnum;
  resourceId: string;
}

export function useScriptsService(props: UseScriptsServiceProps) {
  const scriptQuery = useQuery({
    queryKey: ['scriptsInvocationStatus', props.resource, props.resourceId],
    queryFn: async () => {
      const response = await listInvocationsScriptsInvocationsGet({
        webhookResourceType: props.resource,
        webhookResourceId: props.resourceId,
        include: ['script']
      });

      return getDataFromInvocations(response.data.data);
    },
    refetchInterval: 10 * 1000
  });

  return {
    loading: scriptQuery.isFetching,
    data: scriptQuery.data
  };
}
