import {useCallback, useEffect} from 'react';
import {selectedRateTableDetails} from 'App/actions/_shipmentDetails';
import {RATE_TYPE_PERCENT_OF_LINE_HAUL} from 'App/containers/userCompany/rateTables/constants';

export const useSurchargeCalculation = ({
  buttonId,
  isCustomer,
  customerChargeLineItems,
  vendorChargeLineItems,
  matchingCustomerFuelSurchargeRate,
  matchingVendorFuelSurchargeRate,
  fuelSurchargeCustomer,
  fuelSurchargeVendor,
  totalMiles,
  dispatch,
  setButtonId
}) => {
  const fetchRateTableLineItems = useCallback(() => {
    if (!buttonId) {
      return;
    }
    let calculateFSC = 0;
    const calculateLineHaul = isCustomer ? customerChargeLineItems : vendorChargeLineItems;
    const matchingFSC = isCustomer ? matchingCustomerFuelSurchargeRate : matchingVendorFuelSurchargeRate;
    if (buttonId === 'customer' || buttonId === 'vendor') {
      const {rate_type: rateType} = isCustomer ? fuelSurchargeCustomer : fuelSurchargeVendor;
      if (rateType === RATE_TYPE_PERCENT_OF_LINE_HAUL) {
        calculateLineHaul.forEach((lineItem) => {
          if (lineItem.category === 'LH') {
            calculateFSC = parseFloat(matchingFSC) * parseFloat(lineItem.amount);
          }
        });
      } else {
        calculateFSC = parseFloat(totalMiles) * parseFloat(matchingFSC);
      }
      const lineItems = {
        category: 'FSC',
        unit_name: `FSC Rate ${matchingFSC}`,
        unit_quantity: 1,
        unit_amount: parseFloat(calculateFSC).toFixed(2),
        prepaid_amount: 0
      };
      const payload = Object.assign(lineItems, isCustomer ? {customer: 'customer'} : {vendor: 'vendor'});
      dispatch(selectedRateTableDetails(payload));
      setButtonId(null);
    }
  }, [
    buttonId,
    setButtonId,
    dispatch,
    fuelSurchargeVendor,
    fuelSurchargeCustomer,
    isCustomer,
    matchingVendorFuelSurchargeRate,
    matchingCustomerFuelSurchargeRate,
    customerChargeLineItems,
    vendorChargeLineItems,
    totalMiles
  ]);
  useEffect(() => {
    fetchRateTableLineItems();
  }, [fetchRateTableLineItems]);
};
