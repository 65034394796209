import {Card, SvgIcon} from '@shipwell/shipwell-ui';
import {AppointmentDetailsPaper, AppointmentDetailsPaperProps} from 'App/components/appointments/paper';
import {AppointmentButtonGroup, AppointmentButtonGroupProps} from 'App/components/Buttons';

type CardTitleProps = {
  stopNumber: number;
  facility?: {
    facilityName?: string | null;
    facilityCity?: string | null;
    facilityState?: string | null;
    facilityPostalCode?: string | null;
  };
} & AppointmentButtonGroupProps;

const CardTitle = ({
  stopNumber,
  facility,
  status,
  onCancelClick,
  onScheduleClick,
  onRescheduleClick
}: CardTitleProps): JSX.Element => {
  return (
    <div className="grid w-full grid-flow-col font-normal">
      <div className="flex flex-row items-center gap-1 text-sm">
        <SvgIcon name={`Num${stopNumber}Outlined`} />
        <p className="mb-0">
          <strong>Stop {stopNumber}</strong> • {facility?.facilityName}, {facility?.facilityCity},{' '}
          {facility?.facilityState}, {facility?.facilityPostalCode}
        </p>
      </div>
      <div className="flex flex-row gap-x-1 justify-self-end">
        <AppointmentButtonGroup
          status={status}
          onCancelClick={onCancelClick}
          onScheduleClick={onScheduleClick}
          onRescheduleClick={onRescheduleClick}
        />
      </div>
    </div>
  );
};

export type AppointmentCardProps = {
  stopNumber: number | string;
} & Pick<
  AppointmentDetailsPaperProps,
  'startTime' | 'endTime' | 'deliveryType' | 'dockName' | 'isFirstComeFirstServe' | 'appointmentDate'
> &
  CardTitleProps;
/**
 * Component for when a user comes to select an actual appointment related to a specific stop.
 */
const AppointmentCard = ({
  stopNumber,
  facility,
  deliveryType,
  dockName,
  status,
  startTime,
  endTime,
  isFirstComeFirstServe,
  appointmentDate,
  onCancelClick,
  onScheduleClick,
  onRescheduleClick
}: AppointmentCardProps): JSX.Element => {
  const isStatusNotCancelled = status !== 'CANCELLED';

  return (
    <Card
      draggableProvided={null}
      title={
        <CardTitle
          stopNumber={stopNumber}
          facility={facility}
          status={status}
          onRescheduleClick={onRescheduleClick}
          onCancelClick={onCancelClick}
          onScheduleClick={onScheduleClick}
        />
      }
    >
      <AppointmentDetailsPaper
        startTime={isStatusNotCancelled ? startTime : null}
        endTime={isStatusNotCancelled ? endTime : null}
        isFirstComeFirstServe={isFirstComeFirstServe}
        deliveryType={isStatusNotCancelled ? deliveryType : null}
        dockName={isStatusNotCancelled ? dockName : null}
        appointmentDate={appointmentDate}
      />
    </Card>
  );
};

export default AppointmentCard;
