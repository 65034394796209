import classNames from 'classnames';
import {ReactNode} from 'react';

export const FinancialsGridRow = ({
  hasMismatchedTotals,
  children,
  columnCount,
  isNewFinancialsTable = false
}: {
  hasMismatchedTotals?: boolean;
  children: ReactNode;
  columnCount: number;
  isNewFinancialsTable?: boolean;
}) => {
  const gridTemplateColumnsStyle = isNewFinancialsTable
    ? `300px 200px repeat(${columnCount}, minmax(120px, 120px)) 50px 1fr`
    : `repeat(${columnCount}, minmax(0, 1fr))`;
  return (
    <div
      className={classNames('grid grid-cols-4 items-center justify-between px-4 py-1 whitespace-nowrap gap-5', {
        'text-sw-error': hasMismatchedTotals
      })}
      style={{gridTemplateColumns: gridTemplateColumnsStyle}}
    >
      {children}
    </div>
  );
};

export const FinancialsSubTotalRow = ({label, total}: {label: string; total: ReactNode}) => {
  return (
    <div className="flex items-center justify-between px-4 py-2 font-semibold">
      <div>{label}</div>
      <div>{total}</div>
    </div>
  );
};

export const FinancialsGrandTotalRow = ({label, total}: {label: string; total: ReactNode}) => {
  return (
    <div className="mt-1 flex items-center justify-between rounded bg-sw-success-background px-4 py-3 font-semibold text-sw-success">
      <div>{label}</div>
      <div>{total}</div>
    </div>
  );
};

export const CompanyName = ({children}: {children: ReactNode}) => (
  <div className="flex items-center truncate text-xs uppercase text-sw-text-section-title">{children}</div>
);

export const TextCell = ({children}: {children: string | undefined | null}) => (
  <div className="flex items-center overflow-hidden text-ellipsis" title={children || undefined}>
    {children}
  </div>
);

export const FinancialsTableHeader = ({
  children,
  columnCount,
  isNewFinancialsTable = false
}: {
  children: ReactNode;
  columnCount: number;
  isNewFinancialsTable?: boolean;
}) => {
  const gridTemplateColumnsStyle = isNewFinancialsTable
    ? `300px 200px repeat(${columnCount}, minmax(120px, 120px)) 50px 1fr`
    : `repeat(${columnCount}, minmax(0, 1fr))`;
  return (
    <div className="grid gap-5 truncate px-4 pb-2" style={{gridTemplateColumns: gridTemplateColumnsStyle}}>
      {children}
    </div>
  );
};
