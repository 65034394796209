import uniq from 'lodash/uniq';

export const getTenderBulkOperationBody = (values, selectedShipmentIds, shipmentModes) => {
  // if values are returned from a contract, mode will be the mode id, otherwise it will be the mode code
  // mode code is what is requested by the BE
  const modeCode =
    typeof values.mode === 'number' ? shipmentModes.find((mode) => mode.id === values.mode)?.code : values.mode;
  const tenderToCompanyUserIds = uniq(
    values.tender_to_company.reduce((carrierArray, carrierObj) => {
      carrierArray.push(carrierObj.id);
      return carrierArray;
    }, [])
  );

  return {
    resource_type: 'shipment',
    workflow: {
      name: 'Bulk op',
      start_events: [
        {
          step_id: 'STEP_1',
          trigger: {
            trigger_type: 'NONE',
            outputs: [
              {name: 'shipment_id', data_type: 'String', push_to_list: null},
              {name: 'shipment', data_type: 'Shipment', push_to_list: null}
            ]
          },
          next_step: {
            step_id: 'STEP_2'
          }
        }
      ],
      end_events: [
        {
          step_id: 'STEP_END',
          should_terminate: true
        }
      ],
      actions: [
        {
          action_id: 'TENDER',
          step_id: 'STEP_2',
          inputs: [
            {
              name: 'shipment_id',
              data_type: 'String',
              is_required: true
            }
          ],
          outputs: [
            {
              data_type: 'Tender',
              name: 'tender',
              push_to_list: null
            }
          ],
          params: [
            {
              name: 'involved_tender_to_company_users',
              value: tenderToCompanyUserIds
            },
            ...(values.charge_line_items?.[0]
              ? [
                  {
                    name: 'rate',
                    value: values.charge_line_items[0].unit_amount
                  },
                  {
                    name: 'rate_currency',
                    value: values.charge_line_items[0].unit_amount_currency
                  }
                ]
              : []),
            ...(values.rate_type
              ? [
                  {
                    name: 'rate_type',
                    // if values are returned from a contract they will be returned as an object, otherwise it will a string
                    // BE wants the string value
                    value: typeof values.rate_type === 'string' ? values.rate_type : values.rate_type.value
                  }
                ]
              : []),
            {
              name: 'info_message',
              value: values.info_message
            },
            {
              name: 'expires_after_seconds',
              value: values.expires_after_seconds.value
            },
            {
              name: 'mode',
              value: modeCode
            },
            {
              name: 'equipment_type',
              value: values.equipment_type
            },
            {
              name: 'tender_to_company',
              value: values.tender_to_company[0].carrier
            },
            {
              name: 'contract_id',
              value: values?.contract ?? null
            }
          ],
          attached_triggers: [
            {
              trigger: {
                trigger_type: 'TIMER',
                start_after_seconds: values.expires_after_seconds.value
              },
              next_step_id: 'STEP_END'
            }
          ]
        }
      ]
    },
    criteria: {
      id: {
        $in: selectedShipmentIds
      }
    }
  };
};
