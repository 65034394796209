import {Field} from 'formik';
import PropTypes from 'prop-types';
import {FormikSelect} from '@shipwell/shipwell-ui';
import {useModesQuery} from 'App/data-hooks';

const ModesField = ({name, optionsWhiteList, optionsBlackList, required, ...props}) => {
  const modesQuery = useModesQuery();
  const modes = modesQuery.data || [];
  const refineModesOptions = () => {
    return modes.filter((mode) => {
      if (optionsWhiteList.length > 0 && !optionsWhiteList.includes(mode.code)) {
        return false;
      }
      if (optionsBlackList.length > 0 && optionsBlackList.includes(mode.code)) {
        return false;
      }
      return true;
    });
  };

  return (
    <Field
      required={required}
      options={refineModesOptions()}
      getOptionLabel={(option) => option.description}
      getOptionValue={(option) => option.id}
      label="Modes"
      name={name || 'modes'}
      isMulti
      component={FormikSelect}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

ModesField.propTypes = {
  name: PropTypes.string.isRequired,
  optionsWhiteList: PropTypes.array,
  optionsBlackList: PropTypes.array,
  required: PropTypes.bool
};

ModesField.defaultProps = {
  optionsWhiteList: [],
  optionsBlackList: [],
  required: false
};

export default ModesField;
