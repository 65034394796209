import {Component} from 'react';
import defer from 'lodash/defer';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import omit from 'lodash/omit';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {SubmissionError} from 'redux-form';
import {Grid} from '@material-ui/core';
import {Card, CollapsibleCardContent, Title, Toast} from '@shipwell/shipwell-ui';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import pluralize from 'pluralize';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import {StopEtaLastUpdated, StopEtaPill} from 'App/components/stopEta';
import StopEta from 'App/containers/Shipment/components/stopEta';
import StopHeader from 'App/containers/Shipment/components/StopHeader';
import {bind} from 'App/utils/camelize';
import * as shipmentActions from 'App/actions/shipments';
import * as shipmentdetailsActions from 'App/actions/shipmentdetails';
import ShipmentStopsExpandedUpdateForm from 'App/containers/Shipment/forms/ShipmentStopsExpandedUpdateForm';
import {
  unpackErrors,
  determineToFrom,
  formatHrMinSec,
  formatTime,
  formatDayOfWeekDateTime,
  hasPlanningWindownFlag,
  applyPlanningWindowRules,
  createStopIndexDate
} from 'App/utils/globals';
import {checkShipmentModes, unpackShipmentErrors} from 'App/utils/globalsTyped';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import {trackEditStops, trackSubmitStops} from 'App/utils/analytics';
import {withCustomFieldsProvider} from 'App/data-hooks';
import {getCustomDataPath} from 'App/utils/customDataPath';
import getNil from 'App/utils/getNil';
import withFlags from 'App/utils/withFlags';
import './_shipment-stops.scss';
import {AppointmentCreationModal} from 'App/containers/appointments';
import {StopAppointmentDetails} from 'App/containers/Shipment/components/StopAppointment';
import withQueryClient from 'App/components/withQueryClient';
import {ShipmentContainerDetails} from 'App/containers/Shipment/ShipmentContainerDetails';
import {SHIPMENT_STOPS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getOrderUrl} from 'App/containers/Shipment/utils/orderUtils';
import {Tag} from 'App/components/Tags';
import {packagingTypeOptions} from 'App/containers/Shipment/components/ShipmentItems/AssembleHandlingUnits/utils';
import {FlexBox} from 'App/components/Box';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

export class ShipmentStops extends Component {
  constructor(props) {
    super(props);

    bind(this, [
      'cancelEdit',
      'handleSubmit',
      'renderEditStops',
      'transformShipment',
      'updated',
      'closeAppointmentScheduler'
    ]);

    this.state = {
      selectedStopId: null,
      errors: false,
      showError: false,
      errorContent: [],
      editStopItems: false,
      editingAccessorials: false,
      editingStop: null,
      showShipmentAppointmentScheduler: false
    };
  }

  cancelEdit() {
    this.setState(
      {
        editStopItems: false,
        editAccessorials: false
      },
      () => {
        this.closePopover();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }
    );
  }

  closePopover() {
    const popover = document.getElementById('popover-actions');

    if (popover) {
      popover.classList.remove('in');
      popover.classList.add('out');
    }
  }

  closeAppointmentScheduler() {
    this.setState({
      showShipmentAppointmentScheduler: false
    });
  }

  updated() {
    this.editStops(null);
    // rerender stops after saving
    this.props.constructMarkerArray(this.props.stops);
    defer(() => {
      this.props.fetchDetails(this.props.one.id);
    });
  }

  editStops(stop) {
    if (stop !== null) {
      trackEditStops();
    }
    this.setState(
      {
        editStopItems: !this.state.editStopItems,
        editingStop: stop,
        editingAccessorials: false
      },
      () => {
        this.closePopover();
      }
    );
  }

  editAccessorials() {
    this.setState({
      editStopItems: !this.state.editStopItems,
      editingStop: null,
      editingAccessorials: true
    });
  }

  transformShipment(stops) {
    const transformedShipment = {};
    let timeToggle;
    transformedShipment.stops = stops;
    for (let i = 0; i < stops.length; i++) {
      // TITAN-4210: set the address.id to null to force backend to create a new address id
      if (
        transformedShipment.stops[i]?.location?.address &&
        isUndefined(transformedShipment.stops[i]?.location?.address?.id)
      ) {
        transformedShipment.stops[i].location.address.id = null;
      }
      timeToggle = stops[i]['schedule-select'];

      if (transformedShipment.stops[i].location) {
        transformedShipment.stops[i].location.facility_id = stops[i].location?.facility_id;
      }

      // if integer, map to object
      if (
        stops[i].location.location_type &&
        stops[i].location.location_type.id &&
        parseInt(stops[i].location.location_type.id, 10)
      ) {
        transformedShipment.stops[i].location.location_type = this.props.locationTypes.filter(
          (type) => type.id === parseInt(stops[i].location.location_type.id)
        )[0];
      } else {
        //default to business
        transformedShipment.stops[i].location.location_type = {name: 'Business (with dock or forklift)', id: 1};
      }

      // set overridden by of eta is changed
      if (stops[i].override_eta) {
        transformedShipment.stops[i].eta_overridden_by = this.props.user.id;
      }

      //next line wont be necessary if date is delivered correctly from backend
      if (stops[i].planned_date) {
        transformedShipment.stops[i].planned_date = moment(stops[i].planned_date).format('MM/DD/YYYY');
      }

      //for FTL, there are dates on all stops
      if (!stops[i].planned_date && i === 0) {
        transformedShipment.stops[i].planned_date = moment().format('YYYY-MM-DD');
      } else if (!stops[i].planned_date && i > 0) {
        transformedShipment.stops[i].planned_date = moment().add(2, 'days').format('YYYY-MM-DD');
      }

      //set ordinal index
      const initialOrdinalIndex = getNil(stops, `[0].ordinal_index`, 0);
      stops[i].ordinal_index = i + initialOrdinalIndex;

      if (
        stops[i].confirmed_arrival_at &&
        typeof stops[i].confirmed_arrival_at === 'string' &&
        stops[i].confirmed_arrival_at.includes(',') &&
        stops[i].location &&
        stops[i].location.address &&
        stops[i].location.address.timezone
      ) {
        transformedShipment.stops[i].confirmed_arrival_at = stops[i].confirmed_arrival_at;
      }
      if (
        stops[i].confirmed_departure_at &&
        typeof stops[i].confirmed_departure_at === 'string' &&
        stops[i].confirmed_departure_at.includes(',') &&
        stops[i].location &&
        stops[i].location.address &&
        stops[i].location.address.timezone
      ) {
        transformedShipment.stops[i].confirmed_departure_at = stops[i].confirmed_departure_at;
      }
      if (hasPlanningWindownFlag(this.props.flags)) {
        const timezone = transformedShipment?.location?.address?.address?.timezone || moment.tz.guess();
        if (this.props.isFTL || (this.props.isLTL && stops[i].appointment_needed)) {
          transformedShipment.planning_window = applyPlanningWindowRules(stops[i], timeToggle, i, timezone);
        } else {
          transformedShipment.planning_window = {
            start: stops[i].planning_window?.start || createStopIndexDate(i, 8, timezone),
            end: stops[i].planning_window?.end || createStopIndexDate(i, 18, timezone)
          };
        }
        delete transformedShipment.planned_date;
        delete transformedShipment.planned_time_window_end;
        delete transformedShipment.planned_time_window_start;
      } else {
        if (this.props.isFTL || (this.props.isLTL && stops[i].appointment_needed)) {
          //special handling for windows on FTL & LTL/VLTL
          if (timeToggle === '1' || timeToggle === 1) {
            if (!stops[i].planned_time_window_start || stops[i].planned_time_window_start === 'null') {
              transformedShipment.stops[i].planned_time_window_start = '8:00';
              transformedShipment.stops[i].planned_time_window_end =
                transformedShipment.stops[i].planned_time_window_start;
            } else {
              //use the same time for both fields if Arrive at Time
              transformedShipment.stops[i].planned_time_window_start = formatTime(
                stops[i].planned_time_window_start,
                false,
                null,
                formatHrMinSec
              );
              transformedShipment.stops[i].planned_time_window_end =
                transformedShipment.stops[i].planned_time_window_start;
            }
          } else if (timeToggle === '2' || timeToggle === 2) {
            if (!stops[i].planned_time_window_start || stops[i].planned_time_window_start === 'null') {
              transformedShipment.stops[i].planned_time_window_start = '8:00';
            } else {
              //use both times for Arrive during Time Range
              transformedShipment.stops[i].planned_time_window_start = formatTime(
                stops[i].planned_time_window_start,
                false,
                null,
                formatHrMinSec
              );
            }

            if (!stops[i].planned_time_window_end || stops[i].planned_time_window_end === 'null') {
              transformedShipment.stops[i].planned_time_window_end = '18:00';
            } else {
              transformedShipment.stops[i].planned_time_window_end = formatTime(
                stops[i].planned_time_window_end,
                false,
                null,
                formatHrMinSec
              );
            }
          } else if (timeToggle === '3' || timeToggle === 3) {
            if (!stops[i].planned_time_window_end || stops[i].planned_time_window_end === 'null') {
              transformedShipment.stops[i].planned_time_window_end = '18:00';
            } else {
              //just use the end time for Arrive Before Time
              transformedShipment.stops[i].planned_time_window_end = formatTime(
                stops[i].planned_time_window_end,
                false,
                null,
                formatHrMinSec
              );
            }
            transformedShipment.stops[i].planned_time_window_start = null;
          } else if (timeToggle === '4' || timeToggle === 4) {
            if (!stops[i].planned_time_window_start || stops[i].planned_time_window_start === 'null') {
              transformedShipment.stops[i].planned_time_window_start = '8:00';
            } else {
              //just use the start time for Arrive After Time
              transformedShipment.stops[i].planned_time_window_start = formatTime(
                stops[i].planned_time_window_start,
                false,
                null,
                formatHrMinSec
              );
            }
            transformedShipment.stops[i].planned_time_window_end = null;
          }
        } else {
          // not FTL or LTL/VLTL
          if (!stops[i].planned_time_window_start || stops[i].planned_time_window_start === 'null') {
            //default dock hours
            transformedShipment.stops[i].planned_time_window_start = '8:00';
          } else {
            //when loading from address book, format should already be correct
            transformedShipment.stops[i].planned_time_window_start = formatTime(
              stops[i].planned_time_window_start,
              false,
              null,
              formatHrMinSec
            );
          }
          if (!stops[i].planned_time_window_end || stops[i].planned_time_window_end === 'null') {
            transformedShipment.stops[i].planned_time_window_end = '18:00';
          } else {
            transformedShipment.stops[i].planned_time_window_end = formatTime(
              stops[i].planned_time_window_end,
              false,
              null,
              formatHrMinSec
            );
          }
        }
        delete transformedShipment.planning_window;
      }
    }
    return transformedShipment.stops;
  }

  async handleSubmit() {
    trackSubmitStops();
    // don't overwrite po # values with stale form values
    const attrs = omit(this.props.shipmentStopsUpdateForm.values, 'purchase_order_number');
    const shipmentId = this.props.shipmentId || this.props.one.id;
    let stops = attrs.stops;
    if (stops) {
      stops = this.transformShipment(stops);
    }

    let initialStops = [];
    const currentStops = stops;
    let stopDateChanged = false;
    if (
      this.props.shipmentStopsUpdateForm &&
      this.props.shipmentStopsUpdateForm.initial &&
      this.props.shipmentStopsUpdateForm.initial.stops
    ) {
      initialStops = this.props.shipmentStopsUpdateForm.initial.stops;
    }

    if (attrs.drayage_estimated_arrival_date) {
      attrs.drayage_estimated_arrival_date = moment(attrs.drayage_estimated_arrival_date).isValid()
        ? moment.utc(attrs.drayage_estimated_arrival_date).format('YYYY-MM-DD')
        : null;
    }
    if (attrs.drayage_release_date) {
      attrs.drayage_release_date = moment(attrs.drayage_release_date).isValid()
        ? moment.utc(attrs.drayage_release_date).format('YYYY-MM-DD')
        : null;
    }
    if (attrs.drayage_last_free_date) {
      attrs.drayage_last_free_date = moment(attrs.drayage_last_free_date).isValid()
        ? moment.utc(attrs.drayage_last_free_date).format('YYYY-MM-DD')
        : null;
    }
    if (attrs.drayage_container_return_date) {
      attrs.drayage_container_return_date = moment(attrs.drayage_container_return_date).isValid()
        ? moment.utc(attrs.drayage_container_return_date).format('YYYY-MM-DD')
        : null;
    }

    initialStops.forEach((stop, index) => {
      if (initialStops[index] && currentStops[index] && initialStops.length === currentStops.length) {
        if (
          !moment(initialStops[index].planned_date, 'YYYY-MM-DD').isSame(
            moment(currentStops[index].planned_date, 'MM/DD/YYYY'),
            'day'
          )
        ) {
          //day was changed
          stopDateChanged = true;
        }
      } else {
        //a stop must have been removed
        stopDateChanged = true;
      }
    });

    const payload = Object.assign(attrs, {stops: stops});
    try {
      const response = await this.props.shipmentsShipmentIdPut(shipmentId, payload, {});
      if (stopDateChanged) {
        this.props.triggerCallCancel();
      }
      // set query cached data for the shipment stops
      await this.props.queryClient.setQueryData([SHIPMENT_STOPS_QUERY_KEY, shipmentId], response.payload.stops);

      for (let i = 0; i < response.payload.stops.length; ++i) {
        const stop = response.payload.stops[i];
        // invalidate all the queries which may be using the shipment stops specifically
        await this.props.queryClient.invalidateQueries([SHIPMENT_STOPS_QUERY_KEY, shipmentId, stop.id]);
      }
      this.updated(response);
    } catch (error) {
      const errors = error.field_errors || [];
      const submissionError = unpackErrors(errors, {});
      // metadata info is not a part of this form so a specific toast error is displayed
      // this prevents the user from looking through the form for an error that is located elsewhere
      const errorMessages = unpackShipmentErrors(error, '', ['accessorials', 'stops', 'line_items']);
      if (Array.isArray(errorMessages)) {
        this.setState({errorContent: errorMessages});
        throw new SubmissionError();
      } else {
        submissionError._error = error.error_description;
        // handle edge cases
        if (errors[0].field_errors[0] === 'Stop ordinal indexes must be unique') {
          submissionError._error = 'Stop order numbers must be unique.';
        }
        throw new SubmissionError(submissionError);
      }
    }
  }

  renderEditStops() {
    return (
      <ShipmentStopsExpandedUpdateForm
        stop={this.state.editingStop}
        updated={this.updated}
        cancelEdit={this.cancelEdit}
        onSubmit={this.handleSubmit}
        customFields={this.props.customFields}
        setAddOrderSuccess={this.props.setAddOrderSuccess}
        setAddOrderError={this.props.setAddOrderError}
      />
    );
  }

  displayFormattedAccessorials(array) {
    return (
      <ul className="rw-multiselect-taglist">
        {array.map((item, i) => {
          return <li key={i}>{item.description}</li>;
        })}
      </ul>
    );
  }

  displayAccessorials(array) {
    let list = [];
    // return list of descriptions
    if (array) {
      array.forEach((item) => {
        list.push(item.description);
      });
    }
    if (list.length > 1) {
      list = list.join(', ');
    } else if (list.length === 1) {
      list = list.toString();
    } else {
      list = null;
    }
    return list;
  }

  render() {
    const {
      isLTL,
      customFields = [],
      tripManagementPredictedEta,
      one,
      stops = [],
      shipmentAccessorials = [],
      user = null,
      is_quoting_limited_user: isQuotingLimitedUser = false
    } = this.props;
    const transformedStops = one;
    const hasStops = stops.length === 0 ? false : true;
    const hasCarrier = one && one.current_carrier ? true : false;
    const perms = user ? user.permissions : [];
    const canEdit =
      perms &&
      (perms.includes(UPDATE_SHIPMENTS_USER_PERMISSION) || perms.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)) &&
      !isQuotingLimitedUser
        ? true
        : false;
    let hasDrayage = false;

    if (one && one.mode) {
      ({hasDrayage} = checkShipmentModes(one.mode));
    }

    const showShipmentContainerDetails =
      hasDrayage && Boolean(one.drayage_container_number && one.drayage_carrier_scac_code && one.bol_number);

    return (
      <div className="shipment__stops">
        {(this.state.editStopItems || !hasStops) && (
          <div className="form-update-stops">
            {!hasStops && <h2 className="shipment__stops-none">Stops</h2>}

            {this.renderEditStops()}
          </div>
        )}

        <div className={`${this.state.editStopItems ? 'hide' : 'show'}`}>
          {hasDrayage && (
            <div className="shipment__stop">
              <h3 className="shipment__stop-header border-bottom">
                Terminal Schedule
                {canEdit && (
                  <Link className="btn-action" onClick={() => this.editStops(0)}>
                    <i className="flaticon-edit action-link" title="edit" />
                  </Link>
                )}
              </h3>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <div className="terminal-date">
                    <strong>Estimated Container Arrival</strong>
                    <br />
                    {moment(one.drayage_estimated_arrival_date).isValid()
                      ? moment(one.drayage_estimated_arrival_date).format('MM/DD/YYYY')
                      : '--'}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="terminal-date">
                    <strong>Release Date</strong>
                    <br />
                    {moment(one.drayage_release_date).isValid()
                      ? moment(one.drayage_release_date).format('MM/DD/YYYY')
                      : '--'}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="terminal-date">
                    <strong>Last Free Date</strong>
                    <br />
                    {moment(one.drayage_last_free_date).isValid()
                      ? moment(one.drayage_last_free_date).format('MM/DD/YYYY')
                      : '--'}
                  </div>
                </Grid>
                <Grid item xs={6} md={3}>
                  <div className="terminal-date">
                    <strong>Container Return Date</strong>
                    <br />
                    {moment(one.drayage_container_return_date).isValid()
                      ? moment(one.drayage_container_return_date).format('MM/DD/YYYY')
                      : '--'}
                  </div>
                </Grid>
              </Grid>
            </div>
          )}

          {showShipmentContainerDetails ? (
            <Card className="shipment__stopcard" title="Container Details" isCollapsible>
              <CollapsibleCardContent>
                <ShipmentContainerDetails shipmentId={one?.id || ''} />
              </CollapsibleCardContent>
            </Card>
          ) : null}

          {hasStops &&
            transformedStops.stops.map((item, i) => {
              const hasContacts = item.location.point_of_contacts.length > 0;
              const locationName = item.location.location_name;
              const hasConfirmedArrival = item.confirmed_arrival_at && moment(item.confirmed_arrival_at).isValid();
              const hasUnconfirmedArrival =
                item.unconfirmed_arrival_at && moment(item.unconfirmed_arrival_at).isValid();
              const hasConfirmedDeparture =
                item.confirmed_departure_at && moment(item.confirmed_departure_at).isValid();
              const hasUnconfirmedDeparture =
                item.unconfirmed_departure_at && moment(item.unconfirmed_departure_at).isValid();
              const timezone =
                item.location && item.location.address && item.location.address.timezone
                  ? item.location.address.timezone
                  : moment.tz.guess();
              const arrivalTimeToShow = hasConfirmedArrival
                ? formatDayOfWeekDateTime(item.confirmed_arrival_at, true, timezone)
                : hasUnconfirmedArrival
                ? formatDayOfWeekDateTime(item.unconfirmed_arrival_at, true, timezone)
                : hasConfirmedDeparture || hasUnconfirmedDeparture
                ? '--'
                : null;
              const departureTimeToShow = hasConfirmedDeparture
                ? formatDayOfWeekDateTime(item.confirmed_departure_at, true, timezone)
                : hasConfirmedArrival || hasUnconfirmedArrival
                ? '--'
                : null;

              const formattedTripManagementETA = moment(item.trip_management_eta).isValid()
                ? formatDayOfWeekDateTime(item.trip_management_eta, true, timezone)
                : null;

              const getStopLineItems = () => {
                // if stop is not a dropoff, we don't want to show handling unit info
                if (!item.is_dropoff) {
                  return;
                }

                // get any lineItems whose dropoff id matches this stop's id
                return one.line_items?.filter((lineItem) => {
                  // check that line item has pieces (required to be a handling unit)
                  if (!lineItem?.pieces?.length) {
                    return false;
                  }
                  const prevItem = transformedStops.stops?.[i - 1];
                  // find current index, get prev stop by index and check that origin stop ids match
                  return lineItem?.origin_stop === prevItem?.id && lineItem?.destination_stop === item?.id;
                });
              };

              const getReadablePackageType = (packageTypeEnum) =>
                packagingTypeOptions.find((option) => option.value === packageTypeEnum).label;
              return (
                <Card
                  key={item.id}
                  className="shipment__stopcard"
                  title={<StopHeader stop={item} title={`Stop ${i + 1}`} />}
                  isCollapsible
                >
                  <CollapsibleCardContent>
                    <div className="shipment__stop">
                      <h3 className={`shipment__stop-header border-bottom`}>
                        {/*Show stop eta summary for LTL/final mile shipments (final mile logged as LTL mode) */}
                        {!tripManagementPredictedEta || isLTL ? (
                          <StopEta
                            stop={item}
                            stops={transformedStops.stops}
                            shipmentState={one.state}
                            isLTL={isLTL}
                            hasCarrier={hasCarrier}
                            timezone={timezone}
                            departureTimeToShow={departureTimeToShow}
                          />
                        ) : null}
                        <div className="stop-title">
                          <i className={`stop-marker icon icon-marker-${i + 1}`} />

                          {`${determineToFrom(
                            item,
                            this.props.one && this.props.one.state === 'delivered'
                              ? true
                              : departureTimeToShow
                              ? true
                              : false
                          )} ${locationName}`}

                          {canEdit && (
                            <Link
                              className="btn-action"
                              onClick={() => this.editStops(i)}
                              // to keep the "add orders" button visible, don't scroll
                              // to the stop if it's the first stop.
                              href={i === 0 ? '#' : `#stop-${i + 1}`}
                            >
                              <i className="flaticon-edit action-link" title="edit" />
                            </Link>
                          )}
                        </div>
                      </h3>

                      <div className={`shipment__stop--location ${this.state.editStopItems ? 'hide' : 'show'}`}>
                        {item.location.company_name && (
                          <p>
                            <b>{item.location.company_name}</b>
                          </p>
                        )}
                        <p>{item.location.address && item.location.address.formatted_address}</p>

                        {item.location.location_type && <p>{item.location.location_type.name}</p>}

                        <p>
                          {item.accessorials && item.accessorials.length > 0 && 'Accessorials: '}
                          {this.displayAccessorials(item.accessorials)}
                        </p>

                        {customFields?.length > 0 &&
                          customFields.map((cf) => {
                            const customFieldValue = get(
                              item,
                              `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentStop)}.${cf.id}`,
                              null
                            );
                            return <p key={`${cf.id}${item.id}`}>{`${cf.label}: ${customFieldValue || '--'}`}</p>;
                          })}

                        <div className="stop-hours">
                          <p>
                            <span className="text-sw-form-helper-text">Planned:</span>{' '}
                            {item.display_planned_window ?? 'A planned date has not been set.'}
                          </p>
                          {/*if carrier has not arrived at stop, there's a trip management eta, and shipment is not LTL,
                            show the trip management predicted ETA */}
                          {!arrivalTimeToShow && formattedTripManagementETA && tripManagementPredictedEta && !isLTL ? (
                            <p className="grid grid-cols-[1fr] items-center gap-1 lg:grid-cols-[1.5fr,1.5fr,3fr]">
                              <div>
                                <span className="text-sw-form-helper-text">ETA:</span>
                                {` ${formattedTripManagementETA}`}
                              </div>

                              <FlexBox justify="center">
                                <StopEtaPill stop={item} size="sm" duration="hours" />
                              </FlexBox>
                              <StopEtaLastUpdated stop={item} shipment={one} fetchDetails={this.props.fetchDetails} />
                            </p>
                          ) : null}

                          {arrivalTimeToShow ? (
                            <p>
                              <span className="text-sw-form-helper-text">Actual Arrival:</span> {arrivalTimeToShow}
                            </p>
                          ) : null}
                          {departureTimeToShow ? (
                            <p>
                              <span className="text-sw-form-helper-text">Completed at:</span> {departureTimeToShow}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      {hasContacts && (
                        <div className={`shipment__stop--pocs ${this.state.editStopItems ? 'hide' : 'show'}`}>
                          {hasContacts &&
                            item.location.point_of_contacts.map((item, i) => {
                              return (
                                <div className={`shipment__stop--poc`} key={i}>
                                  <p>
                                    <b>
                                      {item.first_name} {item.last_name}
                                    </b>
                                  </p>
                                  <p>
                                    <i className="icon icon-Phone2 pad-right" />
                                    {item.phone_number && getPhoneHyperlink(item.phone_number)}
                                  </p>
                                  <p>
                                    <i className="icon icon-Mail pad-right" />{' '}
                                    {item.email && <a href={`mailto:${item.email}`}>{item.email}</a>}
                                  </p>
                                </div>
                              );
                            })}
                          <div className="shipment__stop--instructions">
                            {item.instructions && <p className="stop-instructions">{item.instructions}</p>}
                          </div>
                        </div>
                      )}
                      <div className="flex w-full flex-col gap-2">
                        {item.related_orders ? (
                          <div className="flex w-full flex-wrap items-center gap-2 border-1 border-sw-border px-3 py-2.5">
                            <strong className="flex items-center">{item.is_pickup ? 'Pickup' : 'Delivery'}</strong>
                            <FlexBox gap="s">
                              {item.related_orders.map((order) => (
                                <Link
                                  key={order.order_id}
                                  className="inline-block"
                                  to={getOrderUrl(order.self_link, order.order_id)}
                                >
                                  <Tag
                                    tag={{name: `Order #${order.order_number}`, color: 'bg-sw-background-header m-0'}}
                                  />
                                </Link>
                              ))}
                            </FlexBox>
                          </div>
                        ) : null}
                        {/* Any handling unit info, if available */}
                        <FlexBox wrap="wrap">
                          {getStopLineItems()?.map((handlingUnit) => (
                            <Tag
                              key={handlingUnit.id}
                              tag={{
                                name: `${
                                  handlingUnit?.package_type ? getReadablePackageType(handlingUnit.package_type) : '--'
                                }: ${handlingUnit?.serial_number || '--'}`,
                                color: 'bg-sw-background-header m-0'
                              }}
                            />
                          ))}
                        </FlexBox>
                      </div>
                      {this.props.one.id ? (
                        <StopAppointmentDetails
                          shipmentId={this.props.one.id}
                          stopId={item.id}
                          onClick={() =>
                            this.setState((prevState) => ({
                              ...prevState,
                              selectedStopId: item.id,
                              showShipmentAppointmentScheduler: true
                            }))
                          }
                        />
                      ) : null}
                    </div>
                  </CollapsibleCardContent>
                </Card>
              );
            })}

          {hasStops && shipmentAccessorials && shipmentAccessorials.length > 0 && (
            <div className={`shipment-accessorials ${this.state.editStopItems ? 'hidden' : 'show'}`}>
              <Title variant="formTitle">Shipment Accessorials</Title>
              {shipmentAccessorials && shipmentAccessorials.length > 0 && (
                <div className="row-description">{this.displayAccessorials(shipmentAccessorials)}</div>
              )}

              {shipmentAccessorials && shipmentAccessorials.length === 0 && (
                <div className="row-description">No accessorials assigned.</div>
              )}
            </div>
          )}
        </div>
        {this.props.one.id ? (
          <AppointmentCreationModal
            facilityId={
              transformedStops.stops.find((stop) => stop.id === this.state.selectedStopId)?.location?.facility_id
            }
            shipmentId={this.props.one.id}
            stopId={this.state.selectedStopId}
            showModal={this.state.showShipmentAppointmentScheduler}
            onClose={this.closeAppointmentScheduler}
          />
        ) : null}
        <Toast
          variant="error"
          show={this.state.errorContent.length > 0}
          title="Error on Shipment!"
          onClose={() => this.setState({errorContent: []})}
          delay={10000}
        >
          <div className="flex flex-col gap-2 pb-4">
            {this.state.errorContent.map((error, i) => (
              <div key={i}>
                <span>{error}</span>
              </div>
            ))}
          </div>
          <span>
            {pluralize('This', this.state.errorContent.length)} {pluralize('error', this.state.errorContent.length)}{' '}
            must be corrected before any new changes can be saved.
          </span>
        </Toast>
      </div>
    );
  }
}

ShipmentStops.propTypes = {
  customFields: PropTypes.array,
  company: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  locationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number
    })
  ),
  isLTL: PropTypes.bool,
  isFTL: PropTypes.bool,
  is_quoting_limited_user: PropTypes.bool,
  one: PropTypes.shape({
    id: PropTypes.string, // don't require this or you're going to have a bad time
    current_carrier: PropTypes.any,
    drayage_container_return_date: PropTypes.string,
    drayage_release_date: PropTypes.string,
    drayage_last_free_date: PropTypes.string,
    drayage_estimated_arrival_date: PropTypes.string,
    mode: PropTypes.string,
    state: PropTypes.string,
    line_items: PropTypes.array
  }),
  queryClient: PropTypes.shape({
    setQueryData: PropTypes.func.isRequired,
    invalidateQueries: PropTypes.func.isRequired
  }),
  shipmentId: PropTypes.string, // don't require this or you're going to have a bad time
  shipmentAccessorials: PropTypes.arrayOf(PropTypes.string),
  shipmentStopsUpdateForm: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.object),
    initial: PropTypes.shape({
      stops: PropTypes.array
    })
  }),
  stops: PropTypes.array,
  tripManagementPredictedEta: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string)
  }),
  setAddOrderError: PropTypes.func.isRequired,
  setAddOrderSuccess: PropTypes.func.isRequired,
  fetchDetails: PropTypes.func.isRequired,
  constructMarkerArray: PropTypes.func.isRequired,
  triggerCallCancel: PropTypes.func.isRequired,
  shipmentsShipmentIdPut: PropTypes.func.isRequired
};

const ShipmentStopsWithCustomFields = compose(
  withLDConsumer(),
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.ShipmentStop),
  withFlags('tripManagementPredictedEta'),
  withQueryClient
)(ShipmentStops);

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    is_quoting_limited_user: state.auth.is_quoting_limited_user,
    accessorials: state.shipments.accessorialCodes,
    addressBook: state.addresses.addressbook,
    company: state.auth.company,
    locationTypes: state.addresses.locationTypes,
    shipmentStopsUpdateForm: state.form.shipmentStopsUpdateForm
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...shipmentdetailsActions,
      ...shipmentActions
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentStopsWithCustomFields);
