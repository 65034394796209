import {Route, IndexRedirect, IndexRoute, Link} from 'react-router';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import get from 'lodash/get';
import {useFlags} from 'launchdarkly-react-client-sdk';
import CompanyLoadOptimizationConfigList from './defaults/CompanyLoadOptimizationConfig/loadOptimizationConfigList';
import {TagsPage} from './tags/tags';
import withFlags from 'App/utils/withFlags';
import WithStatusToasts from 'App/components/withStatusToasts';
import withConditionalFallback from 'App/components/withConditionalFallback';
import CompanyContainer from 'App/containers/userCompany';
import {UsersTable} from 'App/containers/userCompany/users/list';
import CompanyUserCreateContainer from 'App/containers/userCompany/users/create';
import CompanyUserDetailsContainer from 'App/containers/userCompany/users/details';
import CompanyCarrierComplianceDetails from 'App/containers/userCompany/defaults/carrierCompliance/index';
import CompanyTermsDetails from 'App/containers/userCompany/terms';
import CompanyProfileDetails from 'App/containers/userCompany/profile';
import {CompanyTemplates} from 'App/containers/userCompany/templates';
import CompanyCustomData from 'App/containers/userCompany/customData';
import CompanyIntegrationsList from 'App/containers/integrations/list';
import CompanyIntegrationsDetails from 'App/containers/integrations/details';
import CompanyContractDefaults from 'App/containers/userCompany/defaults/contracts';
import CompanyRoutingGuideDefaults from 'App/containers/userCompany/defaults/routingGuides';
import CompanyFuelSurchargeTablesList from 'App/containers/userCompany/fuelSurcharges/list';
import CompanyFuelSurchargeTablesDetails from 'App/containers/userCompany/fuelSurcharges/details';
import CompanyAccessorialRatesTablesList from 'App/containers/userCompany/accessorialRates/list';
import CompanyAccessorialRateTableDetails from 'App/containers/userCompany/accessorialRates/details';
import CompanyCompassAlertsConfiguration from 'App/containers/userCompany/defaults/compassAlerts';
import {CompanyCustomerSettings} from 'App/containers/userCompany/defaults/customerSettings';
import CompanyLoadOptimizationConfig from 'App/containers/userCompany/defaults/CompanyLoadOptimizationConfig/loadOptimizationConfigDetail';
import SettlementsConfigurationsContainer from 'App/containers/settlements/configurations/SettlementsConfigurationsContainer';
import ContractsList from 'App/containers/contracts/list';
import ContractDetails from 'App/containers/contracts/details';
import SLADetails from 'App/containers/serviceLevelAgreements/details';
import ServiceLevelAgreementsList from 'App/containers/serviceLevelAgreements/list';
import CompanyRfpsBaseAlpha from 'App/containers/userCompany/rfpsAlpha';
import RFPTableAlpha from 'App/containers/userCompany/rfpsAlpha/RFPTableAlpha';
import RfpBase from 'App/containers/userCompany/rfps/RfpBase';
import RfpTableRoute from 'App/containers/userCompany/rfps/RfpTableRoute';
import {State} from 'App/reducers/types';
import {ParcelLabelsRoute} from 'App/containers/userCompany/parcelLabel/ParcelLabelsRoute';
import {ConditionalInternationalConfigurationsView} from 'App/containers/internationalization/components';

export default (
  <Route component={CompanyContainer}>
    <IndexRedirect to="/company/profile" />
    <Route path="/company/users">
      <IndexRoute component={UsersTable} />
      <Route path="/company/users/create" component={CompanyUserCreateContainer} />
      <Route path="/company/users/:userId" component={CompanyUserDetailsContainer} />
    </Route>
    <Route path="/company/profile" component={CompanyProfileDetails} />
    <Route path="/company/templates" component={CompanyTemplates} />
    <Route path="/company/credit-and-terms" component={CompanyTermsDetails} />
    <Route path="/company/custom-data" component={CompanyCustomData} />
    <Route path="/company/tags" component={TagsPage} />
    <Route path="/company/carrier-instructions" component={CompanyTermsDetails} />
    <Route path="/company/integrations">
      <IndexRoute component={CompanyIntegrationsList} />
      <Route path="/company/integrations/:integration" component={CompanyIntegrationsDetails} />
      <Route path="/company/integrations/:integration/:tab" component={CompanyIntegrationsDetails} />
    </Route>
    <Route path="/company/lane-management/contracts">
      <IndexRoute component={WithStatusToasts(ContractsList)} />
      <Route path="/company/lane-management/contracts/:contractId" component={WithStatusToasts(ContractDetails)} />
    </Route>
    <Route path="/company/lane-management/service-level-agreements">
      <IndexRoute component={WithStatusToasts(ServiceLevelAgreementsList)} />
      <Route path="/company/lane-management/service-level-agreements/:slaId" component={WithStatusToasts(SLADetails)} />
    </Route>
    <Route path="/company/defaults/contracts" component={CompanyContractDefaults} />
    <Route path="/company/defaults/routing-guides" component={CompanyRoutingGuideDefaults} />
    <Route path="/company/lane-management/accessorial-rates">
      <IndexRoute component={CompanyAccessorialRatesTablesList} />
      <Route
        path="/company/lane-management/accessorial-rates/:accessorialRateTableId"
        component={CompanyAccessorialRateTableDetails}
      />
    </Route>
    <Route path="/company/lane-management/fuel-surcharges" component={CompanyFuelSurchargeTablesList} />
    <Route
      path="/company/lane-management/fuel-surcharges/:fuelSurchargeTableId"
      component={CompanyFuelSurchargeTablesDetails}
    />
    <Route path="/company/defaults/carrier-compliance" component={CompanyCarrierComplianceDetails} />
    <Route path="/company/defaults/compass-alerts" component={CompanyCompassAlertsConfiguration} />
    <Route path="/company/defaults/customer-settings" component={CompanyCustomerSettings} />
    <Route path="/company/defaults/load-optimization-configurations" component={CompanyLoadOptimizationConfigList} />
    <Route path="/company/defaults/load-optimization-configurations/create" component={CompanyLoadOptimizationConfig} />
    <Route
      path="/company/defaults/load-optimization-configurations/:configId"
      component={CompanyLoadOptimizationConfig}
    />
    <Route path="/company/settlements-configurations">
      <IndexRoute component={SettlementsConfigurationsContainer} />
    </Route>
    <Route path="/company/lane-management/rfps_import">
      <IndexRoute component={CompanyRfpsBaseAlpha} />
      <Route path=":rfpId" component={RFPTableAlpha} />
    </Route>
    <Route path="/company/lane-management/rfps">
      <IndexRoute component={RfpBase} />
      <Route path=":rfpId" component={RfpTableRoute} />
    </Route>
    <Route path="/company/parcel-labels">
      <IndexRoute component={ParcelLabelsRoute} />
    </Route>
    <Route path="/company/international-configurations">
      <IndexRoute component={ConditionalInternationalConfigurationsView} />
    </Route>
  </Route>
);

const IntegrationsRoute = () => (
  <Link to="/company/integrations" activeClassName="active">
    Integrations
  </Link>
);

export const ConditionalIntegrationsLink = compose(
  connect((state: State) => ({
    hasIntegrationsCompanyFlagEnabled: get(state, 'userCompany.company.feature_flags.integrations_enabled', false),
    hasManageIntegrationsPermissions:
      state.userProfile.user.permissions?.includes('integrations.view_integrations') ||
      state.userProfile.user.permissions?.includes('integrations.add_integrations') ||
      state.userProfile.user.permissions?.includes('integrations.manage_integrations') ||
      state.userProfile.user.permissions?.includes('integrations.remove_integrations')
  })),
  withConditionalFallback(
    ({
      hasIntegrationsCompanyFlagEnabled,
      hasManageIntegrationsPermissions
    }: {
      hasIntegrationsCompanyFlagEnabled: boolean;
      hasManageIntegrationsPermissions: boolean;
    }) => !hasIntegrationsCompanyFlagEnabled || !hasManageIntegrationsPermissions,
    null
  )
)(IntegrationsRoute);

const FuelSurchargesRoute = () => (
  <Link className="subLink" to="/company/lane-management/fuel-surcharges" activeClassName="active">
    Fuel Surcharges
  </Link>
);

export const ConditionalFuelSurchargesLink = compose(
  withFlags('fuelSurchargeRateTable'),
  withConditionalFallback(
    ({fuelSurchargeRateTable}: {fuelSurchargeRateTable: boolean}) => !fuelSurchargeRateTable,
    null
  )
)(FuelSurchargesRoute);

const AccessorialRatesRoute = () => (
  <Link className="subLink" to="/company/lane-management/accessorial-rates" activeClassName="active">
    Accessorial Rates
  </Link>
);

export const ConditionalAccessorialRatesLink = compose(
  connect((state) => ({
    rateTablesEnabled: get(state, 'userCompany.company.feature_flags.rate_tables_enabled', false)
  })),
  withConditionalFallback(({rateTablesEnabled}: {rateTablesEnabled: boolean}) => !rateTablesEnabled, null)
)(AccessorialRatesRoute);

const CarrierComplianceRoute = () => (
  <Link to={'/company/defaults/carrier-compliance'} className="subLink" activeClassName="active">
    Carrier Compliance
  </Link>
);

export const ConditionalCarrierComplianceLink = compose(
  connect((state) => ({
    automaticCarrierPolicyEnabled:
      get(state, 'userCompany.company.brokerage.is_3pl', false) || get(state, 'userCompany.company.shipper', false)
  })),
  withConditionalFallback(
    ({automaticCarrierPolicyEnabled}: {automaticCarrierPolicyEnabled: boolean}) => !automaticCarrierPolicyEnabled,
    null
  )
)(CarrierComplianceRoute);

const CompassAlertsRoute = () => (
  <Link to="/company/defaults/compass-alerts" className="subLink" activeClassName="active">
    Compass Alerts
  </Link>
);

export const ConditionalCompassAlertsLink = compose(
  connect((state) => ({
    alertsDashBoardEnabled: get(state, 'userCompany.company.feature_flags.alerts_dashboard_enabled', false)
  })),
  withConditionalFallback(
    ({alertsDashBoardEnabled}: {alertsDashBoardEnabled: boolean}) => !alertsDashBoardEnabled,
    null
  )
)(CompassAlertsRoute);

const MileageConfigurationsLinkBase = () => (
  <Link to="/company/defaults/customer-settings" className="subLink" activeClassName="active">
    Customer Settings
  </Link>
);

export const ConditionalCustomerSettingsLink = compose(
  withFlags('gaMileageConfigurations'),
  withConditionalFallback(
    ({gaMileageConfigurations}: {gaMileageConfigurations: boolean}) => !gaMileageConfigurations,
    null
  )
)(MileageConfigurationsLinkBase);

const LoadOptimizationLink = () => (
  <Link to="/company/defaults/load-optimization-configurations" className="subLink" activeClassName="active">
    Load Optimization
  </Link>
);

export const ConditionalLoadOptimizationLink = compose(
  withFlags('gaShipmentAssemblyMultiTruck'),
  withConditionalFallback(
    ({gaShipmentAssemblyMultiTruck}: {gaShipmentAssemblyMultiTruck: boolean}) => !gaShipmentAssemblyMultiTruck,
    null
  )
)(LoadOptimizationLink);

export const ConditionalSettlementsConfigsLink = () => {
  const {stmInvoiceRuleConfigurationsDashboard} = useFlags();
  if (!stmInvoiceRuleConfigurationsDashboard) return null;
  return (
    <Link to="/company/settlements-configurations" className="subLink" activeClassName="active" role="link">
      Settlements
    </Link>
  );
};

export const ConditionalInternationalConfigsLink = () => {
  const {stmShipmentCurrencyOfRecord} = useFlags();
  if (!stmShipmentCurrencyOfRecord) return null;
  return (
    <Link to="/company/international-configurations" className="subLink" activeClassName="active" role="link">
      International Configs
    </Link>
  );
};

const RfpAlphaLink = () => (
  <Link to="/company/lane-management/rfps_import" className="subLink" activeClassName="active">
    RFP Import
  </Link>
);

export const ConditionalRfpLinkAlpha = compose(
  withFlags('wfaCompanyRfpAlpha'),
  withConditionalFallback(({wfaCompanyRfpAlpha}: {wfaCompanyRfpAlpha: boolean}) => !wfaCompanyRfpAlpha, null)
)(RfpAlphaLink);

const RfpLink = () => (
  <Link to="/company/lane-management/rfps" className="subLink" activeClassName="active">
    RFP Automation
  </Link>
);

export const ConditionalRfpLink = compose(
  withFlags('wfaCompanyRfp'),
  withConditionalFallback(({wfaCompanyRfp}: {wfaCompanyRfp: boolean}) => !wfaCompanyRfp, null)
)(RfpLink);
