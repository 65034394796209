import {useState, useCallback} from 'react';
import {useQueries} from '@tanstack/react-query';
import {
  ChargeCategory,
  Contract,
  ContractRateCurrencyEnum,
  RateAssessmentMeasurement,
  Shipment,
  ShipmentPreferredCurrencyEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import invariant from 'tiny-invariant';
import {useSelectedContract, isApplicableContract} from '../contracts/useSelectedContract';
import {ApplicableContractWithCharges} from '../contracts/useGetApplicableContracts';
import {CARRIER_CAPACITY_AVAILABILITY_QUERY_KEY, CARRIER_RELATIONSHIPS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getCarrierCapacityAvailability} from 'App/api/carriers/carrierCapacity';
import {formatCarrierPOCs} from 'App/utils/carrierHelpers';
import {getCarrierRelationshipsByIdPromise} from 'App/api/carriers/carrierRelationship';
import {RATE_TYPE_PER_HOUR} from 'App/containers/userCompany/rateTables/constants';
import {formatCurrency} from 'App/utils/internationalConstants';

interface BulkTenderContract {
  shipments: Shipment[];
  shipment?: never;
}

interface SingleTenderContract {
  shipment: Shipment;
  shipments?: never;
}

export const useTenderContract = ({shipments, shipment}: BulkTenderContract | SingleTenderContract) => {
  const [selectedMultiApplicableContracts, setSelectedMultiApplicableContracts] = useState<
    ApplicableContractWithCharges[]
  >([]);
  const selectedShipments = shipments || [shipment];

  const {selectedApplicableContract, handleContractChange, isLoading, contractCarrierRelationship, chargeLineItems} =
    useSelectedContract();

  const {contract: selectedContract} = selectedApplicableContract || {};

  // used for the following carrier capacity queries
  const shipmentIds = selectedShipments.map((shipment) => shipment.id);

  // Multiple Contracts Selected Logic
  const contractIds = selectedContract
    ? [selectedContract.id]
    : selectedMultiApplicableContracts?.map((applicableContract) => applicableContract.contract?.id);

  const carrierCapacityAvailableBulkQueries = useQueries({
    queries: shipmentIds?.flatMap((id) =>
      contractIds.map((contractId) => ({
        queryKey: [CARRIER_CAPACITY_AVAILABILITY_QUERY_KEY, id, contractId],
        queryFn: () => getCarrierCapacityAvailability({shipmentId: id, contractId: contractId || ''}),
        enabled: !!contractIds.length && !!shipmentIds.length
      }))
    )
  });
  const capacityAvailable = carrierCapacityAvailableBulkQueries.every((query) => query.isSuccess)
    ? carrierCapacityAvailableBulkQueries.every((query) => query.data?.data.capacity_available)
    : true;

  const carrierRelationshipsQueries = useQueries({
    queries: selectedMultiApplicableContracts.map((applicableContract) => ({
      queryKey: [CARRIER_RELATIONSHIPS_QUERY_KEY, applicableContract.contract?.carrier_relationship],
      queryFn: () => getCarrierRelationshipsByIdPromise(applicableContract.contract?.carrier_relationship || ''),
      enabled: !!selectedMultiApplicableContracts.length
    }))
  });

  const getShipmentEquipmentAndMode = () => {
    let equipmentType;
    let mode;
    if (!shipment) {
      const equipmentTypes = shipments.map((shipment) => shipment.equipment_type?.machine_readable);
      assertAllEqual(equipmentTypes, 'equipment_type');
      equipmentType = equipmentTypes[0];

      const modes = shipments.map((shipment) => shipment.mode?.id);
      assertAllEqual(modes, 'mode');
      mode = modes[0];

      const preferredCurrencies = shipments.map((shipment) => shipment.preferred_currency);
      assertAllEqual(preferredCurrencies, 'preferred_currency');
    } else {
      equipmentType = shipment.equipment_type?.machine_readable;
      mode = shipment.mode?.id;
    }

    return {equipmentType, mode};
  };

  //returns a single payload object if tendering using a single contract
  const getContractValues = (formValues?: {
    expires_at?: string;
    expires_after_seconds?: string;
    info_message: string;
    contractRateCurrency?: ShipmentPreferredCurrencyEnum;
  }) => {
    const {equipmentType, mode} = getShipmentEquipmentAndMode();

    const payload = {
      contract: selectedContract?.id,
      equipment_type: equipmentType,
      ...(formValues?.expires_at ? {expires_at: formValues.expires_at} : {}),
      ...(formValues?.expires_after_seconds ? {expires_after_seconds: formValues.expires_after_seconds} : {}),
      info_message: formValues?.info_message || '',
      message: '',
      mode,
      shipment: shipmentIds,
      tender_to_company: formatCarrierPOCs([contractCarrierRelationship])
    };
    return payload;
  };

  //returns an array of payloads to POST if tendering using multiple contracts
  const getMultiContractsValues = (formValues?: {
    expires_at?: string;
    expires_after_seconds?: string;
    info_message: string;
    contractRateCurrency?: ShipmentPreferredCurrencyEnum;
  }) => {
    const {equipmentType, mode} = getShipmentEquipmentAndMode();

    const payloads = selectedMultiApplicableContracts.map((applicableContract, index) => ({
      contract: applicableContract.contract?.id,
      equipment_type: equipmentType,
      ...(formValues?.expires_at ? {expires_at: formValues.expires_at} : {}),
      ...(formValues?.expires_after_seconds ? {expires_after_seconds: formValues.expires_after_seconds} : {}),
      info_message: formValues?.info_message || '',
      message: '',
      mode,
      shipment: shipmentIds,
      tender_to_company: formatCarrierPOCs([carrierRelationshipsQueries[index].data?.data])
    }));
    return payloads;
  };

  // we could be looking at multiple selected contracts with/without differing/the same per hour rates :o
  const applicableContractsArray = selectedApplicableContract
    ? [selectedApplicableContract]
    : selectedMultiApplicableContracts;
  // if any selected contract is per hour by lane OR contract
  const isPerHourRate = applicableContractsArray.some(
    (applicableContract) =>
      applicableContract.contract_lane?.rate_info?.rate_assessment_measurement ===
        // casting as the sdk is incorrect here
        (RATE_TYPE_PER_HOUR as RateAssessmentMeasurement) ||
      applicableContract.contract?.rate_type === RATE_TYPE_PER_HOUR
  );
  // extract a per hour rate only if all rates are the same. Otherwise we have to show a generic infoMessage
  const perHourRates = applicableContractsArray.map(
    (applicableContract) =>
      applicableContract?.charges?.find((charge) => charge.category === ChargeCategory.Lh && charge.unit_quantity === 0)
        ?.unit_amount
  );
  // discard non per hour rates and then check to make sure all per hour rates are the same
  const perHourRate = perHourRates.filter(Boolean).every((rate) => rate === perHourRates[0])
    ? perHourRates[0]
    : undefined;

  const perHourMessage = isPerHourRate
    ? perHourRate
      ? `Line Haul based on the Hourly Rate of ${formatCurrency(
          perHourRate,
          applicableContractsArray[0]?.contract?.rate_currency || ContractRateCurrencyEnum.Usd
        )} per hour.`
      : 'Line Haul based on the Hourly Rate if applicable.'
    : '';

  return {
    selectedApplicableContract,
    selectedMultiApplicableContracts,
    handleContractChange: useCallback(
      (value: (Contract | ApplicableContractWithCharges) | (Contract | ApplicableContractWithCharges)[]) =>
        Array.isArray(value)
          ? setSelectedMultiApplicableContracts(
              value.map((value) => (isApplicableContract(value) ? value : {contract: value}))
            )
          : handleContractChange(value),
      [handleContractChange]
    ),
    isLoading:
      isLoading ||
      carrierRelationshipsQueries.some((query) => query.isFetching) ||
      carrierCapacityAvailableBulkQueries.some((query) => query.isFetching),
    carrierCapacityIsAvailable: capacityAvailable,
    getContractValues,
    getMultiContractsValues,
    contractCarrierRelationship,
    chargeLineItems,
    hasSelectedContracts: !!selectedApplicableContract || !!selectedMultiApplicableContracts?.length,
    perHourMessage
  };
};

const assertAllEqual = (arr: (string | number | null | undefined)[], name: string) => {
  const isAllEqual = arr.every((val) => val === arr[0]);
  const values = JSON.stringify(arr);
  invariant(
    isAllEqual,
    `Expected all shipments to have the same '${name}', but received the following values for '${name}': ${values}`
  );
};
