import {useFormikContext} from 'formik';
import {OrderItem} from '@shipwell/corrogo-sdk';
import {ShippingDashboardShipmentLineItem} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useFlags} from 'launchdarkly-react-client-sdk';

interface EditPurchaseOrderInitial {
  items?: OrderItem[];
  line_items?: ShippingDashboardShipmentLineItem[];
}

interface UsePiecesChangeHook {
  handlePiecesChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleEmptyChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
}

const getFieldPath = (index: number, field: string, version: 'v2' | 'v3'): string => {
  if (version === 'v3') {
    return `items[${index}].shipping_requirements.${field}`;
  }
  return `line_items[${index}].${field === 'total_pieces' ? 'total_pieces' : 'total_packages'}`;
};

const autoPackagingQuantityCalculator = (newPieces: number, oldPieces: number, oldQuantity: number): number => {
  if (!oldPieces || !oldQuantity) return 0;
  return parseFloat(((newPieces * oldQuantity) / oldPieces).toFixed(2));
};

const usePiecesChange = (version: 'v2' | 'v3'): UsePiecesChangeHook => {
  const {values, setFieldValue} = useFormikContext<EditPurchaseOrderInitial>();

  const flags = useFlags();
  // Empty function to override the component's automatic onChange.
  // Otherwise, the onChange value interferes with the formula to maintain the proportion between total_pieces/total_packages and quantity.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleEmptyChange = () => {};

  const handlePiecesChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newPieces = parseFloat(e.target.value) || 0;
    const oldPiecesPath = getFieldPath(index, 'total_pieces', version);
    const quantityField = version === 'v3' ? 'quantity' : 'total_packages';
    const quantityPath = getFieldPath(index, quantityField, version);

    const oldPieces =
      version === 'v3'
        ? values?.items?.[index].shipping_requirements?.total_pieces
        : values.line_items?.[index].total_pieces;
    const oldQuantity =
      version === 'v3'
        ? Number(values.items?.[index].shipping_requirements?.quantity)
        : values.line_items?.[index].total_packages;

    setFieldValue(oldPiecesPath, newPieces);

    if (!oldPieces || !oldQuantity) return;

    const newQuantity = autoPackagingQuantityCalculator(newPieces, oldPieces, oldQuantity);

    if (flags?.piecePackageQuantityInteraction) {
      setFieldValue(quantityPath, newQuantity);
    }
  };

  return {handlePiecesChange, handleEmptyChange};
};

export default usePiecesChange;
