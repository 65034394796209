import moment from 'moment';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  validateIntegerValue,
  removeCommasAndDollarSign,
  validateDollarValue,
  validateNumber,
  reeferTypes
} from 'App/utils/globals';
import {makeValidationErrors} from 'App/utils/customData';
import {getCustomDataPath} from 'App/utils/customDataPath';
import isInternationalShipment from 'App/utils/isInternationalShipment';
import validateNMFCCode, {validateNMFCSubCode} from 'App/utils/validateNMFCCode';

/**
 * Validation for `Quick Quote` form
 * @param  {Object} values Field values from form
 * @return {Object}        Errors
 */
export const validation = (values, {customFields = [], stopCustomFields = []} = {}) => {
  const isFTL = (Array.isArray(values.mode) && values.mode.find((mode) => mode.id === 1)) || values.mode === '1';
  const hasLTL = Array.isArray(values.mode) && values.mode.find((mode) => mode.id === 2 || mode.id === 4);
  const hasDrayage = Array.isArray(values.mode) && values.mode.find((mode) => mode.id === 5 || mode.id === '5');
  const hasParcel = Array.isArray(values.mode) && values.mode.find((mode) => mode.id === 6 || mode.id === '6');
  const errors = {};

  if (!values.mode || !values.mode.length) {
    errors.mode = 'Select a shipment mode';
  }

  if (isFTL && values.manual_total_weight) {
    if (!values.total_weight_override?.value || values.total_weight_override?.value <= 0) {
      errors.total_shipment_weight = 'Enter total shipment weight.';
    }
  }
  errors.metadata = {};

  if (!values.preferred_currency) {
    errors.preferred_currency = 'Enter a currency of record';
  }

  //validate max buy if it exists
  if (values.metadata && values.metadata.max_buy_amount) {
    if (!validateDollarValue(values.metadata.max_buy_amount)) {
      errors.metadata.max_buy_amount = 'Enter a valid dollar value';
    }
  }
  //validate buy it now if it exists
  if (values.metadata && values.metadata.buy_it_now_amount) {
    if (!validateDollarValue(values.metadata.buy_it_now_amount)) {
      errors.metadata.buy_it_now_amount = 'Enter a valid dollar value';
    }
  }

  if (Object.keys(errors.metadata).length === 0) {
    delete errors.metadata;
  }

  if ((!values.equipment_type || !values.equipment_type.length) && !hasParcel) {
    errors.equipment_type = 'Select an equipment type';
  }
  if (
    values.equipment_type &&
    values.equipment_type.find((el) => reeferTypes.includes(el.id)) &&
    values.mode &&
    values.mode.find((el) => el.id === 1)
  ) {
    if (isNaN(values.temperature_lower_limit) || isNil(values.temperature_lower_limit)) {
      errors.temperature_lower_limit = 'Invalid temperature.';
    }
    if (isNaN(values.temperature_upper_limit) || isNil(values.temperature_upper_limit)) {
      errors.temperature_upper_limit = 'Invalid temperature.';
    }
    if (parseFloat(values.temperature_upper_limit) < parseFloat(values.temperature_lower_limit)) {
      errors.temperature_upper_limit = 'Upper temperature must be greater than lower temperature.';
    }
  }

  /** validate stops fields */
  if (values.stops) {
    const prevStopDates = [];
    const prevConfirmDates = [];

    values.stops.forEach((stop, index) => {
      const isFirstStop = index === 0;
      const error = {};

      /** address validation */
      if (stop.location && !stop.location.address) {
        error.location = Object.assign({}, error.location, {address: 'Enter a location'});
      } else if (stop.location.address && !Object.keys(stop.location.address).length) {
        error.location = Object.assign({}, error.location, {address: 'Enter a location'});
      } else if (stop.location.address && !stop.location.address.formatted_address) {
        error.location = Object.assign({}, error.location, {
          address: 'Enter a complete address with a street name/number, city, state, and postal code'
        });
      }

      /** location type validation */
      if (!hasDrayage && !hasParcel) {
        if (stop.location && (!stop.location.location_type || !stop.location.location_type.id)) {
          error.location = Object.assign({}, error.location, {location_type: 'Enter a location type'});
        }
      }

      /** planned_date validation */
      if (!hasDrayage) {
        if ((isFTL && !stop.planned_date) || (!isFTL && isFirstStop && !stop.planned_date)) {
          error.planned_date = 'Enter a date';
        } else if (!isFTL && isFirstStop && moment(stop.planned_date, 'MM/DD/YYYY').isBefore(moment(), 'day')) {
          error.planned_date = 'Date cannot be in the past';
        }

        if (stop.planned_date && moment(stop.planned_date).isValid()) {
          prevStopDates.push(moment(stop.planned_date));
        }

        if (index > 0) {
          const currentStopDate = moment(stop.planned_date);

          if (
            currentStopDate.isValid() &&
            prevStopDates.some((date) => moment(stop.planned_date).isBefore(date, 'day'))
          ) {
            error.planned_date = 'Date must be before previous stop dates';
          }
        }

        if (isFTL || hasLTL) {
          const startTime = moment(stop.planned_time_window_start, 'HH:mm');
          const stopTime = moment(stop.planned_time_window_end, 'HH:mm');
          //check if schedule selection is 'Open' before validating start and stop time
          const scheduleSelectOpen = stop['schedule-select'] === 2;
          if (startTime.isValid() && stopTime.isValid() && moment(stopTime).isBefore(startTime) && scheduleSelectOpen) {
            error.planned_time_window_end = 'Stop time must be after start time';
          }
        }

        /** Ensure completion date is after arrival date */
        if (moment(stop.confirmed_arrival_at).isValid() && moment(stop.confirmed_departure_at).isValid()) {
          if (moment(stop.confirmed_departure_at).isBefore(moment(stop.confirmed_arrival_at))) {
            error.confirmed_departure_at = 'Completed date must be after arrival date.';
          }
        }
        /** Ensure current completion date and arrival date are after previous dates */
        if (index > 0 && moment(stop.confirmed_arrival_at).isValid()) {
          if (prevConfirmDates.some((prevDate) => moment(stop.confirmed_arrival_at).isBefore(prevDate))) {
            error.confirmed_arrival_at = 'Date must be after previous dates.';
          }
        }
        if (index > 0 && moment(stop.confirmed_departure_at).isValid()) {
          if (prevConfirmDates.some((prevDate) => moment(stop.confirmed_departure_at).isBefore(prevDate))) {
            error.confirmed_departure_at = 'Date must be after previous dates.';
          }
        }

        if (moment(stop.confirmed_arrival_at).isValid()) {
          prevConfirmDates.push(moment(stop.confirmed_arrival_at));
        }
        if (moment(stop.confirmed_departure_at).isValid()) {
          prevConfirmDates.push(moment(stop.confirmed_departure_at));
        }
      }

      if (stopCustomFields.length > 0) {
        set(
          error,
          `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentStop)}`,
          makeValidationErrors(stop, stopCustomFields, CustomFieldEntityTypesEnum.ShipmentStop)
        );
      }

      if (Object.keys(error).length && !errors.stops) {
        errors.stops = [];
      }
      if (Object.keys(error).length) {
        errors.stops[index] = error;
      }
    });
  }

  /** line-item validation */
  if (values.line_items && values.line_items.length) {
    values.line_items.forEach((lineItem, index) => {
      const error = {};

      if (!lineItem.description) {
        error.description = 'Product Description required';
      }
      if (!lineItem.total_packages) {
        error.total_packages = 'Quantity required';
      }

      if (
        (!values.manual_total_weight && !lineItem.package_weight) ||
        (values.manual_total_weight && !isFTL && !lineItem.package_weight)
      ) {
        error.package_weight = 'Package Weight required';
      } else if (
        lineItem.package_weight &&
        (!parseFloat(removeCommasAndDollarSign(lineItem.package_weight)) || !/^[0-9,.]*$/.test(lineItem.package_weight))
      ) {
        error.package_weight = 'Enter a valid number';
      } else if (
        Array.isArray(values.mode) &&
        values.mode.length &&
        !hasParcel &&
        lineItem.package_weight &&
        lineItem.package_weight < 1
      ) {
        error.package_weight = `Package Weight cannot be lees than 1 ${lineItem.weight_unit}`;
      }

      if (lineItem.value_per_piece) {
        if (!validateDollarValue(lineItem.value_per_piece)) {
          error.value_per_piece = 'Enter a valid dollar value';
        }
      }

      if (lineItem.hazmat) {
        if (isNil(lineItem.hazmat_identification_number)) {
          error.hazmat_identification_number = 'Enter Hazmat Identification Number';
        } else if (lineItem.hazmat_identification_number?.length > 16) {
          error.hazmat_identification_number = 'Ensure this field has no more than 16 characters';
        }
        if (isNil(lineItem.hazmat_hazard_class)) {
          error.hazmat_hazard_class = 'Enter Hazmat Hazard Class';
        } else if (lineItem.hazmat_hazard_class?.length > 6) {
          error.hazmat_hazard_class = 'Ensure this field has no more than 6 characters';
        }
        if (isNil(lineItem.hazmat_packing_group)) {
          error.hazmat_packing_group = 'Select a Hazmat Packing Group';
        }
        if (isNil(lineItem.hazmat_proper_shipping_name)) {
          error.hazmat_proper_shipping_name = 'Enter Hazmat Proper Shipping Name';
        }
      }

      /** LTL line-item validation */
      if (hasLTL) {
        if (!lineItem.total_packages) {
          error.total_packages = 'Quantity required';
        } else if (!validateIntegerValue(lineItem.total_packages)) {
          error.total_packages = 'Enter a valid whole number';
        }

        if (!lineItem.package_type) {
          error.package_type = 'Select a package type';
        }

        if (!lineItem.freight_class) {
          error.freight_class = 'Freight Class required';
        }

        if (!lineItem.height) {
          error.height = 'Height required';
        } else if (!validateNumber(lineItem.height)) {
          error.height = 'Enter a valid whole number';
        }

        if (!lineItem.width) {
          error.width = 'Width required';
        } else if (!validateNumber(lineItem.width)) {
          error.width = 'Enter a valid whole number';
        }

        if (!lineItem.length) {
          error.length = 'Length required';
        } else if (!validateNumber(lineItem.length)) {
          error.length = 'Enter a valid whole number';
        }

        if (lineItem.total_pieces && !validateIntegerValue(lineItem.total_pieces)) {
          error.total_pieces = 'Enter a valid whole number';
        }

        if (lineItem.total_pieces && validateIntegerValue(lineItem.total_pieces) && !lineItem.piece_type) {
          error.piece_type = 'Select a type';
        }
      }

      /** Parcel line-item validation */
      if (hasParcel) {
        if (isInternationalShipment(values.stops)) {
          if (!lineItem.country_of_manufacture) {
            error.country_of_manufacture = 'Country of manufacture is required.';
          }
          if (!lineItem.total_pieces) {
            error.total_pieces = 'Total number of pieces is required.';
          } else if (lineItem.total_pieces && !validateIntegerValue(lineItem.total_pieces)) {
            error.total_pieces = 'Enter a valid whole number.';
          }
          if (!lineItem.value_per_piece) {
            error.value_per_piece = 'Value per piece is required.';
          } else if (!validateDollarValue(lineItem.value_per_piece)) {
            error.value_per_piece = 'Enter a valid dollar value.';
          }
        }

        if (!lineItem.total_packages) {
          error.total_packages = 'Quantity required';
        } else if (!validateIntegerValue(lineItem.total_packages)) {
          error.total_packages = 'Enter a valid whole number';
        }

        if (!lineItem.provider_specific_packaging) {
          error.provider_specific_packaging = 'Select a package type';
        }

        if (lineItem.provider_specific_packaging === 'YOUR_PACKAGING') {
          if (!lineItem.height) {
            error.height = 'Height required';
          } else if (!validateIntegerValue(lineItem.height)) {
            error.height = 'Enter a valid whole number';
          }

          if (!lineItem.width) {
            error.width = 'Width required';
          } else if (!validateIntegerValue(lineItem.width)) {
            error.width = 'Enter a valid whole number';
          }

          if (!lineItem.length) {
            error.length = 'Length required';
          } else if (!validateIntegerValue(lineItem.length)) {
            error.length = 'Enter a valid whole number';
          }
        }
      }

      if (lineItem.refrigeration_required && isNil(lineItem.refrigeration_min_temp)) {
        error.refrigeration_min_temp = 'Enter minimum temperature.';
      }

      if (lineItem.refrigeration_required && isNil(lineItem.refrigeration_max_temp)) {
        error.refrigeration_max_temp = 'Enter maximum temperature.';
      }
      if (parseFloat(lineItem.refrigeration_min_temp) > parseFloat(lineItem.refrigeration_max_temp)) {
        error.refrigeration_max_temp = 'Upper temperature must be greater than lower temperature.';
      }

      const customDataValidationErrors = makeValidationErrors(lineItem, customFields);
      if (!isEmpty(customDataValidationErrors)) {
        set(
          error,
          `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentLineItem)}`,
          customDataValidationErrors
        );
      }

      if (lineItem.nmfc_item_code) {
        try {
          lineItem.nmfc_item_code = validateNMFCCode(lineItem.nmfc_item_code);
        } catch (e) {
          error.nmfc_item_code = e.message;
        }
      }
      if (lineItem.nmfc_sub_code) {
        try {
          lineItem.nmfc_sub_code = validateNMFCSubCode(lineItem.nmfc_sub_code);
        } catch (e) {
          error.nmfc_sub_code = e.message;
        }
      }

      if (Object.keys(error).length && !errors.line_items) {
        errors.line_items = [];
      }
      if (Object.keys(error).length) {
        errors.line_items[index] = error;
      }
    });
  } else if (hasLTL) {
    errors.line_items = {
      _error: 'At least one line item is required'
    };
  }

  return errors;
};
