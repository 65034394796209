import {SearchField} from '@shipwell/shipwell-ui';
import {Form, Formik} from 'formik';
import {DebouncedAutoSubmitter} from './debouncedAutoSubmitter/debouncedAutoSubmitter';
import {SearchFormValues} from './searchFormValues';

export interface SearchFormProps {
  onSearch: (values: SearchFormValues) => void;
}

export function SearchForm(props: SearchFormProps) {
  return (
    <Formik
      initialValues={{
        term: ''
      }}
      onSubmit={props.onSearch}
    >
      {(formProps) => (
        <Form>
          <SearchField
            label="Search for Tags"
            name="search for tags"
            value={formProps.values.term}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              formProps.setFieldValue('term', evt.target.value);
            }}
          />
          <DebouncedAutoSubmitter />
        </Form>
      )}
    </Formik>
  );
}
