import {IconButtonWithTooltip, Rule} from '@shipwell/shipwell-ui';
import {CustomField, ShipmentChargeLineItem} from '@shipwell/backend-core-singlerequestparam-sdk';
import classNames from 'classnames';
import {InfoTooltip} from './components';
import {CompanyName, FinancialsTableHeader, TextCell} from './layouts';

const tooltipContent =
  "These are your customer's financials for this shipment. You can view these to aid in resolving disputes, but you won't be able to change them. Rows are highlighted when they differ between parties.";

export const NewCustomerTableHeader = ({
  onCopyClick: onCopyClick,
  isEditable,
  showCustomerColumn,
  customerName,
  vendorName,
  theirCustomerCharges,
  customFields
}: {
  onCopyClick: () => void;
  isEditable?: boolean;
  isEditing?: boolean;
  showCustomerColumn?: boolean;
  customerName?: string;
  vendorName?: string;
  theirCustomerCharges: ShipmentChargeLineItem[];
  customFields: CustomField[];
}) => {
  const showCopybutton = showCustomerColumn && isEditable && theirCustomerCharges?.length > 0;

  const visibleCustomFields = customFields?.filter((customField) => customField.ui_enabled);
  const columnNumber = 2;

  return (
    <>
      <FinancialsTableHeader columnCount={visibleCustomFields.length + columnNumber} isNewFinancialsTable>
        <div className="flex items-center gap-2 overflow-hidden text-ellipsis text-[10px]">CHARGE ITEM</div>
        <div className="flex items-center gap-2 overflow-hidden text-ellipsis text-[10px]">
          <TextCell>DESCRIPTION</TextCell>
        </div>

        {visibleCustomFields.map((customField) => (
          <div key={customField.id} className="flex items-center gap-2 overflow-hidden text-ellipsis text-[10px]">
            <TextCell key={customField.id}>{customField.label.toUpperCase()}</TextCell>
          </div>
        ))}
        <div className="flex items-center gap-2 overflow-hidden text-ellipsis text-[10px]">
          <TextCell>RATE</TextCell>
        </div>
        <div className="flex items-center gap-2 overflow-hidden text-ellipsis text-[10px]">
          <TextCell>QTY</TextCell>
        </div>

        {showCustomerColumn ? (
          <div className="flex items-center justify-end gap-2 text-[10px]">
            <InfoTooltip aria-label={tooltipContent}>{tooltipContent}</InfoTooltip>
            <CompanyName>{customerName}</CompanyName>
          </div>
        ) : (
          <div />
        )}

        <div
          className={classNames('flex items-center', {
            'justify-between': showCopybutton,
            'justify-end': !showCopybutton
          })}
        >
          {showCopybutton ? (
            <div className="px-4">
              <IconButtonWithTooltip
                color="warning"
                iconName="ArrowForward"
                onClick={onCopyClick}
                aria-label="Copy financials from my customer"
              />
            </div>
          ) : null}

          <CompanyName>{vendorName}</CompanyName>
        </div>
      </FinancialsTableHeader>
      <Rule />
    </>
  );
};
