import {Modal, DisplayValue} from '@shipwell/shipwell-ui';
import {LoadBoardShipment} from '@shipwell/backend-core-sdk';
import round from 'lodash/round';
import PlaceBidForm from './PlaceBidForm';
import {formatCurrency} from 'App/utils/internationalConstants';

const PlaceBidModal = ({
  loadboardShipment,
  handleClose,
  showPlaceBidModal,
  handlePlaceBidSuccess,
  setError
}: {
  loadboardShipment: LoadBoardShipment;
  handleClose: () => void;
  showPlaceBidModal: boolean;
  handlePlaceBidSuccess: (spotNegotiationId: string) => Promise<void>;
  setError: (title: string, message: string | JSX.Element) => void;
}) => {
  const {
    customer,
    target_rate_amount,
    target_rate_amount_currency,
    buy_it_now_amount,
    buy_it_now_amount_currency,
    stops,
    total_miles
  } = loadboardShipment;
  const {city: originCity, state_province: originState} = stops?.[0]?.location.address || {};
  const {city: destinationCity, state_province: destinationState} = stops?.at(-1)?.location.address || {};
  const formattedOrigin = originCity && originState ? `${originCity}, ${originState}` : '--';
  const formattedDestination = destinationCity && destinationState ? `${destinationCity}, ${destinationState}` : '--';

  return (
    <Modal
      show={showPlaceBidModal}
      title="Place a Bid"
      footerComponent={null}
      onClose={handleClose}
      bodyClassName="overflow-visible"
    >
      <div className="mb-24 grid grid-cols-2 gap-x-4">
        <div className="rounded bg-sw-background p-4">
          <div className="grid h-full grid-cols-3 gap-x-4">
            <div className="col-span-2 flex flex-col justify-between">
              <DisplayValue label="Customer">{customer?.name || '--'}</DisplayValue>
              <DisplayValue label="Lane">{`${formattedOrigin} > ${formattedDestination}`}</DisplayValue>
              <DisplayValue label="Total Distance">{total_miles ? round(total_miles) : '--'} mi</DisplayValue>
            </div>
            <div className="flex flex-col">
              {target_rate_amount && target_rate_amount > 0 ? (
                <DisplayValue label="Target Rate">
                  <span className="whitespace-nowrap">
                    {formatCurrency(target_rate_amount, target_rate_amount_currency)}
                  </span>
                </DisplayValue>
              ) : null}
              <DisplayValue label="Book Now">
                {buy_it_now_amount && buy_it_now_amount > 0 ? (
                  <span className="whitespace-nowrap">
                    {formatCurrency(buy_it_now_amount, buy_it_now_amount_currency)}
                  </span>
                ) : (
                  '--'
                )}
              </DisplayValue>
            </div>
          </div>
        </div>
        <PlaceBidForm
          loadboardShipment={loadboardShipment}
          handleClose={handleClose}
          handlePlaceBidSuccess={handlePlaceBidSuccess}
          setError={setError}
        />
      </div>
    </Modal>
  );
};

export default PlaceBidModal;
