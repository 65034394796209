import ReactTable from 'react-table-6';
import momentTZ from 'moment-timezone';
import pluralize from 'pluralize';
import {DeprecatedButton, Tooltip} from '@shipwell/shipwell-ui';
import isNil from 'lodash/isNil';
import {formatCurrencyValue} from 'App/utils/globals';

const ParcelRateList = (props) => {
  const {serviceLevels, packageTypes, packageType, numberOfPackages, handleSelectQuote, quotes} = props;
  const sortedQuotesList = quotes.sort((a, b) => {
    if (a.is_failure && !b.is_failure) {
      return 1;
    }

    if (!a.is_failure && b.is_failure) {
      return -1;
    }

    if (a.is_failure && b.is_failure) {
      return 0;
    }

    return a.total - b.total;
  });
  const providerCode = props.providerCode.toLowerCase();
  const columns = [
    {
      Header: 'Service',
      id: 'service',
      minWidth: 160,
      accessor: (d) => d[`${providerCode}_direct_quote`],
      Cell: ({value}) => {
        let service = value?.service_code && serviceLevels[value.service_code];
        if (service?.length > 26) {
          service = service.slice(0, 23) + '...';
          return (
            <Tooltip portal tooltipContent={serviceLevels[value.service_code]}>
              <span>{service}</span>
            </Tooltip>
          );
        }
        return service || '--';
      }
    },
    {
      Header: 'Package',
      id: 'package',
      minWidth: 160,
      Cell: () => {
        const pkgNumberString = numberOfPackages > 1 ? ` (${numberOfPackages})` : '';
        const packageTypeString = packageTypes?.[packageType]
          ? packageTypes[packageType]
          : packageType === 'MIXED_TYPES'
          ? 'Mixed Packages'
          : 'Custom';

        // if the type is too long to fit in the column
        // show a truncated string and a tooltip with the full length package type
        if (packageTypeString.length > 23) {
          return (
            <Tooltip tooltipContent={packageTypeString}>
              {`${packageTypeString.slice(0, 20)}...${pkgNumberString}`}
            </Tooltip>
          );
        }
        return `${packageTypeString}${pkgNumberString}`;
      }
    },
    {
      Header: 'Transit',
      id: 'transit_days',
      accessor: 'transit_days',
      minWidth: 80,
      Cell: ({value}) => (!isNil(value) ? pluralize('Day', value, true) : '--')
    },
    {
      Header: 'Delivery Date',
      id: 'delivery_date',
      accessor: 'delivery_date',
      minWidth: 140,
      Cell: ({value}) => (momentTZ(value).isValid() ? momentTZ.tz(value, 'YYYY-MM-DD').format('ddd, MMMM D') : '--')
    },
    {
      Header: 'Rate',
      id: 'rate',
      minWidth: 80,
      Cell: ({original}) => {
        if (original.is_failure) {
          return (
            <Tooltip tooltipContent={original.error_message || 'Failed to Quote'} portal>
              <span className="text-danger">
                <i className="icon icon-Delayed text-danger pad-right" /> Failed to Quote
              </span>
            </Tooltip>
          );
        }
        return <b>{`$${formatCurrencyValue(original.total)}`}</b>;
      }
    },
    {
      Header: 'Action',
      id: 'select',
      width: 120,
      Cell: ({original}) => {
        return (
          !original.is_failure && (
            <DeprecatedButton onClick={() => handleSelectQuote(original)}>Select</DeprecatedButton>
          )
        );
      }
    }
  ];

  return (
    <ReactTable
      data={sortedQuotesList}
      showPagination={false}
      className="-highlight -striped"
      minRows={0}
      columns={columns}
    />
  );
};

export default ParcelRateList;
