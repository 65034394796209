import {useState} from 'react';
import {Title} from '@shipwell/shipwell-ui';
import {ExternalDocument} from '@shipwell/corrogo-sdk';
import type {User} from '@shipwell/backend-core-sdk';
import get from 'lodash/get';
import without from 'lodash/without';
import pluralize from 'pluralize';
import DocumentItem from './DocumentItem';
import UploadDocuments from './UploadDocuments';
import {UploadDocumentVariablesType} from 'App/api/corrogo/utils/mergeDataFunctions';

type PropTypes = {
  isLoading: boolean;
  documents: ExternalDocument[];
  users: {[key: string]: User};
  viewDocumentId: string;
  onDocumentClick: (documentId: string) => void;
  onDocumentUpload: ({file, description, type}: UploadDocumentVariablesType) => void;
};

const DocumentList = ({
  isLoading,
  documents,
  users,
  viewDocumentId,
  onDocumentClick,
  onDocumentUpload
}: PropTypes): JSX.Element => {
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

  const onDocumentSelect = (isSelected: boolean, documentId: string) => {
    if (isSelected) {
      setSelectedDocuments([...selectedDocuments, documentId]);
    } else {
      setSelectedDocuments(without(selectedDocuments, documentId));
    }
  };

  if (!isLoading && !documents.length) {
    return (
      <div className="flex flex-col items-center justify-center">
        <Title variant="emptyStateHeader" className="mt-20">
          No Documents
        </Title>
        <UploadDocuments hideIcon label="Upload Document" onDocumentUpload={onDocumentUpload} />
      </div>
    );
  }
  return (
    <>
      {documents.map((document) => (
        <DocumentItem
          key={document.id}
          document={document}
          user={get(users, [document.created_by_user_id || ''])}
          isSelected={selectedDocuments.includes(document.id)}
          previewing={document.id === viewDocumentId}
          onDocumentClick={onDocumentClick}
          onDocumentSelect={onDocumentSelect}
        />
      ))}
      {selectedDocuments.length > 0 ? (
        <div className="mt-16 font-bold">
          {selectedDocuments.length} {pluralize('Document', selectedDocuments.length)} Selected
        </div>
      ) : null}
    </>
  );
};

export default DocumentList;
