/* global Stripe */
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {
  Row,
  Col,
  Nav,
  NavItem,
  Button,
  Form,
  ControlLabel,
  FormGroup,
  FormControl,
  InputGroup,
  Table,
  Modal
} from 'react-bootstrap';
import _ from 'lodash';
import {Field, reduxForm, SubmissionError} from 'redux-form';
// App files
import * as shipmentActions from 'App/actions/shipments';
import * as formActions from 'App/actions/forms';
import * as billingActions from 'App/actions/billing';
import * as addressBookActions from 'App/actions/addressBook';
import * as policyActions from 'App/actions/policy';
import * as userActions from 'App/actions/users';
import * as brokerActions from 'App/actions/brokers';
import * as marketplaceActions from 'App/actions/marketplace';
import CreateManualRate from 'App/components/CreateManualRate/CreateManualRate';
import MessagePreview from 'App/components/MessagePreview/MessagePreview';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import {formatCurrencyValue, transformShipmentToForm, unpackErrors, removeCommasAndDollarSign} from 'App/utils/globals';
import ShipmentQuotingHeader from 'App/components/ShipmentQuotingHeader/ShipmentQuotingHeader';

@connect(
  (state) => ({
    selectedShipment: state.shipments.selectedShipment,
    selectedQuote: state.shipments.selectedQuote,
    selectedRFQ: state.shipments.selectedRFQ,
    shipperCredit: state.shipments.shipperCredit,
    user: state.auth.user,
    company: state.auth.company,
    createManualRate: state.form.createManualRate,
    actingAsCompany: state.brokers.actingAsCompany,
    customerNegotiation: state.marketplace.customerNegotiation,
    hazmatCodes: state.shipments.hazmatCodes
  }),
  {
    ...shipmentActions,
    ...formActions,
    ...policyActions,
    ...billingActions,
    ...userActions,
    ...addressBookActions,
    ...brokerActions,
    ...marketplaceActions
  }
)
export default class ManualRate extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context, ...args) {
    super(props, context, ...args);
    this.fetchShipmentDetails = this.fetchShipmentDetails.bind(this);
    this.handleSetTotal = this.handleSetTotal.bind(this);
    this.getSpotNegotiationDetails = this.getSpotNegotiationDetails.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.getRFQDetails = this.getRFQDetails.bind(this);
    this.state = {total: 0, showPreview: false};
  }

  componentDidMount() {
    if (this.props.company && this.props.company.id) {
      this.fetchShipmentDetails();
      this.props.fetchChargeCategories();
      //get the preview for the email
      this.props.getSpotNegotiationEmailPreview(this.props.params.shipment_id, {isCustomerFacing: true});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.company && this.props.company !== nextProps.company) {
      this.fetchShipmentDetails();
      this.props.fetchChargeCategories();
      this.props.getSpotNegotiationEmailPreview(this.props.params.shipment_id, {isCustomerFacing: true});
    }
  }

  fetchShipmentDetails() {
    this.props.getShipmentDetails(this.props.params.shipment_id).then((response) => {
      this.getSpotNegotiationDetails(response.details);
      this.getRFQDetails(response.details);
    });
  }

  getSpotNegotiationDetails(shipment) {
    //use the shipmentId to get all the spot negotiations associated with this user for this shipment
    this.props.getAllSpotNegotiationsByShipment(shipment.id).then((response) => {
      if (response.status === 200) {
        this.setState({loading: false});
      }
    });
  }

  getRFQDetails(shipment) {
    //get the most recent RFQ for this shipment (will be used if new quote is created immediately on this page)
    let rfqs = JSON.parse(JSON.stringify(shipment.rfqs));
    if (rfqs.length > 0) {
      //for LTL shipments, the parent rfq should be null on the parent rfq obj
      //filter down to the parent RFQ on this page
      rfqs = rfqs.filter((e) => e.has_parent_rfq === false);
      rfqs = rfqs.sort(function (a, b) {
        return a.updated_at > b.updated_at ? -1 : 1;
      });
      const lastRFQ = rfqs[0];
      const opts = {};
      if (lastRFQ.company_owner_id !== this.props.company.id) {
        opts['xCompanyId'] = lastRFQ.company_owner_id;
      }
      this.props.getRFQDetails(lastRFQ.id, opts);
    }
  }

  handleSetTotal(total) {
    this.setState({total: total});
  }

  onFormSubmit(attrs) {
    //add the email to the recipients list of the customer negotiation and create a new quote on it
    if (attrs.option === 'send-quote') {
      const body = {};
      body.involved_customer_users = attrs.customers?.map((e) => {
        return {
          id: e.value
        };
      });
      body.involved_vendor_users = [{id: this.props.user.id}];
      return this.props.getCustomerNegotiation(this.props.selectedShipment.id, body).then((response) => {
        if (response.status === 200) {
          //we've successfully GOT or CREATED a customer negotiation
          const customerNegotiation = response.details;
          const quoteObj = {};
          //the quote is constructed, now post it to the customer negotiation
          quoteObj.charge_line_items = attrs.financials || [];
          if (attrs.markup) {
            quoteObj.charge_line_items.push({
              unit_amount: parseFloat(removeCommasAndDollarSign(attrs.markup)) || 0,
              unit_quantity: 1,
              unit_name: 'Item Charge',
              is_provider_markup: true,
              unit_amount_currency: this.props.selectedShipment.preferred_currency,
              category: 'LH'
            });
          }

          quoteObj.charge_line_items.forEach((charge) => {
            charge.unit_amount_currency = this.props.selectedShipment.preferred_currency;
          });
          //defaults to FTL and standard service
          quoteObj.mode = '2';
          quoteObj.service_level = '19';
          quoteObj.message = attrs.message;
          return this.props.createSpotNegotiationQuote(customerNegotiation.id, quoteObj).then((spotResponse) => {
            if (spotResponse.status === 200) {
              //go to the bids page for this shipment
              this.context.router.push('/bids/' + customerNegotiation.id);
            } else {
              const errors = spotResponse.details.field_errors || [];
              let submissionError = {};
              submissionError = unpackErrors(errors, submissionError);
              submissionError._error = spotResponse.error_description;
              //handle edge cases for errors here
              if (submissionError.charge_line_items) {
                //put that on the total field
                if (submissionError.charge_line_items.unit_amount) {
                  submissionError.total = submissionError.charge_line_items.unit_amount[0];
                }
              }
              throw new SubmissionError(submissionError);
            }
          });
        }
        const errors = response.details.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = response.error_description;
        //handle edge cases for errors here
        throw new SubmissionError(submissionError);
      });
    }
    //we are booking immediately
    const quoteObj = {};
    quoteObj.charge_line_items = JSON.parse(JSON.stringify(attrs.financials)) || [];
    if (attrs.markup) {
      quoteObj.charge_line_items.push({
        unit_amount: parseFloat(removeCommasAndDollarSign(attrs.markup)) || 0,
        unit_quantity: 1,
        unit_name: 'Item Charge',
        unit_amount_currency: this.props.selectedShipment.preferred_currency,
        is_provider_markup: true,
        category: 'LH'
      });
    }
    quoteObj.charge_line_items.forEach((charge) => {
      charge.unit_amount_currency = this.props.selectedShipment.preferred_currency;
    });
    quoteObj.mode = this.props.selectedRFQ.autoquote === true ? '2' : '1';
    //putting this on the default RFQ, not doing spot negotiation stuff here
    quoteObj.rfq = this.props.selectedRFQ.id;
    quoteObj.service_level = '19';
    if (
      this.props.selectedRFQ &&
      this.props.selectedRFQ.equipment_types &&
      this.props.selectedRFQ.equipment_types.length > 0
    ) {
      //default to the first one until we build some UI around picking the equipment type if multiple
      quoteObj.equipment_type = this.props.selectedRFQ.equipment_types[0].id;
    }
    const opts = {};
    /*if (this.props.selectedRFQ.company_owner_id !== this.props.company.id) {
        opts['xCompanyId'] = this.props.selectedRFQ.company_owner_id;
      }*/
    return this.props.createQuote(this.props.selectedRFQ.id, quoteObj, opts).then((response) => {
      if (response.status === 200) {
        //now award the quote to the shipment
        return this.props
          .awardQuote(this.props.selectedShipment.id, {
            quote: response.details.id
          })
          .then((awardResponse) => {
            if (awardResponse.status === 200) {
              let shipment = JSON.parse(JSON.stringify(this.props.selectedShipment));
              const mode = response.details.mode;
              shipment = transformShipmentToForm(shipment, this.props.hazmatCodes, [mode], [], true);
              this.props.selectFormShipment(shipment);
              this.context.router.push('/shipments/' + this.props.selectedShipment.id + '/confirm');
            } else {
              //show errors
              const errors = awardResponse.field_errors || [];
              let submissionError = {};
              submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
              submissionError._error = awardResponse.error_description;
              //handle edge cases for errors here
              throw new SubmissionError(submissionError);
            }
          });
      }
      const errors = response.field_errors || [];
      let submissionError = {};
      submissionError = unpackErrors(errors, submissionError);
      submissionError._error = response.error_description;
      //handle edge cases for errors here
      throw new SubmissionError(submissionError);
    });
  }
  showPreview() {
    this.setState({showPreview: true});
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="newshipment">
            <Row>
              <Col xs={12}>
                <ShipmentQuotingHeader showTabs={false} shipment={this.props.selectedShipment} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <CreateManualRate
                  setTotal={this.handleSetTotal}
                  onSubmit={this.onFormSubmit}
                  bookIt={this.bookIt}
                  showPreview={this.showPreview}
                />
              </Col>

              <InfoModalWrapper
                show={this.state.showPreview}
                bsSize="large"
                onHide={() => {
                  this.setState({
                    showPreview: false
                  });
                }}
                title="Preview Email"
                children={
                  <MessagePreview
                    total={this.state.total}
                    message={
                      this.props.createManualRate &&
                      this.props.createManualRate.values &&
                      this.props.createManualRate.values.message
                    }
                  />
                }
              />
            </Row>
          </div>
        </section>
      </div>
    );
  }
}
