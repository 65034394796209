import {
  ContractRateTypeEnum,
  FuelSurchargeTableRateTypeEnum,
  FuelSurchargeTableAppliesToEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';

// Default title for accessorial table
export const ACCESSORIAL_TABLE = 'Accessorial Rates';
export const FUEL_SURCHARGE = 'Fuel Surcharge Rates';

// TODO: Add Fuel Surcharge option (WFA-470)
export const RATE_TABLE_TYPE_OPTIONS = [
  {label: 'Accessorial', value: 'accessorial'},
  {label: 'Fuel Surcharge', value: 'fuelsurcharge'}
];

// Select financial side to apply rate table
export const FINANCIAL_SIDE_OPTIONS = [
  {
    label: 'Carrier',
    value: FuelSurchargeTableAppliesToEnum.CarrierSide
  },
  {
    label: 'Customer',
    value: FuelSurchargeTableAppliesToEnum.CustomerSide
  }
];

export const RATE_TYPE_FLAT_RATE = ContractRateTypeEnum.FlatRate;
export const RATE_TYPE_PER_MILE = ContractRateTypeEnum.PerMile;
export const RATE_TYPE_RATE_TABLE = ContractRateTypeEnum.RateTable;
export const RATE_TYPE_PER_HOUR = ContractRateTypeEnum.PerHour;
export const RATE_TYPE_PERCENT_OF_LINE_HAUL = FuelSurchargeTableRateTypeEnum.PercentOfLineHaul;
export const RATE_TABLE_TITLE = 'Rate Tables';
export const RATE_TABLE_TOOLTIP = 'Rate table will be automatically added to the carrier or customer side financials.';
export const BEM = (name?: string) =>
  !name ? `rateTable__financial__container` : `rateTable__financial__container-${name}`;
