import {ChargeCategory, NetsuiteConfigCategoryMappings} from '@shipwell/backend-core-sdk';
import {CategoryMappingsType} from 'App/containers/integrations/details/components/netSuiteDetails/utils/parseCategoryMappingResponseBody';

const packageCategoryMappingRequest = (values: CategoryMappingsType): NetsuiteConfigCategoryMappings => {
  const categoryMappingPayload: NetsuiteConfigCategoryMappings = {
    invoicing: [],
    billing: []
  };

  (Object.keys(categoryMappingPayload) as Array<keyof CategoryMappingsType>).forEach((service) => {
    if (values[service]) {
      Object.keys(values[service]).forEach((mode) => {
        (Object.keys(values[service][mode]) as Array<ChargeCategory>).forEach((category) => {
          values[service][mode][category] &&
            categoryMappingPayload[service]?.push({
              shipment_mode: mode === 'DEFAULT' ? null : mode,
              netsuite_id: values[service][mode][category],
              charge_category: category
            });
        });
      });
    }
  });

  return categoryMappingPayload;
};

export default packageCategoryMappingRequest;
