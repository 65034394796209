import {FC} from 'react';
import {Formik} from 'formik';
import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {WithRouterProps} from 'react-router';
import {NetsuiteCategoryOptions, NetsuiteCompanyOptions, NetsuiteCustomField} from '@shipwell/backend-core-sdk';
import {
  billingAndInvoicingModes,
  defaultValues,
  validationSchema,
  formatDataMappingInitialValues
} from 'App/formComponents/forms/netSuiteDataMapping/formHelpers';
import ModeMappingFormFields from 'App/formComponents/forms/netSuiteDataMapping/components/modeMappingFormFields';
import DefaultMappingFormFields from 'App/formComponents/forms/netSuiteDataMapping/components/defaultMappingFormFields';
import VendorCarrierMappingFormFields from 'App/formComponents/forms/netSuiteDataMapping/components/vendorCarrierMappingFormFields';
import ShipmentDetailsMappingFormFields from 'App/formComponents/forms/netSuiteDataMapping/components/shipmentDetailsMappingFormFields';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {DataMappingFormValuesType} from 'App/containers/integrations/details/components/netSuiteDetails';

export type NetSuiteDataMappingFormPropsType = {
  values: DataMappingFormValuesType;
  onSubmit: (values: DataMappingFormValuesType) => Promise<void>;
  categoryOptions: NetsuiteCategoryOptions;
  customOptions: NetsuiteCustomField[];
  companyOptions: NetsuiteCompanyOptions;
} & Pick<WithRouterProps, 'router'>;

/**
 * NetSuite Configuration Form
 * @param {*} props
 */

const NetSuiteDataMappingForm: FC<NetSuiteDataMappingFormPropsType> = ({
  values,
  onSubmit,
  categoryOptions,
  customOptions,
  companyOptions,
  router,
  ...formProps
}) => {
  const handleSubmit = (values: DataMappingFormValuesType) => {
    if (onSubmit) {
      void onSubmit(values);
    }
  };

  return (
    <Formik
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formProps}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={formatDataMappingInitialValues(defaultValues, values)}
    >
      {({handleSubmit, isSubmitting, dirty}) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Card
              title={
                <div className="flex w-full items-center justify-between">
                  <img alt="integration-logo" className="mr-2 max-h-6" src="/images/integration-netsuite.png" />
                  <span>NetSuite Company Information</span>
                </div>
              }
              tooltip="These fields require a custom field to be added to Netsuite."
              draggableProvided={false}
            >
              <div className="grid grid-cols-2 gap-x-6">
                <Card title="Vendor/Carrier Mapping" draggableProvided={false}>
                  <VendorCarrierMappingFormFields options={customOptions} />
                </Card>
                <Card title="Shipment Details Mapping" draggableProvided={false}>
                  <ShipmentDetailsMappingFormFields options={customOptions} />
                </Card>
              </div>
              <Card className="mt-4" title="Default Mapping" draggableProvided={false}>
                <DefaultMappingFormFields options={companyOptions} />
              </Card>
            </Card>
            {billingAndInvoicingModes.map((mode) => (
              <Card
                className="mt-4"
                title={`${mode.label} Billing and Invoicing${mode.required ? '*' : ''}`}
                key={mode.id}
                isCollapsible
                isCollapsed={!mode.required}
                draggableProvided={false}
              >
                <CollapsibleCardContent>
                  <div className="grid grid-cols-[1fr_1fr] gap-x-6">
                    <Card title="Billing" draggableProvided={false}>
                      <ModeMappingFormFields mode={mode.id} type="billing" categoryOptions={categoryOptions} />
                    </Card>
                    <Card title="Invoicing" draggableProvided={false}>
                      <ModeMappingFormFields mode={mode.id} type="invoicing" categoryOptions={categoryOptions} />
                    </Card>
                  </div>
                </CollapsibleCardContent>
              </Card>
            ))}
            {(isSubmitting || dirty) && (
              <FormFooter onCancel={() => router.push('/company/integrations')} isSubmitting={isSubmitting} />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default NetSuiteDataMappingForm;
