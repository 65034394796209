import get from 'lodash/get';
import {Field, FormSection} from 'redux-form';
import {ProviderCode} from '@shipwell/genesis-sdk';
import SelectField from 'App/formComponents/fields/select';
import InputField from 'App/formComponents/fields/input';
import {truncateNumber} from 'App/utils/globals';
import {purolatorServiceCodes} from 'App/utils/parcelConstantsTyped';
import {countries} from 'App/utils/countries';
import {useCapacityProviderAccounts} from 'App/data-hooks/integrations/useCapacityProviderAccounts';
import {NewQuoteServiceOptions} from 'App/api/quoting/hooks/parcel/useCreateParcelRateRequest';
import {PartialNewQuoteFormValues} from 'App/formComponents/formSections/shipmentServices';
import './styles.scss';

type GenesisServiceOptionsProps = {
  values?: PartialNewQuoteFormValues;
  triggerFieldTouch: (field: string) => void;
  setFieldValue: (field: string, value: unknown) => void;
  parcelLabel: string;
};

export const GenesisServiceOptions = ({
  values = {},
  triggerFieldTouch,
  setFieldValue,
  parcelLabel
}: GenesisServiceOptionsProps) => {
  // Genesis accounts (Purolator specific for now)
  const {connections, isFetchingProviderAccounts} = useCapacityProviderAccounts({providerCode: ProviderCode.Purolator});
  const purolatorAccounts = connections?.map(({id, account, name}) => {
    const accountNumber = [name, ...(account && 'account_number' in account ? [account?.account_number] : [])]
      .filter(Boolean)
      .join(' - ');

    return {
      id,
      account_number: accountNumber
    };
  });

  const providerOptionsField = 'service_options';
  const serviceOptions = get(values, providerOptionsField) as undefined | NewQuoteServiceOptions;

  const has3rdPartyBilling = (billingType?: string) => {
    return !!billingType && ['COLLECT', 'RECEIVER', 'THIRD_PARTY'].includes(billingType);
  };

  const onBillingTypeChange = (value: string) => {
    if (value === 'PREPAID') {
      setFieldValue(`${providerOptionsField}.bill_to.account_number`, '');
      setFieldValue(`${providerOptionsField}.bill_to.location.country`, '');
      setFieldValue(`${providerOptionsField}.bill_to.location.postal_code`, '');
    } else if (has3rdPartyBilling(value) && !serviceOptions?.bill_to?.location?.country) {
      setFieldValue(`${providerOptionsField}.bill_to.location.country`, 'US');
    }
  };

  return (
    <FormSection name={providerOptionsField}>
      <div className="field-grid">
        <div className="grid-item-2">
          <Field
            req
            simpleValue
            label={`${parcelLabel} Account`}
            name="account"
            placeholder={`${parcelLabel} Account`}
            options={purolatorAccounts?.map((account) => ({
              id: account.id,
              name: account.account_number
            }))}
            component={SelectField}
            isLoading={isFetchingProviderAccounts}
          />
        </div>
        <div className="grid-item-2">
          <Field
            simpleValue
            label="Standard Service"
            name="service_code"
            placeholder="Standard Service"
            options={purolatorServiceCodes}
            component={SelectField}
          />
        </div>
        <div className="grid-item-2">
          <Field
            simpleValue
            clearable
            label="Signature Type"
            name="delivery_confirmation"
            placeholder="Signature Type"
            options={[
              {id: 'SERVICE_DEFAULT', name: 'Service Default'},
              {id: 'NO_SIGNATURE', name: 'No Signature Required'},
              {id: 'INDIRECT_SIGNATURE', name: 'Indirect Signature Required'},
              {id: 'DIRECT_SIGNATURE', name: 'Direct Signature Required'},
              {id: 'ADULT_SIGNATURE', name: 'Adult Signature Required'}
            ]}
            component={SelectField}
          />
        </div>
        <div className="grid-item-2 card-subheader">
          <strong>Billing Options</strong>
        </div>
        <div className="grid-item-1">
          <Field
            simpleValue
            label="Bill To Payment Options"
            name="payment_options"
            placeholder="Bill To Payment Options"
            options={[
              {id: 'COLLECT', name: 'Collect'},
              {id: 'RECEIVER', name: 'Receiver'},
              {id: 'PREPAID', name: 'Sender'},
              {id: 'THIRD_PARTY', name: 'Third Party'}
            ]}
            // e: unknown is the only way to not cause a TS error here. anyway we dont' need it
            onChange={(e: unknown, value: string) => {
              onBillingTypeChange(value);
            }}
            // type cast to 'select' was required because we pass in an onChange function with a parameter signature
            component={SelectField as unknown as 'select'}
          />
        </div>
        {has3rdPartyBilling(serviceOptions?.payment_options) ? (
          <>
            <div className="grid-item-1">
              <Field
                req
                label="Bill To Account ID"
                name="bill_to.account_number"
                placeholder="Account ID"
                component={InputField}
              />
            </div>
            <div className="grid-item-1">
              <Field
                req
                label="Country"
                name="bill_to.location.country"
                placeholder="Country"
                labelKey="label"
                valueKey="value"
                simpleValue
                options={countries}
                component={SelectField}
              />
            </div>
            <div className="grid-item-1">
              <Field
                req
                label="Postal Code"
                name="bill_to.location.postal_code"
                placeholder="Postal Code"
                component={InputField}
              />
            </div>
          </>
        ) : null}
        <div className="grid-item-2 card-subheader">
          <strong>Additional Services</strong>
        </div>
        <div className="field-grid grid-item-2">
          <div className="grid-item-1">
            <Field
              simpleValue
              clearable
              label="C.O.D Options"
              name="cod_options.cod_type"
              placeholder="C.O.D Options"
              options={[{id: 'CHECK', name: 'ExpressCheque'}]}
              onChange={() => {
                // set related field touched so that validation error displays before submit
                triggerFieldTouch(`[${providerOptionsField}].cod_collection_amount`);
              }}
              component={SelectField}
            />
          </div>
          <div className="grid-item-1">
            <Field
              req={serviceOptions?.cod_options?.cod_type}
              label={`Amount to Collect on Delivery (${values.preferred_currency || 'USD'})`}
              name="cod_options.price.amount"
              placeholder="Amount"
              component={InputField}
              normalize={(value: string) => (value === '' ? null : truncateNumber(value))}
            />
          </div>
        </div>
      </div>
    </FormSection>
  );
};
