import {CompanyInternationalPreferences} from '@shipwell/backend-core-sdk';
import {useMutation} from '@tanstack/react-query';
import {updateCompanyInternationalPreferences} from 'App/api/internationalPreferences';
import {useUserMe} from 'App/data-hooks';

export const useUpdateInternationalPreferences = () => {
  const userMeData = useUserMe();
  const companyId = userMeData?.data?.company?.id;
  return useMutation(({values}: {values: CompanyInternationalPreferences}) => {
    if (!companyId) {
      return Promise.reject('Missing required company id parameter');
    }
    return updateCompanyInternationalPreferences(companyId || '', values);
  });
};
