type AddressValues = {country?: string | null; state_province?: string | null; value: undefined};
type NestedAddressValues = {value: AddressValues};

const isNestedAddress = (address: AddressValues | NestedAddressValues): address is NestedAddressValues =>
  !!address.value?.country;

/**
 * Ensures that each address on a contract contains state_province & country WFA-2047
 */
export const validateContractAddress = (addresses?: Array<AddressValues | NestedAddressValues>) =>
  addresses?.every((address) =>
    isNestedAddress(address)
      ? address.value.country && address.value.state_province
      : address.country && address.state_province
  );
