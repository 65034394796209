import {IconButtonWithTooltip, Rule} from '@shipwell/shipwell-ui';
import {ShipmentChargeLineItem, Shipment, CustomField} from '@shipwell/backend-core-singlerequestparam-sdk';
import {InfoTooltip} from './components';
import {CompanyName, FinancialsTableHeader, TextCell} from './layouts';

const tooltipContent =
  "These are your carrier's financials for this shipment. You can view these to aid in resolving disputes, but you won't be able to change them. Rows are highlighted when they differ between parties.";

export const NewVendorTableHeader = ({
  isEditable,
  onCopyClick,
  vendorAssignment,
  is_quoting_limited_user,
  theirVendorCharges,
  myVendorCharges,
  companyName,
  customFields
}: {
  isEditable: boolean;
  onCopyClick?: () => void;
  vendorAssignment?: Shipment['relationship_to_vendor'];
  is_quoting_limited_user?: boolean;
  theirVendorCharges?: ShipmentChargeLineItem[];
  myVendorCharges?: ShipmentChargeLineItem[];
  companyName?: string;
  customFields: CustomField[];
}) => {
  const vendorName = vendorAssignment?.vendor?.name;

  const showCopyButton =
    vendorAssignment && isEditable && !is_quoting_limited_user && theirVendorCharges && theirVendorCharges?.length > 0;

  const hasMyVendorCharges = myVendorCharges && myVendorCharges.length > 0;
  const hasTheirVendorCharges = theirVendorCharges && theirVendorCharges.length > 0;

  const visibleCustomFields = customFields?.filter((customField) => customField.ui_enabled);

  return (
    <>
      <FinancialsTableHeader columnCount={visibleCustomFields.length + 3} isNewFinancialsTable>
        <div className="flex items-center gap-2 overflow-hidden text-ellipsis text-[10px]">CHARGE ITEM</div>
        <div className="flex items-center gap-2 overflow-hidden text-ellipsis text-[10px]">
          <TextCell>DESCRIPTION</TextCell>
        </div>
        {visibleCustomFields.map((customField) => (
          <div key={customField.id} className="flex items-center gap-2 overflow-hidden text-ellipsis text-[10px]">
            <TextCell>{customField.label.toUpperCase()}</TextCell>
          </div>
        ))}
        <div className="flex items-center gap-2 overflow-hidden text-ellipsis text-[10px]">
          <TextCell>RATE</TextCell>
        </div>
        <div className="flex items-center gap-2 overflow-hidden text-ellipsis text-[10px]">
          <TextCell>QTY</TextCell>
        </div>
        <div className="flex items-center gap-2 text-[10px]">
          <CompanyName>{vendorName}</CompanyName>
          {vendorAssignment && <InfoTooltip aria-label={tooltipContent}>{tooltipContent}</InfoTooltip>}
        </div>
        <div className="flex items-center text-[10px]">
          {showCopyButton ? (
            <div className="px-4">
              <IconButtonWithTooltip
                aria-label="Copy financials from my carrier"
                onClick={onCopyClick}
                iconName="ArrowForward"
              />
            </div>
          ) : null}
        </div>
        <div className="flex items-center justify-end text-[10px]">
          {(vendorAssignment || hasMyVendorCharges || hasTheirVendorCharges) && (
            <CompanyName>{companyName}</CompanyName>
          )}
        </div>
      </FinancialsTableHeader>
      <Rule />
    </>
  );
};
