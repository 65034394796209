import {useBrokerCustomers, useV3Shipment, useUserMe} from 'App/data-hooks';
import InlineLoading from 'App/containers/shipments/components/DashboardSummary/InlineLoading';

function CustomerDisplay({shipmentId}: {shipmentId: string}) {
  const userMeQuery = useUserMe();
  const company = userMeQuery.data?.company;
  const {
    context: {getCustomerOptionFromId},
    isLoading: customersQueryIsLoading
  } = useBrokerCustomers(company);
  const shipmentQuery = useV3Shipment(shipmentId);

  const customerId = shipmentQuery.context.customerId;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const customer = getCustomerOptionFromId(customerId);

  return (
    <div>
      <span className="font-bold">Customer: </span>
      {userMeQuery.isInitialLoading || customersQueryIsLoading || shipmentQuery.isInitialLoading ? (
        <InlineLoading />
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        customer?.company?.name || '--'
      )}
    </div>
  );
}

export function ServiceCustomerDisplay({customerId}: {customerId?: string}) {
  const userMeQuery = useUserMe();
  const company = userMeQuery.data?.company;
  const {
    context: {getCustomerOptionFromId},
    isLoading: customersQueryIsLoading
  } = useBrokerCustomers(company, {}, {customerId});

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const customer = getCustomerOptionFromId(customerId);

  return (
    <div>
      <span className="font-bold">Customer: </span>
      {userMeQuery.isInitialLoading || customersQueryIsLoading ? (
        <InlineLoading />
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        customer?.company?.name || '--'
      )}
    </div>
  );
}

export default CustomerDisplay;
