import 'core-js';
import 'regenerator-runtime/runtime';

import {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Provider, connect} from 'react-redux';
import {Router, browserHistory} from 'react-router';
import Moment from 'moment';
import {IntercomProvider} from 'react-use-intercom';
import momentLocalizer from 'react-widgets-moment';
import {Auth0Provider, useAuth0} from '@auth0/auth0-react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {compose} from 'recompose';
import {asyncWithLDProvider, useFlags, useLDClient} from 'launchdarkly-react-client-sdk';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import {Provider as RollbarProvider} from '@rollbar/react';
import has from 'lodash/has';
import {theme, ThemeProvider, Loader, ToastManager} from '@shipwell/shipwell-ui';

import 'App/utils/window-location-origin-polyfill';
import routes from './routes';
import store from './routes/store';
import {HOT_DATA_STALE_TIME} from './utils/queryConstants';

import muiTheme from 'App/materialUiTheme';
import {getCompanyLogo} from 'App/actions/_brokers';
import {isWhiteLabel} from 'App/utils/globals';
import {setAccessTokenFunction} from 'App/api/utils';
import {getSubdomain} from 'App/utils/location';
import getNil from 'App/utils/getNil';
import 'tailwindcss/tailwind.css';
import {getUserMePromise} from 'App/api/auth';
import {withRollbarErrorBoundary} from 'App/common/ErrorBoundary';
import {userToLDUser, ldIdentifyAsync} from 'App/utils/launchDarkly';
import {configureRollbarInstance} from 'App/utils/rollbar';
// Import global react-phone-number-input and react-responsive-ui styles for phone number input styling
import 'react-phone-number-input/style.css';
// Import built shipwell-ui styles directly
import '@shipwell/shipwell-ui/dist/styles.css';
// import custom build of bootstrap
import 'Bootstrap/bootstrap-custom.scss';
// TODO: remove this and fix styles
import 'react-widgets/dist/css/react-widgets.css';
import '../www/style/_grid.scss';
import {DataDogProvider} from 'App/common/dataDog/DataDogProvider';
import {OPTIMIZED_DASHBOARD_LOCALSTORAGE_KEY} from 'App/containers/Dashboard/FeatureFlaggedDashboard';

Moment.locale('en');
momentLocalizer();

let currentPage = '';

const queryClient = new QueryClient();

/** Adjust some defaults to fix the worst default behavior of `useQuery`. */
queryClient.setDefaultOptions({
  queries: {
    /**
     * The default stale time is zero, and the default behavior on "refocus" is to refetch.
     * These two quirks are why why `useQuery` re-fetches the data way more frequently
     * than necessary out of the box.
     * Setting a non-zero default stale time will eliminate many redundant requests when R-Q decides
     * it needs to refetch.
     */
    staleTime: HOT_DATA_STALE_TIME,
    /**
     * In addition to having a non-zero default stale time, we ought not to refetch everything on
     * window refocus. When a user changes tabs, they're probably doing it for a reason, and they'll
     * want to see things just as they left them. When the data is older than the default cache time
     * of 5 minutes, it will be re-fetched anyway.
     */
    refetchOnWindowFocus: false,
    /**
     * The default behavior when `useQuery` get's different query parameters is to start returning
     * `undefined` instead of the data it returned before. Setting `keepPreviousData` to `true` means
     * that our components can choose to wait for the new data before losing the old data, which is
     * almost always a better UX.
     */
    keepPreviousData: true
  }
});

/**
 * Helper to directly retrieve the current user from the API. This is wrapped so that we can handle
 * errors requesting the user separately from errors initializing the app.
 *
 * @return {Object} The user or undefined
 */
async function getCurrentUser() {
  try {
    return await getUserMePromise();
  } catch (e) {
    console.error('Error retrieving user', e);
  }
}

/**
 * Simple heuristic to determine if a given argument is a user. If a nil object or another primitive
 * are supplied for some reason, this check will fail and we can fall back gracefully and continue
 * initializing the app.
 *
 * @param {Object} user
 *
 * @return {Boolean}
 */
function isUser(user) {
  return has(user, 'id');
}

const withWhiteLabeling = (Component) =>
  function RetrieveWhiteLabelingWrapper({dispatch, ...props}) {
    const [retrievedLogos, setRetrievedLogos] = useState(false);

    useEffect(() => {
      const getCompanyLogos = async (subdomain) => {
        if (isWhiteLabel()) {
          try {
            const response = await dispatch(getCompanyLogo(subdomain));
            if (response) {
              const companyLogo = response.details.find((logo) => logo.image_type === 'INLINE_COLOR');
              const inlineLogo = response.details.find((logo) => logo.image_type === 'LOGO_COLOR');

              if (companyLogo) {
                localStorage.setItem('whiteLabelTitle', companyLogo.company_name);
                localStorage.setItem('whiteLabelLogo', companyLogo.logo);
                document.title = getNil(companyLogo, 'company_name', '');
                document.body.classList.add(`brand-${subdomain}`);
              }

              if (inlineLogo) {
                document.querySelectorAll("link[rel*='icon']").forEach((link) => {
                  link.href = inlineLogo.logo;
                });
              }
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          document.title = 'Shipwell';
          localStorage.setItem('whiteLabelTitle', 'Shipwell');
          document.body.classList.add('brand-shipwell');
        }

        setRetrievedLogos(true);
      };

      getCompanyLogos(getSubdomain());
    }, [dispatch]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return retrievedLogos ? <Component {...props} /> : <Loader show />;
  };

const withAuth0 = (Component) =>
  function Auth0GetTokenWrapper(...props) {
    const {getAccessTokenSilently} = useAuth0();
    const [retrievedToken, setRetrievedToken] = useState(false);

    useEffect(() => {
      setAccessTokenFunction(async () => {
        return `Bearer ${await getAccessTokenSilently()}`;
      });

      async function retrieveToken() {
        try {
          await getAccessTokenSilently();
        } catch (e) {
          console.error('Could not retrieve auth0 access token', e);
        } finally {
          setRetrievedToken(true);
        }
      }
      retrieveToken();
    }, [getAccessTokenSilently]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return retrievedToken ? <Component {...props} /> : <Loader show />;
  };

/**
 * A HOC to encapsulate global LaunchDarkly behavior. For now, this HOC just identifies the current
 * user with LD when it is authenticated. This helps in the case where a user has to log in first:
 * we want to re-identify the user to get their specific flags instead of those for an anonymous
 * user.
 */
const withLaunchDarkly = (Component) =>
  function LDIdentifierWrapper(props) {
    const {isLoading, isAuthenticated} = useAuth0();
    const ldClient = useLDClient();
    const [isIdentifying, setIsIdentifying] = useState(true);
    const {decimalSupportForShipmentLineItems} = useFlags();
    window.decimalSupportForShipmentLineItems = decimalSupportForShipmentLineItems;

    useEffect(() => {
      let isUsingOptimizedShipmentDashboardUi = false;
      const handleStorageEvent = () => {
        // get localstorage item, update bool to pass to userToLDUser
        isUsingOptimizedShipmentDashboardUi = localStorage.getItem(OPTIMIZED_DASHBOARD_LOCALSTORAGE_KEY) === 'true';
        fetchCurrentUser();
      };
      // window.addEventListener('storage', handleStorageEvent);
      async function fetchCurrentUser() {
        if (isLoading) {
          return;
        }
        if (isAuthenticated) {
          const currentUser = await getCurrentUser();
          if (isUser(currentUser?.user)) {
            await ldIdentifyAsync(
              ldClient,
              userToLDUser(currentUser.user, currentUser.company, isUsingOptimizedShipmentDashboardUi)
            );
          }
        } else {
          await ldIdentifyAsync(ldClient, userToLDUser({anonymous: true}));
        }
        setIsIdentifying(false);
      }

      // call once during init, then attach to event listener
      handleStorageEvent();
      // return () => {
      //   window.removeEventListener('storage', handleStorageEvent);
      // };
    }, [isLoading, isAuthenticated, ldClient]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return isIdentifying ? <Loader show /> : <Component {...props} />;
  };

const App = () => (
  <DataDogProvider>
    <Router
      history={browserHistory}
      onUpdate={() => {
        //don't scroll when we are simply changing page params (e.g., filters on table)
        //scroll to top when the pathname changes
        //there's probably a better way to do this in react-router 4
        if (window.location.pathname !== currentPage) {
          window.scrollTo(0, 0);
        }
        currentPage = window.location.pathname;
        // trigger analytics on page change
        if (window.analytics) {
          window.analytics.page();
        }
      }}
      routes={routes}
    />

    <ToastManager timeout={5000} />
  </DataDogProvider>
);

const AppWithWrappers = compose(
  withRollbarErrorBoundary,
  withAuth0,
  connect(),
  withWhiteLabeling,
  withLaunchDarkly
)(App);

async function createLDProvider() {
  try {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: process.env.LD_CLIENT_SIDE_ID,
      user: userToLDUser({anonymous: true})
    });
    return LDProvider;
  } catch (e) {
    console.warn('Unable to initialize LaunchDarkly');
    return ({children}) => children;
  }
}

/*
 * Wrap app initialization in an async function per the LaunchDarkly documentation.
 *
 * This means that we defer rendering until we have both requested the current user and initialized
 * LD. This adds a meaningful but acceptable delay to rendering with the benefit that we don't
 * render the app multiple times while this initialization is happening.
 */
(async () => {
  if (!process.env.LD_CLIENT_SIDE_ID) {
    return;
  }
  const LDProvider = await createLDProvider();

  await configureRollbarInstance();

  ReactDOM.render(
    <Provider store={store}>
      <Auth0Provider
        redirectUri={window.location.origin}
        clientId={process.env.AUTH0_CLIENT_ID}
        domain={process.env.AUTH0_DOMAIN}
        audience={process.env.AUTH0_AUDIENCE}
      >
        <RollbarProvider instance={window.Rollbar}>
          <IntercomProvider appId={process.env.INTERCOM_APP_ID} autoBoot autoBootProps={{hideDefaultLauncher: true}}>
            <MuiThemeProvider theme={muiTheme}>
              <ThemeProvider theme={theme}>
                <LDProvider>
                  <QueryClientProvider client={queryClient}>
                    <AppWithWrappers />
                    {JSON.parse(process.env.REACT_QUERY_DEVTOOLS) ? <ReactQueryDevtools initialIsOpen={false} /> : null}
                  </QueryClientProvider>
                </LDProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </IntercomProvider>
        </RollbarProvider>
      </Auth0Provider>
    </Provider>,
    document.getElementById('root')
  );
})();

if (module.hot) {
  module.hot.accept();
}
