import {
  Configuration,
  ContractsApi,
  ContractsApiContractsGetRequest,
  ContractsApiContractsContractIdCalculateChargeItemsPostRequest,
  ContractCalculateChargeItemsResponse,
  ContractChargeItem,
  ContractsApiContractsContractIdContractLanesGetRequest,
  ContractsApiContractsApplicableContractsPostRequest,
  PaginatedContractLanes,
  ShipmentMode,
  ContractLane,
  ContractsApiCarrierRelationshipsCarrierRelationshipIdContractsPostRequest,
  ContractsApiCarrierRelationshipsCarrierRelationshipIdContractsContractIdPutRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getAccessToken} from 'App/api/utils';

const basePath = process.env.SHIPWELL_API_BASE_PATH;

const contractApi = new ContractsApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

/**
 * Get contract by id
 */
export const getContract = async (contractId: string) => {
  const axiosResponse = await contractApi.contractsContractIdGet({contractId});
  return axiosResponse.data;
};

/**
 * Get list of the contracts
 */
export const getContracts = async (requestParameters?: ContractsApiContractsGetRequest) => {
  const axiosResponse = await contractApi.contractsGet(requestParameters);
  return axiosResponse.data;
};

export const createCarrierContract = async (
  requestParameters: ContractsApiCarrierRelationshipsCarrierRelationshipIdContractsPostRequest
) => {
  const axiosResponse = await contractApi.carrierRelationshipsCarrierRelationshipIdContractsPost(requestParameters);
  return axiosResponse.data;
};

export const updateCarrierContract = async (
  requestParameters: ContractsApiCarrierRelationshipsCarrierRelationshipIdContractsContractIdPutRequest
) => {
  const axiosResponse = await contractApi.carrierRelationshipsCarrierRelationshipIdContractsContractIdPut(
    requestParameters
  );
  return axiosResponse.data;
};

export type CorrectedContractCalculateChargeItemsResponse = Omit<ContractCalculateChargeItemsResponse, 'charges'> & {
  charge_items?: ContractChargeItem[];
};
export const getContractChargeItems = async (
  requestParameters: ContractsApiContractsContractIdCalculateChargeItemsPostRequest
): Promise<CorrectedContractCalculateChargeItemsResponse> => {
  const axiosResponse = await contractApi.contractsContractIdCalculateChargeItemsPost(requestParameters);
  return axiosResponse.data;
};

//backend incorrect typing
export type CorrectedContractLane = Omit<ContractLane, 'modes'> & {modes?: Array<ShipmentMode>};
export type CorrectedPaginatedContractLanes = Omit<PaginatedContractLanes, 'results'> & {
  results?: Array<CorrectedContractLane>;
};
export const getContractLanes = async (requestParameters: ContractsApiContractsContractIdContractLanesGetRequest) => {
  const axiosResponse = await contractApi.contractsContractIdContractLanesGet(requestParameters);
  return axiosResponse.data as CorrectedPaginatedContractLanes;
};

export const getContractsApplicableForShipment = async (
  requestParameters: ContractsApiContractsApplicableContractsPostRequest
) => {
  const axiosResponse = await contractApi.contractsApplicableContractsPost(requestParameters);
  return axiosResponse.data;
};
