import _ from 'lodash';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose, renameProp} from 'recompose';
import {Row, Col, Form} from 'react-bootstrap';
import {reduxForm, change, touch} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {withRouter} from 'react-router';
import * as formActions from '../../actions/forms';
import * as actions from '../../actions/users';
import * as billingActions from '../../actions/billing';
import * as shipmentActions from '../../actions/shipments';
import * as authActions from '../../actions/auth';
import * as addressBookActions from '../../actions/addressBook';
import * as productActions from '../../actions/products';
// eslint-disable-next-line import/no-named-as-default
import StopsExpanded from './FormSections/StopsExpanded';
import References from './FormSections/References';
import Header from './FormSections/Header';
// eslint-disable-next-line import/no-named-as-default
import LineItems from './FormSections/LineItems';
import BillTo from './FormSections/BillTo';
import {FinancialsReadOnly} from './FormSections/Financials/components';
import ShipmentTagsCard from 'App/components/shipmentTagsCard';
import ShipmentServices from 'App/formComponents/formSections/shipmentServices';
import QuoteFinancials from 'App/components/QuoteFinancials/QuoteFinancials';
import {fetchPieceTypes} from 'App/actions/_shipments';
import validate from 'App/utils/validateConfirmShipmentForm';
import {scrollToFirstErrorField} from 'App/utils/globals';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import {loadProductIntoForm, updateFormBillTo} from 'App/utils/shipmentFormHelpers';
import {withCustomFieldsProvider} from 'App/data-hooks';
import WithStatusToasts from 'App/components/withStatusToasts';
import BillToView from 'App/containers/Book/components/BillToView';

@connect(
  (state) => ({
    selectedShipmentForForm: state.shipments.selectedShipmentForForm,
    confirmShipmentForm: state.form.confirmShipmentForm,
    selectedShipment: state.shipments.selectedShipment,
    selectedRFQ: state.shipments.selectedRFQ,
    selectedQuote: state.shipments.selectedQuote,
    shipmentModes: state.shipments.shipmentModes,
    goToStops: state.shipments.goToStops,
    company: state.auth.company,
    addressBook: state.addresses.addressbook,
    locationTypes: state.addresses.locationTypes,
    accessorials: state.shipments.accessorialCodes,
    products: state.products.products,
    hazmatCodes: state.shipments.hazmatCodes,
    savedQuoteForCustomerFinancials: state.shipments.savedQuoteForCustomerFinancials,
    purchaseOrders: state.purchaseOrders.details
  }),
  {
    fetchPieceTypes,
    ...formActions,
    ...actions,
    ...billingActions,
    ...shipmentActions,
    ...authActions,
    ...addressBookActions,
    ...productActions
  }
)
class ConfirmShipmentForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.triggerNameChange = this.triggerNameChange.bind(this);
    this.loadProduct = this.loadProduct.bind(this);
    this.triggerLineItemTouch = this.triggerLineItemTouch.bind(this);
    this.initialize = this.initialize.bind(this);
    this.state = {showBillToForm: false, total: 0, quoteErrors: null};
  }

  componentDidMount() {
    const {router, setSuccess} = this.props;
    // NOTE: fetch only when directly navigating, not reload
    // ApiKeyAuth.apiKey needs to exist
    // is valid if company is defined
    if (!_.isEmpty(this.props.company)) {
      this.initialize();
    }
    if (router.location?.query?.showEditDefaultTagsSuccess) {
      setSuccess('User Defaults Updated!', 'User defaults saved successfully.');
    }
  }

  componentWillReceiveProps(nextProps) {
    // NOTE: fetch only on reload or account change
    if (nextProps.company && nextProps.company !== this.props.company) {
      this.initialize();
    }

    if (
      nextProps.confirmShipmentForm &&
      nextProps.confirmShipmentForm.values &&
      nextProps.confirmShipmentForm.values.override_bill_to === true &&
      !this.state.showBillToForm
    ) {
      this.setState({showBillToForm: true});
    } else if (
      nextProps.confirmShipmentForm &&
      nextProps.confirmShipmentForm.values &&
      nextProps.confirmShipmentForm.values.override_bill_to === false &&
      this.state.showBillToForm
    ) {
      this.setState({showBillToForm: false});
    }
  }

  initialize() {
    this.props.addressBookGet({
      page: 1,
      pageSize: 1000,
      ordering: 'location_name',
      q: ''
    });
    this.props.getProducts({
      page: 1,
      pageSize: 1000,
      ordering: 'product_ref',
      q: ''
    });
    this.props.locationTypesGet();
    this.props.fetchPackageTypes();
    this.props.fetchPieceTypes();
    this.props.fetchHazmatCodes();
    this.props.fetchNMFCCodes();
    this.props.fetchShipmentModes();
    this.props.fetchAccessorialCodes();
    this.props.fetchAppointmentTypes();
  }

  triggerNameChange(string) {
    this.props.dispatch(change('confirmShipmentForm', 'name', string));
  }

  loadProduct(productId, index) {
    loadProductIntoForm(this.props.dispatch, 'confirmShipmentForm', productId, index, this.props.products.results);
  }

  triggerLineItemTouch(index, field) {
    this.props.dispatch(touch('confirmShipmentForm', 'line_items[' + index + '].' + field));
  }

  triggerFieldTouch = (field) => {
    this.props.dispatch(touch(`confirmShipmentForm`, field));
  };

  setFieldValue = (field, value) => {
    this.props.dispatch(change('confirmShipmentForm', field, value));
  };

  render() {
    const {
      submitting,
      handleSubmit,
      shipmentModes,
      stopTimes,
      selectedQuote,
      purchaseOrders,
      initialValues,
      referencesCustomFields,
      stopCustomFields,
      selectedShipment,
      selectedShipmentForForm,
      company,
      router
    } = this.props;

    const isAcceptingBid = router?.location?.query?.mode === 'bids';
    const shipmentId = isAcceptingBid ? selectedShipmentForForm?.id : selectedShipment?.id;
    const referenceId = isAcceptingBid ? selectedShipmentForForm?.reference_id : selectedShipment?.reference_id;
    const isInstantRate = selectedQuote && selectedQuote.source_type === 'INSTANT';
    const {hasFTL: isFTLRate, hasLTL: isLTLRate} = checkShipmentModes(selectedQuote?.mode);
    const isParcelRate = selectedQuote && selectedQuote.mode && selectedQuote.mode.code === 'PARCEL';

    return (
      <div className="newshipment">
        <Row>
          <Col md={12} xs={12}>
            <Form onSubmit={this.props.handleSubmit} className="confirmShipmentForm">
              <Header
                hasCustomer
                form="confirmShipmentForm"
                formValues={this.props.confirmShipmentForm}
                label={referenceId && 'Confirm Details for Shipment #' + referenceId}
                showField={false}
              />
              <Row>
                <Col xs={12} sm={12}>
                  <ShipmentTagsCard
                    className="newshipment__card full-width"
                    formValues={this.props.confirmShipmentForm.values}
                    form="confirmShipmentForm"
                    dispatch={this.props.dispatch}
                  />
                </Col>
              </Row>
              <div className="card-container two-objects">
                <div className="card-container-col half-width">
                  <Paper className="newshipment__card full-width">
                    <References
                      form="confirmShipmentForm"
                      shipmentModes={shipmentModes}
                      formValues={this.props.confirmShipmentForm}
                      triggerNameChange={this.triggerNameChange}
                      customFields={referencesCustomFields}
                    />
                  </Paper>
                </div>
                <div className="card-container-col half-width">
                  {isParcelRate && (
                    <Paper className="newshipment__card full-width">
                      <ShipmentServices
                        form="confirmShipmentForm"
                        triggerFieldTouch={this.triggerFieldTouch}
                        setFieldValue={this.setFieldValue}
                      />
                    </Paper>
                  )}
                  <Paper className="newshipment__card full-width">
                    {isInstantRate ? (
                      <QuoteFinancials
                        setTotal={(total) => {
                          this.setState({total: total});
                        }}
                        handleQuoteErrors={(val) => {
                          this.setState({quoteErrors: val});
                        }}
                        canMarkupQuote
                        isConfirmPage
                      />
                    ) : (
                      <FinancialsReadOnly
                        formValues={this.props.confirmShipmentForm}
                        selectedQuote={
                          this.props.savedQuoteForCustomerFinancials
                            ? this.props.savedQuoteForCustomerFinancials
                            : this.props.selectedQuote
                        }
                      />
                    )}
                    <div className="mb-4 flex flex-row items-center justify-between border-t-2 border-sw-border pt-4">
                      <div className="text-xxs text-sw-label">CURRENT BILL-TO INFORMATION</div>
                      {/* For LTL rates, we only want to display the override functionality if the feature flag is on.
                       /* For FTL rates, the override should always be on regardless of the feature flag.
                       */}
                      {((isLTLRate && company?.feature_flags?.can_override_bill_to_for_automated_ltl) || isFTLRate) &&
                      !this.state.showBillToForm ? (
                        <DeprecatedButton
                          variant="tertiary"
                          onClick={() => this.setFieldValue('override_bill_to', true)}
                        >
                          <SvgIcon name="Edit" className="mr-1" />
                          Edit Bill-To Information
                        </DeprecatedButton>
                      ) : null}
                    </div>
                    <BillToView shipmentId={shipmentId} quoteId={selectedQuote?.id} />
                    {this.state.showBillToForm ? (
                      <>
                        <div className="py-4 text-xxs text-sw-label">OVERRIDE BILL-TO INFORMATION</div>

                        <BillTo
                          form="confirmShipmentForm"
                          triggerBillToEdit={this.triggerBillToEdit}
                          showHeader={false}
                          formValues={this.props.confirmShipmentForm}
                        />
                      </>
                    ) : null}
                  </Paper>
                </div>
              </div>
              <Paper className="newshipment__card full-width">
                <StopsExpanded
                  formValues={this.props.confirmShipmentForm}
                  form="confirmShipmentForm"
                  showPickupDateField
                  allowZipOnly={false}
                  dispatchAddressChange={this.dispatchAddressChange}
                  stopTimes={stopTimes}
                  isConfirmationForm
                  quote={this.props.selectedQuote}
                  purchaseOrders={purchaseOrders.purchaseOrdersByStop}
                  scheduleToggles={this.props.scheduleToggles}
                  customFields={stopCustomFields}
                />
              </Paper>
              <Paper className="newshipment__card full-width">
                <LineItems
                  form="confirmShipmentForm"
                  formValues={this.props.confirmShipmentForm}
                  loadProduct={this.loadProduct}
                  triggerLineItemTouch={this.triggerLineItemTouch}
                />
              </Paper>
              {this.props.selectedQuote && this.props.selectedQuote.can_dispatch === false && (
                <p className="warning-text text-center">
                  <i className="icon icon-Flag pad-right" />
                  Potential Dispatch Issue: {this.props.selectedQuote.error_message}
                </p>
              )}
              {!this.props.selectedQuote?.carrier?.id ? (
                <p className="warning-text noCarrier text-center">
                  <i className="icon icon-Flag pad-right" />
                  The selected quote is not tied to a carrier account, so no carrier will be assigned when you Schedule
                  Pickup. You can add the carrier later from the Shipment Details screen.
                </p>
              ) : null}
              {isParcelRate && (
                <div className="confirmShipment__submit text-right">
                  <DeprecatedButton
                    disabled={submitting}
                    className="pull-right"
                    onClick={handleSubmit((values) =>
                      this.props.onSubmit(
                        {...values, option: 'schedule_parcel_pickup'},
                        this.state.total,
                        initialValues
                      )
                    )}
                    variant="primary"
                  >
                    Generate Labels & Schedule Pickup
                  </DeprecatedButton>
                  <DeprecatedButton
                    disabled={submitting}
                    className="pull-right mr-2"
                    onClick={handleSubmit((values) =>
                      this.props.onSubmit({...values, option: 'generate_parcel_label'}, this.state.total, initialValues)
                    )}
                    variant="primary"
                  >
                    Generate Labels
                  </DeprecatedButton>
                  <DeprecatedButton
                    disabled={submitting}
                    className="pull-right mr-2"
                    onClick={() => {
                      this.context.router.push('/marketplace/' + shipmentId + '/fedex-rates/');
                    }}
                    variant="secondary"
                  >
                    Cancel
                  </DeprecatedButton>
                </div>
              )}
              {!isParcelRate && (
                <div className="confirmShipment__submit text-right">
                  <DeprecatedButton
                    disabled={submitting}
                    loading={submitting}
                    className="pull-right"
                    onClick={handleSubmit((values) =>
                      this.props.onSubmit({...values, option: 'print_bol'}, this.state.total, initialValues)
                    )}
                    variant="primary"
                  >
                    {isInstantRate && isFTLRate ? 'Request Rate' : 'Schedule Pickup and Print BOL'}
                  </DeprecatedButton>
                  {!(isInstantRate && isFTLRate) && (
                    <DeprecatedButton
                      disabled={submitting}
                      className="pull-right mr-2"
                      onClick={handleSubmit((values) =>
                        this.props.onSubmit({...values, option: 'schedule_ftl_pickup'}, this.state.total, initialValues)
                      )}
                      variant="secondary"
                    >
                      Schedule Pickup Only
                    </DeprecatedButton>
                  )}
                  {isInstantRate && (
                    <DeprecatedButton
                      disabled={submitting}
                      className="pull-right mr-2"
                      onClick={() => {
                        this.context.router.push('/marketplace/' + shipmentId + '/instant-rates/');
                      }}
                      variant="secondary"
                    >
                      Cancel
                    </DeprecatedButton>
                  )}
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

ConfirmShipmentForm = reduxForm({
  form: 'confirmShipmentForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  touchOnChange: true,
  enableReinitialize: false,
  validate,
  onSubmitFail: scrollToFirstErrorField,
  onChange: (values, dispatch, props, previousValues) => {
    if (values.override_bill_to && values.override_bill_to !== previousValues.override_bill_to) {
      props.searchForCarrierByID(props.selectedQuote.created_by_company.id).then((response) => {
        if (response.status === 200) {
          if (response.details && response.details.results && response.details.results.length > 0) {
            props
              .getCarrierRelationshipsCarrierRelationshipId(response.details.results[0].id)
              .then((getVendorRelationshipResponse) => {
                if (getVendorRelationshipResponse.status === 200) {
                  updateFormBillTo(dispatch, 'confirmShipmentForm', previousValues, values, true);
                }
              });
          }
        }
      });
    } else if (
      values.bill_to_override &&
      previousValues.bill_to_override &&
      values.bill_to_override.direction !== previousValues.bill_to_override.direction
    ) {
      updateFormBillTo(dispatch, 'confirmShipmentForm', previousValues, values, false);
    }
  }
})(ConfirmShipmentForm);

/**
 * Order matters here: the component passed to withCustomFieldsProvider must be already wrapped in reduxForm
 * if the form is expected to receive and validate provided props
 *
 * The context provider for three different custom data contexts (references, line items, stops) is all the way up here
 * so the form can receive and validate these props. Since each context collides with and overwrites the first,
 * we're renaming each prop (except the last) and passing it down to the child/consumer the old fashioned way.
 * Necessary until we're not using class components + reduxForm here and can provide and consume this data more elegantly.
 */

ConfirmShipmentForm = compose(
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.Shipment),
  renameProp('customFields', 'referencesCustomFields'),
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.ShipmentStop),
  renameProp('customFields', 'stopCustomFields'),
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.ShipmentLineItem),
  WithStatusToasts,
  withRouter
)(ConfirmShipmentForm);

ConfirmShipmentForm = connect((state) => {
  const parcelProvider = [
    state.shipments.selectedQuote && state.shipments.selectedQuote.fedex_direct_quote
      ? {id: 'fedex', name: 'FedEx'}
      : state.shipments.selectedQuote && state.shipments.selectedQuote.ups_direct_quote
      ? {id: 'ups', name: 'UPS'}
      : state.shipments.selectedQuote && state.shipments.selectedQuote.usps_direct_quote
      ? {id: 'usps', name: 'USPS'}
      : ''
  ];
  return {
    initialValues: {...state.shipments.selectedShipmentForForm, parcel_provider: parcelProvider}
  };
})(ConfirmShipmentForm);

export default ConfirmShipmentForm;
