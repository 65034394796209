import {UseMutationResult} from '@tanstack/react-query';
import {
  useGetShipmentsDownloadCsvMutation,
  GetShipmentsDownloadCsvMutationOptions,
  GetShipmentsDownloadCsvMutationParams,
  GetShipmentsDownloadCsvResponse,
  GetShipmentsDownloadCsvError
} from './hooks/useGetShipmentsDownloadCsvMutation';

export const ShipmentsDownloadCsv = ({
  options,
  children
}: {
  options: GetShipmentsDownloadCsvMutationOptions;
  children: (
    child: UseMutationResult<
      GetShipmentsDownloadCsvResponse,
      GetShipmentsDownloadCsvError,
      GetShipmentsDownloadCsvMutationParams
    >
  ) => JSX.Element;
}) => children(useGetShipmentsDownloadCsvMutation(options));
