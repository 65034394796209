import {useEffect} from 'react';
import get from 'lodash/get';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Field, getFormValues, change} from 'redux-form';
import {
  FedexShipmentOptions,
  UPSShipmentOptions,
  USPSShipmentOptions
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {ParcelRateRequest, ProviderCode} from '@shipwell/genesis-sdk';
import SelectField from 'App/formComponents/fields/select';
import {useUserMe} from 'App/data-hooks';
import {PackagingTypes} from 'App/utils/packagingTypes';
import {useCapacityProviderAccounts} from 'App/data-hooks/integrations/useCapacityProviderAccounts';
import {NewQuoteServiceOptions} from 'App/api/quoting/hooks/parcel/useCreateParcelRateRequest';
import ShipwellLoader from 'App/common/shipwellLoader';
import {GenesisServiceOptions} from 'App/formComponents/formSections/shipmentServices/genesisServiceOptions';
import {LegacyServiceOptions} from 'App/formComponents/formSections/shipmentServices/legacyServiceOptions';
import './styles.scss';

type ParcelOptions = {
  fedex_specific_options?: FedexShipmentOptions | null;
  ups_specific_options?: UPSShipmentOptions | null;
  usps_specific_options?: USPSShipmentOptions | null;
  // genesis specific options
  service_options?: NewQuoteServiceOptions | null;
};

export type PartialNewQuoteFormValues = ParcelOptions & {
  parcel_provider?: Array<{id: string; name: string}>;
  line_items?: Array<{provider_specific_packaging?: string | null}>;
  id?: string;
  preferred_currency?: string;
};

type ShipmentServicesProps = {
  values?: PartialNewQuoteFormValues;
  form: string;
  isSlimForm?: boolean;
  dispatch: Dispatch;
  triggerFieldTouch: (field: string) => void;
  setFieldValue: (field: string, value: unknown) => void;
  isLoadingParcelRates?: boolean;
  parcelRateRequest?: ParcelRateRequest | null;
};

const ShipmentServices = ({
  values = {},
  form,
  isSlimForm,
  dispatch,
  triggerFieldTouch,
  setFieldValue,
  isLoadingParcelRates,
  parcelRateRequest
}: ShipmentServicesProps) => {
  const {data: {company: {feature_flags: featureFlags = {}} = {}} = {}} = useUserMe();
  const {fedex_enabled: fedexEnabled, ups_enabled: upsEnabled, usps_enabled: uspsEnabled} = featureFlags;

  const isFedExParcel = !!values.fedex_specific_options;
  const isUPSParcel = !!values.ups_specific_options;
  const isUSPSParcel = !!values.usps_specific_options;
  // genesis providers live under service_options
  const isPurolatorParcel =
    values.service_options?.providerCode === ProviderCode.Purolator && !isFedExParcel && !isUPSParcel && !isUSPSParcel;

  // Genesis accounts (Purolator specific for now)
  const {connections} = useCapacityProviderAccounts({providerCode: ProviderCode.Purolator});
  const purolatorAccounts = connections?.map(({id, account, name}) => {
    const accountNumber = [name, ...(account && 'account_number' in account ? [account?.account_number] : [])]
      .filter(Boolean)
      .join(' - ');

    return {
      id,
      account_number: accountNumber
    };
  });
  const purolatorEnabled = !!purolatorAccounts?.length;

  // these options spawn RFQs to get quotes
  const parcelProviderOptions = [
    ...(fedexEnabled ? [{id: 'fedex', name: 'FedEx'}] : []),
    ...(upsEnabled ? [{id: 'ups', name: 'UPS'}] : []),
    ...(uspsEnabled ? [{id: 'usps', name: 'USPS'}] : [])
  ];
  // these options create genesis rate requests to get quotes
  const genesisProviderOptions = [...(purolatorEnabled ? [{id: ProviderCode.Purolator, name: 'Purolator'}] : [])];
  const combinedProviderOptions = [...parcelProviderOptions, ...genesisProviderOptions];

  let parcelLabel = 'FedEx';

  if (isUPSParcel) {
    parcelLabel = 'UPS';
  }
  if (isUSPSParcel) {
    parcelLabel = 'USPS';
  }
  if (isPurolatorParcel) {
    parcelLabel = 'Purolator';
  }

  const providerOptionsField = isPurolatorParcel ? 'service_options' : `${parcelLabel.toLowerCase()}_specific_options`;
  const serviceOptions = get(values, providerOptionsField) as undefined | FedexShipmentOptions;

  const handleUpdateProviderSpecificPackaging = () =>
    values.line_items?.map((lineItem, index) =>
      dispatch(change(form, `line_items[${index}].provider_specific_packaging`, PackagingTypes.YourPackaging))
    );

  const handleChangeParcelProvider = (e: unknown, selectValue: typeof combinedProviderOptions) => {
    const providers = parcelProviderOptions.map(({id}) => id);
    const genesisProviders = genesisProviderOptions.map(({id}) => id);

    if (isSlimForm && selectValue && Array.isArray(selectValue)) {
      providers.forEach((provider) => {
        const providerKey = `${provider}_specific_options` as keyof ParcelOptions;

        if (selectValue?.find(({id}) => id === provider)) {
          // if there are 0 or more than 1 providers selected then we have to clear the existing specific_options
          // if there is 1 provider selected we will try to use an existing options object
          // if there is no existing options object set it to {} to get new quotes from RFQ
          if (selectValue?.length !== 1 || !values[providerKey]) {
            dispatch(change(form, providerKey, {}));
            // set all packaging to the genering YourPackaging
            handleUpdateProviderSpecificPackaging();
          }
        } else {
          // if the option is not selected set its option value to null to prevent getting quotes from RFQ
          dispatch(change(form, providerKey, null));
        }
      });

      // genesis providers should be nested under a service_options key as {[provider]: {options}}
      genesisProviders.forEach((provider) => {
        if (selectValue?.find(({id}) => id === provider)) {
          if (selectValue?.length !== 1 || !values.service_options) {
            dispatch(change(form, 'service_options', {providerCode: ProviderCode.Purolator}));
            // set all packaging to the genering YourPackaging
            handleUpdateProviderSpecificPackaging();
          }
        } else {
          dispatch(change(form, 'service_options', null));
        }
      });
    }
  };

  // this useEffect is to load genesis service options into redux-form
  // since the values do not live on the shipment and must be fetched through a hook I couldn't initialize the form with these values
  useEffect(() => {
    // we only want to do this once, if the user selects different parcel providers we don't want to keep reloading the values
    if (parcelRateRequest && values.service_options === undefined) {
      const {capacity_providers, bill_to} = parcelRateRequest;
      const cap = capacity_providers.at(-1);

      dispatch(
        change(form, 'service_options', {
          providerCode: cap?.provider_code,
          account: cap?.account_number,
          service_code: cap?.service_codes?.[0],
          bill_to,
          ...cap?.service_options
        })
      );
      dispatch(
        change(form, 'parcel_provider', [
          ...(values.parcel_provider || []),
          genesisProviderOptions.find((option) => option.id === cap?.provider_code)
        ])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelRateRequest?.id, values.service_options]);

  useEffect(() => {
    if (serviceOptions?.cod_collection_type) {
      dispatch(
        change(form, `${providerOptionsField}.cod_collection_amount_currency`, values.preferred_currency || 'USD')
      );
    }
  }, [dispatch, form, providerOptionsField, serviceOptions?.cod_collection_type, values.preferred_currency]);

  return isLoadingParcelRates ? (
    <ShipwellLoader loading={isLoadingParcelRates} />
  ) : (
    <>
      {isSlimForm ? (
        <div className="field-grid">
          <div className="grid-item-2">
            <Field
              multi
              label="Parcel Provider"
              name="parcel_provider"
              placeholder="Parcel Provider"
              options={combinedProviderOptions}
              component={SelectField as unknown as 'select'}
              onChange={handleChangeParcelProvider}
            />
          </div>
        </div>
      ) : null}
      {isSlimForm ? null : isPurolatorParcel ? (
        <GenesisServiceOptions
          values={values}
          parcelLabel={parcelLabel}
          triggerFieldTouch={triggerFieldTouch}
          setFieldValue={setFieldValue}
        />
      ) : (
        <LegacyServiceOptions
          values={values}
          parcelLabel={parcelLabel}
          triggerFieldTouch={triggerFieldTouch}
          setFieldValue={setFieldValue}
          isFedExParcel={isFedExParcel}
          isUPSParcel={isUPSParcel}
          isUSPSParcel={isUSPSParcel}
        />
      )}
    </>
  );
};

export default connect((state, {form}: ShipmentServicesProps) => ({
  values: getFormValues(form)(state) || {}
}))(ShipmentServices);
