import {Field, FieldArray} from 'formik';
import startCase from 'lodash/startCase';
import omit from 'lodash/omit';
import {
  FormikTextInput,
  FormikPhoneInput,
  FormikSelect,
  FormikCheckbox,
  Tooltip,
  SvgIcon,
  DeprecatedButton
} from '@shipwell/shipwell-ui';
import {CompanyPreferencesAutoArchiveShipmentStatesEnum} from '@shipwell/backend-core-sdk';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import DeleteWarningTooltip from 'App/components/DeleteWarningTooltip';

/**
 * Company Profile Fields
 * @param {*} props
 */
const CompanyProfileFields = ({values}) => {
  //despite the enum the following statuses are rejected by this endpoint...
  const disallowedStatuses = ['AuctionRunning', 'Delayed', 'OutForDelivery', 'Scheduled'];
  const shipmentStatuses = Object.entries(
    omit(CompanyPreferencesAutoArchiveShipmentStatesEnum, disallowedStatuses)
  ).map(([label, value]) => ({
    value,
    label: startCase(label)
  }));

  const carrierStatusOptions = [
    {value: 'ACTIVE', label: 'Active'},
    {value: 'INACTIVE', label: 'Inactive'}
  ];

  return (
    <>
      <div className="field-grid">
        <div className="grid-item-2">
          <Field required name="name" label="Company Name" component={FormikTextInput} />
        </div>
        <div className="grid-item-2">
          <Field
            required
            searchAddressBook={false}
            name="mailing_address"
            label="Company Address"
            component={AddressSearch}
          />
        </div>
        <div className="grid-item-2">
          <Field required name="primary_email" label="Admin Email" component={FormikTextInput} />
        </div>
      </div>
      <FieldArray
        name="billing_emails"
        render={(arrayHelpers) => (
          <div className="billing-emails">
            {values.billing_emails?.length === 0 && (
              <div className="billing-emails__email-container field-grid grid-1-12">
                <Field
                  className="grid-item-1-12"
                  required
                  name={`billing_emails[0]`}
                  label="Billing Email"
                  component={FormikTextInput}
                />
              </div>
            )}
            {values.billing_emails.length > 0
              ? values.billing_emails?.map((email, i) => {
                  return (
                    <div key={`billing-email${i}`} className="billing-emails__email-container field-grid grid-1-12">
                      <Field
                        className={`grid-item-1-${values.billing_emails.length <= 1 ? '12' : '11'}`}
                        required={i === 0}
                        name={`billing_emails[${i}]`}
                        label="Billing Email"
                        component={FormikTextInput}
                      />
                      {values.billing_emails.length > 1 && (
                        <DeleteWarningTooltip
                          title="Delete Billing Contact"
                          itemLabel={`${email} as a billing contact`}
                          onDelete={() => {
                            arrayHelpers.remove(i);
                          }}
                        >
                          <DeprecatedButton
                            className="grid-item-12-12 billing-emails__button-remove"
                            variant="icon"
                            icon={<SvgIcon name="TrashOutlined" />}
                          />
                        </DeleteWarningTooltip>
                      )}
                    </div>
                  );
                })
              : null}
            <DeprecatedButton
              className="billing-emails__button-add"
              icon={<SvgIcon name="AddCircleOutlined" />}
              variant="tertiary"
              onClick={() => {
                arrayHelpers.push('');
              }}
            >
              Add Additional Billing Email
            </DeprecatedButton>
          </div>
        )}
      />
      <div className="field-grid">
        <Field required name="primary_phone_number" label="Phone Number" component={FormikPhoneInput} />
        <Field
          simpleValue
          clearable={false}
          name="country"
          label="Country Settings"
          options={[
            {id: 'CA', label: 'Canada'},
            {id: 'US', label: 'United States'}
          ]}
          component={FormikSelect}
        />
        <div className="grid-item-2">
          <Field
            simpleValue
            clearable={false}
            label="Default Carrier Status"
            name="default_carrier_status"
            options={carrierStatusOptions}
            component={FormikSelect}
          />
        </div>
      </div>
      <hr className="company-settings-form__divider" />
      <span className="company-settings-form__header">Auto-Archive Settings</span>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-11">
          <Field
            name="auto_archive_shipment_states"
            component={FormikSelect}
            required
            isMulti
            simpleValue
            options={shipmentStatuses}
            label="Auto-Archive Shipment Status"
          />
        </div>
        <div className="grid-item-12-12 company-settings-form__tooltip-container">
          <Tooltip
            placement="top"
            tooltipClassname="company-settings-form__tooltip"
            tooltipContent="Shipments that match the status selected here will be auto-archived after the allotted amount of time you choose below."
          >
            <SvgIcon name="InfoOutlined" color="$sw-icon" />
          </Tooltip>
        </div>
        <div className="grid-item-1-12">
          <Field name="auto_archive_shipment_days" component={FormikTextInput} label="Auto-Archive After (Days)" />
        </div>
      </div>

      <hr className="company-settings-form__divider" />
      <span className="company-settings-form__header">
        Automated Tracking Calls
        <Tooltip
          placement="top"
          tooltipClassname="company-settings-form__tooltip"
          tooltipContent="Automated calls can be scheduled on the shipment detail screen. We will call the driver with an automated message, log their current location, and update the ETA if needed."
        >
          <SvgIcon name="InfoOutlined" color="$sw-icon" />
        </Tooltip>
      </span>
      <div className="field-grid">
        <Field
          name="automated_check_calling_enabled"
          component={FormikCheckbox}
          label="Enable Automated Calls for Shipment Tracking"
        />
      </div>

      <span className="company-settings-form__header">Converting Orders to Shipments</span>
      <Field
        name="prompt_for_mode_and_customer_when_consolidating_orders"
        component={FormikCheckbox}
        label="Always display the Customer/Mode modal when creating shipments from orders"
      />
    </>
  );
};

export default CompanyProfileFields;
