import {Form, Formik, FormikHelpers} from 'formik';
import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import {
  InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum,
  Shipment,
  ShipmentChargeLineItem,
  ShipmentLineItem,
  Stop
} from '@shipwell/backend-core-sdk';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {FreightInvoiceFormData} from '../../types';
import {getFreightInvoiceFormDefaultValues, FreightInvoiceFormValidationSchema} from './validationSchema';
import InvoiceNumberField from './InvoiceNumberField';
import MessageToCustomerField from './MessageToCustomerField';
import FinancialLineItems from './FinancialLineItems';
import UploadedDocuments from './UploadedDocuments';
import {
  constructCarrierAssignmentPayload,
  getFreightInvoicePayload,
  getSelectedFreightInvoiceDocumentMetadata,
  getSelectedFreightInvoiceDocuments,
  mapSettlementsLineItemsToLegacyFinancialLineItems
} from './utils';
import RemitToInfo from './RemitToInfo';
import ShipmentLegInfo from './ShipmentLegInfo';
import RequiredDocuments from './RequiredDocuments';
import {WithStatusToastProps} from 'App/components/withStatusToasts';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {createFreightInvoicePost, updateCarrierAssignment} from 'App/api/invoicing/typed';
import {
  FREIGHT_INVOICES_QUERY_KEY,
  LEGACY_SHIPMENTS_QUERY_KEY,
  SETTLEMENTS_DELIVERED_DASHBOARD_QUERY_KEY
} from 'App/data-hooks/queryKeys';
import {useGetFullShipmentDetails} from 'App/containers/alertsDashboard/utils/hooks/useGetFullShipmentDetails';
import Loader from 'App/common/shipwellLoader';
import {shipmentsShipmentIdGet} from 'App/actions/shipmentdetails';
import {useGetCarrierAssignmentById, useUserMe} from 'App/data-hooks';
import DateTermField from 'App/formComponents/fields/dateTerm';
import DatePickerField from 'App/formComponents/fields/datePicker';

export interface FreightInvoiceFormProps {
  onClose: () => void;
  chargeLineItems?: ShipmentChargeLineItem[];
  role: InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum;
  shipmentId: Shipment['id'];
  stops?: Stop[];
  shipmentLineItems?: ShipmentLineItem[];
  customerName?: string;
  customerReferenceNumber?: Shipment['customer_reference_number'];
  setSuccess?: WithStatusToastProps['setSuccess'];
  setError?: WithStatusToastProps['setError'];
  dispatch?: Dispatch<Action<typeof shipmentsShipmentIdGet>>;
}

const FreightInvoiceForm = ({
  onClose,
  chargeLineItems = [],
  role,
  shipmentId,
  stops,
  shipmentLineItems,
  customerName,
  customerReferenceNumber,
  setSuccess,
  setError,
  dispatch
}: FreightInvoiceFormProps) => {
  const {queryInfo: legacyShipmentQueryInfo} = useGetFullShipmentDetails(shipmentId);
  const {data: userData, isInitialLoading: isLoadingUserData} = useUserMe();
  const carrierAssignmentId = legacyShipmentQueryInfo.data?.relationship_to_customer?.id || '';
  const {data: carrierAssignmentQueryData} = useGetCarrierAssignmentById(shipmentId, carrierAssignmentId);
  const createFreightInvoice = useMutation((formData: FreightInvoiceFormData) => {
    //Since mutate only takes one argument, additional mutation occurs here.
    const freightInvoiceDocuments = getSelectedFreightInvoiceDocuments(formData);
    const freightInvoiceDocumentMetadata = getSelectedFreightInvoiceDocumentMetadata(formData);
    return createFreightInvoicePost(
      shipmentId,
      {
        ...formData,
        charge_line_items: formData.charge_line_items.filter((chargeLineItem) => chargeLineItem.add_to_invoice)
      },
      freightInvoiceDocuments,
      {
        document_metadata: freightInvoiceDocumentMetadata
      }
    );
  });
  const updateCarrierAssignmentPut = useMutation(
    (newFinancialLineItems: FreightInvoiceFormData['charge_line_items']) => {
      if (!carrierAssignmentQueryData) {
        return Promise.reject('Missing carrier assignment');
      }
      const mappedNewFinancialLineItems = mapSettlementsLineItemsToLegacyFinancialLineItems(newFinancialLineItems);
      const updatedCarrierAssignment = constructCarrierAssignmentPayload(
        carrierAssignmentQueryData,
        mappedNewFinancialLineItems
      );
      return updateCarrierAssignment(shipmentId, carrierAssignmentId, updatedCarrierAssignment);
    }
  );

  const queryClient = useQueryClient();

  const createFreightInvoiceMutationFn = (
    freightInvoicePayload: FreightInvoiceFormData,
    setSubmitting: FormikHelpers<FreightInvoiceFormData>['setSubmitting']
  ) =>
    createFreightInvoice.mutate(freightInvoicePayload, {
      onSuccess: () => {
        setSuccess?.('Invoice Created!', 'Your changes have been saved.');
        //We don't have any of the modern redux tools to type actions correctly, but we don't want to make
        //all of the API calls in fetchDetails.
        if (dispatch) {
          void dispatch(shipmentsShipmentIdGet(shipmentId, {}) as unknown as Action);
        }
        onClose();
      },
      onError: () =>
        setError?.(
          'Error!',
          'Something went wrong when trying to create this invoice. Please try again or contact your administrator.'
        ),
      onSettled: () => {
        void queryClient.invalidateQueries([
          FREIGHT_INVOICES_QUERY_KEY,
          shipmentId,
          //when we post a freight invoice, the role is upper case.
          //However, in the list view (UI Dashboard Route), the param is lower case.
          role.toLowerCase()
        ]);
        void queryClient.invalidateQueries([SETTLEMENTS_DELIVERED_DASHBOARD_QUERY_KEY]);
        setSubmitting(false);
      }
    });

  const handleSubmit = (
    values: FreightInvoiceFormData,
    setSubmitting: FormikHelpers<FreightInvoiceFormData>['setSubmitting']
  ) => {
    const freightInvoicePayload = getFreightInvoicePayload(values);
    const newFinancialLineItems = freightInvoicePayload.charge_line_items.filter(
      (lineItem) => lineItem.create_as_financial_line_item
    );
    if (freightInvoicePayload?.charge_line_items.length === 0) {
      setError?.('Error!', 'Please add at least one financial line item to the freight invoice.');
      setSubmitting(false);
    }
    if (newFinancialLineItems.length === 0) {
      return createFreightInvoiceMutationFn(freightInvoicePayload, setSubmitting);
    }
    return updateCarrierAssignmentPut.mutate(newFinancialLineItems, {
      onSettled: () => {
        void queryClient.invalidateQueries([LEGACY_SHIPMENTS_QUERY_KEY, shipmentId]);
        createFreightInvoiceMutationFn(freightInvoicePayload, setSubmitting);
      },
      //We don't have any of the modern redux tools to type actions correctly, but we don't want to make
      //all of the API calls in fetchDetails.
      onSuccess: () => dispatch && void dispatch(shipmentsShipmentIdGet(shipmentId, {}) as unknown as Action)
    });
  };

  if (legacyShipmentQueryInfo.isInitialLoading || isLoadingUserData) {
    return <Loader loading />;
  }

  const initialValues = getFreightInvoiceFormDefaultValues(
    legacyShipmentQueryInfo.data?.reference_id || '',
    role,
    userData?.company
  );
  const invoiceSource =
    role === InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum.ServiceProvider
      ? 'carrier_generated'
      : 'shipper_generated';

  return (
    <Formik
      validationSchema={FreightInvoiceFormValidationSchema}
      initialValues={initialValues}
      onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}
    >
      {({isSubmitting}) => (
        <Form noValidate className="mb-10">
          <div className="flex flex-col gap-y-4">
            <InvoiceNumberField />
            <div className="grid grid-cols-2 gap-2">
              <DateTermField isDueDateField />
              <DatePickerField isDueDateField showTooltip />
            </div>
            <MessageToCustomerField />
            <Card title="Financials" draggableProvided={null} isCollapsible>
              <CollapsibleCardContent>
                <FinancialLineItems chargeLineItems={chargeLineItems} shipmentId={shipmentId} role={role} />
              </CollapsibleCardContent>
            </Card>
            <Card title="Documents" draggableProvided={null} isCollapsible>
              <CollapsibleCardContent expanded>
                <RequiredDocuments shipmentId={shipmentId} invoiceSource={invoiceSource} />
                <UploadedDocuments shipmentId={shipmentId} invoiceSource={invoiceSource} />
              </CollapsibleCardContent>
            </Card>
            <Card title="Remit To Info" draggableProvided={null} isCollapsible>
              <CollapsibleCardContent expanded>
                <RemitToInfo />
              </CollapsibleCardContent>
            </Card>
            <Card title="Shipment Info" draggableProvided={null} isCollapsible>
              <CollapsibleCardContent>
                <ShipmentLegInfo
                  stops={stops}
                  shipmentLineItems={shipmentLineItems}
                  customerName={customerName}
                  customerReferenceNumber={customerReferenceNumber}
                />
              </CollapsibleCardContent>
            </Card>
          </div>
          <ModalFormFooter onCancel={onClose} isSubmitting={isSubmitting} primaryActionName="Send" />
        </Form>
      )}
    </Formik>
  );
};
export default connect()(FreightInvoiceForm);
