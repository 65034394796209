import {Link, withRouter, WithRouterProps} from 'react-router';
import {FieldArray, Form, Formik} from 'formik';
import {Banner, Button, Card} from '@shipwell/shipwell-ui';
import {CreateSupplierUser, SupplierUser} from '@shipwell/backend-core-sdk';
import {compose} from 'recompose';
import {SupplierUserFieldsCard} from '../fields';
import {SUPPLIER_CONTACTS_FORM_SCHEMA} from '../schemas';
import {getDeletedUserIds, isExistingUser, isUpdatedUser, pickCreateUserFields, sortByCreatedAT} from './utils';
import Loader from 'App/common/shipwellLoader';
import {useCreateSupplierUsers, useDeleteSupplierUsers, useGetSupplier, useUpdateSupplierUsers} from 'App/data-hooks';
import FormFooter from 'App/formComponents/formSections/formFooter';
import withStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {toTitleCase} from 'App/utils/globals';

const getError = (gerund: string) => [
  `Error ${toTitleCase(gerund)} Supplier Contacts`,
  `There was an error ${gerund.toLocaleLowerCase()} a supplier contact. Please try again.`
];

const emptyContact: CreateSupplierUser = {
  email: '',
  full_name: '',
  should_send_notifications: true
};

export type SupplierContactsEditFormInitialValues = {
  contacts: (SupplierUser | CreateSupplierUser)[];
};

type SupplierContactsEditFormProps = Pick<WithRouterProps<{supplier_id: string}>, 'params'> &
  Pick<WithStatusToastProps, 'setError'>;

export const SupplierContactsEditFormBase = ({params, setError}: SupplierContactsEditFormProps) => {
  const {supplier, error} = useGetSupplier(params.supplier_id);

  const {deleteSupplierUsers, isDeletingUsers} = useDeleteSupplierUsers({
    onError: () => {
      const [title, message] = getError('Deleting');
      setError(title, message);
    }
  });

  const {updateSupplierUsers, isUpdatingUsers} = useUpdateSupplierUsers({
    onError: () => {
      const [title, message] = getError('Updating');
      setError(title, message);
    }
  });

  const {createSupplierUsers, isCreatingUsers} = useCreateSupplierUsers({
    onError: () => {
      const [title, message] = getError('Creating');
      setError(title, message);
    }
  });

  const isSubmitting = isDeletingUsers || isUpdatingUsers || isCreatingUsers;

  if (supplier) {
    const handleSubmit = (values: SupplierContactsEditFormInitialValues) => {
      if (isSubmitting) return;

      const serverUsers = supplier.users;
      const formUsers = values.contacts;

      const deletedUserIds = getDeletedUserIds({formUsers: formUsers, serverUsers: serverUsers});
      if (deletedUserIds.length > 0) deleteSupplierUsers({supplierId: supplier.id, userIds: deletedUserIds});

      const newUsers = formUsers.filter((formUser) => !isExistingUser(formUser));
      if (newUsers.length) createSupplierUsers({supplierId: supplier.id, users: newUsers});

      const updatedUsers = formUsers.filter((formUser): formUser is SupplierUser =>
        isUpdatedUser({formUser, serverUsers})
      );
      if (updatedUsers.length)
        updateSupplierUsers({
          supplierId: supplier.id,
          users: updatedUsers.map((user) => ({data: pickCreateUserFields(user), id: user.id}))
        });
    };

    const initialValues: SupplierContactsEditFormInitialValues = {
      contacts: supplier.users?.sort(sortByCreatedAT) || []
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={SUPPLIER_CONTACTS_FORM_SCHEMA}
        enableReinitialize
      >
        {({dirty, resetForm, values, isValid}) => {
          const {contacts} = values;

          return (
            <Form noValidate className="pb-24">
              <FieldArray name="contacts">
                {({push, remove}) => (
                  <Card
                    draggableProvided={false}
                    title="Contacts"
                    actions={
                      <Button
                        isCompact
                        variant="tertiary"
                        iconName="AddCircleOutlined"
                        onClick={() => void push(emptyContact)}
                        disabled={isSubmitting || !isValid}
                      >
                        Add Contact
                      </Button>
                    }
                  >
                    {contacts.length < 1 ? (
                      <h2 className="text-lg">No contacts found for this supplier</h2>
                    ) : (
                      <ul className="grid gap-4" aria-label="Contacts">
                        {contacts.map((contact, index) => {
                          const id = isExistingUser(contact) ? contact.id : undefined;
                          return (
                            <li key={index.toString() + (id || '')} aria-label={contact.full_name || 'New contact'}>
                              <SupplierUserFieldsCard
                                index={index}
                                onDelete={
                                  // Don't allow the last user to be deleted
                                  contacts.length > 1 ? () => remove(index) : null
                                }
                              />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </Card>
                )}
              </FieldArray>
              {dirty ? (
                <FormFooter
                  primaryActionName="Save"
                  secondaryActionName="Cancel"
                  isSubmitting={isSubmitting}
                  onCancel={resetForm}
                />
              ) : null}
            </Form>
          );
        }}
      </Formik>
    );
  }

  // TODO: cleanup this approach and maybe checkout some of the error conversion utils available in src/app/utils/errors.ts
  if (error) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    const status = (error as any)?.response?.status;
    if (typeof status === 'number' && status === 404) {
      return (
        <Banner title="Supplier not found">
          The supplier you&apos;re looking for doesn&apos;t exist. Go to{' '}
          <Link to="/suppliers">the suppliers list page</Link>
        </Banner>
      );
    }
    return <Banner title="Error">There was an error. Try reloading the page.</Banner>;
  }

  return <Loader loading />;
};

export const SupplierContactsEditForm = compose<SupplierContactsEditFormProps, Record<string, never>>(
  withRouter,
  withStatusToasts
)(SupplierContactsEditFormBase);
