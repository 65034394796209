import {useCallback, useEffect, useRef, useState} from 'react';
import {noop} from 'lodash';

type UseCurrentTimeRefType = {
  mounted: boolean;
  alignmentMs: number;
  lastTicks: number;
  timeoutHandle: NodeJS.Timeout | number | null;
  fCheckSelf: () => void;
};

/**
 * A hook that rerenders whenever the result of
 * `Math.floor(Date.now() / alignmentMs)`
 * changes, yielding a fresh `Date` instance that rounds down the current time
 * to the given alignment, and remains constant until the next render.
 */
export default function useCurrentTime(alignmentMs = 60000): Date {
  const hookRef = useRef<UseCurrentTimeRefType>({
    mounted: false,
    alignmentMs,
    lastTicks: Math.floor(Date.now() / alignmentMs),
    timeoutHandle: null,
    fCheckSelf: noop
  });
  const [currentTimeState, setCurrentTimeState] = useState(new Date(alignmentMs * hookRef.current.lastTicks));
  const checkSelf = useCallback(
    function insideUseCurrentTimeCheckSelf(): void {
      const {mounted, alignmentMs, lastTicks} = hookRef.current;
      if (!mounted) return;
      const now = Date.now();
      const nowTicks = Math.floor(now / alignmentMs);
      if (nowTicks !== lastTicks) {
        hookRef.current.lastTicks = nowTicks;
        const timeDate = new Date(alignmentMs * nowTicks);
        if (+timeDate !== +currentTimeState) {
          setCurrentTimeState(timeDate);
        }
      }
      if (hookRef.current.timeoutHandle) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
        clearTimeout(hookRef.current.timeoutHandle as any);
      }
      const delay = alignmentMs - (now % alignmentMs);
      hookRef.current.timeoutHandle = setTimeout(() => hookRef.current.fCheckSelf(), delay);
    },
    [currentTimeState, setCurrentTimeState]
  );
  hookRef.current.fCheckSelf = checkSelf;

  useEffect(() => {
    if (!hookRef.current.mounted || hookRef.current.alignmentMs !== alignmentMs) {
      hookRef.current.mounted = true;
      hookRef.current.alignmentMs = alignmentMs;
      setTimeout(() => hookRef.current.fCheckSelf(), 1);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      hookRef.current.mounted = false;
    };
  }, [checkSelf, alignmentMs]);

  return currentTimeState;
}
