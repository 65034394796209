import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Formik, Form, Field} from 'formik';
import {object, string} from 'yup';
import get from 'lodash/get';
import {FormikCollapsibleGroupSelect, DeprecatedButton, FormikDateTimePicker, SvgIcon} from '@shipwell/shipwell-ui';
import {ShipmentTimelineSourceEnum} from 'App/utils/globalsTyped';
import {createStopEvent} from 'App/api/shipment';
import makeLabelForOptionObject from 'App/utils/makeLabelForOptionObject';
import {getShipmentTimeline, shipmentsShipmentIdGet} from 'App/actions/_shipmentDetails';
import useGroupedStopEventTypes from 'App/api/shipment/useGroupedStopEventTypes';
import useGroupedStopStatusReasonCodes from 'App/api/shipment/useGroupedStopStatusReasonCodes';
import {
  isArrivalEventType,
  isDepartureEventType,
  isArrivalSimpleEvent,
  isDepartureSimpleEvent,
  isDateAfterStopPlannedWindowEnd,
  isDateAfterStopArrival,
  isDateAfterStopDeparture,
  isArrivalSimpleEventAndLate
} from 'App/utils/stopEvent';
import './styles.scss';

/**
 * A reusable helper function to add validation around arrival and departure dates to the validation
 * schema for occurred_at. Adds tests to the given schema based on whether the event is an arrival
 * or departure. Since the calculation of those conditions is dependent on the event source, those
 * are passed in by the caller.
 *
 * @param {Object} schema The schema for the occurred_at field
 * @param {Boolean} isArrival Is the event type we're validating an arrival event
 * @param {Boolear} isDeparture Is the event type we're validationg a departure event
 * @param {Object} shipment The shipment whose stops we are validating
 *
 * @return {Object} The updated or original schema, depending on isArrival and isDeparture
 */
function addArrivalAndDepartureValidationToOccurredAtValidationSchema(schema, isArrival, isDeparture, stop, shipment) {
  // For departure events, we want to check that the chosen time is after this stop's arrival
  // time. If there isn't an arrival time, we don't want to allow a departure event, either.
  const departureEventSchema = isDeparture
    ? schema.test('is-departure-after-arrival', 'Completed date must be after the Arrival date.', (occurred_at) =>
        isDateAfterStopArrival(occurred_at, stop, 'confirmed_arrival_at')
      )
    : schema;

  // For both arrival and departure events, we want to check all previous stops' arrival and
  // departure dates to make sure the chosen date is after each of them.
  const previousStopsSchema =
    isArrival || isDeparture
      ? departureEventSchema.test(
          'is-departure-or-arrival-after-previous-dates',
          'Date must be after all the previous dates.',
          (occurred_at) =>
            get(shipment, 'stops', [])
              .filter((shipmentStop) => shipmentStop.ordinal_index < stop.ordinal_index)
              .every(
                (previousStop) =>
                  isDateAfterStopArrival(occurred_at, previousStop) &&
                  isDateAfterStopDeparture(occurred_at, previousStop)
              )
        )
      : departureEventSchema;

  return previousStopsSchema;
}

/**
 * Create the validation schema for long-form event creation. `occurred_at` validation depends on the
 * sibling field `stop_event_type`.
 *
 * @param {Object} stop The stop for which the event is being created
 * @param {Object} shipment The shipment whose stops are being validated
 * @param {Array} groupedStopEventTypes The grouped event types from the API
 *
 * @return {Object} The validation schema
 */
function createEventValidationSchema(stop, shipment, groupedStopEventTypes) {
  return object().shape({
    stop_event_type: string().nullable().required('An event type is required.'),
    stop_event_reason_code: string().nullable().required('A reason is required.'),
    occurred_at: string()
      .nullable()
      .required('A date and time is required.')
      .when('stop_event_type', (eventType, schema) => {
        const isArrival = isArrivalEventType(eventType, groupedStopEventTypes);
        const isDeparture = isDepartureEventType(eventType, groupedStopEventTypes);
        return addArrivalAndDepartureValidationToOccurredAtValidationSchema(
          schema,
          isArrival,
          isDeparture,
          stop,
          shipment
        );
      })
  });
}

const StopEventsCreate = ({shipment, stop, onCancel, onSubmit, onError, dispatch}) => {
  const groupedStopEventTypes = useGroupedStopEventTypes();
  const groupedStopStatusReasonCodes = useGroupedStopStatusReasonCodes();

  const handleSubmit = async (values) => {
    try {
      await createStopEvent(shipment.id, stop.id, values);
      await Promise.all([dispatch(getShipmentTimeline(shipment.id)), dispatch(shipmentsShipmentIdGet(shipment.id))]);
      onSubmit({title: 'Stop Event Added!', message: 'Your event was added to the timeline'});
    } catch (e) {
      console.error('Error creating stop event', e);
      onError({
        title: 'Error creating stop event!',
        error: get(e, 'error_description', 'There was an error creating the stop event.')
      });
    }
  };

  return (
    <div className="shipment__stopEvents-create">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={createEventValidationSchema(stop, shipment, groupedStopEventTypes)}
        initialValues={{
          stop_event_type: '',
          stop_event_reason_code: '',
          occurred_at: new Date().toISOString(),
          manually_created: true,
          source: ShipmentTimelineSourceEnum.WebApp
        }}
        initialTouched={{
          occurred_at: true
        }}
      >
        {({isSubmitting, dirty, isValid}) => (
          <Form>
            <div className="shipment__stopEvents-create-field">
              <Field
                component={FormikCollapsibleGroupSelect}
                name="stop_event_type"
                label="Event Type"
                simpleValue
                options={groupedStopEventTypes}
                getOptionLabel={makeLabelForOptionObject}
                maxMenuHeight={500}
              />
            </div>
            <div className="shipment__stopEvents-create-field">
              <Field
                name="occurred_at"
                label="Event Time"
                component={FormikDateTimePicker}
                timezone={get(stop, 'location.address.timezone')}
                defaultValue={new Date()}
              />
            </div>
            <div className="shipment__stopEvents-create-field">
              <Field
                component={FormikCollapsibleGroupSelect}
                name="stop_event_reason_code"
                label="Reason"
                simpleValue
                options={groupedStopStatusReasonCodes}
                getOptionLabel={makeLabelForOptionObject}
                maxMenuHeight={500}
              />
            </div>
            <div className="shipment__stopEvents-create-buttons">
              <DeprecatedButton variant="secondary" disabled={isSubmitting} onClick={onCancel}>
                Cancel
              </DeprecatedButton>
              <DeprecatedButton type="submit" disabled={isSubmitting || !dirty || !isValid} loading={isSubmitting}>
                Save
              </DeprecatedButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

StopEventsCreate.propTypes = {
  shipment: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  stop: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  dispatch: PropTypes.func
};

StopEventsCreate.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  onError: () => {},
  dispatch: () => {}
};

export default connect((state) => ({
  shipment: state.shipmentdetails.one
}))(StopEventsCreate);

/**
 * Create the validation schema for simple event creation. `occurred_at` validation depends on the
 * passed in, pre-determined event object.
 *
 * @param {Object} stop The stop for which the event is being created
 * @param {Object} shipment The shipment whose stops are being validated
 * @param {Object} event The simple event object being created
 *
 * @return {Object} The validation schema
 */
function createSimpleEventValidationSchema(stop, shipment, event) {
  return object().shape({
    stop_event_type: string().nullable(),
    stop_event_reason_code: string()
      .nullable()
      .when('occurred_at', (occurred_at, schema) => {
        // only ask for reason codes when arriving late
        if (!isArrivalSimpleEvent(event)) {
          return schema;
        }

        return isDateAfterStopPlannedWindowEnd(occurred_at, stop)
          ? schema.required('A reason code is required.')
          : schema;
      }),
    occurred_at: string()
      .nullable()
      .required('A date and time is required.')
      .when('stop_event_type', (eventType, schema) => {
        const isArrival = isArrivalSimpleEvent(event);
        const isDeparture = isDepartureSimpleEvent(event);
        return addArrivalAndDepartureValidationToOccurredAtValidationSchema(
          schema,
          isArrival,
          isDeparture,
          stop,
          shipment
        );
      })
  });
}

const SimpleStopEventsCreateBase = ({shipment, stop, onCancel, onSubmit, onError, dispatch, event}) => {
  const groupedStopStatusReasonCodes = useGroupedStopStatusReasonCodes(['NORMAL_STATUSES']);
  const handleSubmit = async (values) => {
    try {
      await event.createEvents(shipment, stop, values);
      await Promise.all([dispatch(getShipmentTimeline(shipment.id)), dispatch(shipmentsShipmentIdGet(shipment.id))]);
      onSubmit({title: 'Stop Event Added!', message: 'Your event was added to the timeline'});
    } catch (e) {
      console.error('Error creating stop event', e);
      onError({
        title: 'Error creating stop event!',
        error: get(e, 'error_description', 'There was an error creating the stop event.')
      });
    }
  };

  return (
    <div className="shipment__stopEvents-create">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={createSimpleEventValidationSchema(stop, shipment, event)}
        initialValues={{
          stop_event_type: '',
          stop_event_reason_code: '',
          occurred_at: new Date().toISOString()
        }}
        initialTouched={{
          occurred_at: true
        }}
        initialStatus={isArrivalSimpleEventAndLate(event, new Date(), stop)}
      >
        {({isSubmitting, dirty, isValid, errors, status, setStatus, setFieldValue, validateField}) => (
          <Form>
            <div className="shipment__stopEvents-create-field">
              <Field
                name="occurred_at"
                label="Event Time"
                component={FormikDateTimePicker}
                onChange={async (date) => {
                  // Re-validate this field when it changes
                  // Since this is asynchronous, it lets the setFieldValue happen after the
                  // validation schema has been updated to make stop_event_reason_code not required.
                  await validateField('occurred_at');

                  // But since validation doesn't return any results, we have to double-check using
                  // the same validation rules.
                  const isLate = isArrivalSimpleEventAndLate(event, date, stop);
                  setStatus(isLate);
                  // If the new date is not late, we need to reset the reason code to be empty so
                  // it's not submitted. This must happen after validateField so the validation
                  // schema is correct when this value is set.
                  if (!isLate) {
                    setFieldValue('stop_event_reason_code', '');
                  }
                }}
                startOpen
                timeIntervals={15}
                timezone={get(stop, 'location.address.timezone')}
                defaultValue={new Date()}
              />
            </div>
            {status || get(errors, 'stop_event_reason_code') ? (
              <>
                <div className="shipment__stopEvents-create-lateReasonBlurb">
                  <SvgIcon name="ErrorOutlined" color="$sw-error" />
                  <div>
                    The time you entered is after the planned arrival time so a late flag will be applied to the stop.
                    Please select a reason for the late arrival.
                  </div>
                </div>
                <div className="shipment__stopEvents-create-field">
                  <Field
                    component={FormikCollapsibleGroupSelect}
                    name="stop_event_reason_code"
                    label="Reason"
                    simpleValue
                    options={groupedStopStatusReasonCodes}
                    getOptionLabel={makeLabelForOptionObject}
                    maxMenuHeight={500}
                  />
                </div>
              </>
            ) : null}
            <div className="shipment__stopEvents-create-buttons">
              <DeprecatedButton variant="secondary" disabled={isSubmitting} onClick={onCancel}>
                Cancel
              </DeprecatedButton>
              <DeprecatedButton type="submit" disabled={isSubmitting || !dirty || !isValid} loading={isSubmitting}>
                Save
              </DeprecatedButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const SimpleStopEventsCreate = connect()(SimpleStopEventsCreateBase);

SimpleStopEventsCreate.propTypes = {
  shipment: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  stop: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  dispatch: PropTypes.func,
  event: PropTypes.shape({
    id: PropTypes.string,
    createEvents: PropTypes.func
  })
};

StopEventsCreate.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  onError: () => {},
  dispatch: () => {},
  event: {
    id: null,
    createEvents: () => {}
  }
};
