import {DateSelectFilter, DateSelectFilterProps} from '../DateSelectFilter';
import {tryGetDateFromRelativeDate, tryGetRelativeDateFromDate} from 'App/containers/Dashboard/utils/date/dateUtils';

export type RelativeDateSelectFilterProps = DateSelectFilterProps;

/**
 * A Datepicker variation that works with relative date strings
 * represent by the EnumRelativeDates enum
 *
 * @example
 * <RelativeDateSelectFilter
 *  selectedDates=['today', 'today']
 *  onSelect={([start, end]) =>
 *    // start  = 'yesterday'
 *    // end    = 'today'
 *  }
 * />
 */
export function RelativeDateSelectFilter(props: RelativeDateSelectFilterProps) {
  const firstDate = tryGetDateFromRelativeDate(props.selectedDates[0]);
  const secondDate = tryGetDateFromRelativeDate(props.selectedDates[1]);

  function handleSelectDates(newDates: [string, string]) {
    props.onSelect([tryGetRelativeDateFromDate(newDates[0]), tryGetRelativeDateFromDate(newDates[1])]);
  }

  return (
    <DateSelectFilter selectedDates={[firstDate, secondDate]} pastOnly={props.pastOnly} onSelect={handleSelectDates} />
  );
}
