import {toTitleCase} from 'App/utils/string';

type EnumType = {[k: string]: string};

/* converts enums from the SDK to the label/value format
    to be used with FormikSelect components */
export const enumToOptionConverter = (enumerable: EnumType, isTitleCase = true) => {
  const optionsList = Object.keys(enumerable).map((key) => {
    return {label: isTitleCase ? toTitleCase(enumerable[key]) : enumerable[key], value: enumerable[key]};
  });
  return optionsList;
};
