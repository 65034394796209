import React from 'react';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import {string, object} from 'yup';
import {Card, CollapsibleCardContent, DeprecatedButton, FormikTextInput, SvgIcon, Tooltip, FormikDateTimePicker} from '@shipwell/shipwell-ui';
import {
  FeatureFlags,
  InlineObject3,
  Shipment,
  ShipmentChargeLineItem,
  ShipmentDocumentMetadata
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {parseISO, isBefore, isAfter, isFuture} from 'date-fns';
import {FlexBox} from 'App/components/Box';
import ShipmentLegInfo from 'App/containers/settlements/freightInvoices/components/FreightInvoiceForm/ShipmentLegInfo';
import ChargeLineItems from 'App/containers/Shipment/forms/ShipmentBillingForm/ChargeLineItems';
import BillingDocuments from 'App/containers/Shipment/forms/ShipmentBillingForm/BillingDocuments';
import DateTermField from 'App/formComponents/fields/dateTerm';
import DatePickerField from 'App/formComponents/fields/datePicker';
import {DateTerm} from 'App/containers/settlements/freightInvoices/types';

export type BillChargeLineItem = ShipmentChargeLineItem & {add_to_bill?: boolean};

export type ShipmentBillingFormData = Pick<InlineObject3, 'approved_date' | 'bill_number' | 'due_date'> & {
  carrier: {value: string; label: string};
  charge_line_items: Array<BillChargeLineItem>;
  due_date_term?: DateTerm | string;
  approved_date_term?: DateTerm | string;
  transaction_date: string;
  documents: Array<ShipmentDocumentMetadata & {add_to_bill?: boolean}>;
};

const billingFormValidationSchema = object().shape({
  carrier: object().nullable().shape({
    label: string(),
    value: string()
  }),
  bill_number: string().required('Bill # is required.'),
  due_date: string().nullable(),
  due_date_term: object().nullable().shape({
    id: string(),
    label: string(),
    date: string().nullable()
  }),
  approved_date: string().nullable(),
  approved_date_term: object().nullable().shape({
    id: string(),
    label: string(),
    date: string().nullable()
  }),
  transaction_date: string().nullable()
});

const ShipmentBillingForm = ({
  one,
  isTriumphPayAuthed,
  featureFlags,
  externalPaymentStatus,
  handleFormSubmit,
  onClose
}: {
  one: Shipment;
  isTriumphPayAuthed: boolean;
  featureFlags: FeatureFlags;
  externalPaymentStatus?: string;
  handleFormSubmit: (
    values: ShipmentBillingFormData,
    setSubmitting: FormikHelpers<ShipmentBillingFormData>['setSubmitting'],
    setFieldError: FormikHelpers<ShipmentBillingFormData>['setFieldError']
  ) => void;
  onClose: () => void;
}) => {
  
  const initialValues = {
    carrier: {
      value: one?.relationship_to_vendor?.vendor?.id || '',
      label: one?.relationship_to_vendor?.vendor?.name || ''
    },
    bill_number: one?.reference_id || '',
    due_date: '',
    due_date_term: '',
    approved_date: '',
    approved_date_term: '',
    transaction_date: '',
    charge_line_items: one?.relationship_to_vendor?.customer_charge_line_items
      ? one.relationship_to_vendor.customer_charge_line_items
          .filter((lineItem) => lineItem.id)
          .map((lineItem) => ({
            ...lineItem,
            add_to_bill: true
          }))
      : [],
    documents: []
  };

  const isTriumphPayEnabledAndAuthed = featureFlags && featureFlags.triumph_pay_enabled && isTriumphPayAuthed;
  const showTriumphPayAuthed = isTriumphPayEnabledAndAuthed && externalPaymentStatus === 'CURRENT';

  const handleSubmit = (
    values: ShipmentBillingFormData,
    formikHelpers: Pick<FormikHelpers<ShipmentBillingFormData>, 'setSubmitting' | 'setFieldError'>
  ) => {
    if (values.due_date && !isFuture(parseISO(values.due_date))) {
      formikHelpers.setFieldError('due_date', "Due date must be greater than today's date.");
      formikHelpers.setSubmitting(false);
      return;
    }

    const transactionDate = parseISO(values.transaction_date);
    const approvedDate = values.approved_date ? parseISO(values.approved_date) : null;
    const dueDate = values.due_date ? parseISO(values.due_date) : null;
    const deliveryDate = one?.most_recently_awarded_quote?.delivery_date ? parseISO(one?.most_recently_awarded_quote?.delivery_date) : null;

    if (deliveryDate && isBefore(transactionDate, deliveryDate)) {
      formikHelpers.setFieldError('transaction_date', 'Transaction date cannot be before the delivery date.');
      formikHelpers.setSubmitting(false);
      return;
    }
    if (dueDate && isAfter(transactionDate, dueDate)) {
      formikHelpers.setFieldError('transaction_date', 'Transaction date cannot be after the due date.');
      formikHelpers.setSubmitting(false);
      return;
    }
    if (approvedDate && isAfter(transactionDate, approvedDate)) {
      formikHelpers.setFieldError('transaction_date', 'Transaction date cannot be after the approved date.');
      formikHelpers.setSubmitting(false);
      return;
    }
      handleFormSubmit(values, formikHelpers.setSubmitting, formikHelpers.setFieldError);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={billingFormValidationSchema}
      onSubmit={(values, {setSubmitting, setFieldError}) => handleSubmit(values, {setSubmitting, setFieldError})}
      enableReinitialize
    >
      {({isSubmitting, values}) => (
        <Form>
          <FlexBox direction="col" pad="m" gap="m">
            <Field
              disabled
              name="carrier.label"
              label="Carrier"
              component={FormikTextInput}
              textInputClassname="border-r-0"
              append={
                <>
                  {showTriumphPayAuthed ? (
                    <div className="flex items-center">
                      <SvgIcon color="$sw-success" name="CheckCircleOutlined" />
                      <div className="ml-2 w-[150px] text-sw-success">Verified on Triumphpay</div>
                    </div>
                  ) : null}
                </>
              }
            />
            {isTriumphPayEnabledAndAuthed ? (
              <>
                {externalPaymentStatus === 'NOT_SET_UP' ? (
                  <div className="mt-1">
                    <span className="text-sw-error">
                      Carrier is not in TriumphPay. Update carrier details before creating bill.
                    </span>
                  </div>
                ) : null}
                {externalPaymentStatus === 'SYNC_ERROR' ? (
                  <div className="mt-1">
                    <span className="text-sw-error">
                      TriumphPay needs updated carrier information. Resave carrier details before creating bill.
                    </span>
                  </div>
                ) : null}
              </>
            ) : null}
              <FlexBox items="center">
              <div className="mr-3 grid w-full grid-cols-2 gap-4">
                <div className='flex items-center'>
                  <Field className="mr-3 grow" name="bill_number" label="Bill #" component={FormikTextInput} />                      
                  <Tooltip
                    tooltipContent={
                      <>
                        <span className="text-bold">Bill #</span>
                        <br />
                        Identifier for the bill, could also be received invoice number. If left blank, automatically
                        assigned by the financial management system.
                      </>
                    }
                    trigger="hover"
                    wrapperClassname="tooltipWrapper"
                    tooltipClassname="w-[300px]"
                  >
                    <SvgIcon name="InfoOutlined" color="$sw-icon" />
                  </Tooltip>
                </div>                       
                <Field className="grow" name="transaction_date" label="Transaction Date" showTimeSelect={false} prepend={<SvgIcon name="Calendar" />} component={FormikDateTimePicker} />
              </div>
              <Tooltip
                tooltipContent={
                  <>
                    <span className="text-bold">Transaction Date</span>
                    <br />
                    Override of the transaction date of the bill. If left blank, this is automatically computed based on the date that the delivery was completed.
                  </>
                }
                trigger="hover"
                wrapperClassname="tooltipWrapper"
                tooltipClassname="w-[300px]"
              >
                <SvgIcon name="InfoOutlined" color="$sw-icon" />
              </Tooltip>
            </FlexBox>
            <FlexBox items="center">
              <div className="mr-3 grid w-full grid-cols-2 gap-4">
                <DateTermField isDueDateField />
                <DatePickerField isDueDateField />
              </div>
              <Tooltip
                tooltipContent={
                  <>
                    <span className="text-bold">Due Date</span>
                    <br />
                    Override for the due date of the bill. If left blank, this is automatically computed based on the
                    terms for the vendor within the financial management system.
                  </>
                }
                trigger="hover"
                wrapperClassname="tooltipWrapper"
                tooltipClassname="w-[300px]"
              >
                <SvgIcon name="InfoOutlined" color="$sw-icon" />
              </Tooltip>
            </FlexBox>
            <FlexBox items="center">
              <div className="mr-3 grid w-full grid-cols-2 gap-4">
                <DateTermField />
                <DatePickerField />
              </div>
              <Tooltip
                tooltipContent={
                  <>
                    <span className="text-bold">Approved Date</span>
                    <br />
                    Override for the approved date of the bill. If left blank, this is automatically computed based on
                    the date that the delivery was completed.
                  </>
                }
                trigger="hover"
                wrapperClassname="tooltipWrapper"
                tooltipClassname="w-[300px]"
              >
                <SvgIcon name="InfoOutlined" color="$sw-icon" />
              </Tooltip>
            </FlexBox>
            <Card draggableProvided={null} title="Financials" isCollapsible>
              <CollapsibleCardContent>
                <ChargeLineItems shipmentId={one.id} />
              </CollapsibleCardContent>
            </Card>
            <Card draggableProvided={null} title="Documents" isCollapsible>
              <CollapsibleCardContent>
                <BillingDocuments shipmentId={one?.id} />
              </CollapsibleCardContent>
            </Card>
            <Card title="Shipment Info" draggableProvided={null} isCollapsible>
              <CollapsibleCardContent>
                <ShipmentLegInfo
                  stops={one?.stops}
                  shipmentLineItems={one?.line_items}
                  customerName={one?.relationship_to_customer?.customer?.name}
                  customerReferenceNumber={one?.customer_reference_number}
                />
              </CollapsibleCardContent>
            </Card>
          </FlexBox>
          <FlexBox direction="row" justify="end" gap="m" pad="m">
            <DeprecatedButton onClick={onClose} variant="secondary">
              Cancel
            </DeprecatedButton>
            <DeprecatedButton
              type="submit"
              disabled={isSubmitting || !values.charge_line_items.some((i) => i.add_to_bill)}
            >
              Send
            </DeprecatedButton>
          </FlexBox>
        </Form>
      )}
    </Formik>
  );
};

export default ShipmentBillingForm;
