import {ReactNode} from 'react';
import {browserHistory, Link} from 'react-router';
import isNumber from 'lodash/isNumber';
import {SvgIcon, DeprecatedButton} from '@shipwell/shipwell-ui';
import {isContainerLegItem} from 'App/containers/shipments/utils/typed';
import {useDrayageLeg, useShipmentLegs, useV3Shipment, useShipmentFromLeg} from 'App/data-hooks';
import HeaderTags from 'App/containers/shipments/components/Header/HeaderTags';
import ShipmentName from 'App/containers/shipments/components/Header/ShipmentName';
import {LegStatusDropdown, LegStatusPill, ShipmentStatusPill} from 'App/containers/shipments/components/Status';

export const HeaderContainer = ({children}: {children: ReactNode}) => (
  <div className="px-5 shadow-inner-bottom">{children}</div>
);

export const InlineHeaderContainer = ({children}: {children: ReactNode}) => (
  <div className="flex h-10 items-center justify-start">{children}</div>
);

export const BackLink = ({routeTo}: {routeTo?: string}) => (
  <div className="mr-4 h-6 cursor-pointer">
    {routeTo ? (
      <Link to={routeTo}>
        <SvgIcon name="ArrowBack" color="sw-icon" />
      </Link>
    ) : (
      <DeprecatedButton className="p-0" variant="icon" onClick={() => browserHistory.goBack()}>
        <SvgIcon name="ArrowBack" color="sw-icon" />
      </DeprecatedButton>
    )}
  </div>
);

const ReferenceId = ({shipmentId}: {shipmentId: string}) => {
  const shipmentQuery = useV3Shipment(shipmentId);

  if (shipmentQuery.isInitialLoading) {
    return <SvgIcon name="LoadingDots" />;
  }

  return (
    <div className="mr-4 text-lg">
      Shipment {shipmentQuery.data?.reference_id} {shipmentQuery.isError ? 'NOT FOUND' : null}
    </div>
  );
};

export const ContainerId = ({shipmentId, legId}: {shipmentId: string; legId: string}) => {
  const getLegsFromShipmentQuery = useShipmentLegs(shipmentId);

  if (getLegsFromShipmentQuery.isInitialLoading) {
    return <SvgIcon name="LoadingDots" />;
  }

  const legs = getLegsFromShipmentQuery?.data;

  const indexOfLeg = legs?.findIndex((leg) => leg.id === legId);

  const matchingLegItem = legs?.find((leg) => leg.id === legId)?.leg_items?.[0];

  if ((matchingLegItem && !isContainerLegItem(matchingLegItem)) || !isNumber(indexOfLeg)) {
    return null;
  }
  const containerNumber = matchingLegItem?.identification_number;

  return <div className="mx-4 text-lg font-bold">Container #{containerNumber ?? indexOfLeg + 1}</div>;
};

export const ShipmentDetailsHeader = ({shipmentId}: {shipmentId: string}) => {
  const filterParams = localStorage.getItem('dashboardFilterParams');
  const backLink = filterParams ? `/dashboard?${filterParams}` : '/dashboard';

  return (
    <HeaderContainer>
      <InlineHeaderContainer>
        <div className="flex w-full items-center gap-2">
          <BackLink routeTo={backLink} />
          <ReferenceId shipmentId={shipmentId} />
          <ShipmentStatusPill shipmentId={shipmentId} />
          <HeaderTags shipmentId={shipmentId} className="tw-hidden lg:flex" />
        </div>
      </InlineHeaderContainer>
      <HeaderTags shipmentId={shipmentId} className="m-2 lg:hidden" showTagBorder={false} />
      <ShipmentName shipmentId={shipmentId} />
    </HeaderContainer>
  );
};

export const LegDetailsHeader = ({legId}: {legId: string}) => {
  const drayageQuery = useDrayageLeg(legId);
  const shipmentQuery = useShipmentFromLeg(drayageQuery.data);

  const {shipmentId} = shipmentQuery.context;

  if (!shipmentId) {
    return null;
  }

  return (
    <HeaderContainer>
      <InlineHeaderContainer>
        <div className="flex w-full items-center">
          <BackLink routeTo={`/shipments/${shipmentId}/containers`} />
          <ReferenceId shipmentId={shipmentId} />
          <SvgIcon name="ArrowLargeRight" />
          <ContainerId shipmentId={shipmentId} legId={legId} />
          <LegStatusPill legId={legId} />
          <LegStatusDropdown legId={legId} />
        </div>
      </InlineHeaderContainer>
    </HeaderContainer>
  );
};
