import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useAuth0} from '@auth0/auth0-react';
import {Loader} from '@shipwell/shipwell-ui';

/**
 * Wrapper for components that are only supposed to be rendered when the user is not authenticated.
 * Redirects to dashboard if user is authenticated by means of Auth0 or impersonation login.
 */
const withUnauthenticatedUser = (Component) => {
  const InauthenticationWrapper = (props) => {
    const {router} = props;
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const {isAuthenticated, isLoading} = useAuth0();

    useEffect(() => {
      const redirectUserIfLoggedIn = () => {
        router.replace('/dashboard/');
      };

      if (!isLoading) {
        if (isAuthenticated || localStorage.getItem('swimpersonator')) {
          redirectUserIfLoggedIn();
        } else {
          setIsAuthenticating(false);
        }
      }
    }, [isLoading, isAuthenticated, router]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return isAuthenticating ? <Loader show /> : <Component {...props} />;
  };

  InauthenticationWrapper.propTypes = {
    router: PropTypes.shape({
      replace: PropTypes.func
    })
  };

  return InauthenticationWrapper;
};

export default withUnauthenticatedUser;
