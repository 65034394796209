import {number, string} from 'yup';

export const nmfcCodeSchema = number()
  .nullable()
  .typeError('The NMFC code must be a number between two and six digits long.')
  .integer('The NMFC code must be a number between two and six digits long.')
  .min(10, 'The NMFC code must be at least two digits long.')
  .max(999999, 'The NMFC code must be at most six digits long.');

export const nmfcSubCodeSchema = string()
  .nullable()
  .typeError('The NMFC sub code must be a two digit number.')
  .length(2, 'The NMFC sub code must be a two digit number.')
  .matches(/^\d\d$/, {message: 'The NMFC sub code must be a two digit number.', excludeEmptyString: true});

/**
 * Validate an NMFC code using the yup schema. Throws a ValidationError if the given code is not
 * valid.
 *
 * @param {String|Number} code The NMFC code to validate
 *
 * @return {Number} The validated NMFC code
 * @throws {ValidationError}
 */
export default function validateNMFCCode(code) {
  return nmfcCodeSchema.validateSync(code);
}

/**
 * Validate an NMFC sub code using the yup schema. Throws a ValidationError if the given sub code is
 * not valid.
 *
 * @param {String|Number} code The NMFC sub code to validate
 *
 * @return {Number} The validated NMFC sub code
 * @throws {ValidationError}
 */
export function validateNMFCSubCode(subCode) {
  return nmfcSubCodeSchema.validateSync(subCode);
}
