import {useState, ReactNode} from 'react';
import {connect} from 'react-redux';
import {
  CurrencyCode,
  SettlementsDashboardAmount,
  SettlementsDashboardRow,
  FreightInvoiceStatus,
  SettlementsDashboardSubStatus,
  SettlementsDashboardInvoiceableChargeLineItem,
  Shipment
} from '@shipwell/backend-core-sdk';
import {FreightInvoiceSubStatusType} from '@shipwell/settlements-sdk';
import classNames from 'classnames';
import {SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import pluralize from 'pluralize';
import capitalize from 'lodash/capitalize';
import {FreightInvoicePercentPill, getFreightInvoiceExpectedActualPercentageChange} from '../FreightInvoiceForm/utils';
import {SettlementDocumentsModal} from '../FreightInvoicesList/components/SettlementDocumentsModal';
import {StatusData} from '../../types';
import {
  getSubStatusesAriaDescription,
  useGetSubStatusDescriptions,
  getInvoiceSourceValue
} from '../SettlementsDashboard/DashboardView/utils';
import {InvoiceFinancialsTooltip} from './components';
import {toCurrency} from 'App/utils/grammar';
import {formatDate, formatDateMonthDayYear} from 'App/utils/dateTimeGlobalsTyped';
import useToggle from 'App/utils/hooks/useToggle';
import {formatCurrency} from 'App/utils/internationalConstants';
import ActionLinkWithModal from 'App/components/actionLinkWithModal';
import {V3_VERSION} from 'App/containers/Dashboard/utils/constants';
import Messages from 'App/components/Messages';
import {useGetFullShipmentDetails} from 'App/containers/alertsDashboard/utils/hooks/useGetFullShipmentDetails';
import Loader from 'App/common/shipwellLoader';
import {HOT_DATA_STALE_TIME} from 'App/utils/queryConstants';
import ShipmentNotes from 'App/components/shipmentNotes';
import {useGetFreightInvoiceDocuments} from 'App/api/settlements/queryHooks';
import {usePrefetchDocumentTypes, useDocumentShortNames} from 'App/data-hooks';
import {State} from 'App/reducers/types';
import {isDefined} from 'App/utils/guard';
export {default as ActionsCell} from './ActionsCell';

export const TwoColumnLayoutTableContainer = ({children}: {children: ReactNode}) => (
  <div
    className={classNames(
      'grid gap-x-3 items-center',
      'grid-cols-[repeat(auto-fill,_minmax(max-content,_110px))]',
      'md:grid-cols-[repeat(auto-fill,_minmax(max-content,_70px)_minmax(max-content,_150px))]'
    )}
  >
    {children}
  </div>
);
export const TwoColumnLayoutTable = ({
  label,
  value,
  hasIconCol = false,
  icon,
  wrapperClassName
}: {
  label: string;
  value: string | null;
  hasIconCol?: boolean;
  icon?: JSX.Element | null;
  wrapperClassName?: string;
}) => (
  <>
    <div className={wrapperClassName}>{label}:</div>
    <div
      className={classNames('grid gap-x-3 items-center', {
        'grid-cols-[max-content_60px] md:grid-cols-[1fr_60px]': hasIconCol,
        'grid-cols-[max-content] md:grid-cols-[1fr]': !hasIconCol
      })}
    >
      <div className={classNames('md:text-right', wrapperClassName)}>{value}</div>
      {hasIconCol ? <div>{icon}</div> : null}
    </div>
  </>
);

export const FreightInvoiceCostsCellBase = ({row, isCarrier}: {row: SettlementsDashboardRow; isCarrier: boolean}) => {
  const totalInvoiceAmountNumber = parseFloat(row.total_amount?.value);
  const totalShipmentAmountNumber = parseFloat(row.invoiceable.total_amount?.value || '0');
  const expectedActualPercentDiff =
    totalInvoiceAmountNumber && totalShipmentAmountNumber
      ? getFreightInvoiceExpectedActualPercentageChange(totalInvoiceAmountNumber, totalShipmentAmountNumber)
      : 0;
  const hasWrongAmountSubStatus = (row.sub_statuses || []).some(
    (subStatus) => subStatus.sub_status === FreightInvoiceSubStatusType.WrongAmount
  );
  return (
    <TwoColumnLayoutTableContainer>
      <TwoColumnLayoutTable
        label="Invoiced"
        value={toCurrency(totalInvoiceAmountNumber, row.total_amount?.currency)}
        hasIconCol
      />
      <TwoColumnLayoutTable
        label="Expected"
        value={toCurrency(totalShipmentAmountNumber, row.invoiceable.total_amount?.currency)}
        hasIconCol
      />
      <TwoColumnLayoutTable
        label="Difference"
        value={toCurrency(Math.abs(totalInvoiceAmountNumber - totalShipmentAmountNumber), row.total_amount?.currency)}
        hasIconCol
        icon={
          typeof expectedActualPercentDiff === 'number' && expectedActualPercentDiff !== 0 ? (
            <FreightInvoicePercentPill
              isCarrier={isCarrier}
              hasWrongAmountSubStatus={hasWrongAmountSubStatus}
              percentChange={expectedActualPercentDiff}
            />
          ) : null
        }
      />
    </TwoColumnLayoutTableContainer>
  );
};

export const FreightInvoiceCostsCell = connect((state: State) => {
  const {one} = state.shipmentdetails as {one: Shipment};
  return {
    isCarrier: state.auth.company.id === one?.current_carrier?.id
  };
})(FreightInvoiceCostsCellBase);

export const FreightInvoiceDatesCell = ({row}: {row: SettlementsDashboardRow}) => {
  const {invoiceable, created_at: createdDate, due_date: dueDate} = row;
  const {delivered_date: deliveryDate} = invoiceable;
  const deliveryValue = deliveryDate ? formatDateMonthDayYear(deliveryDate) : '--';
  const receivedValue = createdDate ? formatDateMonthDayYear(createdDate) : '--';
  const dueValue = dueDate ? formatDateMonthDayYear(dueDate) : '--';
  return (
    <TwoColumnLayoutTableContainer>
      <TwoColumnLayoutTable
        label="Del"
        value={deliveryValue}
        wrapperClassName={classNames({'font-bold text-sw-warning': !deliveryDate})}
      />
      <TwoColumnLayoutTable label="Rec" value={receivedValue} />
      <TwoColumnLayoutTable label="Due" value={dueValue} />
    </TwoColumnLayoutTableContainer>
  );
};

export const InvoiceNumberCell = ({invoice}: {invoice: SettlementsDashboardRow}) => {
  const subStatusDescriptions = useGetSubStatusDescriptions(invoice.sub_statuses);
  const ariaDescription = getSubStatusesAriaDescription(subStatusDescriptions);
  const source = getInvoiceSourceValue(invoice);

  return (
    <div className="flex items-center">
      {subStatusDescriptions?.length ? (
        <Tooltip
          portal
          placement="top"
          tooltipContent={
            <div className="flex flex-col">
              <b>{`Exceptions (${subStatusDescriptions?.length})`}</b>
              <ul className="list-disc pl-5">
                {subStatusDescriptions.map((description) =>
                  description ? <li key={description}>{description}</li> : null
                )}
              </ul>
            </div>
          }
        >
          <SvgIcon aria-description={ariaDescription} name="ErrorOutlined" color="sw-warning" className="mr-1" />
        </Tooltip>
      ) : null}
      <Tooltip
        portal
        placement="top"
        tooltipContent={
          <div className="flex flex-col">
            <b>Source</b>
            {source}
          </div>
        }
      >
        {invoice.invoice_number || '--'}
      </Tooltip>
    </div>
  );
};

export const MissingDocumentsCell = ({subStatuses}: {subStatuses?: SettlementsDashboardSubStatus[]}) => {
  const {data: shortNamesMap, isLoading: isShortNamesLoading} = useDocumentShortNames();
  if (isShortNamesLoading) return null;
  if (!shortNamesMap) return <>--</>;

  const missingDocSubStatuses = subStatuses?.filter((subStatus) => subStatus.sub_status === 'MISSING_DOC');
  const missingDocShortNames = missingDocSubStatuses
    ?.map((substatus) => shortNamesMap?.[substatus.description])
    .filter(isDefined);
  const docs = [...new Set(missingDocShortNames)];

  return docs.length > 0 ? <span className="font-bold text-sw-warning">{docs.join(', ')}</span> : <span>--</span>;
};

export const DocsCell = ({
  invoiceId,
  documentCount
}: {
  invoiceId: SettlementsDashboardRow['id'];
  documentCount: number | undefined;
}) => {
  const [showModal, toggleModal] = useToggle();
  const [isDocumentsQueryEnabled, setIsDocumentsQueryEnabled] = useState(false);
  const {
    queryInfo: {data: invoiceDocuments, isLoading: isLoadingInvoiceDocuments},
    prefetch: prefetchInvoiceDocuments
  } = useGetFreightInvoiceDocuments(invoiceId, {
    enabled: isDocumentsQueryEnabled,
    staleTime: HOT_DATA_STALE_TIME
  });
  // without prefetching the document types, sometimes the types in the DocumentSelect would flicker (e.g., from "undefined" to "Invoice")
  const prefetchDocumentTypes = usePrefetchDocumentTypes();

  return (
    <>
      <SettlementDocumentsModal
        documents={invoiceDocuments}
        isLoading={isLoadingInvoiceDocuments}
        show={showModal}
        onClose={() => {
          setIsDocumentsQueryEnabled(false);
          toggleModal();
        }}
        invoiceId={invoiceId}
      />
      <Tooltip tooltipContent={documentCount ? pluralize('Document', documentCount, true) : 'No Documents'}>
        <button
          disabled={!documentCount}
          onClick={() => {
            setIsDocumentsQueryEnabled(true);
            toggleModal();
          }}
          onMouseEnter={() => {
            void prefetchDocumentTypes();
            void prefetchInvoiceDocuments();
          }}
          className={`h-6 ${documentCount ? '' : 'cursor-auto'}`}
        >
          <SvgIcon name="Clipboard" color={documentCount ? 'sw-icon' : 'sw-disabled'} />
        </button>
      </Tooltip>
    </>
  );
};

export const InvoicedCell = ({
  invoiceAmount,
  shipmentTotal,
  chargeLineItems
}: {
  invoiceAmount: SettlementsDashboardAmount;
  shipmentTotal: number;
  chargeLineItems:
    | Pick<SettlementsDashboardInvoiceableChargeLineItem, 'id' | 'category' | 'total_amount'>[]
    | undefined;
}) => (
  <div className="flex justify-end">
    <InvoiceFinancialsTooltip
      invoiceTotal={+invoiceAmount.value}
      shipmentTotal={shipmentTotal}
      currency={invoiceAmount.currency}
      chargeLineItems={chargeLineItems}
    >
      <div className="flex justify-end whitespace-nowrap">
        {formatCurrency(invoiceAmount.value, invoiceAmount.currency)}
      </div>
    </InvoiceFinancialsTooltip>
  </div>
);

export const StatusCell = ({
  status,
  subStatuses
}: {
  status: FreightInvoiceStatus;
  subStatuses: SettlementsDashboardSubStatus[];
}) => {
  const subStatusDescriptions = useGetSubStatusDescriptions(subStatuses);
  const ariaDescription = getSubStatusesAriaDescription(subStatusDescriptions);

  return (
    <div className="flex items-center gap-1">
      {capitalize(StatusData[status].label ?? status ?? '')}
      {subStatusDescriptions.length ? (
        <Tooltip
          portal
          placement="top"
          tooltipContent={
            <div className="flex flex-col">
              <div className="font-bold">Exceptions ({subStatusDescriptions.length})</div>
              <ul className="list-disc pl-5">
                {subStatusDescriptions.map((description) =>
                  description ? <li key={description}>{description}</li> : null
                )}
              </ul>
            </div>
          }
        >
          <SvgIcon aria-description={ariaDescription} name="ErrorOutlined" color="sw-warning" className="mr-1" />
        </Tooltip>
      ) : null}
    </div>
  );
};

export const CommunicationsCell = ({
  shipmentId,
  invoiceId,
  documentsCount
}: {
  shipmentId: string;
  invoiceId?: string;
  documentsCount?: number;
}) => {
  const [shipmentQueryEnabled, toggleShipmentQueryEnabled] = useToggle();
  const {queryInfo: legacyShipmentQueryInfo, prefetch: prefetchShipment} = useGetFullShipmentDetails(shipmentId, {
    enabled: shipmentQueryEnabled,
    staleTime: HOT_DATA_STALE_TIME
  });
  const isV3 = legacyShipmentQueryInfo.data?.version === V3_VERSION;
  return (
    <div className="flex gap-2">
      {invoiceId ? <DocsCell invoiceId={invoiceId} documentCount={documentsCount} /> : null}
      <ActionLinkWithModal
        disabled={isV3}
        tooltip="Messages"
        modalTitle="Messages"
        modalContent={
          legacyShipmentQueryInfo.isLoading ? (
            <Loader loading />
          ) : (
            <Messages shipmentResource={legacyShipmentQueryInfo.data} resourceType="SHIPMENT" />
          )
        }
        onClick={() => toggleShipmentQueryEnabled()}
        onClose={() => toggleShipmentQueryEnabled()}
      >
        <button onMouseEnter={() => void prefetchShipment()}>
          <SvgIcon name="Messages" color="sw-icon" />
        </button>
      </ActionLinkWithModal>
      <ActionLinkWithModal
        disabled={isV3}
        tooltip="Internal Notes"
        modalTitle="Internal Notes"
        modalContent={
          legacyShipmentQueryInfo.isLoading ? (
            <Loader loading />
          ) : (
            <ShipmentNotes shipment={legacyShipmentQueryInfo.data} resourceType="SHIPMENT" />
          )
        }
        onClick={() => toggleShipmentQueryEnabled()}
        onClose={() => toggleShipmentQueryEnabled()}
      >
        <button
          onMouseEnter={() => {
            void prefetchShipment();
          }}
        >
          <SvgIcon name="DocumentBlank" color="sw-icon" />
        </button>
      </ActionLinkWithModal>
    </div>
  );
};
export const ShipmentTotalCell = ({
  shipmentTotal = 0,
  invoiceTotal,
  currency,
  chargeLineItems
}: {
  shipmentTotal: number | undefined;
  invoiceTotal: number;
  currency?: CurrencyCode;
  chargeLineItems:
    | Pick<SettlementsDashboardInvoiceableChargeLineItem, 'id' | 'category' | 'total_amount'>[]
    | undefined;
}) => (
  <div className="flex justify-end">
    <InvoiceFinancialsTooltip
      invoiceTotal={invoiceTotal}
      shipmentTotal={shipmentTotal}
      currency={currency}
      chargeLineItems={chargeLineItems}
    >
      <div className="flex items-center justify-end gap-2 whitespace-nowrap">{toCurrency(shipmentTotal, currency)}</div>
    </InvoiceFinancialsTooltip>
  </div>
);

export const TotalPassedCell = ({amount}: {amount?: SettlementsDashboardAmount}) => (
  <div className="flex items-center justify-end gap-2 whitespace-nowrap">
    {amount ? toCurrency(parseFloat(amount.value), amount.currency) : '--'}
  </div>
);

export const DifferenceCellBase = ({
  isCarrier = false,
  invoiceTotal,
  shipmentTotal = 0,
  currency,
  subStatuses
}: {
  isCarrier: boolean;
  invoiceTotal: number;
  shipmentTotal: number | undefined;
  currency: CurrencyCode;
  subStatuses: SettlementsDashboardSubStatus[];
}) => {
  const difference = Math.abs(invoiceTotal - shipmentTotal);
  const differencePercent = getFreightInvoiceExpectedActualPercentageChange(invoiceTotal, shipmentTotal);
  const hasWrongAmountSubStatus = (subStatuses || []).some(
    (subStatus) => subStatus.sub_status === FreightInvoiceSubStatusType.WrongAmount
  );

  return (
    <div className="flex items-center justify-end gap-2 whitespace-nowrap">
      {toCurrency(difference, currency)}
      <div className="w-[40px]">
        {difference ? (
          <FreightInvoicePercentPill
            isCarrier={isCarrier}
            hasWrongAmountSubStatus={hasWrongAmountSubStatus}
            percentChange={differencePercent}
          />
        ) : null}
      </div>
    </div>
  );
};

export const DifferenceCell = connect((state: State) => {
  const {one} = state.shipmentdetails as {one: Shipment};
  return {
    isCarrier: state.auth.company.id === one?.current_carrier?.id
  };
})(DifferenceCellBase);

export const CommonDateCell = ({date}: {date: string | undefined}) => {
  return <>{date ? formatDate(date) : '--'}</>;
};

export const CarrierCell = ({
  invoiceableServiceProviderName,
  subStatuses
}: {
  invoiceableServiceProviderName: SettlementsDashboardRow['invoiceable']['service_provider_name'];
  subStatuses: SettlementsDashboardSubStatus[];
}) => {
  const wrongPartyException = subStatuses.find(({sub_status}) => sub_status === 'WRONG_PARTY');
  return wrongPartyException ? (
    <Tooltip
      portal
      placement="top"
      tooltipContent={<span aria-label="Invoice Party">{wrongPartyException.description}</span>}
    >
      <span role="cell" className="font-bold text-sw-warning">
        {invoiceableServiceProviderName}
      </span>
    </Tooltip>
  ) : (
    <span role="cell">{invoiceableServiceProviderName}</span>
  );
};
