import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Formik, Form} from 'formik';
import {object} from 'yup';
import {useQuery} from '@tanstack/react-query';
import get from 'lodash/get';
import {Card} from '@shipwell/shipwell-ui';
import FuelSurchargeCriteriaFormFields from 'App/containers/userCompany/fuelSurcharges/formFields/criteria';
import FuelSurchargeSettingsFormFields from 'App/containers/userCompany/fuelSurcharges/formFields/settings';
import {fetchShipmentModes, fetchEquipmentTypes} from 'App/actions/_shipments';
import FormFooter from 'App/formComponents/formSections/formFooter';
import {updateFuelSurchargeTable} from 'App/api/rateTables';
import {getCarrierRelationshipsPromise} from 'App/api/carriers';
import {fetchBrokerShipperRelationshipsPromise} from 'App/api/brokers';
import {useCarrierDetails} from 'App/data-hooks';
import {defaultFormValues, carrierValidationSchemaFields} from 'App/containers/userCompany/fuelSurcharges/validation';
import {formatCarrierRelationships, makeCarrierOption} from 'App/containers/userCompany/fuelSurcharges/utils';
import {formatAddresses} from 'App/containers/workflows/utils';
import {PROPTYPE_ADDRESS} from 'App/utils/propTypeConstants';
import {BROKERAGES_QUERY_KEY} from 'App/data-hooks/queryKeys';

const validationSchema = object().shape(carrierValidationSchemaFields);

const FuelSurchargeEditCriteriaForm = ({dispatch, onCancel, onSubmit, onError, values, company}) => {
  const customerIds = get(values, 'customers', []);
  const carrierDetails = useCarrierDetails(get(values, 'carrier_relationships', []));

  useEffect(() => {
    dispatch(fetchShipmentModes());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchEquipmentTypes());
  }, [dispatch]);

  const handleSubmitFuelSurchargeEditCriteriaForm = async (values) => {
    const payload = {
      ...values,
      carrier_relationships: formatCarrierRelationships(values.carrier_relationships),
      customers: values.customers ? values.customers.map((customer) => customer?.id) : [],
      locations: formatAddresses(values.locations),
      miles_per_gallon: values.miles_per_gallon === '' ? null : values.miles_per_gallon
    };
    try {
      const response = await updateFuelSurchargeTable(values.id, payload);
      if (response.ok) {
        onSubmit(response);
      }
    } catch (error) {
      console.error('Error updating fuel surcharge table', error);
      onError(error);
    }
  };

  const fetchCarrierRelationships = async (q) => {
    try {
      const response = await getCarrierRelationshipsPromise({q});
      return get(response, 'body.results', []).map((carrier) => makeCarrierOption(carrier));
    } catch (error) {
      console.error('Error retrieving carrier relationships', error);
    }
  };

  const getCustomersQuery = useQuery(
    [BROKERAGES_QUERY_KEY, company?.brokerage?.id, customerIds],
    () => fetchBrokerShipperRelationshipsPromise(company.brokerage.id, {customerId: customerIds}),
    {
      enabled: company?.brokerage?.id && customerIds.length > 0
    }
  );
  const customers = getCustomersQuery?.data?.body?.results;

  const initialValues = {
    ...values,
    carrier_relationships: get(values, 'carrier_relationships', []).map((carrierId) =>
      makeCarrierOption(get(carrierDetails, carrierId, {}))
    ),
    customers: customers?.map((c) => ({id: c.company.id, label: get(c, 'company.name')}))
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        ...defaultFormValues,
        ...initialValues
      }}
      onSubmit={handleSubmitFuelSurchargeEditCriteriaForm}
    >
      {(formikProps) => (
        <Form noValidate>
          <Card title="Fuel Surcharge Criteria" className="mb-4">
            <FuelSurchargeCriteriaFormFields
              customerIds={customerIds}
              fetchCarrierRelationships={fetchCarrierRelationships}
            />
          </Card>
          <Card title="Settings" className="mb-4">
            <FuelSurchargeSettingsFormFields />
          </Card>
          {get(formikProps, 'dirty') && (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <FormFooter {...formikProps} onCancel={onCancel} />
          )}
        </Form>
      )}
    </Formik>
  );
};

FuelSurchargeEditCriteriaForm.propTypes = {
  dispatch: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  company: PropTypes.shape({
    brokerage: PropTypes.object
  }),
  values: PropTypes.shape({
    id: PropTypes.string,
    locations: PropTypes.object,
    carrier_relationships: PropTypes.arrayOf(PROPTYPE_ADDRESS),
    customers: PropTypes.arrayOf(PropTypes.string),
    miles_per_gallon: PropTypes.number
  })
};

FuelSurchargeEditCriteriaForm.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  onError: () => {},
  values: {
    id: null
  }
};

export default connect((state) => ({
  modes: state.shipments.shipmentModes,
  equipmentTypes: state.shipments.equipmentTypes,
  company: state.userCompany.company
}))(FuelSurchargeEditCriteriaForm);
