import {useState} from 'react';
import {connect} from 'react-redux';
import {Toast} from '@shipwell/shipwell-ui';
import {updateCompanyPreferencesAndSettings} from 'App/containers/userCompany/actions/async';
import CompanyProfileForm from 'App/formComponents/forms/companyProfile';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import './styles.scss';

/**
 * Company Settings
 * @param {*} props
 *
 * @todo Move Toasts to top level
 */
const CompanySettingContainer = (props) => {
  const {company, preferences, dispatch} = props;
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const isLoadingSettings = !preferences || !company.id;

  /**
   * Update company settings and preferences
   * @param {*} values
   * @param {*} Formik props
   */
  const handleSave = async (values, {setSubmitting}) => {
    setSubmitting(true);
    //copy mailing address to billing address
    values.billing_address = values.mailing_address;

    try {
      await dispatch(updateCompanyPreferencesAndSettings(company.id, values));
      setShowSuccess(true);
    } catch (error) {
      setShowError(error.error_description);
    }
    setSubmitting(false);
  };

  return (
    <div className="company-settings-wrapper">
      {isLoadingSettings && <ShipwellLoader loading />}
      {!isLoadingSettings && <CompanyProfileForm edit values={{...company, ...preferences}} onSubmit={handleSave} />}
      <Toast show={showSuccess} title="Success!" anchor="bottom-right" onClose={() => setShowSuccess(false)}>
        Successfully updated.
      </Toast>
      <Toast show={showError} title="Error!" variant="error" anchor="bottom-right" onClose={() => setShowError(false)}>
        {showError}
      </Toast>
    </div>
  );
};

export default connect((state) => ({
  company: state.company.company,
  preferences: state.company.preferences
}))(CompanySettingContainer);
