import {statusOptions} from 'App/containers/Dashboard/filters/StatusFilter';
import {ReadbackContainer, ReadbackTag} from 'App/components/TypedTable/complementaryComponents';
import {
  initialFilterState,
  modeOptions,
  tenderStatusOptions,
  workflowOptions
} from 'App/containers/Dashboard/utils/DashboardUtils';
import {DateReadback, TagsReadback} from 'App/containers/Dashboard/readback';
import {toTitleCase} from 'App/utils/globals';
import {SOURCES} from 'App/utils/createdBy';

// temp array to show users other filters are selected but not shown in the UI
// To be deleted once all the filters are covered in the Readback below
const coveredFilters: (keyof typeof initialFilterState)[] = [
  'alerts',
  'archived',
  'hasBill',
  'drayageContainerReturnDateLte',
  'drayageContainerReturnDateGte',
  'createdAtLte',
  'createdAtGte',
  'dropoffLte',
  'dropoffGte',
  'drayageEstimatedArrivalDateLte',
  'drayageEstimatedArrivalDateGte',
  'equipmentType',
  'hasInvoice',
  'drayageLastFreeDateLte',
  'drayageLastFreeDateGte',
  'loadBoardEnabled',
  'mode',
  'pickupLte',
  'pickupGte',
  'drayageReleaseDateLte',
  'drayageReleaseDateGte',
  'status',
  'statusExclude',
  'parcelPickupStatus',
  'open',
  'tags',
  'tagsExclude',
  'tenderStatus',
  'workflowExecutionStatus',
  'deliveryLat',
  'deliveryLon',
  'deliveryRadius',
  'deliveryStopsLabel',
  'deliveryStopsContains',
  'deliveryStopsStateProvince',
  'deliveryStopsCompanyNameContains',
  'pickupLat',
  'pickupLon',
  'pickupRadius',
  'pickupStopsLabel',
  'pickupStopsContains',
  'pickupStopsStateProvince',
  'pickupStopsCompanyNameContains',
  'drayageContainerNumberContains',
  'drayageSealNumberContains',
  'vendorId',
  'vendor',
  'reps',
  'repLabels',
  'customer',
  'customerId',
  'accessorials',
  'accessorialLabels',
  'serviceLevel',
  'serviceLevelLabels',
  'createdBy',
  'createdByUserId',
  'createdBySource',
  'shipmentShipwellCustomData'
];

export const DashboardReadback = ({
  label,
  filters,
  onClear,
  onFilterChange,
  onClearPickupAddress,
  onClearDeliveryAddress
}: {
  label?: string;
  filters: Partial<typeof initialFilterState>;
  onClear: () => void;
  onFilterChange: (keyName: keyof typeof initialFilterState, clearVal: string | never[]) => void;
  onClearPickupAddress: () => void;
  onClearDeliveryAddress: () => void;
}) => {
  const modes = filters.mode?.map((m) => modeOptions.find((option) => option.value === m)?.label) || [];
  const statuses =
    [
      ...new Set([
        ...(filters.status?.length ? filters.status : []),
        ...(filters.parcelPickupStatus?.length ? filters.parcelPickupStatus : []),
        ...(filters.statusExclude?.length ? filters.statusExclude : []),
        ...(filters.open?.length ? ['open'] : [])
      ])
    ]
      // not_scheduled is applied with "open", removing so it doesn't get rendered twice
      .filter((stat) => stat !== 'NOT_SCHEDULED')
      .map((stat) => statusOptions.find((option) => option.value === stat)?.label) || [];
  const tenderStatuses =
    filters.tenderStatus?.map((tender) => tenderStatusOptions.find((option) => option.value === tender)?.label) || [];
  const workflows =
    filters.workflowExecutionStatus?.map(
      (workflow) => workflowOptions.find((option) => option.value === workflow)?.label
    ) || [];

  // This can be removed once all filters are added to the UI
  const activeFilterKeys = Object.keys(filters).reduce((active: (keyof typeof initialFilterState)[], filterKey) => {
    const typedKey = filterKey as keyof typeof initialFilterState;
    const filter = filters[typedKey];
    if (Array.isArray(filter) && filter.length) {
      return [...active, typedKey];
    }
    if (typeof filter === 'string' && Boolean(filter)) {
      return [...active, typedKey];
    }
    return active;
  }, []);
  const filtersNotCoveredInReadback = activeFilterKeys.filter((filterKey) => !coveredFilters.includes(filterKey));

  return (
    <ReadbackContainer label={label} onClearAll={onClear}>
      {/* Accessorials */}
      {filters.accessorialLabels?.length ? (
        <ReadbackTag
          label={`Accessorials: ${filters.accessorialLabels.join(' | ')}`}
          onClick={() => {
            onFilterChange('accessorials', []);
            onFilterChange('accessorialLabels', []);
          }}
        />
      ) : null}
      {/* Alerts */}
      {filters.alerts ? <ReadbackTag label="Alerts: Exist" onClick={() => onFilterChange('alerts', '')} /> : null}
      {/* Archived */}
      {filters.archived?.length ? (
        <ReadbackTag label="Archived: True" onClick={() => onFilterChange('archived', [])} />
      ) : null}
      {/* Bill */}
      {filters.hasBill ? (
        <ReadbackTag
          label={`Bill: ${filters.hasBill === 'true' ? 'Exists' : 'Does Not Exist'}`}
          onClick={() => onFilterChange('hasBill', '')}
        />
      ) : null}
      {/* Carrier (Vendor) */}
      {filters.vendorId?.length || filters.vendor?.length ? (
        <ReadbackTag
          label={`Carriers: ${filters.vendor?.join(' | ') || ''}`}
          onClick={() => {
            onFilterChange('vendorId', []);
            onFilterChange('vendor', []);
          }}
        />
      ) : null}
      {/* Container Number */}
      {filters.drayageContainerNumberContains?.length ? (
        <ReadbackTag
          label={`Container #: ${filters.drayageContainerNumberContains.join(' | ')}`}
          onClick={() => onFilterChange('drayageContainerNumberContains', [])}
        />
      ) : null}
      {/* Container Return Date */}
      {filters.drayageContainerReturnDateLte || filters.drayageContainerReturnDateGte ? (
        <DateReadback
          start={filters.drayageContainerReturnDateGte || ''}
          stop={filters.drayageContainerReturnDateLte || ''}
          label="Container Return Date"
          onClear={() => {
            onFilterChange('drayageContainerReturnDateGte', '');
            onFilterChange('drayageContainerReturnDateLte', '');
          }}
        />
      ) : null}
      {/* Created */}
      {filters.createdAtLte || filters.createdAtGte ? (
        <DateReadback
          start={filters.createdAtGte || ''}
          stop={filters.createdAtLte || ''}
          label="Created"
          onClear={() => {
            onFilterChange('createdAtGte', '');
            onFilterChange('createdAtLte', '');
          }}
        />
      ) : null}
      {/* Created By */}
      {filters.createdBy?.length || filters.createdBySource?.length ? (
        <ReadbackTag
          label={`Created By: ${[
            ...(filters.createdBy || []),
            ...(filters.createdBySource?.map((sourceKey) => SOURCES[sourceKey]) || [])
          ]
            .sort((a, b) => a.localeCompare(b))
            .join(' | ')}`}
          onClick={() => {
            onFilterChange('createdBy', []);
            onFilterChange('createdByUserId', []);
          }}
        />
      ) : null}
      {/* Custom Data */}
      {filters.shipmentShipwellCustomData?.length ? (
        <ReadbackTag
          label={`Custom Data: ${filters.shipmentShipwellCustomData.join(' | ')}`}
          onClick={() => onFilterChange('shipmentShipwellCustomData', [])}
        />
      ) : null}
      {/* Customer */}
      {filters.customer?.length || filters.customerId?.length ? (
        <ReadbackTag
          label={`Customer: ${filters.customer?.join(' | ') || ''}`}
          onClick={() => {
            onFilterChange('customer', []);
            onFilterChange('customerId', []);
          }}
        />
      ) : null}
      {/* Delivery Address */}
      {filters.deliveryStopsContains?.length ||
      filters.deliveryStopsStateProvince?.length ||
      filters.deliveryStopsLabel ? (
        <ReadbackTag
          label={`Delivery Address: ${[
            ...(filters.deliveryStopsContains || []),
            ...(filters.deliveryStopsStateProvince || []),
            ...(filters.deliveryStopsLabel ? [filters.deliveryStopsLabel] : [])
          ].join(' | ')}`}
          onClick={() => {
            onClearDeliveryAddress();
            onFilterChange('deliveryStopsStateProvince', []);
            onFilterChange('deliveryStopsContains', []);
            onFilterChange('deliveryLat', '');
            onFilterChange('deliveryLon', '');
            onFilterChange('deliveryRadius', '');
            onFilterChange('deliveryStopsLabel', '');
          }}
        />
      ) : null}
      {/* Delivery Date */}
      {filters.dropoffLte || filters.dropoffGte ? (
        <DateReadback
          start={filters.dropoffGte || ''}
          stop={filters.dropoffLte || ''}
          label="Delivery Date"
          onClear={() => {
            onFilterChange('dropoffGte', '');
            onFilterChange('dropoffLte', '');
          }}
        />
      ) : null}
      {/* Delivery Company */}
      {filters.deliveryStopsCompanyNameContains?.length ? (
        <ReadbackTag
          label={`Delivery Company: ${filters.deliveryStopsCompanyNameContains.join(' | ')}`}
          onClick={() => onFilterChange('deliveryStopsCompanyNameContains', [])}
        />
      ) : null}
      {/* Estimated Container Arrival */}
      {filters.drayageEstimatedArrivalDateLte || filters.drayageEstimatedArrivalDateGte ? (
        <DateReadback
          start={filters.drayageEstimatedArrivalDateGte || ''}
          stop={filters.drayageEstimatedArrivalDateLte || ''}
          label="Estimated Container Arrival"
          onClear={() => {
            onFilterChange('drayageEstimatedArrivalDateGte', '');
            onFilterChange('drayageEstimatedArrivalDateLte', '');
          }}
        />
      ) : null}
      {/* Equipment */}
      {filters.equipmentType?.length ? (
        <ReadbackTag
          label={`Equipment: ${
            filters.equipmentType?.map((type) => toTitleCase(type).replaceAll('_', ' ')).join(' | ') || ''
          }`}
          onClick={() => onFilterChange('equipmentType', [])}
        />
      ) : null}
      {/* Invoice */}
      {filters.hasInvoice ? (
        <ReadbackTag
          label={`Invoice: ${filters.hasInvoice === 'true' ? 'Exists' : 'Does Not Exist'}`}
          onClick={() => onFilterChange('hasInvoice', '')}
        />
      ) : null}
      {/* Last Free Date */}
      {filters.drayageLastFreeDateLte || filters.drayageLastFreeDateGte ? (
        <DateReadback
          start={filters.drayageLastFreeDateGte || ''}
          stop={filters.drayageLastFreeDateLte || ''}
          label="Last Free Date"
          onClear={() => {
            onFilterChange('drayageLastFreeDateGte', '');
            onFilterChange('drayageLastFreeDateLte', '');
          }}
        />
      ) : null}
      {/* Load Board */}
      {filters.loadBoardEnabled ? (
        <ReadbackTag label="Load Board: On Load Board" onClick={() => onFilterChange('loadBoardEnabled', '')} />
      ) : null}
      {/* Mode */}
      {filters.mode?.length ? (
        <ReadbackTag label={`Mode: ${modes.join(', ')}`} onClick={() => onFilterChange('mode', [])} />
      ) : null}
      {/* Pickup Address */}
      {filters.pickupStopsContains?.length || filters.pickupStopsStateProvince?.length || filters.pickupStopsLabel ? (
        <ReadbackTag
          label={`Pickup Address: ${[
            ...(filters.pickupStopsContains || []),
            ...(filters.pickupStopsStateProvince || []),
            ...(filters.pickupStopsLabel ? [filters.pickupStopsLabel] : [])
          ].join(' | ')}`}
          onClick={() => {
            onClearPickupAddress();
            onFilterChange('pickupStopsStateProvince', []);
            onFilterChange('pickupStopsContains', []);
            onFilterChange('pickupLat', '');
            onFilterChange('pickupLon', '');
            onFilterChange('pickupRadius', '');
            onFilterChange('pickupStopsLabel', '');
          }}
        />
      ) : null}
      {/* Pickup Company */}
      {filters.pickupStopsCompanyNameContains?.length ? (
        <ReadbackTag
          label={`Pickup Company: ${filters.pickupStopsCompanyNameContains.join(' | ')}`}
          onClick={() => onFilterChange('pickupStopsCompanyNameContains', [])}
        />
      ) : null}
      {/* Pickup Date */}
      {filters.pickupLte || filters.pickupGte ? (
        <DateReadback
          start={filters.pickupGte || ''}
          stop={filters.pickupLte || ''}
          label="Pickup Date"
          onClear={() => {
            onFilterChange('pickupGte', '');
            onFilterChange('pickupLte', '');
          }}
        />
      ) : null}
      {/* Release Date */}
      {filters.drayageReleaseDateLte || filters.drayageReleaseDateGte ? (
        <DateReadback
          start={filters.drayageReleaseDateGte || ''}
          stop={filters.drayageReleaseDateLte || ''}
          label="Release Date"
          onClear={() => {
            onFilterChange('drayageReleaseDateGte', '');
            onFilterChange('drayageReleaseDateLte', '');
          }}
        />
      ) : null}
      {/* Reps */}
      {filters.repLabels?.length ? (
        <ReadbackTag
          label={`Reps: ${filters.repLabels?.join(' | ') || ''}`}
          onClick={() => {
            onFilterChange('reps', []);
            onFilterChange('repLabels', []);
          }}
        />
      ) : null}
      {/* Seal Number */}
      {filters.drayageSealNumberContains?.length ? (
        <ReadbackTag
          label={`Seal #: ${filters.drayageSealNumberContains.join(' | ')}`}
          onClick={() => onFilterChange('drayageSealNumberContains', [])}
        />
      ) : null}
      {/* Service Level */}
      {filters.serviceLevelLabels?.length ? (
        <ReadbackTag
          label={`Service Levels: ${filters.serviceLevelLabels.join(' | ')}`}
          onClick={() => {
            onFilterChange('serviceLevel', []);
            onFilterChange('serviceLevelLabels', []);
          }}
        />
      ) : null}
      {/* Status */}
      {filters.status?.length || filters.parcelPickupStatus?.length || filters.statusExclude?.length || filters.open ? (
        <ReadbackTag
          label={`Status (${
            filters.statusExclude?.length || filters.open === 'false' ? 'Exclude' : 'Or'
          }): ${statuses.join(', ')}`}
          onClick={() => {
            onFilterChange('status', []);
            onFilterChange('parcelPickupStatus', []);
            onFilterChange('statusExclude', []);
            onFilterChange('open', '');
          }}
        />
      ) : null}
      {/* Tags */}
      {filters.tags?.length ? (
        <TagsReadback label="Tags (Or)" onClear={() => onFilterChange('tags', [])} tags={filters.tags} />
      ) : null}
      {filters.tagsExclude?.length ? (
        <TagsReadback
          label="Tags (Exclude)"
          onClear={() => onFilterChange('tagsExclude', [])}
          tags={filters.tagsExclude}
        />
      ) : null}
      {/* Tender Status */}
      {filters.tenderStatus?.length ? (
        <ReadbackTag
          label={`Tender Status: ${tenderStatuses?.join(', ')}`}
          onClick={() => onFilterChange('tenderStatus', [])}
        />
      ) : null}
      {/* Workflows */}
      {filters.workflowExecutionStatus?.length ? (
        <ReadbackTag
          label={`Workflows: ${workflows.join(', ')}`}
          onClick={() => onFilterChange('workflowExecutionStatus', [])}
        />
      ) : null}
      {/* To be removed once all filters are added to the UI */}
      {filtersNotCoveredInReadback.length ? <ReadbackTag label="Additional filters applied" onClick={onClear} /> : null}
    </ReadbackContainer>
  );
};
