import classnames from 'classnames';
import {SvgIcon, SvgIconName} from '@shipwell/shipwell-ui';

const TimelineIcon = ({
  isLineHidden,
  iconToShow,
  iconColor,
  showFlag
}: {
  isLineHidden: boolean;
  iconToShow: SvgIconName;
  iconColor?: string;
  showFlag?: boolean;
}) => {
  return (
    <div className="mr-1 flex flex-col">
      <div>
        <SvgIcon name={iconToShow} color={iconColor ?? 'sw-primary'} />
        {showFlag ? <SvgIcon name="FlagFilled" color="sw-error" /> : null}
      </div>

      {isLineHidden ? null : (
        <div
          className={classnames('ml-[11px] h-full  w-[2px]', {
            'bg-sw-border-light': !iconColor,
            'bg-sw-disabled': iconColor
          })}
        />
      )}
    </div>
  );
};

export default TimelineIcon;
