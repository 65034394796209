import PropTypes from 'prop-types';
import {useState} from 'react';
import {Link} from 'react-router';
import defer from 'lodash/defer';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {SubmissionError} from 'redux-form';
import get from 'lodash/get';
import classnames from 'classnames';
import {
  SvgIcon,
  DeprecatedButton,
  Card,
  DisplayValue,
  CollapsibleCardContent,
  Modal,
  Dropdown,
  Toast,
  Title
} from '@shipwell/shipwell-ui';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import pluralize from 'pluralize';
// eslint-disable-next-line import/no-unresolved
import {useFlags} from 'launchdarkly-react-client-sdk';
import {parseV3ApiError} from 'App/api/typedUtils';
import ShipmentLineItemsForm from 'App/formComponents/forms/ShipmentLineItemsForm';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {unpackErrors} from 'App/utils/globals';
import {fedexServiceOptions, upsServiceOptions, uspsServiceOptions} from 'App/utils/parcelConstants';
import {shipmentsShipmentIdPut} from 'App/actions/_shipmentDetails';
import {formatCurrency} from 'App/utils/internationalConstants';
import ShipmentItemTotals from 'App/containers/quotes/create/components/shipmentItemTotals';
import {trackSubmitLineItems, trackDeleteLineItems} from 'App/utils/analytics';
import withStatusToasts from 'App/components/withStatusToasts';
import {useCustomFields} from 'App/data-hooks';
import partition from 'App/utils/partition';
import {getCustomDataPath} from 'App/utils/customDataPath';
import './_shipment-lineitems.scss';
import {getOrderUrl} from 'App/containers/Shipment/utils/orderUtils';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const fedExServiceLevels = fedexServiceOptions.reduce((options, option) => {
  const {id, name} = option;
  return {...options, [id]: name};
}, {});

const upsServiceLevels = upsServiceOptions.reduce((options, option) => {
  const {id, name} = option;
  return {...options, [id]: name};
}, {});

const uspsServiceLevels = uspsServiceOptions.reduce((options, option) => {
  const {id, name} = option;
  return {...options, [id]: name};
}, {});

export const CustomFields = ({customFields = [], customData = {}}) => {
  const shipmentLineItemsCustomData = get(
    customData,
    getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentLineItem),
    {}
  );
  return partition(customFields, (v, i) => i % 2 === 0).map((column, index) =>
    column.map(({id, label}) => (
      <div className={classnames(`lineitem-col-${index + 1}`)} key={id}>
        <DisplayValue label={label}>{get(shipmentLineItemsCustomData, id) || '--'}</DisplayValue>
      </div>
    ))
  );
};

export const getSelectedPackageType = (type, providerSpecificPackaging, packageTypes) => {
  providerSpecificPackaging = providerSpecificPackaging
    ? providerSpecificPackaging
        .replace(/_/g, ' ')
        .replace('FEDEX', 'FedEx®')
        .toLowerCase()
        .split(' ')
        .map((word) => {
          const letters = [...word];
          letters[0] = letters[0].toUpperCase();
          return letters.join('');
        })
        .join(' ')
    : '';

  if (providerSpecificPackaging) {
    return providerSpecificPackaging;
  }
  if (packageTypes) {
    const pkg = packageTypes.find((item) => item.code === type);
    if (pkg) {
      return pkg.description;
    }
    return '--';
  }
  return '--';
};

export const ShipmentLineItems = ({
  fetchDetails,
  shipmentId,
  dispatch,
  one: shipment,
  packageTypes,
  user,
  lineitems,
  is_quoting_limited_user: isQuotingLimitedUser,
  unitPreferences,
  setError
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalState, setModalState] = useState({
    mode: 'add',
    index: null
  });

  const [lineItemIdToDelete, setLineItemIdToDelete] = useState(null);
  const [deletingLineItem, setDeletingLineItem] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {customFields} = useCustomFields(CustomFieldEntityTypesEnum.ShipmentLineItem, shipmentId);
  const [showLineItemDeleteModal, setShowLineItemDeleteModal] = useState(false);
  const [lineItemDeleteSuccess, setLineItemDeleteSuccess] = useState(false);
  const [saveProductSuccess, setSaveProductSuccess] = useState(false);

  const updated = () => {
    setShowEditModal(false);
    setLineItemIdToDelete(null);
    defer(() => fetchDetails(shipment.id));
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    trackSubmitLineItems();
    const payload = {...values};
    if (!payload) {
      return;
    }
    if (payload.ftl_estimated_weight && payload.ftl_description) {
      payload.line_items = [];
      payload.line_items.push({
        description: payload.ftl_description,
        package_weight: payload.ftl_estimated_weight
      });
    }
    const response = await dispatch(
      shipmentsShipmentIdPut(shipmentId, {
        metadata: payload.metadata,
        line_items: payload.line_items,
        total_weight_override: payload.total_weight_override,
        total_declared_value: payload.total_declared_value,
        total_quantity_override: payload.total_quantity_override,
        carrier_reference_code: payload.carrier_reference_code
      })
    );
    if (response.status === 200) {
      setSaveProductSuccess(true);
      setIsSubmitting(false);
      updated();
      return response;
    }
    const error = parseV3ApiError(response);
    setError('Error!', error.detail);
    setIsSubmitting(false);
  };

  const handleDeleteLineItem = async (e) => {
    e.target.disabled = true;
    try {
      trackDeleteLineItems();
      setDeletingLineItem(true);
      const response = await dispatch(
        shipmentsShipmentIdPut(shipmentId, {
          ...shipment,
          line_items: shipment.line_items.filter((lineItem) => lineItem.id !== lineItemIdToDelete)
        })
      );

      if (response.status === 200) {
        updated();
        setShowLineItemDeleteModal(false);
        setLineItemDeleteSuccess(true);
        setDeletingLineItem(false);
        setLineItemIdToDelete(null);
        return response;
      }
    } catch (errors) {
      let submissionError = {};
      submissionError = unpackErrors(errors, submissionError);
      submissionError._error = errors.error_description;
      throw new SubmissionError(submissionError);
    }
  };

  const handleCancelEdit = () => {
    setShowEditModal(false);
    setLineItemIdToDelete(null);
  };

  const perms = user ? user.permissions : [];
  const canEdit =
    perms &&
    (perms.includes(UPDATE_SHIPMENTS_USER_PERMISSION) || perms.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION)) &&
    !isQuotingLimitedUser
      ? true
      : false;

  const totalWeight = shipment?.total_weight_override;
  const totalValue = shipment?.total_declared_value;
  const valueCurrency = shipment?.total_declared_value_currency;
  const totalQuantity = shipment?.total_quantity_override;
  const {decimalSupportForShipmentLineItems} = useFlags();

  if (!lineitems) {
    return null;
  }

  const hasFTL = shipment?.mode?.id === 1;

  const renderHazmatContent = (item) => {
    if (!item.is_hazmat) {
      return 'None';
    }

    const hazmatFields = [
      item.hazmat_identification_number,
      item.hazmat_hazard_class,
      item.hazmat_proper_shipping_name,
      item.hazmat_packing_group
    ];

    const hazmatValues = hazmatFields.filter(Boolean).join(' - ');

    if (item.is_hazmat && !hazmatValues.length) {
      return 'X';
    }

    return hazmatValues;
  };

  return (
    <div className="shipment__lineitems p-2">
      <Card title="Shipping Items" isCollapsible>
        <CollapsibleCardContent>
          {lineitems.map((item, i) => {
            return (
              <div key={`Item ${i + 1}`} className="mb-4 w-full">
                <Card
                  title={
                    <Title variant="CardTitle" className="flex pl-2 font-medium">
                      {`Item ${i + 1} ${item.description ? ` - ${item.description.replace(/(.{25})..+/, '$1…')}` : ''}`}
                      {item.related_orders ? (
                        <span className="mx-1" key={i}>
                          Order{item.related_orders?.length > 1 ? 's' : ''}
                        </span>
                      ) : null}
                      {item.related_orders?.map((order, i) => (
                        <span key={i} className="mr-1">
                          <Link to={getOrderUrl(order.self_link, order.order_id)}>#{order.order_number}</Link>
                        </span>
                      ))}
                    </Title>
                  }
                  actions={
                    canEdit && (
                      <Dropdown
                        icon={<SvgIcon name="Overflow" className="icon" color="$sw-icon" />}
                        variant="activeIcon"
                        indicator={false}
                      >
                        {() => (
                          <>
                            <li
                              onClick={() => {
                                setShowEditModal(true);
                                setModalState({
                                  mode: 'edit',
                                  index: i
                                });
                              }}
                            >
                              Edit
                            </li>
                            <li
                              onClick={() => {
                                setLineItemIdToDelete(item.id);
                                setShowLineItemDeleteModal(true);
                              }}
                            >
                              Delete
                            </li>
                          </>
                        )}
                      </Dropdown>
                    )
                  }
                  isCollapsible
                >
                  <CollapsibleCardContent>
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
                      <div>
                        <DisplayValue label="Description">{item.description}</DisplayValue>

                        {decimalSupportForShipmentLineItems ? (
                          <DisplayValue label="Quantity">
                            {item.total_packages ? parseFloat(item.total_packages).toFixed(2) : '--'}
                          </DisplayValue>
                        ) : (
                          <DisplayValue label="Quantity">
                            {item.total_packages ? item.total_packages : '--'}
                          </DisplayValue>
                        )}

                        <DisplayValue label="Packaging">
                          {getSelectedPackageType(item.package_type, item.provider_specific_packaging, packageTypes)}
                        </DisplayValue>

                        <DisplayValue label="Package Weight">
                          {`${
                            parseFloat(item.package_weight).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }) || ''
                          }  ${item.weight_unit || ''}`}
                        </DisplayValue>

                        <DisplayValue label="Refrigeration">
                          {item.refrigeration_required
                            ? item.refrigeration_min_temp && item.refrigeration_max_temp
                              ? `${item.refrigeration_min_temp} to ${item.refrigeration_max_temp}°${item.temp_unit}`
                              : item.refrigeration_min_temp
                              ? `${item.refrigeration_min_temp}°${item.temp_unit}`
                              : item.refrigeration_max_temp
                              ? `${item.refrigeration_max_temp}°${item.temp_unit}`
                              : 'Required'
                            : 'Not Required'}
                        </DisplayValue>
                      </div>

                      <div>
                        <DisplayValue label="Value per Piece">
                          {item.value_per_piece
                            ? formatCurrency(item.value_per_piece, item.value_per_piece_currency)
                            : '--'}
                        </DisplayValue>

                        <DisplayValue label="Dimensions">
                          {`
                            ${item.length || ''}
                            ${item.length ? 'x' : ''}
                            ${item.width || ''}
                            ${item.width ? 'x' : ''}
                            ${item.height || ''}
                            ${item.length_unit || ''}
                          `}
                        </DisplayValue>

                        <DisplayValue label="Freight Class">
                          {item.freight_class && item.freight_class !== 'select' ? item.freight_class : '--'}
                        </DisplayValue>

                        <DisplayValue label="Country of Manufacture">
                          {item.country_of_manufacture || '--'}
                        </DisplayValue>
                      </div>

                      <div>
                        <DisplayValue label="Total Weight">
                          {`${
                            item.package_weight && item.total_packages
                              ? parseFloat(item.package_weight * item.total_packages).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })
                              : ''
                          }  ${item.weight_unit || ''}`}
                        </DisplayValue>

                        <DisplayValue label="Number of pieces">
                          {item.total_pieces ? item.total_pieces : '--'}
                          {item.piece_type
                            ? ' ' +
                              pluralize(
                                getSelectedPackageType(item.piece_type, item.provider_specific_packaging, packageTypes),
                                item.total_pieces
                              )
                            : ''}
                        </DisplayValue>

                        {shipment && shipment.fedex_specific_options && (
                          <DisplayValue label="Shipping Method">
                            {fedExServiceLevels[shipment.fedex_specific_options.service_code] || '--'}
                          </DisplayValue>
                        )}
                        {shipment && shipment.ups_specific_options && (
                          <DisplayValue label="Shipping Method">
                            {upsServiceLevels[shipment.ups_specific_options.service_code] || '--'}
                          </DisplayValue>
                        )}
                        {shipment && shipment.usps_specific_options && (
                          <DisplayValue label="Shipping Method">
                            {uspsServiceLevels[shipment.usps_specific_options.service_code] || '--'}
                          </DisplayValue>
                        )}

                        <DisplayValue label="NMFC code">
                          {item.nmfc_item_code ? item.nmfc_item_code : '--'}
                          {item.nmfc_sub_code ? `-${item.nmfc_sub_code}` : ''}
                        </DisplayValue>

                        <DisplayValue label="Stackable">{item.stackable ? 'Yes' : 'No'}</DisplayValue>

                        <DisplayValue label="Hazmat">{renderHazmatContent(item)}</DisplayValue>
                      </div>
                      <CustomFields customFields={customFields} customData={item.custom_data} />
                    </div>
                  </CollapsibleCardContent>
                </Card>
              </div>
            );
          })}
          {lineitems.length > 0 && canEdit ? (
            <Card
              title={
                <DeprecatedButton
                  variant="tertiary"
                  icon={<SvgIcon name="AddCircleOutlined" />}
                  onClick={() => {
                    setShowEditModal(true);
                    setModalState({
                      mode: 'add',
                      index: null
                    });
                    window.__forceSmoothScrollPolyfill__ = true;
                  }}
                >
                  Add Item
                </DeprecatedButton>
              }
              className="shipment-line-items__form-card collapsed shipment-line-items__form-add mb-4 flex"
            />
          ) : (
            <Card title="All line items have been removed from current shipment"></Card>
          )}
          {lineitems.length > 0 ? (
            <ShipmentItemTotals
              unitPreferences={unitPreferences}
              lineItems={lineitems}
              totalWeight={totalWeight}
              totalValue={totalValue}
              totalQuantity={totalQuantity}
              valueCurrency={valueCurrency}
              hasFTL={hasFTL}
            />
          ) : null}
        </CollapsibleCardContent>
      </Card>
      {lineitems.length > 0 && canEdit && (
        <Modal
          show={showEditModal}
          title="Edit Line Items"
          footerComponent={null}
          size="large"
          onClose={() => setShowEditModal(false)}
        >
          <ShipmentLineItemsForm
            shipmentDetails={shipment}
            lineItems={lineitems}
            totalWeight={totalWeight}
            totalValue={totalValue}
            totalQuantity={totalQuantity}
            valueCurrency={valueCurrency}
            hasFTL={hasFTL}
            modalState={modalState}
            onCancel={handleCancelEdit}
            onSubmit={handleSubmit}
            submitDisabled={isSubmitting || deletingLineItem}
          />
        </Modal>
      )}

      <Modal title="Delete Line item?" show={showLineItemDeleteModal} onClose={() => setShowLineItemDeleteModal(false)}>
        <Title variant="CardTitle">
          Are you sure you want to delete {lineitems.find((item) => item.id === lineItemIdToDelete)?.description}?
        </Title>
        <div className="mt-2">
          If this is the only line item on one of the orders associated with this shipment, the line item cannot be
          deleted.
        </div>
        <ModalFormFooter
          primaryActionName={
            lineitems?.length < 2 || lineitems.find((item) => item.id === lineItemIdToDelete)?.purchase_order
              ? 'This item cannot be deleted'
              : 'Delete'
          }
          primaryActionDisabled={
            lineitems?.length < 2 ||
            lineitems.find((item) => item.id === lineItemIdToDelete)?.purchase_order ||
            deletingLineItem
          }
          onPrimaryActionClick={handleDeleteLineItem}
          onCancel={() => setShowLineItemDeleteModal(false)}
        />
      </Modal>

      <Toast
        show={lineItemDeleteSuccess}
        variant="success"
        title="Success!"
        anchor="bottom-right"
        onClose={() => setLineItemDeleteSuccess(false)}
      >
        Line item deleted.
      </Toast>
      <Toast
        show={saveProductSuccess}
        variant="success"
        title="Success!"
        anchor="bottom-right"
        onClose={() => setSaveProductSuccess(false)}
      >
        Line item saved.
      </Toast>
    </div>
  );
};

ShipmentLineItems.propTypes = {
  setError: PropTypes.func,
  closePopover: PropTypes.func,
  company: PropTypes.shape({
    id: PropTypes.string
  }),
  dispatch: PropTypes.func,
  fetchDetails: PropTypes.func,
  is_quoting_limited_user: PropTypes.bool,
  lineitems: PropTypes.array,
  one: PropTypes.shape({
    fedex_specific_options: PropTypes.shape({
      service_code: PropTypes.number
    }),
    id: PropTypes.any,
    mode: PropTypes.shape({
      id: PropTypes.number
    }),
    total_declared_value: PropTypes.number,
    total_declared_value_currency: PropTypes.number,
    total_weight_override: PropTypes.number,
    total_quantity_override: PropTypes.number,
    ups_specific_options: PropTypes.shape({
      service_code: PropTypes.number
    }),
    usps_specific_options: PropTypes.shape({
      service_code: PropTypes.number
    }),
    line_items: PropTypes.array
  }),
  packageTypes: PropTypes.shape({
    find: PropTypes.func
  }),
  shipmentId: PropTypes.string,
  unitPreferences: PropTypes.object,
  user: PropTypes.shape({
    permissions: PropTypes.bool
  })
};

ShipmentLineItems.defaultProps = {
  dispatch: () => {}
};

export default compose(
  withStatusToasts,
  connect((state) => ({
    user: state.auth.user,
    company: state.auth.company,
    is_quoting_limited_user: state.auth.is_quoting_limited_user,
    shipmentLineItemsForm: state.form.shipmentLineItemsForm,
    one: state.shipmentdetails.one,
    unitPreferences: state.userCompany.unitPreferences
  }))
)(ShipmentLineItems);
