import PropTypes from 'prop-types';
import {useState, useEffect, useRef} from 'react';
import {
  ContractAdditionalStopChargesTypeEnum,
  ContractPerHourStartFieldEnum,
  ContractPerHourEndFieldEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Field, FieldArray, useFormikContext} from 'formik';
import {
  FormikTextInput,
  FormikSelect,
  FormikTextarea,
  FormikDateTimePicker,
  FormikCheckbox,
  Tooltip,
  Rule,
  Title,
  SvgIcon,
  Button,
  IconButton,
  FormikRadioGroup
} from '@shipwell/shipwell-ui';
import {ContractRateCurrencyEnum} from '@shipwell/backend-core-sdk';
import {RateTableOverview} from './RateTable/RateTableOverview';
import withFlags from 'App/utils/withFlags';
import MultiAddressSearch from 'App/formComponents/fields/multiAddressSearch';
import {getCarrierRelationshipsPromise} from 'App/api/carriers';
import {getSLAs, getSLADetails} from 'App/api/serviceLevelAgreements';
import {getCompanyDocuments} from 'App/api/company';
import ServiceLevelAgreementReadOnlyDetails from 'App/containers/serviceLevelAgreements/readOnlyDetails';
import ModesField from 'App/formComponents/formSections/modesField';
import EquipmentTypesField from 'App/formComponents/formSections/equipmentTypesField';
import FuelSurchargeField from 'App/formComponents/formSections/fuelSurchargeField';
import {
  RATE_TYPE_PER_MILE,
  RATE_TYPE_FLAT_RATE,
  RATE_TYPE_RATE_TABLE,
  RATE_TYPE_PER_HOUR
} from 'App/containers/userCompany/rateTables/constants';
import {FlexBox} from 'App/components/Box';
import {formatCurrencyValue} from 'App/utils/globals';
import {roundNumberToSpecificDecimals} from 'App/utils/mathHelpers';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import {reorder} from 'App/utils/dragAndDrop';
import {useCompanyPreferences, useTotalDistance} from 'App/data-hooks';
import {enumToOptionConverter} from 'App/utils/enumToOptionConverter';

export const FTL_VALUE = 'FTL';
export const DRAYAGE_VALUE = 'DRAYAGE';
export const INTERMODAL_VALUE = 'INTERMODAL';
export const LTL_VALUE = 'LTL';
const tooltips = {
  ADDITIONAL_STOP_CHARGE_PER_MILE: (
    <span>Additional stop rate per mile is used when you have additional stop charges.</span>
  ),
  SERVICE_LEVEL_AGREEMENT: (
    <span>
      Service Level Agreements are linked to contracts to communicate your service level requirements. Add SLAs in the
      SLA section to link them here.
    </span>
  ),
  DOCUMENTS: <span>Documents that have been uploaded to the Company &gt; Profile will display in this list.</span>,
  DISTANCE_SYSTEM: (
    <span>Your distance system and version is used to communicate the system you use to calculate mileage.</span>
  )
};

const threeDigitPostalCodeOptionalValues = ['state_province'];

// Fields shared across the two forms are abstracted out here
const OriginsField = ({disabled}) => {
  return (
    <Field
      searchAddressBook
      searchMaps
      searchStateProvince
      searchCountry
      searchThreeDigitPostalCode
      threeDigitPostalCodeOptionalValues={threeDigitPostalCodeOptionalValues}
      label="Origins"
      name="origins"
      allowCreate
      component={MultiAddressSearch}
      ignoreFieldWarnings={['postal_code']}
      required
      disabled={disabled}
    />
  );
};

const DestinationsField = ({disabled}) => {
  return (
    <Field
      searchAddressBook
      searchMaps
      searchStateProvince
      searchCountry
      searchThreeDigitPostalCode
      threeDigitPostalCodeOptionalValues={threeDigitPostalCodeOptionalValues}
      label="Destinations"
      name="destinations"
      allowCreate
      component={MultiAddressSearch}
      ignoreFieldWarnings={['postal_code']}
      required
      disabled={disabled}
    />
  );
};

const RateField = ({required, disabled}) => {
  return <Field required={required} label="Rate" name="rate" component={FormikTextInput} disabled={disabled} />;
};

const RateTypeField = ({name, required, disabled}) => {
  const {values} = useFormikContext();

  const isRateTable = values?.rate_type === RATE_TYPE_RATE_TABLE;

  return (
    <Field
      simpleValue
      clearable={false}
      options={
        isRateTable
          ? [{value: RATE_TYPE_RATE_TABLE, label: 'Rate Table'}]
          : [
              {value: RATE_TYPE_PER_MILE, label: 'Per Mile'},
              {value: RATE_TYPE_FLAT_RATE, label: 'Flat Rate'},
              {value: RATE_TYPE_PER_HOUR, label: 'Hourly Rate'}
            ]
      }
      label="Rate Type"
      component={FormikSelect}
      name={name || 'rate_type'}
      required={required}
      disabled={isRateTable || disabled}
    />
  );
};

const RateCurrencyField = () => {
  return (
    <Field
      simpleValue
      clearable={false}
      required
      label="Currency"
      name="rate_currency"
      component={FormikSelect}
      options={enumToOptionConverter(ContractRateCurrencyEnum, false)}
    />
  );
};

const MinimumRateField = ({disabled}) => (
  <Field
    required={false}
    label="Minimum Total Rate"
    name="minimum_rate"
    component={FormikTextInput}
    disabled={disabled}
  />
);

const FuelIncludedField = (props) => {
  return <Field label="Fuel Included" name="fuel_included" component={FormikCheckbox} {...props} />;
};

const DistanceSystemField = (props) => {
  return (
    <Field
      required={false}
      label="Distance System & Version"
      name="distance_estimation_system"
      component={FormikTextInput}
      {...props}
    />
  );
};

const AdditionalStopChargeField = (props) => {
  return (
    <Field
      required={false}
      label="Additional Stop Charge"
      name="additional_stop_charge_flat_fee"
      component={FormikTextInput}
      {...props}
    />
  );
};

export const ServiceLevelAgreementField = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getServiceLevelAgreements = async () => {
      try {
        const response = await getSLAs();
        setOptions(response.body.results);
      } catch (error) {
        console.error(error);
      }
    };

    getServiceLevelAgreements();
  }, []);

  return (
    <Field
      required={false}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      options={options}
      label="Service Level Agreement"
      name="service_level_agreement"
      component={FormikSelect}
      {...props}
    />
  );
};

export const NotesField = (props) => {
  return <Field label="Notes" name="notes" component={FormikTextarea} {...props} />;
};

const DocumentsField = ({documents, ...props}) => {
  return (
    <Field
      isMulti
      required={false}
      options={documents}
      getOptionLabel={(option) => option.description}
      getOptionValue={(option) => option.id}
      label="Documents"
      name="documents"
      component={FormikSelect}
      {...props}
    />
  );
};

/**
 * ContractDefaultsFields - component for use in Company Defaults page
 *
 */
let ContractDefaultsFields = ({company, setFieldValue, values, isSubmitting}) => {
  const [companyDocuments, setCompanyDocuments] = useState([]);

  /**
   * Get company documents
   * @param {*} companyId
   */

  const getCompanyDocumentsList = async (companyId) => {
    try {
      const response = await getCompanyDocuments(companyId, {pageSize: 50});

      if (response && response.body) {
        setCompanyDocuments(response.body.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getServiceLevelAgreementDetails = async (slaID) => {
    try {
      const response = await getSLADetails(slaID);
      setFieldValue('service_level_agreement', response.body);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (values.service_level_agreement && typeof values.service_level_agreement === 'string') {
      //this will fetch only on the initial load from the api
      getServiceLevelAgreementDetails(values.service_level_agreement);
    }
  }, [values, isSubmitting]);

  useEffect(() => {
    if (company.id) {
      getCompanyDocumentsList(company.id);
    }
  }, [company]);

  return (
    <div className="field-grid">
      <div className="grid-item-1-11">
        <DistanceSystemField name="distance_estimation_system" />
      </div>
      <div className="grid-item-12-12 contracts__form-tooltips-container">
        <Tooltip placement="left" tooltipClassname="contracts__form-tooltips" tooltipContent={tooltips.DISTANCE_SYSTEM}>
          <SvgIcon name="InfoOutlined" />
        </Tooltip>
      </div>
      <div className="grid-item-1-12">
        <ModesField name="modes" optionsWhiteList={[FTL_VALUE, DRAYAGE_VALUE, INTERMODAL_VALUE, LTL_VALUE]} />
      </div>
      <div className="grid-item-1-12">
        <EquipmentTypesField name="equipment_types" />
      </div>
      <div className="grid-item-1-12 grid-checkbox-field">
        <FuelIncludedField name="fuel_included" />
      </div>
      <div className="grid-item-1-12">
        <RateTypeField name="rate_type" />
      </div>

      <div className="grid-item-1-12 grid-checkbox-field">
        <AdditionalStopChargeField name="additional_stop_charge_flat_fee" />
      </div>

      <div className="grid-item-1-11">
        <ServiceLevelAgreementField name="service_level_agreement" />
      </div>
      <div className="grid-item-12-12 contracts__form-tooltips-container">
        <Tooltip
          placement="left"
          tooltipClassname="contracts__form-tooltips"
          tooltipContent={tooltips.SERVICE_LEVEL_AGREEMENT}
        >
          <SvgIcon name="InfoOutlined" />
        </Tooltip>
      </div>
      <div className="grid-item-1-12">
        <NotesField name="notes" />
      </div>
      <div className="grid-item-1-11">
        <DocumentsField documents={companyDocuments} name="documents" />
      </div>
      <div className="grid-item-12-12 contracts__form-tooltips-container">
        <Tooltip placement="left" tooltipClassname="contracts__form-tooltips" tooltipContent={tooltips.DOCUMENTS}>
          <SvgIcon name="InfoOutlined" />
        </Tooltip>
      </div>
      <div className="grid-item-1-11">
        <Field name="email" label="Email" component={FormikTextInput} />
      </div>
      <div className="grid-item-12-12 contracts__form-tooltips-container">
        <Tooltip
          placement="left"
          tooltipClassname="contracts__form-tooltips"
          tooltipContent="This email is used to notify someone when certain events occur with Contracts not associated with shipments or actions with a direct contact."
        >
          <SvgIcon name="InfoOutlined" />
        </Tooltip>
      </div>
    </div>
  );
};

ContractDefaultsFields = connect((state) => ({
  company: state.userCompany.company
}))(ContractDefaultsFields);
export {ContractDefaultsFields};

/**
 * Contract Fields - component for use in Contract Create/Edit pages
 * @param {*} props
 */
const ContractFields = ({
  values,
  lockCarrier,
  attachFscToCustomerContract,
  company,
  userPreferences,
  cloningContract,
  setFieldValue,
  hasContractLanes
}) => {
  const [companyDocuments, setCompanyDocuments] = useState([]);
  const {errors, touched} = useFormikContext();

  const inputRef = useRef();

  const getCarrierRelationships = async (input) => {
    try {
      const response = await getCarrierRelationshipsPromise({q: input});
      return response.body.results;
    } catch (error) {
      console.error(error);
    }
  };

  const getServiceLevelAgreementDetails = async (slaID) => {
    try {
      const response = await getSLADetails(slaID);
      setFieldValue('service_level_agreement', response.body);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Get company documents
   * @param {*} companyId
   */
  const getCompanyDocumentsList = async (companyId) => {
    try {
      const response = await getCompanyDocuments(companyId, {pageSize: 50});

      if (response && response.body) {
        setCompanyDocuments(response.body.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (company.id) {
      getCompanyDocumentsList(company.id);
    }
  }, [company]);

  useEffect(() => {
    if (values.service_level_agreement) {
      getServiceLevelAgreementDetails(values.service_level_agreement);
    }
  }, []);

  useEffect(() => {
    if (inputRef?.current && cloningContract) {
      inputRef.current.select();
    }
  }, [inputRef, cloningContract]);

  // ensure stops are sorted by order_index and not initial array index
  const additionalStops =
    values.additional_stops?.sort((a, b) => {
      if (a.order_index < b.order_index) {
        return -1;
      }
      if (a.order_index > b.order_index) {
        return 1;
      }
      return 0;
    }) || [];

  const isCustomStopCharge =
    values.additional_stop_charges_type === ContractAdditionalStopChargesTypeEnum.CustomStopCharges;
  const origin = values?.origins?.length ? values.origins[0] : undefined;
  const destination = values?.destinations?.length ? values.destinations[0] : undefined;
  const hasMultipleOriginsOrDestinations = [values?.origins?.length, values?.destinations?.length]?.some(
    (len) => len > 1
  );

  useEffect(() => {
    if (!hasMultipleOriginsOrDestinations) {
      return;
    }
    // if there are multiple origins or destinations, we should clear the additional stops
    setFieldValue('additional_stops', []);
  }, [hasMultipleOriginsOrDestinations]);

  // the useTotalDistance hook wants each "stop" to be formed as {address: Address}
  const locations = [origin, ...additionalStops, destination]?.map((location) => ({address: location}));
  // each stop should have an address
  const hasValidAdditionalStops = additionalStops?.every((stop) => 'address_1' in stop);
  const {totalDistance} = useTotalDistance({
    stops: locations,
    enabled: !hasMultipleOriginsOrDestinations && hasValidAdditionalStops
  });
  const getLineHaul = () => {
    const rate = values?.rate || 0;
    if (values.rate_type === RATE_TYPE_PER_MILE) {
      return rate * totalDistance;
    }
    return rate;
  };
  const getStopTotals = () => {
    if (isCustomStopCharge) {
      return additionalStops.reduce((total, stop) => (total += Number(stop?.additional_stop_charge) || 0), 0);
    }
    return values?.additional_stop_charge_flat_fee * (additionalStops?.length || 0);
  };

  const handleDropEnd = (result) => {
    const draggedFromIndex = result?.source?.index;
    const draggedToIndex = result?.destination?.index;
    if (draggedFromIndex === undefined || draggedToIndex === undefined || draggedFromIndex === draggedToIndex) {
      return;
    }
    // get stops in newly dragged order
    const newStopsOrder = reorder(values?.additional_stops, draggedFromIndex, draggedToIndex);
    // update stop's order_index based on newly dragged order
    const newStops = newStopsOrder.map((stop, i) => {
      stop.order_index = i;
      return stop;
    });
    // set formik state
    setFieldValue('additional_stops', newStops);
  };

  // All stops' order_indexes should be updated when a stop is removed from the array
  const handleRemoveAdditionalStop = (removedIndex) => {
    const newAdditionalStops = values.additional_stops
      ?.filter((stop) => stop.order_index !== removedIndex)
      .map((stop, i) => {
        stop.order_index = i;
        return stop;
      });
    setFieldValue('additional_stop', newAdditionalStops);
  };

  const showStopAddressInputError = (index) =>
    touched?.additional_stops?.[index] &&
    Object.keys(errors?.additional_stops?.[index] ?? {})?.filter((key) => key !== 'additional_stop_charge')?.length;

  const isRateTable = values?.rate_type === RATE_TYPE_RATE_TABLE;
  const isHourlyRate = values?.rate_type === RATE_TYPE_PER_HOUR;

  return (
    <>
      <div className="field-grid">
        <Field
          required
          label="Status"
          name="status"
          simpleValue
          clearable={false}
          component={FormikSelect}
          options={[
            {value: 'ACTIVE', label: 'Active'},
            {value: 'PAUSED', label: 'Paused'},
            {value: 'SUSPENDED', label: 'Suspended'},
            {value: 'EXPIRED', label: 'Expired'}
          ]}
        />
      </div>
      <Rule />
      <Title variant="sectionTitle">Details</Title>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-12">
          <Field
            ref={inputRef}
            required
            label="Contract Name"
            name="name"
            component={FormikTextInput}
            disabled={hasContractLanes}
          />
        </div>
        <div className="grid-item-1-12">
          <Field
            async
            required
            label="Carrier"
            name="carrier"
            disabled={lockCarrier || hasContractLanes}
            component={FormikSelect}
            loadOptions={getCarrierRelationships}
            getOptionLabel={(option) => option.shipwell_vendor && option.shipwell_vendor.name}
            getOptionValue={(option) => option.id}
          />
        </div>
        <div className="grid-item-1-12">
          <ModesField
            optionsWhiteList={[FTL_VALUE, DRAYAGE_VALUE, INTERMODAL_VALUE, LTL_VALUE]}
            disabled={hasContractLanes}
          />
        </div>
        <div className="grid-item-1-12">
          <EquipmentTypesField disabled={hasContractLanes} />
        </div>
        <div className="grid-item-1-6">
          <Field label="Start Date" name="start_date" showTimeSelect={false} component={FormikDateTimePicker} />
        </div>
        <div className="grid-item-7-12">
          <Field label="End Date" name="end_date" showTimeSelect={false} component={FormikDateTimePicker} />
        </div>
      </div>
      <Rule />

      <Title variant="sectionTitle">Rates</Title>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-4">
          <RateTypeField required disabled={hasContractLanes} />
        </div>
        <div className="grid-item-5-8">
          <RateCurrencyField userPreferences={userPreferences} />
        </div>
        {isRateTable ? (
          <>
            <div className="grid-item-9-12 mb-5">
              <RateTableOverview contract={values} />
            </div>
            <div className="grid-item-1-6">
              <MinimumRateField disabled={hasContractLanes} />
            </div>
          </>
        ) : (
          <div className="grid-item-9-12">
            <RateField required disabled={hasContractLanes} />
          </div>
        )}
        {isHourlyRate ? (
          <>
            <div className="grid-item-1-6">
              <Field
                name="calculate_per_hour_rate_automatically"
                component={FormikRadioGroup}
                options={[
                  {label: 'Auto Calculate Time', value: true},
                  {label: 'Manually Enter Time', value: false}
                ]}
                direction="row"
              />
            </div>
            {[true, 'true'].includes(values?.calculate_per_hour_rate_automatically) ? (
              <>
                <div className="grid-item-1-6">
                  <Field
                    label="Start Time"
                    name="per_hour_start_field"
                    component={FormikSelect}
                    options={[
                      {label: 'Pickup Arrival Time', value: ContractPerHourStartFieldEnum.PickupArrivalTime},
                      {label: 'Pickup Departure Time', value: ContractPerHourStartFieldEnum.PickupDepartureTime},
                      {label: 'Delivery Arrival Time', value: ContractPerHourStartFieldEnum.DeliveryArrivalTime}
                    ]}
                    simpleValue
                    required
                    clearable={false}
                  />
                </div>
                <div className="grid-item-7-12">
                  <Field
                    label="End Time"
                    name="per_hour_end_field"
                    component={FormikSelect}
                    options={[
                      {label: 'Pickup Departure Time', value: ContractPerHourEndFieldEnum.PickupDepartureTime},
                      {label: 'Delivery Arrival Time', value: ContractPerHourEndFieldEnum.DeliveryArrivalTime},
                      {label: 'Delivery Departure Time', value: ContractPerHourEndFieldEnum.DeliveryDepartureTime}
                    ]}
                    simpleValue
                    required
                    clearable={false}
                  />
                </div>
              </>
            ) : null}
          </>
        ) : null}
        <div className="grid-item-1-6">
          <FuelIncludedField disabled={hasContractLanes} />
        </div>
        {values.rate_type && values.rate_type === RATE_TYPE_PER_MILE ? (
          <div className="grid-item-7-12">
            <MinimumRateField disabled={hasContractLanes} />
          </div>
        ) : null}
        <div className="grid-item-1-6">
          <Field
            label="Add Additional Stop Charges"
            name="add_additional_stop_charges"
            component={FormikCheckbox}
            disabled={hasContractLanes}
          />
        </div>
        {values.add_additional_stop_charges ? (
          <>
            <div className="grid-item-7-12">
              <Field
                required
                simpleValue
                label="Charge Type"
                name="additional_stop_charges_type"
                component={FormikSelect}
                options={[
                  {label: 'Custom', value: ContractAdditionalStopChargesTypeEnum.CustomStopCharges},
                  {label: 'Charge Per Stop', value: ContractAdditionalStopChargesTypeEnum.ChargePerAdditionalStop}
                ]}
                clearable={false}
                disabled={hasContractLanes}
              />
            </div>
            {values.additional_stop_charges_type === ContractAdditionalStopChargesTypeEnum.ChargePerAdditionalStop ? (
              <>
                <div className="grid-item-1-6">
                  <span>Additional Charge Per Stop</span>
                </div>
                <div className="grid-item-7-12">
                  <Field
                    required
                    label="Charge Per Stop"
                    name="additional_stop_charge_flat_fee"
                    component={FormikTextInput}
                    disabled={hasContractLanes}
                  />
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
      <Rule />
      <Title variant="sectionTitle">Stops</Title>
      <div className="flex flex-col gap-4 pb-4">
        <OriginsField disabled={hasContractLanes} />
        <FieldArray name="additional_stops">
          {({push, remove}) => (
            <>
              {additionalStops?.length ? (
                <DragDropContext onDragEnd={handleDropEnd}>
                  <Droppable droppableId="additional-stops">
                    {(provided) => (
                      <div ref={provided.innerRef} className="flex flex-col gap-2">
                        {!hasMultipleOriginsOrDestinations &&
                          additionalStops.map((additionalStop, i) => (
                            <Draggable key={additionalStop.id || i} index={i} draggableId={additionalStop?.id}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...provided.draggableProps}
                                >
                                  <div
                                    className={
                                      isCustomStopCharge ? 'grid grid-cols-2 gap-2' : 'flex items-center gap-2'
                                    }
                                  >
                                    <FlexBox items="center" gap="s" grow={1}>
                                      <div
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...provided.dragHandleProps}
                                      >
                                        <SvgIcon height="20" name="Drag" color="sw-icon" />
                                      </div>
                                      <div className="flex w-full flex-col">
                                        <Field
                                          required
                                          label={`Stop ${i + 1}`}
                                          name={`additional_stops[${i}]`}
                                          component={AddressSearch}
                                          onAddressSelect={(address) => {
                                            // augment returned address with order and charge info
                                            setFieldValue(`additional_stops[${i}]`, {
                                              ...address,
                                              order_index: i,
                                              additional_stop_charge_currency:
                                                values?.additional_stop_charge_flat_fee_currency
                                            });
                                          }}
                                          disabled={hasContractLanes}
                                        />
                                        {showStopAddressInputError(i) ? (
                                          <span className="pl-2 text-xs text-sw-error">
                                            An exact address is required.
                                          </span>
                                        ) : null}
                                      </div>
                                    </FlexBox>
                                    <FlexBox items="center" gap="s">
                                      {isCustomStopCharge ? (
                                        <div className="w-full">
                                          <Field
                                            label="Stop Charge"
                                            name={`additional_stops[${i}].additional_stop_charge`}
                                            component={FormikTextInput}
                                            prepend={<SvgIcon name="DollarOutlined" />}
                                            disabled={hasContractLanes}
                                          />
                                        </div>
                                      ) : null}
                                      <IconButton
                                        iconName="TrashOutlined"
                                        aria-label="Remove stop"
                                        onClick={() => {
                                          remove(i);
                                          handleRemoveAdditionalStop(i);
                                        }}
                                      />
                                    </FlexBox>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : null}
              <DestinationsField disabled={hasContractLanes} />
              {!hasMultipleOriginsOrDestinations ? (
                <FlexBox justifyItems="start">
                  <Button
                    iconName="AddCircleOutlined"
                    variant="tertiary"
                    width="compact"
                    onClick={() => push({order_index: values.additional_stops?.length})}
                    disabled={hasContractLanes}
                  >
                    Add Stop
                  </Button>
                </FlexBox>
              ) : null}
            </>
          )}
        </FieldArray>
      </div>
      <Rule />

      {values.rate_type !== RATE_TYPE_RATE_TABLE ? (
        <div className="mb-4 rounded bg-sw-background p-4">
          <FlexBox gap="m">
            <SvgIcon name="DollarFilled" />
            <div className="w-full">
              <h4 className="m-0 pb-2 font-bold">Shipment Totals</h4>
              <FlexBox items="baseline" gap="s">
                <p>Estimated Total Distance</p>
                <div className="grow border-b border-dashed" />
                <span>
                  {hasMultipleOriginsOrDestinations ? 'N/A' : `${Math.round(totalDistance).toLocaleString()} Miles`}
                </span>
              </FlexBox>
              <FlexBox items="baseline" gap="s">
                <p>Estimated Total Line Haul</p>
                <div className="grow border-b border-dashed" />
                <span>
                  {hasMultipleOriginsOrDestinations
                    ? 'N/A'
                    : `${values?.additional_stop_charge_flat_fee_currency || ''} ${formatCurrencyValue(
                        roundNumberToSpecificDecimals(getLineHaul(), 2)
                      )}`}
                </span>
              </FlexBox>
              <FlexBox items="baseline" gap="s">
                <p>Estimated Stop Totals</p>
                <div className="grow border-b border-dashed" />
                <span>
                  {values?.additional_stop_charge_flat_fee_currency || ''}{' '}
                  {formatCurrencyValue(roundNumberToSpecificDecimals(getStopTotals(), 2))}
                </span>
              </FlexBox>
            </div>
          </FlexBox>
        </div>
      ) : null}

      <Rule />
      <Title variant="sectionTitle">Service Level Agreement</Title>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-11">
          <ServiceLevelAgreementField disabled={hasContractLanes} />
        </div>
        <div className="grid-item-12-12 contracts__form-tooltips-container">
          <Tooltip
            placement="left"
            tooltipClassname="contracts__form-tooltips"
            tooltipContent={tooltips.SERVICE_LEVEL_AGREEMENT}
          >
            <SvgIcon name="InfoOutlined" />
          </Tooltip>
        </div>
        {values.service_level_agreement && (
          <div className="grid-item-1-12">
            <ServiceLevelAgreementReadOnlyDetails sla={values.service_level_agreement} />
          </div>
        )}
        <div className="grid-item-1-12 grid-auto-field">
          <NotesField disabled={hasContractLanes} />
        </div>
      </div>
      {attachFscToCustomerContract ? (
        <>
          <Rule />
          <Title variant="sectionTitle">Fuel Surcharge</Title>
          <div className="field-grid grid-1-12">
            <div className="grid-item-1-11">
              <FuelSurchargeField disabled={hasContractLanes} />
            </div>
          </div>
        </>
      ) : null}
      <Rule />
      <Title variant="sectionTitle">Documents</Title>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-11">
          <DocumentsField documents={companyDocuments} disabled={hasContractLanes} />
        </div>
        <div className="grid-item-12-12 contracts__form-tooltips-container">
          <Tooltip placement="left" tooltipClassname="contracts__form-tooltips" tooltipContent={tooltips.DOCUMENTS}>
            <SvgIcon name="InfoOutlined" />
          </Tooltip>
        </div>
      </div>
      <Rule />
      <Title variant="sectionTitle">Notifications</Title>
      <div className="field-grid grid-1-12">
        <div className="grid-item-1-11">
          <Field name="email" label="Email" component={FormikTextInput} disabled={hasContractLanes} />
        </div>
        <div className="grid-item-12-12 contracts__form-tooltips-container">
          <Tooltip
            placement="left"
            tooltipClassname="contracts__form-tooltips"
            tooltipContent="This email is used to notify someone when certain events occur with Contracts not associated with shipments or actions with a direct contact."
          >
            <SvgIcon name="InfoOutlined" />
          </Tooltip>
        </div>
      </div>
    </>
  );
};

ContractFields.propTypes = {
  cloningContract: PropTypes.object,
  company: PropTypes.shape({
    id: PropTypes.string
  }),
  lockCarrier: PropTypes.boolean,
  setFieldValue: PropTypes.func,
  attachFscToCustomerContract: PropTypes.bool,
  values: PropTypes.shape({
    fuel_included: PropTypes.boolean,
    rate_type: PropTypes.string,
    service_level_agreement: PropTypes.object,
    fuel_surcharge_table: PropTypes.string,
    additional_stops: PropTypes.arrayOf(
      PropTypes.shape({
        address_1: PropTypes.string,
        address_2: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        created_at: PropTypes.string,
        formatted_address: PropTypes.string,
        id: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        phone_number: PropTypes.string,
        postal_code: PropTypes.string,
        state_province: PropTypes.string,
        timezone: PropTypes.string,
        updated_at: PropTypes.string,
        additional_stop_charge: PropTypes.number,
        additional_stop_charge_currency: PropTypes.string,
        order_index: PropTypes.number
      })
    ),
    calculate_per_hour_rate_automatically: PropTypes.bool
  }),
  hasContractLanes: PropTypes.bool
};

export default compose(
  connect((state) => ({
    company: state.userCompany.company
  })),
  withFlags('attachFscToCustomerContract')
)(ContractFields);
