import {Configuration, LocationsApi, Address, AddressBookEntry} from '@shipwell/backend-core-sdk';
import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

function createAddressBookApi() {
  return new LocationsApi(
    new Configuration({
      basePath,
      apiKey: getAccessToken
    })
  );
}

type AddressBookParameters = Parameters<typeof LocationsApi.prototype.addressBookGet>;
export type AddressBookNamedParameters = {
  page?: AddressBookParameters[0];
  pageSize?: AddressBookParameters[1];
  ordering?: AddressBookParameters[2];
  q?: AddressBookParameters[3];
};

export function getAddressBookAddresses(options?: AddressBookNamedParameters) {
  return createAddressBookApi().addressBookGet(options?.page, options?.pageSize, options?.ordering, options?.q);
}

export async function getAddressBookAddressById(addressBookId: string) {
  return createAddressBookApi().addressBookAddressBookIdGet(addressBookId);
}

export function updateAddressBookAddressById(addressBookParams: {
  addressBookId: string;
  addressBookEntry: AddressBookEntry;
}) {
  const {addressBookId, addressBookEntry} = addressBookParams;
  return createAddressBookApi().addressBookAddressBookIdPut(addressBookId, addressBookEntry);
}

export function createAddressBookAddress(addressBookEntry: AddressBookEntry) {
  return createAddressBookApi().addressBookPost(addressBookEntry);
}

export function deleteAddressBookAddressById(addressBookId: string) {
  return createAddressBookApi().addressBookAddressBookIdDelete(addressBookId);
}

export async function getDistanceBetweenStops({origin, destination}: {origin: Address; destination: Address}) {
  return createAddressBookApi().locationsDistancePost({origin, destination});
}

export async function createAddressBook(body: AddressBookEntry) {
  return createAddressBookApi().addressBookPost(body);
}

export function getLocationTypes() {
  return createAddressBookApi().locationTypesGet();
}
