import {useState, useEffect} from 'react';
import {Field, FieldArray, FormikErrors, FormikHelpers, useFormikContext} from 'formik';
import {getData as CountryList} from 'country-list';
import {
  FormikTextInput,
  FormikCheckbox,
  FormikSelect,
  Modal,
  Card,
  CollapsibleCardContent,
  SvgIcon,
  Button
} from '@shipwell/shipwell-ui';
import pluralize from 'pluralize';
import {
  CustomField,
  CustomFieldEntityTypesEnum,
  Hazmat,
  Product,
  ProductHazmatPackingGroupEnum,
  Shipment,
  ShipmentLineItem,
  ShipmentLineItemHazmatPackingGroupEnum,
  ShipmentLineItemLengthUnitEnum,
  ShipmentLineItemWeightUnitEnum,
  ShipwellError
} from '@shipwell/backend-core-singlerequestparam-sdk';
import isUndefined from 'lodash/isUndefined';
import {withRouter, WithRouterProps} from 'react-router';
import {useQueryClient} from '@tanstack/react-query';
import HazmatCodesFields from '../hazmatCodesFields';
import {
  LINE_ITEM_DELETE_ERROR_MESSAGE,
  LINE_ITEM_DELETE_SUCCESS_MESSAGE,
  PRODUCT_CREATE_SUCCESS_MESSAGE,
  PRODUCT_UPDATE_SUCCESS_MESSAGE,
  SHIPMENT_LINE_ITEM_ERROR_TITLE,
  SHIPMENT_LINE_ITEM_SUCCESS_TITLE
} from 'App/formComponents/formSections/LineItemFields/constants';
import {LineItemWarning, LineItemFormValues} from 'App/formComponents/formSections/LineItemFields/types';
import {getLineItemWarnings} from 'App/formComponents/formSections/LineItemFields/utils/warnings';
import {useProductFieldHelpers} from 'App/formComponents/formSections/LineItemFields/hooks/useProductFieldHelpers';
import MiniProductFieldsForm from 'App/formComponents/forms/miniProductFields';
import {useDeletePurchaseOrderLineItem} from 'App/data-hooks/purchaseOrders';
import ProviderPackageTypesField from 'App/formComponents/fields/providerPackageTypes';
import {calculateFreightClass, freightClasses, isWhiteLabel, removeCommasAndDollarSign} from 'App/utils/globals';
import {getCustomDataPath} from 'App/utils/customDataPath';
import DeleteWarningTooltip from 'App/components/DeleteWarningTooltip';
import {getErrorCount} from 'App/utils/formikUtilsTyped';
import './styles.scss';
import {useGetInternationalPreferences} from 'App/containers/internationalization/hooks/useGetInternationalPreferences';
import useUpdateShipment from 'App/api/shipment/useUpdateShipment';
import {useEditProduct, useCreateProduct, useGetPieceTypes, usePackageTypes, usePiecesChange} from 'App/data-hooks';
import {getPieceTypeReactSelectOptions} from 'App/data-hooks/mdm/useGetPieceTypes';
import {PRODUCTS_QUERY} from 'App/data-hooks/queryKeys';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {createDefaultLineItem} from 'App/formComponents/formSections/LineItemFields/utils/createDefaultLineItem';
import Loader from 'App/common/shipwellLoader';
import {ExcessiveLengthWarning} from 'App/formComponents/formSections/LineItemFields/components/ExcessiveLengthWarning';
import {FreightClassWarning} from 'App/formComponents/formSections/LineItemFields/components/FreightClassWarning';
import {getPackageTypesReactSelectOptions} from 'App/data-hooks/mdm/usePackageTypes';
import {UNIT_OPTIONS} from 'App/containers/orders/purchaseOrdersCorrogo/formFields/unitOptions';
import {isSingleSelectionList} from 'App/containers/orders/utils';

type LineItemFieldsProps = {
  defaultLineItemFieldValues: ReturnType<typeof createDefaultLineItem>;
  edit: boolean;
  entityType: CustomFieldEntityTypesEnum;
  customFields: CustomField[];
  hasLTL?: boolean;
  hasParcel?: boolean;
  hasDrayage?: boolean;
  slimForm?: boolean;
  externalForm?: boolean;
  parcelProvider?: Record<string, unknown>;
  fieldWarnings?: LineItemWarning[];
  expandedIndex?: number;
  shipmentDetails?: Shipment;
  isWeightRequired?: boolean;
  params?: WithRouterProps['params'];
  router?: WithRouterProps['router'];
  showAmountFields?: boolean;
} & WithStatusToastProps;

export const LineItemFields = WithStatusToasts(
  withRouter(
    ({
      defaultLineItemFieldValues,
      hasLTL,
      hasParcel,
      hasDrayage,
      slimForm,
      externalForm,
      parcelProvider,
      fieldWarnings: fieldWarningsProp,
      edit,
      expandedIndex,
      entityType,
      shipmentDetails,
      customFields,
      isWeightRequired,
      params,
      setSuccess,
      setError,
      showAmountFields
    }: LineItemFieldsProps) => {
      const queryClient = useQueryClient();
      const {data: internationalPreferences, isInitialLoading: isLoadingInternationalPreferences} =
        useGetInternationalPreferences();
      const {data: pieceTypes, isInitialLoading: isLoadingPieceTypes} = useGetPieceTypes();
      const {data: packageTypes, isInitialLoading: isLoadingPackageTypes} = usePackageTypes();
      const {mutate: createProduct} = useCreateProduct();
      const {mutate: editProduct} = useEditProduct();
      const {mutateAsync: deletePurchaseOrderLineItem} = useDeletePurchaseOrderLineItem(params?.purchase_order_id);
      const {mutate: updateShipment} = useUpdateShipment();
      const packageTypeOptions = getPackageTypesReactSelectOptions(packageTypes || []);
      const weightUnitOptions = Object.values(ShipmentLineItemWeightUnitEnum).map((value) => ({
        label: value,
        value
      }));
      const lengthUnitOptions = Object.values(ShipmentLineItemLengthUnitEnum).map((value) => ({
        label: value,
        value
      }));
      const freightClassesOptions = freightClasses.map((option) => {
        return {
          value: option.name,
          label: option.name
        };
      });
      const countryOptions = CountryList().map((country) => {
        return {
          label: country.name,
          value: country.code
        };
      });
      const [showSaveModal, setShowSaveModal] = useState<Record<string, boolean>>({});
      const {values, setFieldValue, setValues, errors, touched, isSubmitting} = useFormikContext<{
        line_items: LineItemFormValues[];
      }>();

      const fieldWarnings = fieldWarningsProp ? fieldWarningsProp : getLineItemWarnings(values?.line_items);
      //if creating purchase order, open item card
      const [expanded, setExpanded] = useState<(number | undefined)[]>(
        edit && expandedIndex ? (expandedIndex >= -1 ? [expandedIndex] : []) : [0]
      );
      const {filterProductsDebounced, setProductValues} = useProductFieldHelpers();
      const toggleExpanded = (index: number) => {
        if (expanded.includes(index)) {
          setExpanded(expanded.filter((e) => e !== index));
        } else {
          setExpanded([...expanded, index]);
        }
      };

      const {handlePiecesChange, handleEmptyChange} = usePiecesChange('v2');

      const handleToggleSaveModal = (i: number) => {
        setShowSaveModal({[i]: !showSaveModal[i]});
      };

      useEffect(() => {
        if (!errors.line_items || typeof errors.line_items === 'string' || !isSubmitting) {
          return;
        }
        const errorIndices = errors.line_items
          //map indices of valid line item errors
          .map((lineItem: string | FormikErrors<LineItemFormValues>, index: number) => (lineItem ? index : undefined))
          .filter((index: number | undefined) => !isUndefined(index));
        setExpanded(errorIndices);
      }, [errors, isSubmitting]);

      useEffect(() => {
        values.line_items.forEach((item, index) => {
          if (item) {
            const calculatedFreightClass = calculateFreightClass(
              item.length,
              item.width,
              item.height,
              item.length_unit,
              item.package_weight,
              item.weight_unit,
              item.total_packages
            );
            if (Number.isNaN(calculatedFreightClass)) {
              return;
            }
            setFieldValue(`line_items[${index}].calculatedFreightClass`, calculatedFreightClass);
          }
        });
      }, [setFieldValue, values.line_items]);

      useEffect(() => {
        values.line_items.forEach((item, index) => {
          if (values.line_items[index].hazmat_details) {
            return;
          }
          if (
            item.hazmat_hazard_class ||
            item.hazmat_identification_number ||
            item.hazmat_proper_shipping_name ||
            item.hazmat_packing_group
          ) {
            setFieldValue(`line_items[${index}].hazmat_details`, {
              hazmat_hazard_class: item.hazmat_hazard_class,
              hazmat_packing_group: item.hazmat_packing_group,
              hazmat_proper_shipping_name: item.hazmat_proper_shipping_name,
              hazmat_identification_number: item.hazmat_identification_number
            });
          }
        });
      }, [setFieldValue, values.line_items]);

      const handleHazmatCodeSelection = (hazmatSelection: Hazmat | null, index: number) => {
        /** Clear out hazmat codes when unselected */
        if (!hazmatSelection) {
          setValues({
            ...values,
            line_items: [
              ...values.line_items.slice(0, index),
              {
                ...values.line_items[index],
                hazmat_details: undefined,
                hazmat_identification_number: null,
                hazmat_hazard_class: null,
                //must send the backend null or packing group remains on the line item
                hazmat_packing_group: null as unknown as ShipmentLineItemHazmatPackingGroupEnum,
                hazmat_proper_shipping_name: null
              },
              ...values.line_items.slice(index + 1)
            ]
          });
        } else {
          setValues({
            ...values,
            line_items: [
              ...values.line_items.slice(0, index),
              {
                ...values.line_items[index],
                hazmat_details: hazmatSelection,
                hazmat_identification_number: hazmatSelection.identification_number,
                hazmat_hazard_class: hazmatSelection.hazard_class,
                hazmat_packing_group: hazmatSelection.packing_group as ProductHazmatPackingGroupEnum,
                hazmat_proper_shipping_name: hazmatSelection.proper_shipping_name
              },
              ...values.line_items.slice(index + 1)
            ]
          });
        }
      };

      const deleteLineItem = async ({
        selectedLineItem,
        onRemoveLineItem
      }: {
        selectedLineItem: LineItemFormValues;
        onRemoveLineItem: () => void;
      }) => {
        if (params?.purchase_order_id && selectedLineItem.id) {
          try {
            await deletePurchaseOrderLineItem(selectedLineItem.id);
            onRemoveLineItem();
            setSuccess(SHIPMENT_LINE_ITEM_SUCCESS_TITLE, LINE_ITEM_DELETE_SUCCESS_MESSAGE, 'bottom-right');
          } catch (error) {
            setError(SHIPMENT_LINE_ITEM_ERROR_TITLE, LINE_ITEM_DELETE_ERROR_MESSAGE);
            console.error(error);
          }
        }
        //we are deleting a shipment details line item
        else if (params?.shipment_id && selectedLineItem.id && shipmentDetails) {
          updateShipment(
            {
              shipmentId: params.shipment_id,
              shipment: {
                ...shipmentDetails,
                line_items: [
                  ...(shipmentDetails?.line_items ||
                    [].filter((lineItem: ShipmentLineItem) => lineItem?.id !== selectedLineItem.id))
                ]
              }
            },
            {
              onSuccess: () => {
                onRemoveLineItem();
                setSuccess(SHIPMENT_LINE_ITEM_SUCCESS_TITLE, LINE_ITEM_DELETE_SUCCESS_MESSAGE);
              },
              onError: (error) => {
                setError(SHIPMENT_LINE_ITEM_ERROR_TITLE, LINE_ITEM_DELETE_ERROR_MESSAGE);
                console.error(error);
              }
            }
          );
        } else onRemoveLineItem();
      };

      /**
       * Create or edit a product
       */

      const createEditProduct = (
        product: LineItemFormValues,
        setSubmitting: FormikHelpers<LineItemFormValues>['setSubmitting'],
        setErrors: FormikHelpers<LineItemFormValues>['setErrors'],
        index: number
      ) => {
        setSubmitting(true);
        const mappedProductFormValues = {
          ...product,
          value_per_piece: Number(removeCommasAndDollarSign(product.value_per_piece))
        };
        if (mappedProductFormValues.productId) {
          const mappedProductFormValues: Product = {
            ...product,
            value_per_piece: Number(removeCommasAndDollarSign(product.value_per_piece))
          };
          editProduct(mappedProductFormValues, {
            onSettled: () => {
              void queryClient.invalidateQueries([PRODUCTS_QUERY]);
              setSubmitting(false);
            },
            onSuccess: () => {
              setValues({
                ...values,
                line_items: [...values.line_items.slice(0, index), product, ...values.line_items.slice(index + 1)]
              });
              setSuccess(SHIPMENT_LINE_ITEM_SUCCESS_TITLE, PRODUCT_UPDATE_SUCCESS_MESSAGE);
              handleToggleSaveModal(index);
            },
            onError: (error: ShipwellError) => {
              setErrors(error?.field_errors || {});
              console.error(error);
            }
          });
        } else if (mappedProductFormValues) {
          createProduct(mappedProductFormValues, {
            onSettled: () => {
              void queryClient.invalidateQueries([PRODUCTS_QUERY]);
              setSubmitting(false);
            },
            onSuccess: () => {
              setSuccess(SHIPMENT_LINE_ITEM_SUCCESS_TITLE, PRODUCT_CREATE_SUCCESS_MESSAGE);
              handleToggleSaveModal(index);
            },
            onError: (error: ShipwellError) => {
              setErrors(error?.field_errors || {});
              console.error(error);
            }
          });
        } else setSubmitting(false);
      };

      /**
       * Check if a product has errors
       */
      const findProductErrors = (lineItem: LineItemFormValues, index: number) => {
        //check if non-empty error object exists for line item
        if (errors?.line_items?.[index]) {
          return Object.keys(errors.line_items[index]).filter((key) => key !== 'custom_data').length > 0;
        }
        //check if line item is missing data
        return lineItem && !lineItem.description;
      };

      const generateCardTitle = (lineItem: LineItemFormValues, index: number) => {
        return (
          <div className="shipment-line-items__form-card-title clickable" onClick={() => toggleExpanded(index)}>
            <span>
              {`Item ${index + 1}
        ${lineItem?.description ? ` - ${lineItem.description.replace(/(.{25})..+/, '$1…')}` : ''}`}
            </span>
          </div>
        );
      };

      const showMissingRequired = (index: number) => errors?.line_items?.[index] && touched?.line_items?.[index];
      const getLineItemErrors = (index: number) => {
        if (!errors.line_items || typeof errors?.line_items[index] === 'string' || !errors.line_items.length) {
          return [];
        }
        return errors?.line_items[index];
      };
      const getCalculatedLineItemWeight = (lineItem: LineItemFormValues) => {
        if (
          lineItem?.package_weight &&
          lineItem.package_weight > 0 &&
          lineItem?.total_packages &&
          lineItem.total_packages > 0
        ) {
          return lineItem.package_weight * lineItem.total_packages;
        }
        return 0;
      };
      if (isLoadingInternationalPreferences || isLoadingPackageTypes || isLoadingPieceTypes) {
        return <Loader loading />;
      }

      return (
        <FieldArray
          name="line_items"
          render={(arrayHelpers) => (
            <>
              {values.line_items?.map((lineItem, i) => {
                const lineItemErrors = getLineItemErrors(i);
                const calculatedLineItemWeight = getCalculatedLineItemWeight(lineItem);
                return (
                  <Card
                    key={lineItem.id}
                    title={generateCardTitle(lineItem, i)}
                    className="shipment-line-items__form-card expanded"
                    draggableProvided={undefined}
                    actions={
                      <div className="flex gap-4">
                        {showMissingRequired(i) ? (
                          <div className="flex items-center gap-2 text-sw-error">
                            <SvgIcon name="ErrorOutlined" />
                            <span>Field {pluralize('error', getErrorCount(lineItemErrors))}</span>
                          </div>
                        ) : null}
                        {values.line_items.length > 1 ? (
                          <div className="shipment-line-items__form-card-actions">
                            <DeleteWarningTooltip
                              title="Delete Item"
                              itemLabel={`Item ${i + 1} ${
                                lineItem.description ? ` - ${lineItem.description.replace(/(.{25})..+/, '$1…')}` : ''
                              }`}
                              onDelete={() =>
                                deleteLineItem({
                                  selectedLineItem: lineItem,
                                  onRemoveLineItem: () => arrayHelpers.remove(i)
                                })
                              }
                            >
                              <SvgIcon name="TrashOutlined" />
                            </DeleteWarningTooltip>
                          </div>
                        ) : null}
                      </div>
                    }
                    isCollapsible
                    isCollapsed={!expanded.includes(i)}
                  >
                    <CollapsibleCardContent>
                      <div key={i} className="field-array-grid">
                        <div className="field-grid select-grid">
                          {!externalForm ? (
                            <div className="grid-item-1-4">
                              <Field
                                name={`line_items[${i}].product_ref`}
                                component={FormikSelect}
                                label="Product"
                                async
                                required={false}
                                clearable
                                defaultOptions
                                loadOptions={filterProductsDebounced}
                                simpleValue
                                defaultValue={
                                  values.line_items[i]?.product_ref
                                    ? {product_ref: values.line_items[i].product_ref}
                                    : ''
                                }
                                onChange={(productId: string) =>
                                  setProductValues({
                                    productId,
                                    index: i,
                                    replace: arrayHelpers.replace,
                                    lineItemId: values.line_items[i]?.id || ''
                                  })
                                }
                                getOptionLabel={(option: Product) => option.product_ref}
                                getOptionValue={(option: Product) => option.id}
                              />
                            </div>
                          ) : null}

                          <div className="grid-item-1-4">
                            <Field
                              required
                              name={`line_items[${i}].description`}
                              label="Product Description"
                              component={FormikTextInput}
                            />
                          </div>
                          {showAmountFields ? (
                            <>
                              <div className="grid-item-1-2">
                                <Field
                                  required
                                  name={`line_items[${i}].amount.value`}
                                  label="Amount"
                                  component={FormikTextInput}
                                />
                              </div>
                              <div className="grid-item-3-4">
                                <Field
                                  required
                                  simpleValue
                                  name={`line_items[${i}].amount.unit`}
                                  label="Unit"
                                  component={FormikSelect}
                                  options={UNIT_OPTIONS}
                                />
                              </div>
                            </>
                          ) : null}
                          <Field
                            required={hasLTL || hasParcel}
                            name={`line_items[${i}].total_packages`}
                            label="Quantity"
                            component={FormikTextInput}
                          />

                          {!hasParcel ? (
                            <Field
                              simpleValue
                              required={hasLTL}
                              clearable
                              name={`line_items[${i}].package_type`}
                              label="Packaging"
                              placeholder="Select a package type"
                              options={packageTypeOptions}
                              component={FormikSelect}
                            />
                          ) : null}

                          {hasParcel ? (
                            <Field
                              simpleValue
                              provider={parcelProvider}
                              required
                              isParcel
                              name={`line_items[${i}].provider_specific_packaging`}
                              label="Packaging"
                              placeholder="Select a package type"
                              component={ProviderPackageTypesField}
                            />
                          ) : null}

                          <Field
                            required={isWeightRequired}
                            name={`line_items[${i}].package_weight`}
                            label="Package Weight"
                            component={FormikTextInput}
                          />

                          <Field
                            clearable={false}
                            simpleValue
                            name={`line_items[${i}].weight_unit`}
                            options={weightUnitOptions}
                            label="Weight Unit"
                            component={FormikSelect}
                          />

                          <div className="grid-item-1-4 shipment-line-items-total-weight">
                            <span>
                              <div className="shipment-line-items-total-weight-title">Total Item Weight </div>
                              {calculatedLineItemWeight > 0 ? (
                                <div className="shipment-line-items-total-weight-value">
                                  {`${calculatedLineItemWeight.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}
                                  ${lineItem.weight_unit || 'lb'}`}
                                </div>
                              ) : (
                                <div className="shipment-line-items-total-weight-value">
                                  <strong>--</strong>
                                </div>
                              )}
                            </span>
                          </div>

                          {!hasParcel || lineItem.provider_specific_packaging === 'YOUR_PACKAGING' ? (
                            <>
                              <Field
                                required={hasLTL || hasParcel}
                                name={`line_items[${i}].length`}
                                label="Length"
                                component={FormikTextInput}
                              />

                              <Field
                                required={hasLTL || hasParcel}
                                name={`line_items[${i}].width`}
                                label="Width"
                                component={FormikTextInput}
                              />

                              <Field
                                required={hasLTL || hasParcel}
                                name={`line_items[${i}].height`}
                                label="Height"
                                component={FormikTextInput}
                              />

                              <Field
                                simpleValue
                                name={`line_items[${i}].length_unit`}
                                options={lengthUnitOptions}
                                label="Length Unit"
                                component={FormikSelect}
                                clearable={false}
                              />
                            </>
                          ) : null}
                          {!hasDrayage && !hasParcel ? (
                            <>
                              <Field
                                name={`line_items[${i}].total_pieces`}
                                label="Number of Pieces"
                                component={FormikTextInput}
                                onChange={handleEmptyChange}
                                onBlur={(ev: React.ChangeEvent<HTMLInputElement>) => handlePiecesChange(ev, i)}
                              />

                              <Field
                                clearable
                                simpleValue
                                name={`line_items[${i}].piece_type`}
                                label="Piece Type"
                                placeholder="Select one"
                                options={getPieceTypeReactSelectOptions(pieceTypes || [])}
                                component={FormikSelect}
                              />

                              <Field
                                name={`line_items[${i}].value_per_piece`}
                                label={`Value Per Piece (${internationalPreferences?.currency || ''})`}
                                component={FormikTextInput}
                              />

                              {!hasDrayage && !hasParcel ? (
                                <Field
                                  simpleValue
                                  required={hasLTL}
                                  name={`line_items[${i}].freight_class`}
                                  label="Freight Class"
                                  placeholder="Select one"
                                  options={freightClassesOptions}
                                  component={FormikSelect}
                                />
                              ) : null}

                              <Field
                                name={`line_items[${i}].nmfc_item_code`}
                                label="NMFC Code"
                                component={FormikTextInput}
                              />

                              <Field
                                name={`line_items[${i}].nmfc_sub_code`}
                                label="NMFC Sub Code"
                                component={FormikTextInput}
                              />

                              <Field
                                clearable
                                simpleValue
                                name={`line_items[${i}].country_of_manufacture`}
                                label="Country of Manufacture"
                                options={countryOptions}
                                component={FormikSelect}
                              />

                              <div className="grid-item-1-4">
                                <Field
                                  name={`line_items[${i}].stackable`}
                                  label="Stackable"
                                  component={FormikCheckbox}
                                />
                              </div>

                              <div className="grid-item-1-4">
                                <Field
                                  component={FormikCheckbox}
                                  name={`line_items[${i}].hazmat_required`}
                                  label="Hazmat"
                                  onChange={({target: {checked}}: React.ChangeEvent<HTMLInputElement>) => {
                                    if (!checked) {
                                      handleHazmatCodeSelection(null, i);
                                    }
                                    setFieldValue(`line_items[${i}].hazmat_required`, checked);
                                  }}
                                />
                                {values.line_items[i].hazmat_required ? (
                                  <HazmatCodesFields
                                    index={i}
                                    onChange={(value: Hazmat | null) => handleHazmatCodeSelection(value, i)}
                                    prepend="line_items"
                                  />
                                ) : null}
                              </div>

                              {!externalForm ? (
                                <div className="mb-4">
                                  <Field
                                    name={`line_items[${i}].refrigeration_required`}
                                    label="Refrigeration Required"
                                    component={FormikCheckbox}
                                  />
                                </div>
                              ) : null}

                              <Field
                                required={lineItem && lineItem.refrigeration_required}
                                label="Min Temp (&deg;F)"
                                name={`line_items[${i}].refrigeration_min_temp`}
                                placeholder="e.g., 35"
                                component={FormikTextInput}
                              />

                              <Field
                                required={lineItem && lineItem.refrigeration_required}
                                label="Max Temp (&deg;F)"
                                name={`line_items[${i}].refrigeration_max_temp`}
                                placeholder="e.g., 50"
                                component={FormikTextInput}
                              />
                            </>
                          ) : null}
                          {fieldWarnings?.length > 0 && fieldWarnings[i]?.freightClassWarning ? (
                            <FreightClassWarning />
                          ) : null}
                          {fieldWarnings?.length > 0 && fieldWarnings[i]?.excessiveLengthWarning ? (
                            <ExcessiveLengthWarning isWhiteLabel={isWhiteLabel()} />
                          ) : null}
                          {!slimForm && !externalForm ? (
                            <div className="grid-item-1-4 grid-end">
                              <Button
                                disabled={
                                  //prevent submission if line item contains errors
                                  findProductErrors(lineItem, i)
                                }
                                onClick={() => handleToggleSaveModal(i)}
                                iconName={'Save'}
                              >
                                {values.line_items[i].product_ref ? ' Update ' : ' Save '} product
                              </Button>
                            </div>
                          ) : null}
                          <Modal
                            show={Boolean(showSaveModal[i])}
                            title="Save Product"
                            onClose={() => handleToggleSaveModal(i)}
                            primaryBtnName={lineItem?.product_ref ? 'Update' : 'Save'}
                            footerComponent={null}
                          >
                            <MiniProductFieldsForm
                              onCancel={() => handleToggleSaveModal(i)}
                              handleSubmit={createEditProduct}
                              values={lineItem}
                              lineItemIndex={i}
                            />
                          </Modal>
                          {customFields.length > 0 ? (
                            <div className="grid-item-1-4">
                              <div className="shipment-line-items-custom-fields-title">Custom Fields</div>
                            </div>
                          ) : null}
                          {customFields.map((cf) => {
                            return (
                              <div className="grid-item-1-4" key={cf.id}>
                                <Field
                                  simpleValue
                                  simpleOptionValue="label"
                                  name={`line_items[${i}].custom_data.${getCustomDataPath(entityType)}.${cf.id || ''}`}
                                  label={cf.label}
                                  required={cf.required}
                                  options={cf.allowed_values}
                                  component={isSingleSelectionList(cf.field_type) ? FormikSelect : FormikTextInput}
                                  key={cf.key}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </CollapsibleCardContent>
                  </Card>
                );
              })}
              <div className="shipment-line-items__form-action">
                <Card
                  draggableProvided={undefined}
                  title={
                    <Button
                      variant="tertiary"
                      iconName="AddCircleOutlined"
                      onClick={() => {
                        arrayHelpers.push(defaultLineItemFieldValues);
                        setExpanded([...expanded, values.line_items.length]);
                      }}
                    >
                      Add Item
                    </Button>
                  }
                  className="shipment-line-items__form-card collapsed shipment-line-items__form-add"
                />
              </div>
            </>
          )}
        />
      );
    }
  )
);
