import {Fragment} from 'react';
import {PermissionDetail, PermissionResponse} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useIsFetching, useQueryClient} from '@tanstack/react-query';
import {Popover, SvgIcon} from '@shipwell/shipwell-ui';
import {PERMISSIONS} from 'App/data-hooks/queryKeys';
import {useIsTruncated} from 'App/utils/hooks/useIsTruncated';
import {toTitleCase} from 'App/utils/globals';
import {FlexBox} from 'App/components/Box';

export const PermissionsCell = ({permissionKeys}: {permissionKeys?: string[]}) => {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching([PERMISSIONS]) > 0;
  const permissionsQuery: PermissionResponse | undefined = queryClient.getQueryData([PERMISSIONS]);

  const getPermissionsFromQuery = () => {
    if (!permissionsQuery) {
      return;
    }
    const entries = Object.entries(permissionsQuery) as unknown as [keyof PermissionResponse, PermissionDetail][];
    const reducedEntries = entries.reduce((all: {name: string; permissions: string[]}[], [key, value]) => {
      if (value.permissions.some((permission) => permissionKeys?.includes(permission.key))) {
        const mappedPermissions =
          permissionKeys?.reduce((all: string[], key) => {
            const match = value.permissions?.find((permission) => permission.key === key);
            if (match) {
              all.push(match.display);
            }
            return all;
          }, []) || [];

        const formattedPermissions = {
          name: toTitleCase(key).replaceAll('_', ' '),
          permissions: mappedPermissions
        };
        all.push(formattedPermissions);
      }
      return all;
    }, []);

    return reducedEntries;
  };
  const permissionsData = getPermissionsFromQuery() || [];

  const {childRef, parentRef, isTruncated} = useIsTruncated<HTMLDivElement, HTMLSpanElement>([permissionsData.length]);

  if (isFetching) {
    return <SvgIcon name="LoadingDots" />;
  }
  if (!permissionsData.length) {
    return <>--</>;
  }
  return (
    <Popover
      showArrow
      theme="dark"
      trigger={({
        setIsOpen,
        setTriggerElement
      }: {
        setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
        setTriggerElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
      }) => (
        <div ref={setTriggerElement}>
          <div
            ref={parentRef}
            className="max-w-3xl"
            onMouseEnter={() => (isTruncated ? setIsOpen(true) : null)}
            onMouseLeave={() => (isTruncated ? setIsOpen(false) : null)}
          >
            <span ref={childRef} className="block truncate">
              {permissionsData
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((permission, i) => (
                  <Fragment key={permission.name}>
                    <span>
                      <span className="font-bold">{permission.name}:</span> {permission.permissions.join(', ')}
                    </span>
                    {permissionsData.length > i + 1 ? ' | ' : null}
                  </Fragment>
                ))}
            </span>
          </div>
        </div>
      )}
    >
      <div className="max-w-2xl p-2">
        <FlexBox direction="col">
          {permissionsData
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((permission) => (
              <div key={permission.name}>
                <span className="font-bold">{permission.name}:</span> {permission.permissions.join(', ')}
              </div>
            ))}
        </FlexBox>
      </div>
    </Popover>
  );
};
