import {ReactNode} from 'react';
import {SidebarContent} from '@shipwell/shipwell-ui';
import GenerateBOL from './GenerateBOL';
import GenerateRateConfirmation from './GenerateRateConfirmation';
import {CancelShipment} from 'App/data-hooks';
import CancelLeg from 'App/containers/shipments/details/components/SidebarActions/CancelLeg';
import AddContainerStop from 'App/containers/shipments/details/components/SidebarActions/AddContainerStop';

export const Divider = () => <div className="-mx-2 my-2 justify-self-stretch border-b border-sw-border" />;

export const ContentWrapper = ({children}: {children: ReactNode}) => (
  <div className="grid grid-cols-1 justify-items-start gap-2 px-6 py-4">{children}</div>
);

export const ShipmentSidebarActions = ({
  shipmentId,
  onClose,
  ...props
}: {
  shipmentId: string;
  onClose: () => void;
}): JSX.Element => {
  return (
    <SidebarContent
      title="Actions"
      action={null}
      onClose={onClose}
      // spreading props are required for SidebarContent in the shipwell-ui
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <ContentWrapper>
        <GenerateBOL shipmentId={shipmentId} />
        <GenerateRateConfirmation shipmentId={shipmentId} />
        <Divider />
        <AddContainerStop shipmentId={shipmentId} />
        <CancelShipment shipmentId={shipmentId} />
      </ContentWrapper>
    </SidebarContent>
  );
};

ShipmentSidebarActions.tabsRole = 'TabPanel';

export const DrayageLegSidebarActions = ({
  legId,
  onClose,
  ...props
}: {
  legId: string;
  onClose: () => void;
}): JSX.Element => {
  return (
    <SidebarContent
      title="Actions"
      action={null}
      onClose={onClose}
      // spreading props are required for SidebarContent in the shipwell-ui
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <ContentWrapper>
        <CancelLeg legId={legId} />
      </ContentWrapper>
    </SidebarContent>
  );
};

DrayageLegSidebarActions.tabsRole = 'TabPanel';
