import moment from 'moment';
import {mixed, string, array, object, ref, number, InferType} from 'yup';
import map from 'lodash/map';
import range from 'lodash/range';
import {FuelSurchargeTableRegionEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {validateDecimalPoints} from 'App/utils/yupHelpers';
import {RATE_TYPE_PER_MILE} from 'App/containers/userCompany/rateTables/constants';

export function getDaysOfWeekOptions() {
  const daysOfWeek = range(0, 7).map((day) => {
    const dayString = moment().weekday(day).format('dddd');
    return {value: dayString.toUpperCase(), label: dayString};
  });
  return daysOfWeek;
}

export const defaultFormValues = {
  status: 'ACTIVE',
  modes: ['FTL'],
  start_date: moment().format('YYYY-MM-DD'),
  end_date: moment().add(1, 'years').format('YYYY-MM-DD'),
  name: '',
  base_fuel_price: null,
  customers: [],
  doe_max_rate: null,
  doe_rate_increment: null,
  user_base_rate: null,
  user_rate_increment: null,
  miles_per_gallon: null,
  external_reference: '',
  rates_updated_day_of_week: '',
  rate_type: RATE_TYPE_PER_MILE,
  calculation_rows: [{fuel_price_lower_bound: 0, fuel_surcharge: 0}],
  region: FuelSurchargeTableRegionEnum.Us
};

export const carrierValidationSchemaFields = {
  status: mixed().oneOf(['ACTIVE', 'INACTIVE']).required('Status is required.'),
  name: string().nullable().required('A name is required.'),
  external_reference: string().nullable().required('A unique Fuel Surcharge Table reference is required.'),
  locations: array().of(object().nullable()),
  modes: array().of(string().nullable()),
  equipment_types: array().of(string().nullable()),
  carrier_relationships: array().of(object().shape({id: string()}).nullable()),
  customers: array().of(
    object().shape({
      id: string().nullable()
    })
  ),
  start_date: string().nullable().required('Start date is required.'),
  end_date: string().nullable(),
  rates_updated_day_of_week: mixed()
    .oneOf(map(getDaysOfWeekOptions(), 'value'), 'Day Rates are Updated must be one of ${values}.')
    .required('Day Rates are Updated is required.'),
  base_fuel_price: number()
    .required('Base Fuel Price is required.')
    .min(0, 'Must be at least 0.')
    .typeError('Rate must be a valid dollar amount.')
    .test({
      name: 'Maximum 3 decimals',
      message: 'Enter up to 3 decimals.',
      test: (value) => (value ? validateDecimalPoints(value, 3) : true)
    }),
  miles_per_gallon: number().integer('Miles Per Gallon must be an integer.').nullable(),
  region: mixed<FuelSurchargeTableRegionEnum>().required('Region is required.')
};

const carrierValidationSchema = object().shape({...carrierValidationSchemaFields});

export type FuelSurchargesFormValues = InferType<typeof carrierValidationSchema>;

export const fuelSurchargeRateTableBuilderSchemaFields = {
  base_fuel_price: number()
    .required('Base Fuel Price is required.')
    .min(0, 'Must be at least 0.')
    .typeError('Rate must be a valid dollar amount.')
    .test({
      name: 'Maximum 3 decimals',
      message: 'Enter up to 3 decimals.',
      test: (value) => (value ? validateDecimalPoints(value, 3) : true)
    }),
  doe_rate_increment: number()
    .required('Rate Increments are required.')
    .min(0.001, 'Must be greater than 0.')
    .typeError('Rate must be a valid dollar amount.')
    .test({
      name: 'Maximum 3 decimals',
      message: 'Enter up to 3 decimals.',
      test: (value) => (value ? validateDecimalPoints(value, 3) : true)
    }),
  doe_max_rate: number()
    .required('Max Rate is required.')
    .moreThan(ref('base_fuel_price'), 'Must be greater base rate.')
    .max(14.999, 'Max Rate must be less than 15.')
    .typeError('Rate must be a valid dollar amount.')
    .test({
      name: 'Maximum 3 decimals',
      message: 'Enter up to 3 decimals.',
      test: (value) => (value ? validateDecimalPoints(value, 3) : true)
    }),
  user_base_rate: number()
    .required('Fuel Rate is required.')
    .min(0, 'Must be at least 0.')
    .typeError('Rate must be a valid dollar amount.')
    .test({
      name: 'Maximum 3 decimals',
      message: 'Enter up to 3 decimals.',
      test: (value) => (value ? validateDecimalPoints(value, 3) : true)
    }),
  user_rate_increment: number()
    .required('Rate Increments are required.')
    .min(0.001, 'Must be greater than 0.')
    .typeError('Rate must be a valid dollar amount.')
    .test({
      name: 'Maximum 3 decimals',
      message: 'Enter up to 3 decimals.',
      test: (value) => (value ? validateDecimalPoints(value, 3) : true)
    }),
  rate_type: string().nullable().required('Rate Type is required.'),
  calculation_rows: array().of(
    object().shape({
      fuel_surcharge: number()
        .required('A rate is required.')
        .typeError('Must be a dollar value amount.')
        .test({
          name: 'Maximum 3 decimals',
          message: 'Enter up to 3 decimals.',
          test: (value) => {
            return value ? validateDecimalPoints(value, 3) : true;
          }
        })
        .test({
          name: 'non negatives',
          message: 'Must be a positive number',
          test: (value) => {
            return value ? value >= 0 : true;
          }
        })
    })
  )
};
