import {EquipmentTypeValues, RFQ, Shipment, ShipmentMetadata} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Modal, SidebarContent} from '@shipwell/shipwell-ui';
import {useState, useEffect, useMemo} from 'react';
import {isNull, isUndefined} from 'lodash';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {ProviderCode, ShipmentMode} from '@shipwell/genesis-sdk';
import {Link} from 'react-router';
import OriginAndDestinationBanner from 'App/common/OriginAndDestinationBanner';
import {useCapacityProviders, useDatPricing} from 'App/data-hooks';
import {DAT_RATE_TYPE_CONTRACT, DAT_RATE_TYPE_SPOT} from 'App/components/pricingIntelChart/pricingIntelConstants';
import {removeCommasAndDollarSign} from 'App/utils/globals';
import useToggle from 'App/utils/hooks/useToggle';
import PricingIntelCalculatorForm from 'App/components/pricingIntelChart/components/pricingIntelCalculator';
import FinancialsForm from 'App/containers/Marketplace/components/forms/FinancialsForm';
import {getShipmentDetails} from 'App/actions/shipments';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import useUpdateShipment from 'App/api/shipment/useUpdateShipment';
import {transformAxiosError} from 'App/utils/globalsTyped';
import {useConnectionsQuery, useCreateRateRequestMutation, useRatesPolling} from 'App/containers/InstantRates/hooks';
import Financials from 'App/containers/Shipment/components/EmbeddedPricingIntel/Financials';
import ExternalRates, {
  CARRIER_RATES_SOURCE
} from 'App/containers/Shipment/components/EmbeddedPricingIntel/ExternalRates';
import {getExternalDATRatesList, getStopAddress} from 'App/containers/Shipment/components/EmbeddedPricingIntel/utils';
import LaneHistory from 'App/containers/Shipment/components/EmbeddedPricingIntel/LaneHistory';
import ContractRates from 'App/containers/Shipment/components/EmbeddedPricingIntel/ContractRates';

export const DEFAULT_DAT_EQUIPMENT = {id: 1, name: 'Dry Van', machine_readable: EquipmentTypeValues.DryVan};

type EmbeddedPricingIntelProps = {
  onClose: () => void;
  tabsRole: string;
  shipment: Shipment;
  fetchShipmentDetails: (id: string) => Promise<void>;
  selected: boolean;
} & WithStatusToastProps;

type RateRequestIdState = {
  rateRequestId?: string;
  rateId?: string | null;
  legacyRfqId?: string | null;
};

export type ExternalRatesType = {
  label: string;
  rate: number;
  currency: string;
  isProviderIntegrated?: boolean;
  perMileRate?: number;
  isDATRate?: boolean;
};

const EmbeddedPricingIntel = ({shipment, fetchShipmentDetails, setError}: EmbeddedPricingIntelProps) => {
  const stopsArr = useMemo(() => shipment?.stops || [], [shipment?.stops]);
  const firstAndLastStops = [stopsArr[0], stopsArr[stopsArr.length - 1]];
  const stopsPayload = stopsArr.length > 2 ? firstAndLastStops : stopsArr;
  const initialPriceSource = stopsArr.length > 2 ? CARRIER_RATES_SOURCE : DAT_RATE_TYPE_SPOT;
  const [requestIds, setRequestIds] = useState<RateRequestIdState | undefined>();
  const [pricingSource, setPricingSource] = useState(initialPriceSource);
  const [showCalculator, toggleShowCalculator] = useToggle(false);
  const [showFinancialsModal, toggleShowFinancialsModal] = useToggle(false);
  const [initialRate, setInitialRate] = useState<string | null>(null);
  const connections = useConnectionsQuery();
  const {mutate: updateShipment, isLoading: isUpdateShipmentLoading} = useUpdateShipment();
  //get active connections
  const activeConnections = useCapacityProviders();
  // external rates
  const ratesPollingQuery = useRatesPolling(requestIds?.rateRequestId, requestIds?.legacyRfqId, connections.data, {
    enabled: pricingSource === CARRIER_RATES_SOURCE
  });
  const createFtlRatesMutation = useCreateRateRequestMutation({
    onSuccess: (data: {rfq?: RFQ; rateRequestId: string}) => {
      setRequestIds({
        rateRequestId: data.rateRequestId,
        legacyRfqId: data.rfq?.id
      });
    }
  });
  const {DpmToggle, showDPM, DATResponse, fetchDatRates, isLoadingDatRates, isDatIntegrated} = useDatPricing({
    equipmentType: shipment.equipment_type || DEFAULT_DAT_EQUIPMENT,
    stops: stopsPayload,
    rateType: pricingSource as typeof DAT_RATE_TYPE_SPOT | typeof DAT_RATE_TYPE_CONTRACT
  });

  useEffect(() => {
    if (pricingSource === DAT_RATE_TYPE_SPOT || pricingSource === DAT_RATE_TYPE_CONTRACT) {
      void fetchDatRates();
    }
  }, [fetchDatRates, pricingSource]);

  useEffect(() => {
    if (
      isUndefined(shipment) ||
      createFtlRatesMutation.isLoading ||
      createFtlRatesMutation.isSuccess ||
      createFtlRatesMutation.isError
    ) {
      return;
    }
    if (pricingSource === CARRIER_RATES_SOURCE) {
      createFtlRatesMutation.mutate(shipment);
    }
  }, [shipment, createFtlRatesMutation, pricingSource]);

  const isLoadingExternalRates =
    pricingSource === CARRIER_RATES_SOURCE &&
    activeConnections?.capacityProvidersQuery?.data?.length !== ratesPollingQuery.data?.length &&
    !createFtlRatesMutation.isError;
  const isLoading = Boolean(
    createFtlRatesMutation.isLoading ||
      ratesPollingQuery.isInitialLoading ||
      isLoadingDatRates ||
      isLoadingExternalRates
  );
  const response = DATResponse?.rateResponses?.[0]?.response;
  const averageFuelSurchargePerTripUsd = response?.rate?.averageFuelSurchargePerTripUsd || 0;
  const averageFuelSurchargePerMileUsd = response?.rate?.averageFuelSurchargePerMileUsd || 0;
  const isFTLShipment = shipment.mode?.code === ShipmentMode.Ftl;
  const mappedExternalRates = ratesPollingQuery.data?.reduce(
    (acc: {[key: string]: {amount: number; currency?: string}}, rate) => {
      return Object.assign(acc, {
        [rate.carrier.scac]: {amount: rate.rate.amount, currency: rate.rate.currency}
      });
    },
    {}
  );

  const externalDATRates = getExternalDATRatesList({
    isDatIntegrated: Boolean(isDatIntegrated),
    ratesPerMile: response?.rate?.perMile,
    ratesPerTrip: response?.rate?.perTrip
  });

  const externalRates = [
    {
      label: 'Amazon Freight',
      rate: mappedExternalRates?.[ProviderCode.Amazon]?.amount || 0,
      currency: mappedExternalRates?.[ProviderCode.Amazon]?.currency || '',
      isProviderIntegrated: Boolean(
        activeConnections?.capacityProvidersQuery?.data?.find(
          (provider) => provider.provider_code === ProviderCode.Amazon
        )
      )
    },
    {
      label: 'Uber Freight',
      rate: mappedExternalRates?.[ProviderCode.Uber]?.amount || 0,
      currency: mappedExternalRates?.[ProviderCode.Uber]?.currency || '',
      isProviderIntegrated: Boolean(
        activeConnections?.capacityProvidersQuery?.data?.find(
          (provider) => provider.provider_code === ProviderCode.Uber
        )
      )
    },
    {
      label: 'CH Robinson',
      rate: mappedExternalRates?.[ProviderCode.Chrobinson]?.amount || 0,
      currency: mappedExternalRates?.[ProviderCode.Chrobinson]?.currency || '',
      isProviderIntegrated: Boolean(
        activeConnections?.capacityProvidersQuery?.data?.find(
          (provider) => provider.provider_code === ProviderCode.Chrobinson
        )
      )
    }
  ];

  const selectedExternalRates: ExternalRatesType[] =
    pricingSource === CARRIER_RATES_SOURCE ? externalRates : externalDATRates;

  const isDisabledCalculator = selectedExternalRates.every((val) => val.rate === 0);

  const handleApplyRate = (applyFor: string, rate: string) => {
    const formattedRate = +removeCommasAndDollarSign(rate) || null;
    return updateShipment(
      {
        shipmentId: shipment.id,
        shipment: {
          ...shipment,
          metadata: {
            ...shipment.metadata,
            [applyFor]: formattedRate
          } as ShipmentMetadata
        }
      },
      {
        onSuccess: () => {
          void fetchShipmentDetails(shipment.id);
        },
        onError: (error) => {
          if (setError) {
            const {title, message} = transformAxiosError(error);
            setError(title, message);
          }
        }
      }
    );
  };

  return (
    <>
      <div className="h-[calc(100vh-200px)] overflow-y-auto">
        {stopsArr.length > 1 ? (
          <OriginAndDestinationBanner
            origin={getStopAddress(stopsArr, 0)}
            destination={getStopAddress(stopsArr, stopsArr.length - 1)}
          />
        ) : null}
        <div className="p-3">
          <Financials
            shipment={shipment}
            isUpdateShipmentLoading={isUpdateShipmentLoading}
            toggleShowFinancialsModal={toggleShowFinancialsModal}
          />
          {isFTLShipment ? (
            <ExternalRates
              isDatIntegrated={Boolean(isDatIntegrated)}
              DpmToggle={DpmToggle}
              pricingSource={pricingSource}
              setPricingSource={setPricingSource}
              isLoading={isLoading}
              stopsArr={stopsArr}
              showDPM={showDPM}
              totalMiles={shipment?.total_miles}
              averageFuelSurchargePerTripUsd={averageFuelSurchargePerTripUsd}
              averageFuelSurchargePerMileUsd={averageFuelSurchargePerMileUsd}
              toggleShowCalculator={toggleShowCalculator}
              setInitialRate={setInitialRate}
              handleApplyRate={handleApplyRate}
              selectedExternalRates={selectedExternalRates}
              isDisabledCalculator={isDisabledCalculator}
              datResponse={DATResponse}
            />
          ) : null}
        </div>

        <LaneHistory
          shipment={shipment}
          stops={stopsPayload}
          isFTLShipment={isFTLShipment}
          showDPM={showDPM}
          totalMiles={shipment?.total_miles}
        />
        {isFTLShipment ? (
          <ContractRates
            shipment={shipment}
            isFTLShipment={isFTLShipment}
            showDPM={showDPM}
            totalMiles={shipment?.total_miles}
          />
        ) : null}
      </div>
      <Modal
        show={showCalculator && !isNull(initialRate)}
        onClose={() => {
          toggleShowCalculator();
          setInitialRate(null);
        }}
        footerComponent={null}
        title="Calculator"
        size="small"
      >
        <PricingIntelCalculatorForm
          rates={selectedExternalRates}
          initialRate={initialRate}
          averageFuelSurchargePerTripUsd={pricingSource === CARRIER_RATES_SOURCE ? 0 : averageFuelSurchargePerTripUsd}
        />
      </Modal>
      <Modal
        show={showFinancialsModal}
        title="Financials"
        footerComponent={null}
        onClose={() => toggleShowFinancialsModal()}
      >
        <FinancialsForm
          selectedShipment={shipment}
          handleClose={() => toggleShowFinancialsModal()}
          fetchShipmentDetails={() => fetchShipmentDetails(shipment.id)}
          setError={setError}
        />
      </Modal>
    </>
  );
};

const ConnectedEmbeddedPricingIntel = compose<EmbeddedPricingIntelProps, EmbeddedPricingIntelProps>(
  WithStatusToasts,
  connect(null, {fetchShipmentDetails: getShipmentDetails})
)(EmbeddedPricingIntel);

const SidebarEmbeddedPricingIntel = (props: EmbeddedPricingIntelProps) => {
  return (
    <SidebarContent
      title="Pricing Intel"
      action={
        props.shipment?.mode?.code === ShipmentMode.Ftl && (props.shipment?.stops || []).length === 2 ? (
          <Link to={`/pricing-intel?shipment_id=${props.shipment.id}`} className="mr-2">
            View Pricing Details
          </Link>
        ) : null
      }
      // spreading props are required for SidebarContent in the shipwell-ui
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ConnectedEmbeddedPricingIntel {...props} />
    </SidebarContent>
  );
};

SidebarEmbeddedPricingIntel.tabsRole = 'TabPanel';

export default SidebarEmbeddedPricingIntel;
