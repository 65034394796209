import {createContext, ReactNode, ComponentType} from 'react';
import {QueryKey, useQuery, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import {
  CompaniesApiCompaniesCompanyIdCustomFieldsGetRequest,
  CustomField,
  CustomFieldEntityTypesEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {connect} from 'react-redux';
import {CUSTOM_FIELDS_KEY} from 'App/data-hooks/queryKeys';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {omitEmptyKeysWithEmptyObjectsRemoved} from 'App/utils/omitEmptyKeysTyped';
import {getCompanyCustomFields} from 'App/api/company/typed';
import {State} from 'App/reducers/types';
import {useUserMe} from 'App/data-hooks';

export default function useCustomFields(
  entityType?: CustomFieldEntityTypesEnum,
  shipmentId?: string,
  orderId?: string,
  queryOptions: UseQueryOptions<CustomField[]> = {}
) {
  const queryClient = useQueryClient();
  const {data: userData} = useUserMe();
  const companyId = userData?.company?.id || '';
  const parameters = omitEmptyKeysWithEmptyObjectsRemoved<CompaniesApiCompaniesCompanyIdCustomFieldsGetRequest>({
    companyId,
    shipmentId,
    entityType,
    orderId,
    //there is no UI to control custom fields pagination, default to 200
    pageSize: 200
  });
  const fetchCustomFields = useQuery(
    [CUSTOM_FIELDS_KEY, parameters] as QueryKey,
    async () => {
      if (!companyId) {
        return Promise.reject(new Error('Missing Company Id'));
      }
      const response = await getCompanyCustomFields(parameters);
      return response.data.results || [];
    },
    {enabled: !!companyId, staleTime: STATIC_DATA_STALE_TIME, ...queryOptions}
  );
  const invalidateCustomFieldsQuery = () => void queryClient.invalidateQueries([CUSTOM_FIELDS_KEY, {companyId}]);

  return {
    customFields:
      fetchCustomFields.data ||
      //there are a lot of components and associated tests that map through the 'custom fields' prop provided
      //by the legacy useCustomFields hook without checking that customFields is defined
      //(it won't be if companyId is not provided). Defaulting to an empty array preserves that behavior here.
      [],
    isLoading: fetchCustomFields.isInitialLoading,
    isError: fetchCustomFields.isError,
    refetch: invalidateCustomFieldsQuery,
    hasRetrievedCustomFields: fetchCustomFields.isSuccess
  };
}
interface CustomFieldsContextValues {
  customFields?: CustomField[];
  refetch?: () => void;
}
export const CustomFieldsContext = createContext<CustomFieldsContextValues>([] as CustomFieldsContextValues);

const CustomFieldsContextProvider = ({
  entityType,
  shipmentId,
  orderId,
  children,
  ...props
}: {
  companyId: string;
  entityType?: CustomFieldEntityTypesEnum;
  shipmentId?: string;
  orderId?: string;
  children: ReactNode;
}) => {
  const {customFields, refetch} = useCustomFields(entityType, shipmentId, orderId);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CustomFieldsContext.Provider value={{customFields, refetch}} {...props}>
      {children}
    </CustomFieldsContext.Provider>
  );
};

const CustomFieldsContextConsumer = CustomFieldsContext.Consumer;

export {CustomFieldsContextProvider, CustomFieldsContextConsumer};

/**
 * A helper to wrap a component in a custom fields context provider. Usually this approach is used
 * to allow class components to use a hook. This slightly different approach lets any component use
 * the hook via a context provider. This is useful when wrapping a form that needs access to custom
 * fields for validation and for displaying custom fields and data in its form elements without
 * refetching the API response.
 */
export const withCustomFieldsProvider =
  (entityType: CustomFieldEntityTypesEnum) =>
  (Component: ComponentType<{customFields: CustomField[]; company?: State['userCompany']['company']}>) => {
    const ComponentWithCustomFieldsProvider = ({
      shipmentId,
      company,
      ...props
    }: {
      shipmentId: string;
      company?: State['userCompany']['company'];
    }) => (
      <CustomFieldsContextProvider companyId={company?.id || ''} entityType={entityType} shipmentId={shipmentId}>
        <CustomFieldsContextConsumer>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {({customFields = []}) => <Component customFields={customFields} company={company} {...props} />}
        </CustomFieldsContextConsumer>
      </CustomFieldsContextProvider>
    );
    return connect((state: State) => ({
      company: state.userCompany.company
    }))(ComponentWithCustomFieldsProvider);
  };
