/* eslint-disable camelcase */
import {useState} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {Modal} from '@shipwell/shipwell-ui';
import useDeleteFuelSurchargeTableMutation from 'App/api/rateTables/hooks/useDeleteFuelSurchargeTableMutation';
import withStatusToasts, {
  WithStatusToastsPropTypes,
  WithStatusToastsDefaultProps
} from 'App/components/withStatusToasts';

const ConfirmDelete = ({fuelSurchargeTable, onSuccess, onError, onClose, show, setError}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const {mutate: deleteFuelSurchargeTable} = useDeleteFuelSurchargeTableMutation({onMutate: () => setIsDeleting(true)});

  const handleDeleteFuelSurchargeTable = () => {
    deleteFuelSurchargeTable(fuelSurchargeTable.id, {
      onSuccess,
      onError: (err) => {
        const {error = 'An unknown error occurred.', error_description = 'An unknown error occurred.'} =
          err.response?.data || {};
        console.error(`Error deleting fuel surcharge table ${fuelSurchargeTable.name}`, error);
        setError('Error!', `Error deleting fuel surcharge table "${fuelSurchargeTable.name}"! ${error_description}`);
        onError({error, error_description});
      },
      onSettled: () => setIsDeleting(false)
    });
  };

  return (
    <Modal
      variant="warning"
      show={show}
      title="Delete Fuel Surcharge Table"
      primaryBtnName="Delete Fuel Surcharge Table"
      onClose={onClose}
      onPrimaryAction={handleDeleteFuelSurchargeTable}
      PrimaryButtonProps={{
        loading: isDeleting,
        disabled: !get(fuelSurchargeTable, 'id')
      }}
    >
      <div>{`Are you sure you want to delete ${get(fuelSurchargeTable, 'name', 'this fuel surcharge table')}?`}</div>
    </Modal>
  );
};

ConfirmDelete.propTypes = {
  fuelSurchargeTable: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  onSucces: PropTypes.func,
  onError: PropTypes.func,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  ...WithStatusToastsPropTypes
};

ConfirmDelete.defaultProps = {
  fuelSurchargeTable: {},
  onSuccess: () => {},
  onError: () => {},
  onClose: () => {},
  show: false,
  ...WithStatusToastsDefaultProps
};

export default withStatusToasts(ConfirmDelete);
