import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Row, Col, Form, FormGroup, FormControl} from 'react-bootstrap';
import moment from 'moment';
import {SubmissionError} from 'redux-form';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import {Modal, Loader} from '@shipwell/shipwell-ui';
import * as formActions from '../../actions/forms';
import * as actions from '../../actions/users';
import * as billingActions from '../../actions/billing';
import * as shipmentActions from '../../actions/shipments';
import * as documentsActions from '../../actions/documents';
import * as authActions from '../../actions/auth';
import * as marketplaceActions from '../../actions/marketplace';
import ConfirmShipmentHeader from './FormSections/ConfirmShipmentHeader';
import ConfirmShipmentForm from './ConfirmShipmentForm';
import * as vendorActions from 'App/actions/vendors';
import {createShipmentPickup, updateShipmentPickup, getDispatchJobDetails} from 'App/api/shipment';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import {getPurchaseOrderByShipment} from 'App/containers/purchaseOrders/actions/async';
import {dispatchFTLInstantRate} from 'App/actions/_shipments';
import SchedulePickupForm from 'App/formComponents/forms/schedulePickup';
import RateQuote from 'App/components/RateQuote/RateQuote';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import SchedulePickup from 'App/components/schedulePickup';
import {getPickupValues, getProviderCode, getParcelPackageType} from 'App/utils/parcelConstants';
import withStatusToasts from 'App/components/withStatusToasts';
import {
  unpackErrors,
  constructShipmentObject,
  transformLocationAppointmentTypes,
  validateDollarValue,
  removeCommasAndDollarSign,
  cleanPayload
} from 'App/utils/globals';
import {SourceTypeEnum} from 'App/api/quoting/typed';
import {getRates} from 'App/api/genesis';
import {getRatesByRequestId, getDispatchByDispatchId} from 'App/api/genesis/typed';
import {checkShipmentModes} from 'App/utils/globalsTyped';

let submittedFormValues = {};
let dispatchJobInterval;
let dispatchResponseInterval;
const JOB_STATUSES = {PROCESSING: 'PROCESSING', SUCCESS: 'SUCCESS', ERROR: 'ERROR'};

@connect(
  (state) => ({
    selectedShipment: state.shipments.selectedShipment,
    selectedRFQ: state.shipments.selectedRFQ,
    selectedQuote: state.shipments.selectedQuote,
    company: state.auth.company,
    hazmatCodes: state.shipments.hazmatCodes,
    serviceLevels: state.shipments.serviceLevels,
    actingAsCompany: state.brokers.actingAsCompany,
    locationTypes: state.addresses.locationTypes,
    marketplaceLogin: state.auth.marketplaceLogin,
    appointmentTypes: state.shipments.appointmentTypes,
    savedQuoteForCustomerFinancials: state.shipments.savedQuoteForCustomerFinancials,
    selectedRelationship: state.vendors.selectedRelationship
  }),
  {
    ...formActions,
    ...actions,
    ...billingActions,
    ...shipmentActions,
    ...authActions,
    ...documentsActions,
    ...marketplaceActions,
    ...vendorActions,
    getPurchaseOrderByShipment,
    dispatchFTLInstantRate,
    showErrorToast: (message) => dispatch({type: types.ALERT_ERROR, payload: message})
  }
)
class ConfirmShipment extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleBook = this.handleBook.bind(this);
    this.handleRerate = this.handleRerate.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.renderReratingModal = this.renderReratingModal.bind(this);
    this.renderDispatchErrorModal = this.renderDispatchErrorModal.bind(this);
    this.fetchShipmentDetails = this.fetchShipmentDetails.bind(this);
    this.handleQuoteFilterChange = this.handleQuoteFilterChange.bind(this);
    this.handleSelectQuote = this.handleSelectQuote.bind(this);
    this.getQuotes = this.getQuotes.bind(this);
    this.awardMarketplaceQuote = this.awardMarketplaceQuote.bind(this);
    this.awardNormalQuote = this.awardNormalQuote.bind(this);
    this.performManualFinancialsAssignment = this.performManualFinancialsAssignment.bind(this);
    this.getPickupFilters = this.getPickupFilters.bind(this);
    this.redirectUser = this.redirectUser.bind(this);
    this.getDispatchStatus = this.getDispatchStatus.bind(this);
    this.showBookingErrors = this.showBookingErrors.bind(this);

    this.state = {
      showDispatchErrorModal: false,
      error: null,
      showReratingModal: false,
      booking: false,
      showNewRatesModal: false,
      loading: false,
      quoteFilter: 'price',
      shouldUpdateQuote: false,
      showSchedulePickupModal: false,
      showCreatePickupModal: false,
      parcelPackageType: null,
      isDispatching: false
    };
  }

  componentDidMount() {
    const {company, params} = this.props;
    const purchaseOrdersEnabled = company && company.feature_flags && company.feature_flags.purchase_orders_enabled;

    if (this.props.marketplaceLogin) {
      this.props.triggerMarketplaceLogin(false, null);
    }
    this.props.clearSelectedRelationship();

    if (purchaseOrdersEnabled) {
      this.props.getPurchaseOrderByShipment(params.shipment_id);
    }
  }

  componentDidUpdate(prevProps) {
    const {company, params} = this.props;

    if (company.id && company !== prevProps.company) {
      const purchaseOrdersEnabled = company && company.feature_flags && company.feature_flags.purchase_orders_enabled;

      if (purchaseOrdersEnabled) {
        this.props.getPurchaseOrderByShipment(params.shipment_id);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    //if we dont have a selected quote here (e.g., they refreshed the page), then send user back to quotes screen to ensure they select
    if (!nextProps.selectedQuote || nextProps.selectedQuote === null) {
      if (nextProps.location.query && nextProps.location.query.mode && !nextProps.location.query.parcel) {
        this.context.router.push('/marketplace/' + this.props.params.shipment_id + '/' + nextProps.location.query.mode);
      } else if (nextProps.location.query && nextProps.location.query.mode && nextProps.location.query.parcel) {
        this.context.router.push('/marketplace/' + this.props.params.shipment_id + '/' + 'fedex-rates');
      } else {
        //just default to the bids page
        this.context.router.push('/marketplace/' + this.props.params.shipment_id + '/bids');
      }
    }
  }

  componentWillUnmount() {
    clearInterval(dispatchJobInterval);
  }

  handleRerate() {
    const {selectedShipment} = this.props;
    this.setState({
      showNewRatesModal: true,
      showReratingModal: false,
      loading: true
    });
    //create a new RFQ for this shipment
    //proceed to kick off the RFQ process
    const rfqObj = {};
    //send this to P44 for quoting
    rfqObj.autoquote = true;
    rfqObj.shipment = this.props.selectedShipment.id;
    rfqObj.parent_rfq = null;
    // rfqObj.shipment_modes = [{id: 2, code: 'LTL', description: 'Less Than Truckload'}];
    rfqObj.shipment_modes = [selectedShipment.mode];
    //default to dry van
    rfqObj.equipment_types = [{id: 1, name: 'Dry Van', machine_readable: 'DRY_VAN'}];

    if (submittedFormValues.fedex_specific_options) {
      rfqObj.fedex_specific_options = {
        ...submittedFormValues.fedex_specific_options,
        packaging: this.state.parcelPackageType
      };
    }
    if (submittedFormValues.ups_specific_options) {
      rfqObj.ups_specific_options = {
        ...submittedFormValues.ups_specific_options,
        packaging: this.state.parcelPackageType
      };
    }
    if (submittedFormValues.usps_specific_options) {
      rfqObj.usps_specific_options = {
        ...submittedFormValues.usps_specific_options,
        packaging: this.state.parcelPackageType
      };
    }

    const opts = {};
    opts['xCompanyId'] = this.props.selectedShipment.customer.id;
    //pass in opts again here to create the rfq on behalf of shipper
    return this.props.createRFQ(rfqObj, opts).then((rfqResponse) => {
      if (rfqResponse.status === 200) {
        this.fetchShipmentDetails();
      } else {
        this.props.dispatchError('Unable to get new quotes. Please contact Shipwell for assistance');
      }
    });
  }

  fetchShipmentDetails() {
    this.props.getShipmentDetails(this.props.params.shipment_id).then((response) => {
      //if the customer in the response is not the currently logged in person, we want to be masquerading
      this.getQuotes(response.details);
    });
  }

  getQuotes(shipment) {
    //get the most recent RFQ for this shipment, and check if autoquoting finished
    let rfqs = JSON.parse(JSON.stringify(shipment.rfqs));
    //if there are no RFQs, maybe show error message?
    if (rfqs.length > 0) {
      //filter down to the parent RFQ on this page
      rfqs = rfqs.filter((e) => e.has_parent_rfq === false);
      rfqs = rfqs.sort(function (a, b) {
        return a.updated_at > b.updated_at ? -1 : 1;
      });
      const lastRFQ = rfqs[0];
      // For Parcels we should use includeFailures: true to get and display failed quotes
      const opts = shipment?.mode?.code === 'PARCEL' ? {includeFailures: true} : {};
      if (lastRFQ.company_owner_id !== this.props.company.id) {
        opts['xCompanyId'] = lastRFQ.company_owner_id;
      }

      this.props.getRFQDetails(lastRFQ.id, opts).then((response) => {
        //if this quote was being autoquoted and did not finish, debounce and check again in one second
        if (
          response &&
          response.details &&
          response.details.autoquote === true &&
          response.details.did_finish_autoquote === false
        ) {
          setTimeout(() => this.fetchShipmentDetails(), 1000);
        } else {
          this.setState({loading: false});
        }
      });
    }
  }

  handleContinue() {
    this.setState({booking: true});
    if (
      (this.props.selectedQuote?.source_type === SourceTypeEnum.Instant ||
        this.props.selectedQuote?.source_type === SourceTypeEnum.CarrierConnection) &&
      this.state.shouldUpdateQuote
    ) {
      //update the quote before proceeding
      return this.handleUpdateQuote({option: this.state.option}, this.state.quoteTotal, this.props.selectedShipment);
    }
    return this.handleBook({details: this.props.selectedShipment}, {option: this.state.option});
  }

  handleFormSubmit(attrs, quoteTotal, initialValues) {
    submittedFormValues = attrs;
    const isParcel = submittedFormValues.mode?.[0]?.code === 'PARCEL';
    let parcelPackageType = null;
    const shouldUpdateQuote = !isParcel && quoteTotal && quoteTotal !== this.props.selectedQuote.total;

    // get the package type when the form is submitted and set it in state
    if (isParcel && submittedFormValues.line_items) {
      const type = getParcelPackageType(submittedFormValues.line_items);
      if (type === 'MIXED_TYPES') {
        // mixed types is UI only, need to send backend-specific string
        parcelPackageType = 'YOUR_PACKAGING';
      } else {
        parcelPackageType = type;
      }
    }

    this.setState({shouldUpdateQuote, quoteTotal, parcelPackageType});

    if (attrs.stops) {
      attrs.stops = transformLocationAppointmentTypes(
        attrs.stops,
        this.props.locationTypes,
        this.props.appointmentTypes
      );
    }

    //if bill to override toggle is false, make sure to clear out any bill to information that might have been entered
    if (!attrs.override_bill_to) {
      if (attrs.bill_to_override) {
        attrs.metadata = {
          ...attrs.metadata,
          bill_to_override: null
        };
      }
    }

    const {serviceLevels} = this.props;
    if (attrs.service_level) {
      const serviceLevelId = Number(attrs.service_level);
      attrs.service_level = serviceLevels.find((sl) => sl.id === serviceLevelId) || null;
    }

    const shipmentObj = cleanPayload(constructShipmentObject(attrs));
    const opts = {};
    const metadata = {tags: []};
    if (attrs.metadata && attrs.metadata.tags && attrs.metadata.tags.length > 0) {
      for (let i = 0; i < attrs.metadata.tags.length; i++) {
        metadata.tags.push(attrs.metadata.tags[i].id ? attrs.metadata.tags[i].id : attrs.metadata.tags[i]);
      }
    }
    if (attrs.metadata?.max_buy_amount) {
      metadata.max_buy_amount = parseFloat(removeCommasAndDollarSign(attrs.metadata.max_buy_amount));
    }
    if (attrs.metadata?.buy_it_now_amount) {
      metadata.buy_it_now_amount = parseFloat(removeCommasAndDollarSign(attrs.metadata.buy_it_now_amount));
    }
    if (attrs.metadata?.target_rate_amount) {
      metadata.target_rate_amount = parseFloat(removeCommasAndDollarSign(attrs.metadata.target_rate_amount));
    }
    shipmentObj.metadata = {...shipmentObj.metadata, ...metadata};

    if (attrs.ups_specific_options) {
      shipmentObj.ups_specific_options = attrs.ups_specific_options;
    }
    if (attrs.fedex_specific_options) {
      shipmentObj.fedex_specific_options = attrs.fedex_specific_options;
    }
    if (attrs.usps_specific_options) {
      shipmentObj.usps_specific_options = attrs.usps_specific_options;
    }
    if (attrs.custom_data) {
      shipmentObj.custom_data = attrs.custom_data;
    }

    //DO A PUT on the shipment object, and submit for requoting
    return this.props.editShipment(this.props.selectedShipment.id, shipmentObj, opts).then((response) => {
      if (response.status === 200) {
        //check if the significantly_modified_at is set
        const timeModified = response.details.significantly_modified_at;
        if (
          (timeModified &&
            moment(timeModified).isValid() &&
            moment(timeModified).isAfter(moment(this.props.selectedQuote.created_at)) &&
            (this.props.selectedQuote?.source_type === SourceTypeEnum.Instant ||
              this.props.selectedQuote?.source_type === SourceTypeEnum.CarrierConnection) &&
            this.props.selectedQuote.mode.id !== 1 &&
            this.props.selectedQuote.mode.id !== 6) ||
          (this.props.selectedQuote.mode.code === 'PARCEL' &&
            (!isEqual(shipmentObj.fedex_specific_options, initialValues.fedex_specific_options) ||
              !isEqual(shipmentObj.ups_specific_options, initialValues.ups_specific_options) ||
              !isEqual(shipmentObj.usps_specific_options, initialValues.usps_specific_options) ||
              !isEqual(
                shipmentObj.stops[0].planned_date,
                moment(initialValues.stops[0].planned_date).format('YYYY-MM-DD')
              ) ||
              !isEqual(
                shipmentObj.stops[0].location.address.formatted_address,
                initialValues.stops[0].location.address.formatted_address
              ) ||
              !isEqual(
                shipmentObj.stops[1].location.address.formatted_address,
                initialValues.stops[1].location.address.formatted_address
              ) ||
              !isEqual(shipmentObj.line_items, initialValues.line_items) ||
              !isEqual(shipmentObj.accessorials, initialValues.accessorials)))
        ) {
          //if this is an instant quote and modified after the quote was created, we need to rerate
          this.setState({showReratingModal: true, option: attrs.option});
        } else {
          if (
            (this.props.selectedQuote?.source_type === SourceTypeEnum.Instant ||
              this.props.selectedQuote?.source_type === SourceTypeEnum.CarrierConnection) &&
            shouldUpdateQuote
          ) {
            //update the quote before proceeding
            return this.handleUpdateQuote(attrs, quoteTotal, shipmentObj);
          }
          //proceed to book
          return this.handleBook(response, attrs);
        }
      } else {
        //show errors
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
        submissionError._error = response.error_description;
        //handle edge cases for errors here
        throw new SubmissionError(submissionError);
      }
    });
  }

  handleUpdateQuote(attrs, quoteTotal, shipmentObj) {
    let markupValue = parseFloat(
      parseFloat(removeCommasAndDollarSign(quoteTotal)) -
        (parseFloat(this.props.selectedQuote.total) - parseFloat(this.props.selectedQuote.customer_markup))
    );

    if (!validateDollarValue(markupValue)) {
      this.setState({quoteErrors: 'Enter a valid number for markup'});
      return;
    }
    if (this.props.selectedQuote.created_by_company.id !== this.props.company.id) {
      //this is not our quote, so we can only modify the customer_markup field on it

      if (parseFloat(markupValue) !== parseFloat(this.props.selectedQuote.customer_markup)) {
        const markupToAdd = {customer_markup: parseFloat(removeCommasAndDollarSign(markupValue)).toFixed(2)};
        return this.props
          .editQuote(this.props.selectedQuote.rfq, this.props.selectedQuote.id, markupToAdd, {})
          .then((response) => {
            if (response.status === 200) {
              return this.handleBook({details: this.props.selectedShipment}, attrs);
            }
            this.setState({
              quoteErrors: response.details.error_description
            });
          });
      }
      //no markup to change
      return this.handleBook({details: this.props.selectedShipment}, attrs);
    }
    //this IS our quote, find the provider markup on it and edit it to proceed
    const currentMarkupCharge = this.props.selectedQuote.charge_line_items.filter(
      (e) => e.is_provider_markup === true
    )[0];
    const quoteObj = this.props.selectedQuote;
    //when doing a PUT on a quote, we need to strip down to just the PKs for most items
    if (quoteObj.equipment_type?.id) {
      quoteObj.equipment_type = quoteObj.equipment_type.id;
    }
    if (quoteObj.mode?.id) {
      quoteObj.mode = quoteObj.mode.id;
    }
    if (quoteObj.service_level?.id) {
      quoteObj.service_level = quoteObj.service_level.id;
    }
    if (quoteObj.carrier?.id) {
      quoteObj.carrier = quoteObj.carrier.id;
    }

    if (currentMarkupCharge) {
      //make sure we add this amount first since it came from the total
      markupValue += parseFloat(currentMarkupCharge.amount);
      for (var i = 0; i < quoteObj.charge_line_items.length; i++) {
        if (quoteObj.charge_line_items[i].is_provider_markup) {
          quoteObj.charge_line_items[i].amount = parseFloat(removeCommasAndDollarSign(markupValue)).toFixed(2);
          quoteObj.charge_line_items[i].unit_amount = parseFloat(removeCommasAndDollarSign(markupValue)).toFixed(2);
          quoteObj.charge_line_items[i].unit_quantity = 1;
        }
      }
    } else {
      quoteObj.charge_line_items.push({
        is_provider_markup: true,
        unit_amount: markupValue,
        unit_quantity: 1,
        unit_name: 'Item Charge',
        category: 'LH'
      });
    }
    return this.props
      .editQuote(this.props.selectedQuote.rfq, this.props.selectedQuote.id, quoteObj, {})
      .then((response) => {
        if (response.status === 200) {
          return this.handleBook({details: this.props.selectedShipment}, attrs);
        }
        this.setState({
          quoteErrors: response.details.error_description
        });
      });
  }

  async handleBook(response, attrs) {
    const {hasFTL} = checkShipmentModes(this.props.selectedQuote);
    //if it looks good, proceed to auto-book the shipment using the selected quote
    //INSTANT type and is FTL mode, should be requested/tendered to shipwell
    if (
      this.props.selectedQuote?.source_type === SourceTypeEnum.Instant && // do not add the CARRIER_CONNECTION here we want that to follow the typical autobook flow
      this.props.selectedQuote.mode.id === 1
    ) {
      return this.props
        .dispatchFTLInstantRate(response.details.id, {quote: this.props.selectedQuote.id})
        .then((dispatchResponse) => {
          if (dispatchResponse.status === 200) {
            this.props.resetConfirmShipmentForm();
            this.context.router.push('/shipments/' + response.details.id + '?FTLRateRequested=true');
          } else {
            //show errors
            const errors = dispatchResponse.field_errors || [];
            let submissionError = {};
            submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
            submissionError._error = dispatchResponse.error_description;
            //handle edge cases for errors here
            //show modal for dispatching error
            this.setState({
              showDispatchErrorModal: true,
              showReratingModal: false,
              booking: false,
              error: dispatchResponse.error_description
            });
            throw new SubmissionError(submissionError);
          }
        });
    } //if the quote is of source_type INSTANT and isLTL, then it can be autobooked...
    if (
      this.props.selectedQuote?.source_type === SourceTypeEnum.Instant ||
      this.props.selectedQuote?.source_type === SourceTypeEnum.CarrierConnection
    ) {
      this.setState({isDispatching: true});
      return this.props
        .autoBookShipment(response.details.id, {
          quote: this.props.selectedQuote.id,
          fedex_specific_options: submittedFormValues.fedex_specific_options
            ? {
                ...submittedFormValues.fedex_specific_options,
                packaging: this.state.parcelPackageType
              }
            : null,
          ups_specific_options: submittedFormValues.ups_specific_options
            ? {
                ...submittedFormValues.ups_specific_options,
                packaging: this.state.parcelPackageType
              }
            : null,
          usps_specific_options: submittedFormValues.usps_specific_options
            ? {
                ...submittedFormValues.usps_specific_options,
                packaging: this.state.parcelPackageType
              }
            : null
        })
        .then((bookResponse) => {
          if (bookResponse.status === 200) {
            this.getDispatchStatus(attrs, bookResponse);
          } else {
            this.showBookingErrors(bookResponse);
          }
        });
    }
    if (this.props.selectedQuote?.source_type === SourceTypeEnum.PrivateMarketplace) {
      //figure out if there is possibly a carrier/broker to assign at this stage
      if (
        this.props.selectedQuote.created_by_company &&
        this.props.selectedQuote.created_by_company.id !== this.props.company.id
      ) {
        return this.awardMarketplaceQuote(attrs, response);
      }
      return this.performManualFinancialsAssignment(attrs, response);
    }
    //figure out if there is possibly a carrier at this stage
    let companyId = null;
    if (
      this.props.selectedQuote.created_by_company &&
      this.props.selectedQuote.created_by_company.id !== this.props.company.id
    ) {
      companyId = this.props.selectedQuote.created_by_company.id;
      //do a lookup on the company id to get the full company object
      return this.props.getCompanyDetails(companyId).then((companyResponse) => {
        if (companyResponse.status === 200) {
          //first check if that vendor is ALREADY the vendor on the shipment, so we dont mess up the chain
          if (
            response.details &&
            response.details.relationship_to_vendor &&
            response.details.relationship_to_vendor.vendor &&
            response.details.relationship_to_vendor.vendor.id === companyId
          ) {
            //just award the quote
            this.awardNormalQuote(attrs, response);
          } else {
            //assign the vendor at this point
            return this.props
              .assignCarrier(response.details.id, {
                vendor: companyResponse.details,
                customer_charge_line_items: [],
                vendor_charge_line_items: []
              })
              .then((assignCarrierResponse) => {
                if (assignCarrierResponse.status === 200) {
                  this.awardNormalQuote(attrs, response);
                } else {
                  //show errors
                  const errors = assignCarrierResponse.field_errors || [];
                  let submissionError = {};
                  submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
                  submissionError._error = assignCarrierResponse.error_description;
                  //handle edge cases for errors here
                  throw new SubmissionError(submissionError);
                }
              });
          }
        } else {
          //proceed to the shipment details screen now that it's all set
          this.props.resetConfirmShipmentForm();
          this.context.router.push('/shipments/' + response.details.id);
        }
      });
    }
    //no vendor, do the financials and move on
    return this.performManualFinancialsAssignment(attrs, response);
  }

  getDispatchStatus(attrs, bookResponse) {
    const {hasFTL} = checkShipmentModes(this.props.selectedQuote?.mode);
    if (bookResponse.details.dispatch_jobs?.length > 0) {
      //get the most recent dispatch job
      const dispatchJob = last(sortBy(bookResponse.details.dispatch_jobs, 'created_at'));
      //poll for dispatch job status before redirecting
      dispatchJobInterval = setInterval(async () => {
        try {
          const jobResponse = await getDispatchJobDetails(bookResponse.details.id, dispatchJob.id);
          if (jobResponse.data?.status === JOB_STATUSES.SUCCESS) {
            clearInterval(dispatchJobInterval);
            this.redirectUser(attrs, bookResponse);
          } else if (jobResponse.data?.status === JOB_STATUSES.ERROR) {
            clearInterval(dispatchJobInterval);
            this.showBookingErrors({error_description: jobResponse.data?.error_message});
          }
        } catch (error) {
          console.error(error.error_message);
        }
        //get job status
      }, 3000);
    } else if (hasFTL) {
      dispatchResponseInterval = setInterval(async () => {
        const dispatchResponse = await this.validateDispatchResponse();
        const errors = dispatchResponse.errors;
        if (dispatchResponse.errors.length > 0) {
          this.showDispatchErrors(errors[errors.length - 1].error);
          this.setState({showDispatchErrorModal: true, isDispatching: false});
          clearInterval(dispatchResponseInterval);
          return;
        }
        clearInterval(dispatchResponseInterval);
        this.redirectUser(attrs, bookResponse);
      }, 3000);
    } else {
      this.redirectUser(attrs, bookResponse);
    }
  }

  async validateDispatchResponse() {
    const axiosResponseRateRequest = await getRatesByRequestId(this.context.router.location.query?.rateRequestId || '');
    const dispatches = axiosResponseRateRequest.data.dispatches;
    let dispatch = dispatches.length ? dispatches[0] : undefined;
    if (dispatches.length > 1) {
      dispatch = dispatches.reduce((a, b) => {
        return new Date(a?.created_at) > new Date(b?.created_at) ? a : b;
      }, undefined);
    }
    if (dispatch) {
      const axiosResponseDispatch = await getDispatchByDispatchId(dispatch?.id);
      return {
        response: axiosResponseDispatch?.data?.data,
        errors: axiosResponseDispatch?.data?.errors
      };
    }
    return {errors: [], response: {}};
  }

  redirectUser(attrs, bookResponse) {
    //if OPTION print_bol - go to the BOL page immediately and show the BOL
    if (attrs.option === 'print_bol') {
      this.props.resetConfirmShipmentForm();
      this.setState({showReratingModal: false, booking: false, isDispatching: false});
      //the BOL should have been automatically created
      //get all the documents for this shipment and filter down to the BOL
      return this.props.fetchDocuments(bookResponse.details.id).then((response) => {
        if (response.status === 200) {
          let documents = response.details.results;
          documents = documents.filter((e) => e.type === 'BOL');
          if (documents.length > 0) {
            documents = documents.sort(function (a, b) {
              return a.updated_at > b.updated_at ? -1 : 1;
            });
            //dispatch a change to the store so we can show a popup on the BOL page
            this.props.triggerSuccessfulDispatch(true);
            this.setState({
              showReratingModal: false,
              booking: false,
              showNewRatesModal: false
            });

            const BOLid = documents[0].id;
            //the most recent BOL will be the first one in the array
            this.context.router.push('/shipments/' + bookResponse.details.id + '/documents/' + BOLid);
          } else {
            this.setState({
              showReratingModal: false,
              booking: false,
              showNewRatesModal: false
            });
            //there wasnt a BOL
            this.context.router.push('/shipments/' + bookResponse.details.id);
          }
        } else {
          //there was an issue getting docs, redirect to details
          this.context.router.push('/shipments/' + bookResponse.details.id);
        }
      });
    }
    if (attrs.option === 'generate_parcel_label' || attrs.option === 'schedule_ftl_pickup') {
      // if OPTION generate_parcel_label or 'schedule_ftl_pickup - go to the shipment details page
      this.props.resetConfirmShipmentForm();
      this.context.router.push('/shipments/' + bookResponse.details.id);
    } else if (attrs.option === 'schedule_parcel_pickup') {
      this.setState({showSchedulePickupModal: true, showReratingModal: false, isDispatching: false});
    }
  }

  showBookingErrors(bookResponse) {
    //show errors
    const errors = bookResponse.field_errors || [];
    let submissionError = {};
    submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
    submissionError._error = bookResponse.error_description;
    //handle edge cases for errors here
    //show modal for dispatching error
    this.setState({
      showDispatchErrorModal: true,
      showReratingModal: false,
      showNewRatesModal: false,
      booking: false,
      error: bookResponse.error_description,
      isDispatching: false
    });
    throw new SubmissionError(submissionError);
  }

  showDispatchErrors(error) {
    this.setState({
      showDispatchErrorModal: true,
      showReratingModal: false,
      showNewRatesModal: false,
      booking: false,
      error: error,
      isDispatching: false
    });
  }

  awardNormalQuote(attrs, response) {
    //now award the quote to the shipment
    return this.props
      .awardQuote(response.details.id, {
        quote: this.props.selectedQuote.id
      })
      .then((awardResponse) => {
        if (awardResponse.status === 200) {
          //generate a BOL
          let BOLid = null;
          this.props.generateBOL(response.details.id).then((BOLResponse) => {
            BOLid = BOLResponse.details.id;
            //send the shipment booked emails (to the POCs)
            this.props.sendShipmentBooked(response.details.id);
            if (attrs.option === 'print_bol' && BOLid !== null) {
              //dispatch a change to the store so we can show a popup on the BOL page
              this.props.triggerSuccessfulDispatch(true);
              this.props.resetConfirmShipmentForm();

              //go straight to the BOL
              this.context.router.push('/shipments/' + response.details.id + '/documents/' + BOLid);
            } else {
              //proceed to the shipment details screen now that it's all set
              this.props.resetConfirmShipmentForm();
              this.context.router.push('/shipments/' + response.details.id);
            }
          });
        } else {
          //proceed to the shipment details screen now that it's all set
          this.props.resetConfirmShipmentForm();
          this.setState({showReratingModal: false, booking: false});
          this.context.router.push('/shipments/' + response.details.id);
        }
      });
  }

  awardMarketplaceQuote(attrs, response) {
    //TODO check if if quote here is created by current company. if so, need to manually add financials and move on
    //also check if there is no company on the quote, and do financials manually too
    return this.props
      .awardDeclineQuote(
        this.props.selectedQuote.spot_negotiation_quote.spot_negotiation,
        this.props.selectedQuote.id,
        {
          award: true
        }
      )
      .then((awardResponse) => {
        if (awardResponse.status === 200) {
          if (this.props.savedQuoteForCustomerFinancials) {
            // we want to manually update the customer financials on this shipment using the details saved here
            const currentCustomerAssignment = response.details.relationship_to_customer;

            currentCustomerAssignment.vendor_charge_line_items =
              this.props.savedQuoteForCustomerFinancials.charge_line_items;

            return this.props
              .editCarrierAssignment(response.details.id, currentCustomerAssignment.id, currentCustomerAssignment)
              .then((vendorAssignmentResponse) => {
                if (vendorAssignmentResponse.status === 200) {
                  //clear this out of the store
                  this.props.saveQuoteCopyForCustomerFinancials(null);

                  //if the user who created the quote matches a POC on the vendor, then assign them as the POC on the vendor assignment
                  if (this.props.selectedQuote.created_by_user) {
                    return this.handleUpdateVendorAssignment(response, attrs);
                  }
                  return this.handleGenerateBOL(response, attrs);
                }
                this.context.router.push('/shipments/' + response.details.id);
              });
          }
          if (this.props.selectedQuote.created_by_user) {
            return this.handleUpdateVendorAssignment(response, attrs);
          }
          return this.handleGenerateBOL(response, attrs);
        }
        //show errors
        this.setState({
          showReratingModal: false,
          booking: false
        });
        const errors = awardResponse.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
        submissionError._error = awardResponse.error_description;
        //handle edge cases for errors here
        throw new SubmissionError(submissionError);
      });
  }

  handleUpdateVendorAssignment(response, attrs) {
    //first look up the details of the carrier to get the POCs
    const companyId = this.props.selectedQuote.created_by_company.id;
    return this.props.searchForCarrierByID(companyId).then((companyResponse) => {
      if (
        companyResponse.status === 200 &&
        companyResponse.details.results &&
        companyResponse.details.results.length > 0
      ) {
        const vendorOnQuote = companyResponse.details.results && companyResponse.details.results[0];
        if (
          vendorOnQuote.point_of_contacts.filter((e) => e.user === this.props.selectedQuote.created_by_user).length > 0
        ) {
          const POCtoAssign = vendorOnQuote.point_of_contacts.filter(
            (e) => e.user === this.props.selectedQuote.created_by_user
          )[0].id;
          //we have the POC, now re-fetch the shipment details to get the current vendor assignment to update
          return this.props.getShipmentDetails(response.details.id, {}, true).then((newShipmentResponse) => {
            if (newShipmentResponse.status === 200) {
              const currentVendorAssignment = newShipmentResponse.details.relationship_to_vendor;
              currentVendorAssignment.vendor_point_of_contact = POCtoAssign;
              return this.props
                .editCarrierAssignment(
                  newShipmentResponse.details.id,
                  currentVendorAssignment.id,
                  currentVendorAssignment
                )
                .then((assignmentResponse) => {
                  if (assignmentResponse.status === 200) {
                    return this.handleGenerateBOL(newShipmentResponse, attrs);
                  }
                  console.warn('unable to assign a POC');
                  return this.handleGenerateBOL(newShipmentResponse, attrs);
                });
            }
            console.warn('unable to assign a POC');
            return this.handleGenerateBOL(response, attrs);
          });
        }
        console.warn('unable to assign a POC');
        return this.handleGenerateBOL(response, attrs);
      }
      console.warn('unable to assign a POC');
      return this.handleGenerateBOL(response, attrs);
    });
  }

  handleGenerateBOL(response, attrs) {
    let BOLid = null;
    this.props.generateBOL(response.details.id).then((BOLResponse) => {
      if (BOLResponse.status === 200) {
        BOLid = BOLResponse.details.id;
        //send the shipment booked emails (to the POCs)
        this.props.sendShipmentBooked(response.details.id);
        if (attrs.option === 'print_bol' && BOLid !== null) {
          //dispatch a change to the store so we can show a popup on the BOL page
          this.props.triggerSuccessfulDispatch(true);
          this.props.resetConfirmShipmentForm();

          //go straight to the BOL
          this.context.router.push('/shipments/' + response.details.id + '/documents/' + BOLid);
        } else {
          //proceed to the shipment details screen now that it's all set
          this.props.resetConfirmShipmentForm();
          this.context.router.push('/shipments/' + response.details.id);
        }
      } else {
        //send the shipment booked emails (to the POCs)
        this.props.sendShipmentBooked(response.details.id);
        //still proceeed to the shipment page since it's booked, even though BOL gen failed
        this.props.resetConfirmShipmentForm();
        this.context.router.push('/shipments/' + response.details.id);
      }
    });
  }

  performManualFinancialsAssignment(attrs, response) {
    const currentCustomerAssignment = response.details.relationship_to_customer;
    if (this.props.savedQuoteForCustomerFinancials && this.props.savedQuoteForCustomerFinancials.charge_line_items) {
      currentCustomerAssignment.vendor_charge_line_items = this.props.savedQuoteForCustomerFinancials.charge_line_items;
    } else {
      currentCustomerAssignment.vendor_charge_line_items = this.props.selectedQuote.charge_line_items;
    }

    return this.props
      .editCarrierAssignment(response.details.id, currentCustomerAssignment.id, currentCustomerAssignment)
      .then((vendorAssignmentResponse) => {
        if (vendorAssignmentResponse.status === 200) {
          this.props.saveQuoteCopyForCustomerFinancials(null);
          //still proceeed to the shipment page since it's booked, even though BOL gen failed
          this.props.resetConfirmShipmentForm();
          this.context.router.push('/shipments/' + response.details.id);
        } else {
          const errors = vendorAssignmentResponse.field_errors || [];
          let submissionError = {};
          submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
          submissionError._error = vendorAssignmentResponse.error_description;
          //handle edge cases for errors here
          throw new SubmissionError(submissionError);
        }
      });
  }

  renderDispatchErrorModal() {
    return (
      <div className="modal-terms-error">
        <h3>
          <i className="icon icon-Delayed" /> Problem dispatching with carrier
        </h3>
        <p>
          If you want to proceed with this booking, please check the response from the carrier below. For same day
          bookings you may need to book one day ahead to attempt to remove the error.
        </p>
        <p>
          If you are still having trouble contact your customer support representative at support@shipwell.com or (512)
          333-0898.
        </p>

        <h4>Message from carrier:</h4>

        <div className="well">{this.state.error}</div>
      </div>
    );
  }

  renderReratingModal() {
    return (
      <div className="modal-terms-error">
        <h3>
          <i className="icon icon-Delayed" /> Re-rating Required
        </h3>
        <p>
          Changing quote information such as locations and line items may result in a different rate. Please request
          re-rating to avoid unforeseen shipment variances.
        </p>
      </div>
    );
  }

  renderNewRatesModal() {
    return (
      <div>
        {' '}
        {this.state.loading ? (
          <ShipwellLoader loading={this.state.loading} loadingText={'Searching for quotes...'} />
        ) : this.state.booking ? (
          <ShipwellLoader loading={this.state.booking} loadingText={'Booking this quote...'} />
        ) : (
          <div>{this.props.selectedRFQ && this.props.selectedRFQ.quotes && this.renderQuotes()}</div>
        )}
      </div>
    );
  }

  async handleCreatePickup(values, {setSubmitting, setErrors}) {
    const shipment = submittedFormValues;

    try {
      let carrierCode = null;

      // only FedEx has carrier codes
      if (shipment.fedex_specific_options) {
        carrierCode = shipment.fedex_specific_options.carrier_code;
      }

      /** Create shipment pickup */
      const response = await createShipmentPickup(
        cleanPayload({
          ...values,
          provider_carrier_code: carrierCode,
          shipments: [shipment.id]
        })
      );

      if (response && response.body) {
        this.context.router.push(`/pickup/${response.body.id}/manifest`);
      }
    } catch (error) {
      console.error(error);
      // show formik field errors if present
      if (error?.field_errors) {
        const unpackedErrors = unpackErrors(error.field_errors, {});
        setErrors(unpackedErrors);
        const errorsToShow = Object.values(unpackedErrors);
        if (errorsToShow.length > 0) {
          this.props.setError('Error!', JSON.stringify(errorsToShow[0]));
        }
        // otherwise show the error returned from the api
      } else if (error.error_description) {
        const errorTitle = 'Error creating pickup';
        try {
          // try to format fedex errors if present
          const {errors} = JSON.parse(error.error_description);
          if (errors) {
            this.props.setError(
              errorTitle,
              <ul>
                {errors.map((error, i) => (
                  <li key={`error-${i}`}>{error.detail}</li>
                ))}
              </ul>
            );
          }
        } catch {
          // else show raw errors from backend
          this.props.setError(errorTitle, error.error_description);
        }
      }
    }

    setSubmitting(false);
  }

  async handleUpdatePickup(pickup) {
    const shipment = submittedFormValues;
    let carrierCode = null;
    const shipmentIds = pickup.shipments.map((s) => s.id);

    // only FedEx has carrier codes
    if (shipment.fedex_specific_options) {
      carrierCode = shipment.fedex_specific_options.carrier_code;
    }

    try {
      /** Update shipment pickup */
      const response = await updateShipmentPickup(
        pickup.id,
        cleanPayload({
          ...pickup,
          provider_carrier_code: carrierCode,
          shipments: [...shipmentIds, shipment.id]
        })
      );

      if (response && response.body) {
        this.context.router.push(`/pickup/${response.body.id}/manifest`);
      }
    } catch (error) {
      let errorDescription;
      if (error && error.error_description) {
        try {
          errorDescription = JSON.parse(error.error_description);
          if (errorDescription?.message) {
            this.props.showErrorToast(errorDescription.message);
          }
        } catch {
          errorDescription = error.error_description;
          this.props.showErrorToast(errorDescription);
        }
      }
    }

    this.setState({showSchedulePickupModal: false});
  }

  handleQuoteFilterChange(event) {
    this.setState({quoteFilter: event.target.value});
  }

  handleSelectQuote(quote) {
    this.props.selectQuote(quote);
    this.setState({isDispatching: true});
    return this.props
      .autoBookShipment(this.props.selectedShipment.id, {
        quote: quote.id,
        fedex_specific_options: submittedFormValues.fedex_specific_options
          ? {
              ...submittedFormValues.fedex_specific_options,
              packaging: this.state.parcelPackageType
            }
          : null,
        ups_specific_options: submittedFormValues.ups_specific_options
          ? {
              ...submittedFormValues.ups_specific_options,
              packaging: this.state.parcelPackageType
            }
          : null,
        usps_specific_options: submittedFormValues.usps_specific_options
          ? {
              ...submittedFormValues.usps_specific_options,
              packaging: this.state.parcelPackageType
            }
          : null
      })
      .then((bookResponse) => {
        if (bookResponse.status === 200) {
          this.setState({showNewRatesModal: false});
          this.props.setSuccess('Success', 'Shipment re-rated successfully!');
          return this.getDispatchStatus({option: this.state.option}, bookResponse);
        }
        return this.showBookingErrors(bookResponse);
      });
  }

  renderQuotes() {
    //filter down to just INSTANT quotes
    let quotes =
      this.props.selectedRFQ &&
      this.props.selectedRFQ.quotes.filter(
        (e) =>
          e.source_type === 'INSTANT' ||
          e.source_type === 'CARRIER_CONNECTION' ||
          // For Parcels we should include MANUAL source_type to display failed quotes
          (e.source_type === 'MANUAL' && this.props.selectedQuote?.mode?.code === 'PARCEL')
      );
    let hasMarketplaceQuotes = false;
    let hasManualQuotes = false;
    if (this.props.selectedRFQ && this.props.selectedRFQ.quotes.filter((e) => e.source_type === 'MANUAL').length > 0) {
      hasManualQuotes = true;
    }
    if (
      this.props.selectedRFQ &&
      this.props.selectedRFQ.quotes.filter((e) => e.source_type === 'PRIVATE_MARKETPLACE').length > 0
    ) {
      hasMarketplaceQuotes = true;
    }
    const aggregatedQuotes = [];
    //handle initial sorting
    if (this.state.quoteFilter === 'price') {
      quotes = sortBy(quotes, 'total');
    } else if (this.state.quoteFilter === 'speed') {
      quotes = quotes.sort(function (a, b) {
        const x = a.delivery_date;
        const y = b.delivery_date;
        if (!moment(x).isValid()) {
          return 1;
        }
        if (!moment(y).isValid()) {
          return -1;
        }
        return x < y ? -1 : 1;
      });
    } else if (this.state.quoteFilter === 'guaranteed') {
      quotes = quotes.sort(function (a, b) {
        if (
          (a.service_level?.description === 'Guaranteed' ||
            a.service_level?.description === 'Guaranteed Single-Hour Window' ||
            a.service_level?.description === 'Guaranteed by AM (Noon)') &&
          b.service_level?.description !== 'Guaranteed' &&
          b.service_level?.description !== 'Guaranteed Single-Hour Window' &&
          b.service_level?.description !== 'Guaranteed by AM (Noon)'
        ) {
          return -1;
        }
        if (
          (b.service_level?.description === 'Guaranteed' ||
            b.service_level?.description === 'Guaranteed Single-Hour Window' ||
            b.service_level?.description === 'Guaranteed by AM (Noon)') &&
          a.service_level?.description !== 'Guaranteed' &&
          a.service_level?.description !== 'Guaranteed Single-Hour Window' &&
          a.service_level?.description !== 'Guaranteed by AM (Noon)'
        ) {
          return 1;
        }
        return 0;
      });
    }
    //TODO - if quote doesnt have a carrier (e.g., manually created), this errors out
    for (var i = 0; i < quotes.length; i++) {
      if (aggregatedQuotes.length === 0) {
        aggregatedQuotes.push({
          carrier_id: quotes[i].carrier.id,
          carrier_name: quotes[i].carrier.dba_name,
          quotes: [quotes[i]],
          rateBelongsTo:
            quotes[i].created_by_company.id === this.props.company.id
              ? this.props.company.name
              : quotes[i].created_by_company.name
        });
      } else {
        let found = false;
        for (var j = 0; j < aggregatedQuotes.length; j++) {
          if (aggregatedQuotes[j].carrier_id === quotes[i].carrier.id) {
            //this carrier is in the list already
            aggregatedQuotes[j].quotes.push(quotes[i]);
            found = true;
          }
        }
        if (!found) {
          //add a new carrier to the aggregated list
          aggregatedQuotes.push({
            carrier_id: quotes[i].carrier.id,
            carrier_name: quotes[i].carrier.dba_name,
            quotes: [quotes[i]],
            rateBelongsTo:
              quotes[i].created_by_company.id === this.props.company.id
                ? this.props.company.name
                : quotes[i].created_by_company.name
          });
        }
      }
    }

    return (
      <div className="quote-flow__results quote-flow__results--ltl">
        <div>
          <div>
            <div>
              {quotes && quotes.length !== 0 ? (
                <div>
                  <Row className="pad-left">
                    <Col xs={3}>
                      <h5>Carrier</h5>
                    </Col>
                    <Col xs={2}>
                      <h5>Rate</h5>
                    </Col>
                    <Col xs={2}>
                      <h5>Details</h5>
                    </Col>
                    <Col xs={5}>
                      <Form className="pull-right" inline>
                        <FormGroup>
                          <FormControl
                            componentClass="select"
                            id="quoteFilter"
                            value={this.state.quoteFilter}
                            onChange={this.handleQuoteFilterChange}
                          >
                            <option value="price">Cheapest &nbsp;</option>
                            <option value="speed">Earliest Delivery&nbsp;&nbsp;&nbsp;</option>
                            <option value="guaranteed">Guaranteed</option>
                          </FormControl>
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="newshipment__quotes">
                        {aggregatedQuotes.map(
                          function (item, i) {
                            return (
                              <RateQuote
                                disableButtons={this.state.booking}
                                rateBelongsTo={item.rateBelongsTo}
                                key={i}
                                filter={this.state.quoteFilter}
                                one={this.props.selectedShipment}
                                quote={item}
                                buttonLabel="Book It"
                                selectQuoteFromList={this.handleSelectQuote}
                              />
                            );
                          }.bind(this)
                        )}
                      </div>{' '}
                    </Col>
                  </Row>
                </div>
              ) : (
                <div className="pad-top text-center">
                  <h3>No instant rates available for this quote.</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getPickupFilters() {
    const shipment = this.props.selectedShipment || {};
    const providerCode = getProviderCode(shipment);
    const filters = {};

    // only get pickups that occur on or before the planned date of shipment
    if (shipment.stops && shipment.stops[0] && shipment.stops[0].planned_date) {
      filters.scheduledDateLte = shipment.stops[0].planned_date;
    }
    // get pickups specific to parcel provider for the shipment
    if (providerCode) {
      filters.providerCode = providerCode;
    }

    return filters;
  }

  render() {
    const shipment = this.props.selectedShipment || {};
    return (
      <div className="confirmShipment content-wrapper">
        <section className="content">
          <ConfirmShipmentHeader />
          <ConfirmShipmentForm
            onSubmit={this.handleFormSubmit}
            searchForCarrierByID={this.props.searchForCarrierByID}
            getCarrierRelationshipsCarrierRelationshipId={this.props.getCarrierRelationshipsCarrierRelationshipId}
            selectedRelationship={this.props.selectedRelationship}
            selectedQuote={this.props.selectedQuote}
          />
        </section>
        <Modal
          show={Boolean(this.state.showDispatchErrorModal)}
          title=""
          className="info-modal-wrapper"
          children={this.renderDispatchErrorModal()}
          onClose={() => this.setState({showDispatchErrorModal: false, isDispatching: false})}
          footerComponent={null}
        ></Modal>
        <InfoModalWrapper
          bsSize="large"
          show={this.state.showReratingModal}
          title=""
          onHide={() => {
            this.setState({showReratingModal: false});
          }}
          children={this.renderReratingModal()}
          loadingAction={this.state.booking}
          primaryAction={{
            label: 'Re-rate Quote',
            action: () => {
              this.handleRerate();
            }
          }}
        />
        <Modal
          show={Boolean(this.state.showSchedulePickupModal)}
          title="Schedule Pickup"
          className="schedule-pickup-modal"
          onClose={() => this.context.router.push(`/shipments/${shipment.id}`)}
          footerComponent={null}
        >
          <SchedulePickup
            shipment={shipment}
            filters={this.getPickupFilters()}
            onCancel={() => this.context.router.push(`/shipments/${shipment.id}`)}
            onCreatePickup={() => this.setState({showSchedulePickupModal: false, showCreatePickupModal: true})}
            onUpdatePickup={this.handleUpdatePickup.bind(this)}
          />
        </Modal>
        <Modal
          show={Boolean(this.state.showCreatePickupModal)}
          title="Add Scheduled Pickup"
          footerComponent={null}
          onClose={() => {
            this.setState({
              showCreatePickupModal: false,
              showSchedulePickupModal: true
            });
          }}
        >
          <SchedulePickupForm
            providerCode={getProviderCode(shipment)}
            values={getPickupValues(shipment)}
            isReadOnlyAddress
            onCancel={() => {
              this.setState({
                showCreatePickupModal: false,
                showSchedulePickupModal: true
              });
            }}
            onSubmit={this.handleCreatePickup.bind(this)}
          />
        </Modal>
        {this.state.showNewRatesModal && (
          <InfoModalWrapper
            bsSize="large"
            show={this.state.showNewRatesModal}
            title="Instant Rates"
            onHide={() => {
              this.setState({showNewRatesModal: false});
            }}
            children={this.renderNewRatesModal()}
            loadingAction={this.state.booking}
          />
        )}
        {this.state.isDispatching && (
          <Loader show>
            <span className="text-xl">Dispatching to carrier...</span>
          </Loader>
        )}
      </div>
    );
  }
}

export default withStatusToasts(withLDConsumer()(ConfirmShipment));
