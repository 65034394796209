import {useState} from 'react';
import {connect} from 'react-redux';
import {SidebarContent} from '@shipwell/shipwell-ui';
import uniq from 'lodash/uniq';
import DocumentList from '../SidebarDocuments/DocumentList';
import UploadDocuments from '../SidebarDocuments/UploadDocuments';
import DocumentViewModal from '../SidebarDocuments/DocumentViewModal';
import {State} from 'App/reducers/types';
import {UploadDocumentVariablesType} from 'App/api/corrogo/utils/mergeDataFunctions';
import {useUserDetails, useUpdateDrayageLegDocuments, useDrayageLeg} from 'App/data-hooks';

const DocumentsController = ({
  companyId,
  legId,
  handleUploadDocument
}: {
  companyId: string;
  legId: string;
  handleUploadDocument: ({file, description, type}: UploadDocumentVariablesType) => void;
}): JSX.Element => {
  const [viewDocumentId, setViewDocumentId] = useState<string>('');
  const {
    context: {drayageLegDocuments},
    isLoading
  } = useDrayageLeg(legId);
  const documents = drayageLegDocuments.sort(
    (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  );
  const userIds = uniq(documents.map((document) => document.created_by_user_id || ''));
  const userDetails = useUserDetails(companyId, userIds);
  const {updateDrayageLegDocumentMutation, deleteDrayageLegDocumentMutation} = useUpdateDrayageLegDocuments(legId);

  const handleUpdateDocument = ({description, type}: {description: string; type: string}) => {
    const documentId = viewDocumentId;
    updateDrayageLegDocumentMutation.mutate({legId, documentId, description, type});
  };

  const handleDeleteDocument = (documentId: string) => {
    deleteDrayageLegDocumentMutation.mutate({legId, documentId});
  };

  return (
    <>
      <DocumentList
        isLoading={isLoading}
        documents={documents}
        users={userDetails}
        viewDocumentId={viewDocumentId}
        onDocumentClick={setViewDocumentId}
        onDocumentUpload={handleUploadDocument}
      />
      <DocumentViewModal
        document={documents.find((document) => document.id === viewDocumentId)}
        closeModal={() => setViewDocumentId('')}
        onUpdateDocument={handleUpdateDocument}
        onDeleteDocument={handleDeleteDocument}
      />
    </>
  );
};

const SidebarDocuments = ({
  companyId,
  legId,
  onClose,
  ...props
}: {
  companyId: string;
  legId: string;
  onClose: () => void;
}): JSX.Element => {
  const {uploadDrayageLegDocumentMutation} = useUpdateDrayageLegDocuments(legId);

  const handleUploadDocument = ({file, description, type}: UploadDocumentVariablesType) => {
    uploadDrayageLegDocumentMutation.mutate({legId, file, description, type});
  };

  return (
    <SidebarContent
      className="shipment__document-container"
      title="Documents"
      action={<UploadDocuments label="Upload" onDocumentUpload={handleUploadDocument} />}
      onClose={onClose}
      // spreading props are required for SidebarContent in the shipwell-ui
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <DocumentsController companyId={companyId} legId={legId} handleUploadDocument={handleUploadDocument} />
    </SidebarContent>
  );
};

SidebarDocuments.tabsRole = 'TabPanel';

export default connect((state: State) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  companyId: state.userCompany.company?.id || ''
}))(SidebarDocuments);
