import {DeprecatedButton, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import {FreightInvoiceDocumentMetadataDocumentTypeEnum} from '@shipwell/backend-core-sdk';
import {UploadDocumentVariablesType} from 'App/api/corrogo/utils/mergeDataFunctions';
import ShipmentDocumentForm from 'App/formComponents/forms/shipmentDocument';
import useToggle from 'App/utils/hooks/useToggle';

type PropTypes = {
  hideIcon?: boolean;
  label: string;
  onDocumentUpload: ({file, description, type}: UploadDocumentVariablesType) => void;
  allowedDocuments?: FreightInvoiceDocumentMetadataDocumentTypeEnum[];
};

export interface UploadDocumentType {
  file: File;
  description: string;
  type: string;
}

const UploadDocuments = ({hideIcon = false, label, onDocumentUpload, allowedDocuments}: PropTypes): JSX.Element => {
  const [showDocumentUpload, toggleShowDocumentUpload] = useToggle(false);

  const handleUploadDocument = ({file, description, type}: UploadDocumentType) => {
    onDocumentUpload({file, description, type});
    toggleShowDocumentUpload();
  };

  return (
    <>
      <DeprecatedButton
        variant="tertiary"
        icon={hideIcon ? null : <SvgIcon name="Upload" />}
        onClick={() => toggleShowDocumentUpload()}
      >
        {label}
      </DeprecatedButton>
      <Modal
        show={showDocumentUpload}
        title="Upload Document"
        footerComponent={null}
        onClose={() => toggleShowDocumentUpload()}
      >
        <ShipmentDocumentForm
          onCancel={() => toggleShowDocumentUpload()}
          onSubmit={handleUploadDocument}
          allowedDocuments={allowedDocuments}
        />
      </Modal>
    </>
  );
};

export default UploadDocuments;
