import {ChangeEvent, useEffect, useState} from 'react';
import moment from 'moment';
import {Popover, RadioGroup, RangeCalendar, SvgIcon, TextInput, useRangeCalendar} from '@shipwell/shipwell-ui';
import {
  DateOptionsEnums,
  defaultDates,
  getDefaultMode,
  getInputValue,
  getRadioButtonValue,
  getRadioOptions
} from 'App/containers/Dashboard/filters/dateSelectUtils';

export interface DateSelectFilterProps {
  selectedDates: [string, string];
  onSelect: (newDates: [string, string]) => void;
  pastOnly?: boolean;
}

// Ideally, this component was to be as "state-less" as possible.
// However, I found when switching between the radio button options and the range calendar input...
// ...that using state was the most painless way to ensure everything updated as expected.
export const DateSelectFilter = ({selectedDates, onSelect, pastOnly = false}: DateSelectFilterProps) => {
  // keeping rangeDates as seperate state to better display/hide values in UI when selecting a radio button or the RangeCalendar
  const [rangeDates, setRangeDates] = useState(selectedDates);

  const handleRangeCalendarChange = (dates: [string, string]) => {
    const getValidDateString = (dateString: string | null) => {
      const isValid = moment(dateString).isValid();
      return isValid ? moment(dateString).format('YYYY-MM-DD') : '';
    };
    const start = getValidDateString(dates[0]);
    const stop = getValidDateString(dates[1]);

    // feels bad to duplicate state like this, but as stated above, this became the most pain-free solution
    setRangeDates([start, stop]);
    onSelect([start, stop]);
    setRadioVal('');
  };

  const {mode, onModeSelect, onDateChange} = useRangeCalendar({
    dateStrings: rangeDates,
    onChange: handleRangeCalendarChange,
    // getting default mode when component renders via selected dates
    defaultMode: getDefaultMode(selectedDates)
  });

  useEffect(() => {
    // if filter value is cleared, clear local state (clearing inputs in the UI)
    if (!selectedDates[0] && !selectedDates[1]) {
      setRadioVal('');
      setRangeDates(['', '']);
    }
  }, [selectedDates]);

  const [radioVal, setRadioVal] = useState(getRadioButtonValue(selectedDates));

  const handleRadioSelect = (value: DateOptionsEnums) => {
    const dates: [string, string] = ['', ''];
    let [start, stop] = dates;
    switch (value) {
      case DateOptionsEnums.yesterday:
        start = defaultDates.yesterday;
        stop = defaultDates.yesterday;
        setRadioVal(DateOptionsEnums.yesterday);
        break;
      case DateOptionsEnums.today:
        start = defaultDates.today;
        stop = defaultDates.today;
        setRadioVal(DateOptionsEnums.today);
        break;
      case DateOptionsEnums.tomorrow:
        start = defaultDates.tomorrow;
        stop = defaultDates.tomorrow;
        setRadioVal(DateOptionsEnums.tomorrow);
        break;
      case DateOptionsEnums.last7Days:
        start = defaultDates.last7Days;
        stop = defaultDates.today;
        setRadioVal(DateOptionsEnums.last7Days);
        break;
      case DateOptionsEnums.last30Days:
        start = defaultDates.last30Days;
        stop = defaultDates.today;
        setRadioVal(DateOptionsEnums.last30Days);
        break;
      case DateOptionsEnums.next7Days:
        start = defaultDates.today;
        stop = defaultDates.next7Days;
        setRadioVal(DateOptionsEnums.next7Days);
        break;
      case DateOptionsEnums.next30Days:
        start = defaultDates.today;
        stop = defaultDates.next30Days;
        setRadioVal(DateOptionsEnums.next30Days);
        break;
      default:
        break;
    }
    onSelect([start, stop]);
    // selecting a radio button should clear the range calendar UI
    setRangeDates(['', '']);
  };

  return (
    <div>
      <RadioGroup
        name="date select"
        options={getRadioOptions(pastOnly)}
        value={radioVal}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleRadioSelect(e.target.value as DateOptionsEnums);
        }}
      />
      <Popover
        placement="bottom-start"
        trigger={({
          setIsOpen,
          setTriggerElement
        }: {
          setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
          setTriggerElement: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
        }) => (
          <TextInput
            ref={setTriggerElement}
            // Because this component is wrapped in forwardRef, the PropTypes do not get communicated to TS
            // @ts-ignore props
            label="Custom Date"
            prepend={<SvgIcon name="Calendar" />}
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
            value={getInputValue(rangeDates, mode)}
          />
        )}
      >
        <RangeCalendar
          mode={mode}
          dates={rangeDates}
          onModeSelect={onModeSelect}
          onChange={onDateChange}
          // if past dates only, today should be max date
          maxDate={pastOnly ? new Date() : undefined}
        />
      </Popover>
    </div>
  );
};
