import {string, object, array, number} from 'yup';
import {InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum, Company} from '@shipwell/backend-core-sdk';
import {nullableEmailSchemaField, nullablePhoneNumberSchemaField} from 'App/utils/yupHelpersTyped';

export const FreightInvoiceChargeLineItemSchema = object().shape({
  category: string().required('A category is required.'),
  description: string(),
  quantity: number().typeError('quantity must be a number.').required('A quantity is required.'),
  unit_amount: object().shape({
    value: number().typeError('quantity must be a number.').required('A unit amount is required.'),
    currency: string().typeError('quantity must be a number.').required('A currency is required.')
  })
});
export const FreightInvoiceDocumentSchema = object().shape({
  document_type: string().nullable().required('A document type is required.'),
  description: string().nullable().required('A description is required.'),
  file: string().nullable().required('A file is required.')
});
export const FreightInvoiceFormValidationSchema = object().shape({
  invoice_number: string().nullable().required('Invoice # is required.'),
  due_date_term: object().nullable().shape({
    id: string(),
    label: string(),
    date: string().nullable()
  }),
  due_date: string().nullable(),
  notes: string().nullable(),
  charge_line_items: array().of(FreightInvoiceChargeLineItemSchema),
  document_metadata: array().of(
    object().shape({
      document_type: string().nullable(),
      description: string().nullable(),
      file: string().nullable()
    })
  ),
  remit_to: object()
    .nullable()
    .shape({
      name: string().nullable(),
      contact_email: nullableEmailSchemaField(),
      contact_phone: nullablePhoneNumberSchemaField('contact_phone'),
      address: object().nullable().shape({
        id: string().nullable(),
        address_1: string().nullable(),
        address_2: string().nullable(),
        city: string().nullable(),
        state_province: string().nullable(),
        postal_code: string().nullable(),
        country: string().nullable(),
        latitude: number().nullable(),
        longitude: number().nullable(),
        timezone: string().nullable(),
        formatted_address: string().nullable(),
        created_at: string().nullable(),
        phone_number: string().nullable(),
        updated_at: string().nullable()
      })
    })
});
const getRemitToDefaultValues = (company?: Company) => ({
  name: company?.name || '',
  address: company?.billing_address || company?.mailing_address || undefined,
  contact_email: company?.billing_emails?.[0] || company?.primary_email || '',
  contact_phone: company?.primary_phone_number || ''
});

export const getFreightInvoiceFormDefaultValues = (
  referenceId: string,
  role: InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum,
  company?: Company
) => ({
  invoice_number:
    role === InvoicingShipmentsShipmentIdFreightInvoicesDataRoleEnum.ServiceProvider ? '' : `INV-${referenceId}-1`,
  due_date_term: '',
  due_date: '',
  notes: '',
  charge_line_items: [],
  document_metadata: [],
  remit_to: getRemitToDefaultValues(company),
  role
});
