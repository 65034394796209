/* eslint-disable camelcase */
import {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  Field,
  FormName,
  FormSection,
  formValueSelector,
  setSubmitFailed,
  getFormValues,
  stopSubmit,
  change
} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {SvgIcon, Toast} from '@shipwell/shipwell-ui';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import {PointOfContactsFields} from '../pointOfContactFields';
import AppointmentFields from './appointmentFields';
import {accessorialPickupBylocationType, accessorialDropOffBylocationType} from './utils/constants';
import {validation} from './utils/validation';
import {postAddressBook, addressBookAddressBookIdPut, locationTypesGet} from 'App/actions/addressBook';
import {ShipmentStopPurchaseOrders} from 'App/components/shipmentStopPurchaseOrders';
import InputField from 'App/formComponents/fields/input';
import SelectField from 'App/formComponents/fields/select';
import DateTimeField from 'App/formComponents/fields/dateTimePicker';
import TimePickerField from 'App/formComponents/fields/timePicker';
import AddressSearchField from 'App/formComponents/fields/addressSearch';
import CheckboxField from 'App/formComponents/fields/checkbox';
import TextAreaField from 'App/formComponents/fields/textArea';
import {fetchAccessorialCodes} from 'App/actions/shipments';
import {
  filterAccessorials,
  scrollToFirstErrorField,
  formatDateTime,
  unpackErrors,
  hasPlanningWindownFlag
} from 'App/utils/globals';
import {getScheduleForTime} from 'App/utils/globalsTyped';
import {getOpenBusinessDay, isOpenBusinessDay} from 'App/formComponents/formSections/shipmentStopFields/utils/typed';
import {isCustomFieldOwner} from 'App/utils/customData';
import {getCustomDataPath} from 'App/utils/customDataPath';
import {updatePurchaseOrder} from 'App/containers/purchaseOrders/actions/async';
import {FlexBox} from 'App/components/Box';
import {AddressHoursTooltip} from 'App/containers/addressBook/list';
import {shipmentsShipmentIdGet} from 'App/actions/shipmentdetails';
import './styles.scss';
import {
  CREATE_ADDRESS_BOOK_PERMISSION,
  UPDATE_ADDRESS_BOOK_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';
import {PermissionsFallback} from 'App/components/permissions/PermissionsFallback';

/**
 * Fields for shipment stop
 */
class ShipmentStopFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      saveLocationSuccess: false,
      saveLocationError: false,
      saveLocationErrorMessage: null,
      pickupDetails: null,
      pickupType: 'fedex_location',
      showSchedulePickupModal: false,
      showNewSchedulePickupModal: false,
      showRemovePurchaseOrderErrorToast: false,
      removePurchaseOrderError: {},
      addressBookDetails: null
    };
  }

  static defaultProps = {
    slimForm: false,
    externalForm: false,
    name: 'stop',
    index: 0,
    modes: {
      hasFTL: false
    },
    shipmentStatus: null,
    accessorials: [],
    locationTypes: [],
    shipmentStop: {
      location: {
        id: null,
        facility_id: null,
        created_using_address_book_entry_id: null,
        address: {
          formatted_address: ''
        }
      }
    },
    shipmentStops: []
  };

  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    const {company, defaultOrigin, shipmentStop, form, name, index, router} = this.props;
    // for cloning shipments we need to set the addressBookId on the location so we can track from where it was created
    if (shipmentStop?.location?.created_using_address_book_entry_id) {
      shipmentStop.location.addressBookId = shipmentStop?.location?.created_using_address_book_entry_id;
    }
    const isPickup = shipmentStop.is_pickup && index === 0;
    const addressValues = shipmentStop.id || Object.keys(shipmentStop?.location?.address || {}).length;

    if (company && company.id) {
      this.requestFieldOption();
    }
    if (defaultOrigin && isPickup && !addressValues) {
      this.setAddressBookDetails(defaultOrigin);
    }
    const justConsolidatedOrders = router?.location?.query?.ordersConsolidated;
    if (!justConsolidatedOrders) {
      this.props.dispatch(
        change(
          form,
          name,
          Object.assign({}, shipmentStop, {
            'schedule-select': getScheduleForTime(shipmentStop)
          })
        )
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {company, shipmentStop, index, form, name} = this.props;
    const {addressBookDetails} = this.state;
    const isPickup = shipmentStop.is_pickup && index === 0;
    const defaultOrigin = this.props.defaultOrigin !== prevProps.defaultOrigin && this.props.defaultOrigin;
    const addressValues = shipmentStop.id || Object.keys(shipmentStop?.location?.address || {}).length;

    if (company && company.id && company !== prevProps.company) {
      this.requestFieldOption();
    }
    if (defaultOrigin && isPickup && !addressValues) {
      this.setAddressBookDetails(this.props.defaultOrigin);
    }

    // if user updates planned date, update start/stop time based on address book entry
    const plannedDate = shipmentStop.planned_date;
    const prevPlannedDate = prevProps.shipmentStop.planned_date;
    if (plannedDate !== prevPlannedDate && addressBookDetails) {
      const plannedDateDay = getOpenBusinessDay(addressBookDetails, plannedDate);
      if (addressBookDetails?.[`open_${plannedDateDay}`]) {
        this.props.dispatch(
          change(
            form,
            name,
            Object.assign({}, shipmentStop, {
              planned_time_window_start: addressBookDetails?.[`${plannedDateDay}_open_time`],
              planned_time_window_end: addressBookDetails?.[`${plannedDateDay}_close_time`]
            })
          )
        );
      }
    }
  }

  requestFieldOption() {
    this.props.dispatch(fetchAccessorialCodes());
    this.props.dispatch(locationTypesGet());
  }

  getAccessorialOptions(accessorials, isPickup, isDropoff) {
    const {hasDrayage} = this.props?.modes;

    // @todo Fix accessorial deviations
    if (hasDrayage && accessorials && isPickup && isDropoff) {
      return [...accessorials.drayagePickup, ...accessorials.drayageDropoff];
    }
    if (hasDrayage && accessorials && isPickup) {
      return [...accessorials.drayagePickup];
    }
    if (hasDrayage && accessorials) {
      return [...accessorials.drayageDropoff];
    }

    if (accessorials && isPickup && isDropoff) {
      return [...accessorials.pickup, ...accessorials.dropoff];
    }
    if (accessorials && isPickup) {
      return [...accessorials.pickup];
    }
    if (accessorials) {
      return [...accessorials.dropoff];
    }
    return [];
  }

  /**
   * Format date/time to location timezone
   * @param {Date} date
   */
  handleArrivalCompletionDates(date) {
    const {shipmentStop} = this.props;
    const {location = {}} = shipmentStop;
    const timezone = (location.address && location.address.timezone) || moment.tz.guess(true);
    const timezoneAbbr = moment.tz(timezone).format('Z');
    const formattedDate = moment(date).format('MMM D, YYYY HH:mm:ss');

    return new Date(`${formattedDate} ${timezoneAbbr}`).toUTCString();
  }

  getArrivalCompletionTimes(shipmentStop) {
    const {location = {}} = shipmentStop;
    const timezone = (location.address && location.address.timezone) || moment.tz.guess(true);

    let arrived_at = null;
    let completed_at = null;

    if (shipmentStop.arrived_at && moment(shipmentStop.arrived_at).isValid()) {
      arrived_at = formatDateTime(shipmentStop.arrived_at, true, timezone);
    }
    if (shipmentStop.completed_at && moment(shipmentStop.completed_at).isValid()) {
      completed_at = formatDateTime(shipmentStop.completed_at, true, timezone);
    }
    return {arrived_at, completed_at};
  }

  setAddressBookDetails(address) {
    const {form, name, shipmentStop} = this.props;
    const {is_pickup: isPickup, is_dropoff: isDropoff, planned_date: plannedDate} = shipmentStop;

    if (!address) {
      return;
    }

    const accessorials =
      address.accessorials &&
      address.accessorials.filter((acc) => {
        if (isPickup && isDropoff) {
          return acc.type === 'pickup' || acc.type === 'dropoff' || acc.type === 'inside-delivery';
        }
        if (isPickup) {
          return acc.type === 'pickup';
        }
        if (isDropoff) {
          return acc.type === 'dropoff' || acc.type === 'inside-delivery';
        }
      });
    const {arrived_at, completed_at} = this.getArrivalCompletionTimes(shipmentStop);

    this.setState({
      addressBookDetails: address
    });
    const plannedDateDay = getOpenBusinessDay(address, plannedDate);

    this.props.dispatch(
      change(
        form,
        name,
        Object.assign({}, shipmentStop, {
          accessorials: accessorials || [],
          // if business is open on date, use open and close time, otherwise default to dock hours
          ...(address?.[`open_${plannedDateDay}`]
            ? {
                planned_time_window_start: address?.[`${plannedDateDay}_open_time`],
                planned_time_window_end: address?.[`${plannedDateDay}_close_time`]
              }
            : null),
          arrived_at: arrived_at,
          completed_at: completed_at,
          instructions: address.notes,
          appointment_type: address.default_appointment_type,
          location: Object.assign({}, shipmentStop.location, {
            facility_id: address.facility_id,
            addressBookId: address.id,
            created_using_address_book_entry_id: address.id,
            dock_hours_end: address.dock_hours_end,
            dock_hours_start: address.dock_hours_start,
            notes: address.notes,
            phone_number: address.phone_number,
            point_of_contacts: address.point_of_contacts,
            // TITAN-4210 address.id must be null to correctly trigger location updated webhook
            address: {...address.address, id: null},
            location_type: address.location_type,
            location_name: address.location_name,
            external_reference: address.external_reference ?? address.location_name,
            company_name: address.company_name
          })
        })
      )
    );
  }

  /**
   * Hander for location_type field selector
   * @param  {String} stopItem       Select field name
   * @param  {Number} index          Index of field in stops list
   * @param  {Number} event          Value of selected item
   */
  handleLocationTypeChange(e, value) {
    e.preventDefault();
    const {form, name, shipmentStop, accessorials} = this.props;
    const {is_pickup: isPickup, is_dropoff: isDropoff} = shipmentStop;
    let accessorialBylocationType;

    if (isPickup && isDropoff) {
      accessorialBylocationType = accessorialPickupBylocationType;
    } else if (isPickup) {
      accessorialBylocationType = accessorialPickupBylocationType;
    } else {
      accessorialBylocationType = accessorialDropOffBylocationType;
    }

    const accessorialTypes = this.getAccessorialOptions(accessorials, isPickup, isDropoff);
    const accessorial = accessorialTypes.find(
      (accessorial) => accessorial?.id === accessorialBylocationType?.[value?.id]
    );
    const defaultAccessorials = accessorial ? [accessorial] : [];
    const stopValues = Object.assign({}, shipmentStop, {
      accessorials: defaultAccessorials,
      location: Object.assign({}, shipmentStop.location, {location_type: value}),
      location_type: value
    });
    stopValues.location_type = value;
    this.props.dispatch(change(form, name, stopValues));
  }

  formatLocationName(address, companyName = null) {
    const {city, country, postal_code: postalCode, latitude, longitude, state_province: stateProvince} = address;
    const latLonOrCity = !city && latitude && longitude ? `${latitude}, ${longitude}` : city;
    const locationString = [latLonOrCity, stateProvince, postalCode, country].filter(Boolean).join(', ');
    //backend allows 256 chars
    return (companyName ? `${companyName} - ${locationString}` : locationString).slice(0, 257);
  }

  /**
   *
   * Hander for setting location name
   * @param  {String} stopItem       Select field name
   * @param  {Number} index          Index of field in stops list
   * @param  {Number} address        Address
   */
  handleAddressChange(address) {
    const {form, name, shipmentStop} = this.props;
    const isAddressBookEntry = address.company && address.company.accessorials;
    const {arrived_at, completed_at} = this.getArrivalCompletionTimes(shipmentStop);

    if (isAddressBookEntry) {
      address.preventDefault();
      this.setAddressBookDetails(address.company);
      return;
    }
    this.setState({
      addressBookDetails: null
    });
    // do not remove this code or you will cause an infinite loop. By dispatching an address changed event
    // this method gets called a second time, which then sees that the formatted address is the same
    // and it leaves the function.
    if (shipmentStop?.location?.address?.formatted_address === address?.formatted_address) {
      return;
    }
    /// this is not an address book entry so we need to clear out a bunch of the fields
    const locationName = this.formatLocationName(address);
    this.props.dispatch(
      change(
        form,
        name,
        Object.assign({}, shipmentStop, {
          arrived_at: arrived_at,
          completed_at: completed_at,
          // the location object holds on to the old references from the previous-possibly address book entry. We need to default
          // the address book values for non-address-book-entry fields
          location: Object.assign({}, shipmentStop.location, {
            appointment_type: null,
            addressBookId: null,
            location_name: locationName,
            external_reference: locationName,
            company_name: null,
            location_type: null,
            facility_id: null,
            created_using_address_book_entry_id: null,
            // need to default the point of contacts for formik here
            point_of_contacts: [
              {
                id: undefined,
                first_name: '',
                last_name: '',
                full_name: '',
                job_title: '',
                phone_number: '',
                preferences: {}
              }
            ]
          })
        })
      )
    );
  }

  handleCompanyNameChangeDebounced = debounce(({target}) => {
    const {value: companyName = {}} = target;
    const {form, name, shipmentStop} = this.props;
    const locationName = this.formatLocationName(shipmentStop.location.address, companyName);
    this.props.dispatch(
      change(form, name, {
        ...shipmentStop,
        location: {...shipmentStop.location, location_name: locationName, external_reference: locationName}
      })
    );
  }, 500);

  handleCompanyNameChange(e) {
    const {shipmentStop} = this.props;
    const isAddressBook = !!shipmentStop.location?.addressBookId;
    // only update location_name and external_reference if not a saved address
    if (!isAddressBook) {
      this.handleCompanyNameChangeDebounced(e);
    }
  }

  handleSaveAddress(e) {
    e.preventDefault();
    const {shipmentStop, form, fields, index} = this.props;
    const errors = validation(shipmentStop);
    const hasErrors = Object.keys(errors).length;
    const locationId = shipmentStop.location && shipmentStop.location.addressBookId;
    let saveAddressRequest = null;

    this.setState({isSubmitting: true});
    if (hasErrors) {
      const stopErrors = [];
      stopErrors[index] = errors;

      this.setState({isSubmitting: false});
      // Manually trigger field validation errors
      this.props.dispatch(stopSubmit(form, {stops: stopErrors}));
      this.props.dispatch(setSubmitFailed(form, ...fields));

      scrollToFirstErrorField();
    } else if (!hasErrors && !locationId) {
      saveAddressRequest = this.props.dispatch(postAddressBook(shipmentStop));
    } else {
      saveAddressRequest = this.props.dispatch(addressBookAddressBookIdPut(locationId, shipmentStop));
    }

    if (saveAddressRequest) {
      saveAddressRequest.then(this.handleAddressSaveSuccess.bind(this)).catch(this.handleAddressSaveError.bind(this));
    }
  }

  handleAddressSaveError({error_description}) {
    this.setState(
      {
        isSubmitting: false,
        saveLocationError: true,
        saveLocationErrorMessage: error_description
      },
      () => {
        setTimeout(() => this.setState({saveLocationError: false}), 10000);
      }
    );
  }

  handleAddressSaveSuccess({status, details}) {
    if (status === 200) {
      this.setAddressBookDetails(details);
    }
    this.setState(
      {
        isSubmitting: false,
        saveLocationSuccess: true
      },
      () => {
        setTimeout(() => this.setState({saveLocationSuccess: false}), 3000);
      }
    );
  }

  handlePickupOptionSelect(selection) {
    const {pickupType} = this.state;

    if (pickupType !== selection) {
      this.setState({pickupType: selection});

      if (selection === 'use_pickup') {
        this.setState({showSchedulePickupModal: true});
      } else if (selection === 'new_pickup') {
        this.setState({showNewSchedulePickupModal: true});
      }
    }
  }

  handleRemovePurchaseOrder = async (order) => {
    const response = await this.props.dispatch(
      updatePurchaseOrder(order?.id, {...order, shipment: null, origin_stop: null, destination_stop: null})
    );
    if (response.status < 400) {
      const updatedLineItems = this.props.shipment.line_items.filter((li) => li.purchase_order !== order.id);
      this.props.dispatch(shipmentsShipmentIdGet(this.props.shipment.id, {}));
      // makes sure the form doesn't hold onto line items belonging to the removed order when submitting
      return this.props.dispatch(change(this.props.form, 'line_items', updatedLineItems));
    }
    this.setState({
      removePurchaseOrderError: unpackErrors(response.field_errors, {}),
      showRemovePurchaseOrderErrorToast: true
    });
  };

  // clears the necessary appointment fields if no appointment is needed
  handleAppointmentRequiredChange = (e) => {
    const {checked} = e.target;
    const {form, name, shipmentStop} = this.props;
    if (!checked) {
      const newStop = {
        ...shipmentStop,
        appointment_type: null,
        planned_time_window_start:
          shipmentStop.location?.address?.dock_hours_start || moment('8:00:00', 'HH:mm:ss').format('HH:mm:ss'),
        planned_time_window_end:
          shipmentStop.location?.address?.dock_hours_end || moment('18:00:00', 'HH:mm:ss').format('HH:mm:ss'),
        'schedule-select': null
      };
      this.props.dispatch(change(form, name, newStop));
    }
  };

  render() {
    const {pickupDetails, pickupType, isSubmitting, saveLocationSuccess, saveLocationError, saveLocationErrorMessage} =
      this.state;
    const {
      name,
      index,
      modes = {},
      accessorials,
      locationTypes,
      shipmentStop,
      shipmentStops,
      purchaseOrders,
      slimForm,
      externalForm,
      detailsForm,
      isConfirmationForm,
      form,
      customFields,
      company,
      shipment
    } = this.props;
    const {hasFTL, hasLTL, hasVLTL, hasDrayage, hasParcel, hasIntermodal} = modes;

    let shipmentId = this.props.shipmentId;
    if (!shipmentId && this.context.router) {
      shipmentId = this.context.router.params.shipment_id;
    }
    const {is_pickup: isPickup, is_dropoff: isDropoff, location = {}} = shipmentStop;
    const accessorialOptions = this.getAccessorialOptions(accessorials, isPickup, isDropoff);
    const addressLabel = isPickup && isDropoff ? 'Delivery and Pickup' : isPickup ? 'Pickup' : 'Delivery';
    const stopType = isPickup && isDropoff ? 'Delivery and Pickup' : isPickup ? 'Pickup' : 'Delivery';
    const includesPickupOrders =
      purchaseOrders[shipmentStop.id] &&
      purchaseOrders[shipmentStop.id].some((order) => order.origin_stop === shipmentStop.id);
    const includesDropoffOrders =
      purchaseOrders[shipmentStop.id] &&
      purchaseOrders[shipmentStop.id].some((order) => order.destination_stop === shipmentStop.id);
    const timezone = (location.address && location.address.timezone) || moment.tz.guess(true);
    const locationId = shipmentStop.location?.addressBookId;
    const isTruckload = hasFTL || hasLTL || hasVLTL;
    const showDeliveryDate =
      (isConfirmationForm || detailsForm || slimForm || externalForm) && (isPickup || (isTruckload && !isPickup));
    const showManualFormDeliveryDate = !slimForm && !detailsForm && !isConfirmationForm && !externalForm;
    const showAppointmentForm =
      Boolean(hasFTL || hasDrayage || ((hasLTL || hasVLTL) && shipmentStop?.appointment_needed)) &&
      !shipmentStop?.location?.facility_id;

    return (
      <FormSection name={name}>
        {(slimForm || !(pickupDetails && isPickup && pickupType !== 'fedex_location')) && (
          <Field
            req
            allowZipOnly={slimForm}
            name="location.address"
            label={isPickup && hasParcel ? 'From Address' : addressLabel}
            component={AddressSearchField}
            searchAddressBook={!externalForm}
            addr={shipmentStop?.location?.address?.formatted_address}
            onChange={this.handleAddressChange.bind(this)}
          />
        )}
        <Grid container spacing={2}>
          {(!slimForm || hasParcel) && (
            <Grid item xs={12}>
              <Field
                req={form && form !== 'newShipmentForm' && !hasParcel}
                name="location.company_name"
                label="Company Name"
                placeholder="Company name"
                component={InputField}
                onChange={this.handleCompanyNameChange.bind(this)}
              />
            </Grid>
          )}
          {detailsForm && !hasParcel && (
            <Grid item xs={12}>
              <Field
                req
                name="location.location_name"
                label="Location name"
                placeholder="Location name"
                component={InputField}
              />
            </Grid>
          )}
          {!hasDrayage && !hasParcel && (
            <Grid item xs={12}>
              <Field
                req
                name="location.location_type"
                label="Location Type"
                placeholder="Select one"
                options={locationTypes}
                component={SelectField}
                onChange={this.handleLocationTypeChange.bind(this)}
              />
            </Grid>
          )}
          {(showDeliveryDate || showManualFormDeliveryDate) && !hasPlanningWindownFlag(this.props.flags) ? (
            <Grid item xs={12}>
              <FlexBox gap="m" items="center">
                <Field
                  req={!hasDrayage && (hasFTL || hasParcel || isPickup) && form && form !== 'newShipmentForm'}
                  name="planned_date"
                  label="On Date"
                  // disable for shipments that have gone through P44 rating
                  disabled={!isPickup && shipment?.most_recently_awarded_quote?.source_type === 'INSTANT'}
                  component={DateTimeField}
                  normalize={(value) => value && moment(value).format('YYYY-MM-DD')}
                />
                {this.state.addressBookDetails && !this.state.addressBookDetails.facility_id ? (
                  <AddressHoursTooltip addressBookEntry={this.state.addressBookDetails}>
                    <SvgIcon name="Time" />
                  </AddressHoursTooltip>
                ) : null}
              </FlexBox>
              {this.state.addressBookDetails && !this.state.addressBookDetails.facility_id ? (
                <div className="-mt-4">
                  {shipmentStops[index]?.planned_date &&
                  !isOpenBusinessDay(this.state.addressBookDetails, shipmentStops[index]?.planned_date) ? (
                    <span className="text-sw-warning">
                      This location is not open on the selected date. The hours below are from the next open day.
                    </span>
                  ) : null}
                </div>
              ) : null}
            </Grid>
          ) : null}
          {detailsForm && !hasFTL && !hasParcel ? (
            <>
              <Grid item xs={12} md={6}>
                <Field name="override_eta" label="Override current ETA" component={CheckboxField} />
              </Grid>
              <Grid item xs={0} md={6} />

              {shipmentStop.override_eta && !isPickup && !hasPlanningWindownFlag(this.props.flags) && (
                <>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="planned_date"
                      label="New ETA"
                      component={DateTimeField}
                      normalize={(value) => value && moment(value).format('YYYY-MM-DD')}
                    />
                  </Grid>
                  <Grid item xs={0} md={6} />
                </>
              )}
            </>
          ) : null}
          {/** if there is a facility ID then the user has to go to the dock scheduling modal to book an appointment */}
          {Boolean(hasDrayage || hasLTL || hasVLTL) && !shipmentStop?.location?.facility_id ? (
            <>
              {hasLTL || hasVLTL ? (
                <>
                  <Grid item xs={12} md={6}>
                    <Field
                      name="appointment_needed"
                      label="Appointment Required"
                      component={CheckboxField}
                      onChange={this.handleAppointmentRequiredChange.bind(this)}
                    />
                  </Grid>
                  <Grid item xs={0} md={6} />
                </>
              ) : null}
            </>
          ) : null}
          {!hasPlanningWindownFlag(this.props.flags) && (
            <>
              <Grid item xs={12} md={6}>
                <Field
                  req={!hasDrayage}
                  name="planned_time_window_start"
                  label={index === 0 ? 'Ready At' : 'Opens At'}
                  placeholder="Select Time"
                  component={TimePickerField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  req={!hasDrayage}
                  name="planned_time_window_end"
                  label="Closes At"
                  placeholder="Select Time"
                  component={TimePickerField}
                />
              </Grid>
            </>
          )}

          {hasPlanningWindownFlag(this.props.flags) && (
            <>
              <Grid item xs={12} md={6}>
                <Field
                  req={!hasDrayage}
                  name="planning_window.start"
                  label="Planning Window Start"
                  placeholder="Select Time"
                  time
                  component={DateTimeField}
                />
                {shipmentStops[index]?.planning_window?.start &&
                this.state.addressBookDetails &&
                !isOpenBusinessDay(this.state.addressBookDetails, shipmentStops[index]?.planning_window?.start) ? (
                  <span className="text-sw-warning">
                    This location is not open on the selected date. The hours below are from the next open day.
                  </span>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  req={!hasDrayage}
                  name="planning_window.end"
                  label="Planning Window End"
                  placeholder="Select Time"
                  time
                  component={DateTimeField}
                />
                {shipmentStops[index]?.planning_window?.end &&
                this.state.addressBookDetails &&
                !isOpenBusinessDay(this.state.addressBookDetails, shipmentStops[index]?.planning_window?.end) ? (
                  <span className="text-sw-warning">
                    This location is not open on the selected date. The hours below are from the next open day.
                  </span>
                ) : null}
              </Grid>
            </>
          )}
        </Grid>

        {showAppointmentForm ? (
          <FormName>
            {({form}) => (
              <AppointmentFields
                modes={modes}
                form={form}
                name={name}
                toggle={shipmentStop['schedule-select']}
                slimForm={slimForm}
              />
            )}
          </FormName>
        ) : null}

        <Grid container spacing={2} className="mb-5 sw-border-b">
          {detailsForm && !hasParcel && (
            <>
              {(hasFTL || hasLTL || hasVLTL || hasDrayage || hasIntermodal) && (
                <Grid item xs={12} md={6}>
                  <Field
                    name="confirmed_arrival_at"
                    label="Actual Arrival"
                    placeholder="Actual Arrival"
                    time
                    min={null}
                    step={30}
                    timezone={timezone}
                    component={DateTimeField}
                    normalize={this.handleArrivalCompletionDates.bind(this)}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <Field
                  name="confirmed_departure_at"
                  label="Completed At"
                  placeholder="Completed At"
                  time
                  min={null}
                  step={30}
                  timezone={timezone}
                  component={DateTimeField}
                  normalize={this.handleArrivalCompletionDates.bind(this)}
                />
              </Grid>
            </>
          )}
          {index > 0 && index < shipmentStops.length - 1 && (
            <Grid item xs={6}>
              <div className="stop-type-fields flex justify-between">
                <Field
                  name="is_pickup"
                  label="Is Pickup"
                  disabled={(!isDropoff && isPickup) || includesPickupOrders}
                  component={CheckboxField}
                />
                <Field
                  name="is_dropoff"
                  label="Is Delivery"
                  disabled={(!isPickup && isDropoff) || includesDropoffOrders}
                  component={CheckboxField}
                />
              </div>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          {!hasParcel && (
            <Grid item xs={12}>
              <Field
                multi
                name="accessorials"
                label={`${stopType} Accessorials`}
                labelKey="description"
                placeholder="Select accessorial"
                options={accessorialOptions}
                component={SelectField}
              />
            </Grid>
          )}
          {!slimForm && !hasParcel && (
            <Grid item xs={12}>
              <Field
                name="instructions"
                label={`${stopType} Instructions`}
                placeholder="e.g., Go to dock #4"
                minRows={2}
                component={TextAreaField}
              />
            </Grid>
          )}
        </Grid>
        {customFields?.length > 0 && (
          <Grid container spacing={2}>
            {customFields.map((cf) => {
              const isOwner = isCustomFieldOwner(cf.company, company.id);
              return (
                <Grid item xs={6} key={`${cf.id}-${shipmentStop.id}`}>
                  <Field
                    label={cf.label}
                    name={`custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.ShipmentStop)}.${cf.id}`}
                    req={cf.required && isOwner}
                    disabled={!isOwner}
                    component={InputField}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {shipmentStop.related_orders ? (
          <ShipmentStopPurchaseOrders
            shipmentStop={shipmentStop}
            purchaseOrders={purchaseOrders[shipmentStop.id] || []}
            shipment={shipment}
            onRemovePurchaseOrder={this.handleRemovePurchaseOrder}
          />
        ) : null}
        {((!slimForm && !hasParcel) || (!slimForm && hasParcel && (!isPickup || pickupType === 'fedex_location'))) && (
          <Fragment>
            <div className="shipment-stop-subheader">
              <span>Contact Information</span>
            </div>
            <Grid item xs={12}>
              <FormName>
                {({form}) => (
                  <PointOfContactsFields externalForm={externalForm} form={form} name="location.point_of_contacts" />
                )}
              </FormName>
            </Grid>

            <PermissionsFallback
              permissions={[locationId ? UPDATE_ADDRESS_BOOK_PERMISSION : CREATE_ADDRESS_BOOK_PERMISSION]}
            >
              {!externalForm && (
                <div className="shipment-stop-footer">
                  {saveLocationSuccess && (
                    <span className="text-success">
                      Successfully {locationId ? ' updated' : ' saved'} this address.
                    </span>
                  )}
                  {saveLocationError ? <span className="error-text">{saveLocationErrorMessage}</span> : null}
                  <button
                    className="btn btn-primary"
                    disabled={isSubmitting}
                    onClick={this.handleSaveAddress.bind(this)}
                  >
                    {isSubmitting ? <i className="icon icon-Restart rotate" /> : <i className="icon-Save pad-right" />}
                    {locationId ? ' Update ' : ' Save to '} Address Book
                  </button>
                </div>
              )}
            </PermissionsFallback>
          </Fragment>
        )}
        <Toast
          show={this.state.showRemovePurchaseOrderErrorToast}
          title="Error"
          variant="error"
          anchor="top-right"
          onClose={() => this.setState({showRemovePurchaseOrderErrorToast: false})}
        >
          {get(
            this.state,
            'removePurchaseOrderError.shipment',
            'There was an error removing the purchase order from the shipment.'
          )}
        </Toast>
      </FormSection>
    );
  }
}

export default compose(
  withLDConsumer(),
  connect((state, props) => ({
    company: state.auth.company,
    accessorials: filterAccessorials(state.shipments.accessorialCodes || []),
    locationTypes: state.addresses.locationTypes,
    purchaseOrders: state.purchaseOrders.details.purchaseOrdersByStop,
    purchaseOrdersByShipment: state.purchaseOrders.details.purchaseOrdersByShipment,
    defaultOrigin:
      state.addresses.addressbook &&
      state.addresses.addressbook.results &&
      state.addresses.addressbook.results.find((address) => address.is_default_origin),
    fields:
      state.form[props.form] &&
      state.form[props.form].registeredFields &&
      Object.keys(state.form[props.form].registeredFields).filter((key) => key.startsWith(props.name)),
    shipment: getFormValues(props.form)(state),
    shipmentId: formValueSelector(props.form)(state, 'id'),
    shipmentStop: formValueSelector(props.form)(state, props.name) || getFormValues(props.form)(state),
    shipmentStops: formValueSelector(props.form)(state, 'stops')
  })),
  withRouter
)(ShipmentStopFields);
