import {useCallback, useMemo} from 'react';
import {compose} from 'recompose';
import {Link, WithRouterProps} from 'react-router';
import {Cell} from 'react-table';
import {AxiosError} from 'axios';
import {DeprecatedButton, SvgIcon} from '@shipwell/shipwell-ui';
import {ShipwellApiErrorResponse} from '@shipwell/tempus-sdk';
import Table from 'App/components/Table';
import {Facility} from 'App/data-hooks/facilities/types';
import PageHeader from 'App/common/pageHeader';
import withFlags from 'App/utils/withFlags';
import useToggle from 'App/utils/hooks/useToggle';
import withConditionalFallback from 'App/components/withConditionalFallback';
import Error404Page from 'App/common/Error404Page';
import FacilityCreationModal from 'App/containers/facilities/FacilityCreationModal';
import {toTitleCase} from 'App/utils/string';
import {formatDateTime} from 'App/utils/dateTimeGlobalsTyped';
import {useFacilityPerms, useFacilitiesQuery, useUserMe} from 'App/data-hooks';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {parseV3ApiError} from 'App/api/typedUtils';
import EmptyListView from 'App/components/Table/components/EmptyListView';

type FacilityListProps = WithRouterProps<{id: string}> & WithStatusToastProps;
const FacilitiesPage = ({setError}: FacilityListProps): JSX.Element => {
  const {isLoading: isUserLoading, data: userMe} = useUserMe();
  const [showFacilityCreationModal, toggleShowFacilityCreationModal] = useToggle();
  const {canCreate} = useFacilityPerms();

  const onError = useCallback(
    (error: AxiosError<ShipwellApiErrorResponse>) => {
      const formattedError = parseV3ApiError(error);
      if (formattedError.title && formattedError.detail) {
        setError(formattedError.title, formattedError.detail);
      } else if (formattedError.isError) {
        setError('Error!', 'Unable to fetch facilities.');
      }
    },
    [setError]
  );

  const {data: facilities, isLoading: isFacilityLoading} = useFacilitiesQuery({onError});
  const columns = useMemo(() => {
    return [
      {
        Header: <div className="text-sm">Facility Name</div>,
        accessor: 'name',
        Cell: (data: Cell<Facility>) => {
          const facility = data.row.original;
          return <Link to={`/facilities/${facility.id}/details`}>{facility.name}</Link>;
        }
      },
      {
        Header: <div className="text-sm">Facility Address</div>,
        accessor: 'address.formatted_address'
      },
      {
        Header: <div className="text-sm">Location Type</div>,
        accessor: 'location_type',
        Cell: (data: Cell<Facility>) => {
          const locationType = data.row.original.location_type || '--';
          return toTitleCase(locationType);
        }
      },
      {
        Header: <div className="text-sm">Docks</div>,
        accessor: 'dock_count'
      },
      {
        Header: <div className="text-sm">Location Notes</div>,
        accessor: 'notes'
      },
      {
        Header: <div className="text-sm">Last Updated</div>,
        accessor: 'updated_at',
        Cell: (data: Cell<Facility>) => {
          const lastUpdated = formatDateTime(data.row.original.updated_at) || '--';
          return lastUpdated;
        }
      }
    ];
  }, []);

  const FacilityPageHeader = useCallback(
    function FacilityPageHeader() {
      const actions =
        !isUserLoading && userMe && canCreate ? (
          <DeprecatedButton
            variant="tertiary"
            icon={<SvgIcon name="AddCircleOutlined" />}
            onClick={toggleShowFacilityCreationModal}
          >
            Add Facility
          </DeprecatedButton>
        ) : null;

      return <PageHeader title="Facilities" actions={actions} />;
    },
    [isUserLoading, userMe, toggleShowFacilityCreationModal, canCreate]
  );

  return (
    <>
      <FacilityPageHeader />
      {facilities?.length === 0 ? (
        <EmptyListView itemLabel="Facilities">
          <DeprecatedButton size="medium" variant="secondary" onClick={toggleShowFacilityCreationModal}>
            Add Facility
          </DeprecatedButton>
        </EmptyListView>
      ) : (
        <Table
          columns={columns}
          data={facilities}
          showRowSelect={false}
          dataIsLoading={isUserLoading || isFacilityLoading}
        />
      )}
      <FacilityCreationModal showModal={showFacilityCreationModal} toggleModal={toggleShowFacilityCreationModal} />
    </>
  );
};

const ConditionalFacilitiesPage = compose<FacilityListProps, Partial<FacilityListProps>>(
  withFlags('fiDockScheduling'),
  withConditionalFallback(({fiDockScheduling}: {fiDockScheduling: boolean}) => !fiDockScheduling, Error404Page),
  WithStatusToasts
)(FacilitiesPage);

export default ConditionalFacilitiesPage;
