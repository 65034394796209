import {useQueryClient, useMutation} from '@tanstack/react-query';
import {InboxMessage} from '@shipwell/backend-core-sdk';
import moment from 'moment';
import {DeprecatedButton} from '@shipwell/shipwell-ui';
import {InjectedRouter} from 'react-router';
import {INBOX_MESSAGES} from 'App/data-hooks/queryKeys';
import {
  updateInboxItemReadStatus,
  deleteInboxMessage,
  markAllAsRead,
  markAllAsUnread,
  inboxDeleteAllMessages
} from 'App/api/inbox/typed';
import './styles.scss';

/**
 * Inbox notifications
 */
type InboxNotificationsProps = {
  inbox: (InboxMessage | undefined)[];
  handleScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  router: InjectedRouter;
};
const InboxNotifications = (props: InboxNotificationsProps) => {
  const {inbox = [], router, handleScroll} = props;
  const queryClient = useQueryClient();
  const inboxUpdateReadStatusMutation = useMutation(
    ({id, body}: {id: string; body: InboxMessage}) => updateInboxItemReadStatus(id, body),
    {
      onSuccess: () => queryClient.invalidateQueries([INBOX_MESSAGES])
    }
  );

  const deleteInboxMessageMutation = useMutation((id: string) => deleteInboxMessage(id), {
    onSuccess: () => queryClient.invalidateQueries([INBOX_MESSAGES])
  });

  const inboxReadAll = async () => {
    try {
      await markAllAsRead();
      void queryClient.invalidateQueries([INBOX_MESSAGES]);
    } catch (error) {
      console.error(error);
    }
  };

  const inboxUnReadAll = async () => {
    try {
      await markAllAsUnread();
      void queryClient.invalidateQueries([INBOX_MESSAGES]);
    } catch (error) {
      console.error(error);
    }
  };

  const inboxDeleteAllNotifications = async () => {
    try {
      await inboxDeleteAllMessages();
      document.body.click();
      void queryClient.invalidateQueries([INBOX_MESSAGES]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNotification = (item?: InboxMessage) => {
    if (item?.url) {
      router.push(item.url);
    }
  };

  return (
    <div className="inbox-container">
      <div className="flex items-stretch justify-stretch">
        <a
          role="button"
          className="light-gray-border flex h-9 w-1/3 items-center justify-center text-[10px] uppercase"
          onClick={() => void inboxReadAll()}
        >
          All Read
        </a>
        <a
          role="button"
          className="light-gray-border flex h-9 w-1/3 items-center justify-center text-[10px] uppercase"
          onClick={() => void inboxUnReadAll()}
        >
          All Unread
        </a>
        <a
          role="button"
          className="light-gray-border flex h-9 w-1/3 items-center justify-center text-[10px] uppercase text-sw-error"
          onClick={() => void inboxDeleteAllNotifications()}
        >
          Delete All
        </a>
      </div>
      <div onScroll={handleScroll} className="inbox-list">
        {inbox.map((item, index) => (
          <div
            key={index}
            className={`inbox-item ${item?.read ? 'inbox-item-read' : ''}`}
            onClick={() => handleNotification(item)}
          >
            <p className="inbox-item-title font-bold">{item?.title || item?.level}</p>
            <p className="inbox-item-message">{item?.message}</p>
            <p className="mt-1 flex w-full items-center justify-between">
              <i className="inbox-item-date">{moment(item?.created_at).fromNow()}</i>
              {!item?.read && (
                <a
                  className="uppercase"
                  title="Mark this notification as read"
                  onClick={() =>
                    inboxUpdateReadStatusMutation.mutate({
                      id: item?.id || '',
                      body: {...item, read: true} as InboxMessage
                    })
                  }
                >
                  Mark as read
                </a>
              )}
              {item?.read ? (
                <a
                  className="uppercase"
                  title="Mark this notification as unread"
                  onClick={() =>
                    inboxUpdateReadStatusMutation.mutate({
                      id: item?.id || '',
                      body: {...item, read: false} as InboxMessage
                    })
                  }
                >
                  Mark as unread
                </a>
              ) : null}
            </p>
            <DeprecatedButton
              variant="icon"
              icon={<i className="material-icons">close</i>}
              onClick={() => deleteInboxMessageMutation.mutate(item?.id || '')}
            ></DeprecatedButton>
          </div>
        ))}
        {inbox.length < 1 ? <p className="empty-inbox">No inbox notifications.</p> : null}
      </div>
    </div>
  );
};

export default InboxNotifications;
