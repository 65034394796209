import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {getFedexAccounts} from 'App/api/registration/typed';
import {COMPANY_INTEGRATION_FEDEX} from 'App/data-hooks/queryKeys';
import {useUserMe} from 'App/data-hooks/users';

type UseGetFedExAccountsQuery = UseQueryOptions<ReturnType<typeof getFedexAccounts>>;

export const useGetFedExAccountsQuery = ({
  accountNumber,
  enabled: isQueryEnabled = true
}: {accountNumber?: string} & UseGetFedExAccountsQuery = {}) => {
  const {company} = useUserMe();
  const isFedExEnabled = company?.feature_flags?.fedex_enabled;

  const fedExQuery = useQuery(
    [COMPANY_INTEGRATION_FEDEX, ...(accountNumber ? [accountNumber] : [])],
    async () => getFedexAccounts(accountNumber),
    {
      onError: (error) => {
        console.error(error);
      },
      enabled: isFedExEnabled && isQueryEnabled
    }
  );

  const isFedExAuthenticated = fedExQuery?.data?.results?.find((result) => result.company === company?.id);

  return {
    isFedExAuthenticated,
    fedExQuery,
    isFedExEnabled,
    fedExAccounts: fedExQuery.data?.results
  };
};
