import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {ChargeLineItemCategory} from '@shipwell/backend-core-sdk';
import {CHARGE_CATEGORY_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {STATIC_DATA_STALE_TIME} from 'App/utils/queryConstants';
import {getChargeCategories} from 'App/api/shipment/typed';
export const useSortedChargeCategories = (props?: {
  queryOptions?: UseQueryOptions<ChargeLineItemCategory[], unknown, ChargeLineItemCategory[], string[]>;
  sendShipwellVersionHeader: boolean;
}) => {
  const getChargeCategoriesQuery = useQuery(
    [CHARGE_CATEGORY_QUERY_KEY],
    async () => {
      const response = await getChargeCategories(props?.sendShipwellVersionHeader);
      return response.data.sort((a, b) => a.name.localeCompare(b.name));
    },
    {staleTime: STATIC_DATA_STALE_TIME, ...props?.queryOptions}
  );
  return getChargeCategoriesQuery;
};
