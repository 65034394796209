import {Field, Formik, Form, useFormikContext, FieldArray} from 'formik';
import {Modal, DeprecatedButton, SvgIcon, FormikSelect, Title} from '@shipwell/shipwell-ui';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import partition from 'lodash/partition';
import {ContactType} from '@shipwell/corrogo-sdk';
import PropTypes from 'prop-types';
import {array, object, string, mixed} from 'yup';
import {useCompanyUsers, useCreateOrUpdateShipment, useV3Shipment} from 'App/data-hooks';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import getNil from 'App/utils/getNil';
import Loader from 'App/common/shipwellLoader';

function getFormattedCompanyUsers(companyUsers) {
  return get(companyUsers, 'results', []).map((cu) => ({
    label: `${cu.first_name} ${cu.last_name}`,
    value: cu.id
  }));
}

const AddRepButton = ({push}) => (
  <DeprecatedButton variant="tertiary" onClick={() => push({user: null, role: null})}>
    <SvgIcon className="shipment__reps-add-icon" name="AddCircleOutlined" color="sw-primary" />
    Add a Rep
  </DeprecatedButton>
);

const RepsBlurb = () => <div className="pb-2">Add reps to provide visibility to who owns a shipment.</div>;

AddRepButton.propTypes = {
  push: PropTypes.func
};

const NoRepsView = ({push}) => (
  <div className="flex flex-col justify-center text-center">
    <Title className="pb-4 text-center" variant="emptyStateHeader">
      No Reps
    </Title>
    <RepsBlurb />
    <AddRepButton push={push} />
  </div>
);

NoRepsView.propTypes = {
  push: PropTypes.func
};

export const RepsFields = ({shipmentRepRoles, companyUsers, push, remove}) => {
  const {values} = useFormikContext();
  const {reps} = values;

  return reps.length > 0 ? (
    <>
      <RepsBlurb />
      <div>
        {reps.map((rep, index) => (
          <div className="grid grid-cols-2 gap-2 pb-4" key={index}>
            <div>
              <Field
                name={`reps[${index}].role`}
                label="Role"
                clearable={false}
                simpleValue
                required
                component={FormikSelect}
                options={shipmentRepRoles}
              />
            </div>
            <div className="flex flex-row">
              <div className="w-full pr-4">
                <Field
                  name={`reps[${index}].user`}
                  label="User"
                  simpleValue
                  required
                  component={FormikSelect}
                  options={getFormattedCompanyUsers(companyUsers)}
                />
              </div>
              <DeprecatedButton variant="icon" onClick={() => remove(index)} title="Remove this rep">
                <SvgIcon name="TrashOutlined" color="sw-icon" />
              </DeprecatedButton>
            </div>
          </div>
        ))}
      </div>
      <AddRepButton push={push} />
    </>
  ) : (
    <NoRepsView push={push} />
  );
};

RepsFields.propTypes = {
  shipmentRepRoles: PropTypes.arrayOf(
    PropTypes.shape({label: PropTypes.string, name: PropTypes.string, id: PropTypes.string})
  ),
  companyUsers: PropTypes.shape({
    results: PropTypes.arrayOf(
      PropTypes.shape({first_name: PropTypes.string, last_name: PropTypes.string, id: PropTypes.string})
    )
  }),
  push: PropTypes.func,
  remove: PropTypes.func
};

const formatContactData = (contact, companyUsers) => ({
  contact_type: contact.role,
  person_name: getFormattedCompanyUsers(companyUsers).find((companyUser) => companyUser.value === contact.user)?.label,
  system_id: contact.user
});

function useUpdateShipmentReps(shipmentId) {
  const {createShipmentRepMutation, updateShipmentRepMutation, deleteShipmentRepMutation} =
    useCreateOrUpdateShipment(shipmentId);

  const mutateReps = (contactsToCreate, contactsToUpdate, contactsToDelete, companyUsers) => {
    const createMutations = contactsToCreate.map((contact) =>
      createShipmentRepMutation.mutateAsync({
        shipmentId,
        data: formatContactData(contact, companyUsers)
      })
    );

    const updateMutations = contactsToUpdate.map((contact) =>
      updateShipmentRepMutation.mutateAsync({
        shipmentId,
        contactId: contact.id,
        data: formatContactData(contact, companyUsers)
      })
    );

    const deleteMutations = contactsToDelete.map((contact) =>
      deleteShipmentRepMutation.mutateAsync({shipmentId, contactId: contact.id})
    );

    return Promise.allSettled([...createMutations, ...updateMutations, ...deleteMutations]);
  };

  return {mutateReps};
}

const validationSchema = object().shape({
  reps: array().of(
    object().shape({
      role: mixed().nullable().required('A role is required.'),
      user: string().nullable().required('A Rep name is required.')
    })
  )
});

const RepsFieldsContainer = ({onClose, shipmentId}) => {
  const shipmentQuery = useV3Shipment(shipmentId);
  const createdBy = shipmentQuery.data?.created_by;
  const tenantId = createdBy?.tenant_id;

  const companyUsersQuery = useCompanyUsers(tenantId, {pageSize: 1000});
  const {mutateReps} = useUpdateShipmentReps(shipmentQuery.data?.id);

  if (shipmentQuery.isInitialLoading || companyUsersQuery.isInitialLoading) {
    return <Loader loading />;
  }

  const contacts = getNil(shipmentQuery, 'data.contacts', []);

  const contactTypes = Object.values(ContactType).map((contact) => ({
    label: startCaseToLower(contact),
    name: contact,
    id: contact
  }));

  const companyUsers = get(companyUsersQuery, 'data');

  const initialValues = {
    reps: contacts.map((contact) => ({
      id: contact.id,
      user: companyUsers?.results?.find((companyUser) => companyUser.id === contact.system_id)?.id,
      role: contactTypes.find((contactType) => contactType.id === contact.contact_type)?.id
    }))
  };

  const handleSubmit = (values) => {
    const {reps} = values;

    const contactsToDelete = contacts.filter((originalContact) => !reps.find((rep) => originalContact.id === rep.id));

    // filters the reps down to only the reps that have been newly created or updated.
    const contactsToCreateOrUpdate = reps.filter(
      (rep) => !initialValues.reps.find((originalRep) => isEqual(originalRep, rep))
    );

    // partition "partitions" the data into two arrays based on the clause in the second parameter. this allows us to
    // separate out the contacts we need to create from the contacts we need to update based on whether an ID already exists
    // for this contact
    const [contactsToUpdate, contactsToCreate] = partition(contactsToCreateOrUpdate, (contactToCreateOrUpdate) =>
      contacts.find((contact) => contactToCreateOrUpdate.id === contact.id)
    );

    mutateReps(contactsToCreate, contactsToUpdate, contactsToDelete, companyUsers);
    onClose();
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      <Form>
        <div className="mb-10">
          <FieldArray
            name="reps"
            render={(arrayHelpers) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <RepsFields companyUsers={companyUsers} shipmentRepRoles={contactTypes} {...arrayHelpers} />
            )}
          />
        </div>
        <ModalFormFooter onCancel={onClose} />
      </Form>
    </Formik>
  );
};

RepsFieldsContainer.propTypes = {
  onClose: PropTypes.func,
  shipmentId: PropTypes.string
};

const RepsModal = ({showModal, onClose, shipmentId}) => (
  <Modal onClose={onClose} title="Reps" show={showModal} footerComponent={null} bodyVariant="disableOverflowScroll">
    <RepsFieldsContainer shipmentId={shipmentId} onClose={onClose} />
  </Modal>
);

RepsModal.propTypes = {
  showModal: PropTypes.bool,
  shipmentId: PropTypes.string,
  onClose: PropTypes.func
};

export default RepsModal;
