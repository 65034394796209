import {SyntheticEvent, useCallback, useState} from 'react';
import invariant from 'tiny-invariant';

import {Shipment, ShipmentDocumentMetadata} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Button, FileDisplay, IconButton, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import {Facility} from '@shipwell/tempus-sdk';

import classNames from 'classnames';
import {useLegacyShipmentDocuments, useMutateLegacyShipmentDocuments} from 'App/data-hooks/documents';

import {formatDateTime} from 'App/utils/dateTimeGlobalsTyped';
import {DocumentAuditLog, DocumentDisplay, DocumentMetadata, DocumentViewLayout} from 'App/components/DocumentView';
import useShipmentAuditlog from 'App/data-hooks/shipments/useShipmentAuditlog';
import ShipwellLoader from 'App/common/shipwellLoader';
import ShipmentDocumentForm from 'App/formComponents/forms/shipmentDocument';
import {parseV3ApiError} from 'App/api/typedUtils';

export type AppointmentShipmentDocumentsProps = {
  shipment?: Shipment;
  facility?: Facility;
  setError: (title: string, details: string) => unknown;
  setSelectedAppointment: (appointment: null) => unknown;
};

const EmptyDocuments: ShipmentDocumentMetadata[] = [];

function canBeDeleted(document: ShipmentDocumentMetadata): boolean {
  // For now always return true because Joe doesn't know what the real policy is.
  return !!document;
}

function canUploadDocument(): boolean {
  // For now always return true because Joe doesn't know what the real policy is.
  return true;
}

export function AppointmentShipmentDocuments(props: AppointmentShipmentDocumentsProps) {
  const {setError} = props;
  const query = useLegacyShipmentDocuments(props.shipment?.id ?? '');

  const {deleteDocument, uploadDocument, isMutating} = useMutateLegacyShipmentDocuments(props.shipment?.id ?? '');

  const documents = query.data?.results ?? EmptyDocuments;

  const [selectedDocument, setSelectedDocument] = useState<ShipmentDocumentMetadata | null>(null);
  const [deletingDocument, setDeletingDocument] = useState<ShipmentDocumentMetadata | null>(null);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);

  const onClickDocument = useCallback(
    (event: SyntheticEvent) => {
      const element = event.currentTarget;
      const row = element.closest('.document-row') as HTMLElement | undefined;
      invariant(row);
      const documentId = row.dataset.documentId;
      const document = documents.find((d) => d.id === documentId);
      invariant(document);
      setSelectedDocument(document);
    },
    [documents]
  );

  const handleDeleteDocument = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();
      const element = event.currentTarget;
      const row = element.closest('.document-row') as HTMLElement | undefined;
      if (!row) {
        throw new Error('Expected row parent');
      }
      const documentId = row.dataset.documentId;
      if (!documentId) {
        return;
      }
      const document = documents.find((d) => d.id === documentId);
      setDeletingDocument(document ?? null);
    },
    [documents]
  );

  const doDeleteDocument = useCallback(async () => {
    setDeleteInProgress(true);
    try {
      await deleteDocument(deletingDocument?.id ?? '');
    } catch (error) {
      const {isError, title, detail} = parseV3ApiError(error);
      if (isError) {
        setError(title, detail);
      }
    } finally {
      setDeleteInProgress(false);
      setDeletingDocument(null);
    }
  }, [deleteDocument, deletingDocument?.id, setError]);

  const handleUploadDocument = useCallback(
    async (document: {file: File; type: string; description: string}) => {
      try {
        await uploadDocument({
          file: document.file,
          type: document.type,
          description: document.description
        });
      } catch (error) {
        const {isError, title, detail} = parseV3ApiError(error);
        if (isError) {
          setError(title, detail);
        }
      } finally {
        setShowDocumentUpload(false);
      }
    },
    [setError, uploadDocument]
  );

  const {isLoading: auditlogIsLoading, auditlog} = useShipmentAuditlog(props.shipment?.id);
  const selectDocumentAuditLog = auditlog?.find((el) => el.id === selectedDocument?.id);

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  const errorText = (query.error && `${(query.error as any)?.message ?? query.error}`) as string | undefined;

  return (
    <div className="flex h-full w-[347px] flex-col content-stretch">
      <div className="flex grow-0 flex-row border-b-1 border-sw-divider">
        <h5 className="flex-1 px-2 pt-1">Documents</h5>
        <div className="flex grow-0 p-1">
          {canUploadDocument() ? (
            <Button onClick={() => setShowDocumentUpload(true)} variant="tertiary" width="compact">
              <SvgIcon name="Upload" aria-label="Upload" />
              Upload
            </Button>
          ) : null}
        </div>
        <div className="flex grow-0 p-1">
          <IconButton
            iconName="Close"
            aria-label="Close"
            size="lg"
            onClick={() => props.setSelectedAppointment(null)}
          />
        </div>
      </div>
      <div className="flex grow flex-col">
        {errorText ? (
          <div className="bg-sw-error-background text-sw-error-light">{errorText}</div>
        ) : query.isLoading ? (
          <ShipwellLoader />
        ) : documents.length === 0 ? (
          <div
            className={classNames(
              'flex grow flex-col border-y-1 border-sw-border p-2',
              !errorText && documents.length === 0 ? 'items-center justify-center' : ''
            )}
          >
            <div className="bold text-lg">No Documents</div>
            <div>
              {canUploadDocument() ? (
                <Button onClick={() => setShowDocumentUpload(true)} variant="tertiary" width="compact">
                  <SvgIcon name="Upload" aria-label="Upload" />
                  Upload
                </Button>
              ) : null}
            </div>
          </div>
        ) : (
          documents.map((document) => (
            <div
              key={document.id}
              data-document-id={document.id}
              className="document-row mt-2 flex w-full grow-0 flex-row items-start border-b-1 border-sw-divider pb-2 pl-1"
            >
              <a className="flex-0 flex" onClick={onClickDocument}>
                <FileDisplay
                  maxHeight={40}
                  maxWidth={40}
                  fileURL={document.file}
                  fileName={document.filename}
                  isThumbnail
                  altText={document.description}
                />
              </a>
              <button className="mx-1 flex flex-1 cursor-pointer flex-col" onClick={onClickDocument}>
                <span className="flex">{document.type ?? ''}</span>
                <span className="flex text-sm">{document.description ?? ''}</span>
                <span className="flex text-sw-disabled-text">
                  {formatDateTime(document.created_at ?? '', props.facility?.address?.timezone) ?? ''}
                  {document.created_by_name ? `by ${document.created_by_name}` : ''}
                </span>
              </button>
              {canBeDeleted(document) ? (
                <div className="justify-self-end p-1">
                  <IconButton
                    iconName="TrashOutlined"
                    aria-label="Delete Document"
                    title="Delete Document"
                    onClick={handleDeleteDocument}
                  />
                </div>
              ) : null}
            </div>
          ))
        )}
      </div>
      <Modal show={!!selectedDocument} onClose={() => setSelectedDocument(null)} footerComponent={null}>
        <DocumentViewLayout>
          <div>
            <DocumentMetadata document={selectedDocument} documentType={document.doctype?.name ?? 'pdf'} />
            {auditlogIsLoading ? (
              <ShipwellLoader />
            ) : (selectDocumentAuditLog?.emails?.length ?? 0) > 0 ? (
              <DocumentAuditLog auditLogEmails={selectDocumentAuditLog?.emails} />
            ) : null}
          </div>
          <DocumentDisplay document={selectedDocument} fileType={selectedDocument?.type} />
        </DocumentViewLayout>
        {/* <DocumentView document={selectedDocument} fileType={selectedDocument?.type ?? 'pdf'} /> */}
      </Modal>
      <Modal
        show={!!deletingDocument}
        primaryActions={
          <Button onClick={doDeleteDocument as () => void} disabled={deleteInProgress}>
            Delete
          </Button>
        }
      >
        Are you sure you wish to delete the document &quot;{deletingDocument?.filename}&quot;?
      </Modal>
      <Modal
        show={showDocumentUpload}
        title="Upload Document"
        footerComponent={null}
        onClose={() => setShowDocumentUpload(false)}
      >
        <ShipmentDocumentForm onCancel={() => setShowDocumentUpload(false)} onSubmit={handleUploadDocument} />
        {isMutating ? (
          <div className="place-self-center">
            <ShipwellLoader />
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
