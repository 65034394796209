import {Stop} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Button, IconButton, Select, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import React from 'react';
import {browserHistory} from 'react-router';
import ShipwellLoader from 'App/common/shipwellLoader';
import {DAT_RATE_TYPE_CONTRACT, DAT_RATE_TYPE_SPOT} from 'App/components/pricingIntelChart/pricingIntelConstants';
import useToggle from 'App/utils/hooks/useToggle';
import DisplayRate from 'App/containers/Shipment/components/EmbeddedPricingIntel/DisplayRate';
import {ExternalRatesType} from 'App/containers/Shipment/components/EmbeddedPricingIntel';
import {DatResponse} from 'App/data-hooks/pricing/useDatPricing';

export const CARRIER_RATES_SOURCE = 'CONNECTED_RATES';

const PRICING_SOURCE_OPTIONS = [
  {label: 'DAT Broker-to-Carrier Spot', value: DAT_RATE_TYPE_SPOT},
  {label: 'DAT Shipper-to-Carrier Contract', value: DAT_RATE_TYPE_CONTRACT},
  {label: 'Connected Carrier Rates', value: CARRIER_RATES_SOURCE}
];

const ExternalRatesTooltipText = () => {
  return (
    <div>
      <div className="text-bold">External Rates</div>
      <div className="mb-2">To view external rates connect or sign into your rate providers to see prices.</div>
      <Button size="sm" onClick={() => browserHistory.push('/company/integrations')}>
        Connect External Rates
      </Button>
    </div>
  );
};

const ExternalRates = ({
  isDatIntegrated,
  DpmToggle,
  pricingSource,
  setPricingSource,
  isLoading,
  stopsArr,
  showDPM,
  totalMiles,
  averageFuelSurchargePerTripUsd,
  averageFuelSurchargePerMileUsd,
  toggleShowCalculator,
  setInitialRate,
  handleApplyRate,
  selectedExternalRates,
  isDisabledCalculator,
  datResponse,
  showOnlyDatRates = false
}: {
  isDatIntegrated: boolean;
  DpmToggle: JSX.Element;
  pricingSource: string;
  setPricingSource: (src: string) => void;
  isLoading: boolean;
  stopsArr: Stop[];
  showDPM: boolean;
  totalMiles?: number | null;
  averageFuelSurchargePerTripUsd: number;
  averageFuelSurchargePerMileUsd: number;
  toggleShowCalculator: () => void;
  setInitialRate: (rate: string | null) => void;
  handleApplyRate?: (applyFor: string, rate: string) => void;
  selectedExternalRates: ExternalRatesType[];
  isDisabledCalculator: boolean;
  datResponse?: DatResponse;
  showOnlyDatRates?: boolean;
}) => {
  const [externalRatesExpanded, toggleExternalRatesExpanded] = useToggle(true);

  const pricingSourceOptions =
    stopsArr.length > 2
      ? PRICING_SOURCE_OPTIONS.slice(2)
      : showOnlyDatRates
      ? PRICING_SOURCE_OPTIONS.slice(0, -1)
      : PRICING_SOURCE_OPTIONS;

  return (
    <div>
      <div className="mb-3 flex items-center justify-between">
        <div className="flex">
          <div className="text-bold mr-1">External Rates</div>
          {!isDatIntegrated ? (
            <Tooltip
              placement="top"
              tooltipClassname="w-48"
              tooltipContent={<ExternalRatesTooltipText />}
              trigger="hover"
              portal
            >
              <SvgIcon color="$sw-icon" name="InfoOutlined" />
            </Tooltip>
          ) : null}
        </div>
        <div className="flex items-center">
          {DpmToggle}
          {showOnlyDatRates ? null : (
            <IconButton
              iconName={externalRatesExpanded ? 'ExpandLess' : 'ExpandMore'}
              aria-label={externalRatesExpanded ? 'Hide external rates' : 'Expand external rates'}
              onClick={() => toggleExternalRatesExpanded()}
            />
          )}
        </div>
      </div>
      <Select
        required
        label="Pricing Source"
        value={pricingSource}
        options={pricingSourceOptions}
        clearable={false}
        simpleValue
        onChange={(source: string) => setPricingSource(source)}
      />
      {isLoading ? (
        <ShipwellLoader loading additionalClassNames={['h-36']} />
      ) : (
        <div>
          {externalRatesExpanded
            ? selectedExternalRates.map((externalRate, index) => (
                <DisplayRate
                  rateValues={externalRate}
                  key={`rate-${index}`}
                  showDPM={showDPM}
                  totalMiles={totalMiles}
                  averageFuelSurchargePerTripUsd={averageFuelSurchargePerTripUsd}
                  averageFuelSurchargePerMileUsd={averageFuelSurchargePerMileUsd}
                  pricingSource={pricingSource}
                  toggleShowCalculator={toggleShowCalculator}
                  setInitialRate={setInitialRate}
                  handleApplyRate={handleApplyRate}
                  isDisabledCalculator={isDisabledCalculator}
                  datResponse={datResponse}
                  showOnlyDatRates={showOnlyDatRates}
                />
              ))
            : null}
        </div>
      )}
    </div>
  );
};

export default ExternalRates;
