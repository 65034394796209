import {FieldArray} from 'redux-form';
import {Banner} from '@shipwell/shipwell-ui';
import {
  CompanyInternationalPreferencesCurrencyEnum,
  CustomFieldEntityTypesEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {GrandTotals} from '../types';
import RenderMultipleFinancials, {FinancialsColumnHeaders} from 'App/formComponents/renderMultipleFinancials';
import {formatCurrencyValue} from 'App/utils/globals';
import {formatCurrency} from 'App/utils/internationalConstants';
import '../_financials.scss';
import Loader from 'App/common/shipwellLoader';
import {useCustomFields, useSortedChargeCategories} from 'App/data-hooks';

export const FinancialsExpandedForm = ({
  prepaidTotal,
  mode,
  lineItemTotals,
  prepaidTotals,
  grandTotals,
  shipmentCurrency
}: {
  prepaidTotal: number;
  mode: 'vendor' | 'customer';
  lineItemTotals: number[];
  prepaidTotals: number[];
  grandTotals: GrandTotals;
  shipmentCurrency: CompanyInternationalPreferencesCurrencyEnum;
}) => {
  const {data: chargeCategories, isLoading: isLoadingChargeCategories} = useSortedChargeCategories();
  const {customFields: shipmentChargeLineItemCustomFields} = useCustomFields(
    CustomFieldEntityTypesEnum.ShipmentChargeLineItem
  );

  if (shipmentCurrency && chargeCategories) {
    const visibleChargeLineItemCustomFields =
      shipmentChargeLineItemCustomFields?.filter((customField) => customField.ui_enabled) || [];
    const customFieldLabels = visibleChargeLineItemCustomFields?.map((customField) =>
      customField.required ? `${customField.label}*` : customField.label
    );

    return (
      <div>
        {mode === 'vendor' ? (
          <div>
            <div className="pl-4">
              <FinancialsColumnHeaders
                labels={['Category', 'Description', ...customFieldLabels, 'Rate', 'Qty', 'Prepaid', 'Total']}
              />
            </div>
            <div className="pl-4">
              <FieldArray
                name="financials"
                component={RenderMultipleFinancials}
                props={{
                  financialTotals: lineItemTotals,
                  prepaidTotals,
                  chargeCategories,
                  currency: shipmentCurrency,
                  mode,
                  chargeLineItemCustomFields: visibleChargeLineItemCustomFields
                }}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="pl-4">
              <FinancialsColumnHeaders
                labels={['Category', 'Description', ...customFieldLabels, 'Rate', 'Qty', 'Total']}
              />
            </div>
            <div className="pl-4">
              <FieldArray
                name="financials"
                component={RenderMultipleFinancials}
                props={{
                  financialTotals: lineItemTotals,
                  prepaidTotals,
                  chargeCategories,
                  currency: shipmentCurrency,
                  mode,
                  chargeLineItemCustomFields: visibleChargeLineItemCustomFields
                }}
              />
            </div>
          </div>
        )}

        {mode === 'vendor' ? (
          <div className="flex justify-end">
            <div className="grid grid-cols-[auto_auto] gap-x-8 gap-y-1 text-right font-bold">
              <div>Shipment Total</div>
              <div className="text-base text-sw-success">{formatCurrency(grandTotals.vendor, shipmentCurrency)}</div>
              <div>Amount Due to Carrier</div>
              <div className="text-base text-sw-success">
                {formatCurrency((grandTotals.vendor || 0) - prepaidTotal, shipmentCurrency)}
              </div>
            </div>
          </div>
        ) : null}

        {mode === 'customer' ? (
          <div>
            <div className="financialsExpanded__markup m-0 p-0">
              <h5> Total Markup </h5>
              <span className="text-sm">
                {parseFloat((grandTotals.customerMarkup || 0).toString()) > 0 && '+'}
                {formatCurrency(grandTotals.customerMarkup, shipmentCurrency) +
                  ' (' +
                  formatCurrencyValue(grandTotals.customerMargin) +
                  '%)'}
              </span>
            </div>
            <div className="financialsExpanded__formTotal m-0 p-0">
              <h5>Customer Total</h5>
              <span className="text-base">{formatCurrency(grandTotals.customer, shipmentCurrency)}</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  if (isLoadingChargeCategories) {
    return <Loader loading />;
  }

  return <Banner title={'Error'}>There was an error loading financials. Try reloading the page.</Banner>;
};
