import {ChangeEvent} from 'react';
import {Field, useFormikContext} from 'formik';
import {Button, Popover} from '@shipwell/shipwell-ui';
import {
  AssembleHandlingUnitsFormValues,
  getLineItemsThatMatchStop,
  handleAddToHandlingUnit,
  handleRemoveFromHandlingUnit,
  isLineItem
} from './utils';
import {FlexBox} from 'App/components/Box';

export const LineItemsTable = ({
  lineItems,
  handlingUnitLineItems,
  isDisabled,
  isHandlingUnit = false
}: {
  lineItems?:
    | AssembleHandlingUnitsFormValues['line_items']
    | AssembleHandlingUnitsFormValues['stops'][number]['handling_units'][number]['pieces'];
  handlingUnitLineItems?: AssembleHandlingUnitsFormValues['stops'][number]['handling_units'][number]['pieces'];
  isDisabled: boolean;
  isHandlingUnit?: boolean;
}) => {
  const {setFieldValue, values, setFieldError, errors} = useFormikContext<AssembleHandlingUnitsFormValues>();
  const getLineItemQuantityError = (index: number, value: string) => {
    const quantity = Number(value);
    const lineItem = values.line_items[index];
    if (!lineItem) {
      return;
    }
    const totalPieces = lineItem.total_pieces || 0;
    if (quantity < 0) {
      return 'Quantity must be a positive number.';
    }
    if (quantity % 1 !== 0) {
      return 'Quantity must be a whole number.';
    }
    if (quantity > totalPieces) {
      return 'Quantity cannot be greater than available pieces.';
    }
  };
  const getFieldError = (index: number) => {
    const error = errors.line_items?.[index];
    if (typeof error === 'string') {
      return;
    }
    return error?.quantity;
  };

  // remove quantity from current line item
  // add lineitem with specified quanity to ACTIVE handling unit
  const handleAdd = (lineItemIndex: number) => {
    if (getFieldError(lineItemIndex)) {
      return;
    }
    const updatedFieldValues = handleAddToHandlingUnit({
      activeHandlingUnitId: values.active_handling_unit_id,
      lineItemIndex,
      stops: values.stops,
      lineItems: values.line_items
    });
    if (!updatedFieldValues) {
      return;
    }
    Object.entries(updatedFieldValues).forEach((entry) => {
      setFieldValue(entry[0], entry[1]);
    });
  };

  const handleRemove = (itemId: string, itemIndex: number) => {
    const updatedFieldValues = handleRemoveFromHandlingUnit({
      activeHandlingUnitId: values.active_handling_unit_id,
      stops: values.stops,
      lineItems: values.line_items,
      itemIndex,
      itemId
    });
    if (!updatedFieldValues) {
      return;
    }
    Object.entries(updatedFieldValues).forEach((entry) => {
      setFieldValue(entry[0], entry[1]);
    });
  };

  const matchingLineItems = getLineItemsThatMatchStop(
    values.line_items || [],
    values.stops,
    values.active_handling_unit_id
  );

  const hideRow = (
    item:
      | AssembleHandlingUnitsFormValues['line_items'][number]
      | AssembleHandlingUnitsFormValues['stops'][number]['handling_units'][number]['pieces'][number]
  ) => {
    // if table is for handling units, always show rows
    if (isHandlingUnit) return false;
    // hide any none line items (handling units found in line item array)
    if (!isLineItem(item)) return true;
    // don't show line items that are associated with different stops
    if (matchingLineItems.every((lineItem) => lineItem.id !== item.id)) return true;
    const noAvailablePieces = !item.total_pieces;
    // hide row if total pieces is 0 AND id is found in a handling unit
    const foundInHandlingUnit = handlingUnitLineItems?.some((handlingUnit) => handlingUnit.id === item.id);
    return noAvailablePieces && foundInHandlingUnit;
  };

  const addQuantityToHandlingUnit = (index: number) => {
    const error = getFieldError(index);
    if (!error) {
      handleAdd(index);
    }
    return Boolean(error);
  };

  return (
    <table className="w-full">
      <thead>
        <tr className="uppercase text-sw-disabled-text">
          <th className=" text-xxs font-normal">Description</th>
          <th className=" text-xxs font-normal">Piece Type</th>
          <th className=" text-xxs font-normal">HZMT</th>
          <th className=" text-xxs font-normal">WT</th>
          <th className=" text-xxs font-normal">Pieces</th>
          {!isHandlingUnit ? <th className=" text-xxs font-normal">Add pieces to pallet</th> : null}
        </tr>
      </thead>
      <tbody>
        {lineItems?.map((item, index) => (
          // hiding table row if no total pieces. Doing this instead of filtering the lineItems array to keep indexes consistent for the sake of Formik
          <tr aria-hidden={hideRow(item)} key={item.id} className={`h-7 ${hideRow(item) ? 'hidden' : ''}`}>
            <td>{item.description || '--'}</td>
            <td>{item.piece_type || '--'}</td>
            <td>{item.hazmat_hazard_class ? 'X' : '--'}</td>
            <td>
              {
                //prevent rounding errors from displaying
                item.weight
                  ? item.total_pieces === item.used_pieces
                    ? item.package_weight
                    : roundToHundredth(item.weight * (item.total_pieces || 1))
                  : '--'
              }{' '}
              {item.weight_unit}
            </td>
            <td>
              <FlexBox gap="s" justify="between" items="center">
                <span className={isHandlingUnit ? 'font-bold' : ''}>{item.total_pieces || '--'}</span>
                {isHandlingUnit ? (
                  <Button
                    type="button"
                    variant="tertiary"
                    isCompact
                    size="sm"
                    onClick={() => handleRemove(item.id || '', index)}
                  >
                    Remove
                  </Button>
                ) : null}
              </FlexBox>
            </td>
            {!isHandlingUnit ? (
              <td>
                <Popover
                  placement="top-end"
                  showArrow
                  lockFocus={false}
                  trigger={({
                    setIsOpen,
                    setTriggerElement
                  }: {
                    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
                    setTriggerElement: (el: HTMLElement | null) => void;
                  }) => (
                    <div className="flex gap-2" ref={setTriggerElement}>
                      <Field
                        name={`line_items[${index}].quantity`}
                        placeholder="Available pieces..."
                        className={`w-full rounded border border-sw-border pl-2 text-xs ${
                          isDisabled ? 'bg-sw-disabled-alternative text-sw-disabled-text' : ''
                        } ${getFieldError(index) ? 'outline outline-sw-error' : ''}`}
                        type="number"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(`line_items[${index}].quantity`, e.target.value);
                          const error = getLineItemQuantityError(index, e.target.value);
                          setFieldError(`line_items[${index}].quantity`, error);
                          setIsOpen(Boolean(getFieldError(index)));
                        }}
                        disabled={isDisabled}
                        onKeyDown={(e: KeyboardEvent) => {
                          // hitting enter within a form by default submits it. But we want this to add the quantity to the handling unit instead
                          if ([e.key, e.code].includes('Enter')) {
                            e.preventDefault();
                            setIsOpen(addQuantityToHandlingUnit(index));
                          }
                        }}
                        step={1}
                        max={item.total_pieces}
                      />
                      <Button
                        type="button"
                        variant="tertiary"
                        isCompact
                        size="sm"
                        disabled={isDisabled}
                        onClick={() => setIsOpen(addQuantityToHandlingUnit(index))}
                      >
                        Add
                      </Button>
                    </div>
                  )}
                >
                  <span className="z-100 bg-sw-component p-4 text-sw-error-dark">{getFieldError(index)}</span>
                </Popover>
              </td>
            ) : null}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const roundToHundredth = (val: number) => Math.round(val * 100) / 100;
