import {useState, useEffect} from 'react';
import {Sidebar} from '@shipwell/shipwell-ui';
import map from 'lodash/map';
import {getDisplayName} from 'recompose';
import SidebarDocuments from './DocumentsController';
import {DrayageLegSidebarActions} from 'App/containers/shipments/details/components/SidebarActions';

const KEY_IS_OPEN = 'containerSidebar_isOpen';
const KEY_SELECTED_INDEX = 'containerSidebar_selectedIndex';
const COMPONENTS = [
  {button: {name: 'ActionFilled', label: 'Actions'}, component: DrayageLegSidebarActions},
  {button: {name: 'Clipboard', label: 'Documents'}, component: SidebarDocuments}
];

type PropTypes = {
  legId: string;
};

const DrayageLegSidebar = ({legId}: PropTypes): JSX.Element => {
  const [isOpen, setIsOpen] = useState(localStorage.getItem(KEY_IS_OPEN) === 'true');
  const [selectedIndex, setSelectedIndex] = useState(Number(localStorage.getItem(KEY_SELECTED_INDEX)));
  const buttons = map(COMPONENTS, 'button');
  const sidebarComponents = map(COMPONENTS, 'component');

  useEffect(() => localStorage.setItem(KEY_IS_OPEN, String(isOpen)), [isOpen]);
  useEffect(() => localStorage.setItem(KEY_SELECTED_INDEX, String(selectedIndex)), [selectedIndex]);

  const onClose = () => {
    setIsOpen(false);
  };

  const onSelect = (index: number) => {
    setIsOpen(isOpen ? selectedIndex !== index : true);
    setSelectedIndex(index);
  };

  return (
    <Sidebar
      className="z-0 mt-2"
      buttons={buttons}
      initialIsOpen={isOpen}
      initialSelectedIndex={selectedIndex}
      onClose={onClose}
      onSelect={onSelect}
      tooltipPlacement="left"
    >
      {sidebarComponents.map((Component) => (
        <Component key={`sidebar-${getDisplayName(Component)}`} legId={legId} onClose={onClose} />
      ))}
    </Sidebar>
  );
};

export default DrayageLegSidebar;
