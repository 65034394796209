import {browserHistory} from 'react-router';
import {Popover, SearchBar, SvgIcon} from '@shipwell/shipwell-ui';
import {ChangeEvent} from 'react';
import {useLocalStorage} from 'App/utils/hooks/useLocalStorage';

enum SearchTypes {
  All = 'All',
  Filters = 'Filters'
}

const DASHBOARD_SEARCH_TYPE_KEY = 'sw-dashboard-searchType';

export const DashboardSearch = () => {
  const searchParamURL = new URLSearchParams(location.search);
  const query = searchParamURL.get('q') || '';

  const [searchType, setSearchType] = useLocalStorage({
    key: DASHBOARD_SEARCH_TYPE_KEY,
    initialValue: SearchTypes.All
  });

  const getSearchTypeAllParams = () => {
    const newParams = new URLSearchParams();
    ['true', 'false'].map((value) => newParams.append('archived', value));
    // preserve page size
    const currentPageSize = searchParamURL.get('page_size');
    if (currentPageSize) newParams.set('page_size', currentPageSize);
    return newParams;
  };

  const handleSearch = (params: URLSearchParams, query: string) => {
    params.set('q', query);
    // always reset to page 0 on search
    params.set('page_index', '0');
    browserHistory.replace({
      pathname: location.pathname,
      search: `?${params.toString()}`
    });
  };

  const handleChange = (newQuery?: string) => {
    const newParams = searchType === SearchTypes.All && !!newQuery ? getSearchTypeAllParams() : searchParamURL;
    handleSearch(newParams, newQuery || '');
  };

  const handleSearchTypeChange = () => {
    if (query) {
      const newParams = getSearchTypeAllParams();
      handleSearch(newParams, query);
    }
  };

  return (
    <div className="grouped-field group flex w-[336px] divide-x-1 divide-sw-border has-[:focus]:divide-sw-focused">
      <Popover
        trigger={
          <Popover.Button
            variant="secondary"
            // @ts-ignore props
            className="rounded-l-[3px] rounded-r-none border-1 border-r-0 border-sw-border bg-sw-background-component pl-2 pr-0 focus:border-sw-primary"
          >
            <div className="flex items-center">
              {searchType}
              <SvgIcon name="CarrotDown" />
            </div>
          </Popover.Button>
        }
      >
        {({setIsOpen}: {setIsOpen: (value: React.SetStateAction<boolean>) => void}) => (
          <Popover.MenuList>
            <Popover.MenuListItemButton
              onClick={() => {
                setSearchType((prevSearchType) => {
                  if (prevSearchType === SearchTypes.Filters) handleSearchTypeChange();
                  return SearchTypes.All;
                });
                setIsOpen(false);
              }}
            >
              All Shipments
            </Popover.MenuListItemButton>
            <Popover.MenuListItemButton
              onClick={() => {
                setSearchType(SearchTypes.Filters);
                setIsOpen(false);
              }}
            >
              Within Filters
            </Popover.MenuListItemButton>
          </Popover.MenuList>
        )}
      </Popover>
      <div className="w-full">
        <SearchBar
          name="search"
          label="Search Shipments"
          value={query}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.currentTarget.value?.trim())}
          clearable
        />
      </div>
    </div>
  );
};
