import axios from 'axios';
import {
  ReferenceDataApi,
  Configuration,
  DocumentsApi,
  Stop as SdkStop,
  ShipwellUiApi,
  RemoveLegacyShipmentStageRequest,
  TendersApi,
  AppointmentType,
  StopStatusEnum,
  StopCustomData,
  StopStatusReasonCodeEnum
} from '@shipwell/backend-core-sdk';
import {
  Shipment,
  ShipmentsApi,
  ShippingDashboardApi,
  ShipmentsApiShipmentsGetRequest,
  ShippingDashboardApiShipwellUiShippingDashboardGetRequest,
  ShipmentsApiShipmentsExternalShipmentIdTrackingGetRequest,
  ShipmentsApiShipmentsShipmentIdTrackingGetRequest,
  ShipmentsApiShipmentsPostRequest,
  ShipmentsApiShipmentsShipmentIdMessagesPostRequest,
  ShipmentsApiShipmentsShipmentIdGetRequest,
  ShipmentsApiShipmentsShipmentIdDocumentsGetRequest,
  ShipmentsApiShipmentsExternalShipmentIdGetRequest,
  ShipmentsApiShipmentsShipmentIdCarrierConfigGetRequest,
  ShipmentsApiShipmentsShipmentIdDocumentsGenerateShippingLabelGetRequest,
  ShipmentsApiShipmentsShipmentIdPutRequest,
  ShipmentsApiShipmentsShipmentIdDocumentsDocumentIdPutRequest,
  ShipmentsApiShipmentsShipmentIdDocumentsDocumentIdDeleteRequest,
  ShipmentsApiShipmentsShipmentIdDocumentsDocumentIdGetRequest,
  ShipmentsApiShipmentsShipmentIdStopsGetRequest,
  ShipmentActionsApi,
  ShipmentActionsApiShipmentsShipmentIdCancelPostRequest,
  ShipmentActionsApiShipmentsShipmentIdSharePostRequest,
  ShipmentsApiShipmentsShipmentIdTimelineEventsGetRequest,
  ShipmentMessage,
  ShipmentDocumentMetadata,
  DocumentAuditlog,
  ShipmentsApiShipmentsShipmentIdGroupedTimelineEventsGetRequest,
  ShipmentsApiShipmentsShipmentIdTimelineEventsShipmentTimelineEventIdGetRequest,
  ShipmentsApiShipmentsShipmentIdTimelineEventsPostRequest,
  ShipmentsApiShipmentsShipmentIdTrackingPostRequest,
  ShipmentsApiShipmentsShipmentIdTimelineEventsShipmentTimelineEventIdDeleteRequest,
  ShipmentsApiShipmentsShipmentIdTimelineEventsShipmentTimelineEventIdPutRequest,
  ShipmentNote,
  ShipmentsApiShipmentsAuctionStatsGetRequest,
  ShipmentLocationResponse,
  ShipmentsApiShipmentsShipmentIdRemoveOrdersOrderIdPutRequest,
  SharedAddress,
  ShipmentsApiShipmentsPickupsGetRequest,
  ShipmentsApiShipmentsPickupsPostRequest,
  ShipmentsApiShipmentsPickupsShipmentPickupIdPutRequest,
  ShipmentPickup,
  ReferenceDataApi as ReferenceDataApiSingleParam,
  ReferenceDataApiListChargeCodesRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getV2ApiAllOfPaginated, getV3ApiAllOfPaginated} from '../typedUtils';
import {basePath, basePathWithOutV2} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';
import {decimalVersionHeader} from 'App/api/consts';

const configuration = new Configuration({
  basePath,
  apiKey: getAccessToken
});
const configurationWithoutV2 = new Configuration({
  basePath: basePathWithOutV2,
  apiKey: getAccessToken
});
declare global {
  interface Window {
    decimalSupportForShipmentLineItems: boolean;
  }
}

const referenceDataApi = new ReferenceDataApi(configuration);
const referenceDataApiSingleParam = new ReferenceDataApiSingleParam(configurationWithoutV2);
const shipmentsApi = new ShipmentsApi(configuration);
const shippingDashboardApi = new ShippingDashboardApi(configuration);
const documentsApi = new DocumentsApi(configuration);
const shipwellUiApi = new ShipwellUiApi(configuration);
const tendersApi = new TendersApi(configuration);
const shipmentActionsApi = new ShipmentActionsApi(configuration);

export const fetchShipmentModesPromise = () => referenceDataApi.shipmentsShipmentModesGet();
export const fetchPackageTypesPromise = () => referenceDataApi.shipmentsPackageTypesGet();
export const fetchEquipmentTypesPromise = () => referenceDataApi.shipmentsEquipmentTypesGet();
export const fetchAccessorialCodesPromise = () => referenceDataApi.shipmentsAccessorialsGet();
export const fetchHazmatCodesPromise = () => referenceDataApi.shipmentsHazmatGet();
export const fetchShipmentServiceLevels = () => referenceDataApi.shipmentsServiceLevelsGet();
export const getShipmentDocuments = (shipmentId: ShipmentsApiShipmentsShipmentIdDocumentsGetRequest['shipmentId']) =>
  shipmentsApi.shipmentsShipmentIdDocumentsGet({shipmentId});
export const fetchExternalShipmentPromise = (
  shipmentId: ShipmentsApiShipmentsExternalShipmentIdGetRequest['shipmentId'],
  key: ShipmentsApiShipmentsExternalShipmentIdGetRequest['key']
) =>
  shipmentsApi.shipmentsExternalShipmentIdGet(
    {shipmentId, key},
    {...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})}
  );

export async function getAllShipmentDocuments(shipmentId: string): Promise<ShipmentDocumentMetadata[]> {
  return getV2ApiAllOfPaginated(async (page, pageSize) => {
    const response = await shipmentsApi.shipmentsShipmentIdDocumentsGet({
      shipmentId,
      page,
      pageSize
    });
    return response.data;
  });
}

export function getBreadcrumbsUnauth({
  shipmentId,
  key,
  beginDatetime,
  endDatetime,
  filterDistance
}: ShipmentsApiShipmentsExternalShipmentIdTrackingGetRequest) {
  return shipmentsApi.shipmentsExternalShipmentIdTrackingGet({
    shipmentId,
    key,
    beginDatetime,
    endDatetime,
    filterDistance
  });
}

export function getBreadcrumbs({
  shipmentId,
  beginDatetime,
  endDatetime,
  filterDistance
}: ShipmentsApiShipmentsShipmentIdTrackingGetRequest) {
  return shipmentsApi.shipmentsShipmentIdTrackingGet({shipmentId, beginDatetime, endDatetime, filterDistance});
}

export function createShipment(
  shipment: ShipmentsApiShipmentsPostRequest['shipment'],
  xCompanyId?: ShipmentsApiShipmentsPostRequest['xCompanyId']
) {
  return shipmentsApi.shipmentsPost(
    {shipment, xCompanyId},
    {...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})}
  );
}

export function getFullShipmentDetails(
  shipmentId: ShipmentsApiShipmentsShipmentIdGetRequest['shipmentId'],
  xCompanyId?: ShipmentsApiShipmentsShipmentIdGetRequest['xCompanyId']
) {
  return shipmentsApi.shipmentsShipmentIdGet(
    {shipmentId, xCompanyId},
    {...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})}
  );
}

export function createShipmentMessage(
  shipmentId: ShipmentsApiShipmentsShipmentIdMessagesPostRequest['shipmentId'],
  message: ShipmentsApiShipmentsShipmentIdMessagesPostRequest['shipmentMessage']
) {
  return shipmentsApi.shipmentsShipmentIdMessagesPost({shipmentId, shipmentMessage: message});
}

export async function getShipmentMessages(shipmentId: string): Promise<ShipmentMessage[]> {
  const messages = await getV2ApiAllOfPaginated(async (page: number, pageSize: number) => {
    const response = await shipmentsApi.shipmentsShipmentIdMessagesGet({page, pageSize, shipmentId});
    return response.data;
  });
  return messages;
}

export async function getShipmentInternalNotes(shipmentId: string): Promise<ShipmentNote[]> {
  const notes = await getV2ApiAllOfPaginated(async (page: number, pageSize: number) => {
    const response = await shipmentsApi.shipmentsShipmentIdNotesGet({page, pageSize, shipmentId});
    return response.data;
  });
  return notes;
}

export async function postShipmentInternalNote(shipmentId: string, message: string): Promise<ShipmentNote> {
  const response = await shipmentsApi.shipmentsShipmentIdNotesPost({shipmentId, shipmentNote: {message}});
  return response.data;
}

export function getCarrierConfig(shipmentId: ShipmentsApiShipmentsShipmentIdCarrierConfigGetRequest['shipmentId']) {
  return shipmentsApi.shipmentsShipmentIdCarrierConfigGet({shipmentId});
}
export function generateShippingLabel(
  shipmentId: ShipmentsApiShipmentsShipmentIdDocumentsGenerateShippingLabelGetRequest['shipmentId']
) {
  return shipmentsApi.shipmentsShipmentIdDocumentsGenerateShippingLabelGet({shipmentId});
}
export function getChargeCategories(sendShipwellVersionHeader = false) {
  return referenceDataApi.shipmentsChargeLineItemCategoriesGet({
    ...(sendShipwellVersionHeader ? {headers: decimalVersionHeader} : {})
  });
}
export function getChargeCodes(params?: ReferenceDataApiListChargeCodesRequest) {
  return referenceDataApiSingleParam.listChargeCodes(params);
}
export function fetchShippingDashboard(opts: ShippingDashboardApiShipwellUiShippingDashboardGetRequest) {
  return shippingDashboardApi.shipwellUiShippingDashboardGet(opts, {
    ...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})
  });
}
export async function getDocumentTypes() {
  return documentsApi.documentsDocumentTypesGet();
}

export const generateDrayageHouseBol = (shipmentId: string) => {
  return documentsApi.shipwellUiShipmentsShipmentIdGenerateDrayageHouseBolGet(shipmentId);
};

export function deleteLegacyShipmentStage({v2_shipment_id, v3_shipment_id}: RemoveLegacyShipmentStageRequest) {
  return shipwellUiApi.shipwellUiLegacyShipmentsRemoveLegacyShipmentStagePost({v2_shipment_id, v3_shipment_id});
}

export function getAppointmentTypes() {
  return referenceDataApi.shipmentsAppointmentTypesGet();
}

export function editShipmentById({
  shipmentId,
  shipment
}: {
  shipmentId: ShipmentsApiShipmentsShipmentIdPutRequest['shipmentId'];
  shipment: ShipmentsApiShipmentsShipmentIdPutRequest['shipment'];
}) {
  return shipmentsApi.shipmentsShipmentIdPut(
    {shipmentId, shipment},
    {...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})}
  );
}

export type GetTendersProps = {
  page?: number;
  pageSize?: number;
  createdByCompanyId?: string;
  shipmentCustomerReferenceNumber?: string;
  externalEdiSystemShipmentId?: string;
  shipmentId?: string;
};

export function getTenders(data: GetTendersProps) {
  return tendersApi.tendersGet(
    data.page,
    data.pageSize,
    data.createdByCompanyId,
    data.shipmentCustomerReferenceNumber,
    data.externalEdiSystemShipmentId,
    data.shipmentId
  );
}

/**
 * mapped stop from backend-core-sdk stop with
 * the included stopNumber necessary for the rest of the application. This function is guaranteed
 * to always give you the stops in ascending order by `ordinal_index` regardless of possible gaps.
 */
export type Stop = {
  appointment_type?: AppointmentType | null;
  custom_data?: StopCustomData | null;
  status?: StopStatusEnum | null;
  status_reason_code?: StopStatusReasonCodeEnum | null;
  stopNumber: number;
} & Pick<
  SdkStop, // if you are running into typing issues remove the property from this list and add it to the Stop
  | 'accessorials'
  | 'alerts'
  | 'appointment_needed'
  | 'carrier_specified_eta'
  | 'confirmed_arrival_at'
  | 'confirmed_departure_at'
  | 'created_at'
  | 'display_eta_window'
  | 'display_planned_window'
  | 'display_schedule'
  | 'eta_overridden_by'
  | 'id'
  | 'instructions'
  | 'internal_notes'
  | 'is_completed'
  | 'is_dropoff'
  | 'is_pickup'
  | 'location'
  | 'ordinal_index'
  | 'planned_date'
  | 'planned_time_window_end'
  | 'planned_time_window_start'
  | 'predictive_model_eta'
  | 'trip_management_eta'
  | 'trip_management_eta_last_updated'
  | 'unconfirmed_arrival_at'
  | 'unconfirmed_departure_at'
  | 'updated_at'
>;
/**
 * Gets all stops from shipment stops paginated route and consolidates them into an ordered array
 * by `ordinal_index` ascending. Stop Number is appended to be properly used throughout the application.
 */
export async function getShipmentStops({shipmentId}: ShipmentsApiShipmentsShipmentIdStopsGetRequest) {
  const stops = await getV3ApiAllOfPaginated(async (page, pageSize) => {
    const {data} = await shipmentsApi.shipmentsShipmentIdStopsGet(
      {shipmentId, page, pageSize},
      {...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})}
    );
    return {
      data: data?.results ?? [],
      total_count: data?.total_count ?? 0
    };
  });
  if (!stops?.length) {
    return [];
  }
  const stopsSorted = stops
    .sort((left, right) => left.ordinal_index - right.ordinal_index)
    .map<Stop>((stop, index) => ({
      ...stop,
      stopNumber: index + 1
    }));
  return stopsSorted;
}

export async function getShipments(opts: ShipmentsApiShipmentsGetRequest) {
  /// Add header in here.
  const {data} = await shipmentsApi.shipmentsGet(opts, {
    ...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})
  });
  return data;
}

export const postShipmentDocument = async (
  shipmentId: string,
  args: {
    file: File;
    type: string;
    description: string;
  },
  isCarrierDocument = false
): Promise<ShipmentDocumentMetadata> => {
  const {file, type, description} = args;
  const response = await shipmentsApi.shipmentsShipmentIdDocumentsPost({
    shipmentId,
    file,
    type,
    description,
    isCarrierDocument
  });
  return response.data;
};

export const updateShipmentDocument = ({
  shipmentId,
  documentId,
  shipmentDocumentMetadata
}: ShipmentsApiShipmentsShipmentIdDocumentsDocumentIdPutRequest) => {
  return shipmentsApi.shipmentsShipmentIdDocumentsDocumentIdPut({shipmentId, documentId, shipmentDocumentMetadata});
};

export const deleteShipmentDocument = ({
  shipmentId,
  documentId
}: ShipmentsApiShipmentsShipmentIdDocumentsDocumentIdDeleteRequest) => {
  return shipmentsApi.shipmentsShipmentIdDocumentsDocumentIdDelete({shipmentId, documentId});
};

export function getCarrierDocument(
  shipmentId: ShipmentsApiShipmentsShipmentIdDocumentsDocumentIdGetRequest['shipmentId'],
  documentId: ShipmentsApiShipmentsShipmentIdDocumentsDocumentIdGetRequest['documentId']
) {
  return shipmentsApi.shipmentsShipmentIdDocumentsDocumentIdGet({shipmentId, documentId});
}

export const getShipmentTags = () => {
  return shipmentsApi.shipmentsTagsGet();
};

export const getPieceTypes = (options = {}) => {
  return referenceDataApi.shipmentsPieceTypesGet(options);
};

export async function getShipment(
  shipmentId: ShipmentsApiShipmentsShipmentIdGetRequest['shipmentId']
): Promise<Shipment> {
  const shipmentResponse = await shipmentsApi.shipmentsShipmentIdGet(
    {shipmentId},
    {...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})}
  );
  return shipmentResponse.data;
}

export const updateShipment = (request: ShipmentsApiShipmentsShipmentIdPutRequest) => {
  return shipmentsApi.shipmentsShipmentIdPut(request, {
    ...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})
  });
};

export const cancelShipment = (request: ShipmentActionsApiShipmentsShipmentIdCancelPostRequest) => {
  return shipmentActionsApi.shipmentsShipmentIdCancelPost(request, {
    ...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})
  });
};

export const shareShipment = (request: ShipmentActionsApiShipmentsShipmentIdSharePostRequest) => {
  return shipmentActionsApi.shipmentsShipmentIdSharePost(request, {
    ...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})
  });
};

export async function getShipmentDocumentsAuditlog(shipmentId: string): Promise<DocumentAuditlog> {
  const response = await shipmentsApi.shipmentsShipmentIdDocumentsAuditlogGet({shipmentId});
  return response.data;
}

//Timeline Events

export const getShipmentTimelineEvents = (request: ShipmentsApiShipmentsShipmentIdTimelineEventsGetRequest) => {
  return shipmentsApi.shipmentsShipmentIdTimelineEventsGet(request);
};

export const getGroupedTimelineEvents = (request: ShipmentsApiShipmentsShipmentIdGroupedTimelineEventsGetRequest) =>
  shipmentsApi.shipmentsShipmentIdGroupedTimelineEventsGet(request);

export const getTimelineEventDetails = (
  request: ShipmentsApiShipmentsShipmentIdTimelineEventsShipmentTimelineEventIdGetRequest
) => shipmentsApi.shipmentsShipmentIdTimelineEventsShipmentTimelineEventIdGet(request);

export const createNewTimelineEvent = (request: ShipmentsApiShipmentsShipmentIdTimelineEventsPostRequest) =>
  shipmentsApi.shipmentsShipmentIdTimelineEventsPost(request);

export const postBreadcrumbPromise = (request: ShipmentsApiShipmentsShipmentIdTrackingPostRequest) =>
  shipmentsApi.shipmentsShipmentIdTrackingPost(request);

export const deleteTimelineEvent = (
  request: ShipmentsApiShipmentsShipmentIdTimelineEventsShipmentTimelineEventIdDeleteRequest
) => shipmentsApi.shipmentsShipmentIdTimelineEventsShipmentTimelineEventIdDelete(request);

export const updateTimelineEventDetails = (
  request: ShipmentsApiShipmentsShipmentIdTimelineEventsShipmentTimelineEventIdPutRequest
) => shipmentsApi.shipmentsShipmentIdTimelineEventsShipmentTimelineEventIdPut(request);

export const getAuctionStats = (params?: ShipmentsApiShipmentsAuctionStatsGetRequest) =>
  shipmentsApi.shipmentsAuctionStatsGet(params);

export const removePurchaseOrderFromShipment = (params: ShipmentsApiShipmentsShipmentIdRemoveOrdersOrderIdPutRequest) =>
  shipmentsApi.shipmentsShipmentIdRemoveOrdersOrderIdPut(params);

export const getShipmentContainerDetails = (shipmentId: string) =>
  shipmentsApi.shipmentsShipmentIdContainerDetailsGet({shipmentId});

export const getShipmentViaSmsToken = async (smsToken: string, shipmentId: string): Promise<Shipment> => {
  const response = await axios.get<Shipment>(`${basePath ?? ''}/shipments/external/${shipmentId}`, {
    headers: {
      authorization: `SMSTracking ${smsToken}`,
      ...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})
    }
  });
  return response.data;
};

export const getShipmentSmsTrackingInfo = async (
  smsToken: string,
  shipmentId: string
): Promise<ShipmentLocationResponse> => {
  const response = await axios.get<ShipmentLocationResponse>(
    `${basePath ?? ''}/shipments/external/${shipmentId}/tracking`,
    {
      headers: {
        authorization: `SMSTracking ${smsToken}`
      }
    }
  );
  return response.data;
};

export const addCorrogoOrdersToShipment = async ({
  shipmentId,
  orderIds,
  sharedDestination,
  sharedOrigin
}: {
  shipmentId: string;
  orderIds: string[];
  sharedDestination?: SharedAddress;
  sharedOrigin?: SharedAddress;
}) => {
  const axiosResponse = await shipmentsApi.shipmentsShipmentIdAddOrdersPut(
    {
      shipmentId,
      //this is one of the downsides of using the named parameters in the generated SDK- sometimes, the open api spec
      //doesn't define these properties and they get assigned an arbitrary and meaningless property name.
      //See the spec if you are confused about the inclusion of inlineObject22 here.
      inlineObject23: {
        purchase_order_ids: orderIds,
        shared_destination: sharedDestination,
        shared_origin: sharedOrigin
      }
    },
    {...(window.decimalSupportForShipmentLineItems ? {headers: decimalVersionHeader} : {})}
  );
  return axiosResponse.data;
};

export const createShipmentsRoundTripJob = async (shipmentIds: string[]) => {
  const axiosResponse = await shipmentsApi.shipmentsRoundTripJobPost({inlineObject22: {shipment_ids: shipmentIds}});
  return axiosResponse.data;
};

type GetShipmentsRoundTripProcessingResponse = {
  message: string;
  job_status: string;
};
type GetShipmentsRoundTripCompletedResponse = {not_updated_shipments: string[]; updated_shipments: string[]};
export type GetShipmentsRoundTripResponse =
  | GetShipmentsRoundTripProcessingResponse
  | GetShipmentsRoundTripCompletedResponse;
export const getShipmentsRoundTripJob = async (roundTripJobId: string) => {
  const axiosResponse = await shipmentsApi.shipmentsRoundTripJobRoundTripJobIdGet({roundTripJobId});
  return axiosResponse.data as GetShipmentsRoundTripResponse;
};

export const getWorkflowExecutions = async (shipmentIds: string[]) => {
  const axiosResponse = await shippingDashboardApi.shipwellUiShippingDashboardShipmentCurrentWorkflowExecutionsPost({
    shippingDashboardShipmentCurrentWorkflowRequest: {shipment_ids: shipmentIds}
  });
  return axiosResponse.data.data;
};

// Parcel
export const getShipmentPickup = async (shipmentPickupId: string) => {
  const axiosResponse = await shipmentsApi.shipmentsPickupsShipmentPickupIdGet({shipmentPickupId});
  return axiosResponse.data;
};

export const getShipmentPickups = async (requestParameters: ShipmentsApiShipmentsPickupsGetRequest) => {
  const axiosResponse = await shipmentsApi.shipmentsPickupsGet(requestParameters);
  return axiosResponse.data;
};

export const createShipmentPickup = async (requestParameters: ShipmentsApiShipmentsPickupsPostRequest) => {
  const axiosResponse = await shipmentsApi.shipmentsPickupsPost(requestParameters);
  return axiosResponse.data;
};

export type ShipmentPickupPutRequest = Omit<ShipmentPickup, 'shipments'> & {shipments?: string[]};
export type CorrectedShipmentsApiShipmentsPickupsShipmentPickupIdPutRequest = Omit<
  ShipmentsApiShipmentsPickupsShipmentPickupIdPutRequest,
  'shipmentPickup'
> & {shipmentPickup: ShipmentPickupPutRequest};
export const updateShipmentPickup = async (
  requestParameters: CorrectedShipmentsApiShipmentsPickupsShipmentPickupIdPutRequest
) => {
  const axiosResponse = await shipmentsApi.shipmentsPickupsShipmentPickupIdPut(
    requestParameters as ShipmentsApiShipmentsPickupsShipmentPickupIdPutRequest
  );
  return axiosResponse.data;
};
