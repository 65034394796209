import {connect} from 'react-redux';
import {Paper} from '@material-ui/core';
import {uspsServiceOptions} from 'App/utils/parcelConstants';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import ParcelRateList from 'App/components/parcelRateList';
import './styles.scss';

const USPSRates = (props) => {
  const {uspsPackageTypes, packageType, numberOfPackages, quotes, handleSelectQuote} = props;

  const serviceLevels = uspsServiceOptions.reduce((options, option) => {
    const {id, name} = option;
    return {...options, [id]: name};
  }, {});

  return (
    <Paper className="usps-rates">
      <div className="paper__header">
        <img src="/images/usps_logo.svg" alt="USPS Logo" width="170" />
      </div>

      {quotes && quotes.usps && quotes.usps.length > 0 && (
        <div className="usps-rates__table table__container">
          <ParcelRateList
            quotes={quotes.usps}
            serviceLevels={serviceLevels}
            packageType={packageType}
            packageTypes={uspsPackageTypes}
            numberOfPackages={numberOfPackages}
            handleSelectQuote={handleSelectQuote}
            providerCode="USPS"
          />
        </div>
      )}

      {quotes && quotes.usps && quotes.usps.length < 1 && (
        <div className="paper__content text-center ">
          <div className="usps-rates__noResults">
            <h3>No rates available for this shipment.</h3>
          </div>
        </div>
      )}

      {(!quotes || !quotes.usps) && (
        <ShipwellLoader loading={!quotes || !quotes.usps} loadingText="Searching for quotes..." />
      )}
    </Paper>
  );
};

export default connect((state) => ({
  uspsPackageTypes: state.shipments.uspsPackageTypes.reduce(
    (types, type) => ({...types, [type.id]: `USPS ${type.label}`}),
    {}
  )
}))(USPSRates);
