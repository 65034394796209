/* eslint-disable import/no-namespace */
/* eslint-disable camelcase */
import {Component} from 'react';
import moment from 'moment';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {change, SubmissionError} from 'redux-form';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {useQueryClient} from '@tanstack/react-query';
import pick from 'lodash/pick';
import isNull from 'lodash/isNull';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import {Button as BsButton} from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import {
  DeprecatedButton,
  Card,
  CollapsibleCardContent,
  SvgIcon,
  Dropdown,
  Modal,
  DisplayValue,
  Tooltip,
  IconButtonWithTooltip
} from '@shipwell/shipwell-ui';
import classNames from 'classnames';
import {removeCommasAndDollarSign, unpackErrors, formatCurrencyValue, permViewInvoice} from '../../utils/globals';
import FinancialsForm from '../Marketplace/components/forms/FinancialsForm';
import {calculateGrandTotal} from './utils/financials';
import ShipmentInvoiceForm from './forms/ShipmentInvoiceForm';
import ShipmentRateTableForm from './forms/ShipmentRateTableForm';
import ShipmentBillingForm from './forms/ShipmentBillingForm';
import ShipmentEmailInvoiceForm from './forms/ShipmentEmailInvoiceForm';
import TriumphPayTable from './components/TriumphPayTable';
import {FinancialsExpanded} from 'App/containers/Book/FormSections/Financials/components';
import {
  FreightInvoicesList,
  FreightInvoicesListCard
} from 'App/containers/settlements/freightInvoices/components/FreightInvoicesList';
import {searchForCarrierByID} from 'App/actions/vendors';
import {fetchTriumphPayVendorInvoices} from 'App/actions/_vendors';
import * as actions from 'App/actions/shipments';
import * as shipmentDetailsActions from 'App/actions/shipmentdetails';
import * as integrationsActions from 'App/actions/integrations';
import * as usersActions from 'App/actions/users';
import {
  checkTriumphPayAuth,
  getShipmentInvoices,
  getCompanyInvoice,
  createInvoice,
  sendInvoice,
  getShipmentBills,
  getBill,
  createBill,
  getFinancialManagementSystems
} from 'App/actions/_integrations';
import ShipmentBillTo from 'App/containers/Shipment/ShipmentBillTo';
import ShipmentRemitTo from 'App/containers/Shipment/ShipmentRemitTo';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import ShipmentFinancialStatus from 'App/containers/Shipment/components/shipmentFinancialStatus';
import PollingProcessor from 'App/containers/Shipment/components/polling/pollingProcessor';
import {formatCurrency} from 'App/utils/internationalConstants';
import {RollbarErrorBoundary} from 'App/common/ErrorBoundary';
import {bind} from 'App/utils/camelize';
import WithStatusToasts from 'App/components/withStatusToasts';
import {CreateFreightInvoiceModalForm} from 'App/containers/settlements/freightInvoices/components/FreightInvoiceForm';
import {CreateFreightInvoiceLineItemButton} from 'App/containers/settlements/freightInvoices/components/TableCells/components/CreateFreightInvoiceLineItemButton';
import {TenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/TenderingUserPermissionFallback';
import {
  SHIPMENT_CREATE_SPOT_NEGOTIATIONS,
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

// move to util
export function titleCase(str) {
  str = str.split('_').join(' ');
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const UseQueryClient = ({children}) => children(useQueryClient());

export class ShipmentFinancialsForm extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);

    bind(this, [
      'cancelEdit',
      'createCarrier',
      'dispatchMarkupChange',
      'updated',
      'createManualRate',
      'getInstantRates',
      'getMarketplaceRates',
      'getFinancialManagementSystems',
      'connectFms',
      'openBillModal',
      'openInvoiceModal',
      'openRateTableModal',
      'openRateTableDetailModal',
      'selectOne',
      'selectAll',
      'selectDoc',
      'getFinancialNames',
      'renderInvoiceModal',
      'renderEmailModal',
      'renderBillModal',
      'selectAllBill',
      'handleInvoiceFormSubmit',
      'handleSubmitRateTable',
      'handleRetryInvoice',
      'submitInvoice',
      'handleEmailSubmit',
      'handleBillFormSubmit',
      'submitBill',
      'handleRetryBill',
      'fetchInvoices',
      'fetchBills',
      'getInvoiceActions',
      'getBillActions',
      'fetchTriumphPayUpdatedList'
    ]);

    this.state = {
      editFinancials: false,
      newQuoteOverride: false,
      setMargin: '',
      showBillToForm: false,
      showInvoiceModal: false,
      showRateTableModal: false,
      showRateTableDetailModal: false,
      showBillModal: false,
      checkedAll: false,
      selectList: [],
      total: 0,
      selectedDocs: [],
      openInvoiceSnackbar: false,
      openEmailSnackbar: false,
      openBillSnackbar: false,
      isAuthenticated: false,
      financials: [],
      showEmailModal: false,
      invoice: null,
      customer: null,
      editingMaxBuy: false,
      canCreateInvoice: false,
      configuredFms: [],
      processingInvoiceId: null,
      processingBillId: null,
      processingInvoiceIds: [],
      processingBillIds: [],
      showCarrierInvoiceModal: false,
      showFinancialsModal: false,
      editCustomerFinancials: false,
      editVendorFinancials: false
    };
  }

  componentDidMount() {
    if (this.props.company?.feature_flags?.invoicing_enabled) {
      if (this.props.user?.permissions?.includes(permViewInvoice)) {
        this.getFinancialManagementSystems();
      }
    }
    if (this.props.user?.permissions?.includes('invoicing.update_invoices')) {
      this.setState({canCreateInvoice: true});
    }
    if (this.props.one?.relationship_to_vendor?.vendor) {
      this.props.searchForCarrierByID(this.props.one.relationship_to_vendor.vendor.id);
    }
    this.props.checkTriumphPayAuth();
    this.fetchInvoices();
    this.fetchBills();

    const triumphPayId = this.props.selectedRelationship?.external_payment_processor_id;
    if (triumphPayId && this.props.paymentProcessorViewEnabled) {
      this.props.fetchTriumphPayVendorInvoices(triumphPayId, this.props.one.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //if margin or markup changes, calculate the other
    if (this.props.markup !== nextProps.markup) {
      let carrierTotal = 0;
      let calculatedMargin = 0;
      if (nextProps.financialTotals?.length > 0) {
        carrierTotal = nextProps.financialTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur));
        calculatedMargin = parseFloat((parseFloat(nextProps.markup) / carrierTotal) * 100).toFixed(2);
        if (!isNaN(calculatedMargin)) {
          this.setState({setMargin: calculatedMargin + '%'});
        }
      }
    }

    const triumphPayId = nextProps.selectedRelationship?.external_payment_processor_id;
    if (
      triumphPayId &&
      this.props.selectedRelationship?.external_payment_processor_id !== triumphPayId &&
      this.props.paymentProcessorViewEnabled
    ) {
      this.props.fetchTriumphPayVendorInvoices(triumphPayId, this.props.one.id);
    }

    if (this.props.isTriumphPayAuthed !== nextProps.isTriumphPayAuthed) {
      this.props.checkTriumphPayAuth();
    }
  }

  async getFinancialManagementSystems() {
    const fmsResult = await this.props.getFinancialManagementSystems();
    if (fmsResult?.ok && fmsResult?.body?.configured_integrations) {
      this.setState({
        configuredFms: fmsResult.body.configured_integrations
      });
    }
  }

  getFinancialNames(relationship) {
    const financials = [];
    if (this.props.one?.[relationship]?.vendor_charge_line_items) {
      const vendor_charge_line_items = [...this.props.one[relationship].vendor_charge_line_items];
      let name = '';
      for (let i = 0; i < vendor_charge_line_items.length; i++) {
        const lineItem = vendor_charge_line_items[i];
        let categories = [];
        if (this.props.chargeCategories) {
          categories = this.props.chargeCategories.filter((c) => c.id === lineItem.category);
        }
        if (categories.length > 0) {
          name = categories[0].name;
        }
        lineItem.name = (
          <Grid container spacing={8} className="shipment__financials-lineItem">
            <Grid item xs={3}>
              <b>{name}</b>
            </Grid>
            <Grid item xs={4}>
              <b>{vendor_charge_line_items[i].unit_name}</b>
            </Grid>
            <Grid item xs={2}>
              <b>{vendor_charge_line_items[i].unit_quantity}</b>
            </Grid>
            <Grid item xs={3} className="shipment__financials-lineItem-cost">
              <b>${formatCurrencyValue(lineItem.amount)}</b>
            </Grid>
          </Grid>
        );
        lineItem.charge_code = {
          id: lineItem.id,
          code: lineItem.category,
          description: lineItem.unit_name
        };
        financials.push(lineItem);
      }
    }
    this.setState({financials: financials});
  }

  dispatchMarkupChange(margin) {
    if (parseFloat(margin)) {
      let carrierTotal = 0;
      let calculatedMarkup = 0;
      if (this.props.financialTotals?.length > 0) {
        carrierTotal = this.props.financialTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur));
        calculatedMarkup = parseFloat(parseFloat(margin / 100) * carrierTotal).toFixed(2);
        if (!isNaN(calculatedMarkup)) {
          this.props.dispatch(change('shipmentFinancialsForm', 'markup', calculatedMarkup));
        }
      }
    } else {
      // set to zero if blank
      this.props.dispatch(change('shipmentFinancialsForm', 'markup', '0'));
    }
  }

  updated() {
    this.props.fetchDetails(this.props.one.id);
  }

  cancelEdit() {
    this.setState({
      editFinancials: false,
      whichFinancials: null
    });
  }

  createManualRate() {
    this.props.manualRateFinancials();
    //change customer markup to false to ensure form is editable
    this.setState({
      createManualRate: true,
      setMargin: '',
      newQuoteOverride: true
    });
  }

  getInstantRates() {
    //if this is an LTL shipment, create an RFQ with autoquote === true
    //proceed to kick off the RFQ process
    const rfqObj = {};
    //send this to P44 for quoting
    rfqObj.autoquote = true;
    rfqObj.shipment = this.props.one.id;
    rfqObj.parent_rfq = null;
    //only dry van can get autoquotes for LTL
    rfqObj.equipment_types = [{id: 1, name: 'Dry Van', machine_readable: 'DRY_VAN'}];
    if (this.props.one.mode) {
      rfqObj.shipment_modes = [this.props.one.mode];
    } else {
      rfqObj.shipment_modes = [{id: 2, code: 'LTL', description: 'Less Than Truckload'}];
    }
    const opts = {};
    //if this shipment doesnt belong to the logged in user, masquerade to create the RFQ
    if (this.props.one.customer.id !== this.props.company.id) {
      //add the xShipperId
      opts['xCompanyId'] = this.props.one.customer.id;
    }

    return this.props.createRFQ(rfqObj, opts).then((rfqResponse) => {
      if (rfqResponse.status === 200) {
        //RFQ was created successfully, now we need to ping the GET route to see if did_finish_autoquote changes to true
        //do that on the quotes page

        this.context.router.push('/marketplace/' + rfqResponse.details.shipment + '/instant-rates');
      } else {
        //show errors
        const errors = rfqResponse.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError, ['stops', 'line_items']);
        submissionError._error = rfqResponse.error_description;
        //handle edge cases for errors here
        throw new SubmissionError(submissionError);
      }
    });
  }

  getMarketplaceRates() {
    //first check if an RFQ exists for this shipment. if not, create one
    //then redirect to marketplace page
    if (!this.props.selectedRFQ) {
      //proceed to kick off the RFQ process
      const rfqObj = {};
      //send this to P44 for quoting
      rfqObj.autoquote = false;
      rfqObj.shipment = this.props.one.id;
      rfqObj.parent_rfq = null;

      if (this.props.one.mode) {
        rfqObj.shipment_modes = [this.props.one.mode];
      } else {
        //assume FTL if getting marketplace rates and not on shipment
        rfqObj.shipment_modes = [{id: 1, code: 'FTL', description: 'Full Truckload'}];
      }
      const opts = {};
      return this.props.createRFQ(rfqObj, opts).then((rfqResponse) => {
        if (rfqResponse.status === 200) {
          this.context.router.push('/marketplace/' + this.props.one.id + '/bids');
        }
      });
    }
    this.context.router.push('/marketplace/' + this.props.one.id + '/bids');
  }

  connectFms() {
    this.context.router.push('/company/integrations');
  }

  openBillModal() {
    this.setState({showBillModal: true});
  }

  onCloseBillModal() {
    this.setState({showBillModal: false});
  }

  openInvoiceModal() {
    this.getFinancialNames('relationship_to_customer');
    this.setState({showInvoiceModal: true}, () => this.selectAll(this.state.financials));
  }

  openRateTableModal() {
    this.setState({showRateTableModal: true});
  }
  openRateTableDetailModal() {
    this.setState({showRateTableDetailModal: true});
  }

  selectAll(financials) {
    let selectList = [];
    let total = 0;
    if (!this.state.checkedAll) {
      total = calculateGrandTotal(financials);
      selectList = selectList.concat(financials);
    }

    this.setState({
      selectList: selectList,
      checkedAll: !this.state.checkedAll,
      total: total
    });
  }

  selectDoc(el) {
    const idx = this.state.selectedDocs.findIndex((doc) => doc.id === el.id);
    const selectList = [...this.state.selectedDocs];
    if (idx === -1) {
      selectList.push(el);
    } else {
      selectList.splice(idx, 1);
    }
    this.setState({selectedDocs: selectList});
  }

  selectOne(financials, el) {
    const idx = this.state.selectList.findIndex((item) => item.id === el.id);
    const selectList = [...this.state.selectList];
    let checkedAll = false;
    if (idx === -1) {
      selectList.push(el);
      if (selectList.length === financials.length) {
        checkedAll = true;
      }
    } else {
      selectList.splice(idx, 1);
    }
    let total = 0;
    if (selectList.length) {
      total = calculateGrandTotal(selectList);
    }
    this.setState({selectList: selectList, total: total, checkedAll: checkedAll});
  }

  // Get the shipment invoices and determine if we need to poll for any processing invoices
  async fetchInvoices() {
    try {
      const invoiceResult = await this.props.getShipmentInvoices(this.props.one.id);
      const processingInvoiceIds =
        invoiceResult?.body?.results
          .filter((invoice) => invoice.processing_status === 'PROCESSING')
          .map((invoice) => invoice.id) || [];

      this.setState({processingInvoiceIds: processingInvoiceIds});
    } catch (error) {
      console.error(error);
    }
  }

  // Get the shipment bills and determine if we need to poll for any processing bills
  async fetchBills() {
    try {
      const billResult = await this.props.getShipmentBills(this.props.one.id);
      const processingBillIds =
        billResult?.body?.results.filter((bill) => bill.processing_status === 'PROCESSING').map((bill) => bill.id) ||
        [];

      //give bill creation some time and fetch for tp invoices after a couple of seconds
      setTimeout(() => {
        this.fetchTriumphPayUpdatedList();
      }, 1500);

      this.setState({processingBillIds: processingBillIds});
    } catch (error) {
      console.error(error);
    }
  }

  //on bill creation, fetch triumph pay list
  fetchTriumphPayUpdatedList() {
    const triumphPayId = this.props.selectedRelationship?.external_payment_processor_id;
    if (triumphPayId && this.props.paymentProcessorViewEnabled) {
      this.props.fetchTriumphPayVendorInvoices(triumphPayId, this.props.one.id);
    }
  }

  async handleInvoiceFormSubmit(attrs) {
    const body = {};
    const customerCompanyId = this.props?.one?.relationship_to_customer?.customer?.id || this.props?.one?.customer?.id;
    if (customerCompanyId) {
      body.customer_company_id = customerCompanyId;
    }

    if (this.state.selectList.length > 0) {
      const mappedLineItems = this.state.selectList.map((lineItem) =>
        pick(lineItem, 'category', 'prepaid_amount', 'unit_amount', 'unit_name', 'unit_quantity')
      );
      body.line_items = mappedLineItems;
    }
    if (this.state.selectedDocs.length) {
      body.document_ids = this.state.selectedDocs.map((el) => el.id);
    }
    if (attrs.customer_memo) {
      body.customer_memo = attrs.customer_memo;
    }

    await this.submitInvoice(body);
  }

  async handleRetryInvoice(invoice) {
    await this.submitInvoice({
      line_items: invoice.line_items.map(({id, ...rest}) => rest),
      document_ids: invoice.documents.map((el) => el.content_object.id),
      customer_company_id: this.props.one.customer.id,
      customer_memo: invoice.customer_memo
    });
  }

  async submitInvoice(body) {
    const postResponse = await this.props.createInvoice(this.props.one.id, body);
    if (postResponse.ok) {
      this.setState({
        showInvoiceModal: false,
        checkedAll: false,
        selectList: [],
        total: 0,
        selectedDocs: [],
        openInvoiceSnackbar: true,
        invoice: postResponse.body
      });
      return this.fetchInvoices();
    }
    const errors = postResponse.field_errors || [];
    let submissionError = {};
    submissionError = unpackErrors(errors, submissionError);
    throw new SubmissionError(submissionError);
  }

  async handleBillFormSubmit(attrs, setSubmitting, setFieldError) {
    const body = {};
    const vendorCompanyId = this.props?.one?.relationship_to_vendor?.vendor?.id || this.props?.one?.current_carrier?.id;
    if (vendorCompanyId) {
      body.vendor_company_id = vendorCompanyId;
    }
    if (attrs.bill_number) {
      body.bill_number = attrs.bill_number;
    }
    if (attrs.due_date) {
      body.due_date = attrs.due_date;
    }
    if (attrs.approved_date) {
      body.approved_date = attrs.approved_date;
    }
    if (attrs.due_date_term) {
      body.due_date_term = attrs.due_date_term;
    }
    if (attrs.approved_date_term) {
      body.approved_date_term = attrs.approved_date_term;
    }

    if (attrs.transaction_date) {
      body.transaction_date = attrs.transaction_date;
    }

    //line items
    if (attrs.charge_line_items) {
      const mappedLineItems = attrs.charge_line_items
        .filter((lineItem) => lineItem.add_to_bill)
        .map((lineItem) => pick(lineItem, 'category', 'prepaid_amount', 'unit_amount', 'unit_name', 'unit_quantity'));
      body.line_items = mappedLineItems;
    }
    //documents
    if (attrs.documents) {
      body.document_ids = attrs.documents.filter((doc) => doc.add_to_bill).map((el) => el.id);
    }

    await this.submitBill(body, setSubmitting, setFieldError);
  }

  async handleRetryBill(bill) {
    await this.submitBill({
      ...bill,
      vendor_company_id: this.props.one.relationship_to_vendor.vendor.id,
      custom_data: bill.custom_data || {}
    });
  }

  async submitBill(body, setSubmitting, setFieldError) {
    const postResponse = await this.props.createBill(this.props.one.id, body);
    if (setSubmitting) {
      setSubmitting(false);
    }

    if (postResponse.ok) {
      this.setState({
        showBillModal: false,
        openBillSnackbar: true
      });
      return this.fetchBills();
    }
    const errors = postResponse.field_errors || [];
    let submissionError = {};
    submissionError = unpackErrors(errors, submissionError);
    if (setFieldError && submissionError && Object.keys(submissionError).length > 0) {
      Object.keys(submissionError).forEach((er) => {
        setFieldError(er, submissionError[er]);
      });
    }
    throw new SubmissionError(submissionError);
  }

  selectAllBill(callback) {
    const formValues = this.props.ShipmentBillingForm;
    if (formValues?.values?.line_items) {
      const isSelected = !formValues.values.checkedAll;
      for (let i = 0; i < formValues.values.line_items.length; i++) {
        callback(change('ShipmentBillingForm', `line_items[${i}].is_selected`, isSelected));
      }
      callback(change('ShipmentBillingForm', `checkedAll`, !isSelected));
    }
  }

  createCarrier() {
    this.setState({openCreateCarrierSnackbar: true});
  }

  handleEmailSubmit(attrs) {
    let emails = [];
    if (attrs.emails && Array.isArray(attrs.emails)) {
      for (let i = 0; i < attrs.emails.length; i++) {
        emails.push(attrs.emails[i].value);
      }
    } else if (attrs.emails && typeof attrs.emails === 'string') {
      emails = [attrs.emails];
    }
    const opts = {body: {recipients: emails}};

    return this.props.sendInvoice(this.state.invoice.id, opts).then((response) => {
      if (response.status === 200) {
        this.setState({
          invoice: null,
          showEmailModal: false,
          openEmailSnackbar: true,
          currentEmail: emails.join(', ')
        });
      }
    });
  }

  async handleSubmitRateTable(attrs) {
    const postResponse = await this.props.shipmentsShipmentIdPut(this.props.one.id, attrs, {});
    if (postResponse.status === 200) {
      this.setState({
        showRateTableModal: false,
        openRateTableSnackbar: true
      });
      this.updated(postResponse);
      return postResponse;
    }
    const errors = postResponse.field_errors || [];
    let submissionError = {};
    submissionError = unpackErrors(errors, submissionError);
    throw new SubmissionError(submissionError);
  }

  renderInvoiceModal() {
    return (
      <ShipmentInvoiceForm
        one={this.props.one}
        selectOne={this.selectOne}
        selectAll={this.selectAll}
        total={this.state.total}
        onSubmit={this.handleInvoiceFormSubmit}
        chargeCategories={this.props.chargeCategories}
        checkedAll={this.state.checkedAll}
        selectDoc={this.selectDoc}
        selectList={this.state.selectList}
        selectedDocs={this.state.selectedDocs}
        financials={this.state.financials}
        selectedCustomer={this.state.selectedCustomer}
        customer={this.props.one?.relationship_to_customer?.customer}
        formValues={this.props.shipmentInvoiceForm}
        processing={this.state.processingInvoiceId}
      />
    );
  }

  renderRateTableModal() {
    return (
      <ShipmentRateTableForm
        vendor={this.props.one?.relationship_to_vendor}
        customer={this.props.one?.relationship_to_customer}
        onSubmit={this.handleSubmitRateTable}
      />
    );
  }

  renderEmailModal() {
    return (
      <ShipmentEmailInvoiceForm
        invoice={this.state.invoice}
        company={this.props.company}
        onSubmit={this.handleEmailSubmit}
        customerAssignment={this.props.customerAssignment}
        formValues={this.props.shipmentInvoiceForm}
        getCompanyDetails={this.props.getCompanyDetails}
        configuredFms={this.state.configuredFms}
      />
    );
  }

  renderBillModal() {
    return (
      <ShipmentBillingForm
        isTriumphPayAuthed={this.props.isTriumphPayAuthed}
        featureFlags={this.props.featureFlags}
        one={this.props.one}
        externalPaymentStatus={this.props?.selectedRelationship?.external_payment_processor_sync_status}
        handleFormSubmit={this.handleBillFormSubmit}
        onClose={() => this.onCloseBillModal()}
      />
    );
  }

  editMaxBuy() {
    this.setState({editingMaxBuy: !this.state.editingMaxBuy});
  }

  updateMaxBuy(attrs) {
    const shipmentObj = JSON.parse(JSON.stringify(attrs));
    if (shipmentObj.metadata?.max_buy_amount) {
      shipmentObj.metadata.max_buy_amount = parseFloat(removeCommasAndDollarSign(shipmentObj.metadata.max_buy_amount));
      // use shipment preferred currency
      shipmentObj.metadata.max_buy_amount_currency = shipmentObj.preferred_currency;
    } else {
      shipmentObj.metadata.max_buy_amount = null;
    }
    if (shipmentObj.metadata?.buy_it_now_amount) {
      shipmentObj.metadata.buy_it_now_amount = parseFloat(
        removeCommasAndDollarSign(shipmentObj.metadata.buy_it_now_amount)
      );
      // use shipment preferred currency
      shipmentObj.metadata.buy_it_now_amount_currency = shipmentObj.preferred_currency;
    } else {
      shipmentObj.metadata.buy_it_now_amount = null;
    }
    return this.props.shipmentsShipmentIdPut(this.props.one.id, shipmentObj, {}).then((response) => {
      if (response.status === 200) {
        this.editMaxBuy();
        this.props.fetchDetails(this.props.one.id);
      } else {
        const errors = response.field_errors || [];
        let submissionError = {};
        submissionError = unpackErrors(errors, submissionError);
        submissionError._error = response.error_description;
        throw new SubmissionError(submissionError);
      }
    });
  }

  getInvoiceActions(invoice) {
    const invoiceActions = [];
    if (invoice.processing_status === 'SUCCESS') {
      invoiceActions.push({
        label: 'Email Invoice',
        callback: () =>
          this.setState({
            invoice: invoice,
            showEmailModal: true
          })
      });
    }
    if (invoice.processing_status === 'ERROR') {
      invoiceActions.push({
        label: 'Retry',
        callback: () => this.handleRetryInvoice(invoice)
      });
    }

    return invoiceActions;
  }

  getBillActions(bill) {
    const billActions = [];
    if (bill.processing_status === 'ERROR') {
      billActions.push({
        label: 'Retry',
        callback: () => this.handleRetryBill(bill)
      });
    }

    return billActions;
  }

  fmsActions() {
    return (
      <div>
        <BsButton bsStyle="link" onClick={this.getMarketplaceRates}>
          Go to My Marketplace
        </BsButton>
      </div>
    );
  }

  setEditCustomerFinancials = (editCustomerFinancials) => this.setState({editCustomerFinancials});
  setEditVendorFinancials = (editVendorFinancials) => this.setState({editVendorFinancials});
  triggerEditVendorFinancials = () => {};
  setTriggerEditVendorFinancials = (editVendorFinancials) => {
    this.triggerEditVendorFinancials = editVendorFinancials;
  };
  triggerEditCustomerFinancials = () => {};
  setTriggerEditCustomerFinancials = (editCustomerFinancials) => {
    this.triggerEditCustomerFinancials = editCustomerFinancials;
  };

  render() {
    const {
      vendorAssignment,
      customerAssignment,
      company,
      selectedRelationship,
      one,
      triumphPayInvoices,
      is_quoting_limited_user,
      setError
    } = this.props;
    const {showFinancialsModal} = this.state;
    const perms = this.props.user?.permissions;
    const canEdit =
      perms?.includes(UPDATE_SHIPMENTS_USER_PERMISSION) || perms?.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION);
    const loadBoardEnabled = company?.feature_flags?.load_board_enabled;
    const shipmentCurrency = one.preferred_currency;

    const financialsEnabled = this.props.company?.feature_flags?.invoicing_enabled && this.state.canCreateInvoice;

    const referenceId = one.reference_id;
    const filteredTriumphPayInvoices = triumphPayInvoices
      ? triumphPayInvoices.filter((invoice) => invoice.external_key.includes(referenceId))
      : [];
    const isCreatingBillDisabled =
      !this.props.one?.relationship_to_vendor?.vendor ||
      !this.props.one?.relationship_to_vendor?.customer_charge_line_items ||
      this.props.one?.relationship_to_vendor?.customer_charge_line_items?.length < 1;

    const handleClose = () => {
      this.setState({showFinancialsModal: false});
    };

    const financialCards = [
      {
        isLegacyFinancialsCard: true,
        type: 'billing',
        cardTitle: vendorAssignment?.vendor?.name ? `Carrier - ${vendorAssignment?.vendor?.name}` : 'Carrier',
        cardAction: (
          <span className="shipment__card-financial-actions-btn-actions flex items-center">
            <IconButtonWithTooltip
              aria-label="Edit financials"
              onClick={() => this.triggerEditVendorFinancials()}
              iconName="Edit"
            />
            <Dropdown
              className="stop-status-edit"
              icon={<SvgIcon name="Overflow" />}
              variant="activeIcon"
              indicator={false}
            >
              {() => (
                <>
                  <li onClick={() => this.triggerEditVendorFinancials()}>Edit Financials</li>
                  <li
                    onClick={() => this.openRateTableModal()}
                    className={classNames({
                      'pointer-events-none opacity-60': !this.props.one?.relationship_to_vendor?.vendor
                    })}
                  >
                    Add Rate Table
                  </li>
                  {financialsEnabled && this.state.configuredFms.length > 0 ? (
                    isCreatingBillDisabled ? (
                      <Tooltip
                        placement="left"
                        tooltipContent={
                          <span>
                            The carrier must have <br /> line items to create a bill
                          </span>
                        }
                      >
                        <li className={'pointer-events-none opacity-60'}>Create Bill</li>
                      </Tooltip>
                    ) : (
                      <li onClick={() => this.openBillModal()}>Create Bill</li>
                    )
                  ) : null}
                </>
              )}
            </Dropdown>
          </span>
        )
      },
      {
        isLegacyFinancialsCard: true,
        type: 'invoicing',
        cardTitle: customerAssignment?.customer?.name ? `Customer - ${customerAssignment?.customer?.name}` : 'Customer',
        cardAction: (
          <span className="shipment__card-financial-actions-btn-actions flex items-center">
            <IconButtonWithTooltip
              aria-label="Edit financials"
              onClick={() => this.triggerEditCustomerFinancials()}
              iconName="Edit"
            />
            <div className="flex gap-1">
              <Dropdown
                className="stop-status-edit"
                icon={<SvgIcon name="Overflow" />}
                variant="activeIcon"
                indicator={false}
              >
                {() => (
                  <>
                    <li onClick={() => this.triggerEditCustomerFinancials()}>Edit Financials</li>
                    <li
                      onClick={() => this.openRateTableModal()}
                      disabled={!this.props.one?.relationship_to_vendor?.vendor}
                    >
                      Add Rate Table
                    </li>
                    {financialsEnabled && this.state.configuredFms.length > 0 ? (
                      <li
                        disabled={!this.props.one?.relationship_to_customer?.customer}
                        onClick={() => this.openInvoiceModal()}
                      >
                        Create Invoice
                      </li>
                    ) : null}
                  </>
                )}
              </Dropdown>
            </div>
          </span>
        )
      }
    ];
    //if the user's company is not either the customer on the shipment or the vendor on the shipment,
    //the user's company is a 3pl on the shipment. We want to show both invoices sent and received
    //cards in this case.
    const is3pl =
      this.props.company?.id !== this.props.one?.relationship_to_customer?.customer?.id &&
      //the vendor information is empty for the carrier, so this is how we distinguish between the
      //3PL and the carrier.
      this.props.one?.relationship_to_vendor?.vendor?.id &&
      //there is a vendor relationship, and it is not the user's company.
      this.props.company?.id !== this.props.one?.relationship_to_vendor?.vendor?.id;
    const isCarrier = this.props.company?.id === this.props.one?.current_carrier?.id;
    const isCustomer = this.props.company?.id === this.props.one?.customer?.id;

    if (financialCards.length === 2) {
      //places the freight invoices sent card below customer financials
      if (isCarrier || is3pl) {
        financialCards.push(
          <CreateFreightInvoiceModalForm shipmentId={this.props.one?.id} role="service_provider">
            {({toggleShowFreightInvoiceModal}) => (
              <FreightInvoicesListCard
                role="service_provider"
                actions={
                  <Dropdown indicator={false} drop="left" variant="icon" icon={<SvgIcon name="Overflow" />}>
                    {({onClick}) => (
                      <CreateFreightInvoiceLineItemButton
                        key="createFreightInvoice"
                        shipmentId={this.props.one?.id}
                        onClick={() => {
                          toggleShowFreightInvoiceModal();
                          onClick();
                        }}
                        role="service_provider"
                      />
                    )}
                  </Dropdown>
                }
              >
                <FreightInvoicesList
                  onCreateNewInvoiceClick={() => toggleShowFreightInvoiceModal()}
                  shipmentId={this.props.one?.id}
                  role="service_provider"
                />
              </FreightInvoicesListCard>
            )}
          </CreateFreightInvoiceModalForm>
        );
      }
      //places the freight invoices received card below carrier financials
      if (isCustomer || is3pl) {
        financialCards.splice(
          1,
          0,
          <CreateFreightInvoiceModalForm shipmentId={this.props.one?.id} role="booking_party">
            {({toggleShowFreightInvoiceModal, stmGenerateInvoiceOnBehalfOfCarrier}) => (
              <FreightInvoicesListCard
                role="booking_party"
                actions={
                  stmGenerateInvoiceOnBehalfOfCarrier ? (
                    <Dropdown indicator={false} drop="left" variant="icon" icon={<SvgIcon name="Overflow" />}>
                      {({onClick}) => [
                        <CreateFreightInvoiceLineItemButton
                          key="createFreightInvoice"
                          shipmentId={this.props.one?.id}
                          onClick={() => {
                            toggleShowFreightInvoiceModal();
                            onClick();
                          }}
                          role="booking_party"
                        />
                      ]}
                    </Dropdown>
                  ) : null
                }
              >
                <FreightInvoicesList
                  onCreateNewInvoiceClick={() => toggleShowFreightInvoiceModal()}
                  shipmentId={this.props.one?.id}
                  role="booking_party"
                />
              </FreightInvoicesListCard>
            )}
          </CreateFreightInvoiceModalForm>
        );
      }
    }

    return (
      <div className="shipment__financials">
        <RollbarErrorBoundary errorMessage="There was an error displaying financials">
          {financialCards.map((card) => {
            if (card.isLegacyFinancialsCard) {
              return (
                <Card
                  className={`shipment__financials-card-${card.type}`}
                  title={card.cardTitle}
                  key={card.type}
                  tooltip={
                    this.state.configuredFms.length === 0
                      ? 'To use Invoice or Billing functions, you need to connect Shipwell to your accounting system.'
                      : null
                  }
                  actions={card.cardAction}
                  isCollapsible
                >
                  <CollapsibleCardContent>
                    <div className="shipment__financials-wrapper">
                      <UseQueryClient>
                        {(queryClient) => (
                          <FinancialsExpanded
                            customerAssignment={customerAssignment}
                            vendorAssignment={vendorAssignment}
                            updated={this.updated}
                            canEdit={canEdit}
                            type={card.type}
                            queryClient={queryClient}
                            editCustomerFinancials={this.state.editCustomerFinancials}
                            setEditCustomerFinancials={this.setEditCustomerFinancials}
                            editVendorFinancials={this.state.editVendorFinancials}
                            setEditVendorFinancials={this.setEditVendorFinancials}
                            triggerEditVendorFinancials={this.setTriggerEditVendorFinancials}
                            triggerEditCustomerFinancials={this.setTriggerEditCustomerFinancials}
                          />
                        )}
                      </UseQueryClient>
                    </div>
                    {card.type === 'billing' ? (
                      <>
                        {this.props.shipmentBills.length > 0 ? (
                          <div className="shipment__financials-row my-4 w-full rounded bg-sw-background py-4">
                            <div className="w-full">
                              <div className="mb-2 grid grid-cols-4 items-end">
                                <span className="pl-4 font-bold">Bill</span>
                                <span className="text-[10px] uppercase">Amount</span>
                                <span className="text-[10px] uppercase">Balance</span>
                                <span className="text-[10px] uppercase">Bill Status</span>
                              </div>
                              {this.props.shipmentBills.map((bill) => {
                                const billActions = this.getBillActions(bill);
                                return (
                                  <div key={bill.id} className="item-row-action grid grid-cols-4">
                                    <div className="space-x-2 pl-4">
                                      <span>
                                        {bill.processing_status === 'SUCCESS' && bill.transaction_number
                                          ? `#${bill.transaction_number}`
                                          : '--'}
                                      </span>
                                      <span className="invoice-date">
                                        (
                                        {moment(bill.created_at).isValid()
                                          ? moment(bill.created_at).format('MM/DD/YY HH:mm')
                                          : 'invalid date'}
                                        )
                                      </span>
                                    </div>
                                    <div>
                                      {bill.processing_status === 'SUCCESS' && bill.total_amount
                                        ? formatCurrency(bill.total_amount, shipmentCurrency)
                                        : '--'}
                                    </div>
                                    <div>
                                      {bill.processing_status === 'SUCCESS' && bill.balance
                                        ? formatCurrency(bill.balance, shipmentCurrency)
                                        : '--'}
                                    </div>
                                    <div className="item-row-action justify-between">
                                      <ShipmentFinancialStatus
                                        processingStatus={bill.processing_status}
                                        paymentStatus={bill.payment_status}
                                        errorDescription={bill.error_description}
                                      />
                                      <Dropdown
                                        indicator={false}
                                        drop="left"
                                        variant="icon"
                                        className="justify-end pr-2"
                                        disabled={billActions.length === 0}
                                        icon={
                                          <SvgIcon
                                            color={billActions.length === 0 ? '$sw-disabled-alternate' : '$sw-icon'}
                                            name="Overflow"
                                          />
                                        }
                                      >
                                        {({onClick}) => (
                                          <>
                                            {billActions.map((action) => (
                                              <div key={action.label}>
                                                <li
                                                  onClick={() => {
                                                    onClick();
                                                    action.callback();
                                                  }}
                                                >
                                                  {action.label}
                                                </li>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                      </Dropdown>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}

                        {loadBoardEnabled ? (
                          <>
                            <Modal
                              show={showFinancialsModal}
                              title="Financials"
                              footerComponent={null}
                              onClose={handleClose}
                            >
                              <FinancialsForm
                                selectedShipment={one}
                                handleClose={handleClose}
                                fetchShipmentDetails={() => this.props.fetchDetails(this.props.one.id)}
                                setError={setError}
                              />
                            </Modal>
                            <div className="my-4 w-full rounded bg-sw-background">
                              <div className="grid grid-cols-4 py-4">
                                <span className="pl-4 font-bold">Internal Pricing</span>
                                <DisplayValue label="Max Buy">
                                  {!isNull(this.props.one.metadata?.max_buy_amount)
                                    ? formatCurrency(this.props.one.metadata?.max_buy_amount, shipmentCurrency)
                                    : '--'}
                                </DisplayValue>
                                <DisplayValue label="Book Now">
                                  {!isNull(this.props.one.metadata?.buy_it_now_amount)
                                    ? formatCurrency(this.props.one.metadata?.buy_it_now_amount, shipmentCurrency)
                                    : '--'}
                                </DisplayValue>
                                <div className="flex w-full">
                                  <DisplayValue className="grow" label="Target Rate">
                                    {!isNull(this.props.one.metadata?.target_rate_amount)
                                      ? formatCurrency(this.props.one.metadata?.target_rate_amount, shipmentCurrency)
                                      : '--'}
                                  </DisplayValue>
                                  <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
                                    <Dropdown
                                      className="justify-end pr-2"
                                      disabled={!(loadBoardEnabled && canEdit && !is_quoting_limited_user)}
                                      variant="icon"
                                      drop="left"
                                      indicator={false}
                                      icon={<SvgIcon name="Overflow" />}
                                      alignEnd
                                    >
                                      {({onClick}) => (
                                        <>
                                          <li
                                            title="Edit"
                                            onClick={() => {
                                              onClick();
                                              this.setState({showFinancialsModal: true});
                                            }}
                                            className="min-w-[80px]"
                                          >
                                            Edit
                                          </li>
                                        </>
                                      )}
                                    </Dropdown>
                                  </TenderingUserPermissionFallback>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}

                        <div className="shipment__financials-row mt-2 p-0">
                          {this.props.one?.metadata &&
                          isEmpty(filteredTriumphPayInvoices) &&
                          selectedRelationship &&
                          !selectedRelationship.vendor_factoring_company &&
                          canEdit &&
                          !this.props.is_quoting_limited_user ? (
                            <div className="bill-to-override-container redesign m-0 w-full rounded p-4">
                              <div className="quote-flow__billToReadOnly">
                                <div className="mb-2 flex border-b border-sw-border">
                                  <h3 className="text-md font-medium capitalize text-sw-text">
                                    Remit To
                                    <Link
                                      className="btn-action"
                                      onClick={() => {
                                        this.setState({showRemitToForm: !this.state.showRemitToForm});
                                      }}
                                    >
                                      <i className="flaticon-edit action-link" />
                                    </Link>
                                  </h3>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {isEmpty(filteredTriumphPayInvoices) &&
                          !this.state.showRemitToForm &&
                          selectedRelationship?.vendor_factoring_company ? (
                            <div className="bill-to-override-container redesign m-0 w-full rounded p-4">
                              <div className="quote-flow__billToReadOnly">
                                <div className="mb-2 flex border-b border-sw-border">
                                  <h3 className="text-md font-medium capitalize text-sw-text">Remit To Factor</h3>
                                  <Link
                                    className="btn-action"
                                    onClick={() => {
                                      this.setState({
                                        showRemitToForm: !this.state.showRemitToForm
                                      });
                                    }}
                                  >
                                    <i className="flaticon-edit action-link" />
                                  </Link>
                                </div>
                                <div className="flex flex-wrap">
                                  <DisplayValue className="m-0 flex-1 p-1" label="Company">
                                    {selectedRelationship.vendor_factoring_company.company_name}
                                  </DisplayValue>
                                  <DisplayValue className="m-0 flex-1 p-1" label="Address">
                                    {selectedRelationship.vendor_factoring_company.address?.formatted_address}
                                  </DisplayValue>

                                  {selectedRelationship.vendor_factoring_company.email && (
                                    <DisplayValue className="m-0 flex-1 p-1" label="Email">
                                      <i className="icon icon-Mail pad-right" />{' '}
                                      <a href={`mailto:${selectedRelationship.vendor_factoring_company.email}`}>
                                        {selectedRelationship.vendor_factoring_company.email}
                                      </a>
                                    </DisplayValue>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {!isEmpty(filteredTriumphPayInvoices) ? (
                            <div className="bill-to-override-container redesign m-0 w-full rounded p-4">
                              <TriumphPayTable triumphPayInvoices={filteredTriumphPayInvoices} />
                            </div>
                          ) : null}
                          {this.state.showRemitToForm ? (
                            <div className="bill-to-override-container redesign m-0 w-full rounded p-4">
                              <ShipmentRemitTo
                                initialValues={selectedRelationship}
                                closeForm={() => {
                                  this.props.fetchDetails(this.props.one.id);
                                  this.setState({showRemitToForm: false});
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <>
                        {this.props.shipmentInvoices?.results?.length > 0 ? (
                          <div className="shipment__financials-row my-4 rounded bg-sw-background py-4">
                            <div className="w-full pl-4">
                              <div className="mb-2 grid grid-cols-4 items-end">
                                <span className="font-bold">Invoice</span>
                                <span className="text-[10px] uppercase">Amount</span>
                                <span className="text-[10px] uppercase">Balance</span>
                                <span className="text-[10px] uppercase">Invoice Status</span>
                              </div>
                              {this.props.shipmentInvoices.results.map((invoice) => {
                                const invoiceActions = this.getInvoiceActions(invoice);
                                return (
                                  <div key={invoice.id} className="item-row-action grid w-full grid-cols-4 ">
                                    <div className="space-x-2">
                                      <span>
                                        {invoice.processing_status === 'SUCCESS' && invoice.invoice_number
                                          ? `#${invoice.invoice_number}`
                                          : '--'}
                                      </span>
                                      <span className="invoice-date">
                                        (
                                        {moment(invoice.created_at).isValid()
                                          ? moment(invoice.created_at).format('MM/DD/YY HH:mm')
                                          : 'invalid date'}
                                        )
                                      </span>
                                    </div>
                                    <div>
                                      {invoice.processing_status === 'SUCCESS' && invoice.total_amount
                                        ? formatCurrency(invoice.total_amount, shipmentCurrency)
                                        : '--'}
                                    </div>
                                    <div>
                                      {invoice.processing_status === 'SUCCESS' && invoice.balance
                                        ? formatCurrency(invoice.balance, shipmentCurrency)
                                        : '--'}
                                    </div>
                                    <div className="item-row-action justify-between">
                                      <span>
                                        <ShipmentFinancialStatus
                                          processingStatus={invoice.processing_status}
                                          paymentStatus={invoice.payment_status}
                                          errorDescription={invoice.error_description}
                                        />
                                      </span>
                                      <Dropdown
                                        indicator={false}
                                        drop="left"
                                        variant="icon"
                                        className="justify-end pr-2"
                                        disabled={invoiceActions.length === 0}
                                        icon={
                                          <SvgIcon
                                            color={invoiceActions.length === 0 ? '$sw-disabled-alternate' : '$sw-icon'}
                                            name="Overflow"
                                          />
                                        }
                                      >
                                        {({onClick}) => (
                                          <>
                                            {invoiceActions.map((action) => (
                                              <li
                                                key={action.label}
                                                onClick={() => {
                                                  onClick();
                                                  action.callback();
                                                }}
                                              >
                                                {action.label}
                                              </li>
                                            ))}
                                          </>
                                        )}
                                      </Dropdown>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}

                        <div className="shipment__financials-row mt-2 p-0">
                          <div className="bill-to-override-container redesign m-0 rounded p-4">
                            {!this.state.showBillToForm &&
                              !this.props.one?.metadata?.bill_to_override &&
                              canEdit &&
                              !this.props.is_quoting_limited_user && (
                                <div className="align-center flex justify-center">
                                  <DeprecatedButton
                                    variant="tertiary"
                                    icon={<SvgIcon name="AddCircleOutlined" />}
                                    onClick={() =>
                                      this.setState({
                                        showBillToForm: !this.state.showBillToForm
                                      })
                                    }
                                  >
                                    Add Bill To
                                  </DeprecatedButton>
                                </div>
                              )}
                            <div>
                              {!this.state.showBillToForm && this.props.one?.metadata?.bill_to_override && (
                                <div className="quote-flow__billToReadOnly">
                                  <div className="mb-2 flex border-b border-sw-border">
                                    <h3 className="text-md font-medium capitalize text-sw-text">
                                      Bill To {titleCase(this.props.one.metadata.bill_to_override.direction)}
                                      <Link
                                        className="btn-action pr-4"
                                        onClick={() => {
                                          this.setState({
                                            showBillToForm: !this.state.showBillToForm
                                          });
                                        }}
                                      >
                                        <i className="flaticon-edit action-link" />
                                      </Link>
                                    </h3>
                                  </div>
                                  <div className="flex flex-wrap justify-between">
                                    {this.props.one.metadata.bill_to_override.billing_id ? (
                                      <DisplayValue className="m-0 p-1" label="Billing ID">
                                        {this.props.one.metadata.bill_to_override.billing_id}
                                      </DisplayValue>
                                    ) : null}
                                    <DisplayValue className="m-0 p-1" label="Company">
                                      {this.props.one.metadata.bill_to_override.company_name}
                                    </DisplayValue>
                                    <DisplayValue className="m-0 p-1" label="Address">
                                      {this.props.one.metadata.bill_to_override.company_address}
                                    </DisplayValue>
                                    {this.props.one.metadata.bill_to_override.contact_email && (
                                      <DisplayValue className="m-0 p-1" label="Email">
                                        <i className="icon icon-Mail pad-right" />
                                        <a href={`mailto:${this.props.one.metadata.bill_to_override.contact_email}`}>
                                          {this.props.one.metadata.bill_to_override.contact_email}
                                        </a>
                                      </DisplayValue>
                                    )}
                                    {this.props.one.metadata.bill_to_override.contact_phone && (
                                      <DisplayValue className="m-0 p-1" label="Phone">
                                        <i className="icon icon-Phone2 pad-right" />
                                        {getPhoneHyperlink(this.props.one.metadata.bill_to_override.contact_phone)}
                                      </DisplayValue>
                                    )}
                                  </div>
                                </div>
                              )}
                              {this.state.showBillToForm && (
                                <ShipmentBillTo
                                  closeForm={() => {
                                    this.props.fetchDetails(this.props.one.id);
                                    this.setState({showBillToForm: false});
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </CollapsibleCardContent>
                </Card>
              );
            }
            return card;
          })}

          <div>
            <InfoModalWrapper
              show={this.state.showInvoiceModal}
              bsSize="large"
              onHide={() => {
                !this.state.processingInvoiceId &&
                  this.setState({
                    showInvoiceModal: false,
                    checkedAll: false,
                    selectList: [],
                    total: 0,
                    selectedDocs: []
                  });
              }}
              title="Create Invoice"
              children={this.renderInvoiceModal()}
            />

            <Modal
              title="Add Rate Table"
              footerComponent={null}
              show={this.state.showRateTableModal}
              onClose={() => {
                this.setState({
                  showRateTableModal: false
                });
              }}
            >
              {this.renderRateTableModal()}
            </Modal>

            <InfoModalWrapper
              show={this.state.showEmailModal && !this.state.showInvoiceModal}
              bsSize="large"
              onHide={() => {
                this.setState({
                  showEmailModal: false,
                  invoice: null
                });
              }}
              title="Email Invoice"
              children={this.renderEmailModal()}
            />
            <Modal
              show={this.state.showBillModal}
              title="Create Bill"
              footerComponent={null}
              onClose={() => {
                this.onCloseBillModal();
              }}
            >
              {this.renderBillModal()}
            </Modal>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              open={this.state.openInvoiceSnackbar}
              autoHideDuration={2000}
              onClose={() => this.setState({openInvoiceSnackbar: false})}
              className="snackbar-success"
              message={<span id="message-id">Invoice was successfully generated.</span>}
            />
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              open={this.state.openRateTableSnackbar}
              autoHideDuration={2000}
              onClose={() => this.setState({openRateTableSnackbar: false})}
              className="snackbar-success"
              message={<span id="message-id">Rate Table was successfully generated.</span>}
            />
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              open={this.state.openBillSnackbar}
              autoHideDuration={2000}
              onClose={() => this.setState({openBillSnackbar: false})}
              className="snackbar-success"
              message={<span id="message-id">Bill was successfully generated.</span>}
            />
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              open={this.state.openCreateCarrierSnackbar}
              autoHideDuration={2000}
              onClose={() => this.setState({openCreateCarrierSnackbar: false})}
              className="snackbar-success"
              message={<span id="message-id">Carrier successfully created in Quickbooks!</span>}
            />
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              open={this.state.openEmailSnackbar}
              autoHideDuration={2000}
              onClose={() => {
                this.setState({openEmailSnackbar: false, currentEmail: null});
              }}
              className="snackbar-success"
              message={<span id="message-id">Invoice was successfully sent to {this.state.currentEmail}!</span>}
            />
            {this.state.processingInvoiceIds?.length > 0 && <PollingProcessor callback={this.fetchInvoices} />}
            {this.state.processingBillIds?.length > 0 && <PollingProcessor callback={this.fetchBills} />}
          </div>
        </RollbarErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    is_quoting_limited_user: state.auth.is_quoting_limited_user,
    company: state.auth.company,
    one: state.shipmentdetails.one,
    selectedRFQ: state.shipmentdetails.selectedRFQ,
    selectedQuote: state.shipmentdetails.selectedQuote,
    shipmentFinancialsForm: state.form.shipmentFinancialsForm,
    chargeCategories: state.shipments.chargeCategories,
    documents: state.documents.documents,
    shipmentInvoiceForm: state.form.shipmentInvoiceForm,
    ShipmentBillingForm: state.form.ShipmentBillingForm,
    quickBooksCustomers: state.integrations.quickBooksCustomers,
    quickBooksCarriers: state.integrations.quickBooksCarriers,
    paymentTerms: state.integrations.paymentTerms,
    shipmentBills: state.integrations.shipmentBills,
    selectedRelationship: state.vendors.selectedRelationship,
    currency: state.userCompany.currency,
    isTriumphPayAuthed: state.integrations.isTriumphPayAuthed,
    featureFlags: state.auth.company && state.auth.company.feature_flags,
    triumphPayInvoices: state.vendors.triumphPayInvoices,
    paymentProcessorViewEnabled: state.auth.user?.permissions?.includes('payment_processors.view_payment_info')
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      ...shipmentDetailsActions,
      ...integrationsActions,
      ...usersActions,
      searchForCarrierByID,
      checkTriumphPayAuth,
      getShipmentInvoices,
      getCompanyInvoice,
      createInvoice,
      sendInvoice,
      getShipmentBills,
      getBill,
      createBill,
      getFinancialManagementSystems,
      fetchTriumphPayVendorInvoices
    },
    dispatch
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps), WithStatusToasts)(ShipmentFinancialsForm);
