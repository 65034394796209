import {Banner} from '@shipwell/shipwell-ui';
import {
  CompanyInternationalPreferencesCurrencyEnum,
  CustomField,
  Shipment,
  ShipmentChargeLineItem
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {AggregateCharge, AggregatePrepaidCharge, GrandTotals} from '../../types';
import {
  AggregateTotalCell,
  FinancialsGrandTotalRow,
  FinancialsGridRow,
  FinancialsSubTotalRow,
  TextCell
} from './components';
import {getCustomDataValueByFieldType} from './utils';
import {formatCurrency} from 'App/utils/internationalConstants';
import Loader from 'App/common/shipwellLoader';
import {useSortedChargeCategories} from 'App/data-hooks';

export const VendorTable = ({
  vendorAssignment,
  aggregateVendorCharges,
  aggregatePrepaidCharges,
  prepaidTotal,
  theirVendorCharges,
  myVendorCharges,
  grandTotals,
  shipmentCurrency,
  customFields
}: {
  vendorAssignment: Shipment['relationship_to_vendor'];
  aggregateVendorCharges: AggregateCharge[];
  aggregatePrepaidCharges: AggregatePrepaidCharge[];
  prepaidTotal: number;
  theirVendorCharges: ShipmentChargeLineItem[];
  myVendorCharges: ShipmentChargeLineItem[];
  grandTotals: GrandTotals;
  shipmentCurrency: CompanyInternationalPreferencesCurrencyEnum;
  customFields: CustomField[];
}) => {
  const {data: chargeCategories, isLoading: isLoadingChargeCategories} = useSortedChargeCategories();
  const visibleCustomFields = customFields.filter((customField) => customField.ui_enabled);
  const {chargeLineItemsCustomFieldsFix}: {chargeLineItemsCustomFieldsFix: boolean} = useFlags();

  if (!vendorAssignment) {
    return (
      <div className="p-4 text-center">
        <h3>You must assign a carrier before adding carrier financials</h3>
      </div>
    );
  }

  if (!aggregateVendorCharges?.length) {
    return (
      <div className="p-4 text-center">
        <h3>No carrier financials</h3>
      </div>
    );
  }

  if (chargeCategories)
    return (
      <div>
        <div>
          {aggregateVendorCharges.map((aggregateVendorCharge, index) => {
            const chargeCategoryName = chargeCategories.find(
              (category) => category.id === aggregateVendorCharge?.category
            )?.name;

            const theirVendorChargesOfThisAggregateCategory = theirVendorCharges.filter(
              (theirVendorCharge) => theirVendorCharge.category === aggregateVendorCharge.category
            );

            const myVendorChargesOfThisAggregateCategory = myVendorCharges.filter(
              (myVendorCharge) => myVendorCharge.category === aggregateVendorCharge.category
            );

            return (
              <FinancialsGridRow
                key={`${aggregateVendorCharge.category}-${index}`}
                hasMismatchedTotals={aggregateVendorCharge?.total_theirs !== aggregateVendorCharge?.total_mine}
                columnCount={visibleCustomFields.length + 4}
              >
                <TextCell>{chargeCategoryName}</TextCell>

                <TextCell>{aggregateVendorCharge.unit_name}</TextCell>

                {visibleCustomFields.map((customField) => {
                  const customFieldValue = getCustomDataValueByFieldType(
                    aggregateVendorCharge.customData,
                    customField,
                    chargeLineItemsCustomFieldsFix
                  );
                  return <TextCell key={customField.id}>{customFieldValue}</TextCell>;
                })}

                <AggregateTotalCell
                  aggregateTotal={aggregateVendorCharge.total_theirs}
                  breakdownCharges={theirVendorChargesOfThisAggregateCategory}
                  currency={shipmentCurrency}
                />

                <AggregateTotalCell
                  aggregateTotal={aggregateVendorCharge.total_mine}
                  breakdownCharges={myVendorChargesOfThisAggregateCategory}
                  currency={shipmentCurrency}
                />
              </FinancialsGridRow>
            );
          })}
        </div>

        <FinancialsSubTotalRow
          label="Shipment Total"
          total={formatCurrency(grandTotals.vendor || 0, shipmentCurrency)}
        />

        {aggregatePrepaidCharges.map((aggregatePrepaidCharge, i) => {
          const chargeCategoryName = chargeCategories.find(
            (category) => category.id === aggregatePrepaidCharge.category
          )?.name;

          const myVendorChargesOfThisAggregateCategory = myVendorCharges.filter(
            (myVendorCharge) => myVendorCharge.category === aggregatePrepaidCharge.category
          );

          return (
            <div key={`${aggregatePrepaidCharge.category}-${i}`} className="flex items-center justify-between px-4">
              <div className="text-sm font-semibold">{chargeCategoryName}</div>
              <AggregateTotalCell
                // prepaid charges come through as positive numbers, but I want to display them as negative
                aggregateTotal={aggregatePrepaidCharge.total * -1}
                breakdownCharges={myVendorChargesOfThisAggregateCategory}
                currency={shipmentCurrency}
              />
            </div>
          );
        })}

        <FinancialsGrandTotalRow
          label="Amount Due to Carrier"
          total={formatCurrency((grandTotals.vendor || 0) - prepaidTotal, shipmentCurrency)}
        />
      </div>
    );

  if (isLoadingChargeCategories) return <Loader loading />;

  return <Banner title={'Error'}>There was an error loading financials. Try reloading the page.</Banner>;
};
