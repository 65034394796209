import {useQueryClient} from '@tanstack/react-query';
import {ShippingDashboardRow} from '@shipwell/backend-core-singlerequestparam-sdk';
import pluralize from 'pluralize';
import {BulkOperation, BulkOperationGetResponse, BulkOperationPostResponse} from '../../utils/DashboardUtilTypes';
import {
  BULK_OPERATION_TYPE_ARCHIVE_SHIPMENT,
  BULK_OPERATION_TYPE_CHANGE_SHIPMENT_STATUS,
  BULK_OPERATION_TYPE_EDIT_SHIPMENT_DATES,
  BULK_OPERATION_TYPE_PUSH_TO_ROUTING_GUIDE,
  BULK_OPERATION_TYPE_TENDER_TO_CARRIER,
  bulkOperationTypeMessages
} from '../../utils/constants';
import {createBulkOperation, getBulkOperation} from 'App/api/bulkOperations';
import {WithStatusToastProps} from 'App/components/withStatusToasts';
import {SHIPMENTS_DASHBOARD_QUERY_KEY} from 'App/data-hooks/queryKeys';

export const useOpusBulkActions = ({
  setSuccess,
  setError,
  selectedShipments
}: {selectedShipments: ShippingDashboardRow[]} & Pick<WithStatusToastProps, 'setSuccess' | 'setError'>) => {
  const queryClient = useQueryClient();
  let bulkOperationInterval: NodeJS.Timer | undefined;

  const pollBulkOperation = (bulkOperationId: string, bulkOperationType: BulkOperation) => {
    let pollCount = 0;
    let hasShownSuccess = false;
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    bulkOperationInterval = setInterval(async () => {
      try {
        pollCount++;
        const response = (await getBulkOperation(bulkOperationId)) as BulkOperationGetResponse;
        if (
          response.data.status === 'FINISHED' ||
          (response.data.status === 'PROCESSING' &&
            [BULK_OPERATION_TYPE_PUSH_TO_ROUTING_GUIDE, BULK_OPERATION_TYPE_TENDER_TO_CARRIER].includes(
              bulkOperationType
            ))
        ) {
          //routing guides may stay in processing for a long time because of timers, so we count that as complete after 30 seconds
          if (response.data.status === 'FINISHED' || pollCount > 15) {
            clearInterval(bulkOperationInterval);
            if (
              [
                BULK_OPERATION_TYPE_EDIT_SHIPMENT_DATES,
                BULK_OPERATION_TYPE_CHANGE_SHIPMENT_STATUS,
                BULK_OPERATION_TYPE_ARCHIVE_SHIPMENT
              ].includes(bulkOperationType)
            ) {
              void queryClient.invalidateQueries([SHIPMENTS_DASHBOARD_QUERY_KEY]);
            }
          }
          if (response.data.failed > 0) {
            const failedShipments = response.data.resource_statuses
              .filter((resource) => resource.status === 'ERROR')
              .map((resource) => {
                return {
                  id: resource.resource_id,
                  reference_id: selectedShipments.find((shipment) => shipment.id === resource.resource_id)?.reference_id
                };
              });
            const {errorTitle, errorDetails} = bulkOperationTypeMessages[bulkOperationType];
            setError(
              errorTitle,
              <div>
                <div>{`${errorDetails} ${pluralize('shipment', failedShipments.length)}.`}</div>
                <div>
                  {failedShipments.map((shipment) => {
                    return (
                      <div key={shipment.id}>
                        <a href={`/shipments/${shipment.id}`} target="_blank" rel="noreferrer">
                          {shipment.reference_id}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>,
              'top-right',
              {delay: null}
            );
          } else {
            if (!hasShownSuccess) {
              hasShownSuccess = true;
              const {successTitle, successDetails} = bulkOperationTypeMessages[bulkOperationType];
              setSuccess(successTitle, `${successDetails} ${pluralize('shipment', response.data.total, true)}.`);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 3000);
  };

  const cancelBulkShipments = async (selectedShipmentIds: string[]) => {
    try {
      if (selectedShipmentIds) {
        const bulkOperationBody = {
          resource_type: 'shipment',
          workflow: {
            name: 'Bulk op',
            start_events: [
              {
                step_id: 'STEP_1',
                trigger: {
                  trigger_type: 'NONE',
                  outputs: [
                    {name: 'shipment_id', data_type: 'String', push_to_list: null},
                    {name: 'shipment', data_type: 'Shipment', push_to_list: null}
                  ]
                },
                next_step: {
                  step_id: 'STEP_2'
                }
              }
            ],
            end_events: [
              {
                step_id: 'STEP_END',
                should_terminate: true
              }
            ],
            actions: [
              {
                action_id: 'MODIFY_SHIPMENT_STATUS',
                step_id: 'STEP_2',
                attached_triggers: [
                  {
                    trigger: {
                      trigger_type: 'NONE'
                    },
                    next_step_id: 'STEP_END'
                  }
                ],
                inputs: [
                  {
                    name: 'shipment_id',
                    data_type: 'String',
                    is_required: true
                  }
                ],
                params: [
                  {
                    name: 'new_status',
                    value: 'cancelled'
                  }
                ]
              }
            ]
          },
          criteria: {
            id: {
              $in: selectedShipmentIds
            }
          }
        };
        const response = (await createBulkOperation(bulkOperationBody)) as BulkOperationPostResponse;
        setSuccess(
          `Shipping cancellation has been submitted for ${pluralize('shipment', selectedShipmentIds.length, true)}.`
        );
        pollBulkOperation(response.data.id, BULK_OPERATION_TYPE_CHANGE_SHIPMENT_STATUS);
      }
    } catch (error) {
      console.error(`Error cancelling ${pluralize('shipment', selectedShipmentIds.length)}.`, error);
      setError(
        'Changes Not Saved',
        `Unable to cancel the ${pluralize('shipment', selectedShipmentIds.length)} you selected.`
      );
    }
  };

  const archiveBulkShipments = async (selectedShipmentIds: string[]) => {
    try {
      if (selectedShipmentIds) {
        const bulkOperationBody = {
          resource_type: 'shipment',
          workflow: {
            name: 'Bulk op',
            start_events: [
              {
                step_id: 'STEP_1',
                trigger: {
                  trigger_type: 'NONE',
                  outputs: [
                    {name: 'shipment_id', data_type: 'String', push_to_list: null},
                    {name: 'shipment', data_type: 'Shipment', push_to_list: null}
                  ]
                },
                next_step: {
                  step_id: 'STEP_2'
                }
              }
            ],
            end_events: [
              {
                step_id: 'STEP_END',
                should_terminate: true
              }
            ],
            actions: [
              {
                action_id: 'ARCHIVE_SHIPMENT',
                step_id: 'STEP_2',
                attached_triggers: [
                  {
                    trigger: {
                      trigger_type: 'NONE'
                    },
                    next_step_id: 'STEP_END'
                  }
                ],
                inputs: [
                  {
                    name: 'shipment_id',
                    data_type: 'String',
                    is_required: true
                  }
                ],
                params: []
              }
            ]
          },
          criteria: {
            id: {
              $in: selectedShipmentIds
            }
          }
        };
        const response = (await createBulkOperation(bulkOperationBody)) as BulkOperationPostResponse;
        setSuccess('Bulk Action Started!', `Archiving ${pluralize('shipment', selectedShipmentIds.length, true)}.`);
        pollBulkOperation(response.data.id, BULK_OPERATION_TYPE_ARCHIVE_SHIPMENT);
      }
    } catch (error) {
      console.error(`Error archiving ${pluralize('shipment', selectedShipmentIds.length)} status.`, error);
      setError(
        'Changes Not Saved',
        `Your changes could not be applied to the ${pluralize('shipment', selectedShipmentIds.length)} you selected.`
      );
    }
  };

  return {
    pollBulkOperation,
    cancelBulkShipments,
    archiveBulkShipments
  };
};
