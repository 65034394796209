import {ShipmentLineItem} from '@shipwell/backend-core-sdk';
import {
  ChargeCategory,
  ContractChargeItem,
  ContractCriteriaByValueRequestTotalWeightUnitEnum,
  TotalWeightOverride
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {convertWeight} from 'App/utils/globalsTyped';

export const getChargeItemsTotals = (charges?: ContractChargeItem[]) => {
  const lineHaulCharge = charges?.find((charge) => charge.category === ChargeCategory.Lh);
  const fuelCharge = charges?.find((charge) => charge.category === ChargeCategory.Fsc);
  const additionalStopCharge = charges?.find((charge) => charge.category === ChargeCategory.Other);

  const currency = charges?.[0]?.unit_amount_currency;

  const totals = {
    lineHaulTotal: calculateChargeItemTotal(lineHaulCharge),
    fuelChargeTotal: calculateChargeItemTotal(fuelCharge),
    additionalStopChargeTotal: calculateChargeItemTotal(additionalStopCharge)
  };

  const total = totals.lineHaulTotal + totals.fuelChargeTotal + totals.additionalStopChargeTotal;

  return {
    lineHaulCharge,
    fuelCharge,
    additionalStopCharge,
    currency,
    total,
    ...totals
  };
};

export const calculateChargeItemTotal = (charge?: ContractChargeItem) =>
  (charge?.unit_amount || 0) * (charge?.unit_quantity || 0);

export const getTotalPackagesAndWeight = ({
  contractWeightUnit,
  totalWeight,
  totalPackages,
  lineItems,
  totalWeightOverride
}: {
  contractWeightUnit?: ContractCriteriaByValueRequestTotalWeightUnitEnum;
  totalWeight?: number | null;
  totalPackages?: number | null;
  lineItems?: ShipmentLineItem[];
  totalWeightOverride?: TotalWeightOverride;
}) => {
  // weight system to determine if weight conversion is required
  const weightSystem =
    contractWeightUnit === ContractCriteriaByValueRequestTotalWeightUnitEnum.Kg ? 'METRIC' : 'IMPERIAL';
  // either total packages were passed in or we need to calculate from lineItems
  const shipmentTotalPackages =
    totalPackages || lineItems?.reduce((totalPackages, lineItem) => totalPackages + Number(lineItem.total_packages), 0);
  // either passed in totalWeight
  // OR the overridden total weight
  // OR calculated from lineItems. Must convert values to match contract weight unit
  const shipmentTotalWeightSum =
    totalWeight ||
    (totalWeightOverride?.value
      ? convertWeight(Number(totalWeightOverride.value), totalWeightOverride.unit, weightSystem)
      : lineItems?.reduce(
          (totalWeight, lineItem) =>
            totalWeight +
            convertWeight(Number(lineItem.package_weight), lineItem.weight_unit, weightSystem) *
              Number(lineItem.total_packages),
          0
        ));

  return {shipmentTotalPackages, shipmentTotalWeightSum};
};
