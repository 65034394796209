//Categories
export const CATEGORIES = {
  SHIPMENT_DETAILS: 'Shipment Details',
  INTEGRATIONS: 'Integrations',
  PRICING_INTEL: 'Pricing Intelligence'
};

//Actions
export const ACTIONS = {
  SUBMIT: 'Submit',
  CLICK: 'Click',
  EDIT: 'Edit',
  DELETE: 'Delete'
};

//Labels
export const LABELS = {
  STATUS: 'Status',
  STOPS: 'Stops',
  CARRIER: 'Carrier',
  NOTES: 'Notes',
  REFERENCES: 'References',
  TAGS: 'Tags',
  VIEW_QUOTES: 'View Quotes',
  CLONE: 'Clone',
  LINE_ITEMS: 'Line Items',
  CASES: 'Cases',
  TRUCK_LOCATION: 'Truck Location',
  TIMELINE: 'Timeline',
  MESSAGES: 'Messages',
  GENERATE_DOCUMENT: 'Generate Document',
  NAME: 'Name',
  SEND_DOCUMENT: 'Send Document',
  UPLOAD_DOCUMENT: 'Upload Document',
  SHARE: 'Share',
  FINANCIALS: 'Financials',
  CONNECT_INTEGRATION: 'Connect Integration',
  DISCONNECT_INTEGRATION: 'Disconnect Integration',
  UPDATE_INTEGRATION: 'Update Integration',
  COPY_REFERENCE_PRICE: 'Copy Reference Price',
  COPY_MY_REFERENCE_PRICE: 'Copy My Reference Price',
  COPY_MARKET_REFERENCE_PRICE: 'Copy Market Reference Price',
  APPLY_MY_PREDICTED_TO_BOOK_NOW: 'Apply My Predicted to Book Now',
  APPLY_MARKET_PREDICTED_TO_BOOK_NOW: 'Apply Market Predicted to Book Now'
};
