import {SearchField, SvgIcon, Tooltip, Checkbox} from '@shipwell/shipwell-ui';
import {ChangeEvent, useState} from 'react';
import {WithRouterProps, withRouter} from 'react-router';
import {AddressBookEntry} from '@shipwell/backend-core-sdk';
import {FormikHelpers} from 'formik';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {RowSelectionState} from '@tanstack/react-table';
import {ContainerType} from '../../components/AddOrdersButton';
import {AddOrdersToShipmentListTable} from 'App/containers/orders/views/AddOrdersToShipmentListView/components/AddOrdersToShipmentListTable';
import {FlexBox} from 'App/components/Box';
import {useLocalStorage} from 'App/utils/hooks/useLocalStorage';
import WithStatusToasts, {WithStatusToastProps} from 'App/components/withStatusToasts';
import {shipmentsShipmentIdGet} from 'App/actions/shipmentdetails';
import {SharedTypeEnum} from 'App/containers/orders/views/AddOrdersToShipmentListView/components/AddOrdersToShipmentSharedStartEndPoint';
import UpdateOrderDates from 'App/containers/orders/views/AddOrdersToShipmentListView/components/UpdateOrderDates/index';
import {useShipment} from 'App/data-hooks';

type AddOrdersToShipmentListViewProps = WithRouterProps<{shipment_id: string}> &
  WithStatusToastProps & {
    onClose: () => void;
    dispatch?: Dispatch<Action<typeof shipmentsShipmentIdGet>>;
    activeContainer: ContainerType;
    setActiveContainer: React.Dispatch<React.SetStateAction<ContainerType>>;
  };
export type SubmitValuesType = {
  sharedType: SharedTypeEnum;
  shared_address?: AddressBookEntry['address'];
  formikActions?: FormikHelpers<SubmitValuesType>;
  start_time?: string | null;
  end_time?: string | null;
};
const AddOrdersToShipmentListViewBase = ({
  params,
  onClose,
  setSuccess,
  setError,
  dispatch,
  setActiveContainer,
  activeContainer
}: AddOrdersToShipmentListViewProps) => {
  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [displayAllAvailableOrders, setDisplayAllAvailableOrders] = useLocalStorage({
    key: 'display-all-available-orders',
    initialValue: false
  });

  const {shipment} = useShipment(params?.shipment_id);

  const removeSelectedById = (id: string) => {
    setRowSelection((prevRowSelection) => {
      const newRowSelection = {...prevRowSelection};
      delete newRowSelection[id];
      return newRowSelection;
    });
    setSelectedOrderIds((prevIds) => prevIds.filter((orderId) => orderId !== id));
  };

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value);

  const DysplayAllAvailableOrdersTooltipContent = () => (
    <div>
      <span className="font-bold">All Available Orders</span>
      <br />
      <span>
        This displays all orders no matter their date.
        <br /> This will not include orders that are in the ‘Not Ready’, ’Filled’, and ‘Delivered’ status.
      </span>
    </div>
  );
  return (
    <FlexBox gap="m" direction="col">
      {activeContainer === 'addOrder' && (
        <>
          <span>Select the order(s) you want to add to this shipment.</span>
          <div className="border border-sw-border">
            <FlexBox gap="s" direction="row" items="center" justify="between" pad="m">
              <div className="flex  gap-1">
                <h5>Orders</h5>
              </div>
              <div className="flex items-center gap-1">
                <div className="flex gap-1">
                  <Checkbox
                    label="Display all available orders"
                    checked={displayAllAvailableOrders}
                    onChange={() => setDisplayAllAvailableOrders(!displayAllAvailableOrders)}
                    fixedHeight={false}
                  />
                  <Tooltip
                    tooltipContent={<DysplayAllAvailableOrdersTooltipContent />}
                    tooltipClassname={'max-w-[400px]'}
                  >
                    <SvgIcon name="InfoOutlined" color="$sw-icon" />
                  </Tooltip>
                </div>
                <div className="grow">
                  <SearchField
                    label="Search for Orders"
                    name="search orders"
                    value={searchTerm}
                    onChange={handleSearchInput}
                  />
                </div>
              </div>
            </FlexBox>
            <AddOrdersToShipmentListTable
              searchTerm={searchTerm}
              displayAllAvailableOrders={displayAllAvailableOrders}
              onRowSelectionChange={setSelectedOrderIds}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              relatedOrdersIds={shipment?.related_orders?.map((order) => order.order_id) || []}
            />
          </div>
        </>
      )}

      <UpdateOrderDates
        selectedOrderIds={selectedOrderIds}
        onClose={onClose}
        setActiveContainer={setActiveContainer}
        activeContainer={activeContainer}
        shipmentId={params?.shipment_id}
        dispatch={dispatch}
        setSuccess={setSuccess}
        setError={setError}
        removeSelectedById={removeSelectedById}
        shipment={shipment}
      />
    </FlexBox>
  );
};

export const AddOrdersToShipmentListView = compose<
  AddOrdersToShipmentListViewProps,
  //the outer props, or what the component receives aside from the HOC props
  {
    onClose: () => void;
    activeContainer: ContainerType;
    setActiveContainer: React.Dispatch<React.SetStateAction<ContainerType>>;
  }
>(
  withRouter,
  WithStatusToasts,
  connect()
)(AddOrdersToShipmentListViewBase);
