import moment from 'moment';
import {
  Shipment,
  Stop,
  ShipmentCustomData,
  CustomFieldEntityTypesEnum
} from '@shipwell/backend-core-singlerequestparam-sdk';

export const getTimeErrorRows = (shipments: Shipment[]) =>
  shipments
    .map((shipment, index) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const hasTimeErrors = shipment.stops?.some((stop: Stop & {'schedule-select'?: number}) => {
        const startTime = moment(stop.planned_time_window_start, 'HH:mm');
        const stopTime = moment(stop.planned_time_window_end, 'HH:mm');
        //check if schedule selection is 'Open' before validating start and stop time
        const scheduleSelectOpen = stop['schedule-select'] === 2;
        return startTime.isValid() && stopTime.isValid() && moment(stopTime).isBefore(startTime) && scheduleSelectOpen;
      });
      return hasTimeErrors ? index + 1 : 0;
    })
    .filter(Boolean);

export const getDateErrorRows = (shipments: Shipment[]) =>
  shipments
    .map((shipment, index) => {
      const stops = shipment.stops || [];
      const pickupDate = stops[0]?.planned_date;
      const dropoffDate = stops[stops?.length - 1]?.planned_date;
      return moment(pickupDate).isAfter(moment(dropoffDate)) ? index + 1 : 0;
    })
    .filter(Boolean);

export const getRequiredDataErrorRows = (shipments: Shipment[], requiredFieldIds: string[]) =>
  shipments
    .map((shipment, rowIndex) => {
      const shipmentCustomData = shipment.custom_data?.shipwell_custom_data as ShipmentCustomData;
      const customData = (shipmentCustomData?.[CustomFieldEntityTypesEnum.Shipment] || {}) as Record<string, string>;
      return requiredFieldIds
        .filter((fieldId) => ['', null].includes(customData[fieldId]))
        .map((fieldId) => ({fieldId, rowIndex}));
    })
    .flat();
