import {INTERMODAL_VALUE} from 'App/formComponents/formSections/contractFields';

export default function modeFilterOptions({excludeParcel = false} = {}) {
  const options = [
    {label: 'Full Truckload', value: 'FTL'},
    {label: 'Less Than Truckload', value: 'LTL'},
    {label: 'Volume Less Than Truckload', value: 'VLTL'},
    {label: 'Drayage', value: 'DRAYAGE'},
    {label: 'Rail', value: 'RAIL_CAR'}
  ];

  if (!excludeParcel) {
    options.push({label: 'Parcel', value: 'PARCEL'});
  }

  return options;
}

export const INTERMODAL_FILTER_OPTION = {label: 'Intermodal', value: INTERMODAL_VALUE};
