import PropTypes from 'prop-types';
import {Field, useFormikContext} from 'formik';
import {compose} from 'recompose';
import {FormikTextInput, FormikSelect, FormikDateTimePicker, FormikTextarea, SvgIcon} from '@shipwell/shipwell-ui';
import {WORKFLOW_WARNING_OVER_CAPACITY} from 'App/containers/workflows/workflowConstants';
import getTimeOptions from 'App/utils/getTimeOptions';
import withFlags from 'App/utils/withFlags';
import {RATE_TYPE_PER_MILE, RATE_TYPE_FLAT_RATE} from 'App/containers/userCompany/rateTables/constants';
import {getCarrierWithDataMetrics} from 'App/api/rfpOptimized/typed';
import {getCarrierPocs} from 'App/containers/userCompany/rfps/utils/carriers';
import {CarrierOption} from 'App/containers/userCompany/rfps/ActionButton';
import InlineNotification from 'App/containers/Book/FormSections/InlineNotification';
import useCheckIsInsuranceValid from 'App/data-hooks/carriers/useCheckIsInsuranceValid';

/**
 * Tender Request Fields
 * @param {*} props
 */
const TenderRequestFields = ({
  locationCapacityAvailable,
  wfaLocationCapacity,
  expirationType,
  singleCarrier,
  currencyOfRecord,
  shipmentDetailData,
  fiCarrierComplianceInsurance
}) => {
  const {values, setValues} = useFormikContext();
  const {isInsuranceInvalid} = useCheckIsInsuranceValid(
    shipmentDetailData,
    fiCarrierComplianceInsurance,
    values.tender_to_company || []
  );

  const convertCarrier = (carrier) => {
    if (carrier?.carrier_company_id) {
      return {
        carrier: carrier.carrier_company_id,
        name: carrier?.company_name,
        carrierName: carrier?.company_name,
        carrierFAVRid: carrier?.id,
        carrierRelationship: carrier?.carrierRelationship,
        carrierStatus: carrier?.carrier_status,
        id: carrier?.id,
        label: `${carrier?.company_name || ''} - ${carrier?.first_name || ''} ${carrier?.last_name || ''} (${
          carrier?.email || ''
        })`,
        pocId: carrier?.user
      };
    }
    return carrier;
  };

  const handleCarrierSelection = (carrier) => {
    if (singleCarrier) {
      setValues({
        ...values,
        tender_to_company: [convertCarrier(carrier)]
      });
    } else {
      const convertedCarriers = carrier.map((c) => convertCarrier(c));
      setValues({
        ...values,
        tender_to_company: convertedCarriers
      });
    }
  };

  const showLocationCapacityWarning = values?.tender_to_company?.length > 0 && !locationCapacityAvailable;
  const isOptionDisabled = (option) => option.carrier_status !== 'ACTIVE';

  return (
    <div className="flex flex-col gap-4">
      <Field
        async
        isMulti={!singleCarrier}
        required
        label={singleCarrier ? 'Carrier' : 'Carrier(s)'}
        name="tender_to_company"
        onChange={handleCarrierSelection}
        getOptionValue={(option) => option.id}
        loadOptions={async (q) => {
          const response = await getCarrierWithDataMetrics({q});
          const paginatedResults = response.data.results || [];
          return getCarrierPocs(paginatedResults);
        }}
        component={FormikSelect}
        defaultOptions
        formatOptionLabel={(option, context) => {
          if ('label' in option) {
            return option.label;
          }
          return CarrierOption(option, context, isOptionDisabled(option));
        }}
        isOptionDisabled={isOptionDisabled}
        disabled={values.contract}
      />
      {isInsuranceInvalid ? (
        <InlineNotification
          type="error"
          title={'Carrier Insurance Limit Not Met!'}
          message="The carriers assigned to this shipment do not meet the insurance limit required for this shipment."
        />
      ) : null}
      {wfaLocationCapacity && showLocationCapacityWarning ? (
        <div className="-mt-1 mb-3 flex flex-row items-center rounded bg-sw-warning-background p-2">
          <div className="flex flex-row items-center font-bold">
            <SvgIcon name="ErrorOutlined" color="sw-warning" />
            <div className="ml-2">{WORKFLOW_WARNING_OVER_CAPACITY}</div>
          </div>
        </div>
      ) : null}
      <div className="grid grid-cols-2 gap-4">
        <Field
          prepend={currencyOfRecord}
          label="Rate"
          name="charge_line_items[0].unit_amount"
          required
          component={FormikTextInput}
        />
        <Field
          label="Rate Type"
          name="rate_type"
          required
          simpleValue
          component={FormikSelect}
          options={[
            {value: RATE_TYPE_PER_MILE, label: 'Per Mile'},
            {value: RATE_TYPE_FLAT_RATE, label: 'Flat Rate'}
          ]}
        />
      </div>
      {expirationType === 'expires_at' ? (
        <Field label="Expiration" name="expires_at" component={FormikDateTimePicker} />
      ) : (
        <Field
          label="Expiration"
          options={getTimeOptions().filter((e) => e.value !== 0)}
          name="expires_after_seconds"
          component={FormikSelect}
          required
        />
      )}
      <Field label="Special Instructions" name="info_message" component={FormikTextarea} />
    </div>
  );
};

TenderRequestFields.propTypes = {
  dispatch: PropTypes.func,
  filteredOptionsList: PropTypes.shape({
    equipment_type: PropTypes.array,
    mode: PropTypes.array,
    showFilteredEquipTypes: PropTypes.bool,
    showFilteredModes: PropTypes.bool
  }),
  shipmentModes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      description: PropTypes.string
    })
  ),
  showOptions: PropTypes.shape({
    equipment_type: PropTypes.bool,
    mode: PropTypes.bool
  }),
  values: PropTypes.shape({
    charge_line_items: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        unit_amount: PropTypes.number,
        unit_amount_currency: PropTypes.number,
        unit_name: PropTypes.string,
        unit_quantity: PropTypes.string
      })
    ),
    equipment_type: PropTypes.string,
    mode: PropTypes.number,
    expires_at: PropTypes.string,
    message: PropTypes.string,
    info_message: PropTypes.string,
    shipment: PropTypes.string,
    tender_to_company: PropTypes.arrayOf(PropTypes.string)
  }),
  shipmentDetailData: PropTypes.shape({
    id: PropTypes.string,
    total_miles: PropTypes.number
  }),
  wfaLocationCapacity: PropTypes.bool,
  wfaCarrierCapacity: PropTypes.bool,
  fiCarrierComplianceInsurance: PropTypes.bool,
  locationCapacityAvailable: PropTypes.bool,
  hasNonMatchingSelectedShipments: PropTypes.bool,
  hasInvalidContract: PropTypes.bool,
  setHasInvalidContract: PropTypes.func,
  carrierCapacityAvailable: PropTypes.bool,
  setCarrierCapacityAvailable: PropTypes.func,
  selectedShipmentIds: PropTypes.array,
  expirationType: PropTypes.string,
  singleCarrier: PropTypes.bool,
  currencyOfRecord: PropTypes.string.isRequired
};

TenderRequestFields.defaultProps = {
  expirationType: 'expires_at'
};

export default compose(withFlags('wfaCarrierCapacity', 'fiCarrierComplianceInsurance'))(TenderRequestFields);
