import {
  OrderItem,
  OrderReleaseDetails,
  OrderItemAmount,
  OrderStop,
  PurchaseOrder,
  TemperatureUnit,
  TransportationReleaseDetails,
  TransportationReleaseDetailsReleaseTypeEnum,
  VolumeUnit,
  OrderRelease,
  TransportationRelease,
  OrderReleaseReleaseTypeEnum,
  CreateSalesOrderRelease,
  CreatePurchaseOrderRelease,
  CreateSalesOrderReleaseReleaseTypeEnum,
  CreatePurchaseOrderReleaseReleaseTypeEnum,
  CreatePurchaseOrder,
  CreateOrderItem,
  PurchaseOrderOrderTypeEnum,
  SalesOrder
} from '@shipwell/corrogo-sdk';
import {ShipmentStatusEnum} from '@shipwell/backend-core-sdk';
import {TempUnitType} from '@shipwell/shipment-assembly-sdk';
import {OrderDocumentMetadata, ReleaseDocumentWithFile} from 'App/containers/orders/types';
import {NewOrUpdatedPurchaseOrder} from 'App/containers/orders/purchaseOrdersCorrogo/forms/PurchaseOrderWithSupplier';

export const isVolumeUnit = (value: OrderItemAmount['unit']): value is VolumeUnit =>
  Object.values(VolumeUnit).some((volumeUnit) => volumeUnit === value);

export function isFile(file: Blob | string): file is File {
  return file instanceof File;
}

export const isReleaseDocumentWithFile = (document: OrderDocumentMetadata): document is ReleaseDocumentWithFile =>
  !!document.original_file && !!document.add_to_release;

export const hasLineItems = (order: PurchaseOrder): order is PurchaseOrder & {items: OrderItem[]} =>
  !!order.items?.length;

export const hasShipFrom = (order: PurchaseOrder): order is PurchaseOrder & {ship_from: OrderStop} => !!order.ship_from;

//this is a kinda poop typeguard that I created specifically because I could not filter an array twice to yield
//an array with a combination of the two types above (e.g., orders.filter(hasLineItems).filter(hasShipFrom)).
//The compiler only would get the first typeguard and not the second.
export const hasLineItemsAndShipFrom = (
  order: PurchaseOrder
): order is PurchaseOrder & {ship_from: OrderStop; items: OrderItem[]} => !!order.ship_from && !!order.items?.length;

export const isTempUnit = (temperatureUnit: TemperatureUnit | TempUnitType): temperatureUnit is TempUnitType =>
  temperatureUnit === 'F' || temperatureUnit === 'C';

export const isTransportationRelease = (
  value: OrderReleaseDetails | TransportationReleaseDetails
): value is TransportationReleaseDetails =>
  value.release_type === TransportationReleaseDetailsReleaseTypeEnum.Transportation;

export interface TransportationReleaseDetailsWithShipmentStatus extends TransportationReleaseDetails {
  shipmentStatus?: ShipmentStatusEnum;
}
export const isTransportationReleaseWithShipmentStatus = (
  value: OrderReleaseDetails | TransportationReleaseDetailsWithShipmentStatus
): value is TransportationReleaseDetailsWithShipmentStatus =>
  value.release_type === TransportationReleaseDetailsReleaseTypeEnum.Transportation;

export const isOrderRelease = (value: OrderRelease | TransportationRelease): value is OrderRelease =>
  value.release_type === OrderReleaseReleaseTypeEnum.Order;

//these are kinda silly and trivial at this time, because as you can see the typeguards both check
//that release_type is ORDER. However, this allows the compiler to differentiate when we are dealing
//with a sales order release versus a purchase release.
export const isCreateSalesOrderRelease = (
  value: CreateSalesOrderRelease | CreatePurchaseOrderRelease
): value is CreateSalesOrderRelease => value.release_type === CreateSalesOrderReleaseReleaseTypeEnum.Order;

export const isCreatePurchaseOrderRelease = (
  value: CreateSalesOrderRelease | CreatePurchaseOrderRelease
): value is CreatePurchaseOrderRelease => value.release_type === CreatePurchaseOrderReleaseReleaseTypeEnum.Order;

export const isCreatePurchaseOrder = (
  purchaseOrder: CreatePurchaseOrder | NewOrUpdatedPurchaseOrder
): purchaseOrder is CreatePurchaseOrder => !('id' in purchaseOrder) || !!purchaseOrder.id;

export const isNewOrderItem = (item: CreateOrderItem | OrderItem): item is CreateOrderItem =>
  !('id' in item) || !item.id;

export const isOrderItem = (item: CreateOrderItem | OrderItem): item is OrderItem => 'id' in item && !!item.id;

export const isPurchaseOrder = (order: PurchaseOrder | SalesOrder): order is PurchaseOrder =>
  order.order_type === PurchaseOrderOrderTypeEnum.PurchaseOrder;
