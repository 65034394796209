import {Button} from '@shipwell/shipwell-ui';
import {createColumnHelper} from '@tanstack/react-table';
import moment from 'moment';
import {EquipmentType, TenderGet} from '@shipwell/backend-core-singlerequestparam-sdk';
import {formatDateTime} from 'App/utils/globals';
import {formatCurrency} from 'App/utils/internationalConstants';
import {TENDER_REQUESTED, handleTenderStatus} from 'App/containers/tendering/create/utils/constants';

const columnHelper = createColumnHelper<Omit<TenderGet, 'equipment_type'> & {equipment_type: EquipmentType}>();

export const getColumns = ({
  compactView,
  revokeTenderById
}: {
  compactView: boolean;
  revokeTenderById: (tenderId: string) => void;
}) => [
  columnHelper.accessor('tender_to_company', {
    header: 'Carrier',
    id: 'carrier',
    enableSorting: false,
    cell: (info) => info.getValue()?.name || '--'
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    id: 'status',
    enableSorting: false,
    cell: (info) => <div className="loadBoard__tendered">{handleTenderStatus(info.row.original)}</div>
  }),
  ...(!compactView
    ? [
        columnHelper.accessor('created_at', {
          header: 'Tendered',
          id: 'created_at',
          enableSorting: false,
          cell: ({getValue}) => formatDateTime(getValue())
        })
      ]
    : []),
  ...(!compactView
    ? [
        columnHelper.accessor('expires_at', {
          header: 'Expires',
          id: 'expires_at',
          enableSorting: false,
          cell: ({getValue}) => (moment(getValue()).isValid() ? formatDateTime(getValue()) : '--')
        })
      ]
    : []),
  ...(!compactView
    ? [
        columnHelper.accessor('mode', {
          header: 'Mode',
          id: 'mode',
          enableSorting: false,
          cell: ({getValue}) => getValue()?.code || '--'
        })
      ]
    : []),
  ...(!compactView
    ? [
        columnHelper.accessor('equipment_type', {
          header: 'Equipment',
          id: 'equipment_type',
          enableSorting: false,
          cell: ({getValue}) => getValue()?.name || '--'
        })
      ]
    : []),
  columnHelper.accessor('charge_line_items', {
    header: 'Rate',
    id: 'rate',
    enableSorting: false,
    cell: ({getValue}) => {
      const value = getValue();
      const currency = value?.[0]?.unit_amount_currency || 'USD';
      const perHourRate = value?.find(
        (value) => !!value.unit_amount && value.category === 'LH' && value.unit_quantity === 0
      );
      const total = value?.reduce((total, item) => total + item.unit_amount * item.unit_quantity, 0);
      return perHourRate
        ? `${formatCurrency(perHourRate.unit_amount, currency)}/hour`
        : formatCurrency(total, currency);
    }
  }),
  columnHelper.accessor('id', {
    header: 'Action',
    enableSorting: false,
    cell: ({row: {original}}) =>
      handleTenderStatus(original) === TENDER_REQUESTED ? (
        <Button onClick={() => revokeTenderById(original.id)} variant="secondary">
          Revoke
        </Button>
      ) : (
        ''
      )
  })
];
